/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react"
import { usePromise, usePrevious } from "react-use"
import { Link } from "@reach/router"
import BaseTable, { AutoResizer } from "react-base-table"

import Header from "components/dashboard/Header/Header"
import Loader from "components/common/Loader/Loader"
import SearchInput from "components/forms/SearchInput"
import Pagination from "components/common/Pagination/Pagination"

import { getSuppliersFromMarketplace, getSuppliers } from "services/supplier"
import { useMediaQuery } from "react-responsive"

import Helmet from "react-helmet"

import { getAllItemsFromPaginatedResponse } from "services/helpers"
import ItemStatus from "components/common/ItemStatus/ItemStatus"
import { inviteStates } from "services/constants"
import { MarketplaceSupplier } from "services/types"
import { showError } from "services/toast"

interface ActionCellProps {
  rowData: MarketplaceSupplier
}

const SupplierMarketplace = () => {
  const [supplierData, setSupplierData] = useState({
    content: [],
  })
  const [addedSuppliers, setAddedSuppliers] = useState<any[]>()

  const [q, setQ] = useState("")
  const previousQ = usePrevious(q)
  const [paginationData, setPaginationData] = useState({
    page: 0,
    size: 20,
    totalPages: "",
    totalElements: "",
    numberOfElements: "",
  })

  const fromPromise = usePromise()
  const [loading, setLoading] = useState(false)
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })

  const ActionCell = ({ rowData }: ActionCellProps) => {
    const isAdded = addedSuppliers?.find(
      (supplier) => supplier.marketplaceSupplier?.id === rowData.id
    )

    const inviteState: { label: string; value: boolean; color: string } =
      inviteStates.filter((iS) => iS.value === !!isAdded)[0]

    return (
      <div
        data-tip={"Supplier has already been added"}
        data-for={"addMarketplaceSupplierTooltip"}
        className="flex"
      >
        <ItemStatus style={{ fontSize: "0.9rem" }} status={inviteState} />
      </div>
    )
  }

  const columns = [
    {
      key: "name",
      title: "Name",
      dataKey: "name",
      width: 250,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <Link
          to={rowData.id}
          className="font-sans text-sm font-semibold text-primaryBlue"
        >
          {rowData.name}
        </Link>
      ),
    },
    {
      key: "accountNumber",
      title: "Acc num",
      dataKey: "accountNumber",
      width: 150,
      flexGrow: 1,
      flexShrink: 0,
      className: "text-sm",
    },
    {
      key: "contactName",
      title: "Contact details",
      width: 150,
      dataKey: "contactName",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <div className="flex flex-col text-sm">
          <span className="block w-full">{rowData.contactName}</span>
        </div>
      ),
    },
    {
      key: "email",
      width: 200,
      title: "Email",
      dataKey: "email",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <a
          className="block text-sm text-primaryBlue font-normal w-full break-all"
          href={`mailto:${rowData.email}`}
        >
          {rowData.email}
        </a>
      ),
    },
    {
      key: "telephone",
      width: 60,
      title: "Phone number",
      dataKey: "telephone",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <a
          className="block text-sm text-primaryBlue font-normal w-full"
          href={`tel:${rowData.telephone}`}
        >
          {rowData.telephone}
        </a>
      ),
    },
    {
      title: "Added",
      key: "action",
      flexGrow: 1,
      flexShrink: 0,
      width: 80,
      cellRenderer: ({ rowData }) => <ActionCell rowData={rowData} />,
    },
  ]

  const getData = async () => {
    setLoading(true)

    const params: {
      page: number
      size: number
      sort: string
      partialName?: string
    } = {
      page: paginationData.page,
      size: paginationData.size,
      sort: "name,asc",
    }

    if (q) {
      params.partialName = q

      if (previousQ !== q) {
        params.page = 0
      }
    }

    // fromPromise prevents call on unmount of component
    const result = await fromPromise(getSuppliersFromMarketplace(params))

    const allSuppliers = await fromPromise(
      getAllItemsFromPaginatedResponse(getSuppliers)
    )

    setAddedSuppliers(allSuppliers)

    if (result?.message) {
      showError(result.message)
    } else if (result && !result.error) {
      setSupplierData({ ...supplierData, ...result })
      setPaginationData({
        ...paginationData,
        size: result.size,
        totalPages: result.totalPages,
        totalElements: result.totalElements,
        numberOfElements: result.numberOfElements,
      })
    }
    setLoading(false)
  }

  const handlePageClick = ({ selected }) => {
    setPaginationData({ ...paginationData, page: selected })
  }

  useEffect(() => {
    getData()
  }, [q, paginationData.page])

  return (
    <>
      <Helmet>
        <title>Supplier Marketplace</title>
      </Helmet>
      <div className="w-full flex flex-col flex-grow h-full overflow-hidden">
        <Header
          title="Supplier Marketplace"
          to={"/dashboard/purchases/suppliers"}
        />

        <div className="px-4 py-1 flex items-center justify-between flex-wrap border-b flex-shrink-0 lg:px-8">
          <div className="my-2 flex-grow mr-4 max-w-md">
            <SearchInput
              label="Search by name"
              placeholder="Search by name"
              className="form-control rounded"
              initialValue={""}
              onSearchChange={(val) => {
                setQ(val)
              }}
            />
          </div>
        </div>

        <div className="px-0 py-6 relative flex flex-col flex-grow w-full h-full overflow-scroll lg:px-8">
          <Loader
            isLoading={loading}
            style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
          >
            Loading suppliers..
          </Loader>

          <div className="w-full flex-grow">
            <AutoResizer height={Infinity}>
              {({ width }) => (
                <BaseTable
                  fixed={isTabletOrMobile ? true : false}
                  rowHeight={50}
                  width={width}
                  maxHeight={Infinity}
                  ignoreFunctionInColumnCompare={false}
                  data={supplierData.content}
                  columns={columns}
                  emptyRenderer={
                    <div className="flex items-center font-sansSemiBold font-semibold justify-center h-full text-xl text-center">
                      No suppliers found
                    </div>
                  }
                />
              )}
            </AutoResizer>
          </div>
          {supplierData.content.length > 0 && (
            <Pagination data={paginationData} onPageClick={handlePageClick} />
          )}
        </div>
      </div>
    </>
  )
}

export default SupplierMarketplace
