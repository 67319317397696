import React, { useEffect, useState } from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LineOptions,
} from "chart.js"
import { Line } from "react-chartjs-2"
import { getStockTakes } from "services/stock-take"
import { createGradient } from "services/chartHelpers"
import { ChartLegend } from "../DashboardPanel/DashboardPanel"
ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

interface StocktakeHistoryChartProps {
  orgValue: string
}

const StocktakeHistoryChart: React.FC<StocktakeHistoryChartProps> = ({
  orgValue,
}) => {
  const [dates, setDates] = useState<string[]>([])
  const [totalValues, setTotalValues] = useState<number[]>([])
  const [discrepancies, setDiscrepancies] = useState<number[]>([])
  const [totalItems, setTotalItems] = useState<number[]>([])

  const options: LineOptions = {
    // @ts-ignore <maintainAspectRatio> property is allowed - based on the docs
    maintainAspectRatio: false,
    clip: false,
    spanGaps: true,
    responsive: true,
    tension: 0.3,
    borderWidth: 1,
    plugins: {
      tooltip: {
        backgroundColor: "#fff",
        bodyColor: "#4E4E4E",
        borderColor: "#E5E5E5",
        borderWidth: 1,
        displayColors: false,
        padding: 15,
        bodyFont: {
          size: 10,
          family: "averta_peregular",
        },
        callbacks: {
          title: () => null,
          label: (context) => {
            return [
              `Date: ${dates[context.dataIndex]}`,
              `Total items: ${totalItems[context.dataIndex]}`,
              `Total value: ${totalValues[context.dataIndex]}`,
              `Discrepancy: ${discrepancies[context.dataIndex]}`,
            ]
          },
        },
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
          maxTicksLimit: 7,
          font: {
            size: 12,
            family: "averta_peregular",
          },
        },
      },
      y: {
        type: "linear",
        position: "left",
        grid: {
          drawOnChartArea: false,
          display: false,
        },
        ticks: {
          font: {
            size: 12,
            family: "averta_peregular",
          },
        },
      },
      y1: {
        type: "linear",
        position: "right",
        grid: {
          drawOnChartArea: false,
          display: false,
        },
        ticks: {
          font: {
            size: 12,
            family: "averta_peregular",
          },
        },
      },
    },
    hover: {
      mode: "nearest",
      intersect: false,
    },
  }

  const labels = dates.map((date) => date.slice(0, 5)) // Only show the day and month

  const data = {
    labels,
    datasets: [
      {
        label: "Total value",
        data: totalValues,
        borderColor: "#3EAFAE",
        pointBackgroundColor: "#3EAFAE",
        backgroundColor: createGradient,
        yAxisID: "y",
        fill: true,
      },
      {
        label: "Discrepancy",
        data: discrepancies,
        borderColor: "#F52525",
        pointBackgroundColor: "#F52525",
        yAxisID: "y1",
      },
    ],
  }

  useEffect(() => {
    getRecentStocktakes()
  }, [])

  const getRecentStocktakes = async () => {
    const response = await getStockTakes({
      status: "COMPLETED",
      sort: "createdAt,desc",
      size: 99,
      organizations: [orgValue],
    })

    getHistory(response.content)
  }

  const getHistory = (data) => {
    const oneYearAgo = new Date().setFullYear(new Date().getFullYear() - 1)

    const tempDates: string[] = []
    const tempTotalValues: number[] = []
    const tempDiscrepancies: number[] = []
    const tempTotalItems: number[] = []

    data?.map((item) => {
      if (
        new Date(item.stockTakeReport.createdAt).getTime() > oneYearAgo &&
        item.stockTakeReport.productsCount != 0 &&
        item.stockTakeReport.totalAmount != 0
      ) {
        const dateObject = new Date(item.stockTakeReport.createdAt)
        const day = dateObject.getDate()
        const month = dateObject.getMonth() + 1 // Month is zero-based, so add 1
        const year = dateObject.getFullYear()
        const formattedDay = day < 10 ? `0${day}` : day
        const formattedMonth = month < 10 ? `0${month}` : month
        const formattedYear = year.toString().slice(-2) // Extract the last two digits
        const formattedDate = `${formattedDay}-${formattedMonth}-${formattedYear}`

        tempDates.push(formattedDate)
        tempTotalValues.push(item.stockTakeReport.totalAmount)
        tempDiscrepancies.push(item.totalDiscrepancyAmount)
        tempTotalItems.push(item.stockTakeReport.productsCount)
      }
    })

    setDates(tempDates.reverse())
    setTotalValues(tempTotalValues.reverse())
    setDiscrepancies(tempDiscrepancies.reverse())
    setTotalItems(tempTotalItems.reverse())
  }

  return (
    <div className="w-full">
      <div>
        <Line options={options} data={data} />
      </div>

      <div className="mt-4">
        <ChartLegend datasets={data.datasets} direction="horizontal" />
      </div>
    </div>
  )
}

export default StocktakeHistoryChart
