import {
  faArrowRight,
  faCheck,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { updateProduct } from "services/product"
import { showError } from "services/toast"
import EditProduct from "../EditProduct/EditProduct"

const PreferredTag = () => {
  return (
    <span className="px-3 py-2 bg-green-500 rounded-full text-xs uppercase font-semibold text-white">
      <FontAwesomeIcon icon={faCheck} /> Preferred
    </span>
  )
}

const ButtonMakePreferred = ({ product, changePreferredProduct }) => {
  const handler = () => {
    changePreferredProduct(product)
  }

  return (
    <button
      onClick={handler}
      className="bg-primaryBlue rounded-full px-3 py-2 text-xs uppercase font-semibold text-white hover:bg-primaryGreen transition-colors"
    >
      <FontAwesomeIcon icon={faArrowRight} /> Mark as preferred
    </button>
  )
}

const PreferredSupplierSelector = ({
  data,
  onDataChange,
  selectedOption,
  changePreferredProduct,
}) => {
  const [opened, setopened] = useState(false)

  const onOptionClick = (value) => {
    onDataChange(value)
    setopened(false)
  }

  const options = data
  // const preferredOption = data.find(a => a.preferred);

  return (
    <div className="relative w-full flex-col flex px-8 bg-primaryBlueLighter py-4 text-white">
      <h2 className="mb-2">Editing item for supplier:</h2>
      <div className="flex">
        <div className="flex space-x-4 rounded-lg w-full items-center mt-2">
          <button
            className="cursor-pointer space-x-2"
            onClick={() => setopened(!opened)}
          >
            <span className="underline font-semibold">
              {selectedOption.supplierName || "Missing data"}
            </span>
            <FontAwesomeIcon icon={opened ? faChevronUp : faChevronDown} />
          </button>

          {selectedOption.preferred ? (
            <PreferredTag />
          ) : (
            <ButtonMakePreferred
              changePreferredProduct={changePreferredProduct}
              product={selectedOption}
            />
          )}
        </div>
      </div>
      <div className="relative z-50">
        {opened && (
          <div
            onMouseLeave={() => setopened(false)}
            className="absolute w-full shadow-lg top-0 left-0 rounded-xl p-3 text-black bg-white"
          >
            <ul className="flex flex-col p-3">
              {options.map((opt, idx) => {
                return (
                  <li
                    key={idx}
                    onClick={() => onOptionClick(opt)}
                    className="space-x-4 h-12 flex items-center hover:bg-gray-300 cursor-pointer"
                  >
                    <span className="font-semibold">{opt.supplierName}</span>
                    <span>
                      {opt.price} - {opt.size}
                      {opt.measure}
                    </span>
                    {opt.preferred && <PreferredTag />}
                  </li>
                )
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

interface EditPreferredProductProps {
  products: any
  fetchProducts: () => void
  onClose?: () => void
}

function EditPreferredProduct({
  products,
  fetchProducts,
  onClose,
}: EditPreferredProductProps) {
  //Locally Scoped
  const [localProducts, setLocalProducts] = useState(products)
  const [selectedOption, setselectedOption] = useState(
    localProducts.find((a) => a.preferred)
  )

  const changePreferredProduct = async (product) => {
    //returns updated single product with preferred flag
    const result = await updateProduct(product.id, {
      ...product,
      preferred: true,
    }).catch((err) => showError(err))

    if (result) {
      //Local scoped state
      //Required to perform this operation within the component since its opened with a function call so update to parent's props triggers no update
      setLocalProducts((prev) => {
        const temp = [...prev]

        const currentPreferred = temp.find((a) => a.preferred)
        currentPreferred.preferred = false

        const newPreferred = temp.find((a) => a.id === result.id)
        newPreferred.preferred = true

        return temp
      })
      fetchProducts()
    }
  }

  return (
    <div className="flex flex-col h-full overflow-auto">
      <PreferredSupplierSelector
        selectedOption={selectedOption}
        data={localProducts}
        onDataChange={setselectedOption}
        changePreferredProduct={changePreferredProduct}
      />
      <EditProduct
        initialValues={selectedOption}
        isCopy={false}
        onClose={onClose}
        onSubmitCallback={fetchProducts}
      />
    </div>
  )
}

export default EditPreferredProduct
