import React, { useState, useEffect } from "react"
import { usePromise, usePrevious } from "react-use"
import { getSuppliers } from "services/supplier"
import SearchInput from "components/forms/SearchInput"
import Loader from "components/common/Loader/Loader"
import PropTypes from "prop-types"
import * as styles from "./SupplierSelect.module.css"

const SupplierSelect = ({
  onSelect,
  noSupplierAllowed = false,
  selected = null,
}) => {
  const fromPromise = usePromise()
  const [supplier, setSupplier] = useState("")
  const [loading, setLoading] = useState(false)
  const [q, setQ] = useState("")
  const previousQ = usePrevious(q)
  const previousSupplier = usePrevious(supplier)
  const [supplierData, setSupplierData] = useState({
    content: [],
  })

  const handleSearch = (query) => {
    setQ(query)
  }

  const getData = async () => {
    setLoading(true)

    let params = {
      size: 999,
      sort: "name,asc",
    }

    if (q) {
      params.partialName = q
    }

    // fromPromise prevents call on unmount of component
    const result = await fromPromise(getSuppliers(params))

    if (result && !result.error) {
      if (noSupplierAllowed) {
        result.content.push({
          name: "No Supplier",
          id: "no-supplier-id",
        })
      }
      setSupplierData({ ...supplierData, ...result })
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (previousSupplier || supplier) {
      onSelect(supplier)
    }
  }, [supplier])

  useEffect(() => {
    if (previousQ || q) {
      getData()
    }
  }, [q])

  return (
    <div className={styles.container}>
      <div className="py-4 px-4 lg:px-0 border-b bg-white lg:sticky top-0 z-10">
        <SearchInput
          className="form-control form-control--first form-control--last"
          label="Search suppliers by name"
          name="supplierName"
          placeholder="Search by name"
          onSearchChange={handleSearch}
        />
      </div>
      <div className="relative">
        <Loader
          isLoading={loading}
          style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
        >
          Loading suppliers..
        </Loader>
        {supplierData.content.map((supplier, index) => (
          <div key={index} className={styles.supplierItem}>
            <label className="flex items-center justify-stretch">
              <input
                type="radio"
                className="form-radio text-primaryBlue focus:shadow-none focus:border-0"
                name="supplier"
                checked={selected && selected.id === supplier.id}
                onChange={() => {
                  setSupplier(supplier)
                }}
                value={supplier}
              />
              <span className="ml-3 flex-grow">{supplier.name}</span>
            </label>
          </div>
        ))}
      </div>
    </div>
  )
}

SupplierSelect.propTypes = {
  onSelect: PropTypes.func,
  selected: PropTypes.any,
}

export default SupplierSelect
