import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef,
} from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons"
import Modal from "react-modal"
import {
  getProductByBarcode,
  getGlobalProductByBarcode,
} from "services/product"
import BarcodeScanner from "components/scanner/BarcodeScanner/BarcodeScanner"
import ProductSelect from "components/common/ProductSelect/ProductSelect"
import ProductNotFound from "components/stock/ProductNotFound/ProductNotFound"
import EditProduct from "components/suppliers/EditProduct/EditProduct"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import PropTypes from "prop-types"
import { AsideContext } from "context/AsideContext"
import { ModalContext } from "context/ModalContext"
import classNames from "classnames/bind"
import * as styles from "./ProductInput.module.css"

const cx = classNames.bind(styles)

const newProduct = {
  barcode: null,
  category: null,
  description: null,
  measure: null,
  size: null,
  subCategory: null,
  id: null,
  posId: null,
  notes: null,
  name: null,
  price: null,
  code: null,
  unit: null,
  supplierId: null,
  supplierName: null,
  productCase: null,
}

const ProductInput = ({ onRequestClose, barcodeUpdate, ...otherProps }) => {
  const scannerRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [barcode, setBarcode] = useState("")
  const [notFound, setNotFound] = useState(false)
  const [currentStep, setCurrentStep] = useState(1)
  const [currentProduct, setCurrentProduct] = useState(null)

  const modal = useContext(ModalContext)
  const aside = useContext(AsideContext)

  const productIsInUser = useCallback(async (barcodeResult) => {
    const { barcodeText } = barcodeResult

    pauseScanning()

    if (barcodeText) {
      setBarcode(barcodeText)
      setCurrentStep(2)
      setLoading(true)

      // Get product by barcode here
      const result = await getProductByBarcode(barcodeText)

      // Product not found
      if (result.status === 404) {
        await productIsInGlobal(barcodeText)
        setLoading(false)
        return
      }

      // Product found
      if (result) {
        setCurrentProduct({ ...newProduct, ...result })
        setLoading(false)
        setCurrentStep(3)
        startCreateProduct({ ...newProduct, ...result })
      }
    } else {
      setNotFound(true)
      return
    }
  }, [])

  const productIsInGlobal = useCallback(async (barcodeText) => {
    pauseScanning()
    setLoading(true)

    // Get global product by barcode here
    const result = await getGlobalProductByBarcode(barcodeText)

    // Global product not found
    if (result.status === 404) {
      setNotFound(true)
      setLoading(false)
      return
    }

    // Global product found
    if (result) {
      setCurrentProduct({ ...newProduct, ...result })
      setLoading(false)
      setCurrentStep(3)
      startCreateProduct({ ...newProduct, ...result })
    }
  }, [])

  const onBarcodeUpdate = useCallback(async (barcodeResult) => {
    const { barcodeText } = barcodeResult

    pauseScanning()

    if (barcodeText) {
      modal.showModal(ConfirmModal, {
        type: "success",
        title: "Replace barcode",
        text: "Do you want to replace the existing barcode?",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        onConfirm: () => {
          barcodeUpdate(barcodeText)
          onRequestClose()
        },
        onClose: () => resumeScanning(),
      })
    } else {
      return
    }
  }, [])

  useEffect(() => {
    if (currentProduct) {
      setCurrentStep(3)
    }
  }, [currentProduct, setCurrentStep])

  const resumeScanning = () => {
    setNotFound(false)
    setCurrentStep(1)
    setCurrentProduct(null)
    setBarcode(null)

    if (scannerRef.current) {
      scannerRef.current.resume()
    }
  }

  const pauseScanning = () => {
    if (scannerRef.current) {
      scannerRef.current.pause()
    }
  }

  const handleProductSelect = async (product) => {
    setCurrentProduct({ ...newProduct, ...product })
    setLoading(false)
    setCurrentStep(3)
    startCreateProduct({ ...newProduct, ...product })
  }

  const startCreateProduct = (product) => {
    const values = { ...product, generateBarcode: false }

    aside.showAside(EditProduct, {
      headerText: "Create Product",
      initialValues: values,
      createProduct: true,
    })

    onRequestClose()
  }

  let content = (
    <BarcodeScanner
      ref={scannerRef}
      onInit={() => setLoading(false)}
      onReadSuccess={!barcodeUpdate ? productIsInUser : onBarcodeUpdate}
      blur={currentStep === 3 || currentStep === 4}
      onAddManually={() =>
        !barcodeUpdate ? setCurrentStep(4) : onRequestClose()
      }
    />
  )

  return (
    <Modal
      isOpen
      style={{ content: { bottom: "40px", border: 0, overflow: "hidden" } }}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={!loading}
      shouldCloseOnEsc={!loading && currentStep !== 3 && currentStep !== 4}
      {...otherProps}
    >
      <div className={styles.container}>
        <div className={styles.navBar}>
          <nav className={cx("header", "transparent")}>
            <button onClick={onRequestClose}>
              <FontAwesomeIcon icon={faArrowLeft} className={styles.backIcon} />
            </button>

            <div className={styles.headerMain}>
              <h2 className={styles.title}>
                {!barcodeUpdate ? "Add new product" : "Scan new barcode"}{" "}
              </h2>
            </div>

            <div className={styles.placeHolder}></div>
          </nav>
        </div>

        <div className={cx("content", "step" + currentStep)}>
          {content}

          {(currentStep === 3 || currentStep === 4 || notFound) && (
            <div className={styles.clickArea} onClick={resumeScanning}></div>
          )}

          <div className={cx("notFound", { show: notFound })}>
            <ProductNotFound
              onResume={resumeScanning}
              onAddManually={() => {
                setNotFound(false)
                setCurrentStep(4)
              }}
              onAddItem={() =>
                startCreateProduct({ ...newProduct, barcode: barcode })
              }
            />
          </div>

          <div className={styles.productSelectContainer}>
            <div className={styles.productSelect}>
              <div className="h-full overflow-hidden flex flex-col bg-white">
                <div className="px-6 pt-4 pb-0 flex items-center">
                  <button
                    className="text-primaryBlue mr-4"
                    onClick={resumeScanning}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </button>
                  <h2 className="text-xl text-primaryBlue">Search a product</h2>
                </div>
                <div className="flex-grow flex flex-col overflow-auto relative">
                  <ProductSelect
                    onSelect={handleProductSelect}
                    onAddItem={() => productIsInGlobal(barcode)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

ProductInput.propTypes = {
  onRequestClose: PropTypes.func,
  barcodeUpdate: PropTypes.func,
}

export default ProductInput
