import React, { useContext, useState, useRef } from "react"
import { Link } from "gatsby"
import { useMediaQuery } from "react-responsive"
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as styles from "./VenueSwitch.module.css"
import { useClickAway } from "react-use"

import { GlobalStateContext } from "context/GlobalContextProvider"
import classNames from "classnames/bind"
import VenueSwitchPopup from "../VenueSwitchPopup/VenueSwitchPopup"
import Label from "components/common/Label/Label"

const cx = classNames.bind(styles)

const VenueSwitch = () => {
  const { organization, organizations, organizationPermissions } =
    useContext(GlobalStateContext)
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
  const [popupVisible, setPopupVisible] = useState(false)

  const clickAwayRef = useRef(null)

  useClickAway(clickAwayRef, () => {
    closePopup()
  })

  const closePopup = () => {
    setPopupVisible(false)
  }

  const isMainAccount = organizationPermissions?.general?.isMain

  return (
    <div ref={clickAwayRef}>
      <button
        className={cx("container", {
          hasOptions: organizations?.length > 1,
          containerMobile: isTabletOrMobile,
        })}
        onClick={() => {
          if (organizations?.length > 1) {
            setPopupVisible(!popupVisible)
          }
        }}
      >
        <div className={styles.selectedItem}>
          <span className="font-medium font-sansSemiBold whitespace-nowrap mb-1 w-full flex justify-between items-center">
            {organization ? (
              <span className="flex items-center">
                <span className="mr-2">{organization.companyName}</span>
                {isMainAccount && <Label type="success">MAIN</Label>}
                {organization.isDemoAccount && (
                  <Label type="danger">DEMO</Label>
                )}
              </span>
            ) : (
              "Unknown company"
            )}

            {organizations?.length > 1 && (
              <FontAwesomeIcon
                size="xs"
                icon={faChevronDown}
                rotation={popupVisible ? 180 : 0}
              />
            )}
          </span>
          <div className={styles.companyAddress}>
            {organization ? (
              <span className="text-sm block opacity-75">
                {organization.address?.city}
              </span>
            ) : (
              <Link
                to="/dashboard/welcome"
                className="text-sm block text-primaryPink"
              >
                Complete profile
              </Link>
            )}
          </div>
        </div>
      </button>
      <VenueSwitchPopup closePopup={closePopup} isOpen={popupVisible} />
    </div>
  )
}

export default VenueSwitch
