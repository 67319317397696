import React, { useContext, useEffect, useState } from "react"
import { createApiKey, getApiKey } from "services/organization"
import Loader from "components/common/Loader/Loader"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { showSuccess, showError } from "services/toast"
import OrganizationApiKeyAuthItem from "./OrganizationApiKeyAuthItem"
import { faKey } from "@fortawesome/pro-regular-svg-icons"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import { ModalContext } from "context/ModalContext"

interface ApiKeyAuth {
  apiKey: string
  id: string
  orgId: string
}

const OrganizationApiKeyAuth = () => {
  const modal = useContext(ModalContext)
  const [apiKeyAuth, setApiKeyAuth] = useState<ApiKeyAuth>({
    apiKey: "",
    id: "",
    orgId: "",
  })
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    getApi()
  }, [])

  const getApi = async () => {
    setLoading(true)
    const res = await getApiKey()

    if (res) {
      setApiKeyAuth(res)
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const generateApiKey = async () => {
    const res = await createApiKey()

    if (res.status !== 400) {
      setApiKeyAuth(res)
      showSuccess(res.message || "API key generated")
    } else {
      showError(res.message || "Could not generate API key")
    }
  }

  return (
    <div>
      <Loader
        isLoading={loading}
        style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
      >
        Loading API key...
      </Loader>

      <div className="space-y-4 w-max">
        {apiKeyAuth.apiKey && (
          <div className="grid grid-cols-3 gap-x-4">
            <OrganizationApiKeyAuthItem
              token={apiKeyAuth.orgId}
              label="Organization ID"
            />
            <OrganizationApiKeyAuthItem
              token={apiKeyAuth.apiKey}
              label="API key"
            />
          </div>
        )}

        {!apiKeyAuth.apiKey && (
          <button
            type="button"
            className="button button--primaryGreen button--autoWidth"
            onClick={() => {
              if (apiKeyAuth.apiKey) {
                // @ts-ignore
                modal.showModal(ConfirmModal, {
                  // @ts-ignore
                  onRequestClose: modal.hideModal(),
                  type: "danger",
                  title: "Are you sure?",
                  text: "After generating a new API key, the current key will be lost.",
                  confirmButtonText: "Generate new key",
                  onConfirm: () => generateApiKey(),
                })
              } else {
                generateApiKey()
              }
            }}
          >
            <FontAwesomeIcon icon={faKey} className="mr-2" />
            {apiKeyAuth.apiKey ? "Generate new key" : "Generate"}
          </button>
        )}
      </div>
    </div>
  )
}

export default OrganizationApiKeyAuth
