import React, { useState, useEffect, useContext } from "react"
import { usePromise, usePrevious } from "react-use"
import {
  getSentStockTransfersByCriteria,
  getReceivedStockTransfersByCriteria,
} from "services/stock-transfer"
import Helmet from "react-helmet"

import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useMediaQuery } from "react-responsive"
import { useLocation, navigate } from "@reach/router"
import * as queryString from "query-string"
import classNames from "classnames"
import Header from "components/dashboard/Header/Header"
import FilterSelect from "components/forms/FilterSelect"
import StockTransferVenueSelectModal from "../StockTransferVenueSelectModal/StockTransferVenueSelectModal"
import StockTransfer from "../StockTransfer/StockTransfer"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/GlobalContextProvider"
import { ModalContext } from "context/ModalContext"
import * as styles from "./StockTransfers.module.css"
import {
  stockTransferStates,
  stockTransferStatesIncoming,
} from "services/constants"
import StockTransferItem from "../StockTransferItem/StockTransferItem"
import usePermissions from "hooks/usePermissions"
import { faFileDownload } from "@fortawesome/pro-light-svg-icons"
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-duotone-svg-icons"
import usePagination from "components/base/hooks/usePagination"
import BaseList from "components/baseList/BaseList"
import {
  getTransfersReceivedDummy,
  getTransfersSendDummy,
} from "services/dummy"

const StockTransfers = () => {
  const location = useLocation()
  const modal = useContext(ModalContext)
  const { organization, organizations } = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  const [loading, setLoading] = useState(false)
  const fromPromise = usePromise()
  const [stockTransfersData, setStockTransfersData] = useState({
    content: [],
  })
  const [status, setStatus] = useState(null) as any
  const previousStatus = usePrevious(status) as any
  const permissionObj = usePermissions("Transfers")
  const pagination = usePagination()
  const previousPage = usePrevious(pagination.page)

  const [activeTab, setActiveTab] = useState("")
  const previousTab = usePrevious(activeTab)

  const [actionRequired, setActionRequired] = useState(false)

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })

  const getData = async () => {
    setLoading(true)
    const params = {
      page: pagination.page,
      sort: "createdAt,desc",
    } as any

    if (status) {
      params.status = status.value
    }

    let result
    const sentResult = await fromPromise(
      getSentStockTransfersByCriteria(params)
    )
    const receivedResult = await fromPromise(
      getReceivedStockTransfersByCriteria(params)
    )

    for (let i = 0; i < receivedResult.content?.length; i++) {
      if (receivedResult.content[i].status === "SENT") {
        setActionRequired(true)
        break
      }
    }

    switch (activeTab) {
      case "out":
        result = sentResult
        break
      case "in":
        result = receivedResult
        break
      default:
        result = sentResult
    }

    if (result && !result.error) {
      setStockTransfersData({ ...stockTransfersData, ...result })
      pagination.setFromResult(result)
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const openStockTransferVenueSelectModal = () => {
    modal.showModal(StockTransferVenueSelectModal, {
      organization: organization,
      organizations: organizations,
      onSelect: openStockTransfer,
    })
  }

  const openStockTransfer = (data) => {
    dispatch({ type: "RESET_NEW_STOCK_TRANSFER" })
    dispatch({
      type: "CREATE_NEW_STOCK_TRANSFER",
      payload: {
        stockTransferData: data,
      },
    })
    modal.showModal(StockTransfer, { onUpdate: () => getData() })
  }

  useEffect(() => {
    const queryParams = queryString.parse(location.search)
    if (queryParams.tab) {
      setActiveTab(queryParams.tab)
    } else {
      setActiveTab("out")
    }
  }, [])

  useEffect(() => {
    if (previousTab !== undefined && activeTab !== previousTab) {
      getData()
    }
  }, [activeTab])

  useEffect(() => {
    if (previousPage !== undefined && pagination.page !== previousPage) {
      getData()
    }
  }, [pagination.page])

  useEffect(() => {
    if (previousStatus || status) {
      getData()
    }
  }, [status])

  return (
    <>
      <Helmet>
        <title>Transfers</title>
      </Helmet>

      <div className={styles.container}>
        <Header title="Transfers" />
        <div className={styles.subHeader}>
          <div className="flex items-center flex-wrap gap-x-4 w-full">
            <div className={styles.switcher}>
              <button
                className={classNames(
                  styles.switcherButton,
                  styles.switcherButtonOut,
                  {
                    [styles.activeSwitch]: activeTab === "out",
                  }
                )}
                type="button"
                onClick={() => {
                  if (activeTab !== "out") {
                    navigate("?tab=out")
                  }
                }}
              >
                Out
              </button>
              <button
                className={classNames(
                  styles.switcherButton,
                  styles.switcherButtonIn,
                  {
                    [styles.activeSwitch]: activeTab === "in",
                  }
                )}
                type="button"
                onClick={() => {
                  if (activeTab !== "in") {
                    navigate("?tab=in")
                  }
                }}
              >
                Incoming
                {actionRequired && (
                  <span className={styles.actionIndicator}></span>
                )}
              </button>
            </div>

            <div className="w-56">
              <FilterSelect
                options={
                  activeTab === "out"
                    ? stockTransferStates
                    : stockTransferStatesIncoming
                }
                value={status && status.value}
                onChange={(val) => {
                  setStatus(val)
                }}
                placeholder="Status: all"
                isClearable={true}
                isSearchable={isTabletOrMobile ? false : true}
                className="my-2 lg:my-0 mr-4 md:mr-6 w-full"
              />
            </div>

            <div className="ml-auto">
              <button
                className="button my-2 ml-auto mr-2 button--autoWidth button--primaryGreen flex-shrink-0"
                onClick={(e) => {
                  e.preventDefault()
                  openStockTransferVenueSelectModal()
                }}
                disabled={!permissionObj?.permissions.modify}
              >
                <FontAwesomeIcon icon={faPlus} className="lg:mr-2" />
                <span className="hidden lg:inline">New transfer</span>
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  navigate("/dashboard/stock/transfers/export")
                }}
                className="text-center my-2 button button--autoWidth no-truncate button--primaryGreen order-last"
                disabled={!permissionObj?.permissions.read}
              >
                <FontAwesomeIcon icon={faFileDownload} />
              </button>
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <BaseList
            title="Transfers"
            id="transfers"
            loading={loading}
            pagination={pagination}
            empty={
              activeTab == "in"
                ? {
                    icon: faArrowLeft,
                    color: "green",
                    getDummy: () => getTransfersReceivedDummy,
                  }
                : {
                    icon: faArrowRight,
                    color: "pink",
                    getDummy: () => getTransfersSendDummy,
                  }
            }
            data={stockTransfersData.content}
            rowRender={(item) => (
              <StockTransferItem
                data={item}
                direction={activeTab}
                onDeleted={() => getData()}
                onUpdated={() => getData()}
                key={item.id}
              />
            )}
          />
        </div>
      </div>
    </>
  )
}

export default StockTransfers
