import React, { useContext, useState, useEffect } from "react"
import classNames from "classnames/bind"
import { formatDistance, format } from "date-fns"
import { getPaymentHistory } from "services/payment"
import { formatCurrencyValue } from "services/helpers"
import { getStripePublicKey } from "services/payment"
import { paymentHistoryStates, paymentPlans } from "services/constants"
import { GlobalStateContext } from "../../../context/GlobalContextProvider"

import { ModalContext } from "context/ModalContext"
import SupportTicketModal from "components/common/SupportTicketModal/SupportTicketModal"
import OrganizationPlanNew from "../OrganizationPlan/OrganizationPlanNew"
import RadioGroup from "components/forms/RadioGroup"

import * as styles from "./OrganizationPlanSelect.module.css"

import RequestCounter from "components/common/RequestCounter/RequestCounter"
import usePaymentPlanExpirationData from "hooks/usePaymentPlanExpirationData"
import OrganizationPlanAddons from "./OrganizationPlanAddons"
import StripeModal from "components/common/StripeModal/StripeModal"
import BaseTable, { AutoResizer } from "react-base-table"
import { useMediaQuery } from "usehooks-ts"
import { Link } from "gatsby"
import FormDropdown from "components/forms/FormDropdown"
import ItemStatus from "components/common/ItemStatus/ItemStatus"
import OrganizationPlanComparisonV2 from "./OrganizationPlanComparisonV2"
const cx = classNames.bind(styles)

const OrganizationPlanSelect = () => {
  const { paymentPlan, user } = useContext(GlobalStateContext)
  const modal = useContext(ModalContext)
  const [selectedTerm, setSelectedTerm] = useState("annual")
  const [showPlans, setShowPlans] = useState([])
  const [stripeKey, setStripeKey] = useState(null)
  const [paymentHistory, setPaymentHistory] = useState([])
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
  const paymentPlanData = usePaymentPlanExpirationData()

  // Note: for testing subscribtions
  /*
  useEffect(() => {
    cancelCustomerSubscription() // delete the current subscription
    updatePaymentPlan({
      orgId: "656f393c0542e2123b302952",
      type: "FREEMIUM",
      validDays: 30,
    })
  }, [])
  */

  // using ref instead of state because the stripe modulue is not native react (looses state in callback)
  // see https://stackoverflow.com/questions/57847594/react-hooks-accessing-up-to-date-state-from-within-a-callback

  const hasNoSubscription =
    !paymentPlan.stripeSubscriptionId || paymentPlan.type === "DEFAULT"
  const hasSubscription = !hasNoSubscription

  const handlePaymentHistory = async () => {
    try {
      const response = await getPaymentHistory({ email: user.username })
      setPaymentHistory(() =>
        response.length
          ? response.map((historyPlan) => ({
              id: `${historyPlan.date}`,
              name: user.firstName + " " + user.lastName,
              ...historyPlan,
            }))
          : []
      )
    } catch (e) {
      setPaymentHistory([])
    }
  }
  const requestPlanViaSupportTicket = (plan) => {
    openSupportTicketModal({
      title: "Enquire now",
      intro: `You'd like to learn more about our ${plan.label} plan? Drop your quesitons below and let's arrange quick chat.`,
      subject: `I like to switch to the ${plan.label} plan...`,
      category: "inquiries",
      hideFileUpload: true,
    })
  }

  const columns = [
    {
      key: "name",
      title: "Name",
      dataKey: "name",
      width: 150,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <Link
          to={rowData.id}
          className="font-sans text-sm font-semibold text-primaryBlue"
        >
          {rowData.name}
        </Link>
      ),
    },
    {
      key: "date",
      title: "Subscription Date",
      dataKey: "date",
      width: 150,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => {
        if (rowData.date) {
          const date = new Date(rowData.date * 1000 || null)
          return <span>{format(date, "yyyy-MM-dd")}</span>
        } else {
          return <span>-</span>
        }
      },
    },
    {
      key: "status",
      title: "Status",
      dataKey: "status",
      width: 100,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => {
        const paymentHistoryState = paymentHistoryStates.find(
          (oS) => oS.value === rowData.status
        )
        return <ItemStatus status={paymentHistoryState} />
      },
    },
    {
      key: "invoice",
      title: "Invoice",
      dataKey: "invoiceLink",
      width: 200,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => {
        return rowData.invoiceLink ? (
          <a
            href={rowData.invoiceLink}
            target="_blank"
            className="text-primaryBlue hover:text-blue-800 font-semibold"
            rel="noopener noreferrer"
          >
            View Invoice
          </a>
        ) : (
          <span>No Invoice</span>
        )
      },
    },
  ]
  const currency = {
    pref: "£",
    code: "GBP",
    name: "Great Britsh Pound",
  }

  const priceTermsSuffix = {
    annual: "/mo",
    monthly: "/mo",
  }

  useEffect(() => {
    configAvailablePlans()
  }, [selectedTerm])

  useEffect(() => {
    configPaymentPlan(paymentPlan)
    configAvailablePlans()
  }, [paymentPlan])

  useEffect(() => {
    async function fetchKey() {
      if (!stripeKey) {
        const key = await getStripePublicKey()
        setStripeKey(key)
      }
    }
    fetchKey()
  }, [])
  const handleOpenStripeModal = (props) => {
    modal.showModal(StripeModal, { ...props, handlePaymentHistory })
  }

  useEffect(() => {
    handlePaymentHistory()
  }, [])

  const configAvailablePlans = () => {
    let plans = paymentPlans
      .filter((plan) => plan.showOnBillingpage)
      .map(function (plan) {
        let planTerm = plan?.pricing?.[selectedTerm] ?? null
        let price = planTerm?.price ?? null
        let price_monthly = planTerm?.price_monthly ?? null
        let priceLabel =
          price_monthly !== false
            ? currency.pref +
              formatCurrencyValue(price_monthly / 100) +
              priceTermsSuffix[selectedTerm]
            : ""
        let userHasThisPlan =
          (!plan.needsSubscriptionId || paymentPlan.stripeSubscriptionId) &&
          plan.values.includes(paymentPlan.type)

        let callback = false
        switch (plan?.btnAction) {
          case "support":
            callback = requestPlanViaSupportTicket
            break
          case "book-demo":
            callback = gotoBookDemoPage
            break
          case "stripe-payment":
            callback = handleOpenStripeModal
            break
        }

        let btnText = plan.btnText
        if (paymentPlan.type == "FREEMIUM" && plan.keyInCode == "PRO") {
          btnText = plan.btnTextUpgrade
        }

        return {
          ...plan,
          btnText: btnText,
          price: price,
          priceLabel: plan?.priceLabel ?? priceLabel,
          stripePriceId: planTerm?.stripePriceId ?? "",
          userHasThisPlan: userHasThisPlan,
          btnCallback: callback,
        }
      })
    setShowPlans(plans)
  }

  const getPlanByType = (type) => {
    return paymentPlans.find((p) => p.values.indexOf(type) > -1)
  }

  const configPaymentPlan = (paymentPlan) => {
    let label = paymentPlan.type
    let foundPlanLabel = getPlanByType(paymentPlan.type)?.label
    let paymentInterval = paymentPlan?.stripePaymentRecurring?.interval
    paymentPlan.label = foundPlanLabel ?? label
    paymentPlan.switchTo = paymentInterval
      ? paymentInterval === "year"
        ? "monthly"
        : "annual"
      : ""
    paymentPlan.expirationDateFormatted = format(
      new Date(paymentPlan.expirationDate),
      "dd-MM-yyyy"
    )
    paymentPlan.expirationDateDistance = formatDistance(
      new Date(paymentPlan.expirationDate),
      new Date()
    )
  }

  const openSupportTicketModal = (params) => {
    modal.showModal(SupportTicketModal, params)
  }

  const gotoBookDemoPage = () => {
    window.open("https://www.growyze.com/book-a-demo", "_blank")
  }

  const getActivePaymentTerm = (plan) => {
    if (!hasSubscription) {
      return null
    }

    if (
      plan.type !== "ENTERPRISE" &&
      plan.type !== "ENTERPRISE_PLUS_SCANNING"
    ) {
      return null
    }

    let planData = getPlanByType(plan.type)
    switch (paymentPlan.stripePaymentRecurring?.interval) {
      case "year":
        return (
          currency.pref +
          `${formatCurrencyValue(planData.pricing.annual.price / 100)} / year`
        )
      case "month":
        return (
          currency.pref +
          `${formatCurrencyValue(planData.pricing.monthly.price / 100)} / month`
        )
      default:
        return ""
    }
  }

  const changeTerm = () => {
    openSupportTicketModal({
      subject: `Switch to ${paymentPlan.switchTo} payment plan`,
    })
  }

  return (
    <div className="">
      {/* If  no active subscription show expiration message */}

      <div className={cx("cancelPlan")}>
        <div className={`p-4 w-full ${hasSubscription ? "border-b" : ""}`}>
          <h2 className="text-xl mb-4 text-primaryBlue">Current plan</h2>
          <h3 className="font-sansSemiBold font-semibold mb-2">
            <span className="uppercase">{paymentPlan.label}</span>{" "}
            {getActivePaymentTerm(paymentPlan) ? "-" : ""}{" "}
            <span className="font-normal normal-case font-sans mr-6">
              {getActivePaymentTerm(paymentPlan)}
            </span>
            {!paymentPlan?.stripeSubscriptionId &&
              paymentPlan?.type !== "FREEMIUM" &&
              paymentPlanData.timeLeftInTotal !== null &&
              paymentPlanData.timeLeftInDays <= 30 && (
                <span
                  className={`text-gray-600 text-sm normal-case text-right ${
                    paymentPlanData.timeLeftInDays > 10
                      ? "text-primaryGreen"
                      : "text-primaryPink"
                  }`}
                >
                  {paymentPlanData.timeLeftInTotal > 0
                    ? `expires ${paymentPlanData.formattedTimeLeft}`
                    : `0 days left`}
                </span>
              )}
            {paymentPlan.stripePaymentRecurring && (
              <a
                href=""
                className="text-primaryGreen font-sansSemiBold font-semibold"
                onClick={(e) => {
                  e.preventDefault()
                  changeTerm()
                }}
              >
                Switch to {paymentPlan.switchTo}
              </a>
            )}
          </h3>
          {paymentPlan.type === "FREEMIUM" && (
            <button
              onClick={() =>
                modal.showModal(RequestCounter, {
                  onRequestClose: modal.hideModal,
                })
              }
              className={`text-primaryGreen text-sm normal-case text-right hover:underline rounded-lg font-semibold font-sansSemiBold`}
            >
              What&apos;s included?
            </button>
          )}
        </div>

        {hasSubscription && (
          <button
            onClick={() =>
              openSupportTicketModal({ subject: "Cancel my plan" })
            }
            className="button button--transparent button--autoWidth"
            style={{ color: "#454545" }}
          >
            Cancel plan
          </button>
        )}
      </div>
      <div className="mt-8">
        <FormDropdown
          buttonText="Payment History"
          customPadding="p-4"
          labelStyle="text-xl mb-4 text-primaryBlue"
          fullWidth={true}
        >
          <div className="w-full flex-grow">
            <AutoResizer height={Infinity}>
              {({ width }) => (
                <BaseTable
                  className={"paymentHistory"}
                  fixed={isTabletOrMobile ? true : false}
                  rowHeight={50}
                  width={width}
                  maxHeight={Infinity}
                  ignoreFunctionInColumnCompare={false}
                  data={paymentHistory}
                  columns={columns}
                  emptyRenderer={
                    <div className={styles.noSuppliers}>No History Plan</div>
                  }
                />
              )}
            </AutoResizer>
          </div>
        </FormDropdown>
      </div>

      <div className="border-t mt-14 -mx-4 lg:-mx-8"></div>

      <div className="py-4 container mx-auto">
        <div className="flex flex-wrap w-full items-center justify-around py-4">
          <h2 className="text-xl my-4 text-primaryBlue w-full text-center">
            Select your plan
          </h2>
          <RadioGroup
            name={"selected"}
            className="mx-auto mb-4"
            value={selectedTerm}
            onChange={(val) => setSelectedTerm(val)}
            options={[
              { id: "monthly", value: "monthly", label: <>Monthly</> },
              {
                id: "annual",
                value: "annual",
                label: (
                  <>
                    Annual <small>- save 15%</small>
                  </>
                ),
              },
            ]}
          />
        </div>
        <div className="block w-full container mx-auto">
          {paymentPlan && (
            <div className={styles.plans}>
              {showPlans.map((plan, index) => (
                <OrganizationPlanNew {...plan} key={index} />
              ))}
            </div>
          )}
        </div>
      </div>
      <OrganizationPlanAddons />
      <OrganizationPlanComparisonV2
        proAction={gotoBookDemoPage}
        premiumAction={() => {
          requestPlanViaSupportTicket(showPlans[1])
        }}
      />
    </div>
  )
}

export default OrganizationPlanSelect
