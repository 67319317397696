import React, { useContext, useEffect } from "react"
import { useMatch } from "@reach/router"
import PropTypes from "prop-types"
import { navigate } from "@reach/router"
import {
  faArrowLeft,
  faBell as faRegularBell,
  faCircle,
} from "@fortawesome/pro-solid-svg-icons"
import { faShoppingCart } from "@fortawesome/pro-duotone-svg-icons"
import { faStream } from "@fortawesome/pro-regular-svg-icons"
import { faComment } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames/bind"
import { paymentPlans } from "services/constants"
import Helmet from "react-helmet"
import { useMediaQuery } from "react-responsive"
import { countCompletedSteps, calculateProgress } from "services/helpers"
import { getStartedSteps, getNextSteps } from "services/constants"
import QuickActionDropdown from "components/dashboard/QuickActionDropdown/QuickActionDropdown"
import UserDropdown from "components/dashboard/UserDropdown/UserDropdown"
import VideoHelpButton from "components/dashboard/VideoHelpButton/VideoHelpButton"
import ProgressBar from "components/common/ProgressBar/ProgressBar"
import Rocket from "inline-svg/rocket.svg"
import Logo from "inline-svg/growyze-icon.svg"
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "../../../context/GlobalContextProvider"
import ProductsGlobalOrderOverview from "./../../products/ProductsGlobalOrderOverview/ProductsGlobalOrderOverview"

import * as styles from "./Header.module.css"
import { AsideContext } from "context/AsideContext"
import AsideNotificationsAndActions from "components/common/AsideNotificationsAndActions/AsideNotificationsAndActions"
import { ModalContext } from "context/ModalContext"
import RequestCounter from "components/common/RequestCounter/RequestCounter"
import { Link } from "gatsby"
import usePaymentPlanExpirationData from "hooks/usePaymentPlanExpirationData"

const cx = classNames.bind(styles)

const Header = ({ title, back, to }) => {
  const modal = useContext(ModalContext)
  const { organization, paymentPlan, onboardingSteps, globalOrder, user } =
    useContext(GlobalStateContext)
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
  const dispatch = useContext(GlobalDispatchContext)
  const paymentPlanData = usePaymentPlanExpirationData()
  const freshWorksUrl = process.env.GATSBY_FRESH_WORK_URL

  const currentPlan =
    paymentPlan &&
    paymentPlans.find((p) => p.values.indexOf(paymentPlan.type) > -1)
      ? paymentPlans.find((p) => p.values.indexOf(paymentPlan.type) > -1)
      : null

  const firstOnboardingSteps = getStartedSteps.map((step) => {
    return {
      ...step,
      checked: onboardingSteps && onboardingSteps[step.name],
    }
  })

  const secondOnboardingSteps = getNextSteps.map((step) => {
    return {
      ...step,
      checked: onboardingSteps && onboardingSteps[step.name],
    }
  })

  const isDashboardPage = useMatch("/dashboard")

  const canUpgrade = ["STARTER", "TRIAL", "DEFAULT", "FREEMIUM"].includes(
    paymentPlan?.type
  )
  const aside = useContext(AsideContext)

  const openMenu = () => {
    dispatch({ type: "OPEN_MOBILE_MENU" })
  }
  useEffect(() => {
    window.fcWidgetMessengerConfig = {
      config: {
        headerProperty: {
          hideChatButton: true,
        },
      },
    }
    if (window?.fcWidget?.user) {
      window.fcWidget.user.setProperties({
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.username,
        cf_company: organization?.companyName,
      })
    }
    window.fcSettings = {
      onInit: function () {
        window.fcWidget.setExternalId(user?.username)
        window.fcWidget.user.setFirstName(user?.firstName)
        window.fcWidget.user.setLastName(user?.lastName)
        window.fcWidget.user.setEmail(user?.username)
        window.fcWidget.user.setProperties({
          cf_company: organization?.companyName,
        })
      },
    }
  }, [user])

  return (
    <div className={styles.headerWrapper}>
      {freshWorksUrl != "" && (
        <Helmet>
          {/* eslint-disable-next-line react/no-unknown-property */}
          <script src={freshWorksUrl} chat="true"></script>
        </Helmet>
      )}
      <header className={cx("container", { mobile: isTabletOrMobile })}>
        <div className="flex items-center">
          {back ? (
            <button
              type="button"
              className="mr-4 text-primaryBlue"
              onClick={() => {
                navigate(-1)
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
          ) : to ? (
            <button
              type="button"
              className="mr-4 text-primaryBlue"
              onClick={() => {
                navigate(to)
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
          ) : isTabletOrMobile ? (
            <button
              type="button"
              className="mr-4 text-primaryBlue"
              onClick={openMenu}
            >
              <FontAwesomeIcon icon={faStream} />
            </button>
          ) : null}
          <h3 className={styles.title}>{title}</h3>
          <VideoHelpButton />
        </div>

        <div className="flex flex-col">
          <div className="items-center flex">
            <div className="whitespace-nowrap mt-1 text-xs -bottom-4 hidden sm:flex px-1 flex-col justify-end items-end uppercase mr-2 text-primaryBlue">
              <div>
                Plan:{" "}
                <span className="font-sansSemiBold font-semibold">
                  {currentPlan?.label || paymentPlan?.type}
                </span>
              </div>
              <div className="flex items-center">
                {!paymentPlan?.stripeSubscriptionId &&
                  paymentPlan?.type !== "FREEMIUM" &&
                  paymentPlanData.timeLeftInTotal !== null &&
                  paymentPlanData.timeLeftInDays <= 30 && (
                    <span
                      className={`text-gray-600 text-xs normal-case text-right ${
                        paymentPlanData.timeLeftInDays > 10
                          ? "text-primaryGreen"
                          : "text-primaryPink"
                      }`}
                    >
                      {paymentPlanData.timeLeftInTotal > 0
                        ? `expires ${paymentPlanData.formattedTimeLeft}`
                        : `0 days left`}
                    </span>
                  )}

                {paymentPlan?.type === "FREEMIUM" && (
                  <button
                    onClick={() =>
                      modal.showModal(RequestCounter, {
                        onRequestClose: modal.hideModal,
                      })
                    }
                    className={`text-primaryGreen text-xs normal-case outline-none text-right hover:underline`}
                  >
                    What&apos;s included?
                  </button>
                )}
                {canUpgrade && (
                  <>
                    <FontAwesomeIcon
                      icon={faCircle}
                      className="mx-2 text-gray-500"
                      style={{ fontSize: "0.2rem" }}
                    />
                    <Link
                      to="/dashboard/company-settings?tab=billing-details"
                      className="text-primaryGreen text-xs normal-case text-right"
                    >
                      Upgrade?
                    </Link>
                  </>
                )}
              </div>
            </div>
            <div className="px-1">
              {!isDashboardPage &&
                onboardingSteps?.id &&
                countCompletedSteps(firstOnboardingSteps) !== "4/4" &&
                organization && (
                  <div
                    className={styles.progressWidget}
                    onClick={() => navigate("/dashboard")}
                  >
                    <Rocket className="max-w-16 max-h-16 flex-grow-0" />
                    <ProgressBar
                      progress={calculateProgress(firstOnboardingSteps)}
                    />
                  </div>
                )}

              {!isDashboardPage &&
                onboardingSteps?.id &&
                countCompletedSteps(firstOnboardingSteps) === "4/4" &&
                countCompletedSteps(secondOnboardingSteps) !== "3/3" &&
                organization && (
                  <div
                    className={styles.progressWidget}
                    onClick={() => navigate("/dashboard")}
                  >
                    <Logo className="max-w-10 max-h-10 flex-grow-0 mr-3" />
                    <ProgressBar
                      progress={calculateProgress(secondOnboardingSteps)}
                    />
                  </div>
                )}
            </div>
            <button
              onClick={() => {
                aside.showAside(ProductsGlobalOrderOverview, {
                  headerText: "Your Cart",
                })
              }}
              className="hover:text-primaryBlue text-primaryBlueLighter mr-3 ml-1 flex items-center cursor-pointer"
            >
              <FontAwesomeIcon icon={faShoppingCart} />
              {Boolean(globalOrder.products.length > 0) && (
                <div
                  className="font-sansBold font-bold flex justify-center items-center text-white bg-primaryGreen leading-none rounded-full h-5 w-5 -ml-2 -mt-5"
                  style={{
                    fontSize: 12,
                  }}
                >
                  {globalOrder.products.length}
                </div>
              )}
            </button>

            <button
              onClick={() => {
                //Notification logic here
                aside.showAside(AsideNotificationsAndActions, {
                  headerText: "Aside notifications",
                  onClose: () => aside.hideAside(),
                })
              }}
              className="relative flex flex-col items-center mx-2 cursor-pointer"
            >
              <div className="text-primaryBlueLighter hover:text-black">
                <FontAwesomeIcon
                  className="text-2xl pr-1 pt-1 -mr-2"
                  style={{
                    transform: `rotate(25deg)`,
                  }}
                  icon={faRegularBell}
                />
              </div>
              {/* Unread notifications display */}
              {/* <div className="absolute -top-2 -right-2 text-white bg-primaryPink rounded-full h-5 w-5 flex items-center justify-center">
                <span>2</span>
              </div> */}
            </button>

            <button
              onClick={() => window.fcWidget && window.fcWidget.open()}
              className="mx-3 hover:text-primaryBlue text-primaryBlueLighter"
              style={{ fontSize: "19px" }}
            >
              <FontAwesomeIcon icon={faComment} />
            </button>
            <div className="flex flex-col relative">
              <UserDropdown />
            </div>
            {organization && !isTabletOrMobile && <QuickActionDropdown />}
          </div>
        </div>
      </header>
    </div>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  back: PropTypes.bool,
  to: PropTypes.string,
}

Header.defaultProps = {
  title: `Dashboard`,
}

export default Header
