import { faCube, faWineBottle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import classNames from "classnames/bind"
import { navigate } from "gatsby"
import EventBus from "services/events"
import { roundNumber, setQtyInputValues } from "services/helpers"
//@ts-ignore
import * as styles from "./ProductSelectItem.module.css"
import { faPlus, faTimes } from "@fortawesome/pro-light-svg-icons"
import { faCheck } from "@fortawesome/pro-regular-svg-icons"
import Quantity from "components/forms/Quantity"

const cx = classNames.bind(styles)

export default (props) => {
  const {
    item,
    isSelected = false,
    requiredFields = [],
    quantities = [],
    negativeQuantitiesAllowed = false,
    onSelect,
    onDeSelect,
    qtyInputTypes = [],
    enableMultiplePackaging = true,
    highlightPosId,
    qtyPicker = true,
    onSelectDisabled = false,
    forcePackaging = false,
  } = props

  /**
   * Set the packaging state by default on single
   */
  const initPackaging = forcePackaging ? forcePackaging : "single"
  const [packaging, setPackaging] = useState(initPackaging)

  const hasProductCase =
    item.productCase != undefined && enableMultiplePackaging

  //Origina approach, leaving for reference
  //const qtysAreZero = sumArrayValues(Object.values(quantities)) === 0

  const hasFocus = isSelected

  const shouldDisable = () => {
    let disable = false
    requiredFields.forEach((field) => {
      if (!item[field]) {
        disable = true
        return
      }
    })
    return disable
  }

  const isDisabled = shouldDisable()

  const setPackage = (packaging) => {
    setPackaging(packaging)
  }
  const removeItem = () => {
    onDeSelect(item)
  }
  const setQuantities = (quantities) => {
    onSelect({
      item: item,
      packaging: packaging,
      quantities,
    })
  }

  const selectItem = () => {
    if (onSelectDisabled) return

    onSelect({
      item: item,
      packaging: packaging,
      quantities: setQtyInputValues(qtyInputTypes, 1),
    })
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.tabs}>
        <button
          className={cx("tab", {
            active: packaging == "single",
            disabled: forcePackaging == "multiple",
          })}
          role="button"
          onClick={() => (forcePackaging ? null : setPackage("single"))}
        >
          <FontAwesomeIcon icon={faWineBottle} />
        </button>
        {hasProductCase && (
          <button
            className={cx("tab", {
              active: packaging == "multiple",
              disabled: forcePackaging == "single",
            })}
            role="button"
            onClick={() => (forcePackaging ? null : setPackage("multiple"))}
          >
            <FontAwesomeIcon icon={faCube} />
          </button>
        )}
        {hasFocus && (
          <button
            className="bg-primaryPink text-lg text-white absolute left-0 top-0 flex items-center justify-center h-full w-full removeButton"
            onClick={removeItem}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )}
      </div>
      <div className={styles.contentWrapper}>
        <div
          className={cx("content", { "pointer-events-none": isDisabled })}
          onClick={selectItem}
        >
          <span className="mb-1 text-primaryBlue">
            <span className="font-sansBold">{item.name}</span>{" "}
            {highlightPosId && item.code && (
              <span className="text-gray-600 font-light">
                {`(Current POS ID: ${item.code})`}
              </span>
            )}
          </span>

          <div className="text-xm text-gray-600 mr-2">
            {!highlightPosId && (
              <span className="uppercase text-xm text-gray-600 mr-2">
                {item.code ? ` ${item.code} -` : ""}
              </span>
            )}
            <span className="text-primaryBlueLighter mr-2">
              {hasProductCase && packaging === "multiple"
                ? roundNumber(item.productCase.price)
                : roundNumber(item.price)}{" "}
              {hasProductCase && packaging === "multiple"
                ? "/ pack"
                : item.unit
                ? `/ ${item.unit.toLowerCase()}`
                : ""}
            </span>
            {packaging == "single" && (
              <span className="text-gray-600">
                {item.size} {item.measure}
              </span>
            )}
            {hasProductCase && packaging == "multiple" && (
              <span className="text-gray-600">
                {item.productCase.size} x {item.size} {item.measure}
              </span>
            )}
            {item.supplierName && (
              <span className="ml-2 text-gray-600">({item.supplierName})</span>
            )}
            {forcePackaging && (
              <div className="ml-2 pt-1 text-gray-500 text-xs">
                Only one type of packageing possible (You can change after
                deletion)
              </div>
            )}
          </div>
        </div>
        {isDisabled && (
          <p className="text-xs text-gray-700">
            Item missing {requiredFields.join("/")}. Go to{" "}
            <button
              className="underline text-left"
              onClick={() => {
                EventBus.dispatch("navigate")
                navigate("/dashboard/products/items")
              }}
            >
              &apos;Products&apos; → &apos;Items&apos;
            </button>{" "}
            to update.
          </p>
        )}
        {!hasFocus
          ? !onSelectDisabled && (
              <button
                disabled={isDisabled}
                className={cx("qtyButton", "mr-4")}
                onClick={selectItem}
              >
                <FontAwesomeIcon icon={faPlus} />
              </button>
            )
          : !qtyPicker && (
              <button
                type="button"
                className="button whitespace-nowrap button--smaller button--autoWidth button--primaryBlueLightweight mr-2"
                onClick={removeItem}
              >
                <FontAwesomeIcon icon={faCheck} className="mr-2" />
                Selected
              </button>
            )}

        {qtyPicker && (
          <>
            <div className={cx("slideInOverlay", { show: hasFocus })}></div>
            <div className={cx("slideIn", { show: hasFocus })}>
              {qtyInputTypes.length > 0 &&
                qtyInputTypes.map((qtyInputType: any) => {
                  return (
                    <Quantity
                      displayStyle="rounded"
                      placeholder=""
                      fieldName={qtyInputType.quantityKey}
                      addClasses={{ field: "w-20", label: "pr-2 w-20" }}
                      allowNegative={negativeQuantitiesAllowed}
                      value={quantities[qtyInputType.quantityKey]}
                      label={qtyInputType.label}
                      onChange={(qty) => {
                        if (!isSelected) return //Prevent onChange trigger on elements that arent in selection
                        if (qtyInputType.syncQuantityKey) {
                          if (
                            quantities[qtyInputType.quantityKey] ===
                            quantities[qtyInputType.syncQuantityKey]
                          ) {
                            setQuantities({
                              //If qtyInputType has syncQuantityKey and values are equal, then sync values.
                              ...quantities,
                              [qtyInputType.quantityKey]: qty,
                              [qtyInputType.syncQuantityKey]: qty,
                            })
                          } else {
                            setQuantities({
                              ...quantities,
                              [qtyInputType.quantityKey]: qty,
                            })
                          }
                        } else {
                          setQuantities({
                            ...quantities,
                            [qtyInputType.quantityKey]: qty,
                          })
                        }
                      }}
                      key={qtyInputType.quantityKey}
                    />
                  )
                })}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
