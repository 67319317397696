import { faCheck, faSpinnerThird } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { getDishLocations, reassignDishToOrg } from "services/dishes"
import { getMyOrganizationConnections } from "services/organization"
import {
  getProductLocations,
  reassignProductGroupToOrgs,
} from "services/product"
import { getRecipeLocations, reassignRecipeToOrg } from "services/recipes"
interface LocationSelectionProps {
  itemType: "dish" | "recipe" | "item"
  itemId?: string
  currentSelection?: string[] // org ids
  groupId?: string
  itemClass?: string
  onSelectionChange?(selection: string[]): void
}

// Endpoint config for different entity types
const endpoints = {
  item: {
    get: getProductLocations,
    save: reassignProductGroupToOrgs,
  },
  recipe: {
    get: getRecipeLocations,
    save: reassignRecipeToOrg,
  },
  dish: {
    get: getDishLocations,
    save: reassignDishToOrg,
  },
}

const LocationSelection = ({
  itemType,
  itemId,
  currentSelection,
  groupId,
  itemClass,
  onSelectionChange,
}: LocationSelectionProps) => {
  const [selection, setSelection] = useState<string[]>(currentSelection || [])
  const [initialized, setInitialized] = useState<boolean>(false)
  const [subOrgs, setSubOrgs] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const endpointConfig = endpoints[itemType]

  const getSelectedOrgs = async () => {
    if (!itemId) {
      return
    }
    return endpointConfig.get(itemId)
  }

  const sortLocations = (locations: any[]): any[] => {
    return locations.sort((a, b) => {
      return a.companyName.toLowerCase() < b.companyName.toLowerCase() ? -1 : 1
    })
  }

  const handleSelectOrg = (org) => {
    setSelection([...selection, org.id])
  }

  const handleDeselectOrg = (org) => {
    const index = selection.indexOf(org.id)
    const newOrgs = [...selection]
    newOrgs.splice(index, 1)
    setSelection(newOrgs)
  }

  const saveLocations = async () => {
    setLoading(true)
    // We need to filter out main account for this request
    const params = {
      organizations: selection,
    }
    const entityId = groupId ?? itemId // If groupId is defined, use that (e.g. for products)
    if (entityId) {
      await endpointConfig.save(entityId, params)
    }
    setLoading(false)
  }

  useEffect(() => {
    // Make sure only fire when component is already initialized and itemId exists (updating)
    if (initialized && (itemId || groupId)) {
      saveLocations()
    }
    onSelectionChange && onSelectionChange(selection)
  }, [selection])

  useEffect(() => {
    const fetchConnections = async () => {
      setLoading(true)
      try {
        const locations = await getMyOrganizationConnections()
        // Sort on name
        const sortedLocations = sortLocations(locations?.content)
        setSubOrgs(sortedLocations || [])

        // Get current selected orgs
        if (itemId && itemType) {
          const selectedOrgs = await getSelectedOrgs()
          // set selection state
          setSelection(selectedOrgs.subOrgsIds || [])
        }

        setInitialized(true)
      } catch (e) {
        console.log(e)
      }
      setLoading(false)
    }
    fetchConnections()
  }, [])

  return (
    <div className="w-full max-w-3xl">
      <div className=" flex flex-wrap mb-2">
        {/* Notification message */}
        {itemType === "item" && (
          <div className="rounded-md border border-opacity-25 mb-3 bg-red-500 border-red-500 bg-opacity-25 text-red-800 text-sm p-3">
            <strong>Please note:</strong> when an item is assigned to a{" "}
            <strong className="underline">recipe</strong> or{" "}
            <strong className="underline">dish</strong> in a location/area,{" "}
            <span className="underline">it won&apos;t be unassigned</span> from
            that location/area here
          </div>
        )}

        {/* User Locations */}
        {subOrgs.map((org: any) => {
          const labelStr = "label" + org.id
          return (
            <label
              className={`mb-1 w-full ${itemClass || ""}`}
              htmlFor={labelStr}
              key={labelStr}
            >
              <input
                id={labelStr}
                type="checkbox"
                disabled={loading}
                checked={selection?.indexOf(org.id) > -1 ? true : false}
                className="mb-1 mr-2 form-checkbox text-primaryBlueLighter cursor-pointer"
                onChange={(e) => {
                  const checked = e.target.checked
                  return checked ? handleSelectOrg(org) : handleDeselectOrg(org)
                }}
              />
              {org.companyName}
            </label>
          )
        })}
        {initialized && itemId && (
          <span
            className={`block w-full pt-2 text-sm font-semibold font-sansSemiBold ${
              !loading ? "text-primaryGreenDarker" : "text-primaryPink"
            }`}
          >
            <FontAwesomeIcon
              icon={loading ? faSpinnerThird : faCheck}
              spin={loading}
              className="mr-1"
            />{" "}
            {loading ? "saving.." : "all changes saved"}
          </span>
        )}
      </div>
    </div>
  )
}

export default LocationSelection
