import React, { useContext } from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import { ModalContext } from "context/ModalContext"

import {
  formatCurrencyValue,
  getFileNameDateTime,
  roundNumber,
} from "services/helpers"
import { deleteCOGSReport, exportCOGSReport } from "services/stock-take"
import { showSuccess, showError } from "services/toast"

import { format } from "date-fns"

import { faCalculator } from "@fortawesome/pro-duotone-svg-icons"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"

//@ts-ignore
import Container from "components/common/OverviewItem/Container/Container"
import ItemIcon from "components/common/OverviewItem/ItemIcon/ItemIcon"
import usePermissions from "hooks/usePermissions"
import { Permission } from "services/types"
import { GlobalStateContext } from "context/GlobalContextProvider"
import { getOrgNameById } from "services/user"

const COGSReportItem = ({ cogs, onDeleted }) => {
  const { organization, organizations, organizationPermissions } =
    useContext(GlobalStateContext)
  const modal = useContext(ModalContext)
  const currency = null
  const permissionObj = usePermissions("COGS") as Permission
  const {
    id,
    periodFrom,
    periodTo,
    totalCOGS,
    totalCostClosingStock,
    totalSoldValue,
    totalCOGSPercentage,
    totalCostGoodsSpendPercentage,
  } = cogs || {}
  const isSelfCreatedReport =
    cogs.organizations.length && cogs.organizations[0] === organization.id

  const options = isSelfCreatedReport
    ? [
        {
          key: "cogs.edit",
          title: "View report",
          disabled: !permissionObj?.permissions.read,
        },
        {
          key: "cogs.delete",
          title: "Delete",
          type: "danger",
          disabled: !permissionObj?.permissions.delete,
        },
        {
          key: "cogs.export.csv",
          title: "Export full report (csv)",
          disabled: !permissionObj?.permissions.read,
        },
        {
          key: "cogs.export.xlsx",
          title: "Export full report (xlsx)",
          disabled: !permissionObj?.permissions.read,
        },
      ]
    : []

  const onDelete = (cogs) => {
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: "Delete COGS report",
      text: "Are you sure you want to delete this COGS report?",
      confirmButtonText: "Delete",
      onConfirm: () => handleDeleteCOGSReport(cogs),
    })
  }

  const handleDeleteCOGSReport = async (cogs) => {
    const deleted = await deleteCOGSReport(cogs.id)
    if (deleted && !deleted.message) {
      showSuccess("Report deleted!")
      onDeleted()
    } else {
      showError("Delete failed, please try again later")
    }
  }

  const handleActionClick = (action, cogs) => {
    const { type } = action
    const fileDatetime = getFileNameDateTime()

    switch (type) {
      case "cogs.edit":
        navigate(`/dashboard/insights/cogs-reports/${cogs.id}`)
        break
      case "cogs.delete":
        onDelete(cogs)
        break
      case "cogs.export.csv":
        exportCOGSReport(cogs.id, `cogs-full-${fileDatetime}`, "csv", {})
        break
      case "cogs.export.xlsx":
        exportCOGSReport(cogs.id, `cogs-full-${fileDatetime}`, "xlsx", {})
        break
      default:
        break
    }
  }

  return (
    <Container
      data={cogs}
      actionOptions={options}
      handleActionClick={handleActionClick}
    >
      <>
        <ItemIcon
          icon={faCalculator}
          color={"#35CCC3"}
          backgroundColor={"#E0FFFF"}
          linkURL={
            !permissionObj?.permissions.read
              ? "`/dashboard/insights/cogs-reports"
              : `/dashboard/insights/cogs-reports/${id}`
          }
        />

        <div className="flex flex-col p-3" style={{ minWidth: "150px" }}>
          <span className="text-sm text-gray-700">Period from</span>
          <span className="font-sansSemiBold font-semibold">
            {format(new Date(periodFrom), "dd/MM/yyyy")}
          </span>
        </div>

        <div className="flex flex-col p-3" style={{ minWidth: "150px" }}>
          <span className="text-sm text-gray-700">Period to</span>
          <span className="font-sansSemiBold font-semibold">
            {format(new Date(periodTo), "dd/MM/yyyy")}
          </span>
        </div>

        {organizationPermissions?.general?.isMain && (
          <div className="flex flex-col p-3" style={{ minWidth: "200px" }}>
            <span className="text-sm text-gray-700">Location/Area</span>
            <span className="font-sansSemiBold font-semibold flex items-center">
              {cogs.organizations.length &&
                getOrgNameById(cogs.organizations[0], organizations)}
            </span>
          </div>
        )}

        <div className="flex flex-col p-3" style={{ minWidth: "120px" }}>
          <span className="text-sm text-gray-700">COGS</span>
          <span className="text-sm font-sansSemiBold font-semibold text-gray-700">
            &nbsp;
            {`${
              currency ? formatCurrencyValue(totalCOGS) : roundNumber(totalCOGS)
            }`}
          </span>
        </div>

        <div className="flex flex-col p-3" style={{ minWidth: "180px" }}>
          <span className="text-sm text-gray-700">Cost of Closing Stock</span>
          <span className="text-sm font-sansSemiBold font-semibold text-gray-700">
            &nbsp;
            {`${
              currency
                ? formatCurrencyValue(totalCostClosingStock)
                : roundNumber(totalCostClosingStock)
            }`}
          </span>
        </div>

        <div className="flex flex-col p-3" style={{ minWidth: "120px" }}>
          <span className="text-sm text-gray-700">Sales</span>
          <span className="text-sm font-sansSemiBold font-semibold text-gray-700">
            &nbsp;
            {totalSoldValue > 0 &&
              `${
                currency
                  ? formatCurrencyValue(totalSoldValue)
                  : roundNumber(totalSoldValue)
              }`}
          </span>
        </div>

        <div className="flex flex-col p-3" style={{ minWidth: "120px" }}>
          <span className="text-sm text-gray-700">COGS %</span>
          <span className="text-sm font-sansSemiBold font-semibold text-gray-700">
            &nbsp;
            {totalCOGSPercentage && (
              <span className="text-primaryPink">
                {`${roundNumber(totalCOGSPercentage)}%`}
              </span>
            )}
          </span>
        </div>

        <div className="flex flex-col p-3" style={{ minWidth: "120px" }}>
          <span className="text-sm text-gray-700">COG Spent %</span>
          <span className="text-sm font-sansSemiBold font-semibold text-gray-700">
            &nbsp;
            {totalCostGoodsSpendPercentage && (
              <span>{`${roundNumber(totalCostGoodsSpendPercentage)}%`}</span>
            )}
          </span>
        </div>
      </>
    </Container>
  )
}

COGSReportItem.propTypes = {
  cogs: PropTypes.object,
  onDeleted: PropTypes.func,
}

export default COGSReportItem
