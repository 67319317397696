import { GlobalStateContext } from "context/GlobalContextProvider"
import { useContext, useMemo } from "react"
import { get, post, remove, patch } from "./api"
import { InvoicedProduct } from "./invoices"
import { SageTaxRate } from "./sage"
import { redirectToXeroAuthorization, XeroTaxRate } from "./xero"

/*----------------------------------------------------------------------------*/
/* interfaces */
/*----------------------------------------------------------------------------*/

export interface AccountingSupplier {
  email?: string
  id: string
  name: string
}

export interface AccountingLineItem {
  account: AccountingAccount | undefined
  description: string
  netCost: number
  taxRate?: XeroTaxRate | SageTaxRate | null
  // taxRate?: AccountingTaxRate
}

export interface AccountingAccount {
  code: string
  description?: string
  id: string
  name: string
  type: string
}

export interface AccountingTaxRate {
  name: string
  taxRate?: number
  percentage?: number
  taxType?: string
  id?: string
  displayedAs?: string
}

export interface AccountingEditParams {
  account: any | null
  supplier: any | null
  description: string
  netCost: number | undefined
  totalVat: number | undefined
  lineItems: AccountingLineItem[]
  taxRate?: XeroTaxRate | SageTaxRate | null
  // taxRate?: AccountingTaxRate
}

export interface AccountingPublishParams {
  account: AccountingAccount
  accountingSupplier: AccountingSupplier
  description?: string
  grossTotalCost?: number
  invoiceDate?: string
  invoiceNumber: string
  dueDate?: string
  netCost?: number
  totalVat?: number
  taxRate?: XeroTaxRate | SageTaxRate | null
  // taxRate?: AccountingTaxRate
  lineItems?: AccountingLineItem[]
}

export interface ExtendedAccountingLineItem extends AccountingLineItem {
  items: InvoicedProduct[]
}

export interface AccountingProvider {
  title: string
  type: string
  logo: string
  key: string
  id: string | null
  taxPerLine: boolean
  taxPercentagField: string
  taxOptionLabelRenderFn: Function
  extractErrorMsgFn: Function
}
export interface AccountingProviders {
  [key: string]: AccountingProvider
}

/*----------------------------------------------------------------------------*/
/* constants */
/*----------------------------------------------------------------------------*/

export const publishableInvoiceStates = ["REVIEW", "DRAFT"]

export const accountingProviders: AccountingProviders = {
  xero: {
    key: "xero",
    title: "Xero",
    type: "XERO",
    logo: "/images/integrations/xero-icon.svg",
    id: null,
    taxPerLine: false,
    taxPercentagField: "taxRate",
    taxOptionLabelRenderFn: (opt) =>
      `${opt.name} - ${opt.taxType || ""}${
        opt.taxRate !== null ? " - " + opt.taxRate + "%" : ""
      }`,
    extractErrorMsgFn: (result) => result.message,
  } as AccountingProvider,
  sage: {
    key: "sage",
    title: "Sage",
    type: "SAGE",
    logo: "/images/integrations/sage.svg",
    id: null,
    taxPerLine: true,
    taxPercentagField: "percentage",
    taxOptionLabelRenderFn: (opt) => `${opt.displayedAs}`,
    extractErrorMsgFn: (result) => {
      return result.message.split('$message":"')[1].split('","')[0]
    },
  } as AccountingProvider,
}

/*----------------------------------------------------------------------------*/
/* Api calls */
/*----------------------------------------------------------------------------*/

export const getAccountingClientId = (provider) => {
  //   return Promise.resolve("B772389903EB4BF9B2C6C1A5BACA5D64")
  return get(`/${provider}-integrations/client-id`, {}, { plain: true })
}

export const redirectToAccountingAuthorization = (
  provider,
  clientId: string
) => {
  switch (provider) {
    case "sage":
      //redirectToSageAuthorization(clientId)
      break
    case "xero":
      redirectToXeroAuthorization(clientId)
      break
    default:
      break
  }
}

export const createAccountingIntegration = (provider, params) => {
  return post(`/${provider}-integrations`, params)
}

export const getAccountingIntegration = (
  provider,
  integrationId: string,
  config?: any
) => {
  return get(`/${provider}-integrations/${integrationId}`, {}, config)
}

export const getAccountingIntegrations = (provider) => {
  return get(`/${provider}-integrations`)
}

export const getAccountingConnections = (provider, params) => {
  return get(`/${provider}-integrations/connections`, params)
}

export const updateAccountingConnection = (
  provider: string,
  integrationId: string,
  connectionId: string
) => {
  return patch(
    `/${provider}-integrations/${integrationId}/connection/${connectionId}`
  )
}

export const removeAccountingIntegration = (provider, integrationId) => {
  return remove(`/${provider}-integrations/${integrationId}`)
}

export const getAccountingAccounts = (provider, params: any, options?: any) => {
  return get(`/${provider}-integrations/accounts`, params, options)
}

export const getAccountingSuppliers = (
  provider,
  params: any,
  options?: any
) => {
  return get(`/${provider}-integrations/suppliers`, params, options)
}

export const getAccountingTaxRates = (provider, params: any, options?: any) => {
  return get(`/${provider}-integrations/tax-rates`, params, options)
}

/*----------------------------------------------------------------------------*/
/* hooks */
/*----------------------------------------------------------------------------*/

const mapAccountingProviders = (integrations) => {
  let numActive = 0
  Object.keys(accountingProviders).forEach(function (key) {
    accountingProviders[key].id = integrations.accountingIntegrations.find(
      (int) => int.type === accountingProviders[key].type
    )?.id
    if (accountingProviders[key].id) {
      numActive++
    }
  })
  return { providers: accountingProviders, numActive: numActive }
}

export const useAccountingProviders = () => {
  const { integrations } = useContext(GlobalStateContext)
  const memoAccountingProviders = useMemo(
    () => mapAccountingProviders(integrations),
    [accountingProviders]
  )
  return memoAccountingProviders
}
