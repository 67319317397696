import React, { useState, useEffect } from "react"
import Modal from "react-modal"

import { showError, showSuccess } from "services/toast"
import { getStockTakes } from "services/stock-take"
import { getSales } from "services/sales"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPlus,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/pro-regular-svg-icons"
import { faSyncAlt } from "@fortawesome/pro-duotone-svg-icons"

import {
  createDiscrepancyReport,
  updateDiscrepancyReportById,
} from "services/stock-take"
import { navigate } from "gatsby"

import Loader from "components/common/Loader/Loader"
import AsyncSelect from "components/forms/AsyncSelect"
import CategorySelect from "components/common/CategorySelect/CategorySelect"
import { formatReportSelectOptionLabel } from "services/helpers"
import { Category, CategoryParams } from "services/types"
import { GrowyzeBackendGETResponse } from "../../../services/types"
import { getCategories } from "services/categories"
import {
  applyReportFiltersToCategories,
  formatCategoriesAsReportFilterCategories,
} from "services/reportHelpers"

Modal.setAppElement("body")

import {
  header,
  thumbnail,
  thumbnailWrapper,
  iconWrapper,
  content,
  footer,
  cancelButton,
  //@ts-ignore
} from "./DiscrepancyReportModal.module.css"

const DiscrepancyReportModal = ({
  title,
  loadingText,
  stockTakeReportId,
  isUpdate = false,
  onCloseParentModal,
  onRequestClose,
  discrepancyReportData,

  ...otherProps
}) => {
  const { openingStockTakeReportId, sales, filterCategories } =
    discrepancyReportData || {}

  const [loading, setLoading] = useState(false)
  const [selectedOpeningStocktake, setSelectedOpeningStocktake] = useState<{
    label: string | undefined
    value: any
  } | null>(null)
  const [selectedSalesFile, setSelectedSalesFile] = useState<{
    label: string | undefined
    value: any
  } | null>(null)

  const [showFilters, setShowFilters] = useState(false)
  const [categories, setCategories] = useState<Category[]>([])

  const fetchFinalisedStockTakes = async (inputValue) => {
    const params = {
      size: 99,
      sort: "completedAt,desc",
      status: "COMPLETED",
      partialName: inputValue ? inputValue : null,
    }
    const result = await getStockTakes(params)

    if (openingStockTakeReportId) {
      const openingStockTake = result.content.find(
        (stocktake) => stocktake.stockTakeReport.id === openingStockTakeReportId
      )

      setSelectedOpeningStocktake({
        label: formatReportSelectOptionLabel(openingStockTake, "stocktake"),
        value: openingStockTake,
      })
    }

    return result
  }

  const fetchSalesFiles = async () => {
    const params = {
      size: 99,
      sort: "createdAt,desc",
      live: false,
    }
    const result = await getSales(params)

    const salesFile = result.content.find((sf) => sf.id === sales?.id)

    if (salesFile) {
      setSelectedSalesFile({
        label: formatReportSelectOptionLabel(salesFile, "sales"),
        value: salesFile,
      })
    }

    return result
  }

  const onSaveDiscrepancyReport = async () => {
    setLoading(true)
    // If a category is selected return the mainCategories object for filterCategories, else send null to get all products in stocktake.
    const filterCategories =
      formatCategoriesAsReportFilterCategories(categories)

    const params = {
      filterCategories:
        filterCategories?.mainCategories &&
        filterCategories?.mainCategories.length > 0
          ? filterCategories
          : null,
    }

    const result = isUpdate
      ? await updateDiscrepancyReportById(
          stockTakeReportId,
          selectedOpeningStocktake?.value.stockTakeReport.id,
          selectedSalesFile ? selectedSalesFile.value.id : null,
          params
        )
      : await createDiscrepancyReport(
          stockTakeReportId,
          selectedOpeningStocktake?.value.stockTakeReport.id,
          selectedSalesFile ? selectedSalesFile.value.id : null,
          params
        )

    if (result.status === 404 || result.status === 400) {
      setLoading(false)
      return showError(result.message)
    } else if (result) {
      setLoading(false)
      showSuccess("Report generated!", { position: "top-center" })

      if (onCloseParentModal) {
        onCloseParentModal()
      }

      onRequestClose()
      return navigate(
        `/dashboard/stock/stocktake/discrepancy-report/${result.id}`
      )
    }
  }

  const buttonText = isUpdate ? "Update report" : "Create report"

  const buttonIcon = !isUpdate && (
    <FontAwesomeIcon icon={faPlus} className="mr-2" />
  )

  const categoryCount = categories
    ? categories.reduce((acc, item) => {
        return acc + item.subCategories.filter((subCat) => subCat.active).length
      }, 0)
    : 0

  const getLatestCategories = async (filterCategories: CategoryParams) => {
    try {
      const res: GrowyzeBackendGETResponse<Category> = await getCategories({
        active: true,
      })

      return setCategories(
        applyReportFiltersToCategories(res.content, filterCategories, true)
      )
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    let isMounted = true

    if (isMounted) {
      getLatestCategories(filterCategories)
    }
    return () => {
      isMounted = false
    }
  }, [])

  return (
    <Modal
      isOpen
      style={{ content: { bottom: "40px", border: 0, overflow: "hidden" } }}
      onRequestClose={onRequestClose}
      portalClassName="selectOpeningStockTake"
      {...otherProps}
    >
      <div className="flex flex-col h-full">
        <div className={header}>
          <div className={thumbnailWrapper}>
            <div className={thumbnail}>
              <div
                className={iconWrapper}
                style={{
                  backgroundColor: "#E0FFFF",
                  color: "#35CCC3",
                }}
              >
                <FontAwesomeIcon icon={faSyncAlt} />
              </div>
            </div>
          </div>
          <h2 className="text-lg">{title}</h2>
        </div>
        <div className={content}>
          <div className="my-1 px-2 w-full">
            <Loader
              isLoading={loading}
              style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
            >
              {loadingText}
            </Loader>

            <h2 className="my-2">
              {openingStockTakeReportId
                ? "Edit opening stocktake"
                : "Select opening stocktake"}
            </h2>
            <AsyncSelect
              name="Opening stocktake"
              label="Opening stocktake"
              className="w-full"
              value={selectedOpeningStocktake}
              promise={fetchFinalisedStockTakes}
              placeholder="Opening stocktake"
              isClearable={true}
              optionLabel={(option) =>
                formatReportSelectOptionLabel(option, "stocktake")
              }
              onChange={(stocktake) => {
                setSelectedOpeningStocktake(stocktake)
              }}
            />

            <h2 className="mb-2 mt-4">
              {openingStockTakeReportId
                ? "Edit sales file"
                : "Select sales file (optional)"}
            </h2>
            <AsyncSelect
              name="Sales file"
              label="Sales file"
              className="w-full"
              value={selectedSalesFile}
              promise={fetchSalesFiles}
              placeholder="Sales file"
              isClearable={true}
              optionLabel={(option) =>
                formatReportSelectOptionLabel(option, "sales")
              }
              onChange={(sf) => {
                setSelectedSalesFile(sf)
              }}
            />
          </div>
          <div className="px-2 mt-2">
            <button
              onClick={() => setShowFilters(!showFilters)}
              className="flex items-baseline"
            >
              <FontAwesomeIcon
                icon={showFilters ? faChevronUp : faChevronDown}
                className="mr-2 text-sm"
              />
              <span className="mb-2 mt-2 flex flex-col items-start">
                <span className="underline hover:no-underline text-primaryBlue font-semibold font-sansSemiBold">
                  Edit product categories
                </span>
                {!showFilters && (
                  <span className="font-normal font-sans text-sm text-gray-600">
                    {categoryCount} categories selected
                  </span>
                )}
              </span>
            </button>
          </div>

          <div
            className={`flex flex-col sm:flex-row mx-2 ${
              !showFilters ? "hidden" : ""
            }`}
          >
            <CategorySelect
              categories={categories}
              onSelectCategories={(selectedCategories) =>
                setCategories(selectedCategories)
              }
            />
          </div>
        </div>
        <div className={footer}>
          <button className={cancelButton} onClick={onRequestClose}>
            Close
          </button>
          <button
            className="button ml-auto button--autoWidth button--primaryGreen"
            onClick={onSaveDiscrepancyReport}
            disabled={!selectedOpeningStocktake}
          >
            <span>{buttonIcon}</span>
            <span>{buttonText}</span>
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default DiscrepancyReportModal
