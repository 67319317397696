import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faSyncAlt } from "@fortawesome/pro-regular-svg-icons"
import { stockTakeStates } from "services/constants"
import { roundNumber, slugify } from "services/helpers"
import { downloadStockTakeReport } from "services/stock-take"
import { format, isValid } from "date-fns"
import classNames from "classnames/bind"
import ActionsButton from "components/common/ActionsButton/ActionsButton"
import PropTypes from "prop-types"

import * as styles from "./StockTakeGroup.module.css"
import { faLock } from "@fortawesome/pro-solid-svg-icons"
import usePermissions from "hooks/usePermissions"

const cx = classNames.bind(styles)

const StockTakeGroup = ({
  stocktake,
  isLocked,
  onSelect,
  onComplete,
  onEdit,
  onDelete,
}) => {
  const isCompleted = stocktake && stocktake.status === "COMPLETED"
  const isParentReport = stocktake && stocktake.subStockTakeReports
  const permissionObj = usePermissions("Stocktakes")
  const state = stockTakeStates.find(
    (oS) =>
      oS.label ===
      (stocktake.status === "COMPLETED" ? "Completed" : "In progress")
  )

  let options = []
  if (!isLocked || isParentReport) {
    options = [
      ...options,
      {
        key: "stocktake.edit",
        title: "Edit stocktake",
        type: "default",
        disabled: !permissionObj?.permissions.modify,
      },
    ]
  }

  if ((isParentReport && stocktake.status === "COMPLETED") || !isParentReport) {
    options = [
      ...options,
      {
        key: "stocktake.export_xlsx",
        title: "Export to .XLSX",
        type: "default",
        disabled: !permissionObj?.permissions.read,
      },
      // {
      //   key: "stocktake.export_pdf",
      //   title: "Export to .PDF",
      //   type: "default",
      // },
      // {
    ]
  }

  if (stocktake.status === "IN_PROGRESS") {
    options = [
      ...options,
      {
        key: "stocktake.delete",
        title: "Delete",
        type: "danger",
        disabled: !permissionObj?.permissions.delete,
      },
    ]
  }

  const handleActionClick = (action, stocktake) => {
    switch (action.type) {
      case "stocktake.edit":
        onEdit(stocktake)
        break
      case "stocktake.delete":
        onDelete(stocktake)
        break
      case "stocktake.export_csv":
        onExportStocktake(stocktake, "csv")
        break
      case "stocktake.export_pdf":
        onExportStocktake(stocktake, "pdf")
        break
      case "stocktake.export_xlsx":
        onExportStocktake(stocktake, "xlsx")
        break
      default:
        break
    }
  }

  const onExportStocktake = (stocktake, fileType) => {
    const fileName = slugify(stocktake.name)

    isParentReport
      ? downloadStockTakeReport(stocktake.id, fileType, fileName)
      : downloadStockTakeReport(
          stocktake.parentId,
          fileType,
          fileName,
          stocktake.id
        )
  }

  const totalProductsValue = () => {
    // If parent report total amount is not completed yet, calculate it from subreports
    if (isParentReport && isCompleted) {
      if (!stocktake.totalAmount) {
        return stocktake.subStockTakeReports.reduce(
          (a, v) =>
            a +
            v.products.reduce(
              (a, v) => (v.totalAmount === null ? a : a + v.totalAmount),
              0
            ) +
            v.recipes.reduce(
              (a, v) => (v.totalCost === null ? a : a + v.totalCost),
              0
            ),
          0
        )
      } else {
        return stocktake.totalAmount
      }
    }
    // If stuff is out of sync from the start (when clicking on StockTake item for the first time, there are no products yet in the group)
    if (stocktake.products.length === 0 && stocktake.totalAmount > 0) {
      return stocktake.totalAmount
    }
    return (
      stocktake.products.reduce(
        (a, v) => (v.totalAmount === null ? a : a + v.totalAmount),
        0
      ) +
      stocktake.recipes.reduce(
        (a, v) => (v.totalCost === null ? a : a + v.totalCost),
        0
      )
    )
  }

  const hasUnsyncedProducts = (st) => {
    return (
      st.products &&
      st.products.filter((product) => product.unsynced === true).length > 0
    )
  }

  const hasUnsavedChanges = (st) => {
    return !st.createdAt || st.unsynced || hasUnsyncedProducts(st)
  }

  return (
    <div
      className={cx("subStockTake", { mergedSub: isLocked && !isParentReport })}
    >
      <button
        onClick={() => onSelect(stocktake)}
        className={cx("item", { mergedSub: isLocked && !isParentReport })}
        disabled={hasUnsavedChanges(stocktake)}
      >
        {state && ((!isLocked && !isParentReport) || isParentReport) ? (
          <span
            className={styles.statusBulb}
            style={{
              backgroundColor: state.color,
              width: "12px",
              height: "12px",
            }}
          ></span>
        ) : (
          <FontAwesomeIcon
            icon={faLock}
            className="mr-3 mt-1 text-sm text-primaryBlueLighter"
          />
        )}
        <div
          className="flex flex-col flex-grow self-center items-start text-left"
          style={{ maxWidth: "200px" }}
        >
          <h2 className="text-primaryBlue mb-1 flex items-center">
            {stocktake.name}
          </h2>
          {stocktake.products && (
            <span className="text-gray-700 text-sm">
              Created at{" "}
              {isValid(new Date(stocktake.createdAt)) &&
                format(new Date(stocktake.createdAt), "dd-MM-yy HH:mm")}
            </span>
          )}
        </div>
        <div className="flex flex-col self-center items-start text-left">
          <span className="text-sm text-gray-700">Total value</span>
          <strong>{roundNumber(totalProductsValue() || 0)}</strong>
        </div>
      </button>
      <div className="flex items-center justify-between w-full sm:w-auto px-4">
        {isCompleted || isLocked ? (
          <div className="flex items-start mr-4">
            <FontAwesomeIcon
              icon={faCheck}
              className={`mr-2 mt-1 text-green-500`}
            />
            <div className="flex flex-col">
              <span
                className={`font-sansSemiBold font-semibold text-green-500`}
              >
                {isParentReport ? "Finalised" : "Completed"}
              </span>

              <span className="text-gray-700 text-sm">
                {isValid(new Date(stocktake.completedAt)) &&
                  format(new Date(stocktake.completedAt), "dd-MM-yy HH:mm")}
              </span>
            </div>
          </div>
        ) : (
          <button
            className={cx("button button--autoWidth mr-4", "completeButton")}
            disabled={
              hasUnsavedChanges(stocktake) || !permissionObj?.permissions.modify
            }
            onClick={(e) => {
              e.preventDefault()
              onComplete(stocktake)
            }}
          >
            {hasUnsavedChanges(stocktake) && (
              <FontAwesomeIcon icon={faSyncAlt} spin className="mr-2" />
            )}
            Complete
          </button>
        )}
        {options.length > 0 && (
          <ActionsButton
            options={options}
            disabled={hasUnsavedChanges(stocktake)}
            onActionClick={(action) => handleActionClick(action, stocktake)}
          />
        )}
      </div>
    </div>
  )
}

StockTakeGroup.propTypes = {
  stocktake: PropTypes.object,
  isLocked: PropTypes.bool,
  onSelect: PropTypes.func,
  onComplete: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
}

export default StockTakeGroup
