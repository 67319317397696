import React, { useState, useEffect } from "react"
import queryString from "query-string"
import { Link } from "gatsby"
import Loader from "components/common/Loader/Loader"

import * as styles from "./OrderApprove.module.css"

import { approveOrder } from "services/order"
import { apiResponseIsError, convertTimeStampDate } from "services/helpers"
import classNames from "classnames"
//@ts-ignore
import Logo from "inline-svg/growyze-icon.svg"

const OrderApprove = ({ location }) => {
  const [result, setResult] = useState(null)
  const [loading, setLoading] = useState(false)

  const isError = apiResponseIsError(result)

  const renderSuccess = (result) => {
    const { supplierName, po, creationDate } = result
    return (
      <>
        <p>The following order has been approved:</p>
        <ul>
          <li>
            <span className="font-sansSemiBold">{supplierName}</span>{" "}
            {po && <span>({po})</span>}
          </li>
          <li>
            <span className="font-sansSemiBold">Created at:</span>{" "}
            {creationDate && convertTimeStampDate(creationDate)}
          </li>
        </ul>
      </>
    )
  }

  // const renderError = (result) => {
  //   const message = result.message
  //     ? result.message
  //     : "Please try again or contact us."

  //   return <p>{message}</p>
  // }

  const handleApproveOrder = (data) => {
    const token = data
    setLoading(true)
    approveOrder(token)
      .then((result) => setResult(result))
      .catch((err) => setResult(err))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    const queryParams = queryString.parse(location.search)

    if (queryParams.token) {
      handleApproveOrder(queryParams.token)
    }
  }, [])

  return (
    <div className={styles.container}>
      <Loader
        isLoading={loading}
        style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
      >
        Loading..
      </Loader>

      {result && (
        <div className="text-center mb-6">
          <Logo className={styles.logo} />
          <h1 className="text-4xl mb-4">
            {isError ? "Order already approved" : "Order approved"}
          </h1>
          <div
            className={classNames(
              " block w-full mx-auto max-w-xs text-center  mb-4"
              // {
              //   "text-primaryPink": isError,
              // }
            )}
          >
            {!isError && renderSuccess(result)}
          </div>

          <p className="text-sm text-gray-700 text-center mb-5 px-6">
            <Link className={styles.btn} to="/dashboard">
              Take me back
            </Link>{" "}
          </p>
        </div>
      )}
    </div>
  )
}

export default OrderApprove
