import React, { useEffect, useCallback, useState, useContext } from "react"
import { ModalContext } from "context/ModalContext"

import ReactTooltip from "react-tooltip"
import classNames from "classnames/bind"
import { useField } from "formik"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
  faTimes,
  faCheck,
  faBarcodeRead,
} from "@fortawesome/pro-regular-svg-icons"

import ProductInput from "components/products/ProductInput/ProductInput"

//@ts-ignore
import * as styles from "./BarcodeInput.module.css"
import { GlobalStateContext } from "context/GlobalContextProvider"
const cx = classNames.bind(styles)

interface BarcodeInputProps {
  label: string
  className: string
  onBarcodeUpdate: (barcode: any) => void
  showValidations: boolean
  enableGenerateBarcode: boolean
  onStatusChange?: ({ generate }: { generate: boolean }) => void
  organizationId?: string
  disabled?: boolean
  disabledMessage?: string
}

const BarcodeInput = ({
  label,
  className,
  onStatusChange,
  onBarcodeUpdate,
  showValidations = true,
  enableGenerateBarcode = true,
  organizationId = "",
  disabled = false,
  disabledMessage = "",
  ...props
}: BarcodeInputProps) => {
  const { organizationPermissions } = useContext(GlobalStateContext)

  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta, helpers] = useField(props)
  const [hasNoCode, setHasNoCode] = useState(false)
  const modal = useContext(ModalContext)

  useEffect(() => {
    if (meta.touched && meta.error && showValidations) {
      setTimeout(() => {
        ReactTooltip.rebuild()
      }, 100)
    }
  }, [meta.touched, meta.error, showValidations])

  const hideInitialValidation = useCallback(() => {
    return !props.required && meta.value === ""
  }, [props.required, meta.value])

  const changeStatus = (val) => {
    setHasNoCode(val)

    if (val === true) {
      helpers.setValue("", false)
      helpers.setError(null)
    }

    if (onStatusChange) {
      onStatusChange({ generate: val })
    }
  }

  return (
    <div className={cx(styles.barcodeInput)}>
      <div className="input-container flex-grow">
        <label className="sr-only" htmlFor={props.id || props.name}>
          {label}
        </label>

        {organizationPermissions.general.isSub &&
          disabled &&
          disabledMessage && (
            <ReactTooltip
              id={"tooltip_" + props.id || props.name}
              type="light"
              place="top"
              effect="float"
              border={true}
              borderColor="#e2e8f0"
            />
          )}

        <input
          className={cx(className, styles.inputField)}
          disabled={hasNoCode || disabled}
          {...(disabled && disabledMessage
            ? {
                "data-tip": disabledMessage,
                "data-for": "tooltip_" + props.id || props.name,
              }
            : {})}
          {...field}
          {...props}
        />

        {meta.touched &&
          showValidations &&
          !hideInitialValidation() &&
          !hasNoCode && (
            <>
              <span
                className={cx("validation-mark", "validationIcon", {
                  "validation-mark--success": !meta.error,
                  "validation-mark--error": meta.error,
                })}
                data-tip={meta.error}
                data-for={`BarcodeInputTooltip-${props.id || props.name}`}
              >
                <FontAwesomeIcon icon={meta.error ? faTimes : faCheck} />
              </span>
            </>
          )}
      </div>
      <div className={styles.buttonContainer}>
        <button
          onClick={(e) => {
            e.preventDefault()
            modal.showModal(ProductInput, {
              barcodeUpdate: (barcodeText) => {
                onBarcodeUpdate(barcodeText)
              },
            })
          }}
          className={cx(
            "button button--autoWidth button--primaryGreen no-truncate",
            styles.barcodeScanButton,
            { [styles.barcodeScanButtonXs]: !enableGenerateBarcode }
          )}
          disabled={disabled}
          {...(disabledMessage
            ? {
                "data-tip": disabledMessage,
                "data-for": "tooltip_" + props.id || props.namedMessage,
              }
            : {})}
        >
          <FontAwesomeIcon icon={faBarcodeRead} className="inline" />
        </button>

        <ReactTooltip
          id={`BarcodeInputTooltip-${props.id || props.name}`}
          type="light"
          place="left"
          effect="solid"
          border={true}
          borderColor="#e2e8f0"
        />

        {enableGenerateBarcode && (
          <div className="flex items-center right-0 px-4 bottom-0 top-0 h-full">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                value={hasNoCode}
                onChange={() => changeStatus(!hasNoCode)}
                name="hasNoCode"
                className="form-checkbox text-primaryPink"
              />
              <span className="ml-2 text-gray-700">No barcode</span>
            </label>
          </div>
        )}
      </div>
    </div>
  )
}

export default BarcodeInput
