import { get } from "./api"
import { saveAs } from "file-saver"

export const getServerImage = async (fileId) => {
  const result = await get(
    `/growyze-storage/files/${fileId}`,
    {},
    {
      download: true,
    }
  )

  if (result) {
    const blob = await result.blob()
    return URL.createObjectURL(blob)
  } else {
    return false
  }
}

export const getStorageFilename = async (fileId) => {
  const result = await get(
    `/growyze-storage/files/${fileId}`,
    {},
    {
      download: true,
    }
  )

  if (result) {
    const entries = await result.headers.entries()
    for (const [key, value] of entries) {
      if (key === "content-disposition" || key === "Content-Disposition") {
        const fileName = value.split("=").pop()
        return fileName
      }
    }
  } else {
    return false
  }
}

export const downloadStorageFile = async (
  fileId,
  fileName,
  organizations = null
) => {
  const result = await get(
    `/growyze-storage/files/${fileId}`,
    {
      organizations,
    },
    {
      download: true,
    }
  )

  if (result) {
    const response = await result.blob()
    saveAs(response, `${fileName}`)
  } else {
    return false
  }
}
