import React, { useState, useContext, useEffect, useRef } from "react"
import { useBeforeUnload } from "react-use"
import * as queryString from "query-string"
import { navigate, useLocation } from "@reach/router"
import {
  faArrowRight,
  faArrowLeft,
  faTimes,
  faChevronUp,
} from "@fortawesome/pro-regular-svg-icons"
import {
  faTruck,
  faWineBottle,
  faShoppingCart,
} from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Helmet from "react-helmet"
import Header from "components/dashboard/Header/Header"
import Loader from "components/common/Loader/Loader"
import OrderDetails from "components/orders/OrderDetails/OrderDetails"
import SupplierSelect from "components/suppliers/SupplierSelect/SupplierSelect"
import SupplierCatalog from "components/suppliers/SupplierCatalog/SupplierCatalog"

import { createOrder, updateOrder } from "services/order"
import { showError, showSuccess } from "services/toast"
import { formatCurrencyValue, roundNumber } from "services/helpers"
import { useMediaQuery } from "react-responsive"
import classNames from "classnames"
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "context/GlobalContextProvider"

import ReactTooltip from "react-tooltip"
//@ts-ignore
import * as styles from "./EditOrder.module.css"
import Discrepancies, {
  Discrepancy,
} from "../../common/Discrepancies/Discrepancies"
import { ModalContext } from "context/ModalContext"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import { getDiscrepancies } from "../../../services/order-discrepancies"
import FileList from "components/common/FileList/FileList"
import { updateOrderDiscrepancy } from "../../../services/order-discrepancies"
import { DiscrepancyStatus, Permission } from "services/types"
import { getOrder } from "services/order"
import usePermissions from "hooks/usePermissions"
import { getSupplierById } from "services/supplier"
import PlaceOrderModal from "components/common/PlaceOrderModal/PlaceOrderModal"
import { useFileOwner } from "hooks/useFileOwner"

const EditOrder = ({ orderId }) => {
  const [dirty, setDirty] = useState(false)
  useBeforeUnload(dirty, "You have unsaved changes, are you sure?")
  const permissionObj = usePermissions("Orders") as Permission
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const { newOrder, user, organization } = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  const [loading, setLoading] = useState(false)
  const [showItems, setShowItems] = useState(false)
  const [step, setStep] = useState("supplier")
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
  const [skipSendingEmail, setSkipSendEmail] = useState(false)
  const [tab, setTab] = useState("items")
  const [discrepancies, setDiscrepancies] = useState<
    Array<Discrepancy> | undefined
  >(undefined)
  const modal = useContext(ModalContext)
  const filesOwner = useFileOwner(newOrder)

  const tooltipElement = useRef(null)

  const { approvers } = newOrder || {}

  const userIsApprover =
    user &&
    approvers?.findIndex((approver) => approver.username == user.username) !==
      -1

  const orderRequiresApproval = newOrder.status === "PENDING_APPROVAL"
  const orderIsPlaced =
    newOrder.status === "PLACED" || newOrder.status === "COMPLETED"

  const userCanModifyOrder =
    ((orderRequiresApproval && userIsApprover) ||
      (!orderRequiresApproval && permissionObj?.permissions.modify)) &&
    !orderIsPlaced

  const itemCount = newOrder.items?.filter((item) => item.quantity).length

  const steps = [
    {
      key: "supplier",
      title: "Select a supplier",
      icon: faTruck,
    },
    {
      key: "items",
      title: "Select your items",
      icon: faWineBottle,
    },
    {
      key: "review",
      title: "Review order",
      icon: faShoppingCart,
    },
  ]
  const DEFAULT_ERROR_MESSAGE = "Something went wrong!"

  const displayActionLabel = () => {
    if (step === "supplier") {
      //step is supplier
      return "Select items"
    } else if (step === "items") {
      //step is items
      return "Review order"
    } else if (step === "review") {
      //step is review
      if (newOrder.status === "REVIEW") {
        return "Confirm order"
      } else if (newOrder.status === "PENDING_APPROVAL") {
        return "Approve order"
      } else {
        return "Place order"
      }
    }
  }

  const handleSupplierSelect = (supplier) => {
    if (newOrder.supplier && newOrder.supplier.id !== supplier.id) {
      dispatch({ type: "UPDATE_NEW_ORDER", payload: { supplier, items: [] } })
    } else {
      dispatch({ type: "UPDATE_NEW_ORDER", payload: { supplier } })
    }
    setDirty(true)
  }

  const initOrder = (orderData) => {
    const initialProducts = orderData.items

    const items = orderData?.items?.map((item) => {
      return { ...item, id: item.productId }
    })

    //Reference type updates --- UPDATES THE PASSED IN OBJECT
    initialProducts?.length &&
      initialProducts.forEach((e) => {
        const found = items.find((a) => a.id === e.productId)
        if (found) {
          //Can directly modify here
          found._productDbPrice = found.price
          found.price = e.price
          if (found?.productCase?.price) {
            found.productCase._productDbPrice = found.productCase.price
            found.productCase.price = e.productCase.price
          }
        }
      })

    dispatch({
      type: "UPDATE_NEW_ORDER",
      payload: {
        po: orderData.po,
        id: orderData.id,
        supplier: orderData.supplier,
        status: orderData.status,
        notes: orderData.notes || "",
        deliveryAddress: orderData.deliveryAddress,
        expectedDeliveryDate: orderData.expectedDeliveryDate,
        items: items,
        _initialItems: items,
        extractedFile: orderData.extractedFile,
        approvers: orderData.approvers,
      },
    })
    setLoading(false)
  }

  const itemsList = () => {
    return (
      <div className="z-20 absolute lg:relative w-full h-full flex flex-col left-0 top-0 lg:left-auto lg:top-auto bg-white">
        <header className="px-4 py-2 flex items-center justify-between lg:hidden">
          <h4 className="text-lg text-primaryBlue">Your items</h4>
          <button
            onClick={() => setShowItems(false)}
            className="text-lg p-2 text-primaryBlue"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </header>
        <ul className="border-t lg:-mx-8 px-4 lg:mt-4 lg:px-8 flex-grow overflow-y-auto">
          {itemCount > 0 &&
            newOrder.items
              .filter((item) => item.quantity)
              .map((item, index) => (
                <li
                  className="flex border-b w-full text-gray-700 text-sm py-2 items-center"
                  key={index}
                >
                  <span className="flex-grow">
                    <strong className="font-bold font-sansBold">
                      {item.quantity}x
                    </strong>{" "}
                    {item.name}{" "}
                    {item.size || item.measure ? (
                      <small>
                        ({item.orderInCase ? `${item.productCase.size} x` : ""}
                        {item.size} {item.measure}
                        {item.orderInCase
                          ? " - pack"
                          : item.unit
                          ? ` - ${item.unit.toLowerCase()}`
                          : ""}
                        )
                      </small>
                    ) : null}
                  </span>
                  <span className="px-4">
                    {item.orderInCase
                      ? newOrder.supplier && newOrder.supplier.currency
                        ? formatCurrencyValue(
                            item.quantity * parseFloat(item.productCase.price)
                          )
                        : roundNumber(
                            item.quantity * parseFloat(item.productCase.price)
                          )
                      : newOrder.supplier && newOrder.supplier.currency
                      ? formatCurrencyValue(
                          item.quantity * parseFloat(item.price)
                        )
                      : roundNumber(item.quantity * parseFloat(item.price))}
                  </span>
                  <button
                    type="button"
                    className="button--autoWidth text-primaryPink"
                    onClick={() => removeOrderItem(item.id)}
                    disabled={!userCanModifyOrder}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </li>
              ))}

          {itemCount === 0 && <p className="py-2">No items in your cart yet</p>}
        </ul>
      </div>
    )
  }

  const saveOrder = async (
    status: string,
    skipSendingEmail: boolean,
    itemSelectionOverwrite: any[] = [], // Allow to send item selection directly as param - prevents awaiting redux thunks
    skipNavigateToOverview?: boolean
  ) => {
    setLoading(true)
    const params = {
      items: (!itemSelectionOverwrite.length
        ? newOrder.items
        : itemSelectionOverwrite
      )
        .map((item) => {
          return {
            productId: item.id,
            name: item.name,
            unit: item.unit,
            quantity: item.quantity,
            orderInCase: item.orderInCase,
          }
        })
        .filter((item) => item.quantity), // Filter out items without set quantity (this might happen on mobile)
      notes: newOrder.notes,
      deliveryAddress: newOrder.deliveryAddress,
      status,
      po: newOrder.po,
      supplier: {
        id: newOrder.supplier.id,
        name: newOrder.supplier.name,
      },
      expectedDeliveryDate: newOrder.expectedDeliveryDate,
    }
    // Create or update order
    const orderResult = newOrder.id
      ? await updateOrder(newOrder.id, params, {
          firstName: user.firstName,
          lastName: user.lastName,
          skipSendingEmail,
        })
      : await createOrder(params, {
          firstName: user.firstName,
          lastName: user.lastName,
          skipSendingEmail,
        })
    if (
      ![400, 401, 402, 403, 404, 405, 406, 407, 408, 409].includes(
        orderResult.status
      )
    ) {
      if (orderResult) {
        const message =
          orderResult.status === "PLACED" ? "Order placed!" : "Order saved!"
        showSuccess(message)

        if (!skipNavigateToOverview) {
          await navigate("/dashboard/purchases/orders", { replace: true })
          dispatch({ type: "RESET_NEW_ORDER" })
        }
      }
    } else {
      // Case plan does not allow this
      if (orderResult.status === 403) {
        // do nothing, message comes from api.js
      } else if (orderResult.status?.toString().charAt(0) === "4") {
        let errorText = DEFAULT_ERROR_MESSAGE
        if (orderResult?.errors?.length > 0) {
          errorText = orderResult.errors[0].defaultMessage
        } else if (orderResult.message) {
          errorText = orderResult.message
        }
        showError(errorText)
      } else {
        showError(DEFAULT_ERROR_MESSAGE)
      }
    }
    setLoading(false)
    return orderResult
  }

  const nextStep = (_skipSendingEmail?: boolean) => {
    const flatSteps = steps.map((s) => s.key)
    const currentStep = flatSteps.indexOf(step)
    if (currentStep === steps.length - 1) {
      const skipNotification =
        newOrder.status === "REVIEW" ? true : skipSendingEmail

      if (!userCanModifyOrder) {
        return
      }

      const checkMinOrderValue = async () => {
        const supplier = await getSupplierById(newOrder.supplier.id)
        const minOrderValue = supplier?.minOrderConfig?.minOrderValue
        const orderTotal = totalPrice()
        const orderIsAboveMinValue = minOrderValue
          ? orderTotal >= minOrderValue
          : true

        if (!orderIsAboveMinValue) {
          return modal.showModal(PlaceOrderModal, {
            title: `Are you sure you want to place this order?`,
            text: `The total is below the min order value of ${formatCurrencyValue(
              minOrderValue
            )} for this supplier`,
            confirmButtonText: "Place Order",
            cancelButtonText: "Close",
            onConfirm: ({ shouldNotifySupplier }) =>
              saveOrder(
                "PLACED",
                // When the order is created from an Review status, no email to the supplier should be send
                shouldNotifySupplier ? shouldNotifySupplier : skipNotification
              ),
          })
        } else {
          return saveOrder(
            "PLACED",
            // When the order is created from an Review status, no email to the supplier should be send
            _skipSendingEmail ? _skipSendingEmail : skipNotification
          )
        }
      }

      return checkMinOrderValue()
    } else {
      navigate(`?step=${steps[currentStep + 1].key}`)
    }
  }

  const removeOrderItem = (itemId) => {
    const newOrderItems = newOrder.items.filter((item) => item.id !== itemId)
    dispatch({ type: "UPDATE_NEW_ORDER", payload: { items: newOrderItems } })
    setDirty(true)
  }

  const prevStep = () => {
    const flatSteps = steps.map((step) => step.key)
    const currentStep = flatSteps.indexOf(step)
    if (currentStep === 0) {
      return
    }
    navigate(`?step=${steps[currentStep - 1].key}`)
  }

  const handleSelectionChange = (items) => {
    dispatch({ type: "UPDATE_NEW_ORDER", payload: { items } })
    setDirty(true)
  }

  const handleAddItemToOrder = (item) => {
    const newItems = newOrder.items.concat([item])
    handleSelectionChange(newItems)
    return newItems // Returning complete array with newly added item
  }

  const handleResolveOrderDiscrepancy = async (order, discrepancy, product) => {
    const params = {
      orderId: order.id,
      discrepancyId: discrepancy.id,
      payload: { status: DiscrepancyStatus.RESOLVED },
    }

    const formattedProduct = {
      ...product,
      orderedInCase: product.packaging !== "single", //Ensures right case is selected - assigns price correctly
    }

    return modal.showModal(ConfirmModal as any, {
      title: "Add item",
      text: "Do you want to add to this order",
      confirmButtonText: "Add",
      onConfirm: async () => {
        const newItems = handleAddItemToOrder(formattedProduct)
        const orderSaveResponse = await saveOrder(
          newOrder.status,
          true, //skipSendingEmail
          newItems,
          true //skipNavigateToOverview
        )
        if (orderSaveResponse.status.toString().startsWith(4)) {
          //Prevent adding a product until order becomes fixed
          console.log("Error when saving order, 4-- type error ")
          return
        } else {
          await updateOrderDiscrepancy(params)
          await getDiscrepancies(newOrder.id).then(setDiscrepancies)
        }
      },
    })
  }

  const totalPrice = () => {
    if (!itemCount) {
      return 0
    }
    const filteredItems = newOrder.items.filter((item) => item.quantity)
    let price = 0
    for (let i = 0; i < filteredItems.length; i++) {
      const item = filteredItems[i]
      const itemPrice = item.orderInCase
        ? parseFloat(item.productCase.price)
        : parseFloat(item.price)
      price += item.quantity * itemPrice
    }
    return newOrder.supplier && newOrder.supplier.currency
      ? formatCurrencyValue(price)
      : roundNumber(price)
  }

  const emailSupplierModal = () => {
    modal.showModal(ConfirmModal as any, {
      type: "success",
      title: "Finalise order",
      text: `Do you want to notify ${newOrder.supplier.name} of this order by email?`,
      confirmButtonText: "Yes",
      rejectButtonText: "No",
      showCancel: false,
      showReject: true,
      onConfirm: nextStep,
      onReject: () => {
        setSkipSendEmail(true)
        nextStep(true)
      },
      // return
    })
  }

  /*
  Set step from query param
   */
  useEffect(() => {
    if ((orderRequiresApproval && !userIsApprover) || orderIsPlaced) {
      setStep("review")
    } else if (typeof queryParams.step === "string") {
      if (steps.find((s) => s.key === queryParams.step)) {
        if (newOrder.supplier) {
          setStep(queryParams.step)
        }
      }
    }
  }, [queryParams.step, newOrder.supplier, steps])

  useEffect(() => {
    if (orderId) {
      if (!newOrder.id || orderId !== newOrder.id) {
        setLoading(true)
        getOrder(orderId).then((res) => {
          initOrder(res)
        })
      }
      getDiscrepancies(orderId).then(setDiscrepancies)
    }
  }, [orderId])

  useEffect(() => {
    if (
      !organization ||
      (newOrder.deliveryAddress &&
        newOrder.deliveryAddress.addressLine1 &&
        newOrder.deliveryAddress.city &&
        newOrder.deliveryAddress.postCode &&
        newOrder.deliveryAddress.country)
    ) {
      return
    }
    const { deliveryAddress, address } = organization
    if (deliveryAddress) {
      dispatch({
        type: "UPDATE_NEW_ORDER",
        payload: { deliveryAddress: deliveryAddress },
      })
    } else {
      dispatch({
        type: "UPDATE_NEW_ORDER",
        payload: { deliveryAddress: address },
      })
    }
  }, [organization, dispatch])

  return (
    <>
      <Helmet>
        <title>Order details</title>
      </Helmet>
      <div className={styles.container}>
        <Header back title={`${orderId ? "Edit" : "New"} order`} />

        <div className={styles.wrapper}>
          {isTabletOrMobile && showItems && itemsList()}

          <div className={styles.main}>
            <div className={styles.content}>
              <div className={styles.subHeader}>
                {steps.map((stepItem) => (
                  <div
                    key={stepItem.key}
                    className={classNames(
                      styles.subHeaderStep,
                      stepItem.key === step ? styles.subHeaderStepActive : ""
                    )}
                  >
                    <FontAwesomeIcon icon={stepItem.icon} />
                    {(isTabletOrMobile && stepItem.key === step) ||
                    !isTabletOrMobile ? (
                      <h3 className="truncate">{stepItem.title}</h3>
                    ) : null}
                  </div>
                ))}
              </div>
              <Loader
                isLoading={loading}
                style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
              >
                Loading..
              </Loader>

              {step === "supplier" && (
                <div className="px-0 h-full lg:px-8 pb-4 lg:pb-6">
                  <SupplierSelect
                    selected={newOrder.supplier || ""}
                    onSelect={handleSupplierSelect}
                  />
                </div>
              )}

              {step === "items" && (
                <div className="-mt-px">
                  <div className="growyze-tabs">
                    <ul>
                      <li>
                        <button
                          className={tab === "items" ? "active" : ""}
                          onClick={() => setTab("items")}
                        >
                          Products
                        </button>
                      </li>
                      {newOrder.status == "REVIEW" && (
                        <li>
                          <button
                            className={tab === "discrepancies" ? "active" : ""}
                            onClick={() => setTab("discrepancies")}
                          >
                            Discrepancies
                            {discrepancies
                              ? ` (${
                                  discrepancies.filter(
                                    ({ status }) => status === "PENDING"
                                  ).length
                                }) `
                              : ``}
                          </button>
                        </li>
                      )}
                    </ul>
                  </div>
                  {
                    {
                      items: !loading && (
                        <SupplierCatalog
                          selectedItems={newOrder.items}
                          onSelectionChange={handleSelectionChange}
                          supplier={newOrder.supplier}
                        />
                      ),
                      discrepancies: (
                        <div>
                          <div className="m-4">
                            {newOrder.extractedFile && (
                              <FileList
                                filesOwner={filesOwner}
                                extractedFile={newOrder.extractedFile}
                                disabled={!userCanModifyOrder}
                              ></FileList>
                            )}
                          </div>
                          <Discrepancies
                            discrepancies={discrepancies}
                            instance={newOrder}
                            handleDiscrepancy={handleResolveOrderDiscrepancy}
                          ></Discrepancies>
                        </div>
                      ),
                    }[tab]
                  }
                </div>
              )}

              {step === "review" && (
                <OrderDetails
                  items={newOrder.items?.filter((item) => item.quantity)}
                  po={newOrder.po}
                  orderNotes={newOrder.notes}
                  address={newOrder.deliveryAddress}
                  expectedDeliveryDate={newOrder.expectedDeliveryDate}
                  placed={newOrder.status === "PLACED"}
                  supplier={newOrder.supplier}
                  isEditable={userCanModifyOrder}
                />
              )}
              {!isTabletOrMobile &&
                step !== "supplier" &&
                userCanModifyOrder && (
                  <button
                    type="button"
                    onClick={prevStep}
                    className={
                      "mb-8 ml-8 button button--autoWidth button--primaryBlue"
                    }
                    style={{ color: "#FFFFFF" }}
                  >
                    <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
                    Back
                  </button>
                )}
            </div>
          </div>
          <div className={styles.sidebar}>
            <div className={styles.sidebarContent}>
              {step === "supplier" && !isTabletOrMobile && (
                <h3 className={styles.sidebarTitle}>Select a supplier</h3>
              )}

              {newOrder.status === "DRAFT" && (
                <div className="items-center flex lg:mb-3 text-sm w-auto lg:text-base mr-6">
                  <span
                    className="rounded-full mr-3"
                    style={{
                      backgroundColor: "#F3D019",
                      width: "10px",
                      height: "10px",
                    }}
                  ></span>
                  <div className="flex flex-col">
                    <span className="font-sansSemiBold font-semibold text-gray-700">
                      Draft
                    </span>
                  </div>
                </div>
              )}
              <span className="block font-semibold text-gray-700 text-sm lg:text-base font-sansSemiBold lg:mb-2">
                {step === "review"
                  ? "Estimated order total"
                  : isTabletOrMobile
                  ? ""
                  : "Total est cost (exc VAT)"}
              </span>
              <h2 className="text-lg lg:mb-3 ml-3 sm:mr-4 lg:ml-0 lg:text-2xl">
                {step === "supplier" ? "" : totalPrice()}
              </h2>

              <div className={styles.selectedSupplier}>
                <span className="text-gray-700 flex-grow truncate">
                  Supplier:{" "}
                  {newOrder.supplier ? (
                    <span className="font-semibold font-sansSemiBold">
                      {newOrder.supplier.name}
                    </span>
                  ) : (
                    "not selected"
                  )}
                </span>
                {newOrder.status !== "PLACED" &&
                  step !== "supplier" &&
                  userCanModifyOrder && (
                    <button
                      type="button"
                      className="text-primaryPink button--autoWidth font-semibold font-sansSemiBold"
                      onClick={() => navigate(`?step=supplier`)}
                      disabled={!userCanModifyOrder}
                    >
                      (Change)
                    </button>
                  )}
              </div>

              {newOrder.status !== "PLACED" &&
                step === "items" &&
                (isTabletOrMobile ? (
                  <button
                    className="text-primaryPink font-sansSemiBold font-semibold w-1/3 text-left order-first"
                    onClick={() => setShowItems(true)}
                  >
                    {itemCount} {itemCount !== 1 ? "items" : "item"}
                    <FontAwesomeIcon icon={faChevronUp} className="ml-2" />
                  </button>
                ) : (
                  itemsList()
                ))}

              {newOrder.status === "PLACED" && (
                <ul className="lg:border-t lg:-mx-8 lg:mt-4 lg:px-8">
                  <li className="flex w-full text-gray-700 py-2 items-center">
                    <span className="flex-grow">
                      <strong className="font-bold font-sansBold">
                        Order number:
                      </strong>{" "}
                      #{newOrder.po}
                    </span>
                  </li>
                </ul>
              )}
            </div>
            <div
              className={
                step === "supplier"
                  ? styles.sidebarFooter + " flex-row-reverse"
                  : styles.sidebarFooter + " flex-row"
              }
            >
              {userCanModifyOrder ? (
                <>
                  {(step === "review" || step === "items") && (
                    <>
                      {isTabletOrMobile && (
                        <button
                          className={
                            "button button--autoWidth button--transparent text-primaryBlue -ml-4 order-1"
                          }
                          onClick={prevStep}
                        >
                          <span className=" text-primaryBlue">
                            <FontAwesomeIcon
                              icon={faArrowLeft}
                              className="mr-2"
                            />
                            Back
                          </span>
                        </button>
                      )}
                      {newOrder.status === "REVIEW" ? (
                        <button
                          className="text-center button--autoWidth lg:-mt-3 text-gray-800 font-sansSemiBold font-semibold py-3 px-4 hover:underline order-2 mb-1"
                          onClick={() => saveOrder("REVIEW", true)}
                          disabled={
                            (step === "items" && !itemCount) ||
                            !userCanModifyOrder
                          }
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          className="text-center button--autoWidth lg:-mt-3 text-gray-800 font-sansSemiBold font-semibold py-3 px-4 hover:underline order-2 mb-1"
                          onClick={() => saveOrder("DRAFT", true)}
                          disabled={
                            (step === "items" && !itemCount) ||
                            !userCanModifyOrder
                          }
                        >
                          Save{!isTabletOrMobile ? " as draft" : ""}
                        </button>
                      )}
                    </>
                  )}

                  {step === "review" && !isTabletOrMobile && (
                    <div
                      className={
                        !isTabletOrMobile
                          ? "flex justify-center pb-4"
                          : "flex justify-center"
                      }
                    >
                      {newOrder.status !== "REVIEW" && (
                        <label className="inline-flex items-center">
                          <input
                            type="checkbox"
                            checked={!skipSendingEmail}
                            onChange={() => {
                              if (tooltipElement.current) {
                                if (skipSendingEmail) {
                                  ReactTooltip.hide(tooltipElement?.current)
                                } else {
                                  ReactTooltip.show(tooltipElement.current)
                                }
                              }
                              setSkipSendEmail(!skipSendingEmail)
                            }}
                            className="form-checkbox text-primaryPink"
                          />
                          <span className="ml-2 text-gray-700 text-sm lg:text-base">
                            Email supplier
                          </span>
                          {newOrder.supplier && (
                            <span
                              data-tip={`${newOrder.supplier.name} will not be notified. Are you sure?`}
                              ref={tooltipElement}
                            ></span>
                          )}
                          <ReactTooltip
                            place="top"
                            effect="solid"
                            type="light"
                            offset={
                              !isTabletOrMobile
                                ? { left: 115, top: 15 }
                                : { left: 40, top: 4 }
                            }
                            border={true}
                            borderColor="#e2e8f0"
                            multiline={true}
                            className={styles.supplierTooltip}
                          />
                        </label>
                      )}
                    </div>
                  )}

                  <button
                    className={
                      isTabletOrMobile
                        ? "button button--autoWidth button--primaryPink order-last"
                        : "button button--primaryPink order-last"
                    }
                    onClick={() => {
                      if (step === "review" && isTabletOrMobile) {
                        emailSupplierModal()
                      } else {
                        setLoading(true)
                        nextStep()
                      }
                    }}
                    disabled={
                      loading ||
                      (step === "supplier" && !newOrder.supplier) ||
                      (step === "items" && !itemCount) ||
                      (step === "review" && newOrder.status === "PLACED")
                    }
                  >
                    <span className="hidden lg:inline">
                      {displayActionLabel()}
                    </span>
                    <FontAwesomeIcon className="lg:ml-3" icon={faArrowRight} />
                  </button>
                </>
              ) : (
                <button
                  className={
                    isTabletOrMobile
                      ? "button button--autoWidth button--primaryPink order-last"
                      : "button button--primaryPink order-last"
                  }
                  onClick={async () => {
                    await navigate("/dashboard/purchases/orders", {
                      replace: true,
                    })
                    dispatch({ type: "RESET_NEW_ORDER" })
                  }}
                >
                  <span className="hidden lg:inline">Close</span>
                  <FontAwesomeIcon className="lg:ml-3" icon={faArrowRight} />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditOrder
