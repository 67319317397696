import React, { useContext } from "react"

// import { format } from "date-fns"
import { showSuccess, showError } from "services/toast"
import classNames from "classnames"
import { faArrowRight, faCommentAlt } from "@fortawesome/pro-regular-svg-icons"
import { stockTransferStates } from "services/constants"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ActionsButton from "components/common/ActionsButton/ActionsButton"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import StockTransfer from "components/stock/transfers/StockTransfer/StockTransfer"
import StockTransferConfirmModal from "../StockTransferConfirmModal/StockTransferConfirmModal"
import {
  deleteStockTransferById,
  cancelStockTransfer,
} from "services/stock-transfer"
import PropTypes from "prop-types"
import { ModalContext } from "context/ModalContext"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/GlobalContextProvider"
import { convertTimeStampFormat } from "services/helpers"
import usePermissions from "hooks/usePermissions"
import * as styles from "./StockTransferItem.module.css"
import { Permission } from "services/types"

const StockTransferItem = ({
  data,
  direction = "out",
  onDeleted,
  onUpdated,
}) => {
  const modal = useContext(ModalContext)
  const dispatch = useContext(GlobalDispatchContext)
  const { organization } = useContext(GlobalStateContext)
  const isOutgoing = direction === "out"
  const permissionObj = usePermissions("Transfers") as Permission

  const { status, receiverOrg, senderOrg, hasProductsComments, ...rest } = data

  const standardData = {
    orgName: isOutgoing ? receiverOrg.companyName : senderOrg.companyName,
    orgId: isOutgoing ? receiverOrg.id : senderOrg.id,
    status: isOutgoing ? status : status === "SENT" ? "INCOMING" : status,
    city: isOutgoing ? receiverOrg.address.city : senderOrg.address.city,
    numItems:
      status !== "DRAFT"
        ? rest.products.length
        : rest.products.length +
          (rest?.recipes?.length ?? 0) +
          (rest?.dishes?.length ?? 0),
    ...rest,
  }

  const statusColor = stockTransferStates.find(
    (state) => state.value === status
  )?.color

  const hasComments = hasProductsComments

  const onEdit = (data) => {
    dispatch({ type: "RESET_NEW_STOCK_TRANSFER" })
    dispatch({
      type: "CREATE_NEW_STOCK_TRANSFER",
      payload: {
        stockTransferData: data,
      },
    })

    return modal.showModal(StockTransfer, {
      isOutgoing: isOutgoing,
      onUpdate: () => onUpdated(),
    })
  }

  const onCancel = (data) => {
    modal.showModal(StockTransferConfirmModal, {
      type: "danger",
      title: `Are you sure?`,
      text: `Are you sure you want to cancel this transfer?`,
      confirmButtonText: "Cancel",
      onConfirm: () => handleCancel(data),
    })
  }

  const handleCancel = async (data) => {
    const params = {
      stockTransferId: data.id,
    }

    const result = await cancelStockTransfer(params)
    if (result && !result.message) {
      showSuccess("Transfer cancelled!")
    } else {
      showError("Delete failed, please try again later")
    }
    onUpdated()

    return
  }

  const handleDelete = async (data) => {
    const deleted = await deleteStockTransferById({ stockTransferId: data.id })
    if (deleted && !deleted.message) {
      showSuccess("Transfer deleted!")
      onDeleted()
    } else {
      showError("Delete failed, please try again later")
    }
    onUpdated()
    return
  }

  const onDelete = (data) =>
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: "Delete stock transfer",
      text: "Are you sure you want to delete this transfer? Once deleted, there's no going back and cannot be undone!",
      confirmButtonText: "Delete",
      onConfirm: () => handleDelete(data),
    })

  const handleActionClick = (action, data) => {
    const { type } = action
    switch (type) {
      case "stockTransfer.view":
        onEdit(data)
        break
      case "stockTransfer.edit":
        onEdit(data)
        break
      case "stockTransfer.cancel":
        onCancel(data)
        break
      case "stockTransfer.delete":
        onDelete(data)
        break
      default:
        break
    }
  }

  const handleEdit = (e) => {
    e.preventDefault()
    handleActionClick({ type: "stockTransfer.edit" }, data)
  }

  const actions = (rowData) => {
    const options = [
      {
        key: "stockTransfer.view",
        title: "View details",
        disabled: !permissionObj?.permissions.read,
        type: "",
      },
      {
        key: "stockTransfer.edit",
        title: "Edit",
        disabled: !permissionObj?.permissions.modify,
        type: "",
      },
    ]

    if (rowData.status === "SENT") {
      options.push({
        key: "stockTransfer.cancel",
        title: "Cancel transfer",
        type: "danger",
        disabled: !permissionObj?.permissions.modify,
      })
    }

    if (rowData.status === "DRAFT") {
      options.push({
        key: "stockTransfer.delete",
        title: "Delete",
        type: "danger",
        disabled: !permissionObj?.permissions.delete,
      })
    }

    return (
      <div className="px-3 flex-shrink-0" style={{ textAlign: "right" }}>
        <ActionsButton
          options={options}
          onActionClick={(action) => handleActionClick(action, rowData)}
        />
      </div>
    )
  }

  return (
    <div className="border -mb-px" style={{ backgroundColor: "#FFFFFF" }}>
      <div className="flex flex-wrap items-center content-center justify-between">
        <div className="flex items-center">
          <button
            onClick={(e) => {
              handleEdit(e)
            }}
            className={classNames(styles.icon, {
              [styles.iconIncoming]: !isOutgoing,
            })}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </button>

          <button
            onClick={(e) => {
              handleEdit(e)
            }}
            disabled={!permissionObj?.permissions.modify}
            style={{ width: "200px", textAlign: "left" }}
            className=" focus:outline-none py-3 pr-3 flex flex-col relative"
          >
            <span className="font-sansSemiBold font-semibold text-primaryBlue flex items-center">
              {status === "SENT" && (
                <span className={styles.actionIndicator}></span>
              )}{" "}
              {standardData.orgName}
            </span>
            <div className="text-sm text-gray-700">{standardData.city}</div>
          </button>
        </div>

        <div className="flex flex-col p-3" style={{ minWidth: "150px" }}>
          <span className="font-sansSemiBold font-semibold">
            <div className={styles.status}>
              <span
                className={styles.statusDot}
                style={{ background: statusColor }}
              ></span>
              {standardData.status.toLowerCase()}
            </div>
          </span>
        </div>

        <div className="flex flex-col p-3" style={{ minWidth: "150px" }}>
          <span className="text-sm text-gray-700">Items</span>
          <span className="font-sansSemiBold font-semibold flex items-center">
            {standardData.numItems}
            {hasComments && (
              <div className={styles.commentIcon}>
                <div className={styles.commentIndicator}></div>
                <FontAwesomeIcon icon={faCommentAlt} />
              </div>
            )}
          </span>
        </div>

        <div className="flex flex-col p-3" style={{ minWidth: "150px" }}>
          <span className="text-sm text-gray-700">Value</span>
          <span className="font-sansSemiBold font-semibold flex items-center">
            {isOutgoing
              ? standardData.sentTotalCost
                ? standardData.sentTotalCost
                : "-"
              : standardData.receivedTotalCost
              ? standardData.receivedTotalCost
              : "-"}
          </span>
        </div>

        <div className="flex flex-col p-3" style={{ minWidth: "150px" }}>
          <span className="text-sm text-gray-700">Sent:</span>
          <span className="font-sansSemiBold font-semibold">
            <span className="text-gray-700 text-sm font-sansSemiBold font-semibold flex items-center">
              {standardData.sentAt
                ? convertTimeStampFormat(
                    data.sentAt,
                    organization?.address.zoneId
                  )
                : "-"}
            </span>
          </span>
        </div>

        <div className="flex flex-col p-3" style={{ minWidth: "150px" }}>
          <span className="text-sm text-gray-700">Received:</span>
          <span className="font-sansSemiBold font-semibold">
            <span className="text-gray-700 text-sm font-sansSemiBold font-semibold flex items-center">
              {standardData.receivedAt
                ? convertTimeStampFormat(
                    data.receivedAt,
                    organization?.address.zoneId
                  )
                : "-"}
            </span>
          </span>
        </div>

        {actions(data)}
      </div>
    </div>
  )
}

StockTransferItem.propTypes = {
  data: PropTypes.object,
  direction: PropTypes.string,
  onDeleted: PropTypes.func,
  onUpdated: PropTypes.func,
}

export default StockTransferItem
