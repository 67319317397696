import { faCoins, faPercent } from "@fortawesome/pro-light-svg-icons"
import IconToggle from "components/common/IconToggle/IconToggle"
import TextInput from "components/forms/TextInput"
import usePermissions from "hooks/usePermissions"
import React, { useEffect, useState } from "react"
import { Permission } from "services/types"

interface Props {
  data: any
  isWastePercentage: boolean
  setIsWastePercentage: React.Dispatch<React.SetStateAction<boolean>>
}

const RecipeAndDishWaste = ({
  data,
  isWastePercentage,
  setIsWastePercentage,
}: Props) => {
  const permissionObj = usePermissions("Recipes & Dishes") as Permission
  const [waste, setWaste] = useState(data?.waste)
  const [ingredientCost, setIngredientCost] = useState<number>(0)

  useEffect(() => {
    if (data?.waste) {
      setWaste({ ...data?.waste })
      setIngredientCost(data?.totalCost - data?.waste?.cost)
    } else {
      setWaste({ percent: null, cost: null })
      setIngredientCost(data?.totalCost || 0)
    }
  }, [data])

  return (
    <div className="flex flex-wrap w-full p-5 gap-y-4 items-end gap-x-4">
      <div className="flex flex-col">
        <div className="flex items-center pb-2 gap-x-2">
          <IconToggle
            onChange={(val) => setIsWastePercentage(!val)}
            noBackground={true}
            value={isWastePercentage}
            icons={[
              { icon: faPercent, value: false },
              { icon: faCoins, value: true },
            ]}
          />

          <span>{isWastePercentage ? "Percentage" : "Amount"}</span>
        </div>

        <div className="input-container flex items-center">
          <TextInput
            name={isWastePercentage ? "waste.percent" : "waste.cost"}
            label={isWastePercentage ? "Percentage" : "Cost"}
            type="number"
            disabled={!permissionObj?.permissions.modify}
            placeholder={isWastePercentage ? "Percentage" : "Amount"}
            className="form-control form-control--first form-control--last"
            showValidations={false}
            value={
              isWastePercentage
                ? waste?.percent
                  ? waste?.percent
                  : ""
                : waste?.cost
                ? waste?.cost
                : ""
            }
            onChange={(e) => {
              if (isWastePercentage) {
                setWaste({ ...waste, percent: e.target.value })
              } else {
                setWaste({ ...waste, cost: e.target.value })
              }
            }}
          />
        </div>
      </div>

      {isWastePercentage &&
        waste?.percent &&
        !isNaN(ingredientCost) &&
        ingredientCost === 0 && (
          <div className="flex pb-3 text-orange-600 text-sm">
            Save to see cost
          </div>
        )}

      {isWastePercentage &&
        waste?.percent &&
        !isNaN(ingredientCost) &&
        ingredientCost !== 0 && (
          <div className="flex pb-3">
            Cost: {((waste?.percent / 100) * ingredientCost).toFixed(2)}
          </div>
        )}
    </div>
  )
}

export default RecipeAndDishWaste
