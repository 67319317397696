import React from "react"
import { faEllipsisH } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Select, { components } from "react-select"

const ActionsButton = ({
  options,
  onActionClick,
  buttonClass = "",
  disabled = false,
  dropDirection = "auto",
  asPortal = true,
}) => {
  const getItemColorFromType = (type) => {
    if (type === "danger") {
      return "rgb(252, 55, 98)" //brandPink
    } else if (type === "success") {
      return "rgb(53, 204, 195)" //brandGreen
    } else if (type === "warning") {
      return "rgb(237, 137, 54)" //brandOrange
    }
    return "rgb(74,85,104)" //grey
  }

  const customStyles = {
    option: (provided, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...provided,
        backgroundColor: isSelected || isFocused ? "#f4f4f4" : "#ffffff",
        cursor: isDisabled ? "not-allowed" : "pointer",
        fontWeight: 700,
        color: getItemColorFromType(data?.type),
        whiteSpace: "nowrap",
        padding: "0.5rem 1rem",
        opacity: isDisabled ? 0.5 : 1,
        ":hover": {
          ...provided[":hover"],
          backgroundColor: "#f7fafc",
          color: "rgb(45, 55, 72)",
        },
      }
    },
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 1001,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 5,
      width: "16rem",
      right: 0,
      padding: "0.5rem 0",
      textAlign: "left",
    }),
    valueContainer: (provided) => ({
      ...provided,
      position: "absolute",
      opacity: 0,
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: "transparent",
      backgroundColor: "transparent",
      boxShadow: "none",
      zIndex: state.isFocused ? 2 : null,
      minHeight: 32,
      ":hover": {
        ...provided[":hover"],
        borderColor: "transparent",
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      cursor: "pointer",
      ":hover": {
        ...provided[":hover"],
      },
    }),
  }

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon
          size="sm"
          style={{ color: "black" }}
          icon={faEllipsisH}
          rotation={180}
        />
      </components.DropdownIndicator>
    )
  }
  return (
    <Select
      isSearchable={false}
      className={buttonClass}
      options={options}
      styles={customStyles}
      menuPortalTarget={asPortal ? document.querySelector("body") : null}
      menuPosition={"absolute"}
      getOptionLabel={(opt) => opt.title}
      getOptionValue={(opt) => opt.key}
      components={{ DropdownIndicator }}
      onChange={(opt) => {
        onActionClick({ type: opt.key })
      }}
      isDisabled={disabled}
      menuPlacement={dropDirection}
      isOptionDisabled={(opt) => opt.disabled}
    />
  )
}

export default ActionsButton
