import React, { useState } from "react"
import classNames from "classnames/bind"

// @ts-ignore
import * as styles from "./CategorySelect.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMinus, faCaretDown } from "@fortawesome/pro-solid-svg-icons"
import { faPencil } from "@fortawesome/pro-light-svg-icons"

import { Category, SubCategory } from "../../../services/types"

const cx = classNames.bind(styles)

interface CategoryListProps {
  category: Category
  handleCategoryChange: (
    category: Category,
    subCategory?: SubCategory | undefined
  ) => Promise<void>
  onEditCategory?: (category: Category) => void
  disabled?: boolean
}

const CategoryList = ({
  category,
  handleCategoryChange,
  onEditCategory,
  disabled,
}: CategoryListProps) => {
  const [expanded, setExpanded] = useState<boolean>(true)

  const categoryHasInactiveSubCategories = (subCategories: SubCategory[]) => {
    if (!subCategories) return false
    const activeSubCategories = subCategories.filter((subCat) => subCat.active)

    return activeSubCategories.length < subCategories.length
  }

  const hasInactiveSubcategories =
    categoryHasInactiveSubCategories(category?.subCategories) && category.active

  const toggleExpanded = () => setExpanded(!expanded)

  return (
    <div key={category.name}>
      <div className="flex items-center p-2 pl-0">
        <button
          type="button"
          onClick={toggleExpanded}
          className="px-3 py-1 inline-block -ml-3"
        >
          <FontAwesomeIcon
            className={`text-gray-500 transform ${
              expanded ? "rotate-0" : "rotate-180"
            }`}
            icon={faCaretDown}
          />
        </button>
        <label className="text-gray-800 font-sansBold block relative cursor-pointer text-base group">
          <input
            type="checkbox"
            checked={category.active}
            name={category.name}
            className="form-checkbox text-primaryGreen mr-2 cursor-pointer"
            onChange={() => handleCategoryChange(category)}
            disabled={disabled}
          />
          {hasInactiveSubcategories && category.active && (
            <span
              className={cx(
                styles.checkMark,
                " absolute left-0 h-4 w-4 bg-primaryGreen border  border-gray-500 mr-2 pointer-events-none border-none"
              )}
            >
              <FontAwesomeIcon
                icon={faMinus}
                className={category.active ? "block text-white" : "hidden"}
              />
            </span>
          )}
          {category.name}
        </label>

        {onEditCategory && !category.default && !disabled && (
          <button
            onClick={() => onEditCategory(category)}
            className="flex items-center rounded-full px-3 ml-4"
          >
            <FontAwesomeIcon className="text-lg" icon={faPencil} />
          </button>
        )}
      </div>
      <div className={expanded ? "flex flex-col" : "hidden"}>
        {category?.subCategories?.map((subCategory) => {
          return (
            <label
              key={subCategory.name}
              className="flex items-center my-1 ml-10 cursor-pointer text-gray-700"
            >
              <input
                type="checkbox"
                checked={subCategory.active}
                name={subCategory.name}
                className="form-checkbox text-primaryGreen mr-2 cursor-pointer"
                disabled={disabled}
                onChange={() => {
                  return handleCategoryChange(category, subCategory)
                }}
              />
              <span className="text-gray-700 text-sm">{subCategory.name}</span>
            </label>
          )
        })}
      </div>
    </div>
  )
}

export default CategoryList
