// extracted by mini-css-extract-plugin
export var content = "AddWasteForm-module--content--8BKr5";
export var dateField = "AddWasteForm-module--dateField--+5nVi";
export var formBottom = "AddWasteForm-module--formBottom--8JsOq";
export var header = "AddWasteForm-module--header--5O6Ea";
export var inputCol = "AddWasteForm-module--inputCol--1YzNy";
export var productMeta = "AddWasteForm-module--productMeta--eHEiZ";
export var productName = "AddWasteForm-module--productName--1Evm5";
export var quantityInput = "AddWasteForm-module--quantityInput--puNFt";
export var quantityInputSecond = "AddWasteForm-module--quantityInputSecond--7PSM1";
export var quantityLabel = "AddWasteForm-module--quantityLabel--jRUPC";
export var subValueContainer = "AddWasteForm-module--subValueContainer--Jsb+y";
export var subValueLabel = "AddWasteForm-module--subValueLabel--s+zpq";
export var summary = "AddWasteForm-module--summary--zMMT+";
export var summaryItem = "AddWasteForm-module--summaryItem--lehT3";
export var summaryItemList = "AddWasteForm-module--summaryItemList--H-i4M";