/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext, useMemo } from "react"
import { usePromise, usePrevious } from "react-use"
import BaseTable, { AutoResizer, ColumnShape } from "react-base-table"
import { Link } from "gatsby"
import classNames from "classnames"
import { useMediaQuery } from "react-responsive"

import FilterSelect from "components/forms/FilterSelect"
import CartButton from "components/orders/CartButton/CartButton"
import Pagination from "components/common/Pagination/Pagination"
import SearchByParamInput from "components/forms/SearchByParamInput/SearchByParamInput"
import ProductIcon from "components/common/ProductIcon/ProductIcon"
import ItemStatus from "components/common/ItemStatus/ItemStatus"

import {
  getProducts,
  getProductsByBarcodes,
  getProductsFromSupplierMarketplace,
} from "services/product"
import { formatCurrencyValue } from "services/helpers"
import {
  inviteStates,
  productCategoriesCombined,
  productSingleUnits,
  supplierCatalogProductSearchQueryParameters,
} from "services/constants"
import {
  itemIsInCase,
  itemIsOrderedAsCase,
  itemIsOrderedAsSingleAndCase,
} from "services/order"

import { GlobalStateContext } from "context/GlobalContextProvider"
import { Product, Supplier } from "services/types"
import { getAllItemsFromPaginatedResponse } from "../../../services/helpers"

import * as styles from "./SupplierCatalog.module.css"

interface SelectColumnHeaderProps {
  selectedProducts: Product[]
  setSelectedProducts: (items: any) => void
  supplier: Supplier
}

/**
 *  Table Component: Select Column Header
 */
const SelectColumnHeader = ({
  selectedProducts,
  setSelectedProducts,
  supplier,
}: SelectColumnHeaderProps) => {
  const multiSelectActive = selectedProducts?.length > 0
  const params = {
    supplierId: supplier?.id,
    sort: "favourite,desc&sort=name,asc",
    size: 100,
  }
  const handleSelect = async (e) => {
    if (!e.target.checked) {
      setSelectedProducts([])
    } else {
      const allProducts = await getAllItemsFromPaginatedResponse(
        getProductsFromSupplierMarketplace,
        params
      )

      const allProductsAddedToSupplier = await getProductsByBarcodes({
        barcodes: allProducts.map((item) => item.barcode),
        supplierId: supplier.id,
      })

      //Filter out any products already added to supplier
      const filtered = allProducts.filter(
        (prod) =>
          !allProductsAddedToSupplier.find(
            (addedProd) => addedProd.barcode === prod.barcode
          )
      )

      setSelectedProducts(filtered)
    }
  }
  return (
    <div className="mr-4">
      <input
        type="checkbox"
        value={""}
        onChange={handleSelect}
        name="multiSelect"
        className="form-checkbox text-primaryPink"
        checked={multiSelectActive}
      />
    </div>
  )
}

interface StatusCellProps {
  rowData: Product
  productsAddedToSupplier: Product[]
}

/**
 *  Table Component: Status Cell
 */
const StatusCell = ({ rowData, productsAddedToSupplier }: StatusCellProps) => {
  const isAdded = productsAddedToSupplier?.find(
    (product) => product?.barcode === rowData.barcode
  )

  const inviteState: { label: string; value: boolean; color: string } =
    inviteStates.filter((iS) => iS.value === !!isAdded)[0]

  return (
    <div className="flex">
      <ItemStatus style={{ fontSize: "0.9rem" }} status={inviteState} />
    </div>
  )
}

interface Props {
  /**
   * Items currently selected by user
   */
  selectedItems: Product[]
  /**
   * Handles change in selected items
   */
  onSelectionChange: (items: any) => void
  /**
   * Supplier object
   */
  supplier: Supplier | null
  /**
   * Determines whether SupplierCatalog is being used in the context of the supplier marketplace
   */
  fromMarketplace?: boolean
}

/**
 * Supplier Catalog Component
 */
const SupplierCatalog = ({
  selectedItems = [],
  onSelectionChange,
  supplier,
  fromMarketplace = false,
}: Props) => {
  const [products, setProducts] = useState({
    content: [],
  })
  const [productsAddedToSupplier, setProductsAddedToSupplier] = useState([])

  const { newOrder } = useContext(GlobalStateContext)
  const [q, setQ] = useState("")
  const previousQ = usePrevious(q)

  const [paginationData, setPaginationData] = useState({
    page: 0,
    size: 100,
    totalPages: "",
    totalElements: "",
    numberOfElements: "",
  })

  const [category, setCategory] = useState(null)
  const previousCategory = usePrevious(category)

  const [packaging, setPackaging] = useState(null)
  const previousPackaging = usePrevious(packaging)

  const [queryParameter, setQueryParameter] = useState({
    label: "name",
    value: "partialProductName",
  })

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
  const isMobile = useMediaQuery({ maxWidth: 639 })

  const [selectedPackaging, setSelectedPackaging] = useState([])

  const fromPromise = usePromise()

  const [, setLoading] = useState(false)

  const itemHasSelectedPackaging = (rowData) =>
    selectedPackaging.find((product) => {
      return product.id === rowData.id
    })

  const showSKU = useMemo(() => {
    return (
      products.content.find((product: Product) => product.code) !== undefined
    )
  }, [products.content])

  const columns: ColumnShape<Product>[] = [
    {
      key: "name",
      title: "Name",
      dataKey: "name",
      width: 150,
      flexGrow: 1,
      flexShrink: 0,
      className: "font-sansSemiBold font-semibold text-primaryBlue",
      cellRenderer: ({ rowData }) => (
        <div className="flex flex-col my-3">
          <span className=" text-primaryBlue">
            {rowData.name}
            {showSKU &&
              (rowData.code ? (
                <div className="text-xs text-gray-700 font-normal font-sans">
                  {rowData.code}
                </div>
              ) : (
                <div className="text-xs text-error text-opacity-80 font-normal font-sans">
                  Missing SKU
                </div>
              ))}
          </span>
        </div>
      ),
    },
    {
      key: "packaging",
      title: "Packaging",
      width: 100,
      dataKey: "packaging",
      className: "text-sm",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => {
        const hasSelectedPackaging = itemHasSelectedPackaging(rowData)

        const orderedAsCase = itemIsOrderedAsCase(rowData)

        const orderedAsSingleAndCase = itemIsOrderedAsSingleAndCase(rowData)

        const inCase = itemIsInCase(
          orderedAsCase,
          orderedAsSingleAndCase,
          hasSelectedPackaging
        )
        return (
          <>
            <ProductIcon product={rowData} inCase={inCase}></ProductIcon>
            {!orderedAsSingleAndCase && (
              <span className="font-sansSemiBold text-sm font-semibold text-primaryBlue px-2">
                {inCase && `${rowData.productCase.size} x `}
                {rowData.size} {rowData.measure}
              </span>
            )}

            {orderedAsSingleAndCase && (
              <div>
                <select
                  className="font-sansSemiBold text-sm font-semibold text-primaryBlue bg-transparent pl-1 pr-4 border-0"
                  onChange={(event) =>
                    onSelectPackaging(event.target.value, rowData)
                  }
                  defaultValue={inCase ? "multiple" : "single"}
                >
                  <option value="single">
                    {rowData.size} {rowData.measure}
                  </option>
                  <option value="multiple">
                    {rowData.productCase.size} x {rowData.size}{" "}
                    {rowData.measure}
                  </option>
                </select>
              </div>
            )}
          </>
        )
      },
    },
    {
      key: "price",
      title: "Price",
      width: 65,
      dataKey: "price",
      flexGrow: 1,
      className: "text-sm",
      flexShrink: 0,
      cellRenderer: ({ rowData }) => {
        const hasSelectedPackaging = itemHasSelectedPackaging(rowData)

        const orderedAsCase = itemIsOrderedAsCase(rowData)

        const orderedAsSingleAndCase = itemIsOrderedAsSingleAndCase(rowData)

        const inCase = itemIsInCase(
          orderedAsCase,
          orderedAsSingleAndCase,
          hasSelectedPackaging
        )

        return (
          <>
            {!inCase && (
              <span>{`${formatCurrencyValue(rowData.price)} ${
                rowData.unit ? `/ ${rowData.unit.toLowerCase()}` : ""
              }`}</span>
            )}
            {inCase && !hasSelectedPackaging && (
              <span>{`${formatCurrencyValue(
                rowData.productCase.price
              )} / pack`}</span>
            )}
            {inCase && hasSelectedPackaging && (
              <span>
                {hasSelectedPackaging.packaging === "single"
                  ? `${formatCurrencyValue(rowData.price)} ${
                      rowData.unit ? `/ ${rowData.unit.toLowerCase()}` : ""
                    }`
                  : `${formatCurrencyValue(rowData.productCase.price)} / pack`}
              </span>
            )}
          </>
        )
      },
    },
  ]

  if (fromMarketplace) {
    columns.unshift({
      key: "select",
      title: "Select",
      dataKey: "select",
      width: 45,
      flexGrow: 0,
      flexShrink: 0,
      productsAddedToSupplier,
      headerRenderer: (
        <SelectColumnHeader
          selectedProducts={selectedItems}
          setSelectedProducts={onSelectionChange}
          productsAddedToSupplier={productsAddedToSupplier}
          supplier={supplier}
        />
      ),
      cellRenderer: ({ rowData }) => {
        const selectedIndex: number = selectedItems?.findIndex(
          (a: any) => a.barcode === rowData.barcode
        )

        const isAdded = productsAddedToSupplier?.find(
          (product) => product?.barcode === rowData.barcode
        )
        return (
          <div className={"flex items-center justify-center"}>
            <input
              type="checkbox"
              onChange={(e) =>
                handleSelectionChange(e.target.checked, selectedIndex, rowData)
              }
              name="itemSelect"
              className={classNames("form-checkbox text-primaryPink", {
                invisible: isAdded,
              })}
              checked={selectedIndex > -1}
              disabled={isAdded}
            />
          </div>
        )
      },
    })

    columns.push({
      key: "action",
      title: "Added",
      flexGrow: 1,
      flexShrink: 0,
      dataKey: "action",
      width: 100,
      className: "justify-start text-sm",
      productsAddedToSupplier,
      cellRenderer: ({ rowData }) => (
        <StatusCell
          rowData={rowData}
          productsAddedToSupplier={productsAddedToSupplier}
        />
      ),
    })
  } else {
    columns.push({
      key: "action",
      title: fromMarketplace ? "Action" : "Order Qty",
      flexGrow: 1,
      flexShrink: 0,
      dataKey: "quantity",
      width: 100,
      className: "justify-start text-sm",
      cellRenderer: ({ rowData }) => {
        const hasSelectedPackaging = itemHasSelectedPackaging(rowData)

        const orderedAsCase = itemIsOrderedAsCase(rowData)

        const orderedAsSingleAndCase = itemIsOrderedAsSingleAndCase(rowData)

        const inCase = itemIsInCase(
          orderedAsCase,
          orderedAsSingleAndCase,
          hasSelectedPackaging
        )

        let quantity
        if (rowData.quantity) {
          quantity = rowData.quantity
        }
        if (selectedItems.length !== 0) {
          const filteredItem = selectedItems.filter(
            (item) => item.id === rowData.id
          )[0]

          if (filteredItem) {
            quantity = filteredItem.quantity
          } else {
            quantity = 0
          }
        }

        return (
          <CartButton
            quantity={quantity}
            onChange={(amount) => {
              handleItemQuantityChange(rowData, amount, inCase)
            }}
            allowFloat
            initialValue={isTabletOrMobile ? "" : 1}
          />
        )
      },
    })
  }

  const getData = async () => {
    setLoading(true)

    const params = {
      supplierId: supplier?.id,
      page: paginationData.page,
      sort: "favourite,desc&sort=name,asc",
      size: paginationData.size,
    }

    if (q) {
      switch (queryParameter.value) {
        case "partialProductName":
          params.partialProductName = q
          break
        case "partialBarcode":
          params.partialBarcode = q
          break
        case "partialCode":
          params.partialCode = q
          break
        case "partialPosId":
          params.partialPosId = q
          break
        default:
          params.partialProductName = q
          break
      }
      if (previousQ !== q) {
        params.page = 0
      }
    }

    if (packaging) {
      params.unit = packaging.value

      if (previousPackaging && previousPackaging.value !== packaging.value) {
        params.page = 0
      }
    }

    if (category) {
      params.subCategory = category.value

      if (previousCategory && previousCategory.value !== category.value) {
        params.page = 0
      }
    }

    const getItems = fromMarketplace
      ? getProductsFromSupplierMarketplace
      : getProducts

    // fromPromise prevents call on unmount of component
    const result = await fromPromise(getItems(params))

    if (result && !result.error) {
      const merged = { ...products, ...result }
      merged.content.map((a) => {
        const found = newOrder?._initialItems?.find((b) => b.productId === a.id)

        if (found) {
          //Can directly modify here
          a._productDbPrice = a.price
          a.price = found.price
          if (found?.productCase?.price) {
            a.productCase._productDbPrice = a.productCase.price
            a.productCase.price = found.productCase.price
          }
        }
        return a
      })

      if (fromMarketplace && supplier?.id && merged.content.length) {
        const addedProducts = await getProductsByBarcodes({
          barcodes: merged.content.map((item) => item.barcode),
          supplierId: supplier.id,
        })

        setProductsAddedToSupplier(addedProducts)
      }

      setProducts(merged)
      setPaginationData({
        ...paginationData,
        size: result.size,
        totalPages: result.totalPages,
        totalElements: result.totalElements,
        numberOfElements: result.numberOfElements,
      })
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const handlePageClick = ({ selected }) => {
    setPaginationData({ ...paginationData, page: selected })
  }

  const handleSelectionChange = (
    checked: boolean,
    selectedIndex: number,
    rowData: any
  ) => {
    const latestItems: any[] = [...selectedItems]
    if (checked && selectedIndex <= -1) {
      latestItems.push(rowData)
    } else {
      latestItems.splice(selectedIndex, 1)
    }
    onSelectionChange(latestItems)
  }

  const handleItemQuantityChange = (selectedItem, quantity, orderInCase) => {
    const newSelectedItem = { ...selectedItem, quantity, orderInCase }
    const newSelectionList = [...selectedItems]
    const isSelectedItem = (item) => item.id === newSelectedItem.id
    const itemIndex = newSelectionList.findIndex(isSelectedItem)
    const itemExists = itemIndex !== -1

    if ((quantity === 0 || quantity === "0") && itemExists) {
      newSelectionList.splice(itemIndex, 1)
      return onSelectionChange(newSelectionList)
    }

    if (itemExists && selectedItems.length) {
      return onSelectionChange(
        newSelectionList.map((item) => {
          if (isSelectedItem(item)) {
            return newSelectedItem
          } else {
            return item
          }
        })
      )
    } else {
      if (quantity !== 0 && quantity !== "0" && quantity !== undefined) {
        return onSelectionChange(newSelectionList.concat(newSelectedItem))
      }
    }
  }

  const onSelectPackaging = (value, productData) => {
    const index = selectedPackaging.findIndex(
      (element) => element.id === productData.id
    )

    const alreadyExists = index !== -1

    const orderedProduct = selectedItems.find((element) => {
      return element.id === productData.id
    })

    if (value === "single") {
      if (orderedProduct) {
        handleItemQuantityChange(productData, orderedProduct.quantity, false)
      }

      if (!alreadyExists) {
        const newProduct = {
          name: productData.name,
          id: productData.id,
          packaging: "single",
        }

        setSelectedPackaging([...selectedPackaging, { ...newProduct }])
      }

      if (alreadyExists) {
        const newState = [...selectedPackaging]
        const updatedElement = { ...newState[index] }
        updatedElement.packaging = "single"
        newState[index] = updatedElement
        setSelectedPackaging(newState)
      }
    }

    if (value === "multiple") {
      if (orderedProduct) {
        handleItemQuantityChange(productData, orderedProduct.quantity, true)
      }

      if (!alreadyExists) {
        const newProduct = {
          name: productData.name,
          id: productData.id,
          packaging: "multiple",
        }

        setSelectedPackaging([...selectedPackaging, { ...newProduct }])
      }

      if (alreadyExists) {
        const newState = [...selectedPackaging]
        const updatedElement = { ...newState[index] }
        updatedElement.packaging = "multiple"
        newState[index] = updatedElement
        setSelectedPackaging(newState)
      }
    }
  }

  useEffect(() => {
    if (!fromMarketplace) {
      // Set initial selected packaging array in first render
      const selectedPackaging = selectedItems.map((product) => {
        return {
          name: product.name,
          id: product.id,
          packaging: product.orderInCase ? "multiple" : "single",
        }
      })

      setSelectedPackaging(selectedPackaging)
    }
  }, [])

  useEffect(() => {
    getData()
  }, [q, packaging, category, paginationData.page, supplier])

  return (
    <div className={styles.container}>
      <div className={styles.subHeader}>
        {!isMobile && (
          <FilterSelect
            options={productSingleUnits}
            value={packaging}
            onChange={async (val) => {
              setPackaging(val)
            }}
            placeholder="All packaging"
            isClearable={true}
            isSearchable={true}
            className="my-2 w-32 md:w-40 mr-4 md:mr-6"
          />
        )}
        <FilterSelect
          options={productCategoriesCombined}
          value={category}
          onChange={(val) => {
            setCategory(val)
          }}
          placeholder="Category"
          isClearable={true}
          isSearchable={true}
          getOptionLabel={(opt) => `${opt.groupLabel} - ${opt.label}`}
          className="my-2 w-40 md:w-48 mr-4 lg:mr-6"
        />
        <SearchByParamInput
          currentParam={queryParameter}
          setCurrentParam={setQueryParameter}
          setSearchValue={setQ}
          paramOptions={supplierCatalogProductSearchQueryParameters}
        />
      </div>

      <div className={styles.content}>
        <div className="w-full h-auto">
          <AutoResizer height={Infinity}>
            {({ width }) => (
              <BaseTable
                fixed={isTabletOrMobile ? true : false}
                estimatedRowHeight={70}
                width={width}
                ignoreFunctionInColumnCompare={false} // Very important (hooks won't work otherwise)
                maxHeight={products.content.length === 0 ? undefined : Infinity}
                height={products.content.length === 0 ? 150 : undefined}
                data={products.content}
                columns={columns}
                emptyRenderer={
                  fromMarketplace ? (
                    <div className={styles.noProducts}>
                      There are no products to add for this supplier.
                    </div>
                  ) : (
                    <div className={styles.noProducts}>
                      There are no products set up for this supplier.
                      <div>
                        Go to{" "}
                        <Link
                          className="inline-block underline"
                          to="/dashboard/products/items"
                        >
                          Products → Items → Add item
                        </Link>
                      </div>
                    </div>
                  )
                }
              />
            )}
          </AutoResizer>
        </div>

        {paginationData.totalElements > 20 && (
          <Pagination data={paginationData} onPageClick={handlePageClick} />
        )}
      </div>
    </div>
  )
}

export default SupplierCatalog
