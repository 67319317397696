import React, { useContext } from "react"
import {
  deleteStockTakeSheet,
  exportStockTakeSheetsXlsx,
} from "services/stock-take"
import { isAfter, parseISO } from "date-fns"
import { showSuccess, showError } from "services/toast"
import { faList, faSyncAlt } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ActionsButton from "components/common/ActionsButton/ActionsButton"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import StockTakeSheet from "components/stock/StockTakeSheet/StockTakeSheet"
import PropTypes from "prop-types"
import { ModalContext } from "context/ModalContext"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/GlobalContextProvider"
import {
  uuidv4,
  convertTimeStampFormat,
  slugify,
  convertTimeStampDate,
} from "services/helpers"

import * as styles from "./StockTakeSheetItem.module.css"
import usePermissions from "hooks/usePermissions"

const StockTakeSheetItem = ({ sheet, onDeleted, onUpdated }) => {
  const modal = useContext(ModalContext)
  const dispatch = useContext(GlobalDispatchContext)
  const { organization } = useContext(GlobalStateContext)
  const permissionObj = usePermissions("Stocktakes")
  const removeStockTake = async (sheet) => {
    const deleted = await deleteStockTakeSheet(sheet.id)
    if (deleted && !deleted.message) {
      showSuccess("Sheet deleted!")
      onDeleted()
    } else {
      showError("Delete failed, please try again later")
    }
  }

  const onEdit = (sheet) => {
    dispatch({ type: "RESET_NEW_STOCKTAKE_SHEET" })
    const params = {
      id: sheet.id,
      name: sheet.name,
      createdAt: sheet.createdAt,
      note: sheet.note,
      key: uuidv4(),
      unsynced: false,
      organizations: sheet.organizations,
      products: [],
      updatedAt: sheet.updatedAt,
      version: sheet.version,
    }
    dispatch({
      type: "CREATE_NEW_STOCKTAKE_SHEET",
      payload: {
        stockTakeSheetData: params,
      },
    })

    return modal.showModal(StockTakeSheet, { onUpdate: () => onUpdated() })
  }

  const onExport = (sheet) => {
    const fileNameFull = slugify(
      `stockTakeSheet_${sheet.name}_${convertTimeStampDate(
        new Date(sheet.createdAt)
      )}`
    )

    exportStockTakeSheetsXlsx(sheet.id, fileNameFull)
  }

  const onDelete = (sheet) =>
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: `Delete ${sheet.name || "Stocktake"}`,
      text: "Are you sure you want to delete this sheet? Once deleted, there's no going back and cannot be undone!",
      confirmButtonText: "Delete",
      onConfirm: () => removeStockTake(sheet),
    })

  const handleActionClick = (action, sheet) => {
    const { type } = action
    switch (type) {
      case "stockTakeSheet.edit":
        onEdit(sheet)
        break
      case "stockTakeSheet.export":
        onExport(sheet)
        break
      case "stockTakeSheet.delete":
        onDelete(sheet)
        break
      default:
        break
    }
  }

  const handleEdit = (e) => {
    e.preventDefault()
    handleActionClick({ type: "stockTakeSheet.edit" }, sheet)
  }

  const actions = (rowData) => {
    const options = [
      {
        key: "stockTakeSheet.edit",
        title: "Edit",
        disabled: !permissionObj?.permissions.read,
      },
      {
        key: "stockTakeSheet.export",
        title: "Export to .XLSX",
        disabled: !permissionObj?.permissions.read,
      },
      {
        key: "stockTakeSheet.delete",
        title: "Delete",
        type: "danger",
        disabled: !permissionObj?.permissions.delete,
      },
    ]

    return (
      <div className="px-3 flex-shrink-0" style={{ textAlign: "right" }}>
        <ActionsButton
          options={options}
          onActionClick={(action) => handleActionClick(action, rowData)}
        />
      </div>
    )
  }

  return (
    <div className="border -mb-px" style={{ backgroundColor: "#FFFFFF" }}>
      <div className="flex flex-wrap items-center content-center justify-between">
        <div className="flex items-center">
          <button
            onClick={(e) => {
              handleEdit(e)
            }}
            className={styles.thumbnail}
            disabled={!permissionObj?.permissions.read}
          >
            <FontAwesomeIcon icon={faList} />
            <span className={styles.thumbnailBadge}>
              <span>
                <FontAwesomeIcon size="xs" icon={faSyncAlt} />
              </span>
            </span>
          </button>

          <button
            onClick={(e) => {
              handleEdit(e)
            }}
            disabled={!permissionObj?.permissions.read}
            style={{ minWidth: "300px", maxWidth: "300px", textAlign: "left" }}
            className="font-sansSemiBold focus:outline-none font-semibold text-primaryBlue py-3 px-3 flex flex-col"
          >
            <span>{sheet.name || "New stocktake"}</span>
            <div className="flex items-center">
              {isAfter(parseISO(sheet.updatedAt), parseISO(sheet.createdAt)) ? (
                <span className="text-gray-700 text-sm font-sansSemiBold font-semibold flex items-center">
                  <span className="mr-1">Updated at:</span>{" "}
                  {convertTimeStampFormat(
                    sheet.updatedAt,
                    organization?.address.zoneId
                  )}
                </span>
              ) : (
                <span className="text-gray-700 text-sm font-sansSemiBold font-semibold flex items-center">
                  <span className="mr-1">Created at:</span>{" "}
                  {convertTimeStampFormat(
                    sheet.createdAt,
                    organization?.address.zoneId
                  )}
                </span>
              )}
            </div>
          </button>
        </div>
        <div className="flex flex-col p-3" style={{ minWidth: "150px" }}>
          <span className="text-sm text-gray-700">Total items</span>
          <span className="font-sansSemiBold font-semibold">
            {sheet.productsCount}
          </span>
        </div>
        {actions(sheet)}
      </div>
    </div>
  )
}

StockTakeSheetItem.propTypes = {
  sheet: PropTypes.object,
  onDeleted: PropTypes.func,
  onUpdated: PropTypes.func,
}

export default StockTakeSheetItem
