import { GlobalStateContext } from "context/GlobalContextProvider"
import { navigate } from "gatsby"
import React, { useContext } from "react"
import { getOrgNameById } from "services/user"

interface SubSalesItemProps {
  subSales: {
    id: string
    organizations: string[]
    from: string
    to: string
    totalSales: number
  }[]
  formatDate: (date: string) => string
}

const SubSalesItem = ({ subSales, formatDate }: SubSalesItemProps) => {
  const { organization, organizations } = useContext(GlobalStateContext)

  return (
    <div className="w-full bg-paleBlue">
      {subSales.map((subSale) => (
        <div key={subSale.id} className="flex py-4 w-full border-t">
          <div style={{ width: 45, flex: "0 0 auto" }}></div>
          <div style={{ width: 230, flex: "1 0 auto" }} className="pl-8">
            {subSale.organizations[0] === organization.id ? (
              <button
                type="button"
                className="text-primaryBlue text-left"
                onClick={() => navigate(`/dashboard/sales/${subSale.id}`)}
              >
                {formatDate(new Date(subSale.from))} -{" "}
                {formatDate(new Date(subSale.to))}
              </button>
            ) : (
              <span className="text-left">
                {formatDate(new Date(subSale.from))} -{" "}
                {formatDate(new Date(subSale.to))}
              </span>
            )}
          </div>

          <div style={{ width: 150, flex: "1 0 auto" }} className="pl-2">
            {getOrgNameById(subSale.organizations[0], organizations)}
          </div>

          <div style={{ width: 100, flex: "1 0 auto" }} className="pl-2">
            {subSale.totalSales}
          </div>

          <div style={{ width: 100, flex: "1 0 auto" }}></div>

          <div style={{ width: 150, flex: "1 0 auto" }}></div>
        </div>
      ))}
    </div>
  )
}

export default SubSalesItem
