import { GlobalStateContext } from "context/GlobalContextProvider"
import { useContext } from "react"
import { Permission } from "./../services/types"

const usePermissions = (feature?: string): Permission | Permission[] | null => {
  const { organization, user } = useContext(GlobalStateContext)

  if (!user || !organization) {
    return null
  }

  let res: any = user.permissions

  if (res && feature) {
    res = res.find((f) => f.name === feature)
  }

  return res
}

export default usePermissions
