/* eslint-disable react/prop-types */
import React, { useState, useContext, useEffect } from "react"
import axios from "axios"
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "context/GlobalContextProvider"
import { navigate, useLocation } from "@reach/router"
import { updateOnboardingSteps } from "services/onboarding"
import { useMediaQuery } from "react-responsive"
import Helmet from "react-helmet"
import FormData from "form-data"

import Header from "components/dashboard/Header/Header"
import AsyncSelect from "components/forms/AsyncSelect"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import SupplierSelectModal from "components/suppliers/SupplierSelectModal/SupplierSelectModal"
import { useBeforeUnload } from "react-use"
import EditableImportTable from "components/common/EditableImportTable/EditableImportTable"

import {
  productKeys,
  productMeasures,
  productSingleUnits,
} from "services/constants"
import { convertProductFile } from "services/product"
import { createProductsFromJson, searchSuppliers } from "services/supplier"
import {
  isNumber,
  isInteger,
  getKeyByValue,
  extractNumbersFromString,
} from "services/helpers"
import { showError } from "services/toast"
import { ModalContext } from "context/ModalContext"
import EditableImportForm from "../../common/EditableImportForm/EditableImportForm"
import EditableImportHeader from "components/common/EditableImportHeader/EditableImportHeader"
import usePermissions from "hooks/usePermissions"
import { Permission } from "services/types"
import Loader from "components/common/Loader/Loader"

//@ts-ignore
import * as styles from "./ImportProducts.module.css"

const PRODUCT_SAMPLE_FILE_URL =
  "https://growyze-onboarding.s3.eu-west-2.amazonaws.com/Sample_Products_Import.xlsx"

const PRODUCT_IMPORT_EXAMPLE_URL =
  "https://growyze-onboarding.s3.eu-west-2.amazonaws.com/Example_Products_Bulk_Import.xlsx"

const storedKeysStatic = {}
const storedRequiredKeysStatic = {}
productKeys.forEach((itm) => {
  storedKeysStatic[itm.value] = ""
  if (itm.required) {
    storedRequiredKeysStatic[itm.value] = ""
  }
})

const ImportProducts = () => {
  const [productsData, setProductsData] = useState([])
  const [isUploading, setIsUploading] = useState(false)
  const [storedKeys, setStoredKeys] = useState(storedKeysStatic)
  const [hiddenIndexes, setHiddenIndexes] = useState({})
  const [formattingHasError, setFormattingHasError] = useState(false)
  const [supplier, setSupplier] = useState(null)
  const [removeFirstRow, setRemoveFirstRow] = useState(false)
  const [isProcessing, setProcessing] = useState(false)

  useBeforeUnload(isUploading || !!productsData.length, "Are you sure?")

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
  const modal = useContext(ModalContext)
  const location = useLocation()

  const measures = productMeasures.map((measure) => measure.value)
  const measuresLowerCase = productMeasures.map((measure) =>
    measure.value.toLowerCase()
  )
  const units = productSingleUnits.map((unit) => unit.value)
  const unitsLowerCase = productSingleUnits.map((unit) =>
    unit.value.toLowerCase()
  )

  const dispatch = useContext(GlobalDispatchContext)
  const { onboardingSteps } = useContext(GlobalStateContext)
  const permissionObj = usePermissions("Items") as Permission

  // KEYS

  const emptyStoredKeys = Object.keys(storedKeys).filter(
    //These are the requried columns that have not yet been assigned
    (key) =>
      !storedKeys[key].length && storedRequiredKeysStatic.hasOwnProperty(key)
  )

  const reversedStoredKeys = {} // An object of assigned column headers with their corresponding column index as a value
  Object.keys(storedKeys).forEach((key) => {
    if (storedKeys[key] !== "") {
      reversedStoredKeys[storedKeys[key]] = key
    }
  })

  // HANDLERS

  const uploadFile = async (ev) => {
    const formData = new FormData()
    setIsUploading(true)
    formData.append("file", ev.currentTarget.files[0])

    const uploadResult = await convertProductFile(formData)

    setIsUploading(false)

    if (uploadResult.status === 400) {
      showError(uploadResult.message, { closeOnClick: true, autoClose: false })
    } else {
      if (Array.isArray(uploadResult.rows)) {
        setProductsData(uploadResult.rows)
      }
      showSupplierSelect()
    }
  }

  const uploadSampleProducts = async () => {
    try {
      const response = await axios.get(PRODUCT_SAMPLE_FILE_URL, {
        responseType: "blob",
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      })

      if (response) {
        const arrayBuffer = await response.data.arrayBuffer()
        const file = new File([arrayBuffer], "Sample_Products_Import.xlsx", {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })

        const formData = new FormData()
        setIsUploading(true)
        formData.append("file", file)

        const uploadResult = await convertProductFile(formData)

        setIsUploading(false)

        if (uploadResult.status === 400) {
          showError(uploadResult.message, {
            closeOnClick: true,
            autoClose: false,
          })
        } else {
          if (Array.isArray(uploadResult.rows)) {
            setProductsData(uploadResult.rows)
          }
        }
      }
    } catch (error) {
      showError(error.message, { closeOnClick: true, autoClose: false })
    }
  }

  const showSupplierSelect = () => {
    modal.showModal(SupplierSelectModal, {
      noSupplierAllowed: true,
      onSelect: (data) =>
        setSupplier({
          label: data.name,
          value: data.id,
        }),
    })
  }

  const onImport = (data) => {
    modal.showModal(ConfirmModal, {
      title: "Import products",
      text: `You’re about to import ${data.length} products. Do you want to proceed?`,
      confirmButtonText: "Import",
      onConfirm: () => handleImport(data),
    })
  }

  const handleImport = async (data) => {
    setProcessing(true)
    const productsRecord = await createProductsFromJson(
      supplier ? supplier.value : "no-supplier-id",
      data.map((data) => {
        const newItem = {}
        Object.keys(storedKeys).map((key) => {
          if (data[storedKeys[key]]) {
            if (key.indexOf(".") > -1) {
              // Need to format the nested object
              const depth = key.split(".")
              let nestedObj = newItem
              depth.forEach((itmKey, index) => {
                if (index >= depth.length - 1) {
                  nestedObj[itmKey] = data[storedKeys[key]] // Final item, so lets assign the actual value
                } else {
                  if (!nestedObj[itmKey]) {
                    nestedObj[itmKey] = {}
                  }
                }
                nestedObj = nestedObj[itmKey]
              })
            } else {
              newItem[key] = data[storedKeys[key]]
            }
          }
        })

        if (!newItem.hasOwnProperty("price")) {
          //Assigns value of 0 to price if not provided
          newItem.price = "0"
        }

        newItem.price = extractNumbersFromString(
          // removes symbols and letters from price
          newItem.price
        )

        if (newItem.hasOwnProperty("productCase")) {
          if (
            //Assigns value of 0 to pack price if not provided
            !newItem.productCase.price
          ) {
            newItem.productCase.price = "0"
          }

          newItem.productCase.price = extractNumbersFromString(
            // removes symbols and letters from case price
            newItem.productCase.price
          )

          if (
            //Assigns value of 1 to pack size if not provided
            !newItem.productCase.size
          ) {
            newItem.productCase.size = 1
          }
        }

        if (newItem.hasOwnProperty("measure")) {
          //Gets formatted measure
          const measureIndex = measuresLowerCase.indexOf(
            newItem.measure.toLowerCase()
          )
          newItem.measure = measures[measureIndex]
        }

        if (newItem.hasOwnProperty("unit")) {
          //Gets formatted unit
          const unitIndex = unitsLowerCase.indexOf(newItem.unit.toLowerCase())
          newItem.unit = units[unitIndex]
        }

        return newItem
      }),
      true
    )

    if (productsRecord.message) {
      setProcessing(false)
      showError(productsRecord.message, {
        closeOnClick: true,
        autoClose: false,
      })
    } else {
      const params = {
        ...onboardingSteps,
        hasAddedProducts: true,
      }

      const updated = await updateOnboardingSteps(params)

      if (updated && !updated.message) {
        dispatch({
          type: "UPDATE_ONBOARDING_STEPS",
          payload: { onboardingSteps: updated },
        })
      }
      if (productsRecord) {
        setProcessing(false)
        navigate("/dashboard/products/items")
      }
    }
  }

  // VALIDATION

  const verifyField = (key, item = "", row = {}, data = []) => {
    //Called on the data in each cell of the table to verify input can be sent to BE
    let error = ""

    const trimmedItem = item.trim()

    const itemLowerCase = trimmedItem.toLowerCase()

    const itemNrs = extractNumbersFromString(trimmedItem)

    const fieldValues = Object.values(reversedStoredKeys)

    const linkedFields = [
      [
        { label: "Size", value: "size" },
        { label: "Measure", value: "measure" },
      ],
    ]

    const uniqueFields = [{ label: "Barcode", value: "barcode" }]

    if (reversedStoredKeys[key]) {
      //Checks for "linked fields"
      for (let i = 0; i < linkedFields.length; i++) {
        const fieldGroup = linkedFields[i]
        const groupValues = fieldGroup.map((field) => field.value)
        if (groupValues.includes(reversedStoredKeys[key])) {
          const field1 = getKeyByValue(reversedStoredKeys, groupValues[0])
          const field2 = getKeyByValue(reversedStoredKeys, groupValues[1])
          if (row[field2] && !row[field1]) {
            error = `${fieldGroup[0].label} header is needed too`
          }

          if (row[field1] && !row[field2]) {
            error = `${fieldGroup[1].label} header is needed too`
          }
        }
      }

      //Checks for unique fields
      for (let i = 0; i < uniqueFields.length; i++) {
        const fieldValue = uniqueFields[i].value
        const fieldLabel = uniqueFields[i].label
        if (fieldValue === reversedStoredKeys[key]) {
          const uniqueKey = getKeyByValue(reversedStoredKeys, fieldValue)
          const uniqueIndex = uniqueKey.split("-")[1]
          const uniqueColumn = data.map((row) => row.row[uniqueIndex])
          const inArray = []
          uniqueColumn.forEach((uniqueItem) =>
            inArray[uniqueItem] &&
            trimmedItem === uniqueItem.trim() &&
            trimmedItem.length > 0
              ? (error = `${fieldLabel} must be unique`)
              : (inArray[uniqueItem.trim()] = true)
          )
        }
      }

      //Individual field validations
      if (
        reversedStoredKeys[key] === "measure" &&
        !measuresLowerCase.includes(itemLowerCase) &&
        trimmedItem.length > 0
      ) {
        error = `Measure can be ${measures.join(", ")}`
      }

      if (
        reversedStoredKeys[key] === "unit" &&
        !unitsLowerCase.includes(itemLowerCase) &&
        trimmedItem.length > 0
      ) {
        error = `Unit can be ${units.join(", ")}`
      }

      if (
        reversedStoredKeys[key] === "size" &&
        !isNumber(trimmedItem) &&
        trimmedItem.length > 0
      ) {
        error = "Size must be a number"
      }

      if (
        reversedStoredKeys[key] === "productCase.size" &&
        !isInteger(trimmedItem) &&
        trimmedItem.length > 0
      ) {
        error = "Pack size must be a whole number"
      }

      if (
        (reversedStoredKeys[key] === "price" ||
          reversedStoredKeys[key] === "productCase.price") &&
        itemNrs.length < 1 &&
        trimmedItem.length > 0
      ) {
        error = "Price must be a number"
      }

      if (
        reversedStoredKeys[key] === "minQtyInStock" &&
        !isNumber(trimmedItem) &&
        trimmedItem.length > 0
      ) {
        error = "Min qty must be a number"
      }

      if (
        (reversedStoredKeys[key] === "productCase.price" ||
          reversedStoredKeys[key] === "productCase.code") &&
        !fieldValues.includes("productCase.size")
      ) {
        error = "Pack size is required if Pack price or Pack SKU are provided"
      }

      if (
        trimmedItem.length < 1 &&
        storedRequiredKeysStatic.hasOwnProperty(reversedStoredKeys[key])
      ) {
        error = "Field is required"
      }
    }

    return error
  }

  // EFFECTS

  useEffect(() => {
    const data = location.state

    if (data.uploadSampleProducts) {
      uploadSampleProducts()
    }

    if (data.supplierData?.name && data.supplierData?.id) {
      setSupplier({
        label: data.supplierData.name,
        value: data.supplierData.id,
      })
    }
  }, [location])

  return (
    <>
      <Helmet>
        <title>Import Products</title>
      </Helmet>
      <div className={styles.container}>
        <Header back title="Import Products" />

        <div className={styles.content}>
          {productsData.length < 1 && (
            <EditableImportForm
              isUploading={isUploading}
              onUpload={uploadFile}
              exampleFile={PRODUCT_IMPORT_EXAMPLE_URL}
              disabled={!permissionObj?.permissions.modify}
              infoText={
                <div>
                  <p className="text-sm text-gray-700 my-6">
                    You can add as many products as you wish. Here you can store
                    the following information:
                  </p>
                  <ul className="text-sm text-gray-700 mb-6 list-inside list-disc">
                    <li>Item name*</li>
                    <li>
                      Barcode* - allows for seamless stocktaking and waste
                      recording
                    </li>
                    <li>Unit (Bottle, Can, Keg, Other)</li>
                    <li>
                      Size* - needed for accuracy of stocktakes, waste reporting
                      and recipes management
                    </li>
                    <li>
                      Measure* (L, ml, kg, g, lbs, cl, gal, fl oz, oz, each, %)
                      - needed for accuracy of stocktakes, waste reporting and
                      recipes management
                    </li>
                    <li>
                      Cost price* - needed for accuracy of stocktakes, waste
                      reporting and recipes management
                    </li>
                    <li>Category (Food or Beverages)</li>
                    <li>
                      Supplier - provide if you plan to place orders, process
                      delivery notes and validate invoices
                    </li>
                    <li>SKU / Product code (as per your supplier invoices)</li>
                    <li>
                      POS ID - important if you wish to analyse stock
                      discrepancies
                    </li>
                    <li>
                      Min qty in stock - receive alerts for stock below min qty
                      required
                    </li>
                    <li>
                      Pack size (if this items comes in more than one in a
                      package)
                    </li>
                    <li>Pack price</li>
                    <li>
                      Pack SKU (if different to the item SKU / Product code)
                    </li>
                    <li>Product description</li>
                    <li>Notes</li>
                  </ul>
                  <p className="text-sm text-gray-700 mb-6">
                    File size must be less than 10MB.
                  </p>
                </div>
              }
            />
          )}
          {isProcessing && (
            <>
              <Loader
                isLoading={isProcessing}
                style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
              >
                <div
                  style={{
                    textShadow:
                      "0 0 5px white, 0 0 10px white, 0 0 20px white, 0 0 40px white",
                  }}
                  className="text-sm"
                >
                  <p>Your products file is being imported.</p>
                  <p> It may take a few minutes.</p>
                  <p>Please wait.</p>
                </div>
              </Loader>
            </>
          )}

          {productsData.length > 0 && (
            <>
              <EditableImportHeader
                setImportData={setProductsData}
                keys={productKeys}
                storedKeysStatic={storedKeysStatic}
                setStoredKeys={setStoredKeys}
                emptyStoredKeys={emptyStoredKeys}
                setHiddenIndexes={setHiddenIndexes}
                formattingHasError={formattingHasError}
                injectTopRight={
                  <AsyncSelect
                    promise={searchSuppliers}
                    placeholder={"Supplier (optional)"}
                    isClearable={true}
                    isSearchable={isTabletOrMobile ? false : true}
                    optionLabel="name"
                    optionValue="id"
                    onChange={(val) => {
                      setSupplier(val)
                    }}
                    className="my-2 w-full md:w-60"
                    staticOptions={{
                      label: "No Supplier",
                      value: "no-supplier-id",
                    }}
                    defaultValue={
                      supplier
                        ? { value: supplier.value, label: supplier.label }
                        : null
                    }
                    value={
                      supplier
                        ? { value: supplier.value, label: supplier.label }
                        : null
                    }
                    menuPortalTarget={document.body}
                  />
                }
                injectMiddle={
                  <p className="text-sm text-gray-700 mb-6">
                    Please assign at least the following headers:{" "}
                    <strong>
                      Item name, Barcode, Unit, Size, Measure, Cost price.
                    </strong>{" "}
                    <strong>Remove </strong>
                    any rows that do not contain product information ie the file
                    headers.
                  </p>
                }
                removeFirstRow={removeFirstRow}
                setRemoveFirstRow={setRemoveFirstRow}
              />

              <EditableImportTable
                type="product"
                verifyField={verifyField}
                onImport={onImport}
                importData={productsData}
                setImportData={setProductsData}
                keys={productKeys}
                storedKeys={storedKeys}
                setStoredKeys={setStoredKeys}
                emptyStoredKeys={emptyStoredKeys}
                reversedStoredKeys={reversedStoredKeys}
                hiddenIndexes={hiddenIndexes}
                setHiddenIndexes={setHiddenIndexes}
                formattingHasError={formattingHasError}
                setFormattingHasError={setFormattingHasError}
                removeFirstRow={removeFirstRow}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ImportProducts
