import React from "react"
import BaseTable, { AutoResizer, BaseTableProps } from "react-base-table"
import { useMediaQuery } from "react-responsive"
import SetEmptyListState from "./plugins/SetEmptyListState"
import Loader from "components/common/Loader/Loader"
import withPagination from "components/base/withPagination"

import "../base/Dummy-list.css"
import { EmptyProps } from "components/base/EmptyListState"
import hasExpandIcon from "./plugins/hasExpandIcon"

interface ExtBaseTableProps extends BaseTableProps {
  data: any
  empty: EmptyProps
  id?: string
  title?: string
  pagination?: any
  fullHeight?: boolean
  expand?: any
}

// create a custom table
const ExtBaseTable: any = (props: ExtBaseTableProps) => {
  const { id = "unknown" } = props

  // mobile settings
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })

  let forceProps: any = {}

  forceProps = SetEmptyListState(props, forceProps)
  forceProps = hasExpandIcon(props, forceProps)
  const pagination = withPagination({
    pagination: props.pagination,
    data: props.data,
  })

  return (
    <>
      <Loader
        isLoading={props.loading}
        style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
      >
        Loading {props.title}..
      </Loader>
      <div className={`w-full flex-grow ${id}Table`}>
        <AutoResizer height={Infinity}>
          {({ width }) => (
            <>
              <BaseTable
                fixed={isTabletOrMobile}
                ignoreFunctionInColumnCompare={false} // Very important (hooks won't work otherwise)
                maxHeight={Infinity}
                {...{ ...props, ...forceProps }}
                width={width}
              />
              <div style={{ width: width + "px" }}>{pagination}</div>
            </>
          )}
        </AutoResizer>
      </div>
    </>
  )
}

export default ExtBaseTable
