import { GlobalStateContext } from "context/GlobalContextProvider"
import { useContext } from "react"

export const useFileOwner = (entity) => {
  const { organizationPermissions } = useContext(GlobalStateContext)

  return organizationPermissions.general.isMain
    ? entity?.organizations?.[0] || null
    : null
}
