import React, { useContext } from "react"
import PropTypes from "prop-types"
import { Link } from "@reach/router"
import { deliveryNoteStates } from "services/constants"
import { removeDeliveryNote } from "services/delivery-notes"
import { isValid } from "date-fns"
import { faBoxOpen } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPaperclip } from "@fortawesome/pro-light-svg-icons"
import classNames from "classnames/bind"
import ItemStatus from "components/common/ItemStatus/ItemStatus"
import TimeLabel from "components/common/TimeLabel/TimeLabel"
import ActionsButton from "components/common/ActionsButton/ActionsButton"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import DeliveryNoteDetails from "components/delivery-notes/DeliveryNoteDetails/DeliveryNoteDetails"
import { ModalContext } from "context/ModalContext"
import { useMediaQuery } from "react-responsive"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/GlobalContextProvider"

import * as styles from "./DeliveryNoteItem.module.css"
import { showError, showSuccess } from "services/toast"
import { createInvoiceFromDeliveryNote } from "services/invoices"
import usePermissions from "hooks/usePermissions"

const cx = classNames.bind(styles)

const DeliveryNoteItem = ({ note, onDeleted, onUpdated, onMarked }) => {
  const modal = useContext(ModalContext)
  const { organization, organizationPermissions } =
    useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
  const permissionObj = usePermissions("Delivery notes")

  const getCorrectDate = (rowData) => {
    switch (rowData.status) {
      case "COMPLETED":
        return rowData.completedDate
      case "APPROVED":
        return rowData.approvedDate
      case "REJECTED":
        return rowData.rejectedDate
      case "IN_QUERY":
        return rowData.inQueryDate
      default:
        return rowData.dateOfScanning
    }
  }

  const rowState = (rowData) => {
    const dnState = deliveryNoteStates.find((oS) => oS.value === rowData.status)

    return (
      dnState && (
        <ItemStatus status={dnState}>
          <TimeLabel date={getCorrectDate(rowData)} />
        </ItemStatus>
      )
    )
  }

  const deleteDeliveryNote = async (note) => {
    const deleted = await removeDeliveryNote(note.id)
    if (deleted) {
      showSuccess("Delivery note deleted!")
      onDeleted()
    }
  }

  const markDeliveryNoteAsInvoiced = async (note) => {
    const created = await createInvoiceFromDeliveryNote(note.id)
    if (created && created.id) {
      showSuccess("Marked as invoiced!")
      onMarked()
    } else {
      showError(
        created.message ? created.message : "Cannot mark as invoice any more"
      )
    }
  }

  const onEdit = (note) => {
    dispatch({
      type: "RESET_NEW_DELIVERY_NOTE",
      options: { resetSupplier: true },
    })
    dispatch({ type: "UPDATE_NEW_DELIVERY_NOTE", payload: note })
    modal.showModal(DeliveryNoteDetails, { onUpdate: () => onUpdated() })
  }

  const onDelete = (note) =>
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: `Delete ${note.deliveryNoteNumber || "Delivery note"}`,
      text: "Are you sure you want to delete this delivery note?",
      confirmButtonText: "Delete",
      onConfirm: () => deleteDeliveryNote(note),
    })

  const onMarkAsInvoiced = (note) =>
    modal.showModal(ConfirmModal, {
      type: "success",
      title: `Mark ${note.deliveryNoteNumber || "Delivery note"} as invoiced`,
      text: "Are you sure you want to mark this delivery note as invoiced? An invoice will be created based on this note.",
      confirmButtonText: "Mark as invoiced",
      onConfirm: () => markDeliveryNoteAsInvoiced(note),
    })

  const handleActionClick = (action, note) => {
    const { type } = action
    switch (type) {
      case "dn.edit":
        onEdit(note)
        break
      case "dn.delete":
        onDelete(note)
        break
      case "dn.mark_invoiced":
        onMarkAsInvoiced(note)
        break
      default:
        break
    }
  }

  const totalFiles =
    (note.extractedFile ? 1 : 0) + (note.files ? note.files.length : 0)

  /**
   * Action used for both Edit and Re-order
   * @param  {Object} rowData current o rder
   * @return {Function}         shows action buttons
   */
  const actions = (rowData) => {
    let options = [
      {
        key: "dn.edit",
        title: "Edit",
        type: "default",
        disabled: !permissionObj?.permissions.modify,
      },
      {
        key: "dn.delete",
        title: "Delete",
        type: "danger",
        disabled: !permissionObj?.permissions.delete,
      },
    ]
    if (!rowData.isInvoiced) {
      options.unshift({
        key: "dn.mark_invoiced",
        title: "Mark as invoiced",
        type: "default",
        disabled: !permissionObj?.permissions.modify,
      })
    }
    return (
      <div className="px-3 flex-shrink-0 ml-auto text-right">
        {rowData?.organizations.length &&
          rowData?.organizations[0] === organization?.id && (
            <ActionsButton
              options={options}
              onActionClick={(action) => handleActionClick(action, rowData)}
            />
          )}
      </div>
    )
  }

  return (
    <div className="border -mb-px">
      <div className="flex flex-wrap items-center content-center">
        <div className="pl-3 flex w-auto items-center">
          <button
            onClick={(e) => {
              e.preventDefault()
              handleActionClick({ type: "dn.edit" }, note)
            }}
            className={styles.imageWrapper}
            disabled={!permissionObj?.permissions.read}
          >
            <div
              className={styles.iconWrapper}
              style={{
                backgroundColor: "#FAEAFF",
                color: "#B91DB3",
              }}
            >
              <FontAwesomeIcon icon={faBoxOpen} />
            </div>
          </button>

          <button
            onClick={(e) => {
              e.preventDefault()
              handleActionClick({ type: "dn.edit" }, note)
            }}
            disabled={!permissionObj?.permissions.read}
            style={{ minWidth: "150px", maxWidth: "150px", textAlign: "left" }}
            className="font-sansSemiBold focus:outline-none font-semibold text-primaryBlue py-3 px-3 flex flex-col"
          >
            <span className="break-all">
              {note.status === "DRAFT" && "[Draft] "}
              {note.deliveryNoteNumber || "DN-??"}
            </span>
            {note.deliveryDate && isValid(new Date(note.deliveryDate)) && (
              <TimeLabel date={note.deliveryDate} />
            )}
            <Link
              to={`/dashboard/purchases/suppliers/${note.supplier.id}`}
              className="lg:hidden text-gray-700 font-sans block w-full font-normal text-xs truncate"
            >
              {note.supplier.name}
            </Link>
          </button>
        </div>

        {organizationPermissions?.general?.isMain &&
          note?.organizationsNames.length > 0 && (
            <div
              className="flex-col p-3 flex"
              style={{ minWidth: "175px", maxWidth: "175px" }}
            >
              <span className="text-xs text-uppercase text-gray-700">
                Location/Area
              </span>
              <span>{note?.organizationsNames[0]}</span>
            </div>
          )}

        <div
          className="flex-col p-3 hidden lg:flex"
          style={{ minWidth: "220px", maxWidth: "220px" }}
        >
          <span className="text-xs text-uppercase text-gray-700">Supplier</span>
          <Link
            to={`/dashboard/purchases/suppliers/${note.supplier.id}`}
            className="text-primaryBlue break-words"
          >
            {note.supplier.name}
          </Link>
        </div>
        {rowState(note)}
        <div
          className="flex items-center justify-start lg:justify-end p-3"
          style={{ minWidth: isTabletOrMobile ? "190px" : "150px" }}
        >
          {note.po ? (
            <span className={cx("noteOrderStatus", "found")}>
              Order found: {`#${note.po}`}
            </span>
          ) : (
            <span className={cx("noteOrderStatus", "notfound")}>
              No order found
            </span>
          )}
        </div>

        <div
          className="flex justify-self-end items-center text-gray-700 text-sm font-semibold font-sansSemiBold p-3"
          style={{ minWidth: "55px" }}
        >
          {totalFiles > 0 && (
            <>
              <FontAwesomeIcon icon={faPaperclip} className="mr-1" />{" "}
              {totalFiles}
            </>
          )}
        </div>

        {actions(note)}
      </div>
    </div>
  )
}

DeliveryNoteItem.propTypes = {
  note: PropTypes.object,
  onDeleted: PropTypes.func,
  onUpdated: PropTypes.func,
  onMarked: PropTypes.func,
}

export default DeliveryNoteItem
