/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext, useCallback } from "react"
import PropTypes from "prop-types"
import { useMountedState } from "react-use"
import BaseTable, { AutoResizer } from "react-base-table"
import { useMediaQuery } from "react-responsive"
import { countries } from "services/constants"
import ProductIcon from "components/common/ProductIcon/ProductIcon"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  convertToFormat,
  formatCurrencyValue,
  roundNumber,
} from "services/helpers"
import AddressForm from "components/common/AddressForm/AddressForm"
import { faPaperPlane } from "@fortawesome/pro-duotone-svg-icons"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/GlobalContextProvider"
import ReactTooltip from "react-tooltip"
import {
  getProductsByIdsByPost,
  updateProductPrices,
} from "./../../../services/product"

import * as styles from "./OrderDetails.module.css"
import AsideCalendar from "components/common/AsideCalendar/AsideCalendar"
import { AsideContext } from "context/AsideContext"
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import { ModalContext } from "context/ModalContext"
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons"
import { toast } from "react-toastify"
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz"

const OrderDetails = (props) => {
  const {
    items = [],
    address,
    orderNotes,
    supplier,
    isEditable = true,
    placed = false,
    po = "",
    expectedDeliveryDate = "",
  } = props
  const isMounted = useMountedState()
  const dispatch = useContext(GlobalDispatchContext)
  const aside = useContext(AsideContext)
  const modal = useContext(ModalContext)
  const { user } = useContext(GlobalStateContext)

  const [itemData, setItemData] = useState<any[]>([])
  const [productsFromDB, setProductsFromDB] = useState([])
  const [editingAddress, setEditingAddress] = useState(false)
  const [saveAddressEnabled, setSaveAddressEnabled] = useState(false)
  const [addressData, setAddressData] = useState(null)
  const [notes, setNotes] = useState("")
  const [selectedDate, setSelectedDate] = useState(
    expectedDeliveryDate == null
      ? ""
      : utcToZonedTime(new Date(expectedDeliveryDate), user.zoneId)
  )
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
  const isMobile = useMediaQuery({ maxWidth: 639 })
  const currency = (supplier && supplier.currency) || "" // Might be deprecated

  const PriceCell = React.memo(function PriceCell({ rowData }) {
    const hasDifferentPrices = //Check based on whether order is placed and there is a discrepancy between prices
      rowData.price &&
      (rowData._productDbPrice || rowData.productCase?._productDbPrice) &&
      (rowData.orderInCase
        ? rowData.productCase._productDbPrice !== rowData.productCase.price
        : rowData._productDbPrice !== rowData.price)

    return (
      <div
        className="flex items-center space-x-2 overflow-visible"
        style={{
          borderBottom: hasDifferentPrices ? "1px red solid" : "",
        }}
      >
        <div>
          {rowData.orderInCase
            ? rowData.productCase.price
              ? currency
                ? formatCurrencyValue(rowData.productCase.price)
                : roundNumber(rowData.productCase.price)
              : ""
            : rowData.price
            ? currency
              ? formatCurrencyValue(rowData.price)
              : roundNumber(rowData.price)
            : ""}
          {rowData.price
            ? ` / ${rowData.orderInCase ? "Pack" : rowData.unit}`
            : ""}
        </div>
        {hasDifferentPrices && (
          <button
            onClick={() =>
              modal.showModal(ConfirmModal, {
                title: "Price difference",
                text: "This price does not match your database. You can optionally set it to match it with this order.",
                confirmButtonText: "Update price",
                onConfirm: () => {
                  updateProductPrices(rowData.supplierId, rowData.barcode, {
                    casePrice: Boolean(rowData.orderInCase),
                    price: rowData.orderInCase
                      ? rowData.productCase.price
                      : rowData.price,
                  })
                    .then((response) => {
                      Boolean(response) &&
                        toast.success("Price updated successfully")
                    })
                    .then(() => getDbProducts())
                    .catch(() =>
                      toast.error(
                        "Something went wrong when updating the price"
                      )
                    )
                },
                children: (
                  <div className="flex flex-col items-center">
                    <span className="inline-block mb-2 font-semibold text-primaryBlue">
                      <ProductIcon
                        product={rowData}
                        inCase={rowData.orderInCase}
                      />
                      {rowData.name}
                    </span>
                    <div className="flex justify-center space-x-4 items-center">
                      <div className="flex flex-col">
                        <small className="text-xs">Product DB price</small>
                        <span className="font-semibold">
                          {rowData.orderInCase
                            ? rowData.productCase._productDbPrice
                            : rowData._productDbPrice}
                        </span>
                      </div>
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        className="text-primaryBlue"
                      />
                      <div className="flex flex-col">
                        <small className="text-xs">Order price</small>
                        <span className="font-semibold">
                          {rowData.orderInCase
                            ? rowData.productCase.price
                            : rowData.price}
                        </span>
                      </div>
                    </div>
                  </div>
                ),
              })
            }
            data-tip
            data-for="price-warning"
            className="flex flex-col text-red-700 z-50"
          >
            <FontAwesomeIcon icon={faExclamationCircle} />
            <ReactTooltip
              id="price-warning"
              place="right"
              effect="solid"
              backgroundColor="white"
              textColor="#4E4E4E"
              borderColor="#DCDCDC"
              border={true}
              offset={{ left: 4, top: -3 }}
              className="font-sansBold z-50"
            >
              <div className="flex flex-col items-center text-xs">
                <span className="uppercase text-red-500">Price mismatch</span>
                <span>Click to view details</span>
              </div>
            </ReactTooltip>
          </button>
        )}
      </div>
    )
  })

  const totalPrice = useCallback(() => {
    if (!items.length) {
      return 0
    }

    let price = 0
    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      const itemPrice = item.orderInCase
        ? parseFloat(item.productCase.price)
        : parseFloat(item.price)
      price += item.quantity * itemPrice
    }
    return price
  }, [items])

  const addressValidChange = (isValid) => {
    setSaveAddressEnabled(isValid)
  }

  const handleAddressChange = (data) => {
    setAddressData(data.nextValues)
  }

  const saveAddress = () => {
    dispatch({
      type: "UPDATE_NEW_ORDER",
      payload: { deliveryAddress: addressData },
    })
    setEditingAddress(false)
  }

  const updateNotes = (value) => {
    dispatch({ type: "UPDATE_NEW_ORDER", payload: { notes: value } })
    setNotes(value)
  }

  const updateSelectedDate = (value) => {
    dispatch({
      type: "UPDATE_NEW_ORDER",
      payload: {
        expectedDeliveryDate: zonedTimeToUtc(value, user.zoneId).toISOString(),
      },
    })
    setSelectedDate(value)
  }

  const formatDate = (date) => {
    if (!date) {
      return ""
    }
    return convertToFormat(date)
  }

  const toggleDatePicker = () => {
    // @ts-ignore
    aside.showAside(AsideCalendar, {
      headerText: "Date picker",
      mode: "single",
      disable: { before: new Date() },
      onConfirm: (data) => {
        updateSelectedDate(data.from)
        aside.hideAside()
      },
    })
  }

  const getDbProducts = () =>
    getProductsByIdsByPost({
      productsIds: items.map((a) => a.id),
    }).then((r) => setProductsFromDB(r))

  useEffect(() => {
    getDbProducts()
  }, [])

  useEffect(() => {
    if (items.length && isMounted()) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars

      const itemsWithMergedPrices: any[] = items.map((a: any) => {
        const temp: any = { ...a }

        const found: any = productsFromDB.find((tempProd) => {
          return tempProd.id === (temp.productId || temp.id)
        })

        if (found) {
          //Can directly modify here
          temp._productDbPrice = found?.price
          temp.supplierId = found?.supplierId
          if (temp?.productCase?.price) {
            temp.productCase._productDbPrice = found?.productCase?.price
          }
        }

        return temp
      })

      setItemData(() => {
        return [
          ...itemsWithMergedPrices,
          {
            id: "012090",
            name: "Subtotal",
            quantity: "",
            barcode: "",
            price: "",
            totalPrice: totalPrice(),
          },
        ]
      })
    }
  }, [items, isMounted, totalPrice, productsFromDB])

  useEffect(() => {
    if (isMounted()) {
      setAddressData(address)
    }
  }, [address, isMounted])

  useEffect(() => {
    if (!notes && isMounted()) {
      setNotes(orderNotes)
    }
  }, [orderNotes, isMounted, notes])

  const desktopColumns = [
    {
      key: "name",
      title: "Name",
      dataKey: "name",
      width: 200,
      flexGrow: 1,
      flexShrink: 0,
      className: "font-sansSemiBold font-semibold text-primaryBlue",
      cellRenderer: ({ rowData }) => (
        <div className="flex flex-col my-3">
          <span className="font-sansSemiBold text-sm font-semibold text-primaryBlue">
            {rowData.name}
          </span>
        </div>
      ),
    },
    {
      key: "packaging",
      title: "Packaging",
      width: 120,
      dataKey: "packaging",
      className: "text-sm",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => {
        const hasCase = !!rowData.productCase
        const isOrderedAsCase = rowData.orderInCase

        return rowData.totalPrice ? null : (
          <>
            <ProductIcon
              product={rowData}
              inCase={isOrderedAsCase}
            ></ProductIcon>
            {isOrderedAsCase && hasCase ? (
              <span className="font-sansSemiBold text-sm font-semibold text-primaryBlue px-2">
                {rowData.productCase.size} x {rowData.size} {rowData.measure}
              </span>
            ) : (
              <span className="font-sansSemiBold text-sm font-semibold text-primaryBlue px-2">
                {rowData.size} {rowData.measure}
              </span>
            )}
          </>
        )
      },
    },
    {
      key: "price",
      title: "Price",
      width: 90,
      dataKey: "price",
      className: "text-sm",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => <PriceCell rowData={rowData} />,
    },
    {
      key: "quantity",
      title: "Order Qty",
      flexGrow: 1,
      flexShrink: 0,
      className: "text-sm",
      dataKey: "quantity",
      width: 80,
    },
    {
      key: "total",
      title: "Item total",
      width: 100,
      dataKey: "price",
      flexGrow: 1,
      flexShrink: 0,
      className: "justify-start text-sm",
      cellRenderer: ({ rowData }) => (
        <div
          className={
            rowData.totalPrice ? "font-semibold font-sansSemiBold" : ""
          }
        >
          {rowData.totalPrice
            ? formatCurrencyValue(rowData.totalPrice)
            : formatCurrencyValue(
                rowData.orderInCase
                  ? rowData.quantity * parseFloat(rowData.productCase.price)
                  : rowData.price
                  ? rowData.quantity * parseFloat(rowData.price)
                  : 0
              )}
        </div>
      ),
    },
  ]

  const mobileColumns = [
    {
      key: "name",
      title: "Name",
      dataKey: "name",
      width: 100,
      flexGrow: 1,
      flexShrink: 0,
      className: "font-sansSemiBold font-semibold text-primaryBlue",
      cellRenderer: ({ rowData }) => (
        <div className="flex flex-col my-3">
          <span className="font-sansSemiBold text-sm font-semibold text-primaryBlue">
            {rowData.name}
          </span>
        </div>
      ),
    },
    {
      key: "packaging",
      title: "Packaging",
      width: 90,
      dataKey: "packaging",
      className: "text-sm",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => {
        const hasCase = !!rowData.productCase
        const isOrderedAsCase = rowData.orderInCase

        return rowData.totalPrice ? null : (
          <>
            {isOrderedAsCase && hasCase ? (
              <span className="font-sansSemiBold text-sm font-semibold text-primaryBlue px-2">
                {rowData.productCase.size} x {rowData.size} {rowData.measure}
              </span>
            ) : (
              <span className="font-sansSemiBold text-sm font-semibold text-primaryBlue px-2">
                {rowData.size} {rowData.measure}
              </span>
            )}
          </>
        )
      },
    },
    {
      key: "quantity",
      title: "Qty",
      flexGrow: 1,
      flexShrink: 0,
      className: "text-sm",
      dataKey: "quantity",
      width: 40,
    },
    {
      key: "price",
      title: "Price",
      width: 70,
      dataKey: "price",
      className: "text-sm",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => <PriceCell rowData={rowData} />,
    },
    {
      key: "total",
      title: "Item total",
      width: 100,
      dataKey: "price",
      flexGrow: 1,
      flexShrink: 0,
      className: "justify-start text-sm",
      cellRenderer: ({ rowData }) => (
        <div
          className={
            rowData.totalPrice ? "font-semibold font-sansSemiBold" : ""
          }
        >
          {rowData.totalPrice
            ? formatCurrencyValue(rowData.totalPrice)
            : formatCurrencyValue(
                rowData.orderInCase
                  ? rowData.quantity * parseFloat(rowData.productCase.price)
                  : rowData.price
                  ? rowData.quantity * parseFloat(rowData.price)
                  : 0
              )}
        </div>
      ),
    },
  ]

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          {placed && (
            <div className={styles.successMessage}>
              <h1>
                <FontAwesomeIcon icon={faPaperPlane} className="mr-3" />
                Order placed!
              </h1>
              <p>
                An email is on it’s way to the supplier to request your items.
                View your order details below
              </p>
            </div>
          )}

          <div className={styles.detailsHeader}>
            <h2 className={styles.title}>
              New order for <span>{supplier && supplier.name}</span>
            </h2>
            {po && <span className={styles.po}>#{po}</span>}
          </div>
          <div className="w-full h-auto">
            {isMounted() && (
              <AutoResizer height={Infinity}>
                {({ width }) => (
                  <BaseTable
                    fixed={isTabletOrMobile ? true : false}
                    width={width}
                    maxHeight={items.length === 0 ? undefined : Infinity}
                    height={items.length === 0 ? 150 : undefined}
                    estimatedRowHeight={70}
                    data={itemData}
                    columns={isMobile ? mobileColumns : desktopColumns}
                    emptyRenderer={
                      <div className={styles.noItems}>No items yet</div>
                    }
                  />
                )}
              </AutoResizer>
            )}
          </div>
          <div className={styles.bottom}>
            <div className={styles.note}>
              <div className="form-group">
                <label htmlFor="notes" className={styles.noteLabel}>
                  {isEditable ? "Add a note for this supplier" : "Notes"}
                </label>
                <div className="input-container">
                  {isEditable ? (
                    <textarea
                      style={{ resize: "none" }}
                      className="form-control form-control--first form-control--last"
                      name="notes"
                      id="notes"
                      placeholder="Any notes, e.g. related to deliveries, etc"
                      value={notes}
                      onChange={(e) => updateNotes(e.target.value)}
                    ></textarea>
                  ) : (
                    notes
                  )}
                </div>
              </div>
            </div>
            <div className={styles.address}>
              {addressData && !editingAddress && (
                <>
                  <label className={styles.noteLabel}>Delivery address</label>
                  <span>{addressData.addressLine1}</span>
                  {addressData.addressLine2 && (
                    <span>{addressData.addressLine2}</span>
                  )}
                  <span>
                    {addressData.city} {addressData.postCode}
                  </span>
                  <span>
                    {countries.find((c) => c.value === addressData.country)
                      ? countries.find((c) => c.value === addressData.country)
                          .label
                      : addressData.country}
                  </span>
                  {isEditable ? (
                    <button
                      className="button--autoWidth mt-4 text-primaryPink font-sansSemiBold font-semibold"
                      onClick={() => setEditingAddress(true)}
                    >
                      Change address
                    </button>
                  ) : null}
                </>
              )}
              {addressData && editingAddress && (
                <>
                  <AddressForm
                    initialValues={addressData}
                    onValidChange={(isValid) => addressValidChange(isValid)}
                    onDataChange={handleAddressChange}
                  />
                  <button
                    disabled={!saveAddressEnabled}
                    className="button button--primaryGreen"
                    onClick={saveAddress}
                  >
                    Save
                  </button>
                </>
              )}
            </div>
            <div className={styles.date}>
              <div className="form-group">
                <label htmlFor="notes" className={styles.noteLabel}>
                  Expected delivery date
                </label>
                <div className="input-container">
                  {isEditable ? (
                    <input
                      type="text"
                      value={formatDate(selectedDate) || ""}
                      className="cursor-pointer form-control rounded"
                      placeholder="Expected Delivery Date"
                      readOnly
                      onClick={toggleDatePicker}
                    />
                  ) : (
                    formatDate(selectedDate)
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

OrderDetails.propTypes = {
  items: PropTypes.array,
  address: PropTypes.object,
  orderNotes: PropTypes.string,
  supplier: PropTypes.object,
  isEditable: PropTypes.bool,
  placed: PropTypes.bool,
  po: PropTypes.string,
}

export default OrderDetails
