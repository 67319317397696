import React, { useState } from "react"
import Modal from "react-modal"
import { showError, showSuccess } from "services/toast"
import ConfirmDetails from "components/invoices/ConfirmDetails/ConfirmDetails"
import PropTypes from "prop-types"

//@ts-ignore
import * as styles from "./InvoiceDetails.module.css"

const InvoiceDetails = ({ onRequestClose, onUpdate, ...otherProps }) => {
  const [disableClose, setDisableClose] = useState(false)

  return (
    <Modal
      isOpen
      style={{ content: { bottom: "40px", border: 0 } }}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={!disableClose}
      shouldCloseOnEsc={!disableClose}
      portalClassName="invoiceDetails"
      {...otherProps}
    >
      <div className={styles.container}>
        <div className={styles.confirmContainer}>
          <div className={styles.confirm}>
            <ConfirmDetails
              onCancel={() => {
                onRequestClose()
                onUpdate()
              }}
              onConfirm={() => {
                onRequestClose()
                onUpdate()
                showSuccess("Invoice approved!")
              }}
              onReject={() => {
                onRequestClose()
                onUpdate()
                showError("Invoice rejected!")
              }}
              onQuery={() => {
                onRequestClose()
                onUpdate()
              }}
              onSave={() => {
                onUpdate()
              }}
              onLoadingChange={(loading) => {
                setDisableClose(loading)
              }}
              onFilesChange={() => {
                onUpdate()
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

InvoiceDetails.propTypes = {
  onRequestClose: PropTypes.func,
  onUpdate: PropTypes.func,
}

export default InvoiceDetails
