import React, { useContext, useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { showSuccess, showError } from "services/toast"
import { faArrowLeft, faSpinnerThird } from "@fortawesome/pro-regular-svg-icons"
import {
  Invoice,
  InvoicedProduct,
  publishToAccounting,
  AccountingAccount,
} from "services/invoices"
import AccountingPublishFormHeader from "./AccountingPublishFormHeader"
import AccountingPublishForm from "./AccountingPublishForm"
import AccountingPublishSummary from "./AccountingPublishSummary"
import EditDetails from "components/invoices/EditDetails/EditDetails"

import * as styles from "./AccountingPublishForm.module.css"
import classNames from "classnames/bind"
import { groupBy } from "services/helpers"
import {
  ExtendedAccountingLineItem,
  AccountingEditParams,
  AccountingProvider,
} from "services/accounting"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import { ModalContext } from "context/ModalContext"

const cx = classNames.bind(styles)

interface AccountingPublishContainerProps {
  provider: AccountingProvider
  editEnabled: boolean
  currentStep: number
  invoice: Invoice
  integrationId: string | null
  onPublish(invoice: any, provider: AccountingProvider): void
  onEditInvoice?: Function
  onCancel(): void
  onSaveDetails(params: any): void
  onSaveInvoicedProducts(params: InvoicedProduct[])
}

const AccountingPublishContainer: React.FunctionComponent<
  AccountingPublishContainerProps
> = ({
  provider,
  editEnabled = true,
  currentStep,
  invoice,
  integrationId,
  onPublish,
  onCancel,
  onSaveDetails,
  onSaveInvoicedProducts,
}: AccountingPublishContainerProps) => {
  const [step, setStep] = useState(currentStep !== null ? currentStep : 0)
  const [loading, setLoading] = useState<boolean>(false)
  const providerKey = provider.key
  const modal = useContext(ModalContext)

  const [AccountingParams, setAccountingParams] =
    useState<AccountingEditParams>({
      account: invoice[providerKey + "Invoice"]?.account || null,
      supplier:
        invoice[providerKey + "Invoice"]?.[providerKey + "Supplier"] || null,
      description: invoice[providerKey + "Invoice"]?.description || "",
      lineItems: [],
      netCost: invoice[providerKey + "Invoice"]
        ? invoice[providerKey + "Invoice"].netCost
        : invoice.totalCost,
      totalVat: invoice[providerKey + "Invoice"]
        ? invoice[providerKey + "Invoice"].totalVat
        : invoice.totalVat,
      taxRate: invoice[providerKey + "Invoice"]?.taxRate || null,
    })
  const [editDetails, setEditDetails] = useState<boolean>(false)
  const [invoicedProducts, setInvoicedProducts] = useState<
    InvoicedProduct[] | []
  >(invoice.products)

  const [groupedLineItems, setGroupedLineItems] = useState<
    ExtendedAccountingLineItem[]
  >([])

  /* line items need to be grouped by account & by tax-rate */
  /* otherwise the invoices in the accounting programmet get really long */

  const mapgroupedLineItems = (items: InvoicedProduct[]) => {
    const mappedItems = items.map((item) => {
      // Add Account & TaxRate as groupKey on top level to be able to make a groupBy
      return {
        ...item,
        groupKey:
          item[providerKey]?.account.name +
          "|" +
          (item[providerKey]?.taxRate?.name || ""),
      }
    })
    // Group items by account & tax-rate
    const res = groupBy("groupKey", mappedItems)
    const accountsLength = Object.keys(res).length

    const tmpGroupedLineItems = Object.keys(res).map((groupKey) => {
      const lineItem = res[groupKey]
      const accountLength = lineItem.length
      const itemDescription = `${lineItem[0].description}${
        accountLength > 1
          ? accountLength === 2
            ? " (+ " + (accountLength - 1) + " item)"
            : " (+ " + (accountLength - 1) + " items)"
          : ""
      }`

      return {
        account: lineItem[0][providerKey]?.account,
        description:
          accountsLength <= 1 && AccountingParams.description
            ? AccountingParams.description
            : itemDescription,
        netCost:
          lineItem?.reduce((acc, curr) => {
            acc += curr.invoicedTotalCost
            return acc
          }, 0) || 0,
        taxRate: provider.taxPerLine ? lineItem[0][providerKey]?.taxRate : null,
        items: lineItem,
      }
    })
    setGroupedLineItems(tmpGroupedLineItems)

    setAccountingParams({
      ...AccountingParams,
      lineItems: tmpGroupedLineItems.map((item) => {
        return {
          account: item.account,
          // When there is only 1 account selected and the 'general' description is set, set this as a description for each line item.
          description:
            accountsLength <= 1 && AccountingParams.description
              ? AccountingParams.description
              : item.description,
          netCost: item.netCost,
          taxRate: provider.taxPerLine ? item?.taxRate : null,
        }
      }),
    })
  }

  useEffect(() => {
    // Make sure the local state of the Accounting accounts per line item
    // is not lost when update comes from parent context
    const mappedProducts = invoice.products.map(
      (p: InvoicedProduct, index: number) => {
        return {
          ...p,
          [providerKey]: invoicedProducts[index]?.[providerKey],
        }
      }
    )
    setInvoicedProducts(mappedProducts)
  }, [invoice.products])

  useEffect(() => {
    if (invoice[providerKey + "Invoice"]) {
      setGroupedLineItems(invoice[providerKey + "Invoice"].lineItems)
    } else {
      mapgroupedLineItems(invoicedProducts)
    }
  }, [invoicedProducts])

  const publish = async () => {
    setLoading(true)
    const params = {
      account: AccountingParams.account,
      [providerKey + "Supplier"]: AccountingParams.supplier,
      description: AccountingParams.description || "",
      grossTotalCost: invoice.grossTotalCost,
      invoiceDate: invoice.dateOfIssue,
      invoiceNumber: invoice.invoiceNumber,
      dueDate: invoice.dueDate,
      netCost: AccountingParams.netCost,
      totalVat: AccountingParams.totalVat,
      lineItems: AccountingParams.lineItems,
      taxRate: AccountingParams.taxRate,
    }

    try {
      await onSaveInvoicedProducts(invoicedProducts)
      const published = await publishToAccounting(
        providerKey,
        invoice.id,
        params
      )

      if (
        published === true ||
        (published.status >= 200 && published.status < 300)
      ) {
        showSuccess(`Invoice publised to ${providerKey}!`)
        onPublish(params, provider)
      } else {
        if (published.message) {
          const message = provider.extractErrorMsgFn(published)
          modal.showModal(ConfirmModal, {
            type: "danger",
            title: provider.title + " error:",
            text: message,
            showCancel: false,
            confirmButtonText: "Continue",
            onConfirm: () => void 0,
          })
        } else {
          showError(
            `Could not publish invoice to ${providerKey}, try again later`
          )
        }
      }
    } catch (e) {
      showError(`Could not publish invoice to ${providerKey}, try again later`)
    }
    setLoading(false)
  }

  const handleInvoiceItemChange = (
    val: AccountingAccount,
    field: string,
    itemIndex: number
  ) => {
    const newArray = invoicedProducts
    newArray[itemIndex][providerKey] = {
      ...newArray[itemIndex][providerKey],
      [field]: val,
    }
    setInvoicedProducts([...newArray])
  }

  const setDefaultAccountForLineItems = (
    newAccount: AccountingAccount,
    prevAccount: AccountingAccount
  ) => {
    const newInvoicedProducts = invoicedProducts.map((p) => {
      /**
       * 2 scenarios:
       * 1. line item has no account -> set default new account
       * 2. line item has same account as previous default account -> set default new account
       */
      if (
        !p[providerKey]?.account ||
        (prevAccount && p[providerKey].account.id === prevAccount.id)
      ) {
        const lineData = provider.taxPerLine
          ? { account: newAccount, taxRate: newAccount.taxRate }
          : { account: newAccount }

        return { ...p, [providerKey]: lineData }
      } else {
        return p
      }
    })
    setInvoicedProducts([...newInvoicedProducts])
  }

  return (
    <>
      <div className="flex flex-col items-start overflow-hidden flex-grow h-full relative">
        <form className="flex flex-col flex-grow items-start overflow-hidden w-full">
          {editEnabled && <AccountingPublishFormHeader step={step} />}
          <div className="flex items-start flex-wrap w-full flex-grow overflow-auto border-t">
            {step === 0 ? (
              <AccountingPublishForm
                provider={provider}
                AccountingParams={AccountingParams}
                setAccountingParams={setAccountingParams}
                onEditDetails={() => setEditDetails(true)}
                handleInvoiceItemChange={handleInvoiceItemChange}
                invoicedProducts={invoicedProducts}
                setDefaultAccountForLineItems={setDefaultAccountForLineItems}
                integrationId={integrationId}
                invoice={invoice}
              />
            ) : (
              <AccountingPublishSummary
                provider={provider}
                invoice={invoice}
                AccountingParams={AccountingParams}
                groupedLineItems={groupedLineItems}
              />
            )}
          </div>
          <div className="px-6 md:px-4 py-4 w-full flex items-center flex-shrink-0 border-t">
            <button
              onClick={onCancel}
              className={`${
                !editEnabled && "button button--paleBlue button--autoWidth"
              } text-gray-700 px-2 font-sansSemiBold font-semibold mr-auto`}
              type="button"
            >
              {editEnabled ? "Cancel" : "Close"}
            </button>
            {step === 1 && editEnabled && (
              <button
                onClick={() => setStep(0)}
                className="button button--paleBlue button--autoWidth mr-4"
                type="button"
              >
                <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                Previous
              </button>
            )}
            {editEnabled && (
              <button
                onClick={() => (step === 0 ? setStep(1) : publish())}
                disabled={
                  loading ||
                  !AccountingParams.supplier ||
                  !AccountingParams.account ||
                  !AccountingParams.netCost ||
                  !invoice.invoiceNumber ||
                  !invoice.dueDate
                }
                className="button button--primaryGreen button--autoWidth"
                type="button"
              >
                {loading && (
                  <FontAwesomeIcon
                    icon={faSpinnerThird}
                    spin
                    className="mr-2"
                  />
                )}
                {step === 0 ? "Save & Next" : "Publish"}
              </button>
            )}
          </div>
        </form>
      </div>
      {editDetails && (
        <div className={cx("editDetailsWrapper", { active: editDetails })}>
          <div className="flex items-center justify-center w-full p-2 border-b">
            <h2 className="text-primaryBlue font-semibold font-sansSemiBold text-lg">
              Edit details
            </h2>
          </div>
          <div className="overflow-auto flex-grow">
            <EditDetails
              object={{
                number: invoice.invoiceNumber,
                date: invoice.dateOfIssue,
                dueDate: invoice.dueDate,
              }}
              isSaving={loading}
              onSave={(params) => {
                onSaveDetails(params)
                setEditDetails(false)
              }}
              labels={{
                number: "Invoice number",
                date: "Issue date",
                dueDate: "Due date",
              }}
            />
          </div>
        </div>
      )}
      <div
        className={cx("editDetailsOverlay", { active: editDetails })}
        onClick={() => setEditDetails(false)}
      ></div>
    </>
  )
}

export default AccountingPublishContainer
