import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useContext,
} from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons"
import Modal from "react-modal"
import { getProductByBarcode } from "services/product"
import AddProductForm from "components/stock/AddProductForm/AddProductForm"
import AddRecipeForm from "components/stock/AddRecipeForm/AddRecipeForm"
import BarcodeScanner from "components/scanner/BarcodeScanner/BarcodeScanner"
import RecipeSelect from "components/common/RecipeSelect/RecipeSelect"
import ProductNotFound from "components/stock/ProductNotFound/ProductNotFound"
import classNames from "classnames/bind"
import NewProductSelect from "components/common/NewProductSelect/NewProductSelect"
import { ProductOrder } from "services/types"
import { ModalContext } from "context/ModalContext"
import { AsideContext } from "context/AsideContext"
import EditProduct from "components/suppliers/EditProduct/EditProduct"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import { getRecipeByBarcode } from "services/recipes"

//@ts-ignore
import * as styles from "./StockTakeInput.module.css"
const cx = classNames.bind(styles)

const newProduct = {
  barcode: null,
  category: null,
  description: null,
  measure: null,
  price: null,
  size: null,
  quantity: null,
  subCategory: null,
  id: null,
}

interface StockTakeInputProps {
  onRequestClose: Function
  onSave: Function
  onSaveRecipe?: Function
  type: "items" | "recipes"
  [x: string]: any
  manually?: boolean
}

const StockTakeInput = ({
  onRequestClose,
  onSave,
  onSaveRecipe,
  type = "items",
  manually = false,
  ...otherProps
}: StockTakeInputProps) => {
  const modal = useContext(ModalContext)
  const aside = useContext(AsideContext)
  const isRecipe = type === "recipes"
  const scannerRef = useRef<BarcodeScanner>(null)
  const [loading, setLoading] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [currentStep, setCurrentStep] = useState(manually ? 4 : 1)
  const [currentProduct, setCurrentProduct] = useState<ProductOrder | null>(
    null
  )
  const [currentRecipe, setCurrentRecipe] = useState(null)

  const tab = type

  const setStep2 = useCallback(async (barcodeResult) => {
    // get the barcode
    const { barcodeText } = barcodeResult

    // Pause scanner for performance reasons
    pauseScanning()

    if (barcodeText) {
      setCurrentStep(2)
      setLoading(true)

      // Get product by barcode here
      const productResult = await getProductByBarcode(barcodeText)

      // if product not found, try to find recipe
      if (
        productResult.status &&
        productResult.status.toString().startsWith("4")
      ) {
        const recipeResult = await getRecipeByBarcode(barcodeText)

        // If recipe not found set status not found
        if (
          recipeResult.status &&
          recipeResult.status.toString().startsWith("4")
        ) {
          setNotFound(true)
          setLoading(false)
          return
        } else {
          // recipe is found
          setCurrentRecipe(recipeResult)
        }
      } else {
        // product is found
        setCurrentProduct({ ...newProduct, ...productResult })
      }

      setLoading(false)
      setCurrentStep(3)
    } else {
      setNotFound(true)
      return
    }
  }, [])

  useEffect(() => {
    if (currentProduct) {
      setCurrentStep(3)
    }
  }, [currentProduct, setCurrentStep])

  const resumeScanning = () => {
    setNotFound(false)
    setCurrentStep(manually ? 4 : 1)
    setCurrentProduct(null)
    setCurrentRecipe(null)

    if (!manually && scannerRef.current) {
      scannerRef.current.resume()
    }
  }

  const resumeSelecting = () => {
    setCurrentStep(4)
    setCurrentProduct(null)
    setCurrentRecipe(null)
  }

  const pauseScanning = () => {
    if (scannerRef.current) {
      scannerRef.current.pause()
    }
  }

  const onProductSave = (next, product: ProductOrder, totalValue) => {
    if (product.packaging === "single" || product.packaging == null) {
      onSave(product, totalValue)
      // If the user selected multiple packaging, just multiply the
      // amount of items with the case size in order to get the quantity
      // in single items
    } else if (product.packaging === "multiple") {
      onSave(product, totalValue * product.productCase.size)
    } else {
      throw new TypeError(`Invalid packaging - ${product.packaging}`)
    }
    if (next) {
      resumeScanning()
    } else {
      onRequestClose()
    }
  }

  const onRecipeSave = (next, recipe, totalValue) => {
    if (onSaveRecipe) {
      onSaveRecipe(recipe, totalValue)
    }
    if (next) {
      resumeScanning()
    } else {
      onRequestClose()
    }
  }

  const handleProductSelect = async (product) => {
    setCurrentProduct({
      ...newProduct,
      barcode: product.barcode,
      barcodes: product.barcodes,
      description: product.name,
      category: product.category,
      measure: product.measure,
      price: product.price,
      size: product.size,
      subCategory: product.subCategory,
      unit: product.unit,
      productCase: product.productCase,
      packaging: product.packaging,
      id: product.id,
      quantity: 0,
    })
    setLoading(false)
    setCurrentStep(3)
  }

  const handleRecipeSelect = async (recipe) => {
    setCurrentRecipe(recipe)
    setLoading(false)
    setCurrentStep(3)
  }

  const onAddNewProductToStockTake = (data) => {
    modal.showModal(ConfirmModal, {
      title: "Add item",
      text: "Do you want to add this item to the current stocktake?",
      confirmButtonText: "Add",
      onConfirm: () => {
        handleProductSelect(data)
      },
    })
  }

  const onAddNewProduct = () => {
    aside.showAside(EditProduct, {
      createProduct: true,
      headerText: "Create new product",
      onSubmitCallback: (data) => onAddNewProductToStockTake(data),
    })
  }

  const content = manually ? null : (
    <BarcodeScanner
      ref={scannerRef}
      onInit={() => setLoading(false)}
      onReadSuccess={setStep2}
      blur={currentStep === 3 || currentStep === 4}
      onAddManually={() => setCurrentStep(4)}
    />
  )

  return (
    <Modal
      isOpen
      style={{ content: { bottom: "40px", border: 0, overflow: "hidden" } }}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={!loading}
      shouldCloseOnEsc={!loading && currentStep !== 3 && currentStep !== 4}
      portalClassName="stocktake"
      {...otherProps}
    >
      <div className={styles.container}>
        {
          <div className={styles.navBar}>
            {!manually && (
              <nav className={cx("header", "transparent")}>
                <button onClick={onRequestClose}>
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    className={styles.backIcon}
                  />
                </button>

                <div className={styles.headerMain}>
                  <h2 className={styles.title}>Scan items</h2>
                </div>

                <div className={styles.placeHolder}></div>
              </nav>
            )}
          </div>
        }

        <div className={cx("content", "step" + currentStep)}>
          {content}

          {(currentStep === 3 || currentStep === 4 || notFound) && (
            <div
              className={styles.clickArea}
              onClick={resumeScanning}
              aria-hidden="true"
              role="button"
            ></div>
          )}

          {
            <div className={cx("notFound", { show: notFound })}>
              <ProductNotFound
                onResume={resumeScanning}
                onAddManually={() => {
                  setNotFound(false)
                  setCurrentStep(4)
                }}
              />
            </div>
          }

          <div className={cx(styles.confirmContainer)}>
            <div className={styles.confirm}>
              {currentProduct && (
                <AddProductForm
                  product={currentProduct}
                  onCancel={resumeScanning}
                  onSave={onProductSave}
                />
              )}

              {currentRecipe && (
                <AddRecipeForm
                  newRecipe={currentRecipe}
                  onCancel={resumeSelecting}
                  onSave={onRecipeSave}
                />
              )}
            </div>
          </div>

          <div className={cx(styles.productSelectContainer)}>
            <div className={styles.productSelect}>
              <div className="h-full overflow-hidden flex flex-col bg-white">
                <div className="px-6 pt-4 pb-0 flex items-center">
                  <button
                    className="text-primaryBlue mr-4"
                    onClick={
                      !isRecipe && !manually ? resumeScanning : onRequestClose
                    }
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </button>
                  <h2 className="text-xl text-primaryBlue">
                    Search a {tab === "items" ? "product" : "recipe"}
                  </h2>
                </div>
                <div className="flex-grow flex flex-col overflow-auto relative">
                  {tab === "items" && (
                    <NewProductSelect
                      searchBySupplier={true}
                      searchByPackaging={true}
                      searchByCategories={true}
                      onSelect={handleProductSelect}
                      createNewProduct={onAddNewProduct}
                      multiSelect={false}
                    />
                  )}

                  {tab === "recipes" && (
                    <RecipeSelect
                      onSelect={handleRecipeSelect}
                      onClose={() => {}}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default StockTakeInput
