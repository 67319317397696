import React, { useState, useContext, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTrash,
  faCheck,
  faSpinnerThird,
} from "@fortawesome/pro-regular-svg-icons"

import usePermissions from "hooks/usePermissions"
import { getCategories } from "services/categories"
import { Category, GrowyzeBackendGETResponse } from "../../services/types"

import CategorySelect from "components/common/CategorySelect/CategorySelect"
import { updateCategory, deleteCategory } from "../../services/categories"
import { AsideContext } from "context/AsideContext"
import CategoriesSettingsSidebar from "./CategoriesSettingsSidebar"
import { ModalContext } from "context/ModalContext"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import { useBeforeUnload } from "react-use"

const CategoriesSettings = () => {
  const aside = useContext(AsideContext)
  const modal = useContext(ModalContext)

  const [categories, setCategories] = useState<Category[]>([])
  const [loading, setLoading] = useState(false)

  const permissionObj = usePermissions("Company settings")

  const getLatestCategories = async () => {
    try {
      const res: GrowyzeBackendGETResponse<Category> = await getCategories()

      return setCategories(res.content)
    } catch (e) {
      console.log(e)
    }
  }

  const handleUpdate = async (category: Category) => {
    setLoading(true)
    if (category.id) {
      await updateCategory(category.id, category)
    }
    await getLatestCategories()
    setLoading(false)
  }

  const handleDelete = async (category: Category) => {
    if (category.id) {
      await deleteCategory(category.id)
    }
    getLatestCategories()
  }

  const showDeleteCategoryConfirmationModal = (category: Category) => {
    modal.showModal(ConfirmModal, {
      onRequestClose: modal.hideModal(),
      text: "After deleting this category list, all linked items will be set to uncategorised.",
      confirmButtonText: "Delete category list",
      type: "danger",
      onConfirm: async () => {
        await handleDelete(category)
        aside.hideAside()
      },
    })
  }

  const showCreateCategorySidebar = () => {
    // @ts-ignore
    aside.showAside(CategoriesSettingsSidebar, {
      headerText: "Create new category list",
      onClose: async () => {
        await getLatestCategories()
        aside.hideAside()
      },
    })
  }

  const showEditCategorySidebar = (category: Category) => {
    // @ts-ignore
    aside.showAside(CategoriesSettingsSidebar, {
      headerText: "Edit category list",
      category,
      headerButton: (
        <button
          onClick={() => showDeleteCategoryConfirmationModal(category)}
          className="text-primaryPink font-bold"
        >
          <FontAwesomeIcon icon={faTrash} className="text-sm mr-1" /> Delete
          category list
        </button>
      ),
      onClose: async () => {
        await getLatestCategories()
        aside.hideAside()
      },
    })
  }

  useBeforeUnload(aside.isOpen, "Are you sure?")

  useEffect(() => {
    let isMounted = true

    if (isMounted) {
      getLatestCategories()
    }
    return () => {
      isMounted = false
    }
  }, [])

  return (
    <>
      <CategorySelect
        categories={categories}
        onSelectCategory={handleUpdate}
        onEditCategory={showEditCategorySidebar}
        onCreateCategory={showCreateCategorySidebar}
        groupCategoriesInDropdowns={true}
        disabled={!permissionObj?.permissions.modify}
      />
      {!!categories.length && (
        <span
          className={`block w-full pt-2 text-sm font-semibold font-sansSemiBold ${
            !loading ? "text-primaryGreenDarker" : "text-primaryPink"
          }`}
        >
          <FontAwesomeIcon
            icon={loading ? faSpinnerThird : faCheck}
            spin={loading}
            className="mr-1"
          />{" "}
          {loading ? "saving.." : "all changes saved"}
        </span>
      )}
    </>
  )
}

export default CategoriesSettings
