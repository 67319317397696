import {
  faChevronDown,
  faChevronUp,
  faQuestionCircle,
} from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import InvoicedProductItem from "components/invoices/InvoicedProductItem/InvoicedProductItem"
import { format } from "date-fns"
import React, { useState } from "react"
import { roundNumber } from "services/helpers"
import { Invoice } from "services/invoices"
import {
  ExtendedAccountingLineItem,
  AccountingEditParams,
  AccountingProvider,
} from "services/accounting"
import ReactTooltip from "react-tooltip"

interface AccountingPublishFormProps {
  provider: AccountingProvider
  invoice: Invoice
  AccountingParams: AccountingEditParams
  groupedLineItems: ExtendedAccountingLineItem[] | undefined
}

interface AccountingSummaryItemProps {
  provider: AccountingProvider
  item: ExtendedAccountingLineItem
}

const AccountingSummaryItem: React.FunctionComponent<
  AccountingSummaryItemProps
> = ({ item, provider }: AccountingSummaryItemProps) => {
  const [open, setOpen] = useState(false)
  return (
    <div
      onClick={() => setOpen(!open)}
      key={`index-${item.account?.name}`}
      className="cursor-pointer "
    >
      <ReactTooltip
        id="groupedItems"
        type="light"
        place={"right"}
        effect="solid"
        border={true}
        borderColor="#e2e8f0"
        multiline={true}
      />
      <div className="flex relative z-10 flex-wrap flex-grow items-center border text-sm bg-white hover:bg-paleBlue transition-colors duration-200 shadow-sm rounded">
        <div className="flex flex-wrap sm:flex-nowrap relative items-center flex-grow pl-8 py-2 sm:py-0">
          <span className="w-10 h-full absolute left-0 top-0 flex items-center flex-shrink-0 justify-center">
            {item.items?.length > 0 && (
              <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} />
            )}
            {!item.items?.length && (
              <span
                data-tip={
                  "Published invoice lines don't have information about the related grouped products anymore"
                }
                data-for={"groupedItems"}
              >
                <FontAwesomeIcon icon={faQuestionCircle} />
              </span>
            )}
          </span>
          <span className="truncate sm:overflow-auto sm:whitespace-normal font-bold sm:font-normal w-full mr-auto sm:w-auto p-1 text-gray-800">
            {item.description}
          </span>
          <span className="w-1/3 sm:w-1/12 p-1">
            <span className="inline-block sm:hidden pr-2 uppercase text-gray-500">
              Qty:
            </span>
            {roundNumber(1, 2)}
          </span>
          <span className="w-2/3 sm:w-2/12 p-1">
            <span className="inline-block sm:hidden pr-2 uppercase text-gray-500">
              Unit&nbsp;price:
            </span>
            {roundNumber(item.netCost, 2)}
          </span>
          <span className="w-full sm:w-4/12 p-1">
            <span className="inline-block sm:hidden pr-2 uppercase text-gray-500">
              Account:
            </span>
            {item.account?.code ? `${item.account?.code} -` : ""}{" "}
            {item.account?.name || "Unknnown"}
          </span>
          {provider.taxPerLine && (
            <span className="w-full sm:w-2/12 p-1">
              <span className="inline-block sm:hidden pr-2 uppercase text-gray-500">
                TaxRate:
              </span>
              {item.taxRate?.name || "Unknnown"}
            </span>
          )}
        </div>
      </div>
      {item.items?.length > 0 && open && (
        <ul className="bg-paleBlue">
          {item.items.map((item, index) => (
            <li
              key={`${index} -${item.barcode}`}
              className="border-l border-r border-b border-paleBlueDarker"
            >
              <InvoicedProductItem item={item} layout="horizontal" />
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

const AccountingPublishSummary: React.FunctionComponent<
  AccountingPublishFormProps
> = ({
  provider,
  invoice,
  AccountingParams,
  groupedLineItems,
}: AccountingPublishFormProps) => {
  return (
    <div className="mt-4 w-full px-4">
      <label className="font-sansSemiBold font-semibold mb-2 block">
        Summary
      </label>
      <div className="flex flex-col text-gray-700">
        <span className="text-sm flex items-center border-b py-1">
          <span className="w-1/3 sm:1/4 mr-2 text-xs uppercase">Supplier:</span>
          <span className="font-semibold">
            {AccountingParams.supplier ? AccountingParams.supplier.name : "-"}
          </span>
        </span>
        <span className="text-sm flex items-center border-b py-1">
          <span className="w-1/3 sm:1/4  mr-2 text-xs uppercase">
            Invoice nr:
          </span>
          <span
            className={`font-semibold ${
              !invoice.invoiceNumber ? "text-red-600" : ""
            }`}
          >
            {invoice.invoiceNumber
              ? invoice.invoiceNumber
              : "Invoice number is required"}
          </span>
        </span>
        <span className="text-sm flex items-center border-b py-1">
          <span className="w-1/3 sm:1/4 mr-2 text-xs uppercase">
            Total cost:
          </span>
          <span className="font-semibold">
            {AccountingParams.netCost ? AccountingParams.netCost : "-"}
          </span>
        </span>
        <span className="text-sm flex items-center border-b py-1">
          <span className="w-1/3 sm:1/4  mr-2 text-xs uppercase">
            Total VAT:
          </span>
          <span className="font-semibold">
            {AccountingParams.totalVat ? AccountingParams.totalVat : "-"}
          </span>
        </span>
        <span className="text-sm flex items-center border-b py-1">
          <span className="w-1/3 sm:1/4 mr-2 text-xs uppercase">Tax type:</span>
          <span className="font-semibold">
            {AccountingParams.taxRate
              ? provider.taxOptionLabelRenderFn(AccountingParams.taxRate)
              : "Extracted from invoice"}
          </span>
        </span>
        <span className="text-sm flex items-center border-b py-1">
          <span className="w-1/3 sm:1/4  mr-2 text-xs uppercase">
            Total cost (including VAT):
          </span>
          <span className="font-semibold">
            {invoice.totalCost
              ? roundNumber(
                  (AccountingParams.netCost || 0) +
                    (AccountingParams.totalVat || 0),
                  3
                )
              : "-"}
          </span>
        </span>
        <span className="text-sm flex items-center border-b py-1">
          <span className="w-1/3 sm:1/4 mr-2 text-xs uppercase">Due date:</span>
          <span
            className={`font-semibold ${
              !invoice.dueDate ? "text-red-600" : ""
            }`}
          >
            {invoice.dueDate
              ? format(new Date(invoice.dueDate), "dd/MM/yyyy")
              : "Due date is required"}
          </span>
        </span>
        <span className="text-sm flex items-center border-b py-1">
          <span className="w-1/3 sm:1/4 mr-2 text-xs uppercase">
            Description:
          </span>
          <span className={`font-semibold`}>
            {AccountingParams.description || "-"}
          </span>
        </span>
      </div>
      <label className="font-sansSemiBold font-semibold my-2 block">
        Grouped items on invoice
      </label>
      <div className="flex flex-col text-gray-700 mb-6">
        {groupedLineItems && groupedLineItems.length > 0 && (
          <>
            <div className="flex flex-wrap w-full relative items-center pl-8">
              <span className="hidden sm:block w-10 absolute left-0 top-0 flex-shrink-0"></span>
              <div className="flex flex-wrap items-center flex-grow">
                <span className="w-full sm:w-auto mr-auto p-1">
                  Description
                </span>
                <span className="w-1/2 sm:w-1/12 p-1 text-xs text-gray-700 hidden sm:block font-sansSemiBold font-semibold uppercase">
                  Qty
                </span>
                <span className="w-1/2 sm:w-2/12 p-1 text-xs text-gray-700 hidden sm:block font-sansSemiBold font-semibold uppercase">
                  Unit price
                </span>
                <span className="w-1/2 sm:w-4/12 p-1 text-xs text-gray-700 hidden sm:block font-sansSemiBold font-semibold uppercase">
                  Account
                </span>
                {provider.taxPerLine && (
                  <span className="w-1/2 sm:w-2/12 p-1 text-xs text-gray-700 hidden sm:block font-sansSemiBold font-semibold uppercase">
                    TaxRate
                  </span>
                )}
              </div>
            </div>
            {groupedLineItems.map((item, index) => {
              return (
                <AccountingSummaryItem
                  provider={provider}
                  item={item}
                  key={index}
                />
              )
            })}
          </>
        )}
      </div>
    </div>
  )
}

export default AccountingPublishSummary
