import React from "react"

type Props = {
  title: string
  tabKey: string
  children: any
  [key: string]: any
}

const Tab: React.FC<Props> = ({ children }) => {
  return <>{children}</>
}

export default Tab
