import React, { useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as styles from "../../ExportForm/ExportForm.module.css"
import ProductSelection from "../../../products/ProductSelection/ProductSelection"
import { faBan, faPlus } from "@fortawesome/pro-light-svg-icons"
import ConfirmModal from "../../ConfirmModal/ConfirmModal"
import ModalProductSelector from "../ModalExportProductSelector/ModalExportProductSelector"
import { getProducts } from "services/product"

const ExportProductSelector = ({
  products,
  setProducts,
  downloadFormat = "",
  modal,
  supplier,
  selectAllOption = false,
}) => {
  const [selectAll, setSelectAll] = React.useState(false)
  const [totalProducts, setTotalProducts] = React.useState<number | null>(null)

  const noProducts = products.length === 0

  // Get number of products on first render
  useEffect(() => {
    getTotalProducts()
  }, [])

  useEffect(() => {
    setProducts([])
  }, [selectAll])

  const getTotalProducts = async () => {
    const params = {
      size: 9999,
    }
    const result = await getProducts(params)

    if (result) {
      setTotalProducts(result.totalElements)
    }
  }

  const clearAllProducts = () => {
    setProducts([])
    setSelectAll(false)
  }

  return (
    <div className="mt-4 w-full flex flex-col" style={{ maxWidth: 700 }}>
      <label className={styles.label}>Select product(s)</label>
      <div className="rounded-md">
        <div className="mb-1">
          {!selectAll && (
            <ProductSelection
              products={products}
              setProducts={setProducts}
              cutIndex={-1}
            />
          )}
        </div>
        <div className="flex flex-col w-full bg-gray-200 p-4 rounded-md">
          <div className="flex flex-wrap w-full items-center gap-y-4">
            <div className="flex items-center w-full gap-x-6">
              <button
                className="bg-primaryBlue hover:bg-opacity-80 text-white rounded-md px-4 py-2"
                disabled={downloadFormat.includes("csv") || selectAll}
                onClick={() => {
                  modal.showModal(ModalProductSelector, {
                    currentProducts: products,
                    setProducts: setProducts,
                    onRequestClose: modal.hideModal,
                    selectedSupplier: supplier?.length ? supplier[0] : null,
                    allowedSuppliers: supplier,
                  })
                }}
              >
                <FontAwesomeIcon icon={faPlus} /> Select products
              </button>

              {selectAllOption && (
                <div className="flex items-center gap-x-2">
                  <input
                    type="checkbox"
                    className="text-primaryBlue cursor-pointer"
                    checked={selectAll}
                    onChange={() => setSelectAll(!selectAll)}
                  />
                  <span>Select all products</span>
                </div>
              )}
            </div>

            <div className="flex items-center w-full gap-x-6">
              <div className="font-semibold text-primaryBlue ml-3 text-sm">
                {selectAll ? totalProducts : products?.length ?? 0} products
                selected
              </div>

              <button
                className={
                  "text-sm text-red-600 " + (noProducts && "text-opacity-50")
                }
                disabled={noProducts}
                onClick={() =>
                  modal.showModal(ConfirmModal, {
                    onRequestClose: modal.hideModal(),
                    title: "Are you sure you want to clear your selection?",
                    text: "This will reset your selection",
                    onConfirm: clearAllProducts,
                  })
                }
              >
                <FontAwesomeIcon icon={faBan} /> Clear products
              </button>
            </div>
          </div>
          {downloadFormat === "csv" && (
            <div className="mt-4 text-sm">
              Product selection is only available in <code>.xlsx</code> format.
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ExportProductSelector
