import React, { useState } from "react"
import { showError, showSuccess } from "services/toast"
import { createSupplier, updateSupplier } from "services/supplier"
import Loader from "components/common/Loader/Loader"
import PropTypes from "prop-types"
import SupplierForm from "components/suppliers/SupplierForm/SupplierForm"
import * as styles from "./EditSupplier.module.css"

const initialData = {
  id: "",
  accountNumber: "",
  name: "",
  contactName: "",
  email: "",
  website: "",
  telephone: "",
  address: {
    addressLine1: "",
    addressLine2: "",
    city: "",
    country: "",
    postCode: "",
  },
  currency: "GBP",
  notes: "",
  orderReminders: [],
  ordersContact: "",
  deliveriesContact: "",
  invoicesContact: "",
  orderApprovals: {
    enabled: false,
    shouldApproveAllOrders: true,
    aboveTotalValue: 0,
    approvers: [],
  },
  minOrderConfig: {
    enabled: false,
    minOrderValue: 0,
  },
}

const EditSupplier = ({ initialValues, onClose }) => {
  const [submitEnabled, setSubmitEnabled] = useState(false)
  const [supplierData, setSupplierData] = useState({ website: null })
  const [loading, setLoading] = useState(false)

  const formattedValues = {
    ...initialData,
    ...initialValues,
    minOrderConfig: {
      enabled: !!initialValues?.minOrderConfig?.minOrderValue,
      minOrderValue: initialValues?.minOrderConfig?.minOrderValue,
    },
  }

  if (!formattedValues.orderApprovals) {
    // Sets orderApprovals object to default values to prevent controlled input error
    formattedValues.orderApprovals = {
      enabled: false,
      shouldApproveAllOrders: true,
      aboveTotalValue: 0,
      approvers: [],
    }
  }

  const prependHttpIfNeeded = (str) => {
    if (str.match("://") === null) {
      return "https://" + str
    } else {
      return str
    }
  }

  const handleSubmit = async () => {
    setLoading(true)
    const { id } = formattedValues
    if (supplierData.website) {
      supplierData.website = prependHttpIfNeeded(supplierData.website)
    }

    if (id) {
      // @todo update supplier here
      const result = await updateSupplier(id, supplierData)
      if (
        result &&
        !result.error &&
        !result.errors &&
        result.status !== 400 &&
        result.status !== 409
      ) {
        setLoading(false)
        onClose("supplier-updated")
        showSuccess("Supplier updated!")
      } else if (result.status === 400 || result.status === 409) {
        setLoading(false)
        showError(result.message)
      } else {
        setLoading(false)
        showError("Saving failed. Did you enter all required fields?")
      }
    } else {
      const result = await createSupplier(supplierData)

      if (
        result &&
        !result.error &&
        !result.errors &&
        result.status !== 400 &&
        result.status !== 403 &&
        result.status !== 409
      ) {
        onClose("supplier-created")
        showSuccess("Supplier created!")
      } else if (result.status === 409) {
        showError("This email address is already assigned to another supplier!")
      } else if (result.status === 400 || result.status === 403) {
        showError(result.message)
      } else {
        showError("Saving failed. Did you enter all required fields?")
      }
      setLoading(false)
    }
  }

  const handleDataChange = (data) => {
    setSupplierData(data.nextValues)
  }

  return (
    <div className={styles.container}>
      {/* <header className={styles.header}>
        <button
          type="button"
          className="mr-4"
          onClick={(e) => {
            e.preventDefault()
            onClose()
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} size="lg" />
        </button>
        <h4>
          {Object.keys(formattedValues).length && formattedValues.id
            ? "Edit supplier"
            : "Create new supplier"}
        </h4>
      </header> */}
      <div className={styles.body}>
        <Loader isLoading={loading}>Saving..</Loader>
        <SupplierForm
          initialValues={formattedValues}
          onDataChange={handleDataChange}
          onValidChange={(isValid) => setSubmitEnabled(isValid)}
        />
      </div>

      <footer className={styles.footer}>
        <button
          type="button"
          className="mr-6 font-semibold font-sansSemiBold"
          onClick={(e) => {
            e.preventDefault()
            onClose()
          }}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={!submitEnabled || loading}
          onClick={(e) => {
            e.preventDefault()
            handleSubmit()
          }}
          className="button button--autoWidth button--primaryGreen"
        >
          Save
        </button>
      </footer>
    </div>
  )
}

EditSupplier.propTypes = {
  initialValues: PropTypes.object,
  onClose: PropTypes.func,
}

export default EditSupplier
