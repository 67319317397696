import React, { useState, useEffect, useContext } from "react"
import { usePromise } from "react-use"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalculator, faSyncAlt } from "@fortawesome/pro-duotone-svg-icons"
import {
  faFileDownload,
  faSync,
  faEllipsisH,
  faPencil,
} from "@fortawesome/pro-regular-svg-icons"
import { useMediaQuery } from "react-responsive"
import Helmet from "react-helmet"
import { format } from "date-fns"
import PropTypes from "prop-types"

import DropdownButton from "components/common/DropdownButton/DropdownButton"
import SearchInput from "components/forms/SearchInput"
import Header from "components/dashboard/Header/Header"
import Loader from "components/common/Loader/Loader"
import ExpandableCategorisedProductTable from "components/common/ExpandableCategorisedProductTable/ExpandableCategorisedProductTable"
import COGSReportModal from "../COGSReportModal/COGSReportModal"

import {
  roundNumber,
  convertTimeStampFormat,
  getFileNameDateTime,
} from "services/helpers"
import {
  getCOGSReportById,
  updateCOGSReport,
  exportCOGSReport,
} from "services/stock-take"
import { GlobalStateContext } from "context/GlobalContextProvider"
import { ModalContext } from "context/ModalContext"

//@ts-ignore
import * as styles from "./COGSReport.module.css"
import usePermissions from "hooks/usePermissions"
import { Permission } from "services/types"
import ReactTooltip from "react-tooltip"
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons"

const COGSReport = ({ cogsReportId }) => {
  const [cogsData, setCOGSData] = useState(null)
  const fromPromise = usePromise()
  const [loading, setLoading] = useState(true)
  const [generating, setGenerating] = useState(false)

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
  const [query, setQuery] = useState("")

  const { organization, organizationGroup } = useContext(GlobalStateContext)
  const permissionObj = usePermissions("COGS") as Permission

  const modal = useContext(ModalContext)
  // Report cell components

  const exportOptions = [
    ...(isTabletOrMobile
      ? [
          {
            title: "Refresh",
            key: "refresh",
            icon: faSync,
          },
        ]
      : []),
    { title: "Export full report (csv)", key: "export.csv" },
    { title: "Export full report (xlsx)", key: "export.xlsx" },
    { title: "Export summary (xlsx)", key: "exportSummary.xlsx" },
    { title: "Export summary (pdf)", key: "exportSummary.pdf" },
  ]

  const Title = ({ rowData }) => {
    return (
      <div className="flex flex-col my-3 text-sm">
        <span className="font-sansSemiBold text-sm font-semibold text-primaryBlue">
          {rowData.name}
        </span>
        <span className="text-gray-700">
          {rowData.size} {rowData.measure}
        </span>
      </div>
    )
  }
  Title.propTypes = {
    rowData: PropTypes.shape({
      name: PropTypes.string,
      size: PropTypes.number,
      measure: PropTypes.string,
    }),
  }

  const Category = ({ rowData }) => {
    return (
      <div className="flex flex-col my-3 text-sm">
        <span className="text-gray-700">
          {rowData.category}{" "}
          {rowData.subCategory ? `- ${rowData.subCategory}` : ""}
        </span>
      </div>
    )
  }
  Category.propTypes = {
    rowData: PropTypes.shape({
      category: PropTypes.string,
      subCategory: PropTypes.string,
    }),
  }

  const Number = ({ rowData, dataKey }) => {
    return (
      <div className="flex flex-col my-3 text-sm">
        {rowData[dataKey] ? roundNumber(rowData[dataKey]) : ""}
      </div>
    )
  }
  Number.propTypes = {
    rowData: PropTypes.object,
    dataKey: PropTypes.string,
  }

  const COGS = ({ rowData }) => {
    return (
      <span
        className={
          rowData.totalCOGS < 0
            ? "text-primaryPink font-sansSemiBold font-semibold"
            : ""
        }
      >
        {roundNumber(rowData.totalCOGS)}
      </span>
    )
  }
  COGS.propTypes = {
    rowData: PropTypes.shape({
      discrepancyQty: PropTypes.number,
      cogs: PropTypes.number,
    }),
  }

  const columns = [
    {
      key: "name",
      title: "Item name",
      dataKey: "name",
      width: 200,
      flexGrow: 1,
      flexShrink: 0,
      headerClassName: "pl-6",
      cellRenderer: Title,
    },
    // {
    //   key: "category",
    //   title: "Category",
    //   dataKey: "category",
    //   width: 200,
    //   flexGrow: 1,
    //   flexShrink: 0,
    //   headerClassName: "pl-6",
    //   cellRenderer: Category,
    // },
    {
      key: "openingQty",
      title: "Opening Qty",
      width: 100,
      dataKey: "openingQty",
      flexGrow: 1,
      flexShrink: 0,
      className: "text-sm",
      // cellRenderer: ({ rowData }) => <>{roundNumber(rowData.openingQty)}</>,
      cellRenderer: <Number dataKey="openingQty" />,
    },
    {
      key: "deliveryQty",
      title: "Delivery (+)",
      width: 100,
      dataKey: "deliveryQty",
      className: "text-sm",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: <Number dataKey="deliveryQty" />,
    },
    {
      key: "transferredQty",
      title: "Transfers (+/-)",
      width: 100,
      dataKey: "transferredQty",
      flexGrow: 1,
      flexShrink: 0,
      className: "text-sm",
      cellRenderer: ({ rowData }) => (
        <>{rowData.transferredQty ? roundNumber(rowData.transferredQty) : ""}</>
      ),
    },
    {
      key: "closingQty",
      title: "Closing Qty",
      width: 100,
      dataKey: "closingQty",
      flexGrow: 1,
      flexShrink: 0,
      className: "text-sm",
      cellRenderer: <Number dataKey="closingQty" />,
    },
    {
      key: "consumptionQty",
      title: "Consumption",
      width: 100,
      dataKey: "consumptionQty",
      className: "text-sm",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: <Number dataKey="consumptionQty" />,
    },
    {
      key: "price",
      title: "Cost Price",
      width: 100,
      dataKey: "price",
      className: "text-sm",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: <Number dataKey="price" />,
    },
    {
      key: "totalCOGS",
      title: "COGS",
      width: 100,
      dataKey: "totalCOGS",
      className: "text-sm",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: COGS,
    },
    {
      key: "totalCostGoodsSpend",
      title: "COG Spent",
      width: 100,
      dataKey: "totalCostGoodsSpend",
      className: "text-sm",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: <Number dataKey="totalCostGoodsSpend" />,
    },
    {
      key: "totalSoldValue",
      title: "Sales",
      width: 100,
      dataKey: "totalSoldValue",
      className: "text-sm",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: <Number dataKey="totalSoldValue" />,
    },
    {
      key: "totalCostGoodsSpendPercentage",
      title: (
        <div>
          <span>COG Spent %</span>

          <span
            data-for="spent_percentage_tooltip"
            data-tip="COG Spent / Sales"
          >
            <FontAwesomeIcon
              icon={faExclamationCircle}
              size="lg"
              className="ml-2 opacity-75"
            />
          </span>

          <ReactTooltip
            id={"spent_percentage_tooltip"}
            type="light"
            place="top"
            effect="float"
            border={true}
            borderColor="#e2e8f0"
          />
        </div>
      ),
      width: 160,
      dataKey: "totalCostGoodsSpendPercentage",
      className: "text-sm",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: <Number dataKey="totalCostGoodsSpendPercentage" />,
    },
  ]

  const onEditCOGSReport = () => {
    modal.showModal(COGSReportModal, {
      cogsReportId,
      cogsData,
      title: "Edit COGS report",
      loadingText: "Updating the COGS Report",
      refreshData: getData,
    })
  }

  const getData = async (regenerate = false) => {
    if (!regenerate) {
      setLoading(true)
    }

    if (regenerate && cogsData) {
      const updateParams = {
        cogsReportId,
        closingStockTakeReportId: cogsData.closingStockTakeReportId,
        openingStockTakeReportId: cogsData.openingStockTakeReportId,
        filterCategories: cogsData.filterCategories,
      }
      await fromPromise(updateCOGSReport(updateParams))
    }

    const params = {
      partialProductName: query ? query : null,
      organizations: organizationGroup,
    }
    // fromPromise prevents call on unmount of component
    const result = await fromPromise(getCOGSReportById(cogsReportId, params))

    if (result && !result.error) {
      setCOGSData(result)
      setLoading(false)
      setGenerating(false)
    } else {
      setLoading(false)
      setGenerating(false)
    }
  }

  useEffect(() => {
    getData()
  }, [query])

  const regenerate = () => {
    setGenerating(true)
    getData(true)
  }

  const handleActionClick = ({ type }) => {
    const fileDatetime = getFileNameDateTime()
    const downloadFormat = type.split(".")[1]

    const params = {}
    if (cogsData?.organizations[0] !== organization.id) {
      params["organizations"] = organizationGroup
    }

    switch (type) {
      case "refresh":
        regenerate()
        break
      case "export.csv":
      case "export.xlsx":
        exportCOGSReport(
          cogsReportId,
          `cogs-full-${fileDatetime}`,
          downloadFormat,
          params
        )
        break
      case "exportSummary.xlsx":
      case "exportSummary.pdf":
        exportCOGSReport(
          cogsReportId,
          `cogs-summary-${fileDatetime}`,
          downloadFormat,
          params,
          "/summarised"
        )
        break
      default:
        break
    }
  }

  return (
    <>
      <Helmet>
        <title>COGS report</title>
      </Helmet>
      <div className={styles.container}>
        <Header back title="COGS report" />

        <div className={styles.content}>
          <Loader
            isLoading={loading}
            style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
          >
            Loading report..
          </Loader>
          {cogsData && (
            <div className={styles.header}>
              <div className={styles.headerContent}>
                <div className={styles.headerTopColumn}>
                  <div className={styles.thumbnail}>
                    <FontAwesomeIcon icon={faCalculator} />
                    <span className={styles.thumbnailBadge}>
                      <span>
                        <FontAwesomeIcon size="xs" icon={faSyncAlt} />
                      </span>
                    </span>
                  </div>
                  <div
                    className="my-2 mr-4 flex flex-col"
                    style={{ maxWidth: !isTabletOrMobile ? "33.33%" : "53%" }}
                  >
                    <h2 className="text-xl mb-1">{cogsData.name}</h2>
                    <p className="text-gray-700">
                      <span className="text-xs md:text-base mr-6">
                        Period:{" "}
                        <strong>{`${format(
                          new Date(cogsData.periodFrom),
                          "dd/MM/yy"
                        )}`}</strong>{" "}
                        -{" "}
                        <strong>{`${format(
                          new Date(cogsData.periodTo),
                          "dd/MM/yy"
                        )}
                    `}</strong>
                      </span>
                      <button
                        type="button"
                        disabled={!permissionObj?.permissions.modify}
                        onClick={onEditCOGSReport}
                      >
                        <FontAwesomeIcon icon={faPencil} />
                      </button>
                    </p>
                    <span className="text-xs text-gray-600">
                      {cogsData.updatedAt
                        ? `Updated at ${convertTimeStampFormat(
                            cogsData.updatedAt,
                            organization?.address.zoneId
                          )} `
                        : `Created at ${convertTimeStampFormat(
                            cogsData.createdAt,
                            organization?.address.zoneId
                          )}
                        `}
                    </span>
                  </div>
                  <div className="flex flex-1 items-center">
                    <div className="my-2 w-full max-w-md">
                      <SearchInput
                        label="Search by products"
                        placeholder="Search by products"
                        className="form-control rounded"
                        onSearchChange={setQuery}
                      />
                    </div>
                    <div className={styles.headerRight}>
                      <button
                        type="button"
                        disabled={!permissionObj?.permissions.modify}
                        className="hidden md:flex items-center text-orange-600 rounded hover:text-orange-500 font-sansSemiBold font-semibold px-0 lg:px-6 py-2 flex-shrink-0"
                        onClick={regenerate}
                      >
                        <FontAwesomeIcon
                          icon={faSync}
                          spin={generating}
                          className="mr-2 block"
                        />
                        Refresh
                      </button>
                    </div>

                    <DropdownButton
                      containerClass="ml-3 md:ml-0"
                      onActionClick={handleActionClick}
                      label={<FontAwesomeIcon icon={faFileDownload} />}
                      mobileIcon={faEllipsisH}
                      options={exportOptions}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {cogsData && (
            <ExpandableCategorisedProductTable
              data={cogsData}
              columns={columns}
              productValueMap={(prod) => {
                return {
                  totalCOGS: prod.cogs,
                  totalCostClosingStock: prod.totalCostClosingStock,
                  totalCostGoodsSpend: prod.costGoodsSpend,
                  totalSoldValue: prod.soldValue,
                  totalCostGoodsSpendPercentage: prod.costGoodsSpendPercentage,
                }
              }}
            />
          )}
        </div>
        {cogsData && (
          <div className={styles.bottomSummary}>
            Total value:{" "}
            <span
              className={`font-sansSemiBold font-semibold ${
                cogsData.totalCOGS < 0
                  ? "text-primaryPink"
                  : cogsData.totalCOGS > 0
                  ? "text-primaryGreen"
                  : ""
              }`}
            >
              {roundNumber(cogsData.totalCOGS)}
            </span>
          </div>
        )}
      </div>
    </>
  )
}

COGSReport.propTypes = {
  cogsReportId: PropTypes.string,
}

export default COGSReport
