import React, { useContext } from "react"
import { ModalContext } from "context/ModalContext"
import DishListModal from "components/common/DishListModal/DishListModal"

const RecipeItemDishCount = ({ recipe, permissionObj }) => {
  const modal = useContext(ModalContext)
  const inDishCount = recipe.dishes ? recipe.dishes.length : 0
  return (
    <button
      className="text-primaryBlue underline text-sm text-left"
      onClick={() => {
        if (!permissionObj?.permissions.read) {
          return
        }
        modal.showModal(DishListModal, {
          recipe: recipe,
        })
      }}
    >
      {inDishCount}x
    </button>
  )
}

export default RecipeItemDishCount
