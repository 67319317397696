import React from "react"
import PropTypes from "prop-types"

const Checkbox = ({ name, label, isChecked, onSelect, colorClass = "text-primaryGreen" }) => {

  return (
    <button className="flex items-center" onClick={onSelect}>
      <input
        type="checkbox"
        className={`form-checkbox ${colorClass} mr-2`}
        checked={isChecked}
        name={name}
      />
      {label && (
        <span className="text-gray-600 font-sansBold cursor-pointer">
          {label}
        </span>
      )}
    </button>
  )
}

Checkbox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  isChecked: PropTypes.bool,
  onSelect: PropTypes.func,
}

export default Checkbox
