import { get, post, put, remove } from "./api"

export const getSuppliers = (params) => {
  return get("/suppliers", params)
}

export const searchSuppliers = (inputValue) => {
  const params = {
    size: 99,
    sort: "name,asc",
    partialName: inputValue ? inputValue : null,
  }
  return getSuppliers(params)
}

export const getSupplierById = (supplierId, params) => {
  return get(`/suppliers/${supplierId}`, params)
}

export const createSupplier = (params) => {
  return post("/suppliers", params)
}

export const updateSupplier = (supplierId, params) => {
  return put(`/suppliers/${supplierId}`, params)
}

export const removeSupplier = (supplierId) => {
  return remove(`/suppliers/${supplierId}`)
}

export const createSupplierProducts = (supplierId, params) => {
  return post(`/suppliers/${supplierId}/products`, params)
}

export const createSupplierProduct = (supplierId, params, generateBarcode) => {
  return post(
    `/suppliers/${supplierId}/product${
      generateBarcode ? `?generateBarcode=true` : ""
    }`,
    params
  )
}

export const importSuppliers = (params) => {
  return post("/suppliers/import", params, {
    headers: { "Content-Type": "multipart/form-data" },
  })
}
export const importMultipleSuppliers = (params) => {
  return post("/suppliers/import-multiple", params)
}

export const importSupplierProducts = (supplierId, params) => {
  return post(`/suppliers/${supplierId}/products/import`, params, {
    headers: { "Content-Type": "multipart/form-data" },
  })
}

export const getSupplierByName = (supplierName, params) => {
  return get(`/suppliers/${supplierName}`, params)
}

export const getSupplierProducts = (supplierId, params) => {
  return get(`/suppliers/${supplierId}/products`, params)
}

export const convertSupplierFile = (params) => {
  return post("/suppliers/convert", params, {
    headers: { "Content-Type": "multipart/form-data" },
  })
}

export const createProductsFromJson = (supplierId, params, generateBarcode) => {
  return post(
    `/suppliers/${supplierId}/products${
      generateBarcode ? `?generateBarcode=true` : ""
    }`,
    params
  )
}

// Marketplace

export const getSuppliersFromMarketplace = (params) => {
  return get(`/suppliers/marketplace`, params)
}

export const getSupplierFromMarketplaceById = (supplierId, params) => {
  return get(`/suppliers/marketplace/${supplierId}`, params)
}

export const createSupplierWithProductsFromMarketplace = (params) => {
  return post(`/suppliers/marketplace`, params)
}

export const addProductsToSupplierFromMarketplace = (params) => {
  return post(`/suppliers/marketplace/products`, params)
}
