import React, { useContext } from "react"
import PropTypes from "prop-types"
import { isValid } from "date-fns"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames/bind"
import {
  faPencil,
  faCalendar,
  faSearch,
  faPlus,
} from "@fortawesome/pro-regular-svg-icons"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import { deliveryNoteStates } from "services/constants"
import { faBoxOpen } from "@fortawesome/pro-duotone-svg-icons"
import * as styles from "./ConfirmDetailsHeader.module.css"
import { GlobalDispatchContext } from "context/GlobalContextProvider"
import { formatAsYearMonthDay } from "services/helpers"
import usePermissions from "hooks/usePermissions"

const cx = classNames.bind(styles)
const ConfirmDetailsHeader = ({
  note,
  onEdit,
  onSearchOrderAction,
  searchOrderActive,
  onClose,
  addManually,
}) => {
  const permissionObj = usePermissions("Delivery notes")
  const dispatch = useContext(GlobalDispatchContext)
  const dnState = deliveryNoteStates.find((oS) => oS.value === note.status)

  return (
    <div className={styles.header}>
      <div className={styles.thumbnailWrapper}>
        <div className={styles.thumbnail}>
          <div
            className={styles.iconWrapper}
            style={{
              backgroundColor: "#FAEAFF",
              color: "#B91DB3",
            }}
          >
            <FontAwesomeIcon icon={faBoxOpen} />
          </div>
        </div>
        {dnState && (
          <span
            className={styles.statusBulb}
            style={{
              backgroundColor: dnState.color,
              width: "12px",
              height: "12px",
            }}
          ></span>
        )}
      </div>

      <div className={styles.headerMain}>
        <div className="flex flex-col">
          <h3 className={styles.noteRef}>
            {note.status === "DRAFT" && "[Draft] "}
            {note.deliveryNoteNumber || `DN-??`}
            {note.id && (
              <button
                onClick={onEdit}
                disabled={!permissionObj?.permissions.modify}
                className={cx("button--autoWidth", "editButton")}
              >
                <FontAwesomeIcon icon={faPencil} />
              </button>
            )}
          </h3>
          {note.supplier && (
            <p className={styles.supplierName}>By {note.supplier.name}</p>
          )}
          {note.deliveryDate && isValid(new Date(note.deliveryDate)) && (
            <p className={styles.deliveryDate}>
              <FontAwesomeIcon icon={faCalendar} className="mr-1" />
              {formatAsYearMonthDay(new Date(note.deliveryDate))}
            </p>
          )}
        </div>
        {!note.po && note.products.length == 0 && note.supplier !== null && (
          <div>
            <button
              className="button button--primaryGreen button--autoWidth p-2 font-sansSemiBold font-semibold rounded-t border text-base"
              onClick={(e) => {
                e.preventDefault()
                addManually()
                // Set add manual flag here
                dispatch({
                  type: "RESET_NEW_DELIVERY_NOTE",
                  options: { resetSupplier: false },
                })
                dispatch({
                  type: "UPDATE_NEW_DELIVERY_NOTE",
                  payload: { isCreatedManuallyWithoutOrder: true },
                })
              }}
              disabled={!permissionObj?.permissions.modify}
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Add w/o order
            </button>
          </div>
        )}
      </div>

      {note.supplier &&
        note.products.length > 0 &&
        !note.isCreatedManuallyWithoutOrder && (
          <div className={styles.noteSubDetails}>
            {note.po ? (
              <span className={cx("noteOrderStatus", "found")}>
                Related order: #{note.po}
              </span>
            ) : (
              <div className={styles.notFoundContainer}>
                <span className={cx("noteOrderStatus", "notfound")}>
                  No related order{" "}
                </span>
                {!searchOrderActive && (
                  <button
                    onClick={onSearchOrderAction}
                    className="button button--autoWidth button--orange text-sm"
                    disabled={!permissionObj?.permissions.modify}
                  >
                    <FontAwesomeIcon icon={faSearch} className="mr-2" />
                    Search order
                  </button>
                )}
              </div>
            )}
          </div>
        )}
      <button className={styles.closeButton} onClick={onClose}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </div>
  )
}

ConfirmDetailsHeader.propTypes = {
  note: PropTypes.object,
  onEdit: PropTypes.func,
  onSearchOrderAction: PropTypes.func,
  searchOrderActive: PropTypes.bool,
  onClose: PropTypes.func,
  addManually: PropTypes.func,
}

export default ConfirmDetailsHeader
