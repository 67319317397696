/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react"
import { navigate } from "gatsby"
import { usePromise, usePrevious, useMountedState } from "react-use"
import { getProducts } from "services/product"
import { searchSuppliers } from "services/supplier"
import {
  formatCurrencyValue,
  roundNumber,
  checkIsAutomaticBarcode,
} from "services/helpers"
import EventBus from "services/events"
import BaseTable, { AutoResizer } from "react-base-table"
import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import SearchInput from "components/forms/SearchInput"
import AsyncSelect from "components/forms/AsyncSelect"
import FilterSelect from "components/forms/FilterSelect"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWineBottle, faCube, faBan } from "@fortawesome/pro-solid-svg-icons"
import { faCheck } from "@fortawesome/pro-regular-svg-icons"
import {
  productCategories,
  productBeverageCategories,
  productFoodCategories,
  productCategoriesCombined,
  productSingleUnits,
} from "services/constants"

import classNames from "classnames/bind"

import * as styles from "./ProductSelect.module.css"
import { Product, Supplier } from "../../../services/types"

const cx = classNames.bind(styles)

interface Props {
  /**
   * close modal callback
   */
  onClose: () => void
  /**
   * select item callback
   */
  onSelect: (rowData: Product | Product[], inCase?: boolean | undefined) => void
  /**
   * handle add item
   */
  onAddItem?: () => void
  /**
   * list of currently selected items
   */
  currentProducts?: Product[]
  /**
   * index from which newly selected items should be added to the list of currently selected items.
   * items are added to the end of the list by default
   */
  selectionStartIndex?: number
  /**
   * enable multi select functionality
   */
  multiSelect?: boolean
  /**
   * list of fields required to enable item selection
   */
  requiredFields?: string[]
  /**
   * enable search by barcode
   */
  searchByBarcode?: boolean
  /**
   * enable search by category
   */
  searchByCategories?: boolean
  /**
   * enable search by packaging
   */
  searchByPackaging?: boolean
  /**
   * enable search by SKU
   */
  searchBySKU?: boolean
  /**
   * enable search by supplier
   */
  searchBySupplier?: boolean
  /**
   * the previously selected supplier
   */
  selectedSupplier?: Supplier
  /**
   * supplier available for select
   */
  allowedSuppliers?: Supplier[]
}

const ProductSelect = ({
  onClose,
  onSelect,
  onAddItem,
  currentProducts = [],
  selectionStartIndex,
  multiSelect,
  requiredFields = [],
  searchByBarcode,
  searchByCategories,
  searchByPackaging,
  searchBySKU,
  searchBySupplier,
  selectedSupplier,
  allowedSuppliers = [],
}: Props) => {
  const _isMounted = useMountedState()
  const [loading, setLoading] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)
  const [loadedAll, setLoadedAll] = useState(false)
  const fromPromise = usePromise()
  const [productData, setProductData] = useState<{ content: Product[] }>({
    content: [],
  })
  const [q, setQ] = useState("")
  const [barcodePart, setBarcodePart] = useState("")
  const previousBarcodePart = usePrevious(barcodePart)

  const [skuPart, setSkuPart] = useState("")
  const previousSkuPart = usePrevious(skuPart)

  const previousQ = usePrevious(q)
  const [supplier, setSupplier] = useState(
    selectedSupplier
      ? selectedSupplier
      : allowedSuppliers?.length
      ? allowedSuppliers[0]
      : null
  )
  const previousSupplier = usePrevious(supplier)

  const [packaging, setPackaging] = useState(null)
  const previousPackaging = usePrevious(packaging)

  const [category, setCategory] = useState(null)
  const previousCategory = usePrevious(category)

  const [selectedPackaging, setSelectedPackaging] = useState<
    { name: string; id: string; packaging: string }[]
  >([])

  const [paginationData, setPaginationData] = useState({
    page: 0,
    size: 10,
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  })
  const previousPage = usePrevious(paginationData.page)

  const [selectedProducts, setSelectedProducts] = useState(currentProducts)

  // const handleSubproductSwitch = (value, index) => {
  //   const rowIndex = showSubproductRows.indexOf(index)
  //   const currentRows = [...showSubproductRows]
  //   switch (value) {
  //     case "multiple":
  //       currentRows.splice(rowIndex, 1)
  //       setShowSubproductRows(currentRows)
  //       break
  //     case "single":
  //       if (rowIndex === -1) {
  //         currentRows.push(index)

  const onSelectPackaging = (value, productData) => {
    const index = selectedPackaging.findIndex(
      (element) => element.id === productData.id
    )
    const alreadyExists = index !== -1

    if (value === "single") {
      if (!alreadyExists) {
        const newProduct = {
          name: productData.name,
          id: productData.id,
          packaging: "single",
        }

        setSelectedPackaging([...selectedPackaging, { ...newProduct }])
      }

      if (alreadyExists) {
        const newState = [...selectedPackaging]
        const updatedElement = { ...newState[index] }
        updatedElement.packaging = "single"
        newState[index] = updatedElement
        setSelectedPackaging(newState)
      }
    }

    if (value === "multiple") {
      if (!alreadyExists) {
        const newProduct = {
          name: productData.name,
          id: productData.id,
          packaging: "multiple",
        }
        setSelectedPackaging([...selectedPackaging, { ...newProduct }])
      }

      if (alreadyExists) {
        const newState = [...selectedPackaging]
        const updatedElement = { ...newState[index] }
        updatedElement.packaging = "multiple"
        newState[index] = updatedElement
        setSelectedPackaging(newState)
      }
    }
  }

  const columns = [
    {
      key: "description",
      title: "",
      dataKey: "description",
      width: 180,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => {
        const currency = null
        const hasProductCase = !!rowData.productCase
        const hasSelectedPackaging = selectedPackaging.find((product) => {
          return product.id === rowData.id
        })

        const renderIcon = () => {
          if (!hasProductCase) {
            return faWineBottle
          }

          if (hasProductCase && !hasSelectedPackaging) {
            return faWineBottle
          }

          if (hasProductCase && hasSelectedPackaging) {
            if (hasSelectedPackaging.packaging === "single") {
              return faWineBottle
            } else {
              return faCube
            }
          }
        }

        const cats = productBeverageCategories.concat(productFoodCategories)
        const matchedCat = productCategories.find(
          (c) => c.value === rowData.category
        )
        const matchedSubCat = cats.find((c) => c.value === rowData.subCategory)

        const isAutomaticBarcode = checkIsAutomaticBarcode(rowData.barcode)

        return (
          <div className="flex flex-col my-3">
            {(rowData.category || rowData.subCategory) && (
              <span className="text-xs uppercase text-gray-600">{`${
                rowData.category
                  ? matchedCat
                    ? matchedCat.label
                    : rowData.category
                  : ""
              } - ${
                rowData.subCategory
                  ? matchedSubCat
                    ? matchedSubCat.label
                    : rowData.subCategory
                  : ""
              }`}</span>
            )}
            <span className="font-sansSemiBold mb-1 text-sm font-semibold text-primaryBlue">
              <FontAwesomeIcon icon={renderIcon()} className="mr-2" />
              {rowData.name}
            </span>

            {!hasProductCase && (
              <span className="font-sansSemiBold text-sm font-semibold text-primaryBlue px-0">
                {rowData.size} {rowData.measure}{" "}
                {rowData.unit ? `(${rowData.unit.toLowerCase()})` : ""}
              </span>
            )}

            {hasProductCase && !hasSelectedPackaging && (
              <div>
                <select
                  className="font-sansSemiBold text-sm font-semibold text-primaryBlue bg-transparent px-2"
                  onChange={(event) =>
                    onSelectPackaging(event.target.value, rowData)
                  }
                >
                  <option value="single">
                    {"single"} {rowData.size} {rowData.measure}
                  </option>
                  <option value="multiple">
                    multiple {rowData.productCase.size} x {rowData.size}{" "}
                    {rowData.measure}
                  </option>
                </select>
              </div>
            )}

            {hasProductCase && hasSelectedPackaging && (
              <div>
                <select
                  className="font-sansSemiBold text-sm font-semibold text-primaryBlue bg-transparent px-2"
                  onChange={(event) =>
                    onSelectPackaging(event.target.value, rowData)
                  }
                  defaultValue={hasSelectedPackaging.packaging}
                >
                  <option
                    value="single"
                    selected={hasSelectedPackaging.packaging === "single"}
                  >
                    {"single"} {rowData.size} {rowData.measure}
                  </option>
                  <option
                    value="multiple"
                    selected={hasSelectedPackaging.packaging === "multiple"}
                  >
                    multiple {rowData.productCase.size} x {rowData.size}{" "}
                    {rowData.measure}
                  </option>
                </select>
              </div>
            )}

            {rowData.barcode && !isAutomaticBarcode && (
              <span className="text-sm text-gray-700">{rowData.barcode}</span>
            )}

            {!hasProductCase && (
              <span>{`${
                currency
                  ? formatCurrencyValue(rowData.price)
                  : roundNumber(rowData.price)
              } / ${rowData.unit ? rowData.unit.toLowerCase() : ""}`}</span>
            )}
            {hasProductCase && !hasSelectedPackaging && (
              <span>{`${
                currency
                  ? formatCurrencyValue(rowData.price)
                  : roundNumber(rowData.price)
              } / ${rowData.unit ? rowData.unit.toLowerCase() : ""}`}</span>
            )}
            {hasProductCase && hasSelectedPackaging && (
              <span>
                {hasSelectedPackaging.packaging === "single"
                  ? `${
                      currency
                        ? formatCurrencyValue(rowData.price)
                        : roundNumber(rowData.price)
                    } / ${rowData.unit ? rowData.unit.toLowerCase() : ""}`
                  : `${
                      currency
                        ? formatCurrencyValue(rowData.productCase.price)
                        : roundNumber(rowData.productCase.price)
                    } / pack`}
              </span>
            )}
          </div>
        )
      },
    },
    {
      key: "action",
      flexGrow: 1,
      flexShrink: 0,
      width: 150,
      className: "justify-end",
      // eslint-disable-next-line react/prop-types
      cellRenderer: ({ rowData }) => {
        const hasProductCase = !!rowData.productCase
        const hasSelectedPackaging = selectedPackaging.find((product) => {
          return product.id === rowData.id
        })

        const isSelectedInCase = () => {
          if (!hasProductCase) return false

          if (hasProductCase && !hasSelectedPackaging) return false

          if (hasProductCase && hasSelectedPackaging) {
            if (hasSelectedPackaging.packaging === "single") return false
            if (hasSelectedPackaging.packaging === "multiple") return true
          }
        }

        const isSelected = selectedProducts?.map((item) => item.id)

        const shouldDisable = (data, requiredFields) => {
          let disable = false
          requiredFields.forEach((field) => {
            if (!data[field]) {
              disable = true
              return
            }
          })
          return disable
        }

        const isDisabled = shouldDisable(rowData, requiredFields)

        return (
          <>
            {isDisabled && (
              <p className="text-xs text-gray-700">
                Item missing {requiredFields.join("/")}. Go to{" "}
                <button
                  className="underline text-left"
                  onClick={() => {
                    EventBus.dispatch("navigate")
                    navigate("/dashboard/products/items")
                  }}
                >
                  &apos;Products&apos; → &apos;Items&apos;
                </button>{" "}
                to update.
              </p>
            )}
            {!isSelected?.includes(rowData.id) ? (
              <button
                type="button"
                className="button whitespace-nowrap button--smaller button--autoWidth button--paleBlue mr-2 flex-shrink-0"
                onClick={(e) => {
                  e.preventDefault()
                  if (!multiSelect) {
                    onSelect(rowData, isSelectedInCase())
                  } else {
                    const latestProducts = [...selectedProducts]
                    if (selectionStartIndex === undefined) {
                      latestProducts.push(rowData)
                    } else {
                      latestProducts.splice(selectionStartIndex, 0, rowData)
                    }
                    setSelectedProducts(latestProducts)
                  }
                }}
                disabled={isDisabled}
              >
                Select
              </button>
            ) : (
              <button
                type="button"
                className="button whitespace-nowrap button--smaller button--autoWidth button--primaryBlueLightweight mr-2"
                onClick={(e) => {
                  e.preventDefault()
                  const latestProducts = [...selectedProducts]
                  setSelectedProducts(
                    latestProducts.filter((p) => p.id !== rowData.id)
                  )
                }}
              >
                <FontAwesomeIcon icon={faCheck} className="mr-2" />
                Selected
              </button>
            )}
          </>
        )
      },
    },
  ]

  const getData = useCallback(async () => {
    if (!_isMounted()) {
      return
    }
    setLoading(true)

    const params = {
      page: paginationData.page,
      size: paginationData.size,
      sort: "createdDate,desc",
    }

    if (supplier) {
      params.supplierId = supplier.id || supplier.value
    }

    if (packaging) {
      params.unit = packaging.value

      if (previousPackaging && previousPackaging.value !== packaging.value) {
        params.page = 0
      }
    }

    if (q) {
      params.partialProductName = q
    }

    if (barcodePart) {
      params.partialBarcode = barcodePart
    }

    if (skuPart) {
      params.partialCode = skuPart
    }

    if (category) {
      params.subCategory = category.value

      if (previousCategory && previousCategory.value !== category.value) {
        params.page = 0
      }
    }

    // fromPromise prevents call on unmount of component
    const result = await fromPromise(getProducts(params))

    if (result && !result.error) {
      const newContent = [
        ...result.content.map((item) => {
          return {
            ...item,
          }
        }),
      ]

      if (paginationData.page === 0) {
        setProductData({ content: [...newContent] })
      } else {
        setProductData({ content: [...productData.content, ...newContent] })
      }

      setPaginationData({
        ...paginationData,
        size: result.size,
        totalPages: result.totalPages,
        totalElements: result.totalElements,
        numberOfElements: result.numberOfElements,
      })
      setLoadingMore(false)
      setLoadedAll(paginationData.page === result.totalPages - 1)
      setLoading(false)
    } else {
      setLoading(false)
    }
  }, [
    supplier,
    packaging,
    q,
    barcodePart,
    skuPart,
    category,
    paginationData,
    productData,
  ])

  const getFullData = useCallback(async () => {
    if (!_isMounted()) {
      return
    }

    setLoading(true)
    setProductData({ content: [] })

    const params = {
      //Initial params, will be changed depending on fetch return
      page: 0,
      size: 9999,
      sort: "createdDate,desc",
    }

    if (supplier) {
      params.supplierId = supplier.id || supplier.value
    }

    if (q) {
      //Resetting query string to nothing
      params.partialProductName = ""
      setQ("")
    }

    //Keeping comments as reference

    // if (packaging) {
    //   params.unit = packaging.value

    //   if (previousPackaging && previousPackaging.value !== packaging.value) {
    //     params.page = 0
    //   }
    // }

    // if (barcodePart) {
    //   params.partialBarcode = barcodePart
    // }

    // if (skuPart) {
    //   params.partialCode = skuPart
    // }

    // if (category) {
    //   params.subCategory = category.value

    // if (previousCategory && previousCategory.value !== category.value) {
    //     params.page = 0
    //   }
    // }

    // fromPromise prevents call on unmount of component
    const result = await fromPromise(getProducts(params))

    const { totalPages } = result

    const promises = new Array(totalPages).fill(null).map(async (c, i) => {
      const result = getProducts({
        ...params,
        page: i,
      }).then((result) => {
        if (result && !result.error) {
          const newContent = [
            ...result.content.map((item) => {
              return {
                ...item,
              }
            }),
          ]
          return newContent
        }
        return []
      })
      return result
    })

    const additionalData = await Promise.all(promises).then(
      (values) =>
        values.reduce((accumulator, current) => {
          return accumulator.concat(current)
        }),
      []
    ) //Creating one large array containing all items from all paginations

    setProductData({
      content: additionalData,
    })
    setLoading(false)
    setPaginationData({
      page: Math.ceil(additionalData.length / 10),
      size: paginationData.size,
      totalPages: totalPages,
      totalElements: additionalData.length,
      numberOfElements: additionalData.length,
    })
    setSelectedProducts((prev) => [
      ...prev,
      ...additionalData.filter(
        (a) => !selectedProducts.find((b) => b.id === a.id)
      ),
    ]) //Assing current filtered selection to state, filtered = products that are not already in selection, previously caused duplication issues
  }, [supplier, q, paginationData])

  const removeSelectionFromState = () => {
    // const supplierId = supplier?.id || supplier?.value

    // if (!supplierId) return

    // setSelectedProducts((prev) => {
    //   const final = prev.filter((a) => a.supplierId !== supplierId)
    //   return final
    // })

    setSelectedProducts([])
  }

  useEffect(() => {
    if (!selectedSupplier) {
      getData()
    }
  }, [selectedSupplier])

  useEffect(() => {
    if (previousPage !== undefined) {
      getData()
    }
  }, [paginationData.page])

  useEffect(() => {
    if (previousSupplier || supplier) {
      resetPage()
    }
  }, [supplier])

  useEffect(() => {
    if (previousPackaging || packaging) {
      getData()
    }
  }, [packaging])

  useEffect(() => {
    if (previousCategory || category) {
      resetPage()
    }
  }, [category])

  useEffect(() => {
    if (previousQ || q) {
      resetPage()
    }
  }, [q])

  useEffect(() => {
    if (previousBarcodePart || barcodePart) {
      resetPage()
    }
  }, [barcodePart])

  useEffect(() => {
    if (previousSkuPart || skuPart) {
      resetPage()
    }
  }, [skuPart])

  const resetPage = () => {
    if (paginationData.page === 0) {
      return getData()
    }
    setPaginationData({
      ...paginationData,
      page: 0,
    })
  }

  const handleEndReached = () => {
    if (loading || loadingMore || loadedAll) return
    loadMore()
  }

  const loadMore = () => {
    setLoadingMore(true)
    setPaginationData({ ...paginationData, page: paginationData.page + 1 })
  }

  const productsOutOfCurrentSelection = React.useMemo(() => {
    const hasSupplier = supplier?.id || supplier?.value

    return !hasSupplier
      ? []
      : productData?.content?.filter((a) =>
          selectedProducts.find(
            (b) => a.id === b.id && a.supplierId === b.supplierId
          )
        )
  }, [productData.content, selectedProducts])

  return (
    <>
      <div className={styles.container}>
        <div className="p-5 flex justify-between items-center border ">
          <h1 className="text-primaryBlue text-xl">Select product(s)</h1>
        </div>
        <div className={cx("subHeader", { "flex-wrap": !multiSelect })}>
          <div className="my-2 px-2 w-1/2">
            <SearchInput
              label="Name"
              placeholder="Product name"
              className="form-control rounded text-sm md:text-base"
              onSearchChange={(val) => {
                setQ(val)
              }}
            />
          </div>
          {searchByBarcode && (
            <div className="my-2 px-2 w-1/2">
              <SearchInput
                label="Barcode"
                placeholder="Barcode"
                className="form-control rounded text-sm md:text-base"
                onSearchChange={(val) => {
                  setBarcodePart(val)
                }}
              />
            </div>
          )}

          {searchBySKU && (
            <div className="my-2 px-2 w-1/2">
              <SearchInput
                label="SKU"
                placeholder="SKU code"
                className="form-control rounded text-sm md:text-base"
                onSearchChange={(val) => {
                  setSkuPart(val)
                }}
              />
            </div>
          )}
          {searchBySupplier && (
            <div className="my-1 px-2 w-1/2">
              <AsyncSelect
                promise={searchSuppliers}
                filterOptions={(option) =>
                  allowedSuppliers?.length
                    ? allowedSuppliers.find((a) => a.value === option.value.id)
                    : true
                }
                placeholder="All suppliers"
                isDisabled={selectedSupplier}
                defaultValue={
                  selectedSupplier
                    ? { value: selectedSupplier, label: selectedSupplier.name }
                    : allowedSuppliers?.length
                    ? allowedSuppliers[0]
                    : null
                }
                isClearable={!allowedSuppliers?.length}
                optionLabel="name"
                onChange={(val) => {
                  setSupplier(val ? val.value : null)
                }}
              />
            </div>
          )}
          {searchByCategories && (
            <div className="my-1 px-2 w-1/2">
              <FilterSelect
                options={productCategoriesCombined}
                value={category}
                getOptionLabel={(opt) => `${opt.groupLabel} - ${opt.label}`}
                onChange={async (val) => {
                  await setCategory(val)
                }}
                placeholder="All categories"
                isClearable={true}
                isSearchable={true}
                className="my-1 w-full md:w-64 mr-6 text-sm md:text-base"
              />
            </div>
          )}

          {searchByPackaging && (
            <div className="my-1 px-2 w-1/2">
              <FilterSelect
                options={productSingleUnits}
                value={packaging}
                onChange={async (val) => {
                  setPackaging(val)
                }}
                placeholder="All packaging"
                isClearable={true}
                isSearchable={true}
                className="my-1 w-full md:w-64 mr-6 text-sm md:text-base"
              />
            </div>
          )}
        </div>

        <div className={styles.content}>
          <div className="w-full flex-grow">
            <AutoResizer>
              {({ width, height }) => (
                <BaseTable
                  fixed={false}
                  estimatedRowHeight={80}
                  width={width}
                  disabled={loading}
                  ignoreFunctionInColumnCompare={false} // Very important (hooks won't work otherwise)delive
                  loadingMore={loadingMore}
                  onEndReachedThreshold={50}
                  onEndReached={handleEndReached}
                  maxHeight={
                    productData.content.length === 0 ? undefined : height
                  }
                  height={productData.content.length === 0 ? 200 : height}
                  headerHeight={0}
                  data={productData.content}
                  columns={columns}
                  emptyRenderer={
                    <div className={styles.noProducts}>
                      <p className="mb-4">No products found</p>
                      {onAddItem && (
                        <button
                          type="button"
                          onClick={onAddItem}
                          className="
                            button button--autoWidth button--primaryGreen
                            addManualButton
                          "
                        >
                          <FontAwesomeIcon icon={faPlus} className="mr-3" />
                          Add item
                        </button>
                      )}
                    </div>
                  }
                />
              )}
            </AutoResizer>
          </div>
        </div>
        {multiSelect && (
          <footer className={styles.footer}>
            <div className="w-full flex flex-col">
              <div className="flex flex-row space-x-4 sm:space-y-1 space-y-0 mb-2 justify-center">
                <button
                  disabled={
                    q ||
                    loading ||
                    productsOutOfCurrentSelection.length ===
                      productData.content.length ||
                    selectedProducts.length === productData.content.length
                  }
                  onClick={getFullData}
                  className="button--autoWidth bg-primaryBlue text-white border rounded-full px-4 py-2 flex items-center justify-center font-semibold"
                >
                  <FontAwesomeIcon className="mr-1" icon={faPlus} />
                  Add all{" "}
                </button>

                <button
                  disabled={
                    q ||
                    loading ||
                    // productsOutOfCurrentSelection.length <= 0 ||
                    selectedProducts.length === 0
                  }
                  onClick={removeSelectionFromState}
                  className="button--autoWidth bg-primaryBlue text-white border rounded-full px-4 py-2 flex items-center justify-center font-semibold"
                >
                  <FontAwesomeIcon className="mr-1" icon={faBan} />
                  Clear selection
                </button>
              </div>
              <div className="flex w-full justify-between items-center">
                <button className={styles.cancelButton} onClick={onClose}>
                  Close
                </button>
                <span className="font-semibold mr-4 sm:mr-0">
                  <span className="text-primaryBlue">
                    {selectedProducts.length}{" "}
                    {selectedProducts.length === 1 ? "product" : "products"}
                  </span>{" "}
                  <span className="text-gray-600">selected</span>
                </span>
                <button
                  type="button"
                  className="button button--autoWidth button--primaryGreen"
                  onClick={() => {
                    onSelect(selectedProducts)
                  }}
                  disabled={selectedProducts.length < 1}
                >
                  <FontAwesomeIcon icon={faCheck} className="mr-2" />
                  Confirm
                </button>
              </div>
            </div>
          </footer>
        )}
      </div>
    </>
  )
}

export default ProductSelect
