import React, { useState, useContext, useEffect } from "react"
import { usePromise } from "react-use"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/pro-light-svg-icons"

import { GlobalStateContext } from "context/GlobalContextProvider"
import StockTransferConnection from "components/stock/transfers/StockTransferConnection/StockTransferConnection"
import Loader from "components/common/Loader/Loader"

import { getStockTransferConnectionsByCriteria } from "services/stock-transfer"
import usePermissions from "hooks/usePermissions"
import { Permission } from "services/types"

const StockTransferList = () => {
  const { organization, organizations } = useContext(GlobalStateContext)
  const permissionObj = usePermissions("Company settings") as Permission

  const [loading, setLoading] = useState(true)
  const [stockTransfersData, setStockTransfersData] = useState({
    content: [],
  })
  const [connections, setConnections] = useState([]) as any

  const fromPromise = usePromise()

  const enableAddConnection =
    permissionObj?.permissions.modify &&
    organizations &&
    organizations.length > 1 &&
    ((connections.length > 0 &&
      connections[connections.length - 1].id !== "") ||
      connections.length === 0)

  const addConnection = () => {
    setConnections([...connections, { id: "" }])
  }

  const getData = async () => {
    const params = {
      sort: "createdAt,desc",
    }
    try {
      // fromPromise prevents call on unmount of component
      const result = await fromPromise(
        getStockTransferConnectionsByCriteria(params)
      )

      if (result && !result.error) {
        setStockTransfersData({ ...stockTransfersData, ...result })
        setConnections(result.content)
      }
    } catch (error) {
      console.log({ error })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    let isMounted = true

    if (isMounted) {
      getData()
    }

    return () => {
      isMounted = false
    }
  }, [])

  return (
    <div className="flex flex-col mt-4 md:mt-0">
      {!loading && (
        <p className="text-gray-800 mb-3 text-sm">
          {stockTransfersData.content.length > 0 ? (
            <>
              You have{" "}
              <span className="font-semibold font-sansSemiBold">
                {stockTransfersData.content.length}{" "}
                {`connection${
                  stockTransfersData.content.length > 1 ? "s" : ""
                }`}
              </span>
            </>
          ) : organizations.length > 1 ? (
            "No connections with other venues yet"
          ) : (
            "No other venues yet"
          )}
        </p>
      )}
      <div>
        <Loader
          isLoading={loading}
          style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
        >
          Loading stock transfers..
        </Loader>

        {connections.map((stockTransfer) => {
          return (
            <StockTransferConnection
              transfer={stockTransfer}
              organization={organization}
              organizations={organizations}
              refreshData={getData}
              key={stockTransfer.id}
            />
          )
        })}

        {enableAddConnection && (
          <button
            className={"button button--autoWidth button--primaryGreen"}
            onClick={addConnection}
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" /> Add connection
          </button>
        )}
      </div>
    </div>
  )
}

export default StockTransferList
