/* eslint-disable react/prop-types */
import React, { useState, useContext } from "react"
import { navigate } from "@reach/router"
import Header from "components/dashboard/Header/Header"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"

import { supplierKeys } from "services/constants"
import { convertSupplierFile, importMultipleSuppliers } from "services/supplier"
import { showError } from "services/toast"

import Helmet from "react-helmet"
import FormData from "form-data"
import { useBeforeUnload } from "react-use"

//@ts-ignore
import * as styles from "./ImportSuppliers.module.css"
import EditableImportForm from "../../common/EditableImportForm/EditableImportForm"
import EditableImportHeader from "components/common/EditableImportHeader/EditableImportHeader"
import EditableImportTable from "components/common/EditableImportTable/EditableImportTable"

import { ModalContext } from "context/ModalContext"
import { isEmail } from "services/helpers"
import usePermissions from "hooks/usePermissions"
import { Permission } from "services/types"

const SUPPLIERS_IMPORT_EXAMPLE_URL =
  "https://growyze-onboarding.s3.eu-west-2.amazonaws.com/Sample_Supplier_Import.xlsx"

const storedKeysStatic = {}
const storedRequiredKeysStatic = {}
supplierKeys.forEach((itm) => {
  storedKeysStatic[itm.value] = ""
  if (itm.required) {
    storedRequiredKeysStatic[itm.value] = ""
  }
})

const ImportSuppliers = () => {
  const [suppliersData, setSuppliersData] = useState([])
  const [hiddenIndexes, setHiddenIndexes] = useState({})
  const [isUploading, setIsUploading] = useState(false)
  const [storedKeys, setStoredKeys] = useState(storedKeysStatic)
  const [formattingHasError, setFormattingHasError] = useState(false)
  const [removeFirstRow, setRemoveFirstRow] = useState(false)
  const permissionObj = usePermissions("Suppliers") as Permission

  const modal = useContext(ModalContext)

  // KEYS

  const emptyStoredKeys = Object.keys(storedKeys).filter(
    (key) =>
      !storedKeys[key].length && storedRequiredKeysStatic.hasOwnProperty(key)
  )

  const reversedStoredKeys = {}
  Object.keys(storedKeys).forEach((key) => {
    if (storedKeys[key] !== "") {
      reversedStoredKeys[storedKeys[key]] = key
    }
  })

  useBeforeUnload(isUploading || Boolean(suppliersData.length), "Are you sure?")

  // HANDLERS

  const uploadFile = async (ev) => {
    const uploadedFile = ev.currentTarget.files[0]
    const formData = new FormData()

    setIsUploading(true)

    formData.append("file", uploadedFile)
    const uploadResult = await convertSupplierFile(formData)

    setIsUploading(false)

    if (uploadResult.status === 400) {
      showError(uploadResult.message, { closeOnClick: true, autoClose: false })
    } else {
      if (Array.isArray(uploadResult.rows)) {
        setSuppliersData(uploadResult.rows)
      }
    }
  }

  const onImport = (data) => {
    modal.showModal(ConfirmModal, {
      title: "Import suppliers",
      text: `You’re about to import ${data.length} suppliers. Do you want to proceed?`,
      confirmButtonText: "Import",
      onConfirm: () => handleImport(data),
    })
  }

  const handleImport = async (data) => {
    const suppliersRecord = await importMultipleSuppliers(
      data.map((data) => {
        const newItem = {}
        Object.keys(storedKeys).map((key) => {
          if (data[storedKeys[key]]) {
            if (key.indexOf(".") > -1) {
              // Need to format the nested object
              const depth = key.split(".")
              let nestedObj = newItem
              depth.forEach((itmKey, index) => {
                if (index >= depth.length - 1) {
                  if (itmKey === "emails") {
                    nestedObj[itmKey] = [data[storedKeys[key]]] // Final item, so lets assign the actual value
                  } else {
                    nestedObj[itmKey] = data[storedKeys[key]]
                  }
                } else {
                  if (!nestedObj[itmKey]) {
                    nestedObj[itmKey] = {}
                  }
                }
                nestedObj = nestedObj[itmKey]
              })
            } else {
              newItem[key] = data[storedKeys[key]]
            }
          }
        })
        return newItem
      })
    )

    if (
      suppliersRecord.status === 400 ||
      suppliersRecord.status === 403 ||
      suppliersRecord.status === 409
    ) {
      showError(suppliersRecord.message, {
        closeOnClick: true,
      })
    } else {
      navigate("/dashboard/purchases/suppliers")
    }
  }

  const verifyField = (key, item = "", row = {}) => {
    // Verify the field to make sure there's no errors
    let error = ""

    const emailFields = [
      "email",
      "ordersContact.emails",
      "deliveriesContact.emails",
      "invoicesContact.emails",
    ]

    const linkedFields = [
      [
        { label: "Orders: Email", value: "ordersContact.emails" },
        { label: "Orders: Tel", value: "ordersContact.telephone" },
      ],
      [
        { label: "Deliveries: Email", value: "deliveriesContact.emails" },
        { label: "Deliveries: Tel", value: "deliveriesContact.telephone" },
      ],
      [
        { label: "Invoices: Email", value: "invoicesContact.emails" },
        { label: "Invoices: Tel", value: "invoicesContact.telephone" },
      ],
    ]

    const getKeyByValue = (object, value) => {
      return Object.keys(object).find((key) => object[key] === value)
    }

    if (reversedStoredKeys[key]) {
      if (
        emailFields.includes(reversedStoredKeys[key]) &&
        isEmail(item) &&
        item.length > 0
      ) {
        // Check if it's a valid email with regex
        error = "Field is not a valid email"
      }

      for (let i = 0; i < linkedFields.length; i++) {
        //Checks for "linked fields"
        const fieldGroup = linkedFields[i]
        const groupValues = fieldGroup.map((field) => field.value)
        if (groupValues.includes(reversedStoredKeys[key])) {
          const field1 = getKeyByValue(reversedStoredKeys, groupValues[0])
          const field2 = getKeyByValue(reversedStoredKeys, groupValues[1])
          // @ts-ignore
          if (row[field2] && !row[field1]) {
            error = `Please also provide ${fieldGroup[0].label}`
          }
        }
      }

      if (
        item.length < 1 &&
        storedRequiredKeysStatic.hasOwnProperty(reversedStoredKeys[key])
      ) {
        error = "Field is required"
      }
    }

    return error
  }

  return (
    <>
      <Helmet>
        <title>Import Suppliers</title>
      </Helmet>
      <div className={styles.container}>
        <Header back title="Import Suppliers" />

        <div className={styles.content}>
          {suppliersData.length < 1 && (
            <EditableImportForm
              isUploading={isUploading}
              onUpload={uploadFile}
              exampleFile={SUPPLIERS_IMPORT_EXAMPLE_URL}
              disabled={!permissionObj?.permissions.modify}
              infoText={
                <div>
                  <p className="text-sm text-gray-700 my-6">
                    Information you can import for your suppliers:
                  </p>
                  <ul className="text-sm text-gray-700 mb-6 list-inside list-disc">
                    <li>Supplier Name*</li>
                    <li>Email*</li>
                    <li>Contact Name*</li>
                    <li>Account number</li>
                    <li>Phone number</li>
                    <li>Orders: Tel</li>
                    <li>Orders: Email</li>
                    <li>Deliveries: Tel</li>
                    <li>Deliveries: Email</li>
                    <li>Invoices: Tel</li>
                    <li>Invoices: Email</li>
                    <li>Website</li>
                    <li>Notes</li>
                    <li>Address</li>
                    <li>Currency</li>
                  </ul>
                </div>
              }
            />
          )}

          {suppliersData.length > 0 && (
            <>
              <EditableImportHeader
                setImportData={setSuppliersData}
                keys={supplierKeys}
                storedKeysStatic={storedKeysStatic}
                setStoredKeys={setStoredKeys}
                emptyStoredKeys={emptyStoredKeys}
                setHiddenIndexes={setHiddenIndexes}
                formattingHasError={formattingHasError}
                removeFirstRow={removeFirstRow}
                setRemoveFirstRow={setRemoveFirstRow}
              />

              <EditableImportTable
                type="supplier"
                verifyField={verifyField}
                onImport={onImport}
                importData={suppliersData}
                setImportData={setSuppliersData}
                keys={supplierKeys}
                storedKeys={storedKeys}
                setStoredKeys={setStoredKeys}
                emptyStoredKeys={emptyStoredKeys}
                reversedStoredKeys={reversedStoredKeys}
                hiddenIndexes={hiddenIndexes}
                setHiddenIndexes={setHiddenIndexes}
                formattingHasError={formattingHasError}
                setFormattingHasError={setFormattingHasError}
                removeFirstRow={removeFirstRow}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ImportSuppliers
