import { get, post, put, remove } from "./api"
import { saveAs } from "file-saver"

export const getStockTakes = (params) => {
  return get("/stock-take/report", params)
}

export const createStockTake = (params) => {
  return post(`/stock-take/report`, params)
}

export const createSubStockTake = (stockTakeId, params) => {
  return post(`/stock-take/report/${stockTakeId}/sub-report`, params)
}

export const getStockTake = (stockTakeId, params = {}) => {
  return get(`/stock-take/report/${stockTakeId}`, params)
}

export const getSubStockTake = (stockTakeId, subStockTakeId, params = {}) => {
  return get(
    `/stock-take/report/${stockTakeId}/sub-report/${subStockTakeId}`,
    params
  )
}

export const updateStockTake = (stockTakeId, params) => {
  return put(`/stock-take/report/${stockTakeId}`, params)
}

export const updateSubStockTake = (stockTakeId, subStockTakeId, params) => {
  return put(
    `/stock-take/report/${stockTakeId}/sub-report/${subStockTakeId}`,
    params
  )
}

export const mergeStockTake = (stockTakeId, completedAt) => {
  return get(
    `/stock-take/report/${stockTakeId}/merge?completedAt=${completedAt}`
  )
}

export const deleteStockTake = (stockTakeId) => {
  return remove(`/stock-take/report/${stockTakeId}`)
}

export const deleteSubStockTake = (stockTakeId, subStockTakeId) => {
  return remove(
    `/stock-take/report/${stockTakeId}/sub-report/${subStockTakeId}`
  )
}

export const updateStockTakeProduct = (stockTakeId, barcode, params) => {
  // please note in rare cases the barcode can contain spaces (encode to keep these)
  return put(
    `/stock-take/report/${stockTakeId}/products/${encodeURIComponent(barcode)}`,
    params
  )
}

export const updateSubStockTakeProduct = (
  stockTakeId,
  subStockTakeId,
  barcode,
  params
) => {
  // please note in rare cases the barcode can contain spaces (encode to keep these)
  return put(
    `/stock-take/report/${stockTakeId}/sub-report/${subStockTakeId}/products/${encodeURIComponent(
      barcode
    )}`,
    params
  )
}

export const deleteStockTakeProduct = (stockTakeId, barcode) => {
  // please note in rare cases the barcode can contain spaces (encode to keep these)
  return remove(
    `/stock-take/report/${stockTakeId}/products/${encodeURIComponent(barcode)}`
  )
}

export const deleteSubStockTakeProduct = (
  stockTakeId,
  subStockTakeId,
  barcode
) => {
  return remove(
    `/stock-take/report/${stockTakeId}/sub-report/${subStockTakeId}/products/${encodeURIComponent(
      barcode
    )}`
  )
}

export const completeStockTake = (stockTakeId) => {
  return post(`/stock-take/report/${stockTakeId}/complete`)
}

export const completeSubStockTake = (stockTakeId, subStockTakeId) => {
  return post(
    `/stock-take/report/${stockTakeId}/sub-report/${subStockTakeId}/complete`
  )
}

export const getStockTakeProducts = (stockTakeId, params) => {
  return get(`/stock-take/report/${stockTakeId}/products`, params)
}

export const getSubStockTakeProducts = (
  stockTakeId,
  subStockTakeId,
  params
) => {
  return get(
    `/stock-take/report/${stockTakeId}/sub-report/${subStockTakeId}/products`,
    params
  )
}

export const addStockTakeProducts = (stockTakeId, params) => {
  return post(`/stock-take/report/${stockTakeId}/products`, params)
}

export const addSubStockTakeProducts = (
  stockTakeId,
  subStockTakeId,
  params
) => {
  return post(
    `/stock-take/report/${stockTakeId}/sub-report/${subStockTakeId}/products`,
    params
  )
}

// Recipes

export const getRecipesFromMainReport = (stockTakeId, params) => {
  return get(`/stock-take/report/${stockTakeId}/recipes`, params)
}

export const getRecipesFromSubStockTakeReport = (
  stockTakeId,
  subStockTakeId,
  params
) => {
  return get(
    `/stock-take/report/${stockTakeId}/sub-report/${subStockTakeId}/recipes`,
    params
  )
}

export const addStockTakeRecipes = (stockTakeId, params) => {
  return post(`/stock-take/report/${stockTakeId}/recipes`, params)
}

export const addSubStockTakeRecipes = (stockTakeId, subStockTakeId, params) => {
  return post(
    `/stock-take/report/${stockTakeId}/sub-report/${subStockTakeId}/recipes`,
    params
  )
}

export const updateRecipeInMainReport = (stockTakeId, recipeId, params) => {
  return put(`/stock-take/report/${stockTakeId}/recipes/${recipeId}`, params)
}

export const updateRecipeInSubStockTakeReport = (
  stockTakeId,
  subStockTakeId,
  recipeId,
  params
) => {
  return put(
    `/stock-take/report/${stockTakeId}/sub-report/${subStockTakeId}/recipes/${recipeId}`,
    params
  )
}

export const deleteRecipeFromMainReport = (stockTakeId, recipeId) => {
  return remove(`/stock-take/report/${stockTakeId}/recipes/${recipeId}`)
}

export const deleteRecipeFromSubStockTakeReport = (
  stockTakeId,
  subStockTakeId,
  recipeId
) => {
  return remove(
    `/stock-take/report/${stockTakeId}/sub-report/${subStockTakeId}/recipes/${recipeId}`
  )
}

// OTHER

export const downloadStockTakeReport = async (
  stockTakeReportId,
  fileType,
  fileName,
  subStockTakePortId = null
) => {
  const subReport = subStockTakePortId
    ? `/sub-report/${subStockTakePortId}`
    : ""
  const url = `/stock-take/report/${stockTakeReportId}${subReport}/export-as-${fileType}?fileName=${fileName}`

  const response = await get(
    url,
    {},
    {
      download: true,
    }
  )
  if (response) {
    const result = await response.blob()
    saveAs(result, `${fileName}.${fileType}`)
  } else {
    return false
  }
}

export const createDiscrepancyReport = (
  stockTakeId,
  openingStockTakeReportId,
  salesId = null,
  params
) => {
  return post(
    `/stock-take/discrepancy-report?stockTakeReportId=${stockTakeId}&openingStockTakeReportId=${openingStockTakeReportId}${
      salesId ? "&salesId=" + salesId : ""
    }`,
    params.filterCategories
  )
}

export const getDiscrepancyReport = (discrepancyReportId, params) => {
  return get(`/stock-take/discrepancy-report/${discrepancyReportId}`, params)
}

export const updateDiscrepancyReport = (stockTakeId, params) => {
  return put(
    `/stock-take/discrepancy-report?stockTakeReportId=${stockTakeId}`,
    params
  )
}

export const updateDiscrepancyReportById = (
  discrepancyReportId,
  openingStockTakeReportId,
  salesId,
  params
) => {
  return put(
    `/stock-take/discrepancy-report/${discrepancyReportId}?openingStockTakeReportId=${openingStockTakeReportId}${
      salesId ? "&salesId=" + salesId : ""
    }`,
    params.filterCategories
  )
}

export const getDiscrepancyReportItems = (discrepancyReportId, params) => {
  return get(
    `/stock-take/discrepancy-report/${discrepancyReportId}/discrepancy-data`,
    params
  )
}

export const downloadDiscrepancyReport = async (
  discrepancyReportId,
  fileType,
  fileName
) => {
  const response = await get(
    `/stock-take/discrepancy-report/${discrepancyReportId}/export-as-${fileType}?fileName=${fileName}`,
    {},
    {
      download: true,
    }
  )
  if (response) {
    const result = await response.blob()
    saveAs(result, `${fileName}.${fileType}`)
  } else {
    return false
  }
}

// Stocktake sheets (templates)

export const getStockTakeSheets = (params) => {
  return get("/stock-take/report-templates", params)
}

export const createStockTakeSheet = (params) => {
  return post("/stock-take/report-templates", params)
}

export const getStockTakeSheet = (stockTakeSheetId) => {
  return get(`/stock-take/report-templates/${stockTakeSheetId}`)
}

export const updateStockTakeSheet = (stockTakeSheetId, params) => {
  return put(`/stock-take/report-templates/${stockTakeSheetId}`, params)
}

export const refreshStockTakeSheet = (stockTakeSheetId) => {
  return put(`/stock-take/report-templates/${stockTakeSheetId}/refresh`)
}

export const deleteStockTakeSheet = (stockTakeSheetId) => {
  return remove(`/stock-take/report-templates/${stockTakeSheetId}`)
}

export const exportStockTakeSheetsXlsx = async (stockTakeSheetId, fileName) => {
  const response = await get(
    `/stock-take/report-templates/${stockTakeSheetId}/export-as-xlsx?fileName=${fileName}`,
    {},
    {
      download: true,
    }
  )
  if (response) {
    const result = await response.blob()
    saveAs(result, `${fileName}.xlsx`)
  } else {
    return false
  }
}

// COGS

export const getCOGSReportsByCriteria = (params) => {
  return get("/stock-take/cogs-reports", params)
}

export const createCOGSReport = (params) => {
  const salesIdString = params.salesId ? `&salesId=${params.salesId}` : ""
  return post(
    `/stock-take/cogs-reports?closingStockTakeReportId=${params.closingStockTakeReportId}&openingStockTakeReportId=${params.openingStockTakeReportId}${salesIdString}`,
    params.filterCategories
  )
}

export const getCOGSReportById = (cogsReportId, params) => {
  return get(`/stock-take/cogs-reports/${cogsReportId}`, params)
}

export const updateCOGSReport = (params) => {
  const salesIdString = params.salesId ? `&salesId=${params.salesId}` : ""
  return put(
    `/stock-take/cogs-reports/${params.cogsReportId}?closingStockTakeReportId=${params.closingStockTakeReportId}&openingStockTakeReportId=${params.openingStockTakeReportId}${salesIdString}`,
    params.filterCategories
  )
}

export const deleteCOGSReport = (cogsReportId) => {
  return remove(`/stock-take/cogs-reports/${cogsReportId}`)
}

export const exportCOGSReport = async (
  cogsReportId,
  fileName,
  downloadFormat,
  params,
  subUrl = ""
) => {
  const response = await get(
    `/stock-take/cogs-reports/${cogsReportId}${subUrl}/export-as-${downloadFormat}?fileName=${fileName}`,
    params,
    {
      download: true,
    }
  )

  if (response) {
    const result = await response.blob()
    saveAs(result, `${fileName}.${downloadFormat}`)
  } else {
    return false
  }
}

export const getCOGSReportProducts = (cogsReportId, params) => {
  return get(`/stock-take/cogs-reports/${cogsReportId}/products`, params)
}

// BELOW MIN QTY
export const getAllStockBelowMinQtyReports = () => {
  return get("/stock-below-min-qty-reports")
}

export const createBelowMinQtyReport = () => {
  return post("/stock-below-min-qty-reports")
}

export const getStockBelowMinQtyReport = (stockBelowMinQtyReportId) => {
  return get(`/stock-below-min-qty-reports/${stockBelowMinQtyReportId}`)
}

export const getStockBelowMinQtyReportProducts = (
  stockBelowMinQtyReportId,
  params
) => {
  return get(
    `/stock-below-min-qty-reports/${stockBelowMinQtyReportId}/products`,
    params
  )
}

export const updateStockBelowMinQtyReport = (stockBelowMinQtyReportId) => {
  return put(`/stock-below-min-qty-reports/${stockBelowMinQtyReportId}`)
}

export const sendEmailForStockBelowMinQty = (
  stockBelowMinQtyReportId,
  username
) => {
  return post(
    `/stock-below-min-qty-reports/${stockBelowMinQtyReportId}/email-me?username=${username}`
  )
}

export const subStockTakeHelper = (state, subStockTakeId) => {
  const subStockTakeIndex = state.newStockTake.subStockTakeReports.findIndex(
    (s) => s.id == subStockTakeId
  )
  return subStockTakeId && subStockTakeIndex !== -1
    ? `.subStockTakeReports.${subStockTakeIndex}`
    : ""
}
