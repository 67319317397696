/* eslint-disable */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState, useCallback, useContext, useEffect } from "react"
import {
  faReply,
  faArrowLeft,
  faPlus,
  faTrashAlt,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons"
import classNames from "classnames/bind"
import UpdateItem from "components/delivery-notes/UpdateItem/UpdateItem"
import AddComment from "components/delivery-notes/AddComment/AddComment"
import EditDetails from "components/delivery-notes/EditDetails/EditDetails"
import RejectNote from "components/delivery-notes/RejectNote/RejectNote"
import OptionsPopup from "components/common/OptionsPopup/OptionsPopup"
import Loader from "components/common/Loader/Loader"
import { useMediaQuery } from "react-responsive"
import ConfirmDetailsHeader from "components/delivery-notes/ConfirmDetailsHeader/ConfirmDetailsHeader"
import ConfirmDetailsItem from "components/delivery-notes/ConfirmDetailsItem/ConfirmDetailsItem"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import SupplierSelect from "components/suppliers/SupplierSelect/SupplierSelect"
import OrderSelect from "components/orders/OrderSelect/OrdersSelect"
import NewProductSelect from "components/common/NewProductSelect/NewProductSelect"
import { showError, showSuccess } from "services/toast"
import { uuidv4 } from "services/helpers"
import SearchInput from "components/forms/SearchInput"
import EditProduct from "components/suppliers/EditProduct/EditProduct"

import {
  addDeliveryNote,
  updateDeliveryNote,
  rejectDeliveryNote,
  approveDeliveryNote,
  querySupplier,
} from "services/delivery-notes"
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "context/GlobalContextProvider"
import { ModalContext } from "context/ModalContext"
import { AsideContext } from "context/AsideContext"
import { isDate } from "date-fns"
import ConfirmDetailsMessages from "components/delivery-notes/ConfirmDetailsMessages/ConfirmDetailsMessages"
import PropTypes from "prop-types"
import QueryNote from "../QueryNote/QueryNote"
import FileList from "components/common/FileList/FileList"
import { updateDeliveryNoteFiles } from "services/delivery-notes"

//@ts-ignore
import * as styles from "./ConfirmDetails.module.css"
import ConfirmQtyModal from "components/common/ConfirmQtyModal/ConfirmQtyModal"
import usePermissions from "hooks/usePermissions"
import { Permission } from "services/types"
import { useFileOwner } from "hooks/useFileOwner"

const cx = classNames.bind(styles)

const ConfirmDetails = ({
  onCancel,
  onSave,
  onConfirm,
  onReject,
  onQuery,
  onLoadingChange,
  onFilesChange,
  shouldShowCalendarImmediately = false,
}) => {
  const modal = useContext(ModalContext)
  const aside = useContext(AsideContext)

  const dispatch = useContext(GlobalDispatchContext)
  const { organization, organizationPermissions, newDeliveryNote } =
    useContext(GlobalStateContext)
  const permissionObj = usePermissions("Delivery notes") as Permission

  const [itemList, setItemList] = useState(newDeliveryNote.products)
  const [initialList, setInitialList] = useState([])
  const [q, setQ] = useState("")
  const [editItem, setEditItem] = useState(false)
  const [selectOrder, setSelectOrder] = useState(false)
  const [commentItem, setCommentItem] = useState(false)
  const [editNumber, setEditNumber] = useState(false)
  const [rejectNote, setRejectNote] = useState(false)
  const [queryNote, setQueryNote] = useState(false)
  const [editItemIndex, setEditItemIndex] = useState(null)
  const [showOptions, setShowOptions] = useState(false)
  const [popupTitle, setPopupTitle] = useState("")
  const [saving, setSaving] = useState(false)
  const [productSelectOpen, setProductSelectOpen] = useState(false)
  const [selectedSupplier, setSelectedSupplier] = useState(null)
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
  const [fileLoading, setFileLoading] = useState(false)
  const [activeTab, setActiveTab] = useState("items")
  const filesOwner = useFileOwner(newDeliveryNote)

  //
  // Callbacks
  //

  const remove = (index) => {
    let newProducts = newDeliveryNote.products
    newProducts.splice(index, 1)
    const params = {
      ...newDeliveryNote,
      products: newProducts,
    }
    saveDeliveryNote(params)
  }

  const onRemove = (index) => {
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: "Remove DN item",
      text: "Are you sure you want to remove this item from the list?",
      confirmButtonText: "Remove",
      onConfirm: () => {
        remove(index)
      },
    })
  }

  const selectEditItem = (item, index) => {
    setEditItem(item)
    setEditItemIndex(index)
    setPopupTitle(
      item ? (
        <div
          className={cx("flex w-full", {
            "justify-between": !item.orderQty,
            "justify-center": item.orderQty,
          })}
        >
          <span>{item.name}</span>
          {!item.orderQty && index !== null && (
            <button
              className="text-primaryPink whitespace-nowrap"
              onClick={() => onRemove(index)}
              disabled={!permissionObj?.permissions.modify}
            >
              <FontAwesomeIcon className="mr-2" icon={faTrashAlt} />
              Remove
            </button>
          )}
        </div>
      ) : (
        ""
      )
    )
    setShowOptions(true)
  }

  const resetEditItem = () => {
    setShowOptions(false)
    // Wait for animation to be done
    setTimeout(() => {
      setPopupTitle("")
      setEditItem(false)
      setEditNumber(false)
      setCommentItem(false)
      setRejectNote(false)
    }, 300)
  }

  const selectCommentItem = (item, index) => {
    setCommentItem(item)
    setEditItemIndex(index)
    setPopupTitle(item ? `Add note for ${item.name}` : "")
    setShowOptions(true)
  }

  const _updateProductQuantities = (quantities) => {
    const newProducts = initialList.map((item) => {
      if (editItem.uuid === item.uuid) {
        return {
          ...item,
          ...quantities,
          price:
            item.receivedQtyInCase && !quantities.receivedQtyInCase
              ? item.price / item.orderCaseSize
              : item.price,
          isConfirmed: true,
        }
      }
      return item
    })
    return newProducts
  }

  const _updateProductComments = (comment) => {
    const newProducts = newDeliveryNote.products.map((item, index) => {
      if (editItemIndex === index) {
        return {
          ...item,
          comment,
        }
      }
      return item
    })
    return newProducts
  }

  const approveDN = async () => {
    setSaving(true)
    const result = await approveDeliveryNote(newDeliveryNote.id)

    if (result && (result.status === 500 || result.status === 400)) {
      showError(result.message || "Something went wrong")
      setSaving(false)
      return
    }
    if (result) {
      dispatch({
        type: "UPDATE_NEW_DELIVERY_NOTE",
        payload: {
          status: "APPROVED",
        },
      })
      setSaving(false)
      return onConfirm()
    }
  }

  const rejectDN = async ({ message, notifySupplier, completeOrder }) => {
    const params = {
      message,
      notifySupplier,
      completeOrder,
    }
    setSaving(true)
    const result = await rejectDeliveryNote(newDeliveryNote.id, params)
    setSaving(false)
    if (result && (result.error || result.status === 400)) {
      return showError(result.message || "Could not reject this note")
    }
    dispatch({
      type: "UPDATE_NEW_DELIVERY_NOTE",
      payload: {
        status: "REJECTED",
      },
    })
    return onReject()
  }

  const saveDeliveryNote = async (params) => {
    setSaving(true)
    let reqParams = params
    if (reqParams.dataUri) {
      delete reqParams.dataUri
    }

    const updateResult = newDeliveryNote.id
      ? await updateDeliveryNote(newDeliveryNote.id, reqParams)
      : await addDeliveryNote(reqParams)

    if (
      updateResult &&
      updateResult.status !== 400 &&
      updateResult.status !== 403 &&
      updateResult.status !== 404
    ) {
      setSaving(false)
      // Update quantities here

      const {
        products,
        isCreatedManuallyWithoutOrder,
        deliveryNoteNumber,
        dateOfScanning,
        deliveryDate,
        globalDiscrepancies,
        hasNoDeliveredProducts,
        hasNoOrderedProducts,
        hasReceivedQtyDiscrepancies,
        hasDNQtyDiscrepancies,
        fileId,
        hasReceivedOrderQtyDiscrepancies,
        id,
        po,
        supplier,
      } = updateResult

      dispatch({
        type: "UPDATE_NEW_DELIVERY_NOTE",
        payload: {
          products,
          isCreatedManuallyWithoutOrder,
          deliveryNoteNumber,
          dateOfScanning,
          deliveryDate,
          globalDiscrepancies,
          hasNoDeliveredProducts,
          hasNoOrderedProducts,
          fileId,
          hasReceivedQtyDiscrepancies,
          hasDNQtyDiscrepancies,
          hasReceivedOrderQtyDiscrepancies,
          id,
          po,
          supplier,
        },
      })
      resetEditItem()
      // showSuccess("Delivery note saved!") // removed because was called too much
      if (onSave) {
        onSave()
      }
      return true
    } else if (updateResult.status === 400 || updateResult.status === 404) {
      if (updateResult.errors && updateResult.errors.length > 0) {
        showError(
          updateResult.errors
            ? updateResult.errors[0].defaultMessage
            : "Saving failed"
        )
      } else {
        showError(updateResult.message || "Saving failed")
      }
      setSaving(false)
      return false
    } else if (updateResult.status === 403) {
      // Message comes from api.js - forbidden
      setSaving(false)
      return false
    }
  }

  const updateQuantity = async (quantities) => {
    const params = {
      ...newDeliveryNote,
      products:
        editItemIndex !== null
          ? _updateProductQuantities(quantities)
          : [
              ...newDeliveryNote.products,
              { ...editItem, ...quantities, isConfirmed: true },
            ],
    }
    saveDeliveryNote(params)
  }

  const confirmItem = (productData, isConfirmed) => {
    const updatedProductsList = newDeliveryNote.products.map((product) => {
      if (product.name === productData.name) {
        return {
          ...product,
          isConfirmed,
        }
      }

      return product
    })

    const params = {
      ...newDeliveryNote,
      products: updatedProductsList,
    }

    saveDeliveryNote(params)
  }

  const updateComment = async (comment) => {
    const params = {
      ...newDeliveryNote,
      products: _updateProductComments(comment),
    }
    saveDeliveryNote(params)
  }

  const updateDetails = async ({ number, date }) => {
    const params = {
      ...newDeliveryNote,
      deliveryDate: isDate(date) ? date.toISOString() : date,
      deliveryNoteNumber: number,
    }
    saveDeliveryNote(params)
  }

  const handleSupplierSelect = (supplier) => {
    setSelectedSupplier(supplier)
  }

  const handleOrderSelect = async (order) => {
    let params = {
      ...newDeliveryNote,
      status: "DRAFT",
      po: order.po,
    }

    if (!newDeliveryNote.supplier) {
      params = {
        ...params,
        supplier: {
          id: order.supplier.id,
        },
      }
    }

    if (!newDeliveryNote.products.length) {
      params = {
        ...params,
        products: order.items
          ? order.items.map((item) => {
              return {
                ...item,
                name: item.name,
                price: item.orderInCase ? item.productCase.price : item.price,
                orderQty: item.quantity,
                dnQty: item.quantity,
                receivedQtyInCase: item.orderInCase,
                receivedQty: item.quantity,
              }
            })
          : [],
      }
    }

    const saved = await saveDeliveryNote(params)
    if (saved) {
      setSelectOrder(false)
    }
  }

  const confirmSupplier = async () => {
    setSaving(true)
    let params = {
      ...newDeliveryNote,
      status: "DRAFT",
      supplier: selectedSupplier,
    }
    if (params.dataUri) {
      delete params.dataUri
    }

    if (params.selectedFiles) {
      delete params.selectedFiles
    }

    dispatch({
      type: "UPDATE_NEW_DELIVERY_NOTE",
      payload: params,
    })

    setSaving(false)
  }

  const notifySupplier = async ({ message }) => {
    let formData = new FormData()

    formData.append("message", message)

    setSaving(true)
    const queryResult = await querySupplier(newDeliveryNote.id, formData)
    setSaving(false)
    if (queryResult.status !== 400) {
      dispatch({
        type: "UPDATE_NEW_DELIVERY_NOTE",
        payload: {
          status: "IN_QUERY",
        },
      })
      showSuccess("Notification sent!")
      return onQuery()
    } else {
      showError("There are no discrepancies.")
    }
  }

  const hasDiscrepancies = () => {
    return (
      (!newDeliveryNote.isCreatedManuallyWithoutOrder &&
        newDeliveryNote.hasReceivedOrderQtyDiscrepancies) ||
      (newDeliveryNote.isCreatedManuallyWithoutOrder &&
        newDeliveryNote.hasReceivedQtyDiscrepancies) ||
      newDeliveryNote.hasNoOrderedProducts ||
      newDeliveryNote.hasNoDeliveredProducts
    )
  }

  const _getFilteredItemsBySearch = (list, input) => {
    return list.filter((product) => {
      return product.name.toLowerCase().includes(input.toLowerCase())
    })
  }

  const handleSearch = (input) => {
    setQ(input)

    if (input == "" || !input) {
      setItemList(initialList)
      return
    }

    const filtered = _getFilteredItemsBySearch(initialList, input)
    setItemList(filtered)
  }

  /**
   * For adding a product manually
   *
   * @param   {Object}  product
   *
   * @return  {Function}
   */
  const handleProductSelect = (selectedProducts) => {
    const newProducts = selectedProducts.map((p) => {
      return {
        ...p,
        uuid: uuidv4(),
        dnQty: p.quantities.dn,
        isConfirmed: true,
        orderCaseSize: p.packaging === "multiple" ? p.productCase.size : null,
        orderQty: null,
        price: p.packaging === "multiple" ? p.productCase.price : p.price,
        receivedQty: p.quantities.received,
        receivedQtyInCase: p.packaging === "multiple" ? true : false,
      }
    })
    const params = {
      ...newDeliveryNote,
      products: [
        ...newDeliveryNote.products,
        // Add new products but filter out duplicates (if already in DN)
        ...newProducts.filter(
          (p) =>
            newDeliveryNote.products.map((pr) => pr.name).indexOf(p.name) === -1
        ),
      ],
    }
    saveDeliveryNote(params)
    setProductSelectOpen(false)
  }

  const onAddNewProductToDeliveryNote = (data) => {
    modal.showModal(ConfirmQtyModal, {
      title: "Add item",
      text: "Do you want to add this item to this delivery?",
      confirmButtonText: "Add",
      packagingMenu: { enabled: data.productCase != undefined },
      qtyInputTypes: [
        {
          label: "DN Qty",
          shortLabel: "DN",
          quantityKey: "dn",
          syncQuantityKey: "received",
        },
        {
          label: "Received",
          shortLabel: "R",
          quantityKey: "received",
        },
      ],
      onConfirm: (quantities, packaging) => {
        const formattedProd = [
          {
            ...data,
            quantities,
            packaging,
          },
        ]
        handleProductSelect(formattedProd)
      },
    })
  }

  const onAddNewProduct = () => {
    aside.showAside(EditProduct, {
      headerText: "Add new product",
      supplierData: newDeliveryNote.supplier,
      createProduct: true,
      onSubmitCallback: (data) => onAddNewProductToDeliveryNote(data),
    })
  }

  const notifySupplierConfirm = () => {
    setQueryNote(true)
    setPopupTitle(`Notify supplier`)
    setShowOptions(true)
  }

  const totalFiles =
    (newDeliveryNote.extractedFile ? 1 : 0) +
    (newDeliveryNote.files ? newDeliveryNote.files.length : 0)

  useEffect(() => {
    const products = newDeliveryNote.products.map((p, index) => {
      return {
        ...p,
        uuid: uuidv4(),
        originalIndex: index,
      }
    })

    setInitialList(products)

    if (q) {
      const filteredProducts = _getFilteredItemsBySearch(products, q)
      setItemList(filteredProducts)
    } else {
      setItemList(products)
    }
  }, [newDeliveryNote.products])

  useEffect(() => {
    if (newDeliveryNote.fileId) {
      getFilename()
    }
  }, [newDeliveryNote.fileId])

  useEffect(() => {
    //Pop calendar out immediately if prop allows
    if (shouldShowCalendarImmediately === true) {
      setEditNumber(true)
      setPopupTitle("Add delivery date")
      setShowOptions(true)
    }
  }, [])

  const handleFilesUploaded = async (newFiles) => {
    setFileLoading(true)
    const filesToUpload = [...(newDeliveryNote.files || []), ...newFiles]

    const updateDnFiles = await updateDeliveryNoteFiles(newDeliveryNote.id, {
      files: filesToUpload,
    })

    if (updateDnFiles.files && updateDnFiles.files.length) {
      dispatch({
        type: "UPDATE_NEW_DELIVERY_NOTE",
        payload: { files: updateDnFiles.files },
      })
      onFilesChange()
    }
    setFileLoading(false)
  }

  const handleFilesDelete = async (file) => {
    setFileLoading(true)

    const newFileList = [
      ...newDeliveryNote.files.filter((f) => f.fileId !== file.fileId),
    ]

    const updateDnFiles = await updateDeliveryNoteFiles(newDeliveryNote.id, {
      files: newFileList,
    })

    dispatch({
      type: "UPDATE_NEW_DELIVERY_NOTE",
      payload: { files: updateDnFiles.files },
    })
    onFilesChange()
    setFileLoading(false)
  }

  const renderContent = () => {
    let content = (
      <div className={styles.items}>
        <Loader isLoading={saving} />

        {itemList.length > 0 || newDeliveryNote.id ? (
          <div className="flex-grow overflow-auto pb-6">
            <div className="px-4 lg:px-6">
              <div className="flex items-center">
                <ul className={styles.tabs}>
                  <li className={activeTab === "items" ? styles.activeTab : ""}>
                    <button type="button" onClick={() => setActiveTab("items")}>
                      Items ({itemList.length})
                    </button>
                  </li>
                  <li className={activeTab === "files" ? styles.activeTab : ""}>
                    <button type="button" onClick={() => setActiveTab("files")}>
                      Files ({totalFiles})
                    </button>
                  </li>
                </ul>

                <div className="flex flex-1 justify-between items-center">
                  {activeTab === "items" && (
                    <div className="flex flex-1">
                      <button
                        className="button ml-auto button--autoWidth button--primaryGreen"
                        onClick={(e) => {
                          e.preventDefault()
                          setProductSelectOpen(true)
                        }}
                        disabled={!permissionObj?.permissions.modify}
                      >
                        <FontAwesomeIcon icon={faPlus} className="lg:mr-2" />
                        <span className="hidden lg:inline">Add item</span>
                      </button>
                    </div>
                  )}
                </div>
              </div>

              {activeTab === "items" && (
                <>
                  {newDeliveryNote.supplier && !selectOrder && (
                    <ConfirmDetailsMessages note={newDeliveryNote} />
                  )}
                  <SearchInput
                    className="rounded-t"
                    placeholder="Search on item name.."
                    onSearchChange={handleSearch}
                  />
                </>
              )}
            </div>

            {activeTab === "items" && (
              <ul className="px-2 lg:px-6">
                {itemList.length === 0 ? (
                  <span className="block p-4 text-center text-lg">
                    No items found...
                  </span>
                ) : (
                  itemList.length > 0 &&
                  itemList.map((product, index) => {
                    return (
                      <React.Fragment key={`${product.uuid}-${index}`}>
                        <ConfirmDetailsItem
                          product={product}
                          onEdit={(product) =>
                            selectEditItem(
                              product,
                              product.originalIndex || index
                            )
                          }
                          onComment={(product) =>
                            selectCommentItem(
                              product,
                              product.originalIndex || index
                            )
                          }
                          onConfirm={(confirmed) => {
                            confirmItem(product, confirmed)
                          }}
                          isManualFlow={
                            newDeliveryNote.isCreatedManuallyWithoutOrder
                          }
                          supplier={newDeliveryNote.supplier}
                        />
                      </React.Fragment>
                    )
                  })
                )}
              </ul>
            )}

            {activeTab === "files" && (
              <div className="mx-4">
                <FileList
                  clientKey={"delivery-note"}
                  extractedFile={newDeliveryNote.extractedFile}
                  files={newDeliveryNote.files}
                  filesOwner={filesOwner}
                  onUploaded={handleFilesUploaded}
                  disabled={!permissionObj?.permissions.modify}
                  onDeleted={handleFilesDelete}
                  isLoading={fileLoading}
                  modifyPermission={
                    newDeliveryNote.organizations?.length > 0 &&
                    newDeliveryNote.organizations[0] === organization?.id
                  }
                />
              </div>
            )}
          </div>
        ) : (
          <div className="flex-grow overflow-auto pb-6">
            <div className="">
              {/* px-4 lg:px-4 py-6 - div classes
              <h3 className="text-primaryBlue text-xl mb-2 text-center">
                New delivery note
              </h3>
              <p className="text-gray-700 text-center mb-4">
                You have no items yet, add them or create from order
              </p> */}
              <div className="h-full flex-grow overflow-hidden flex flex-col bg-white">
                <div className="border-b px-4 lg:px-6 flex items-center flex-shrink-0">
                  <button
                    className="text-primaryBlue mr-4"
                    onClick={() => onCancel()}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </button>
                  <h2 className="py-4 text-xl text-primaryBlue">
                    Search order
                  </h2>
                </div>
                <div className="flex-grow overflow-hidden flex flex-col relative">
                  <Loader isLoading={saving} />
                  <OrderSelect
                    selectedSupplier={newDeliveryNote.supplier}
                    onSelect={handleOrderSelect}
                  />
                </div>
              </div>

              {/* <div className="flex flex-col">
                <button
                  className="bg-transparent p-4 font-sansSemiBold font-semibold rounded-t border hover:bg-primaryBlueLighter hover:bg-opacity-10 transition-colors border-gray-400 text-primaryBlueLighter hover:text-primaryBlue text-lg"
                  onClick={(e) => {
                    e.preventDefault()
                    // Set add manual flag here
                    dispatch({
                      type: "RESET_NEW_DELIVERY_NOTE",
                      options: { resetSupplier: false },
                    })
                    dispatch({
                      type: "UPDATE_NEW_DELIVERY_NOTE",
                      payload: { isCreatedManuallyWithoutOrder: true },
                    })
                    setProductSelectOpen(true)
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} className="mr-2" />
                  Add w/o order
                </button>
                <button
                  onClick={() => setSelectOrder(true)}
                  className="bg-transparent p-4 -mt-px font-sansSemiBold font-semibold rounded-b border hover:bg-primaryBlueLighter hover:bg-opacity-10 transition-colors border-gray-400 text-primaryBlueLighter hover:text-primaryBlue text-lg"
                >
                  <FontAwesomeIcon icon={faShoppingCart} className="mr-2" />
                  Create from order
                </button>
              </div> */}
            </div>
          </div>
        )}

        {/* Footer */}
        <footer className="flex items-center flex-shrink-0 w-full bg-white px-4 lg:px-6 py-4 border-t">
          <button className="mr-auto hidden sm:block" onClick={onCancel}>
            Close
          </button>

          {hasDiscrepancies() &&
            newDeliveryNote.status !== "IN_QUERY" &&
            itemList.length > 0 && (
              <button
                className="button button--autoWidth button--paleBlue no-truncate"
                onClick={notifySupplierConfirm}
                disabled={saving || !permissionObj?.permissions.modify}
              >
                <FontAwesomeIcon icon={faReply} className="mr-2" />
                <span className="inline sm:hidden">Notify</span>
                <span className="hidden sm:inline">Notify supplier</span>
              </button>
            )}
          {newDeliveryNote.status !== "REJECTED" && itemList.length > 0 && (
            <button
              onClick={() => {
                setRejectNote(true)
                setPopupTitle(
                  `Are you sure you’d like to reject this delivery?`
                )
                setShowOptions(true)
              }}
              className="button button--autoWidth button--primaryPink ml-2 lg:ml-4 no-truncate"
              disabled={saving || !permissionObj?.permissions.modify}
            >
              Reject {isTabletOrMobile ? "" : "delivery"}
            </button>
          )}
          {newDeliveryNote.status !== "APPROVED" && itemList.length > 0 && (
            <button
              className="button button--autoWidth button--primaryGreen ml-2 lg:ml-4 no-truncate"
              onClick={approveDN}
              disabled={saving || !permissionObj?.permissions.modify}
            >
              Approve {isTabletOrMobile ? "" : "delivery"}
            </button>
          )}
        </footer>
      </div>
    )

    if (!newDeliveryNote.supplier && !selectOrder) {
      content = (
        <div className="h-full overflow-hidden flex flex-col bg-white">
          <div className="flex items-center justify-between">
            <h2 className="px-4 lg:px-6 py-4 text-xl text-primaryBlue">
              Select your supplier
            </h2>
            <button
              className="text-gray-700 absolute right-0 py-4 px-6"
              onClick={onCancel}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="flex-grow px-4 lg:px-6 overflow-auto relative">
            <Loader isLoading={saving} />
            <SupplierSelect onSelect={handleSupplierSelect} />
          </div>
          <div className="p-4 border-t">
            <button
              disabled={
                saving ||
                !selectedSupplier ||
                !permissionObj?.permissions.modify
              }
              onClick={() => confirmSupplier()}
              className="button button--primaryBlue"
            >
              Confirm
            </button>
          </div>
        </div>
      )
    }

    // content = (
    //   <div className="h-full flex-grow overflow-hidden flex flex-col bg-white">
    //     <div className="border-b px-4 lg:px-6 flex items-center flex-shrink-0">
    //       <button className="text-primaryBlue mr-4" onClick={() => onCancel()}>
    //         <FontAwesomeIcon icon={faArrowLeft} />
    //       </button>
    //       <h2 className="py-4 text-xl text-primaryBlue">Search order</h2>
    //     </div>
    //     <div className="flex-grow overflow-hidden flex flex-col relative">
    //       <Loader isLoading={saving} />
    //       <OrderSelect
    //         selectedSupplier={newDeliveryNote.supplier}
    //         onSelect={handleOrderSelect}
    //       />
    //     </div>
    //   </div>
    // )

    return content
  }

  useCallback(() => {
    onLoadingChange(saving)
  }, [saving, onLoadingChange])

  //
  // Render
  //

  return (
    <>
      <div className={styles.container}>
        <ConfirmDetailsHeader
          note={newDeliveryNote}
          searchOrderActive={selectOrder}
          onClose={onCancel}
          onEdit={() => {
            setEditNumber(true)
            setPopupTitle("Edit details")
            setShowOptions(true)
          }}
          onSearchOrderAction={() => setSelectOrder(true)}
          addManually={() => setProductSelectOpen(true)}
        />

        <div className="flex-grow flex overflow-hidden flex-col">
          {renderContent()}
        </div>
      </div>

      <OptionsPopup
        active={showOptions}
        title={popupTitle}
        //Wont close the modal if shouldShowCalendarImmediately is TRUE
        activeCallback={
          shouldShowCalendarImmediately ? () => {} : resetEditItem
        }
      >
        {editItem && (
          <UpdateItem
            item={editItem}
            isSaving={saving}
            onSave={updateQuantity}
          />
        )}
        {commentItem && (
          <AddComment
            item={commentItem}
            isSaving={saving}
            onSave={updateComment}
          />
        )}
        {rejectNote && (
          <RejectNote
            supplier={newDeliveryNote.supplier}
            hasOrder={newDeliveryNote.po}
            isSaving={saving}
            onSave={rejectDN}
          />
        )}
        {queryNote && (
          <QueryNote
            supplier={newDeliveryNote.supplier}
            isSaving={saving}
            onSave={notifySupplier}
          />
        )}
        {editNumber && (
          <EditDetails
            note={newDeliveryNote}
            isSaving={saving}
            onSave={updateDetails}
          />
        )}
      </OptionsPopup>

      <div
        className={cx("productSelectContainer", { open: productSelectOpen })}
      >
        <div className={styles.productSelect}>
          <div className="h-full overflow-hidden flex flex-col bg-white">
            {/* <div className="px-6 pt-4 pb-0 flex items-center">
              <button
                className="text-primaryBlue mr-4"
                onClick={() => setProductSelectOpen(false)}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              <h2 className="text-xl text-primaryBlue">Search a product</h2>
            </div> */}
            <div className="flex-grow flex flex-col overflow-auto relative">
              <NewProductSelect
                negativeQuantitiesAllowed={true}
                onSelect={handleProductSelect}
                onClose={() => setProductSelectOpen(false)}
                selectedSupplier={newDeliveryNote.supplier}
                multiSelect
                searchBySKU
                searchBySupplier
                searchByCategories
                searchByPackaging
                deliveryNote
                qtyInputTypes={[
                  {
                    label: "DN Qty",
                    shortLabel: "DN",
                    quantityKey: "dn",
                    syncQuantityKey: "received",
                  },
                  {
                    label: "Received",
                    shortLabel: "R",
                    quantityKey: "received",
                  },
                ]}
                enablePriceEdit={true}
                priceLabel="DN Price"
                createNewProduct={onAddNewProduct}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

ConfirmDetails.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  onConfirm: PropTypes.func,
  onReject: PropTypes.func,
  onQuery: PropTypes.func,
  onLoadingChange: PropTypes.func,
  onFilesChange: PropTypes.func,
}

export default ConfirmDetails
