import React, { useContext, useState, useEffect } from "react"
import { GlobalStateContext } from "context/GlobalContextProvider"
import { GlobalDispatchContext } from "context/GlobalContextProvider"
import PropTypes from "prop-types"
import Modal from "react-modal"

import { showError, showSuccess } from "services/toast"
import {
  countCompletedSteps,
  calculateProgress,
  uuidv4,
} from "services/helpers"
import { updateOnboardingSteps } from "services/onboarding"
import { getStartedSteps, getNextSteps } from "services/constants"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons"

import Loader from "components/common/Loader/Loader"
import ProgressBar from "components/common/ProgressBar/ProgressBar"
import GetStartedStep from "../GetStartedStep/GetStartedStep"

import * as styles from "./GetStartedModal.module.css"
import { navigate } from "gatsby"

const GetStartedModal = ({
  title,
  description,
  onRequestClose,
  svgIcon,
  withNotification,
  isFirstSteps,
  ...otherProps
}) => {
  const dispatch = useContext(GlobalDispatchContext)
  const [isLoading, setLoading] = useState(false)
  const [steps, setSteps] = useState(null)
  const { onboardingSteps } = useContext(GlobalStateContext)

  const onCompleteStep = async (step, checked) => {
    setLoading(true)

    const params = {
      ...onboardingSteps,
      [step]: !checked,
    }

    const updated = await updateOnboardingSteps(params)

    if (updated && !updated.message) {
      dispatch({
        type: "UPDATE_ONBOARDING_STEPS",
        payload: { onboardingSteps: updated },
      })

      setLoading(false)
      if (checked === false) {
        showSuccess("Finished onboarding step")
      }

      if (step === "hasCheckedCorrectPricesAndBarcodes" && !checked) {
        onRequestClose()
        navigate("/dashboard/products/items")
      }
    } else {
      setLoading(false)
      showError(updated.message)
    }
  }

  const getSteps = () => {
    if (isFirstSteps) {
      const firstOnboardingSteps = getStartedSteps.map((step) => {
        return {
          ...step,
          checked: onboardingSteps && onboardingSteps[step.name],
        }
      })
      setSteps(firstOnboardingSteps)
    } else {
      const secondOnboardingSteps = getNextSteps.map((step) => {
        return {
          ...step,
          checked: onboardingSteps && onboardingSteps[step.name],
        }
      })
      setSteps(secondOnboardingSteps)
    }
  }

  useEffect(() => {
    getSteps()
  }, [])

  useEffect(() => {
    getSteps()
  }, [onboardingSteps])

  return (
    <Modal
      isOpen
      style={{ content: { bottom: "40px", border: 0, overflow: "hidden" } }}
      onRequestClose={onRequestClose}
      {...otherProps}
    >
      <Loader
        isLoading={isLoading}
        style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
      />
      <header className={styles.header}>
        {svgIcon}
        <div className="my-2 pr-4">
          <h3 className="text-lg mb-1">{title}</h3>
          <p className="text-sm leading-normal text-gray-700">{description}</p>
        </div>
        <div className="ml-auto">
          <span className="font-sansBold text-primaryPink text-lg mr-3">
            {steps && countCompletedSteps(steps)}
          </span>
        </div>
        <div className={styles.progressBar}>
          {steps && <ProgressBar progress={calculateProgress(steps)} />}
        </div>
      </header>
      <div className={styles.content}>
        <div className="flex flex-col w-full">
          {steps &&
            steps.map((step) => {
              return (
                <GetStartedStep
                  step={step}
                  closeModal={onRequestClose}
                  onToggle={onCompleteStep}
                  key={uuidv4()}
                />
              )
            })}
        </div>
        {withNotification && (
          <div className={styles.notification}>
            <div className="flex flex-col">
              <span className="font-sansBold">Need help getting set up?</span>
              <span className="text-sm">
                Book a demo to see how we can best help.
              </span>
            </div>
            <a
              href="https://www.growyze.com/book-a-demo"
              target="_blank"
              type="button"
              rel="noreferrer"
              className="button button--autoWidth w-auto bg-primaryPink mt-2 md:mt-0 pb-1 flex items-center justify-center"
              style={{ display: "flex" }}
            >
              Book a demo{" "}
              <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
            </a>
          </div>
        )}
      </div>
      <footer className={styles.footer}>
        <span
          className="font-sansBold text-gray-600 cursor-pointer"
          onClick={onRequestClose}
        >
          Close
        </span>
      </footer>
    </Modal>
  )
}

GetStartedModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  checkedSteps: PropTypes.object,
  svgIcon: PropTypes.element,
  withNotification: PropTypes.bool,
  isFirstSteps: PropTypes.bool,
  onRequestClose: PropTypes.func,
}

export default GetStartedModal
