import React, { useEffect, useState } from "react"
import TabTitle from "./TabTitle"
import TabContent from "./TabContent"

type Props = {
  children: any
  activeTab?: string
  onTabChange?(tabKey: string): void
  hideTabContent?: boolean
  hideTabs?: Array<string>
  [key: string]: any
}

const Tabs: React.FC<Props> = ({
  activeTab,
  children,
  onTabChange,
  hideTabContent = false,
  hideTabs = [],
  ...props
}) => {
  const [selectedTab, setSelectedTab] = useState<string>(
    activeTab ? activeTab : children[0].props.tabKey
  )

  const handleTabChange = (tabKey: string) => {
    if (selectedTab !== tabKey) {
      setSelectedTab(tabKey)
      // Check if callback is defined
      if (onTabChange) {
        onTabChange(tabKey)
      }
    }
  }

  const filterNotAllowed = (setChildren) => {
    return (
      setChildren
        // Main isAllowed prop has preference over the roles filters
        .filter((item) =>
          item.props.isAllowed !== undefined ? item.props.isAllowed : true
        )
        .filter((item) => (hideTabs.includes(item.props.tabKey) ? false : true))
    )
  }

  useEffect(() => {
    setSelectedTab(activeTab)
  }, [activeTab])

  const allowedChildren = filterNotAllowed(children)

  const showActiveTabs = () => {
    return allowedChildren
      .filter((c) => hideTabContent || c.props.tabKey === selectedTab)
      .map((c, i) => {
        const { ...rest } = c.props
        if (hideTabContent) {
          rest.className = rest?.className ?? ""
          rest.className += c.props.tabKey === selectedTab ? "" : " hidden"
        }
        return (
          <TabContent key={i} {...rest}>
            {c}
          </TabContent>
        )
      })
  }

  return (
    <>
      <div className={props.className ?? "growyze-tabs"} {...props}>
        <ul>
          {allowedChildren.map((item, index) => (
            <TabTitle
              key={index}
              title={item.props.title}
              isActive={selectedTab === item.props.tabKey}
              tabKey={item.props.tabKey}
              handleTabChange={handleTabChange}
              showWarning={item.props.showWarning}
            />
          ))}
        </ul>
      </div>
      {showActiveTabs()}
    </>
  )
}

export default Tabs
