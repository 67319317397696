import React, { useState, useContext, useCallback, useEffect } from "react"
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "context/GlobalContextProvider"
import { ModalContext } from "context/ModalContext"
import { AsideContext } from "context/AsideContext"
import Modal from "react-modal"
import { showError } from "services/toast"
import {
  createStockTransfer,
  updateStockTransfer,
  receiveStockTransfer,
  cancelStockTransfer,
  revertStockTransferToSent,
  updateStockTransferItem,
} from "services/stock-transfer"
import OptionsPopup from "components/common/OptionsPopup/OptionsPopup"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import StockTransferHeader from "./StockTransferHeader/StockTransferHeader"
import StockTransferContent from "./StockTransferContent/StockTransferContent"
import StockTransferComments from "./StockTransferComments/StockTransferComments"
import StockTransferEditVenue from "./StockTransferEditVenue/StockTransferEditVenue"
import StockTransferEditQuantity from "./StockTransferEditQuantity/StockTransferEditQuantity"
import StockTransferConfirmModal from "../StockTransferConfirmModal/StockTransferConfirmModal"
import Tabs from "components/common/Tabs/Tabs"
import Tab from "components/common/Tabs/Tab"
import NewProductSelect from "components/common/NewProductSelect/NewProductSelect"
import EditProduct from "components/suppliers/EditProduct/EditProduct"
import RecipeSelect from "components/common/RecipeSelect/RecipeSelect"
import DishSelect from "components/common/DishSelect/DishSelect"
import AddRecipeForm from "components/stock/AddRecipeForm/AddRecipeForm"
import AddDishForm from "components/stock/AddDishForm/AddDishForm"
import { isDevelopment } from "services/constants"

interface StockTransferProps {
  onUpdate: Function
  isOutgoing: boolean
  onRequestClose: Function
  [key: string]: any
}

interface popupState {
  title: string
  action: string
  data: null | any
  options?: any
}

const StockTransfer = ({
  onUpdate,
  isOutgoing = true,
  onRequestClose,
  ...otherProps
}: StockTransferProps) => {
  const modal = useContext(ModalContext)
  const aside = useContext(AsideContext)
  const [loading, setLoading] = useState(false)
  const { newStockTransfer, organization } = useContext(GlobalStateContext)
  const [addData, setAddData] = useState(null)
  const [popup, setPopup] = useState({
    title: "",
    action: "",
    data: null,
    options: {},
  } as popupState)
  const [tab, setTab] = useState("items")
  const [contentTypeObj, setContentTypeObj] = useState(null) as any
  const [forcePackingForProducts, setForcePackingForProducts] = useState({})
  const dispatch = useContext(GlobalDispatchContext)

  // UTIL
  const hideOptions = useCallback(() => {
    setAddData(null)
    setTimeout(() => {
      setPopup({
        title: "",
        action: "",
        data: null,
        options: null,
      })
    }, 300)
  }, [])

  const handleUpdate = (data) => {
    dispatch({
      type: "UPDATE_NEW_STOCK_TRANSFER",
      payload: {
        stockTransferData: data,
      },
    })
    hideOptions()
  }

  // CONFIRMATION MODALS
  const onSend = (data) => {
    modal.showModal(StockTransferConfirmModal, {
      type: "danger",
      title: `Send stock transfer`,
      text: `When is this transfer sent?`,
      confirmButtonText: "Send",
      onConfirm: (date) => handleSend(data, date),
    })
  }

  const onCancel = (data) => {
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: `Are you sure?`,
      text: `Are you sure you want to cancel this transfer?`,
      confirmButtonText: "Cancel",
      onConfirm: () => handleCancel(data),
    })
  }

  const onReceive = (data) => {
    modal.showModal(StockTransferConfirmModal, {
      title: `Receive stock transfer`,
      text: `When did you receive this transfer?`,
      dateLabel: "Received date",
      confirmButtonText: "Receive",
      onConfirm: (date) => handleReceive(data, date),
    })
  }

  const onReject = (data) =>
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: `Reject transfer`,
      text: `Are you sure you want to reject this transfer?`,
      confirmButtonText: "Reject",
      onConfirm: () => handleReject(data),
    })

  // CRUD OPERATIONS
  const handleAddItem = (continues, data, total) => {
    data.qty = total
    handleAddItems([data], continues)
    setAddData(null)
    if (continues) continueSelecting()
  }

  const continueSelecting = () => {
    setPopup({
      action: "add",
      title: `Add ${contentTypeObj.label}`,
      data: contentTypeObj.typeKey,
    })
  }

  const addValueItems = (addItems, data) => {
    addItems[0]["portion"] = data[0].portion
    addItems[0]["portionCount"] = data[0].portionCount
    addItems[0]["dishCost"] = data[0].totalCost
  }

  const handleAddItems = (data, continues = false) => {
    const currentItems = newStockTransfer[contentTypeObj.type] ?? []
    const addItems = data.map((item) => {
      return contentTypeObj.mapFn(item)
    })
    addValueItems(addItems, data)

    const newItems = mergeItems(currentItems, addItems, contentTypeObj.idField)

    dispatch({
      type: "UPDATE_NEW_STOCK_TRANSFER_CONTENT",
      payload: {
        items: newItems,
        type: contentTypeObj.type,
      },
    })
    if (!continues) hideOptions()
  }

  const handleAddMissingItem = async (newProd) => {
    const item = popup.data

    const id = item[contentTypeObj.idField]
    const changes = mapMissingIncommingProduct(item, newProd)

    dispatchItemChanges(item, changes)

    await sendItemChanges(newStockTransfer, contentTypeObj, id, changes, item)

    hideOptions()
    return
  }

  const handleDelete = async (item) => {
    dispatch({
      type: "UPDATE_NEW_STOCK_TRANSFER_CONTENT_ITEM_REMOVE",
      payload: {
        id: item[contentTypeObj.idField],
        type: contentTypeObj.type,
        idField: contentTypeObj.idField,
      },
    })
  }

  const mapMissingIncommingProduct = (org, newProd) => {
    return {
      receivedProductId: newProd.id,
      receivedQty: org.receivedQty, // <- note: we keep the received qty
      receivedQtyInCase: newProd.packaging == "multiple" ? true : false,
      receivedBarcode: newProd.barcode,
      receivedMeasure: newProd.measure,
      receivedName: newProd.name,
      receivedSize: newProd.size,
      receivedPrice: newProd.price,
    }
  }

  const mapProduct = (prod) => {
    return {
      senderComment: "",
      sentBarcode: prod.barcode,
      sentCategory: prod.category,
      sentMeasure: prod.measure,
      sentName: prod.name,
      sentProductId: prod.id,
      sentQty: prod.quantities.qty,
      sentQtyInCase: prod.packaging === "multiple",
      sentSize: prod.size,
      sentSubCategory: prod.subCategory,
      sentUnit: prod.unit,
      sentPrice: prod.price,
      sentCasePrice: prod?.productCase?.price,
    }
  }

  const mapRecipe = (recipe) => {
    return {
      id: recipe.id,
      sentQty: recipe.qty,
      sentMeasure: "portion",
      senderComment: "",
      // extra for frontend
      sentName: recipe.name,
    }
  }

  const mapDish = (dish) => {
    return {
      id: dish.id,
      sentQty: dish.qty,
      senderComment: "",
      sentName: dish.name,
    }
  }

  const mapProductPayload = (prod, isOutgoing = true) => {
    if (isOutgoing) {
      return {
        senderComment: prod.senderComment,
        sentProductId: prod.sentProductId,
        sentQty: prod.sentQty,
        sentQtyInCase: !!prod.sentQtyInCase,
        isConfirmed: prod.isConfirmed ?? false,
      }
    } else {
      return {
        receiverComment: prod.receiverComment,
        receivedProductId: prod.receivedProductId,
        receivedQty: prod.receivedQty,
        receivedQtyInCase: prod.receivedQtyInCase ?? !!prod.sentQtyInCase,
        isConfirmed: prod.isConfirmed ?? false,
      }
    }
  }

  const mapRecipePayload = (recipe) => {
    return {
      id: recipe.id,
      sentQty: recipe.sentQty,
      sentMeasure: recipe.sentMeasure,
      senderComment: recipe.senderComment,
    }
  }

  const mapDishPayload = (dish) => {
    return {
      id: dish.id,
      sentQty: dish.sentQty,
      senderComment: dish.senderComment,
    }
  }

  const mergeItems = (currentItems, addItems, idField) => {
    addItems = addItems.filter((item) => {
      const foundIndex = currentItems.findIndex(
        (i) => i[idField] === item[idField]
      )
      if (foundIndex > -1) {
        const curr = currentItems[foundIndex]
        curr.sentQty += item.sentQty
        currentItems[foundIndex] = curr
        return false
      } else {
        return true
      }
    })

    return currentItems.concat(addItems)
  }

  const dispatchItemChanges = async (item, data, incomming = false) => {
    let id = item[contentTypeObj.idField]
    let idField = contentTypeObj.idField

    if (id == null) {
      id = item.source.refId
      idField = "source.refId"
    }

    // hide total costs
    if (!incomming && data.sentQty && item.sentQty != data.sentQty) {
      data.sentTotalCost = null
    }

    await dispatch({
      type: "UPDATE_NEW_STOCK_TRANSFER_CONTENT_ITEM",
      payload: {
        type: contentTypeObj.type,
        idField: idField,
        id: id,
        item: data,
      },
    })
  }

  const handleAddComment = async (transfer, item, comment) => {
    const field = isOutgoing ? "senderComment" : "receiverComment"

    const changes = { [field]: comment }
    dispatchItemChanges(item, changes)

    const id = item[contentTypeObj.idField]
    await sendItemChanges(transfer, contentTypeObj, id, changes, item)

    hideOptions()
    return
  }

  const getItem = (contentTypeObj, id, idField = null) => {
    const items = newStockTransfer[contentTypeObj.type]
    const itemIndex =
      idField == "source.refId"
        ? items.findIndex((i) => i?.source?.refId == id)
        : items.findIndex((i) => i[contentTypeObj.idField] == id)
    return items[itemIndex]
  }

  const sendItemChanges = async (
    transfer,
    contentTypeObj,
    itemId,
    changes,
    item
  ) => {
    let idField

    if (itemId == null) {
      itemId = item.source.refId
      idField = "source.refId"
    }

    const storeItem = {
      ...getItem(contentTypeObj, itemId, idField),
      ...changes,
    }

    if (transfer.status !== "DRAFT") {
      const direction = isOutgoing ? "sent" : "received"

      const itemPayload = contentTypeObj.mapPayloadFn(storeItem, isOutgoing)

      const params = {
        payload: itemPayload,
        id: itemId,
        direction: direction,
        typeKey: contentTypeObj.typeKey,
        stockTransferId: transfer.id,
      }
      const result = await updateStockTransferItem(params)

      if (result && !result.error && result.status !== 400) {
        dispatchUpdatedItem(result, params)
        onUpdate()
      } else {
        showError(result.message)
      }
    }
  }

  const dispatchUpdatedItem = (result, params) => {
    let item = { [contentTypeObj.idField]: params.id } as any
    let updatedItem = result[contentTypeObj.type].find(
      (i) => i[contentTypeObj.idField] === params.id
    )
    if (!updatedItem) {
      updatedItem = result[contentTypeObj.type].find(
        (i) => i.source?.refId === params.id
      )
      item = { [contentTypeObj.idField]: null, source: { refId: params.id } }
    }
    dispatchItemChanges(item, updatedItem, true)
  }

  const handleEditQty = async (transfer, item, data) => {
    dispatchItemChanges(item, data)

    const id = item[contentTypeObj.idField]
    await sendItemChanges(transfer, contentTypeObj, id, data, item)
    hideOptions()
    return
  }

  const handleConfirm = async (transfer, item, isConfirmed) => {
    const changes = { isConfirmed }
    dispatchItemChanges(item, changes)

    const id = item[contentTypeObj.idField]
    await sendItemChanges(transfer, contentTypeObj, id, changes, item)
    hideOptions()
    return
  }

  const getItemsMapped = (contentTypeObj) => {
    const items = newStockTransfer[contentTypeObj.type]
    return items.map((item) => contentTypeObj.mapPayloadFn(item))
  }

  const handleSend = async (data, date = null) => {
    let result
    setLoading(true)

    const payload = {
      receiverOrgId: data.receiverOrg.id,
      senderOrgId: data.senderOrg.id,
      status: "DRAFT",
    }

    Object.keys(tabMap).forEach((key) => {
      const contentTypeObj = tabMap[key]
      payload[contentTypeObj.type] = getItemsMapped(contentTypeObj)
    })

    const params = {
      stockTransferId: data.id,
      payload,
    } as any

    if (date) {
      params.payload.sentAt = new Date(date).toISOString()
      params.payload.status = "SENT"
    }

    if (data.id) {
      result = await updateStockTransfer(params)
    } else {
      result = await createStockTransfer(params)
    }

    if (result && !result.error && result.status !== 400) {
      onUpdate()
      onRequestClose()
    } else {
      showError(result.message)
    }
    setLoading(false)

    return
  }

  const handleCancel = async (data) => {
    setLoading(true)

    const params = {
      stockTransferId: data.id,
    }

    const result = await cancelStockTransfer(params)

    if (result && !result.error) {
      onUpdate()
    } else {
      showError(result.message)
    }
    onRequestClose()
    setLoading(false)
    return
  }

  const handleReceive = async (data, date) => {
    let result
    setLoading(true)
    if (data.id) {
      const params = {
        stockTransferId: data.id,
        receivedAt: new Date(date).toISOString() ?? date,
      }
      result = await receiveStockTransfer(params)
    }
    if (result && !result.error) {
      onUpdate()
    } else {
      showError(result.message)
    }
    onRequestClose()
    setLoading(false)
    return
  }

  const handleReject = async (data) => {
    let result
    setLoading(true)
    if (data.id) {
      const params = {
        stockTransferId: data.id,
      }
      result = await cancelStockTransfer(params)
    }
    if (result && !result.error) {
      onUpdate()
    } else {
      showError(result.message)
    }
    onRequestClose()
    setLoading(false)
    return
  }

  const handleRevertToSent = async (data) => {
    let result
    setLoading(true)
    if (data.id) {
      const params = {
        stockTransferId: data.id,
      }
      result = await revertStockTransferToSent(params)
    }
    if (result && !result.error) {
      onUpdate()
    } else {
      showError(result.message)
    }
    onRequestClose()
    setLoading(false)
    return
  }

  // OTHER
  const onCreateNewProduct = () => {
    const options = {
      headerText: "Create new product",
      createProduct: true,
    } as any

    if (popup.action == "add.incomming") {
      const type = popup.data.source?.type
      const list =
        type === "RECIPE"
          ? newStockTransfer.recipes
          : type === "DISH"
          ? newStockTransfer.dishes
          : []
      const object = list.find((item) => item.id === popup.data.source.refId)

      // Create new recipe/dish
      if (type) {
        options.initialValues = {
          barcode: object?.barcode,
          measure: "each",
          name: object?.name,
          size: 1,
          unit: "Other",
          price: object?.portion?.cost || object?.dishCost,
        }
      } else {
        options.initialValues = {
          barcode: popup.data.sentBarcode || object?.barcode,
          category: popup.data.sentCategory,
          subCategory: popup.data.sentSubCategory,
          measure: popup.data.sentMeasure,
          name: popup.data.sentName,
          size: popup.data.sentSize,
          unit: popup.data.sentUnit,
          price: popup.data.sentPrice,
        }
      }

      options.onSubmitCallback = handleAddMissingItem
    }
    aside.showAside(EditProduct, options)
  }

  const tabMap = {
    items: {
      label: "Item",
      labelPlural: "Items",
      typeKey: "product",
      type: "products",
      idField: "sentProductId",
      mapFn: mapProduct,
      mapPayloadFn: mapProductPayload,
    },
    ...(isDevelopment
      ? {
          recipes: {
            label: "Recipe",
            labelPlural: "Recipes",
            typeKey: "recipe",
            type: "recipes",
            idField: "id",
            mapFn: mapRecipe,
            mapPayloadFn: mapRecipePayload,
          },
          dishes: {
            label: "Dish",
            labelPlural: "Dishes",
            typeKey: "dish",
            type: "dishes",
            idField: "id",
            mapFn: mapDish,
            mapPayloadFn: mapDishPayload,
          },
        }
      : {}),
  }

  const tabContents = () => {
    return (
      <StockTransferContent
        transfer={newStockTransfer}
        contentTypeObj={contentTypeObj}
        onAdd={(contentTypeObj) => {
          setPopup({
            action: "add",
            title: `Add ${contentTypeObj.label}`,
            data: contentTypeObj.typeKey,
          })
        }}
        onSelect={(data) => {
          setPopup({
            action: "add.incomming",
            title: `Select ${contentTypeObj.label}`,
            data: data,
          })
        }}
        onComment={(data) => {
          setPopup({
            action: "edit.comment",
            title: `Comments for ${data.name}`,
            data: data,
          })
        }}
        onEditQty={(data, field) => {
          setPopup({
            action: "edit.quantity",
            title: "Edit quantity",
            data: data,
            options: { field: field },
          })
        }}
        onDelete={handleDelete}
        onSave={handleSend}
        onSend={onSend}
        onReceive={onReceive}
        onReject={onReject}
        onCancel={onCancel}
        onConfirm={handleConfirm}
        onRevert={handleRevertToSent}
        isOutgoing={isOutgoing}
        onClose={onRequestClose}
        {...otherProps}
      />
    )
  }

  useEffect(() => {
    setContentTypeObj(tabMap[tab])
  }, [tab])

  useEffect(() => {
    const map = {}
    newStockTransfer.products.forEach((p) => {
      map[p.sentProductId] = p.sentQtyInCase ? "multiple" : "single"
    })
    setForcePackingForProducts(map)
  }, [newStockTransfer.products])

  return (
    <Modal
      isOpen
      style={{ content: { bottom: "40px", border: 0, overflow: "hidden" } }}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={!loading}
      shouldCloseOnEsc={!loading}
      portalClassName="stockTransfer"
      {...otherProps}
    >
      <StockTransferHeader
        transfer={newStockTransfer}
        onEdit={(data) => {
          setPopup({
            action: "edit.transfer",
            title: "Switch transfer venue",
            data: data,
          })
        }}
        onClose={onRequestClose}
        isOutgoing={isOutgoing}
        {...otherProps}
      />
      {isOutgoing ? (
        <Tabs onTabChange={(t) => setTab(t)} activeTab={tab}>
          {Object.keys(tabMap).map((key) => (
            <Tab key={key} title={tabMap[key].labelPlural} tabKey={key}>
              {tabContents()}
            </Tab>
          ))}
        </Tabs>
      ) : (
        tabContents()
      )}
      <OptionsPopup
        active={popup.action == "edit.transfer"}
        title={popup.title}
        activeCallback={hideOptions}
      >
        <StockTransferEditVenue
          organization={organization}
          onRequestClose={hideOptions}
          onSelect={handleUpdate}
        />
      </OptionsPopup>
      <OptionsPopup
        active={popup.action == "edit.quantity"}
        title={popup.title}
        activeCallback={hideOptions}
      >
        <StockTransferEditQuantity
          value={
            popup?.options?.field === "sentQty"
              ? popup.data?.sentQty
              : popup.data?.receivedQty
          }
          field={popup?.options?.field}
          onSave={(data) => handleEditQty(newStockTransfer, popup.data, data)}
          onClose={hideOptions}
        />
      </OptionsPopup>
      <OptionsPopup
        active={
          ["add", "add.incomming"].includes(popup.action) && tab == "items"
        }
        title={popup.title}
        activeCallback={hideOptions}
        fullHeight={true}
      >
        <NewProductSelect
          onSelect={
            popup.action == "add" ? handleAddItems : handleAddMissingItem
          }
          forcePackingForProducts={forcePackingForProducts}
          searchBySupplier={true}
          searchByBarcode={true}
          onClose={hideOptions}
          multiSelect={popup.action == "add"}
          requiredFields={["size", "measure"]}
          enablePriceEdit={false}
          qtyInputTypes={
            popup.action == "add.incomming"
              ? undefined
              : [{ label: "Qty", shortLabel: "Q", quantityKey: "qty" }]
          }
          createNewProduct={onCreateNewProduct}
        />
      </OptionsPopup>
      <OptionsPopup
        active={popup.action == "add" && tab == "recipes"}
        title={popup.title}
        activeCallback={hideOptions}
        fullHeight={true}
      >
        <div
          className={`flex flex-col w-full h-full overflow-auto ${
            !addData ? "" : "hidden"
          }`}
        >
          <RecipeSelect
            onSelect={setAddData}
            onClose={hideOptions}
            disabledSelectWithoutPortion={true}
          />
        </div>
        {addData && (
          <AddRecipeForm
            newRecipe={addData}
            isEdit={false}
            onCancel={() => setAddData(null)}
            onSave={handleAddItem}
            fullQtyAsPortions={true}
            hidePartialQty={true}
          />
        )}
      </OptionsPopup>
      <OptionsPopup
        active={popup.action == "add" && tab == "dishes"}
        title={popup.title}
        activeCallback={hideOptions}
        fullHeight={true}
      >
        <div
          className={`flex flex-col w-full h-full overflow-auto ${
            !addData ? "" : "hidden"
          }`}
        >
          <DishSelect onSelect={setAddData} onClose={hideOptions} />
        </div>
        {addData && (
          <AddDishForm
            newDish={addData}
            isEdit={false}
            onCancel={() => setAddData(null)}
            onSave={handleAddItem}
            fullQtyAsPortions={true}
            hidePartialQty={true}
          />
        )}
      </OptionsPopup>
      <OptionsPopup
        active={popup.action == "edit.comment"}
        title={popup.title}
        activeCallback={hideOptions}
      >
        <StockTransferComments
          transfer={newStockTransfer}
          product={popup.data}
          isOutgoing={isOutgoing}
          onClose={hideOptions}
          onSave={handleAddComment}
        />
      </OptionsPopup>
    </Modal>
  )
}

export default StockTransfer
