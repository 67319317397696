import React from "react"
import * as styles from "./Login.module.css"
import { Formik, Form, Field } from "formik"
import TextInput from "../../forms/TextInput"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import AuthFormWrapper from "../AuthFormWrapper/AuthFormWrapper"
import { ModalConsumer } from "../../../context/ModalContext"
import { Link } from "@reach/router"
import Logo from "../../../inline-svg/growyze-logo-primary-blue-dark.svg"
import { navigate } from "gatsby"
import ContactModal from "components/common/ContactModal/ContactModal"
import {
  loginV2 as login,
  isLoggedIn,
  authenticateAdmin,
  loginAdmin,
  authenticateUser,
} from "services/auth"
import OrganizationSelect from "components/common/OrganizationSelect/OrganizationSelect"
import { orgMapToArray } from "services/helpers"
import { sendEvent } from "services/gtm"

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: "",
      organizationSelect: false,
      organizations: [],
      token: "",
      adminLogin: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSubmitAdmin = this.handleSubmitAdmin.bind(this)
  }

  async handleSubmit(data) {
    // TODO: add functionality for remembering the user
    const authenticateUserResult = await authenticateUser({
      username: data.username,
      password: data.password,
    })

    const loginSuccess = (loginResult) => {
      /*
      Redirect to onboarding:
      if user has default organisation
      */

      // eslint-disable-next-line no-undef
      const currentOrganisation = loginResult.organizations[0]
      if (currentOrganisation === "growyze-default") {
        return navigate(`/dashboard/welcome`, { replace: true })
      } else {
        return navigate(`/dashboard`, { replace: true })
      }
    }
    if (authenticateUserResult.orgIdToOrgName) {
      const orgIds = Object.keys(authenticateUserResult.orgIdToOrgName)
      sendEvent("login", {
        email: data.username,
      })
      if (orgIds.length > 1) {
        this.setState({
          organizationSelect: true,
          organizations: orgMapToArray(authenticateUserResult),
          token: authenticateUserResult.sessionId,
        })
      } else {
        const params = {
          activeOrgId: orgIds[0],
          sessionId: authenticateUserResult.sessionId,
        }

        const loginResult = await login(params).catch((err) =>
          this.setState({
            error: err,
          })
        )

        if (loginResult) {
          loginSuccess(loginResult)
        }
      }
    } else {
      this.setState({
        error: true,
      })
    }
  }

  async handleSubmitAdmin(data) {
    const authenticateAdminResult = await authenticateAdmin({
      username: data.username,
      password: data.password,
    })

    if (authenticateAdminResult.orgIdToOrgName) {
      this.setState({
        organizationSelect: true,
        organizations: orgMapToArray(authenticateAdminResult),
      })
    } else {
      this.setState({
        error: true,
      })
    }
  }

  componentDidMount() {
    if (isLoggedIn()) {
      navigate(`/dashboard`, { replace: true })
    }
    if (this.props.location.pathname === "/login/admin") {
      this.setState({
        adminLogin: true,
      })
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Log In</title>
        </Helmet>
        <AuthFormWrapper>
          {!this.state.organizationSelect ? (
            <Formik
              initialValues={{
                username: "",
                password: "",
                rememberMe: false,
              }}
              onSubmit={
                this.state.adminLogin
                  ? this.handleSubmitAdmin
                  : this.handleSubmit
              }
            >
              {({ isSubmitting }) => (
                <Form className="flex flex-col mb-6 px-6">
                  <Logo className="mb-4 block mx-auto" />

                  <div className={styles.welcomeContent}>
                    <h1 className="text-4xl mb-6">Welcome back</h1>
                  </div>

                  {this.state.error && (
                    <div className={styles.error}>
                      Incorrect login details, please try again. Having trouble?{" "}
                      <ModalConsumer>
                        {({ showModal }) => (
                          <a
                            href="mailto:support@growyze.com"
                            className="text-primaryPink"
                            onClick={(e) => {
                              e.preventDefault()
                              showModal(ContactModal)
                            }}
                          >
                            Contact us
                          </a>
                        )}
                      </ModalConsumer>
                    </div>
                  )}

                  <div className="form-group mt-2">
                    <div className="input-container">
                      <TextInput
                        name="username"
                        showValidations={false}
                        label="Email address"
                        type="text"
                        placeholder="Email address"
                        autoComplete="username"
                        className="form-control form-control--first"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-container">
                      <TextInput
                        name="password"
                        showValidations={false}
                        label="Password"
                        type="password"
                        autoComplete="current-password"
                        placeholder="Password"
                        className="form-control form-control--last"
                      />
                    </div>
                  </div>
                  <div className="form-group justify-between items-center flex-wrap form-group--flex">
                    <div className="mt-3">
                      <label
                        className="inline-flex items-center"
                        htmlFor="rememberMe"
                      >
                        <Field
                          type="checkbox"
                          name="rememberMe"
                          className="form-checkbox text-primaryPink"
                        />
                        <span className="ml-2 text-gray-700">Remember me</span>
                      </label>
                    </div>
                    <Link
                      className="text-gray-700 mt-3 font-sans font-normal"
                      to="/reset-password"
                    >
                      Forgot your password?
                    </Link>
                  </div>
                  <button
                    disabled={isSubmitting}
                    type="submit"
                    className="button mt-5"
                  >
                    Log In
                  </button>
                </Form>
              )}
            </Formik>
          ) : (
            <OrganizationSelect
              organizations={this.state.organizations}
              onSubmitOrganization={this.state.adminLogin ? loginAdmin : login}
              token={this.state.token}
            />
          )}

          <p className="text-sm text-gray-700 text-center mb-5">
            No account yet?{" "}
            <Link className="text-primaryPink" to="/signup">
              Sign Up
            </Link>
          </p>
        </AuthFormWrapper>
      </>
    )
  }
}

Login.propTypes = {
  location: PropTypes.object,
}

export default Login
