import React, { useState, useEffect } from "react"
import { navigate, useLocation } from "@reach/router"
import Helmet from "react-helmet"
import Header from "components/dashboard/Header/Header"
import StockTakes from "components/stock/StockTakes/StockTakes"
import StockTakeSheets from "components/stock/StockTakeSheets/StockTakeSheets"
import BelowMinQtyReport from "components/stock/BelowMinQtyReport/BelowMinQtyReport"

import * as queryString from "query-string"

import * as styles from "./StockTakeWrapper.module.css"

const StockTakeWrapper = () => {
  const location = useLocation()

  const initialTab = location?.state?.initialTab

  const [activeTab, setActiveTab] = useState(
    initialTab ? initialTab : "dashboard"
  )

  useEffect(() => {
    const queryParams = queryString.parse(location.search)
    if (queryParams.tab) {
      setActiveTab(queryParams.tab)
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>Stocktake</title>
      </Helmet>
      <div className={styles.container}>
        <Header title="Stocktake" />
        <ul className={styles.tabs}>
          <li className={activeTab === "dashboard" ? styles.activeTab : ""}>
            <button
              type="button"
              onClick={() => {
                navigate("?tab=dashboard")
              }}
            >
              Dashboard
            </button>
          </li>
          <li className={activeTab === "history" ? styles.activeTab : ""}>
            <button
              type="button"
              onClick={() => {
                navigate("?tab=history")
              }}
            >
              History
            </button>
          </li>
          <li className={activeTab === "sheets" ? styles.activeTab : ""}>
            <button
              type="button"
              onClick={() => {
                navigate("?tab=sheets")
              }}
            >
              Sheets
            </button>
          </li>
        </ul>
        {activeTab === "history" && <StockTakes />}
        {activeTab === "sheets" && <StockTakeSheets />}
        {activeTab === "dashboard" && <BelowMinQtyReport />}
      </div>
    </>
  )
}

export default StockTakeWrapper
