import React, { useCallback, useMemo, useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { components as cs } from "react-select"
import debounce from "debounce-promise"
import AsyncSelect from "react-select/async"
import AsyncCreatableSelect from "react-select/async-creatable"

const NewSingleValue = () => null

const dot = (color = "#ccc") => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 10,
    width: 10,
  },
})

const NewInput = ({ value: inputValue, isHidden, ...props }) => {
  const {
    selectProps: { value, getOptionLabel },
  } = props

  const label = useMemo(() => {
    if (!value) {
      return ""
    }
    if (value.value && value.value.name) {
      return getOptionLabel(value)
    } else {
      return value.value
    }
  }, [getOptionLabel, value])
  const v = useMemo(() => {
    if (!inputValue) {
      return label
    }
    return inputValue
  }, [inputValue, label])
  const hidden = useMemo(() => {
    if (v) {
      return false
    }
    return isHidden
  }, [isHidden, v])
  return <cs.Input isHidden={hidden} value={v} {...props} />
}

NewInput.propTypes = {
  value: PropTypes.any,
  isHidden: PropTypes.bool,
  selectProps: PropTypes.any,
}

const components = {
  ...cs,
  Input: NewInput,
  SingleValue: NewSingleValue,
}

const customStyles = (creatable = false) => {
  return {
    option: (provided, state) => ({
      ...provided,
      ...(state.data.color ? dot(state.data.color) : {}),
      backgroundColor: state.isSelected ? "#FC3762" : "#ffffff",
      ":hover": {
        ...provided[":hover"],
        backgroundColor: state.isSelected ? "#FC3762" : "#f7fafc",
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 5,
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "2px calc(1rem - 2px)",
    }),
    control: (provided, state) => {
      return {
        ...provided,
        borderRadius: "0.25rem",
        borderColor: state.isFocused ? "#FC3762" : "#e2e8f0",
        backgroundColor: creatable ? "#FFFFFF" : "#F8F8FF",
        boxShadow: "none",
        zIndex: state.isFocused ? 2 : null,
        minHeight: 45,
        ":hover": {
          ...provided[":hover"],
          borderColor: state.isFocused ? "#FC3762" : "#e2e8f0",
        },
      }
    },
    singleValue: (styles, { data }) => ({
      ...styles,
      ...(data.color ? dot(data.color) : {}),
    }),
  }
}

const Select = ({
  name,
  label,
  promise,
  value,
  optionLabel,
  optionValue,
  onChange = undefined,
  creatable = false,
  ...props
}) => {
  const [currentValue, setCurrentValue] = useState(value)
  const selectRef = useRef(null)

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  const handleChange = useCallback(
    (selectedOption, triggeredAction) => {
      setCurrentValue(selectedOption)
      if (onChange) {
        onChange(selectedOption, triggeredAction)
      }
    },
    [onChange]
  )

  /**
   * Make sure the input value stays on input change
   *
   */
  const handleInputChange = (inputValue, action) => {
    if (action.action !== "input-blur" && action.action !== "menu-close") {
      setCurrentValue({ value: inputValue })
      if (onChange) {
        onChange({ value: inputValue }, action)
      }
    }
  }

  const getOptions = (inputValue) => {
    return promise(inputValue).then((res) => {
      const options = res.content.map((item) => {
        return {
          value: optionValue ? item[optionValue] : item,
          label: item[optionLabel],
        }
      })
      return options
    })
  }

  const loadOptions = (inputValue) => getOptions(inputValue)
  const getDebouncedOptions = debounce(loadOptions, 500, { leading: true })

  const componentProps = {
    components: components,
    id: name,
    name: name,
    cacheOptions: true,
    ref: selectRef,
    defaultOptions: true,
    onChange: handleChange,
    onInputChange: handleInputChange,
    onKeyDown: (e) => {
      if (e.keyCode === 32 && !selectRef.current.state.inputValue) {
        e.preventDefault()
        // handleInputChange(selectRef.current.state.inputValue + " ", "set-value")
      }
    },
    loadOptions: (inputValue) => getDebouncedOptions(inputValue),
    styles: customStyles(creatable),
    value: currentValue,
    ...props,
  }

  const comp = creatable ? (
    <AsyncCreatableSelect {...componentProps} />
  ) : (
    <AsyncSelect {...componentProps} />
  )

  return (
    <>
      <label className="sr-only" htmlFor={name}>
        {label}
      </label>
      {comp}
    </>
  )
}

Select.propTypes = {
  value: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.string,
  promise: PropTypes.any,
  optionLabel: PropTypes.any,
  optionValue: PropTypes.any,
  onChange: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  creatable: PropTypes.bool,
}

export default Select
