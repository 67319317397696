import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { productCategories } from "services/constants"
import { faCircle } from "@fortawesome/pro-solid-svg-icons"
import { Dish } from "services/dishes"
import DishIcon from "../DishIcon/DishIcon"
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons"
import ReactTooltip from "react-tooltip"

interface DishItemNameProps {
  dish: Dish
  permissionObj?: any
  handleActionClick?(config: {}, dish: Dish): void
}

const DishItemName = ({
  dish,
  permissionObj,
  handleActionClick,
}: DishItemNameProps) => {
  const matchedCat = productCategories.find((c) => c.value === dish.category)

  return (
    <div className="pl-1 flex w-auto items-center h-14">
      {dish.hasDeletedIngredients && (
        <>
          <span
            data-for="deleted_product_tooltip"
            data-tip="This dish contains deleted ingredients."
          >
            <FontAwesomeIcon
              icon={faExclamationCircle}
              size="lg"
              className="text-error mr-2"
            />
          </span>

          <ReactTooltip
            id={"deleted_product_tooltip"}
            type="light"
            place="right"
            effect="float"
            border={true}
            borderColor="#e2e8f0"
          />
        </>
      )}

      <DishIcon
        disabled={!permissionObj?.permissions.read}
        dish={dish}
        onClick={(e) => {
          if (!permissionObj?.permissions.read) {
            return
          }
          e.preventDefault()
          handleActionClick && handleActionClick({ type: "dish.view" }, dish)
        }}
      />

      <button
        onClick={(e) => {
          if (!permissionObj?.permissions.read) {
            return
          }
          e.preventDefault()
          handleActionClick && handleActionClick({ type: "dish.view" }, dish)
        }}
        type="button"
        style={{
          minWidth: "200px",
          textAlign: "left",
        }}
        className=" focus:outline-none  py-3 px-3 flex flex-col"
      >
        <span className="break-all text-sm font-semibold font-sansSemiBold text-primaryBlue">
          {dish.name}
        </span>
        <span className="flex items-center text-xs text-gray-700">
          <span className="mr-1">{matchedCat?.label}</span>
          {dish.posId && (
            <FontAwesomeIcon
              icon={faCircle}
              className="text-gray-500 mr-1 text-xs"
              style={{ fontSize: "0.3em" }}
            />
          )}
          <span>{dish.posId}</span>
        </span>
      </button>
    </div>
  )
}

export default DishItemName
