import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { usePromise, usePrevious } from "react-use"
import { getDeliveryNotes } from "services/delivery-notes"
import { searchSuppliers } from "services/supplier"
import SearchInput from "components/forms/SearchInput"
import AsyncSelect from "components/forms/AsyncSelect"
import FilterSelect from "components/forms/FilterSelect"
import Loader from "components/common/Loader/Loader"
import DeliveryNoteSelectItem from "components/delivery-notes/DeliveryNoteSelectItem/DeliveryNoteSelectItem"

import * as styles from "./DeliveryNoteSelect.module.css"
import { deliveryNoteStates } from "services/constants"

const DeliveryNoteSelect = ({
  onSelect,
  selectedSupplier,
  searchOnSupplier,
}) => {
  const [loading, setLoading] = useState(false)
  const fromPromise = usePromise()
  const [deliveryNoteData, setDeliveryNoteData] = useState({
    content: [],
  })
  const [dnQ, setDnQ] = useState("")
  const previousDnQ = usePrevious(dnQ)
  const [poQ, setPoQ] = useState("")
  const previousPoQ = usePrevious(poQ)
  const [status, setStatus] = useState(null)
  const previousStatus = usePrevious(status)
  const [supplier, setSupplier] = useState(
    selectedSupplier ? selectedSupplier : null
  )
  const previousSupplier = usePrevious(supplier)

  const [paginationData, setPaginationData] = useState({
    page: 0,
    size: 999,
    totalPages: "",
    totalElements: "",
    numberOfElements: "",
  })
  const previousPage = usePrevious(paginationData.page)

  const getData = async () => {
    setLoading(true)

    let params = {
      page: paginationData.page,
      sort: "dateOfScanning,desc",
      includeIsInvoiced: true,
    }

    if (supplier) {
      params.supplierId = supplier.id
      if (previousSupplier && previousSupplier.id !== supplier.id) {
        params.page = 0
      }
    }

    if (status) {
      params.status = status.value

      if (previousStatus && previousStatus.value !== status.value) {
        params.page = 0
      }
    }

    if (dnQ) {
      params.deliveryNoteNumber = dnQ

      if (previousDnQ && previousDnQ.value !== dnQ.value) {
        params.page = 0
      }
    }

    if (poQ) {
      params.po = poQ

      if (previousPoQ && previousPoQ.value !== dnQ.value) {
        params.page = 0
      }
    }

    // fromPromise prevents call on unmount of component
    const result = await fromPromise(getDeliveryNotes(params))

    if (result && !result.error) {
      setDeliveryNoteData({ ...deliveryNoteData, ...result })
      setPaginationData({
        ...paginationData,
        size: result.size,
        totalPages: result.totalPages,
        totalElements: result.totalElements,
        numberOfElements: result.numberOfElements,
      })
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!selectedSupplier) {
      getData()
    }
  }, [selectedSupplier])

  useEffect(() => {
    if (previousStatus || status) {
      getData()
    }
  }, [status])

  useEffect(() => {
    if (previousPage !== undefined && paginationData.page !== previousPage) {
      getData()
    }
  }, [paginationData.page])

  useEffect(() => {
    if (previousSupplier || supplier) {
      getData()
    }
  }, [supplier])

  useEffect(() => {
    if (previousDnQ || dnQ) {
      getData()
    }
  }, [dnQ])

  useEffect(() => {
    if (previousPoQ || poQ) {
      getData()
    }
  }, [poQ])

  return (
    <>
      <div className={styles.container}>
        <div className={styles.subHeader}>
          <div className="my-2 px-2 w-1/2">
            <SearchInput
              label="Search DN number"
              placeholder="Search DN number"
              className="form-control rounded"
              onSearchChange={(val) => {
                setDnQ(val)
              }}
            />
          </div>
          <div className="my-2 px-2 w-1/2">
            <SearchInput
              label="Search PO number"
              placeholder="Search PO number"
              className="form-control rounded"
              onSearchChange={(val) => {
                setPoQ(val)
              }}
            />
          </div>
          <div className="my-2 px-2 w-1/2">
            <FilterSelect
              options={deliveryNoteStates}
              value={status}
              onChange={(val) => {
                setStatus(val)
              }}
              placeholder="Status: all"
              isClearable={true}
            />
          </div>
          {searchOnSupplier && (
            <div className="my-2 px-2 w-1/2">
              <AsyncSelect
                promise={searchSuppliers}
                placeholder="All suppliers"
                isDisabled={selectedSupplier}
                defaultValue={
                  selectedSupplier
                    ? { value: selectedSupplier, label: selectedSupplier.name }
                    : null
                }
                isClearable={true}
                optionLabel="name"
                onChange={(val) => {
                  setSupplier(val ? val.value : null)
                }}
              />
            </div>
          )}
        </div>

        <div>
          <Loader
            isLoading={loading}
            style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
          >
            Loading deliveries..
          </Loader>
          <div className="w-full h-auto -mt-px">
            <div className="flex flex-col">
              {deliveryNoteData.content.length > 0 &&
                deliveryNoteData.content.map((deliveryNote) => {
                  return (
                    <React.Fragment key={deliveryNote.id}>
                      <DeliveryNoteSelectItem
                        deliveryNote={deliveryNote}
                        onSelect={onSelect}
                      />
                    </React.Fragment>
                  )
                })}
              {deliveryNoteData.content.length === 0 && !loading && (
                <p className={styles.noDeliveries}>No deliveries founds</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

DeliveryNoteSelect.propTypes = {
  onSelect: PropTypes.func,
  selectedSupplier: PropTypes.object,
  searchOnSupplier: PropTypes.bool,
}

export default DeliveryNoteSelect
