import React from "react"
import PropTypes from "prop-types"
import { isValid } from "date-fns"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames/bind"
import {
  faPencil,
  faCalendar,
  faSearch,
} from "@fortawesome/pro-regular-svg-icons"
import { invoiceStates } from "services/constants"
import { formatAsYearMonthDay, getMimeTypeFromDataUri } from "services/helpers"
import ServerImage from "components/common/ServerImage/ServerImage"
import { faFileAlt } from "@fortawesome/pro-duotone-svg-icons"
import * as styles from "./ConfirmDetailsHeader.module.css"
import usePermissions from "hooks/usePermissions"

const cx = classNames.bind(styles)

const InvoiceDeliveryInfo = ({
  invoice,
  permissionObj,
  onSearchDeliveryAction,
}) => (
  <>
    {invoice.po ? (
      <span className={cx("invoiceDeliveryStatus", "found")}>
        Order found: {`#${invoice.po}`}
      </span>
    ) : (
      <span className={cx("invoiceDeliveryStatus", "notfound")}>
        No order found
      </span>
    )}
    {invoice.deliveryNoteId ? (
      <span className={cx("invoiceDeliveryStatus", "found", "transparent")}>
        {invoice.deliveryNoteNumber
          ? `DN: ${invoice.deliveryNoteNumber}`
          : "Delivery found"}
      </span>
    ) : (
      <div className={styles.notFoundContainer}>
        <span className={cx("invoiceDeliveryStatus", "notfoundtransparent")}>
          No delivery found{" "}
        </span>
        <button
          onClick={onSearchDeliveryAction}
          disabled={!permissionObj?.permissions.modify}
          className="button button--autoWidth button--orange text-sm"
        >
          <FontAwesomeIcon icon={faSearch} className="lg:mr-2" />
          <span className="hidden lg:inline">Search delivery</span>
        </button>
      </div>
    )}
  </>
)

const ReviewInvoiceDeliveryInfo = ({ invoice }) => (
  <>
    {invoice.expectedPo ? (
      <span className={cx("invoiceDeliveryStatus", "expected")}>
        Order number: {invoice.expectedPo}
      </span>
    ) : (
      <span className={cx("invoiceDeliveryStatus", "notfound")}>
        No order number
      </span>
    )}
    {invoice.expectedDeliveryNoteNumber ? (
      <span className={cx("invoiceDeliveryStatus", "expected", "transparent")}>
        Delivery Note: {invoice.expectedDeliveryNoteNumber}
      </span>
    ) : (
      <div className={styles.notFoundContainer}>
        <span className={cx("invoiceDeliveryStatus", "notfoundtransparent")}>
          No delivery note
        </span>
      </div>
    )}
  </>
)

const ConfirmDetailsHeader = ({ invoice, onEdit, onSearchDeliveryAction }) => {
  const invoiceState = invoiceStates.find((oS) => oS.value === invoice.status)
  const permissionObj = usePermissions("Invoices")

  const isPdf = (dataUri) => {
    return getMimeTypeFromDataUri(dataUri) === "application/pdf"
  }

  const Icon = (
    <div
      className={styles.iconWrapper}
      style={{
        backgroundColor: "#E7EDFF",
        color: "#1B377D",
      }}
    >
      <FontAwesomeIcon icon={faFileAlt} />
    </div>
  )
  return (
    <div className={styles.header}>
      <div className={styles.thumbnailWrapper}>
        <div className={styles.thumbnail}>
          {invoice.dataUri ? (
            isPdf(invoice.dataUri) ? (
              Icon
            ) : (
              <img alt={invoice.deliveryNoteNumber} src={invoice.dataUri} />
            )
          ) : (
            <ServerImage
              fileId={invoice.fileId}
              alt={invoice.deliveryNoteNumber}
            >
              {Icon}
            </ServerImage>
          )}
        </div>
        {invoiceState && (
          <span
            className={styles.statusBulb}
            style={{
              backgroundColor: invoiceState.color,
              width: "12px",
              height: "12px",
            }}
          ></span>
        )}
      </div>

      <div className={styles.headerMain}>
        <h3 className={styles.noteRef}>
          {invoice.status === "DRAFT" && "[Draft] "}
          {invoice.invoiceNumber || (
            <span className="text-primaryPink">INV-??</span>
          )}
          {invoice.id && (
            <button
              onClick={onEdit}
              className={cx("button--autoWidth", "editButton")}
              disabled={!permissionObj?.permissions.modify}
            >
              <FontAwesomeIcon icon={faPencil} />
            </button>
          )}
        </h3>
        {invoice.supplier && (
          <p className={styles.supplierName}>By {invoice.supplier.name}</p>
        )}
        {invoice.dateOfIssue && isValid(new Date(invoice.dateOfIssue)) && (
          <p className={styles.dateOfIssue}>
            <FontAwesomeIcon icon={faCalendar} className="mr-1" />
            {formatAsYearMonthDay(new Date(invoice.dateOfIssue))}
          </p>
        )}
      </div>

      {invoice.supplier && (
        <div className={styles.noteSubDetails}>
          {invoice.status !== "REVIEW" ? (
            <InvoiceDeliveryInfo
              permissionObj={permissionObj}
              onSearchDeliveryAction={onSearchDeliveryAction}
              invoice={invoice}
            ></InvoiceDeliveryInfo>
          ) : (
            <ReviewInvoiceDeliveryInfo
              permissionObj={permissionObj}
              onSearchDeliveryAction={onSearchDeliveryAction}
              invoice={invoice}
            ></ReviewInvoiceDeliveryInfo>
          )}
        </div>
      )}
    </div>
  )
}

ConfirmDetailsHeader.propTypes = {
  invoice: PropTypes.object,
  onEdit: PropTypes.func,
  onSearchDeliveryAction: PropTypes.func,
  onAttachedFilesAction: PropTypes.func,
}

export default ConfirmDetailsHeader
