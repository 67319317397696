import React, { useContext } from "react"
import { Formik, Form } from "formik"
import FormikEffect from "components/forms/FormikEffect"
import * as Yup from "yup"
import { countries, timeZones } from "services/constants"
import { normalizeNullValues } from "services/helpers"
import TextInput from "../../forms/TextInput"
import CustomSelect from "../../forms/CustomSelect"
import { GlobalStateContext } from "context/GlobalContextProvider"
import { useMediaQuery } from "react-responsive"
import { Address } from "services/types"

const NullableSchema = Yup.object().shape({
  addressLine1: Yup.string(),
  city: Yup.string(),
  postCode: Yup.string(),
  country: Yup.string(),
})

const Schema = Yup.object().shape({
  addressLine1: Yup.string().required("Please fill in an address"),
  city: Yup.string().required("Please fill in a city"),
  postCode: Yup.string().required("Please fill in a postal code"),
  country: Yup.string().required("Please fill in a country"),
})

interface AddressFormProps {
  onDataChange({ nextValues: Address }): void
  onValidChange?(valid: boolean): void
  initialValues?: Address
  isNullable?: boolean
  allowNullable?: boolean
  validateOnMount?: boolean
  showCompanyName?: boolean
  selectTimeZone?: boolean
  selectCountry?: boolean
}

const AddressForm = ({
  onDataChange,
  onValidChange,
  initialValues,
  isNullable,
  allowNullable,
  showCompanyName = false,
  validateOnMount = true,
  selectTimeZone = false, // Toggles whether you can edit the timezone fieldy
  selectCountry = true, // Default, allow country selection
}: AddressFormProps) => {
  const { user } = useContext(GlobalStateContext)
  const { addressLine1, city, postCode, country } =
    normalizeNullValues(initialValues)
  const hasInitialAddress = addressLine1 && city && postCode && country

  const defaultValues =
    selectTimeZone && !initialValues?.zoneId
      ? normalizeNullValues({ ...initialValues, zoneId: user.zoneId })
      : normalizeNullValues(initialValues)

  const timeZoneSchema = Yup.object().shape({
    zoneId: Yup.string().required("Please select your timezone"),
  })

  const companyNameSchema = Yup.object().shape({
    companyName: Yup.string().required("Please fill in a location name"),
  })

  const validationSchema =
    isNullable && (hasInitialAddress || allowNullable)
      ? selectTimeZone
        ? NullableSchema.concat(timeZoneSchema)
        : NullableSchema
      : selectTimeZone
      ? Schema.concat(timeZoneSchema)
      : Schema

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
  return (
    <Formik
      enableReinitialize
      validateOnMount={validateOnMount}
      initialValues={defaultValues}
      validationSchema={
        showCompanyName
          ? validationSchema.concat(companyNameSchema)
          : validationSchema
      } //Attach enterprise schema if required
    >
      {() => (
        <>
          <FormikEffect
            onChange={onDataChange}
            onValidationChange={onValidChange}
          />
          <Form className="flex flex-col mb-4 mt-2">
            <div className="form-group">
              {showCompanyName && (
                <div className="input-container mb-4">
                  <TextInput
                    required={true}
                    name="companyName" //According to Zahari, companyName = venueName in BE
                    label="Location name"
                    type="text"
                    placeholder={
                      isNullable ? "Location name" : "Location name *"
                    }
                    // autoComplete="street"
                    className="form-control form-control--first form-control--last"
                  />
                </div>
              )}
              <div className="input-container">
                <TextInput
                  required={true}
                  name="addressLine1"
                  label="Address line"
                  type="text"
                  placeholder={
                    isNullable ? "Address line 1" : "Address line 1 *"
                  }
                  autoComplete="street"
                  className="form-control form-control--first"
                />
              </div>
              <div className="input-container">
                <TextInput
                  name="addressLine2"
                  showValidations={false}
                  label="Address line 2"
                  type="text"
                  placeholder="Address line 2"
                  autoComplete="street"
                  className="form-control"
                />
              </div>
            </div>

            <div className="form-group form-group--flex">
              <div className="input-container input-container--left">
                <TextInput
                  required={true}
                  name="postCode"
                  label="Post code"
                  type="text"
                  placeholder={isNullable ? "Post code" : "Post code *"}
                  autoComplete="postcode"
                  className="form-control"
                />
              </div>
              <div className="input-container input-container--right">
                <TextInput
                  required={true}
                  name="city"
                  label="City"
                  type="text"
                  placeholder={isNullable ? "City" : "City *"}
                  autoComplete="city"
                  className="form-control"
                />
              </div>
            </div>

            {selectCountry && (
              <div className="form-group">
                <div className="input-container">
                  <CustomSelect
                    name="country"
                    id="country"
                    label="Country"
                    placeholder={isNullable ? "Country" : "Country *"}
                    isSearchable={!isTabletOrMobile}
                    className={`form-control ${
                      selectTimeZone ? "" : "form-control--last"
                    }`}
                    options={countries}
                  />
                </div>
              </div>
            )}

            {selectTimeZone && (
              <div className="form-group">
                <div className="input-container">
                  <CustomSelect
                    name="zoneId"
                    isSearchable={true}
                    id="zoneId"
                    label="Timezone"
                    placeholder={"Timezone *"}
                    className="form-control form-control--last"
                    options={timeZones}
                  />
                </div>
              </div>
            )}
          </Form>
        </>
      )}
    </Formik>
  )
}

export default AddressForm
