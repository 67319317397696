import {
  ActivityWidget,
  NotificationWidget,
} from "components/dashboard/Widgets/NotificationWidget/NotificationWidget"
import { GlobalStateContext } from "context/GlobalContextProvider"
import React, { ReactNode, useContext, useState } from "react"

interface TabsProps {
  label: string
  Component: ReactNode
}

function AsideNotificationsAndActions() {
  //CONTEXT
  const { selectedOrganizations } = useContext(GlobalStateContext)

  //CONSTANTS
  const tabs: TabsProps[] = [
    {
      label: "Activities",
      Component: <ActivityWidget organizations={selectedOrganizations} />,
    },
    {
      label: "Notifications",
      Component: <NotificationWidget organizations={selectedOrganizations} />,
    },
  ]

  //STATE
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  return (
    <div className="flex w-full h-full relative">
      <div className="flex flex-col flex-grow">
        <div className="flex space-x-2 mt-2 px-6">
          {tabs.map((tab, tabIndex) => {
            const isActiveTab = () => tabIndex === activeTabIndex
            const tabClasses: string = isActiveTab()
              ? "text-black border-black"
              : "hover:opacity-70 text-gray-800 opacity-70"

            return (
              <div
                className={`z-50 cursor-pointer border-b-2 border-transparent px-2 py-1 box-content ${tabClasses}`}
                key={`AsideTab-${tabIndex}-${tab.label}`}
              >
                <span
                  className="font-semibold text-base p-1 inline-block text-center"
                  onClick={() => setActiveTabIndex(tabIndex)}
                >
                  {tab.label}
                </span>
              </div>
            )
          })}
        </div>
        <div
          className="w-full h-1 rounded-md block border-t-2 z-40"
          style={{
            marginTop: -2,
          }}
        ></div>
        <div className="flex-1 overflow-y-auto pb-8">
          {tabs[activeTabIndex].Component}
        </div>
      </div>
    </div>
  )
}

export default AsideNotificationsAndActions
