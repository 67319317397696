import React, { useEffect, useState, useContext } from "react"
import { GlobalStateContext } from "context/GlobalContextProvider"
import { ModalContext } from "context/ModalContext"
import * as queryString from "query-string"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { showWarn, showSuccess } from "services/toast"
import {
  getSquarePosClientId,
  redirectToSquareAuthorization,
  createSquareConnection,
  getSquareConnections,
  removeSquareConnection,
  getCSRFToken,
  removeCSRFToken,
} from "services/square"
import {
  faChevronRight,
  faSpinnerThird,
} from "@fortawesome/pro-regular-svg-icons"
import { useLocation } from "@reach/router"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import SquareLocationSelect from "components/integrations/Square/SquareLocationSelect/SquareLocationSelect"

const SquareIntegrationButton = ({
  // status = "INACTIVE",
  id = null,
  onConnectionChange,
  canConnect,
  canDisconnect,
}) => {
  const route = useLocation()

  const { organization } = useContext(GlobalStateContext)
  const modal = useContext(ModalContext)
  const queryParams = queryString.parse(route.search)

  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [connectionId, setConnectionId] = useState(false)
  const [squareLocation, setSquareLocation] = useState(false)
  const [checkingConnection, setCheckingConnection] = useState(true)

  const startSquareAuthorization = async () => {
    try {
      const clientId = await getSquarePosClientId()
      setLoading(true)
      redirectToSquareAuthorization(clientId)
    } catch (e) {
      setLoading(false)
      setError("Failed to connect to square, please try again later")
    }
  }

  const setupSquare = async (code) => {
    setError("")
    const params = {
      code,
      name: `SQUARE_POS - ${organization.companyName}`,
      organizations: null,
    }
    const { id, location } = await createSquareConnection(params)

    if (id) {
      setConnectionId(id)
      setSquareLocation(location)
      // !important: User needs to select the right location from Square
      if (!location) {
        showSquareLocationSelect(id)
      } else {
        onConnectionChange()
      }
    } else {
      setLoading(false)
      setError("Failed to setup square integration, please contact support")
    }
  }

  const showSquareLocationSelect = (connectionId) => {
    modal.showModal(SquareLocationSelect, {
      onClose: () => {
        setLoading(false)
        if (!squareLocation) {
          setError(
            "You need to select your Square location to finish the integration"
          )
        }
      },
      connectionId: connectionId,
      onLocationSelected: (location) => {
        setSquareLocation(location)
        showSuccess("Square connection successful!")
        onConnectionChange()
        setLoading(false)
      },
    })
  }

  const confirmRemove = () => {
    setError("")
    if (id) {
      modal.showModal(ConfirmModal, {
        type: "danger",
        title: `Disconnect Square integration?`,
        text: "Are you sure you want to disconnect this integration?",
        confirmButtonText: "Disconnect",
        onConfirm: async () => {
          setCheckingConnection(true)
          const success = await removeSquareConnection(id)
          if (success) {
            showWarn("Square disconnected!")
            setConnectionId(false)
            setSquareLocation(false)
            setCheckingConnection(false)
            onConnectionChange()
          }
        },
      })
    }
  }

  const getActiveSquareConnections = async () => {
    setCheckingConnection(true)
    try {
      const connections = await getSquareConnections()
      /**
       * As we currently only have 1 connection per company we just get the first
       * But the Square location needs to be set
       *
       */
      if (connections.length > 0) {
        setConnectionId(connections[0].id)
        setSquareLocation(connections[0].location)
        if (!connections[0].location) {
          setError(
            "You need to select your Square location to finish the integration"
          )
        }
        setCheckingConnection(false)
      } else {
        setConnectionId(false)
        setSquareLocation(false)
        setCheckingConnection(false)

        // If no connection but callback is present, try to setup connection
        if (queryParams.callback === "square" && queryParams.code) {
          const csrf = getCSRFToken()

          // Validate CSRF
          if (queryParams.state && csrf && csrf === queryParams.state) {
            setLoading(true)
            setupSquare(queryParams.code)
            removeCSRFToken()
          } else {
            setError("Connection not allowed")
          }
        }
      }
    } catch {
      setCheckingConnection(false)
      setError("Could not load connection status, please try again later")
    }
  }

  useEffect(() => {
    getActiveSquareConnections()
  }, [])

  return checkingConnection ? null : connectionId && squareLocation ? (
    canDisconnect ? (
      <button
        className="text-primaryPink font-semibold font-sansSemiBold hover:bg-gray-50 px-3 bg-white border rounded border-gray-200 text-sm self-start text-left mr-3"
        style={{ height: "40px " }}
        onClick={confirmRemove}
      >
        Disconnect
      </button>
    ) : null
  ) : (
    <div className="inline-flex flex-col items-end mr-3">
      {canConnect && (
        <button
          style={{ height: "40px" }}
          className={` font-semibold font-sansSemiBold bg-white px-3 hover:bg-gray-50 border rounded border-gray-200 text-sm self-start text-left ${
            !squareLocation && connectionId
              ? "text-primaryPink"
              : "text-primaryGreen"
          }`}
          disabled={loading || checkingConnection}
          onClick={() => {
            if (!squareLocation && connectionId) {
              showSquareLocationSelect(connectionId)
            } else {
              startSquareAuthorization()
            }
          }}
        >
          {loading ? (
            <>
              <FontAwesomeIcon icon={faSpinnerThird} className="mr-2" spin />
              Connecting..
            </>
          ) : !squareLocation && connectionId ? (
            "Set location"
          ) : (
            <>
              Connect
              <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
            </>
          )}
        </button>
      )}
      {error && <span className="text-primaryPink text-xs">{error}</span>}
    </div>
  )
}

export default SquareIntegrationButton
