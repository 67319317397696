import React, { useState, useEffect, useRef, RefObject } from "react"
import { usePrevious } from "react-use"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import { faCartPlus } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { decimalCount } from "services/helpers"
import ReactTooltip from "react-tooltip"

interface Props {
  quantity: number
  allowFloat: boolean
  onChange: (amount: number | string) => void
  initialValue?: number | string
  disabled?: boolean
  tooltip?: string
}

const CartButton = ({
  quantity = 0,
  allowFloat = false,
  initialValue = 1,
  onChange,
  disabled,
  tooltip,
}: Props) => {
  const [amount, setAmount] = useState<number | string>(0)
  const previousAmount = usePrevious(amount)
  const numberInput: RefObject<any> = useRef(null)

  const shouldFloat = !Number.isInteger(Number(quantity))

  useEffect(() => {
    setAmount(quantity)
  }, [quantity])

  useEffect(() => {
    if (previousAmount !== undefined && amount !== undefined) {
      onChange(amount)
    }
  }, [amount])

  const hasInputFocus = () => {
    return document.activeElement === numberInput.current
  }

  const handleChange = (event) => {
    const rawValue = event.target.value
    const value = rawValue || rawValue === 0 ? parseInt(rawValue) : ""

    setAmount(allowFloat ? rawValue : value)
  }

  const onWheel = () => {
    numberInput.current.blur()
  }

  const handleKeyDown = (event) => {
    // prevent comma and dot keys
    // 190 = .
    // 188 = comma
    if ((event.keyCode === 190 && !allowFloat) || event.keyCode === 188) {
      event.preventDefault()
      return
    }

    // Escape key removes value
    if (event.keyCode === 27) {
      numberInput.current.blur()
      setAmount(0)
    }
  }

  return (
    <>
      {amount === 0 && !hasInputFocus() && (
        <div data-tip={tooltip} data-for={"cartButtonTooltip"}>
          <button
            type="button"
            className="button whitespace-nowrap button--smaller button--autoWidth button--primaryBlue"
            onClick={() => setAmount(initialValue)}
            disabled={disabled}
          >
            <FontAwesomeIcon icon={faCartPlus} />
            <span className="hidden lg:inline ml-3">Add</span>
          </button>
          {tooltip && (
            <ReactTooltip
              id={"cartButtonTooltip"}
              type="light"
              place="top"
              effect="solid"
              border={true}
              borderColor="#e2e8f0"
            />
          )}
        </div>
      )}
      {(amount > 0 || amount === "" || hasInputFocus()) && (
        <div
          className="input-container flex items-stretch"
          style={{ maxWidth: 100 }}
        >
          <input
            type="number"
            onKeyDown={handleKeyDown}
            onWheel={onWheel}
            ref={numberInput}
            min={allowFloat ? 0 : 1}
            step={
              allowFloat && shouldFloat
                ? decimalCount(amount) >= 2
                  ? 0.01
                  : 0.1
                : 1
            }
            onChange={handleChange}
            value={amount}
            className="form-control form-control--smaller form-control--no-margin form-control--topleft form-control--bottomleft"
          />
          <button
            type="button"
            style={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              marginLeft: "-2px",
              boxShadow: "none",
            }}
            className="button button--smaller button--primaryPink button--autoWidth no-truncate"
            onClick={() => {
              setAmount(0)
              onChange(amount)
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      )}
    </>
  )
}

export default CartButton
