/* eslint-disable react/prop-types */
import React, { useEffect, useContext } from "react"
import {
  deleteInvitation,
  inviteUser,
  removeUserFromOrganization,
} from "services/user"
import { userIsAdmin } from "services/auth"
import { getUserPermissions } from "services/user"
import BaseTable, { AutoResizer } from "react-base-table"
import { useMediaQuery } from "react-responsive"
import { format } from "date-fns"
import { showError, showSuccess } from "services/toast"
import { inviteStates } from "services/constants"
import Loader from "components/common/Loader/Loader"
import ItemStatus from "components/common/ItemStatus/ItemStatus"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import SupportTicketModal from "components/common/SupportTicketModal/SupportTicketModal"
import Pagination from "components/common/Pagination/Pagination"
import { ModalContext } from "context/ModalContext"
import { GlobalStateContext } from "context/GlobalContextProvider"
import UserAvatar from "components/common/UserAvatar/UserAvatar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import usePermissions from "hooks/usePermissions"
import { AsideContext } from "context/AsideContext"
import UserPermissionsForm from "components/auth/UserPermissionsForm/UserPermissionsForm"
import { Permission } from "services/types"
import { faUserTie } from "@fortawesome/pro-duotone-svg-icons"
import ActionsButton from "components/common/ActionsButton/ActionsButton"

import * as styles from "./Invites.module.css"

interface InvitesProps {
  invitesData: any
  getInvitesData(): void
  invitesPaginationData: any
  handleInvitesPageClick(): void
  loading: boolean
}

const Invites = ({
  invitesData,
  getInvitesData,
  invitesPaginationData,
  handleInvitesPageClick,
  loading,
}: InvitesProps) => {
  const modal = useContext(ModalContext)
  const { user } = useContext(GlobalStateContext)
  const aside = useContext(AsideContext)
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
  const companyPermissions = usePermissions("Company settings") as Permission

  const supportTeamMemberUsernames = ["support@goepos.com"]

  const columns = [
    {
      key: "username",
      title: "Email",
      dataKey: "username",
      width: 260,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => {
        return (
          <div className="flex">
            <UserAvatar user={rowData} />
            <div className="flex flex-col ml-2">
              <span className="font-semibold font-sansSemiBold text-sm text-primaryBlue">
                {rowData.firstName} {rowData.lastName}{" "}
                {rowData.orgCreator && (
                  <FontAwesomeIcon icon={faUserTie} className="ml-2" />
                )}
              </span>
              <span className="text-xs text-gray-700">{rowData.username}</span>
              {!rowData.active && rowData.invitationDate && (
                <span className="text-xs font-sansSemiBold font-semibold text-primaryBlue">
                  Invite sent at{" "}
                  {format(new Date(rowData.invitationDate), "dd/MM/yyyy")}
                </span>
              )}
            </div>
          </div>
        )
      },
    },
    {
      key: "role",
      title: "Role",
      dataKey: "role",
      width: 130,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => {
        return (
          <div className="flex">
            <span className="text-sm">
              {rowData.orgCreator ? "Account manager" : "Team member"}
            </span>
          </div>
        )
      },
    },
    {
      key: "active",
      title: "Active",
      dataKey: "active",
      width: 80,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => {
        const inviteState: { label: string; value: boolean; color: string } =
          inviteStates.filter((iS) => iS.value === rowData.active)[0]
        return (
          <ItemStatus style={{ fontSize: "0.9rem" }} status={inviteState} />
        )
      },
    },
    {
      key: "action",
      flexGrow: 0,
      flexShrink: 0,
      width: 220,
      className: "justify-end",
      cellRenderer: ({ rowData }) => {
        const options = getActionOptions(rowData)
        return (
          <>
            {options.length > 0 && (
              <ActionsButton
                options={options}
                onActionClick={(action) => handleActionClick(action, rowData)}
              />
            )}
          </>
        )
      },
    },
  ]

  useEffect(() => {
    getInvitesData()
  }, [])

  const getActionOptions = (data) => {
    if (data.orgCreator) {
      return []
    }

    const isSupportTeamMember = supportTeamMemberUsernames.includes(
      data.username
    )
    const options: Array<any> = []

    if (data.username !== user.username) {
      options.push({
        key: "edit",
        title: "Edit permissions",
        type: "default",
        disabled: !companyPermissions?.permissions.modify,
      })
    }

    if (data.active) {
      options.push({
        key: "delete",
        title: "Delete member",
        type: "danger",
        disabled:
          !companyPermissions?.permissions.delete || isSupportTeamMember,
      })
    } else {
      options.push(
        {
          key: "resend",
          title: "Re-send invite",
          type: "default",
        },
        {
          key: "delete-invite",
          title: "Delete invite",
          type: "danger",
          disabled:
            !companyPermissions?.permissions.delete || isSupportTeamMember,
        }
      )
    }

    return options
  }

  const handleActionClick = (action, data) => {
    switch (action.type) {
      case "edit":
        handleEditPermissions(data)
        break
      case "resend":
        handleResendInvitation(data.username)
        break
      case "delete":
        handleRemoveUser(data.username)
        break
      case "delete-invite":
        handleDeleteInvitation(data.username)
        break
      default:
        break
    }
  }

  const handleResendInvitation = (username) => {
    getUserPermissions(username, {}).then((permissions) => {
      if (permissions.status === 404) {
        showError(permissions.message || "Could not resend invitation.")
      } else {
        const params = {
          username: username,
          permissionsPerFeatures: permissions.permissionsPerFeatures.map(
            (feature) => {
              return {
                id: feature.id,
                permissions: feature.permissions,
              }
            }
          ),
        }

        inviteUser(params).then((res) => {
          if (!res.error) {
            showSuccess(
              `A new invitation email has been sent to ${params.username}`
            )
          } else {
            showError(
              res.message || "Invite failed. Did you enter all required fields?"
            )
          }
        })
      }
    })
  }

  const handleDeleteInvitation = (data) => {
    // @ts-ignore
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: "Delete user invite",
      text: "Are you sure you want to delete this user invite?",
      confirmButtonText: "Delete",
      onConfirm: () => {
        const param = encodeURIComponent(data)
        deleteInvitation(param)
          .then((res) => {
            if (res === true) {
              showSuccess(`${data} has been deleted from invites`)
              getInvitesData()
            } else {
              showError(res.message)
            }
          })
          .catch((err) => {
            showError(err.message || "Delete failed")
          })
      },
    })
  }

  const handleRemoveUser = async (data) => {
    const isAdmin = await userIsAdmin()
    if (isAdmin) {
      // @ts-ignore
      modal.showModal(ConfirmModal, {
        type: "danger",
        title: "Remove user",
        text: "Are you sure you want to remove this user from this company?",
        confirmButtonText: "Confirm",
        onConfirm: () => {
          const param = encodeURIComponent(data)
          removeUserFromOrganization(param)
            .then((res) => {
              if (res === true) {
                showSuccess(`${data} has been removed`)
                getInvitesData()
              } else {
                showError(res.message)
              }
            })
            .catch((err) => {
              showError(err.message || "Remove failed")
            })
        },
      })
    } else {
      // @ts-ignore
      modal.showModal(SupportTicketModal, { subject: `Remove user ${data}` })
    }
  }

  const handleEditPermissions = (user) => {
    // @ts-ignore
    aside.showAside(UserPermissionsForm, {
      user: user,
      headerText: "User permissions",
    })
  }

  return (
    <div className={styles.container}>
      <div className="w-full mt-4">
        <Loader
          isLoading={loading}
          style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
        >
          Loading..
        </Loader>
        <div className="w-full flex-grow">
          <AutoResizer height={Infinity}>
            {({ width }) => (
              <BaseTable
                fixed={isTabletOrMobile ? true : false}
                rowHeight={70}
                width={width}
                maxHeight={
                  invitesData.content.length === 0 ? undefined : Infinity
                }
                height={invitesData.content.length === 0 ? 150 : undefined}
                data={invitesData.content}
                columns={columns}
                emptyRenderer={
                  <div className={styles.noInvites}>No invites found</div>
                }
              />
            )}
          </AutoResizer>
        </div>
        {invitesData.content.length > 0 && (
          <Pagination
            data={invitesPaginationData}
            onPageClick={handleInvitesPageClick}
          />
        )}
      </div>
    </div>
  )
}

export default Invites
