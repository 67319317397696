import React from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import { Field } from "formik"

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#FC3762" : "#ffffff",
    ":hover": {
      ...provided[":hover"],
      backgroundColor: state.isSelected ? "#FC3762" : "#f7fafc",
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 4,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "2px calc(1rem - 2px)",
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    borderColor: state.isFocused ? "#FC3762" : "#e2e8f0",
    boxShadow: "none",
    zIndex: state.isFocused ? 2 : null,
    minHeight: 45,
    ":hover": {
      ...provided[":hover"],
      borderColor: state.isFocused ? "#FC3762" : "#e2e8f0",
    },
  }),
}

const CustomSelect = ({
  name,
  label,
  options,
  onSelectCallback = (_) => {},
  showValidations = true,
  isSearchable = false,
  ...props
}) => (
  <Field name={name}>
    {({ field, form, meta }) => {
      // Next line is VERY important (please do not remove)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { onChange, onBlur, value, ...restField } = field

      const handleChange = (e) => {
        form.setFieldValue(name, e ? e.value : null)
        if (onSelectCallback) {
          onSelectCallback(e)
        }
      }

      const handleBlur = () => form.setFieldTouched(name, true)

      const isTouched = meta.touched
      const error = meta.error
      const getValue = (opts, value) => {
        const isgrouped = opts[0]?.options
        if (isgrouped) {
          let optList = []
          for (let i = 0; i < opts.length; i++) {
            optList = optList.concat(opts[i].options)
          }
          return optList.find((o) => o.value === value)
        } else {
          return opts.find((o) => o.value === value)
        }
      }

      return (
        <>
          <label className="sr-only" htmlFor={name}>
            {label}
          </label>
          <Select
            id={name}
            name={name}
            options={options}
            isSearchable={isSearchable}
            styles={{
              ...customStyles,
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              menu: (provided) => ({ ...provided, zIndex: "9999 !important" }),
            }}
            menuPosition={"absolute"}
            menuPortalTarget={document.querySelector("body")}
            value={getValue(options, props.defaultValue || value) || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            {...restField}
            {...props}
          />
          {isTouched && error && showValidations && (
            <span className="block border -mb-px px-4 py-2 text-xs bg-red-100 text-primaryPink">
              {error}
            </span>
          )}
        </>
      )
    }}
  </Field>
)

CustomSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  onSelectCallback: PropTypes.func,
  showValidations: PropTypes.bool,
  defaultValue: PropTypes.any,
  isSearchable: PropTypes.bool,
}

export default CustomSelect
