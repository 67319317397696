import Pagination from "components/common/Pagination/Pagination"
import React from "react"
import { paginationProps } from "./hooks/usePagination"

interface withPaginationProps {
  data: any
  pagination: paginationProps
}
const withPagination = (props: withPaginationProps) => {
  if (props.pagination && props?.data?.length > 0) {
    return (
      <div className="mb-4">
        <Pagination
          data={props.pagination}
          onPageClick={({ selected }) => props.pagination.goto(selected)}
        />
      </div>
    )
  } else {
    return <></>
  }
}
export default withPagination
