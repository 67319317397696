import React, { useContext } from "react"

import { navigate } from "@reach/router"
import { formatAsYearMonthDay } from "services/helpers"
import { orderStates } from "services/constants"
import { faClock } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ItemStatus from "components/common/ItemStatus/ItemStatus"
import { GlobalStateContext } from "context/GlobalContextProvider"
import { faArrowRight } from "@fortawesome/pro-light-svg-icons"

const OrderItemState = ({ order }) => {
  const { user } = useContext(GlobalStateContext)

  const { approvers } = order || {}

  const userIsApprover =
    user &&
    approvers?.findIndex((approver) => approver.username === user.username) !==
      -1

  const orderRequiresApproval = order.status === "PENDING_APPROVAL"

  const userCanApprovePendingOrder = userIsApprover && orderRequiresApproval

  const getStateDate = (order) => {
    let date
    switch (order.status) {
      case "PLACED":
        date = order.placedDate
        break
      case "DRAFT":
        date = order.createdDate
        break
      case "PENDING_APPROVAL":
        date = order.createdDate
        break
      case "REVIEW":
        date = order.placedDate ?? order.createdDate
        break
      case "COMPLETED":
        date = order.completedDate
        break
      case "CANCELED":
        date = order.canceledDate
        break
      default:
        break
    }
    return date
  }

  const orderState = orderStates.find((oS) => oS.value === order.status)
  const stateDate = getStateDate(order)
  return (
    <div className="w-auto">
      {orderState && (
        <ItemStatus status={orderState}>
          {userCanApprovePendingOrder ? (
            <button
              onClick={() => navigate(`orders/${order.id}?step=review`)}
              className="button button--smallest button--autoWidth no-truncate button--primaryPink text-xs my-1"
            >
              View order{" "}
              <FontAwesomeIcon icon={faArrowRight} className="ml-1" />
            </button>
          ) : (
            stateDate && (
              <span className="text-xs text-gray-700">
                <FontAwesomeIcon icon={faClock} size="sm" />{" "}
                {formatAsYearMonthDay(new Date(stateDate))}
              </span>
            )
          )}
        </ItemStatus>
      )}
    </div>
  )
}

export default OrderItemState
