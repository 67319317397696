import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircle } from "@fortawesome/pro-solid-svg-icons"
import { Recipe } from "services/dishes"
import RecipeIcon from "../RecipeIcon/RecipeIcon"
import { productCategories } from "services/constants"
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons"
import ReactTooltip from "react-tooltip"

interface RecipeItemNameProps {
  recipe: Recipe
  permissionObj?: any
  handleActionClick?(config: {}, recipe: Recipe): void
}

const RecipeItemName = ({
  recipe,
  permissionObj,
  handleActionClick,
}: RecipeItemNameProps) => {
  const matchedCat = productCategories.find((c) => c.value === recipe.category)
  return (
    <div className="pl-1 flex w-auto items-center h-14">
      {recipe.hasDeletedIngredients && (
        <>
          <span
            data-for="deleted_product_tooltip"
            data-tip="This recipe contains deleted ingredients."
          >
            <FontAwesomeIcon
              icon={faExclamationCircle}
              size="lg"
              className="text-error mr-2"
            />
          </span>

          <ReactTooltip
            id={"deleted_product_tooltip"}
            type="light"
            place="right"
            effect="float"
            border={true}
            borderColor="#e2e8f0"
          />
        </>
      )}

      <RecipeIcon
        recipe={recipe}
        onClick={(e) => {
          if (!permissionObj?.permissions.read) {
            return
          }
          e.preventDefault()
          handleActionClick &&
            handleActionClick({ type: "recipe.view" }, recipe)
        }}
        disabled={!permissionObj?.permissions.read}
      />

      <button
        onClick={(e) => {
          if (!permissionObj?.permissions.read) {
            return
          }
          e.preventDefault()
          handleActionClick &&
            handleActionClick({ type: "recipe.view" }, recipe)
        }}
        style={{
          minWidth: "200px",
          textAlign: "left",
        }}
        className=" focus:outline-none  py-3 px-3 flex flex-col"
      >
        <span className="break-all text-sm font-semibold font-sansSemiBold text-primaryBlue">
          {recipe.name}
        </span>
        <span className="flex items-center text-xs text-gray-700">
          <span className="mr-1">{matchedCat?.label}</span>
          {recipe.posId && (
            <FontAwesomeIcon
              icon={faCircle}
              className="text-gray-500 mr-1 text-xs"
              style={{ fontSize: "0.3em" }}
            />
          )}
          <span>{recipe.posId}</span>
        </span>
      </button>
    </div>
  )
}

export default RecipeItemName
