import React, { useContext, useState } from "react"
import { navigate } from "@reach/router"
import { stockTakeStates } from "services/constants"
import {
  deleteStockTake,
  completeStockTake,
  mergeStockTake,
  createDiscrepancyReport,
  downloadStockTakeReport,
} from "services/stock-take"
import { toast } from "react-toastify"
import { isValid } from "date-fns"
import { showSuccess, showError, showWarn } from "services/toast"
import {
  roundNumber,
  slugify,
  uuidv4,
  convertToGMT,
  convertTimeStampFormat,
} from "services/helpers"
import { faChevronDown, faPencil } from "@fortawesome/pro-regular-svg-icons"
import { faSyncAlt } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ItemStatus from "components/common/ItemStatus/ItemStatus"
import ActionsButton from "components/common/ActionsButton/ActionsButton"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import StockTake from "components/stock/StockTake/StockTake"
import PropTypes from "prop-types"
import { ModalContext } from "context/ModalContext"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/GlobalContextProvider"
import usePermissions from "hooks/usePermissions"

import * as styles from "./StockTakeItem.module.css"
import { getOrgNameById } from "services/user"

const StockTakeItem = ({
  stocktake,
  onDeleted,
  onUpdated,
  stockTakeOptionsClose,
}) => {
  const modal = useContext(ModalContext)
  const dispatch = useContext(GlobalDispatchContext)
  const [collapse, setCollapse] = useState(false)
  const toastId = React.useRef(null)
  const { organization, organizations, organizationPermissions } =
    useContext(GlobalStateContext)
  const permissionObj = usePermissions("Stocktakes")

  const rowState = (rowData) => {
    const stState = stockTakeStates.find(
      (oS) =>
        oS.label ===
        (rowData.stockTakeReport.status === "COMPLETED"
          ? "Finalised"
          : "In progress")
    )
    return stState && <ItemStatus status={stState} />
  }

  const removeStockTake = async (stocktake) => {
    const deleted = await deleteStockTake(stocktake.stockTakeReport.id)
    if (deleted && !deleted.message) {
      showSuccess("Stock take deleted!")
      onDeleted()
    } else {
      showError("Delete failed, please try again later")
    }
  }

  const fireComplete = async (stocktake) => {
    const completed = await completeStockTake(stocktake.stockTakeReport.id)
    if (completed && !completed.message) {
      showSuccess("Stock take completed!")
      onUpdated()
    } else {
      showError("Complete failed, please try again later")
    }
  }

  const fireFinalise = async (stocktake) => {
    const finalised = await mergeStockTake(
      stocktake.stockTakeReport.id,
      convertToGMT(new Date())
    )
    if (finalised && !finalised.message) {
      showSuccess("Stock take finalized!")
      onUpdated()
    } else {
      showError("Finalise failed, please try again later")
    }
  }

  const onEdit = (stocktake) => {
    dispatch({ type: "RESET_NEW_STOCKTAKE" })

    const params = {
      id: stocktake.stockTakeReport.id,
      name: stocktake.stockTakeReport.name,
      createdAt: stocktake.stockTakeReport.createdAt,
      completedAt: stocktake.stockTakeReport.completedAt,
      note: stocktake.stockTakeReport.note,
      status: stocktake.stockTakeReport.status,
      totalAmount: stocktake.stockTakeReport.totalAmount,
      totalDiscrepancyAmount: stocktake.totalDiscrepancyAmount,
      discrepancyReportId: stocktake.discrepancyReportId,
      key: uuidv4(),
      unsynced: false,
      products: [],
      recipes: [],
      subStockTakeReports: [],
    }
    dispatch({
      type: "CREATE_NEW_STOCKTAKE",
      payload: {
        stockTakeData: params,
      },
    })

    for (
      let i = 0;
      i < stocktake.stockTakeReport.subStockTakeReports.length;
      i++
    ) {
      const subRep = stocktake.stockTakeReport.subStockTakeReports[i]

      dispatch({
        type: "CREATE_NEW_STOCKTAKE_SUBREPORT",
        payload: {
          stockTakeData: {
            ...subRep,
            key: uuidv4(),
            products: [],
            recipes: [],
            unsynced: false,
          },
        },
      })
    }
    return modal.showModal(StockTake, { onUpdate: () => onUpdated() })
  }

  const onDelete = (stocktake) =>
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: `Delete ${stocktake.stockTakeReport.name || "Stocktake"}`,
      text: "Are you sure you want to delete this stocktake? Once deleted, there's no going back and cannot be undone!",
      confirmButtonText: "Delete",
      onConfirm: () => removeStockTake(stocktake),
    })

  const onComplete = (stocktake) =>
    modal.showModal(ConfirmModal, {
      type: "success",
      title: `Complete ${stocktake.stockTakeReport.name || "Stocktake"}`,
      text: "Are you sure you want to complete this stocktake?",
      confirmButtonText: "Complete",
      onConfirm: () => fireComplete(stocktake),
    })

  const onFinalise = (stocktake) =>
    modal.showModal(ConfirmModal, {
      type: "success",
      title: `Finalise ${stocktake.stockTakeReport.name || "Stocktake"}`,
      text: "Are you sure you want to finalise this stocktake? All subgroups will be merged into one single list",
      confirmButtonText: "Finalise",
      onConfirm: () => fireFinalise(stocktake),
    })

  const onCreateDiscrepancyReport = async (stocktake) => {
    toastId.current = showWarn("Generating discrepancy report", {
      position: "top-center",
      autoClose: false,
      draggable: false,
      closeButton: false,
      closeOnClick: false,
      hideProgressBar: false,
    })
    const created = await createDiscrepancyReport(stocktake.stockTakeReport.id)

    // Previous stocktake = required to generate a discrepancy report
    if (created.status === 404) {
      toast.dismiss(toastId.current)
      return showError(
        "Could not generate a report because there was no earlier one",
        { position: "top-center" }
      )
    }

    if (created.status === 400) {
      toast.dismiss(toastId.current)
      showError("Something went wrong!", { position: "top-center" })
    }

    if (created.id) {
      toast.dismiss(toastId.current)
      showSuccess("Report generated!", { position: "top-center" })
      return navigate(
        `/dashboard/stock/stocktake/discrepancy-report/${created.id}`
      )
    }

    toast.dismiss(toastId.current)
  }

  const onExportStocktake = (stocktake, fileType) => {
    const fileName = slugify(stocktake.stockTakeReport.name)
    downloadStockTakeReport(stocktake.stockTakeReport.id, fileType, fileName)
  }

  const handleActionClick = (action, stocktake) => {
    const { type } = action
    switch (type) {
      case "stocktake.edit":
        onEdit(stocktake)
        break
      case "stocktake.delete":
        onDelete(stocktake)
        break
      case "stocktake.complete":
        onComplete(stocktake)
        break
      case "stocktake.merge":
        onFinalise(stocktake)
        break
      case "stocktake.view_discrepancy_report":
        navigate(
          `/dashboard/stock/stocktake/discrepancy-report/${stocktake.discrepancyReportId}`
        )
        break
      case "stocktake.create_discrepancy_report":
        onCreateDiscrepancyReport(stocktake)
        break
      case "stocktake.export_csv":
        onExportStocktake(stocktake, "csv")
        break
      case "stocktake.export_pdf":
        onExportStocktake(stocktake, "pdf")
        break
      case "stocktake.export_xlsx":
        onExportStocktake(stocktake, "xlsx")
        break
      default:
        break
    }
  }

  const handleEdit = (e) => {
    e.preventDefault()
    if (stockTakeOptionsClose) {
      stockTakeOptionsClose()
    }
    handleActionClick({ type: "stocktake.edit" }, stocktake)
  }

  /**
   * Action used for both Edit and Re-order
   * @param  {Object} rowData current o rder
   * @return {Function}         shows action buttons
   */
  const actions = (rowData) => {
    const subStockTakesInProgress =
      rowData.stockTakeReport.subStockTakeReports &&
      rowData.stockTakeReport.subStockTakeReports.filter(
        (st) => st.status === "IN_PROGRESS"
      ).length

    let options = []

    if (rowData.stockTakeReport.status !== "COMPLETED") {
      options = [
        ...options,
        {
          key: "stocktake.edit",
          title: "Edit",
          disabled: !permissionObj?.permissions.modify,
        },
        {
          key: "stocktake.delete",
          title: "Delete",
          type: "danger",
          disabled: !permissionObj?.permissions.delete,
        },
      ]
    }

    // Show merge option if no parent and sub stocktakes are in progress and all is saved
    if (
      !subStockTakesInProgress &&
      rowData.stockTakeReport.status === "IN_PROGRESS"
    ) {
      options = [
        ...options,
        {
          key: "stocktake.merge",
          title: "Finalise",
          type: "success",
          disabled: !permissionObj?.permissions.modify,
        },
      ]
    }

    if (rowData.stockTakeReport.status === "COMPLETED") {
      const hasDReport = rowData.discrepancyReportId
      options = [
        ...options,
        {
          key: "stocktake.edit",
          title: "Edit",
          disabled: !permissionObj?.permissions.modify,
        },
        {
          key: "stocktake.delete",
          title: "Delete",
          type: "danger",
          disabled: !permissionObj?.permissions.delete,
        },
        {
          key: hasDReport
            ? "stocktake.view_discrepancy_report"
            : "stocktake.create_discrepancy_report",
          title: hasDReport
            ? "View discrepancy report"
            : "Run discrepancy report",
          type: "warning",
          disabled:
            (!hasDReport && !permissionObj?.permissions.modify) ||
            (hasDReport && !permissionObj?.permissions.read),
        },
        {
          key: "stocktake.export_csv",
          title: "Export to .CSV",
          disabled: !permissionObj?.permissions.read,
        },
        {
          key: "stocktake.export_xlsx",
          title: "Export to .XLSX",
          disabled: !permissionObj?.permissions.read,
        },
        // { key: "stocktake.export_pdf", title: "Export to .PDF" },
      ]
    }

    return (
      <div className="ml-auto px-3 flex-shrink-0 text-right">
        {rowData?.stockTakeReport.organizations.length &&
          rowData?.stockTakeReport.organizations[0] === organization?.id && (
            <ActionsButton
              options={options}
              onActionClick={(action) => handleActionClick(action, rowData)}
            />
          )}
      </div>
    )
  }

  return (
    <div
      className="border -mb-px"
      style={{ backgroundColor: collapse ? "#F8F8FF" : "#FFFFFF" }}
    >
      <div className="flex flex-wrap items-center content-center justify-between">
        <div className="lg:pl-3 flex items-center">
          <button
            type="button"
            className="button--smaller button--autoWidth outline-none focus:outline-none"
            onClick={(e) => {
              e.preventDefault()
              setCollapse(!collapse)
            }}
          >
            <FontAwesomeIcon
              icon={faChevronDown}
              rotation={collapse ? 180 : 0}
            />
          </button>
          <button
            onClick={(e) => {
              handleEdit(e)
            }}
            className={styles.imageWrapper}
          >
            <div
              className={styles.iconWrapper}
              style={{
                backgroundColor: "#E0FFFF",
                color: "#35CCC3",
              }}
            >
              <FontAwesomeIcon icon={faSyncAlt} />
            </div>
          </button>

          <button
            onClick={(e) => {
              handleEdit(e)
            }}
            style={{ minWidth: "300px", maxWidth: "300px", textAlign: "left" }}
            className="font-sansSemiBold focus:outline-none font-semibold text-primaryBlue py-3 px-3 flex flex-col"
          >
            <span>{stocktake.stockTakeReport.name || "New stocktake"}</span>
            <div className="flex items-center">
              {stocktake.stockTakeReport.completedAt
                ? stocktake.stockTakeReport.completedAt &&
                  isValid(new Date(stocktake.stockTakeReport.completedAt)) && (
                    <span className="text-gray-700 text-sm font-sansSemiBold font-semibold flex items-center">
                      <span className="mr-1">Finalised:</span>{" "}
                      {convertTimeStampFormat(
                        stocktake.stockTakeReport.completedAt,
                        organization?.address.zoneId
                      )}
                    </span>
                  )
                : isValid(new Date(stocktake.stockTakeReport.createdAt)) && (
                    <span className="text-gray-700 text-sm font-sansSemiBold font-semibold flex items-center">
                      <span className="mr-1">Created:</span>{" "}
                      {convertTimeStampFormat(
                        stocktake.stockTakeReport.createdAt,
                        organization?.address.zoneId
                      )}
                    </span>
                  )}{" "}
            </div>
          </button>
        </div>

        {organizationPermissions?.general?.isMain && (
          <div
            className="flex-col p-3 flex"
            style={{ minWidth: "175px", maxWidth: "175px" }}
          >
            <span className="text-sm text-gray-700">Location/Area</span>
            <span>
              {getOrgNameById(
                stocktake.stockTakeReport.organizations[0],
                organizations
              )}
            </span>
          </div>
        )}

        {!stockTakeOptionsClose && rowState(stocktake)}
        <div className="flex flex-col p-3" style={{ minWidth: "150px" }}>
          <span className="text-sm text-gray-700">Total value</span>
          <span className="font-sansSemiBold font-semibold">
            {roundNumber(stocktake.stockTakeReport.totalAmount)}
          </span>
        </div>
        {!stockTakeOptionsClose && (
          <div className="flex flex-col p-3" style={{ minWidth: "150px" }}>
            <span className="text-sm text-gray-700">Discrepancy value</span>
            {stocktake.discrepancyReportId ? (
              <button
                className="font-sansSemiBold font-semibold text-left"
                onClick={() =>
                  handleActionClick(
                    { type: "stocktake.view_discrepancy_report" },
                    stocktake
                  )
                }
              >
                {stocktake.discrepancyReportId &&
                stocktake.totalDiscrepancyAmount !== null ? (
                  <span
                    className={`${
                      stocktake.totalDiscrepancyAmount > 0
                        ? "text-primaryGreen"
                        : stocktake.totalDiscrepancyAmount === 0
                        ? "text-gray-800"
                        : "text-primaryPink"
                    }`}
                  >
                    {roundNumber(stocktake.totalDiscrepancyAmount)}
                  </span>
                ) : (
                  "-"
                )}
              </button>
            ) : (
              <span>-</span>
            )}
          </div>
        )}
        {!stockTakeOptionsClose ? (
          actions(stocktake)
        ) : (
          <button
            className="button button--autoWidth button--lightGray mr-3"
            onClick={(e) => {
              handleEdit(e)
            }}
          >
            <FontAwesomeIcon icon={faPencil} className="mr-2" />
            <span>Join / Edit</span>
          </button>
        )}
      </div>
      {collapse && (
        <div className="w-full pl-3 lg:pl-20 lg:pr-8 lg:pb-8 -mb-px">
          {stocktake.stockTakeReport.subStockTakeReports.map((st, index) => {
            const state = stockTakeStates.find((oS) => oS.value === st.status)
            return (
              <div className="flex flex-wrap items-center border-b" key={index}>
                {state && (
                  <span
                    className={styles.statusBulb}
                    style={{
                      backgroundColor: state.color,
                      width: "12px",
                      height: "12px",
                    }}
                  ></span>
                )}
                <div
                  className="flex py-2 flex-col self-center items-start text-left mr-8"
                  style={{ width: "250px" }}
                >
                  <span className="text-primaryBlue font-semibold font-sansSemiBold mb-1 flex items-center">
                    {st.name}
                  </span>
                  {stocktake.products && (
                    <span className="text-gray-700 text-sm">
                      {st.products.length}{" "}
                      {st.products.length !== 1 ? "items" : "item"}
                    </span>
                  )}
                </div>
                {/* mr-16 */}
                <div
                  className="flex py-2 text-sm self-center items-start text-left mr-8"
                  style={{ minWidth: "150px" }}
                >
                  <span className="text-gray-700 mr-3">Total value</span>
                  <strong>{roundNumber(st.totalAmount)}</strong>
                </div>
                <div className="flex py-2 text-sm self-center items-start text-left">
                  <span className="text-gray-700 mr-3">Status</span>
                  <strong>
                    {state.value === "COMPLETED" ? "Completed" : "In progress"}
                  </strong>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

StockTakeItem.propTypes = {
  stocktake: PropTypes.object,
  onDeleted: PropTypes.func,
  onUpdated: PropTypes.func,
  stockTakeOptionsClose: PropTypes.func,
}

export default StockTakeItem
