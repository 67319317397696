import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import ReactTooltip from "react-tooltip"
import { uuidv4 } from "services/helpers"
import * as styles from "./SimpleTable.module.css"
interface props {
  props: Array<any>
  style?: Object
  cellStyle?: Object
  isDeletedProduct?: Array<boolean | null>
}
export default ({ props, style, cellStyle, isDeletedProduct }: props) => {
  const tableID = uuidv4()

  if (props.length > 0) {
    return (
      <table style={style} className={styles.table}>
        <thead>
          <tr>
            {Object.keys(props[0]).map((name) => (
              <th key={`${tableID}-col-${name}`}>
                <span className={styles.label}>{name}</span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.map((row, i) => (
            <tr key={`${tableID}-row-${i}`}>
              {Object.keys(row).map((name) => (
                <td
                  key={`${tableID}-${row[name]}`}
                  style={cellStyle}
                  {...(isDeletedProduct && isDeletedProduct[i]
                    ? { className: "bg-error bg-opacity-5" }
                    : {})}
                >
                  {isDeletedProduct &&
                    isDeletedProduct[i] &&
                    name === "Name" && (
                      <>
                        <span
                          data-for="deleted_ingredient_tooltip"
                          data-tip="This ingredient is deleted."
                        >
                          <FontAwesomeIcon
                            icon={faExclamationCircle}
                            size="lg"
                            className="text-error mr-2"
                          />
                        </span>

                        <ReactTooltip
                          id={"deleted_ingredient_tooltip"}
                          type="light"
                          place="right"
                          effect="float"
                          border={true}
                          borderColor="#e2e8f0"
                        />
                      </>
                    )}

                  <span className={styles.label}>{name}</span>
                  {row[name]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    )
  } else {
    return <div>No results</div>
  }
}
