import React, { useContext } from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import { ModalContext } from "context/ModalContext"

import {
  formatCurrencyValue,
  getFileNameDateTime,
  roundNumber,
} from "services/helpers"
import { deleteGPReport, exportGPReport } from "services/gp-report"
import { showSuccess, showError } from "services/toast"

import { format } from "date-fns"

import { faChartBar } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import ActionsButton from "components/common/ActionsButton/ActionsButton"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import classNames from "classnames/bind"

import * as styles from "./GPReportItem.module.css"
import usePermissions from "hooks/usePermissions"
import { GlobalStateContext } from "context/GlobalContextProvider"
import { getOrgNameById } from "services/user"

const cx = classNames.bind(styles)

const GPReportItem = ({ item, onDeleted }) => {
  const { organization, organizations, organizationPermissions } =
    useContext(GlobalStateContext)
  const modal = useContext(ModalContext)
  const currency = null
  const permissionObj = usePermissions("GP Analysis")

  const onDelete = (data) => {
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: "Delete GP analysis",
      text: "Are you sure you want to delete this GP analysis?",
      confirmButtonText: "Delete",
      onConfirm: () => handleDeleteGPReport(data),
    })
  }

  const handleDeleteGPReport = async (data) => {
    const deleted = await deleteGPReport(data.id)
    if (deleted && !deleted.message) {
      showSuccess("Report deleted!")
      onDeleted()
    } else {
      showError("Delete failed, please try again later")
    }
  }

  const handleActionClick = (action, data) => {
    const { type } = action
    const fileDatetime = getFileNameDateTime()

    switch (type) {
      case "gp.edit":
        navigate(`/dashboard/insights/gp-analysis/${data.id}`)
        break
      case "gp.delete":
        onDelete(data)
        break
      case "gp.exportSummary":
        exportGPReport(
          data.id,
          `gp-analysis-summary-${fileDatetime}`,
          "csv",
          {},
          "/summarised"
        )
        break
      case "gp.exportFull":
        exportGPReport(
          data.id,
          `gp-analysis-full-${fileDatetime}`,
          "csv",
          {},
          "/itemised"
        )
        break
      default:
        break
    }
  }

  const actions = (data) => {
    const options = [
      {
        key: "gp.edit",
        title: "View report",
        disabled: !permissionObj?.permissions.read,
      },
      {
        key: "gp.delete",
        title: "Delete",
        type: "danger",
        disabled: !permissionObj?.permissions.delete,
      },
      {
        key: "gp.exportSummary",
        title: "Export summary (csv)",
        disabled: !permissionObj?.permissions.read,
      },
      {
        key: "gp.exportFull",
        title: "Export full report (csv)",
        disabled: !permissionObj?.permissions.read,
      },
    ]

    return (
      <div className="px-3 flex-shrink-0" style={{ textAlign: "right" }}>
        {data?.organizations.length &&
          data?.organizations[0] === organization?.id && (
            <ActionsButton
              options={options}
              onActionClick={(action) => handleActionClick(action, data)}
            />
          )}
      </div>
    )
  }

  return (
    <div className="border m-4 lg:m-0">
      <div
        className={`flex items-center content-center justify-between h-full`}
      >
        <div className="flex flex-wrap p-2 w-full">
          <div className="pl-3 flex items-center cursor-pointer">
            <button
              type="button"
              disabled={!permissionObj?.permissions.read}
              onClick={() =>
                navigate(`/dashboard/insights/gp-analysis/${item.id}`)
              }
              className={`${styles.imageWrapper} mr-4`}
            >
              <div
                className={styles.iconWrapper}
                style={{
                  backgroundColor: "#E0FFFF",
                  color: "#35CCC3",
                }}
              >
                <FontAwesomeIcon icon={faChartBar} />
              </div>
            </button>
          </div>
          <div className="flex flex-col p-3" style={{ minWidth: "150px" }}>
            <span className="text-sm text-gray-700">Period from</span>
            <span className="font-sansSemiBold font-semibold">
              {format(new Date(item.periodFrom), "dd/MM/yyyy")}
            </span>
          </div>

          <div className="flex flex-col p-3" style={{ minWidth: "150px" }}>
            <span className="text-sm text-gray-700">Period to</span>
            <span className="font-sansSemiBold font-semibold">
              {format(new Date(item.periodTo), "dd/MM/yyyy")}
            </span>
          </div>

          {organizationPermissions?.general?.isMain && (
            <div className="flex flex-col p-3" style={{ minWidth: "200px" }}>
              <span className="text-sm text-gray-700">Location/Area</span>
              <span className="text-sm font-sansSemiBold font-semibold flex items-center">
                {item.organizations.length &&
                  getOrgNameById(item.organizations[0], organizations)}
              </span>
            </div>
          )}

          <div className="flex flex-col p-3" style={{ minWidth: "150px" }}>
            <span className="text-sm text-gray-700">Sales</span>
            <span className="text-sm font-sansSemiBold font-semibold text-gray-700 flex items-center">
              {`${
                currency
                  ? formatCurrencyValue(item.totals.totalSoldValue)
                  : roundNumber(item.totals.totalSoldValue)
              }`}
            </span>
          </div>

          <div className="flex flex-col p-3" style={{ minWidth: "150px" }}>
            <span className="text-sm text-gray-700">Expected GP</span>
            <div className="flex items-center text-sm ">
              <span className="font-sansSemiBold font-semibold text-gray-700 flex items-center">
                <span className={cx("indicator", "indicatorExpected")}></span>
                {`${
                  currency
                    ? formatCurrencyValue(item.totals.totalExpectedGP)
                    : roundNumber(item.totals.totalExpectedGP)
                }`}
              </span>
              <span className="text-gray-500 ml-1">
                {roundNumber(item.totals.totalExpectedGPPercent)}%
              </span>
            </div>
          </div>
          <div className="flex flex-col p-3" style={{ minWidth: "150px" }}>
            <span className="text-sm text-gray-700">Actual GP</span>
            <div className="flex items-center text-sm ">
              <span className="text-sm font-sansSemiBold font-semibold text-gray-700 flex items-center">
                <span className={cx("indicator", "indicatorActual")}></span>
                {`${
                  currency
                    ? formatCurrencyValue(item.totals.totalActualGP)
                    : roundNumber(item.totals.totalActualGP)
                }`}
              </span>
              <span className="text-gray-500 ml-1">
                {roundNumber(item.totals.totalActualGPPercent)}%
              </span>
            </div>
          </div>
          <div className="flex flex-col p-3" style={{ minWidth: "150px" }}>
            <span className="text-sm text-gray-700">Variance</span>
            <div className="flex items-center text-sm ">
              <span className="text-sm font-sansSemiBold font-semibold text-gray-700 flex items-center">
                <span className={cx("indicator", "indicatorVariance")}></span>
                {`${
                  currency
                    ? formatCurrencyValue(item.totals.totalVarianceGP)
                    : roundNumber(item.totals.totalVarianceGP)
                }`}
              </span>

              <span className="text-gray-500 ml-1">
                {roundNumber(item.totals.totalVarianceGPPercent)}%
              </span>
            </div>
          </div>

          <div className="flex flex-1 justify-end items-center">
            {actions(item)}
          </div>
        </div>
      </div>
    </div>
  )
}

GPReportItem.propTypes = {
  item: PropTypes.object,
  onDeleted: PropTypes.func,
}

export default GPReportItem
