import React from "react"
import debounce from "debounce-promise"

import AsyncSelect from "react-select/async"
import AsyncCreatableSelect from "react-select/async-creatable"

const dot = (color = "#ccc") => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 10,
    width: 10,
  },
})

const customStyles = (creatable = false) => {
  return {
    option: (provided, state) => ({
      ...provided,
      ...(state.data.color ? dot(state.data.color) : {}),
      backgroundColor: state.isSelected ? "#FC3762" : "#ffffff",
      ":hover": {
        ...provided[":hover"],
        backgroundColor: state.isSelected ? "#FC3762" : "#f7fafc",
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 5,
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 10,
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "2px calc(1rem - 2px)",
    }),
    control: (provided, state) => {
      return {
        ...provided,
        borderRadius: "0.25rem",
        borderColor: state.isFocused ? "#FC3762" : "#e2e8f0",
        backgroundColor: creatable ? "#FFFFFF" : "#F8F8FF",
        boxShadow: "none",
        cursor: "pointer",
        zIndex: state.isFocused ? 2 : null,
        minHeight: 45,
        ":hover": {
          ...provided[":hover"],
          borderColor: state.isFocused ? "#FC3762" : "#e2e8f0",
        },
      }
    },
    singleValue: (styles, { data }) => ({
      ...styles,
      ...(data.color ? dot(data.color) : {}),
    }),
  }
}

interface Props {
  name: string
  label: string
  promise: (val: any) => Promise<any>
  optionLabel: string | ((opt) => {})
  optionValue?: string
  includeMeta?: boolean
  filterOptions?: () => {}
  staticOptions?: { [key: string]: any }
  creatable?: boolean
  className?: string
  disabled?: boolean
  placeholder?: string
  isClearable?: boolean
  onChange?: (val: any) => void
  value?: any
  bgWhite?: boolean
  [key: string]: any // extend react-select (https://react-select.com/props)
  // would be better to do proper extend of the
}

const Select = ({
  name = "",
  label = "",
  promise,
  optionLabel,
  optionValue,
  includeMeta = false,
  filterOptions,
  staticOptions,
  creatable = false,
  className = "",
  disabled = false,
  bgWhite,
  ...props
}: Props) => {
  const getOptions = (inputValue, filter) => {
    return promise(inputValue).then((res) => {
      // Most of the time the items are returned inside of a 'content' object.
      // If this does not exists, the items are assumed to be on main level
      const list = res.content ?? res
      const options = list.map((item) => {
        return {
          value: optionValue ? item[optionValue] : item,
          label:
            typeof optionLabel === "function"
              ? optionLabel(item)
              : item[optionLabel],
          ...(includeMeta ? item : {}),
          options: item.options
            ? item.options.map((opt) => {
                return {
                  ...opt,
                  label:
                    typeof optionLabel === "function"
                      ? optionLabel(opt)
                      : opt[optionLabel],
                }
              })
            : undefined,
        }
      })

      if (filter) {
        return options.filter(filter)
      }
      if (staticOptions) {
        return options.concat(staticOptions)
      }
      return options
    })
  }

  const loadOptions = (inputValue) => getOptions(inputValue, filterOptions)
  const getDebouncedOptions = debounce(loadOptions, 500, { leading: true })

  const componentProps = {
    id: name,
    name: name,
    cacheOptions: true,
    defaultOptions: true,
    loadOptions: (inputValue) => getDebouncedOptions(inputValue),
    styles: customStyles(creatable || bgWhite),
    className: `${className} ${disabled ? "pointer-events-none" : ""}`,
    ...props,
  }

  const comp = creatable ? (
    <AsyncCreatableSelect {...componentProps} />
  ) : (
    <AsyncSelect {...componentProps} />
  )

  return (
    <>
      {label != "" && (
        <label className="sr-only" htmlFor={name}>
          {label}
        </label>
      )}
      {comp}
    </>
  )
}

export default Select
