import {
  faBoxOpen,
  faFileAlt,
  faShoppingCart,
  faSyncAlt,
  faBan,
  faHomeLgAlt,
  faCoins,
  faRocketLaunch,
  faWineBottle,
  faFlag,
  faUserCheck,
  faSearchDollar,
  faSync,
  faUtensils,
  faTrashAlt,
} from "@fortawesome/pro-duotone-svg-icons"
import {
  faBallPile,
  faCroissant,
  faGlassMartini,
} from "@fortawesome/pro-light-svg-icons"
import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons"
import { getUserPermissions } from "./auth"
import { ExportObjectInterface } from "./export"

const DEV_ENV = "development"
const STAGE_ENV = "staging"
const GATSBY_ENV = process.env.GATSBY_ENVIRONMENT

export const isDevelopment =
  GATSBY_ENV === STAGE_ENV || GATSBY_ENV === DEV_ENV || GATSBY_ENV === undefined

export const quickActions = [
  { name: "Delivery note", icon: faBoxOpen },
  { name: "Invoice", icon: faFileAlt },
  { name: "Order", icon: faShoppingCart },
  { name: "Stocktake", icon: faSyncAlt },
  { name: "Waste", icon: faBan },
]

export const forcedReadPermissions = [
  "Recipes & Dishes",
  "Items",
  "Company settings",
]

export const menuItems = async () => {
  const permissions = await getUserPermissions()
  const canView = (name) => {
    return (
      permissions && permissions.find((p) => p.name === name)?.permissions.read
    )
  }
  return [
    { name: "Dashboard", icon: faHomeLgAlt, link: "./" },
    {
      name: "Products",
      icon: faWineBottle,
      link: "products",
      children: [
        {
          name: "Items",
          link: "/dashboard/products/items",
          disabled: !canView("Items"),
        },
        {
          name: "Recipes and Dishes",
          link: "/dashboard/products/cookbook",
          disabled: !canView("Recipes & Dishes"),
        },
        { name: "Menus", link: "/dashboard/products/menus", status: "alpha" },
      ],
    },
    {
      name: "Purchases",
      icon: faShoppingCart,
      link: "purchases",
      children: [
        // { name: "Overview", link: "purchases" },
        {
          name: "Orders",
          link: "/dashboard/purchases/orders",
          disabled: !canView("Orders"),
        },
        {
          name: "Deliveries",
          link: "/dashboard/purchases/deliveries",
          disabled: !canView("Delivery notes"),
        },
        {
          name: "Invoices",
          link: "/dashboard/purchases/invoices",
          disabled: !canView("Invoices"),
        },
        {
          name: "Suppliers",
          link: "/dashboard/purchases/suppliers",
          disabled: !canView("Suppliers"),
        },
      ],
    },
    {
      name: "Stock",
      icon: faSyncAlt,
      link: "/dashboard/stock",
      children: [
        {
          name: "Stocktake",
          link: "/dashboard/stock/stocktake",
          disabled: !canView("Stocktakes"),
        },
        {
          name: "Waste",
          link: "/dashboard/stock/waste",
          disabled: !canView("Waste"),
        },
        {
          name: "Transfers",
          link: "/dashboard/stock/transfers",
          disabled: !canView("Transfers"),
        },
      ],
    },

    {
      name: "Sales",
      icon: faCoins,
      link: "/dashboard/sales",
      disabled: !canView("Sales"),
    },
    {
      name: "Insights",
      icon: faRocketLaunch,
      link: "/dashboard/insights",
      children: [
        {
          name: "GP Analysis",
          link: "/dashboard/insights/gp-analysis",
          disabled: !canView("GP Analysis"),
        },
        {
          name: "COGS",
          link: "/dashboard/insights/cogs-reports",
          disabled: !canView("COGS"),
        },
        {
          name: "Price changes",
          link: "/dashboard/insights/price-changes",
          disabled: !canView("Price changes"),
        },
      ],
    },
  ]
}

export const bottomBarItems = async () => {
  const permissions = await getUserPermissions()
  const canView = (name) => {
    return permissions.find((p) => p.name === name)?.permissions.read
  }
  return [
    {
      name: "Dashboard",
      icon: faHomeLgAlt,
      link: "./",
    },
    {
      name: "Products",
      icon: faWineBottle,
      link: "/dashboard/products/items",
      disabled: !canView("Items"),
    },
    {
      name: "Purchases",
      icon: faShoppingCart,
      link: "/dashboard/purchases/orders",
      disabled: !canView("Orders"),
    },
    {
      name: "Stock",
      icon: faSyncAlt,
      link: "/dashboard/stock/stocktake",
      disabled: !canView("Stocktakes"),
    },
  ]
}

export const businessTypes = [
  { label: "Pub / Bar", value: "PUB_BAR" },
  { label: "Restaurant", value: "RESTAURANT" },
  { label: "Café", value: "COFFEE_SHOP" },
  { label: "Hotel", value: "HOTEL" },
  { label: "Convenience store", value: "CONVENIENCE_STORE" },
  { label: "Other", value: "OTHER" },
]

export const countries = [
  {
    label: "Popular countries",
    options: [
      { label: "United Kingdom", value: "United Kingdom" },
      { label: "United States", value: "United States" },
    ],
  },
  {
    label: "Countries",
    options: [
      { label: "Afghanistan", value: "Afghanistan" },
      { label: "Albania", value: "Albania" },
      { label: "Algeria", value: "Algeria" },
      { label: "Andorra", value: "Andorra" },
      { label: "Angola", value: "Angola" },
      { label: "Antigua & Deps", value: "Antigua & Deps" },
      { label: "Argentina", value: "Argentina" },
      { label: "Armenia", value: "Armenia" },
      { label: "Australia", value: "Australia" },
      { label: "Austria", value: "Austria" },
      { label: "Azerbaijan", value: "Azerbaijan" },
      { label: "Bahamas", value: "Bahamas" },
      { label: "Bahrain", value: "Bahrain" },
      { label: "Bangladesh", value: "Bangladesh" },
      { label: "Barbados", value: "Barbados" },
      { label: "Belarus", value: "Belarus" },
      { label: "Belgium", value: "Belgium" },
      { label: "Belize", value: "Belize" },
      { label: "Benin", value: "Benin" },
      { label: "Bhutan", value: "Bhutan" },
      { label: "Bolivia", value: "Bolivia" },
      { label: "Bosnia Herzegovina", value: "Bosnia Herzegovina" },
      { label: "Botswana", value: "Botswana" },
      { label: "Brazil", value: "Brazil" },
      { label: "Brunei", value: "Brunei" },
      { label: "Bulgaria", value: "Bulgaria" },
      { label: "Burkina", value: "Burkina" },
      { label: "Burundi", value: "Burundi" },
      { label: "Cambodia", value: "Cambodia" },
      { label: "Cameroon", value: "Cameroon" },
      { label: "Canada", value: "Canada" },
      { label: "Cape Verde", value: "Cape Verde" },
      { label: "Central African Rep", value: "Central African Rep" },
      { label: "Chad", value: "Chad" },
      { label: "Chile", value: "Chile" },
      { label: "China", value: "China" },
      { label: "Colombia", value: "Colombia" },
      { label: "Comoros", value: "Comoros" },
      { label: "Congo", value: "Congo" },
      { label: "Congo {Democratic Rep}", value: "Congo {Democratic Rep" },
      { label: "Costa Rica", value: "Costa Rica" },
      { label: "Croatia", value: "Croatia" },
      { label: "Cuba", value: "Cuba" },
      { label: "Cyprus", value: "Cyprus" },
      { label: "Czech Republic", value: "Czech Republic" },
      { label: "Denmark", value: "Denmark" },
      { label: "Djibouti", value: "Djibouti" },
      { label: "Dominica", value: "Dominica" },
      { label: "Dominican Republic", value: "Dominican Republic" },
      { label: "East Timor", value: "East Timor" },
      { label: "Ecuador", value: "Ecuador" },
      { label: "Egypt", value: "Egypt" },
      { label: "El Salvador", value: "El Salvador" },
      { label: "Equatorial Guinea", value: "Equatorial Guinea" },
      { label: "Eritrea", value: "Eritrea" },
      { label: "Estonia", value: "Estonia" },
      { label: "Ethiopia", value: "Ethiopia" },
      { label: "Fiji", value: "Fiji" },
      { label: "Finland", value: "Finland" },
      { label: "France", value: "France" },
      { label: "Gabon", value: "Gabon" },
      { label: "Gambia", value: "Gambia" },
      { label: "Georgia", value: "Georgia" },
      { label: "Germany", value: "Germany" },
      { label: "Ghana", value: "Ghana" },
      { label: "Greece", value: "Greece" },
      { label: "Grenada", value: "Grenada" },
      { label: "Guatemala", value: "Guatemala" },
      { label: "Guinea", value: "Guinea" },
      { label: "Guinea-Bissau", value: "Guinea-Bissau" },
      { label: "Guyana", value: "Guyana" },
      { label: "Haiti", value: "Haiti" },
      { label: "Honduras", value: "Honduras" },
      { label: "Hungary", value: "Hungary" },
      { label: "Iceland", value: "Iceland" },
      { label: "India", value: "India" },
      { label: "Indonesia", value: "Indonesia" },
      { label: "Iran", value: "Iran" },
      { label: "Iraq", value: "Iraq" },
      { label: "Ireland {Republic}", value: "Ireland {Republic" },
      { label: "Israel", value: "Israel" },
      { label: "Italy", value: "Italy" },
      { label: "Ivory Coast", value: "Ivory Coast" },
      { label: "Jamaica", value: "Jamaica" },
      { label: "Japan", value: "Japan" },
      { label: "Jordan", value: "Jordan" },
      { label: "Kazakhstan", value: "Kazakhstan" },
      { label: "Kenya", value: "Kenya" },
      { label: "Kiribati", value: "Kiribati" },
      { label: "Korea North", value: "Korea North" },
      { label: "Korea South", value: "Korea South" },
      { label: "Kosovo", value: "Kosovo" },
      { label: "Kuwait", value: "Kuwait" },
      { label: "Kyrgyzstan", value: "Kyrgyzstan" },
      { label: "Laos", value: "Laos" },
      { label: "Latvia", value: "Latvia" },
      { label: "Lebanon", value: "Lebanon" },
      { label: "Lesotho", value: "Lesotho" },
      { label: "Liberia", value: "Liberia" },
      { label: "Libya", value: "Libya" },
      { label: "Liechtenstein", value: "Liechtenstein" },
      { label: "Lithuania", value: "Lithuania" },
      { label: "Luxembourg", value: "Luxembourg" },
      { label: "Macedonia", value: "Macedonia" },
      { label: "Madagascar", value: "Madagascar" },
      { label: "Malawi", value: "Malawi" },
      { label: "Malaysia", value: "Malaysia" },
      { label: "Maldives", value: "Maldives" },
      { label: "Mali", value: "Mali" },
      { label: "Malta", value: "Malta" },
      { label: "Marshall Islands", value: "Marshall Islands" },
      { label: "Mauritania", value: "Mauritania" },
      { label: "Mauritius", value: "Mauritius" },
      { label: "Mexico", value: "Mexico" },
      { label: "Micronesia", value: "Micronesia" },
      { label: "Moldova", value: "Moldova" },
      { label: "Monaco", value: "Monaco" },
      { label: "Mongolia", value: "Mongolia" },
      { label: "Montenegro", value: "Montenegro" },
      { label: "Morocco", value: "Morocco" },
      { label: "Mozambique", value: "Mozambique" },
      { label: "Myanmar, Burma", value: "Myanmar, Burma" },
      { label: "Namibia", value: "Namibia" },
      { label: "Nauru", value: "Nauru" },
      { label: "Nepal", value: "Nepal" },
      { label: "Netherlands", value: "Netherlands" },
      { label: "New Zealand", value: "New Zealand" },
      { label: "Nicaragua", value: "Nicaragua" },
      { label: "Niger", value: "Niger" },
      { label: "Nigeria", value: "Nigeria" },
      { label: "Norway", value: "Norway" },
      { label: "Oman", value: "Oman" },
      { label: "Pakistan", value: "Pakistan" },
      { label: "Palau", value: "Palau" },
      { label: "Panama", value: "Panama" },
      { label: "Papua New Guinea", value: "Papua New Guinea" },
      { label: "Paraguay", value: "Paraguay" },
      { label: "Peru", value: "Peru" },
      { label: "Philippines", value: "Philippines" },
      { label: "Poland", value: "Poland" },
      { label: "Portugal", value: "Portugal" },
      { label: "Qatar", value: "Qatar" },
      { label: "Romania", value: "Romania" },
      { label: "Russian Federation", value: "Russian Federation" },
      { label: "Rwanda", value: "Rwanda" },
      { label: "St Kitts & Nevis", value: "St Kitts & Nevis" },
      { label: "St Lucia", value: "St Lucia" },
      {
        label: "Saint Vincent & the Grenadines",
        value: "Saint Vincent & the Grenadines",
      },
      { label: "Samoa", value: "Samoa" },
      { label: "San Marino", value: "San Marino" },
      { label: "Sao Tome & Principe", value: "Sao Tome & Principe" },
      { label: "Saudi Arabia", value: "Saudi Arabia" },
      { label: "Senegal", value: "Senegal" },
      { label: "Serbia", value: "Serbia" },
      { label: "Seychelles", value: "Seychelles" },
      { label: "Sierra Leone", value: "Sierra Leone" },
      { label: "Singapore", value: "Singapore" },
      { label: "Slovakia", value: "Slovakia" },
      { label: "Slovenia", value: "Slovenia" },
      { label: "Solomon Islands", value: "Solomon Islands" },
      { label: "Somalia", value: "Somalia" },
      { label: "South Africa", value: "South Africa" },
      { label: "South Sudan", value: "South Sudan" },
      { label: "Spain", value: "Spain" },
      { label: "Sri Lanka", value: "Sri Lanka" },
      { label: "Sudan", value: "Sudan" },
      { label: "Suriname", value: "Suriname" },
      { label: "Swaziland", value: "Swaziland" },
      { label: "Sweden", value: "Sweden" },
      { label: "Switzerland", value: "Switzerland" },
      { label: "Syria", value: "Syria" },
      { label: "Taiwan", value: "Taiwan" },
      { label: "Tajikistan", value: "Tajikistan" },
      { label: "Tanzania", value: "Tanzania" },
      { label: "Thailand", value: "Thailand" },
      { label: "Togo", value: "Togo" },
      { label: "Tonga", value: "Tonga" },
      { label: "Trinidad & Tobago", value: "Trinidad & Tobago" },
      { label: "Tunisia", value: "Tunisia" },
      { label: "Turkey", value: "Turkey" },
      { label: "Turkmenistan", value: "Turkmenistan" },
      { label: "Tuvalu", value: "Tuvalu" },
      { label: "Uganda", value: "Uganda" },
      { label: "Ukraine", value: "Ukraine" },
      { label: "United Arab Emirates", value: "United Arab Emirates" },
      { label: "United Kingdom", value: "United Kingdom" },
      { label: "United States", value: "United States" },
      { label: "Uruguay", value: "Uruguay" },
      { label: "Uzbekistan", value: "Uzbekistan" },
      { label: "Vanuatu", value: "Vanuatu" },
      { label: "Vatican City", value: "Vatican City" },
      { label: "Venezuela", value: "Venezuela" },
      { label: "Vietnam", value: "Vietnam" },
      { label: "Yemen", value: "Yemen" },
      { label: "Zambia", value: "Zambia" },
      { label: "Zimbabwe", value: "Zimbabwe" },
    ],
  },
]

export const timeZones = [
  { label: "Africa/Abidjan", value: "Africa/Abidjan" },
  { label: "Africa/Accra", value: "Africa/Accra" },
  { label: "Africa/Addis_Ababa", value: "Africa/Addis_Ababa" },
  { label: "Africa/Algiers", value: "Africa/Algiers" },
  { label: "Africa/Asmara", value: "Africa/Asmara" },
  { label: "Africa/Asmera", value: "Africa/Asmera" },
  { label: "Africa/Bamako", value: "Africa/Bamako" },
  { label: "Africa/Bangui", value: "Africa/Bangui" },
  { label: "Africa/Banjul", value: "Africa/Banjul" },
  { label: "Africa/Bissau", value: "Africa/Bissau" },
  { label: "Africa/Blantyre", value: "Africa/Blantyre" },
  { label: "Africa/Brazzaville", value: "Africa/Brazzaville" },
  { label: "Africa/Bujumbura", value: "Africa/Bujumbura" },
  { label: "Africa/Cairo", value: "Africa/Cairo" },
  { label: "Africa/Casablanca", value: "Africa/Casablanca" },
  { label: "Africa/Ceuta", value: "Africa/Ceuta" },
  { label: "Africa/Conakry", value: "Africa/Conakry" },
  { label: "Africa/Dakar", value: "Africa/Dakar" },
  { label: "Africa/Dar_es_Salaam", value: "Africa/Dar_es_Salaam" },
  { label: "Africa/Djibouti", value: "Africa/Djibouti" },
  { label: "Africa/Douala", value: "Africa/Douala" },
  { label: "Africa/El_Aaiun", value: "Africa/El_Aaiun" },
  { label: "Africa/Freetown", value: "Africa/Freetown" },
  { label: "Africa/Gaborone", value: "Africa/Gaborone" },
  { label: "Africa/Harare", value: "Africa/Harare" },
  { label: "Africa/Johannesburg", value: "Africa/Johannesburg" },
  { label: "Africa/Juba", value: "Africa/Juba" },
  { label: "Africa/Kampala", value: "Africa/Kampala" },
  { label: "Africa/Khartoum", value: "Africa/Khartoum" },
  { label: "Africa/Kigali", value: "Africa/Kigali" },
  { label: "Africa/Kinshasa", value: "Africa/Kinshasa" },
  { label: "Africa/Lagos", value: "Africa/Lagos" },
  { label: "Africa/Libreville", value: "Africa/Libreville" },
  { label: "Africa/Lome", value: "Africa/Lome" },
  { label: "Africa/Luanda", value: "Africa/Luanda" },
  { label: "Africa/Lubumbashi", value: "Africa/Lubumbashi" },
  { label: "Africa/Lusaka", value: "Africa/Lusaka" },
  { label: "Africa/Malabo", value: "Africa/Malabo" },
  { label: "Africa/Maputo", value: "Africa/Maputo" },
  { label: "Africa/Maseru", value: "Africa/Maseru" },
  { label: "Africa/Mbabane", value: "Africa/Mbabane" },
  { label: "Africa/Mogadishu", value: "Africa/Mogadishu" },
  { label: "Africa/Monrovia", value: "Africa/Monrovia" },
  { label: "Africa/Nairobi", value: "Africa/Nairobi" },
  { label: "Africa/Ndjamena", value: "Africa/Ndjamena" },
  { label: "Africa/Niamey", value: "Africa/Niamey" },
  { label: "Africa/Nouakchott", value: "Africa/Nouakchott" },
  { label: "Africa/Ouagadougou", value: "Africa/Ouagadougou" },
  { label: "Africa/Porto-Novo", value: "Africa/Porto-Novo" },
  { label: "Africa/Sao_Tome", value: "Africa/Sao_Tome" },
  { label: "Africa/Timbuktu", value: "Africa/Timbuktu" },
  { label: "Africa/Tripoli", value: "Africa/Tripoli" },
  { label: "Africa/Tunis", value: "Africa/Tunis" },
  { label: "Africa/Windhoek", value: "Africa/Windhoek" },
  { label: "America/Adak", value: "America/Adak" },
  { label: "America/Anchorage", value: "America/Anchorage" },
  { label: "America/Anguilla", value: "America/Anguilla" },
  { label: "America/Antigua", value: "America/Antigua" },
  { label: "America/Araguaina", value: "America/Araguaina" },
  {
    label: "America/Argentina/Buenos_Aires",
    value: "America/Argentina/Buenos_Aires",
  },
  {
    label: "America/Argentina/Catamarca",
    value: "America/Argentina/Catamarca",
  },
  {
    label: "America/Argentina/ComodRivadavia",
    value: "America/Argentina/ComodRivadavia",
  },
  { label: "America/Argentina/Cordoba", value: "America/Argentina/Cordoba" },
  { label: "America/Argentina/Jujuy", value: "America/Argentina/Jujuy" },
  { label: "America/Argentina/La_Rioja", value: "America/Argentina/La_Rioja" },
  { label: "America/Argentina/Mendoza", value: "America/Argentina/Mendoza" },
  {
    label: "America/Argentina/Rio_Gallegos",
    value: "America/Argentina/Rio_Gallegos",
  },
  { label: "America/Argentina/Salta", value: "America/Argentina/Salta" },
  { label: "America/Argentina/San_Juan", value: "America/Argentina/San_Juan" },
  { label: "America/Argentina/San_Luis", value: "America/Argentina/San_Luis" },
  { label: "America/Argentina/Tucuman", value: "America/Argentina/Tucuman" },
  { label: "America/Argentina/Ushuaia", value: "America/Argentina/Ushuaia" },
  { label: "America/Aruba", value: "America/Aruba" },
  { label: "America/Asuncion", value: "America/Asuncion" },
  { label: "America/Atikokan", value: "America/Atikokan" },
  { label: "America/Atka", value: "America/Atka" },
  { label: "America/Bahia", value: "America/Bahia" },
  { label: "America/Bahia_Banderas", value: "America/Bahia_Banderas" },
  { label: "America/Barbados", value: "America/Barbados" },
  { label: "America/Belem", value: "America/Belem" },
  { label: "America/Belize", value: "America/Belize" },
  { label: "America/Blanc-Sablon", value: "America/Blanc-Sablon" },
  { label: "America/Boa_Vista", value: "America/Boa_Vista" },
  { label: "America/Bogota", value: "America/Bogota" },
  { label: "America/Boise", value: "America/Boise" },
  { label: "America/Buenos_Aires", value: "America/Buenos_Aires" },
  { label: "America/Cambridge_Bay", value: "America/Cambridge_Bay" },
  { label: "America/Campo_Grande", value: "America/Campo_Grande" },
  { label: "America/Cancun", value: "America/Cancun" },
  { label: "America/Caracas", value: "America/Caracas" },
  { label: "America/Catamarca", value: "America/Catamarca" },
  { label: "America/Cayenne", value: "America/Cayenne" },
  { label: "America/Cayman", value: "America/Cayman" },
  { label: "America/Chicago", value: "America/Chicago" },
  { label: "America/Chihuahua", value: "America/Chihuahua" },
  { label: "America/Coral_Harbour", value: "America/Coral_Harbour" },
  { label: "America/Cordoba", value: "America/Cordoba" },
  { label: "America/Costa_Rica", value: "America/Costa_Rica" },
  { label: "America/Creston", value: "America/Creston" },
  { label: "America/Cuiaba", value: "America/Cuiaba" },
  { label: "America/Curacao", value: "America/Curacao" },
  { label: "America/Danmarkshavn", value: "America/Danmarkshavn" },
  { label: "America/Dawson", value: "America/Dawson" },
  { label: "America/Dawson_Creek", value: "America/Dawson_Creek" },
  { label: "America/Denver", value: "America/Denver" },
  { label: "America/Detroit", value: "America/Detroit" },
  { label: "America/Dominica", value: "America/Dominica" },
  { label: "America/Edmonton", value: "America/Edmonton" },
  { label: "America/Eirunepe", value: "America/Eirunepe" },
  { label: "America/El_Salvador", value: "America/El_Salvador" },
  { label: "America/Ensenada", value: "America/Ensenada" },
  { label: "America/Fort_Nelson", value: "America/Fort_Nelson" },
  { label: "America/Fort_Wayne", value: "America/Fort_Wayne" },
  { label: "America/Fortaleza", value: "America/Fortaleza" },
  { label: "America/Glace_Bay", value: "America/Glace_Bay" },
  { label: "America/Godthab", value: "America/Godthab" },
  { label: "America/Goose_Bay", value: "America/Goose_Bay" },
  { label: "America/Grand_Turk", value: "America/Grand_Turk" },
  { label: "America/Grenada", value: "America/Grenada" },
  { label: "America/Guadeloupe", value: "America/Guadeloupe" },
  { label: "America/Guatemala", value: "America/Guatemala" },
  { label: "America/Guayaquil", value: "America/Guayaquil" },
  { label: "America/Guyana", value: "America/Guyana" },
  { label: "America/Halifax", value: "America/Halifax" },
  { label: "America/Havana", value: "America/Havana" },
  { label: "America/Hermosillo", value: "America/Hermosillo" },
  {
    label: "America/Indiana/Indianapolis",
    value: "America/Indiana/Indianapolis",
  },
  { label: "America/Indiana/Knox", value: "America/Indiana/Knox" },
  { label: "America/Indiana/Marengo", value: "America/Indiana/Marengo" },
  { label: "America/Indiana/Petersburg", value: "America/Indiana/Petersburg" },
  { label: "America/Indiana/Tell_City", value: "America/Indiana/Tell_City" },
  { label: "America/Indiana/Vevay", value: "America/Indiana/Vevay" },
  { label: "America/Indiana/Vincennes", value: "America/Indiana/Vincennes" },
  { label: "America/Indiana/Winamac", value: "America/Indiana/Winamac" },
  { label: "America/Indianapolis", value: "America/Indianapolis" },
  { label: "America/Inuvik", value: "America/Inuvik" },
  { label: "America/Iqaluit", value: "America/Iqaluit" },
  { label: "America/Jamaica", value: "America/Jamaica" },
  { label: "America/Jujuy", value: "America/Jujuy" },
  { label: "America/Juneau", value: "America/Juneau" },
  {
    label: "America/Kentucky/Louisville",
    value: "America/Kentucky/Louisville",
  },
  {
    label: "America/Kentucky/Monticello",
    value: "America/Kentucky/Monticello",
  },
  { label: "America/Knox_IN", value: "America/Knox_IN" },
  { label: "America/Kralendijk", value: "America/Kralendijk" },
  { label: "America/La_Paz", value: "America/La_Paz" },
  { label: "America/Lima", value: "America/Lima" },
  { label: "America/Los_Angeles", value: "America/Los_Angeles" },
  { label: "America/Louisville", value: "America/Louisville" },
  { label: "America/Lower_Princes", value: "America/Lower_Princes" },
  { label: "America/Maceio", value: "America/Maceio" },
  { label: "America/Managua", value: "America/Managua" },
  { label: "America/Manaus", value: "America/Manaus" },
  { label: "America/Marigot", value: "America/Marigot" },
  { label: "America/Martinique", value: "America/Martinique" },
  { label: "America/Matamoros", value: "America/Matamoros" },
  { label: "America/Mazatlan", value: "America/Mazatlan" },
  { label: "America/Mendoza", value: "America/Mendoza" },
  { label: "America/Menominee", value: "America/Menominee" },
  { label: "America/Merida", value: "America/Merida" },
  { label: "America/Metlakatla", value: "America/Metlakatla" },
  { label: "America/Mexico_City", value: "America/Mexico_City" },
  { label: "America/Miquelon", value: "America/Miquelon" },
  { label: "America/Moncton", value: "America/Moncton" },
  { label: "America/Monterrey", value: "America/Monterrey" },
  { label: "America/Montevideo", value: "America/Montevideo" },
  { label: "America/Montreal", value: "America/Montreal" },
  { label: "America/Montserrat", value: "America/Montserrat" },
  { label: "America/Nassau", value: "America/Nassau" },
  { label: "America/New_York", value: "America/New_York" },
  { label: "America/Nipigon", value: "America/Nipigon" },
  { label: "America/Nome", value: "America/Nome" },
  { label: "America/Noronha", value: "America/Noronha" },
  {
    label: "America/North_Dakota/Beulah",
    value: "America/North_Dakota/Beulah",
  },
  {
    label: "America/North_Dakota/Center",
    value: "America/North_Dakota/Center",
  },
  {
    label: "America/North_Dakota/New_Salem",
    value: "America/North_Dakota/New_Salem",
  },
  { label: "America/Ojinaga", value: "America/Ojinaga" },
  { label: "America/Panama", value: "America/Panama" },
  { label: "America/Pangnirtung", value: "America/Pangnirtung" },
  { label: "America/Paramaribo", value: "America/Paramaribo" },
  { label: "America/Phoenix", value: "America/Phoenix" },
  { label: "America/Port-au-Prince", value: "America/Port-au-Prince" },
  { label: "America/Port_of_Spain", value: "America/Port_of_Spain" },
  { label: "America/Porto_Acre", value: "America/Porto_Acre" },
  { label: "America/Porto_Velho", value: "America/Porto_Velho" },
  { label: "America/Puerto_Rico", value: "America/Puerto_Rico" },
  { label: "America/Punta_Arenas", value: "America/Punta_Arenas" },
  { label: "America/Rainy_River", value: "America/Rainy_River" },
  { label: "America/Rankin_Inlet", value: "America/Rankin_Inlet" },
  { label: "America/Recife", value: "America/Recife" },
  { label: "America/Regina", value: "America/Regina" },
  { label: "America/Resolute", value: "America/Resolute" },
  { label: "America/Rio_Branco", value: "America/Rio_Branco" },
  { label: "America/Rosario", value: "America/Rosario" },
  { label: "America/Santa_Isabel", value: "America/Santa_Isabel" },
  { label: "America/Santarem", value: "America/Santarem" },
  { label: "America/Santiago", value: "America/Santiago" },
  { label: "America/Santo_Domingo", value: "America/Santo_Domingo" },
  { label: "America/Sao_Paulo", value: "America/Sao_Paulo" },
  { label: "America/Scoresbysund", value: "America/Scoresbysund" },
  { label: "America/Shiprock", value: "America/Shiprock" },
  { label: "America/Sitka", value: "America/Sitka" },
  { label: "America/St_Barthelemy", value: "America/St_Barthelemy" },
  { label: "America/St_Johns", value: "America/St_Johns" },
  { label: "America/St_Kitts", value: "America/St_Kitts" },
  { label: "America/St_Lucia", value: "America/St_Lucia" },
  { label: "America/St_Thomas", value: "America/St_Thomas" },
  { label: "America/St_Vincent", value: "America/St_Vincent" },
  { label: "America/Swift_Current", value: "America/Swift_Current" },
  { label: "America/Tegucigalpa", value: "America/Tegucigalpa" },
  { label: "America/Thule", value: "America/Thule" },
  { label: "America/Thunder_Bay", value: "America/Thunder_Bay" },
  { label: "America/Tijuana", value: "America/Tijuana" },
  { label: "America/Toronto", value: "America/Toronto" },
  { label: "America/Tortola", value: "America/Tortola" },
  { label: "America/Vancouver", value: "America/Vancouver" },
  { label: "America/Virgin", value: "America/Virgin" },
  { label: "America/Whitehorse", value: "America/Whitehorse" },
  { label: "America/Winnipeg", value: "America/Winnipeg" },
  { label: "America/Yakutat", value: "America/Yakutat" },
  { label: "America/Yellowknife", value: "America/Yellowknife" },
  { label: "Antarctica/Casey", value: "Antarctica/Casey" },
  { label: "Antarctica/Davis", value: "Antarctica/Davis" },
  { label: "Antarctica/DumontDUrville", value: "Antarctica/DumontDUrville" },
  { label: "Antarctica/Macquarie", value: "Antarctica/Macquarie" },
  { label: "Antarctica/Mawson", value: "Antarctica/Mawson" },
  { label: "Antarctica/McMurdo", value: "Antarctica/McMurdo" },
  { label: "Antarctica/Palmer", value: "Antarctica/Palmer" },
  { label: "Antarctica/Rothera", value: "Antarctica/Rothera" },
  { label: "Antarctica/South_Pole", value: "Antarctica/South_Pole" },
  { label: "Antarctica/Syowa", value: "Antarctica/Syowa" },
  { label: "Antarctica/Troll", value: "Antarctica/Troll" },
  { label: "Antarctica/Vostok", value: "Antarctica/Vostok" },
  { label: "Arctic/Longyearbyen", value: "Arctic/Longyearbyen" },
  { label: "Asia/Aden", value: "Asia/Aden" },
  { label: "Asia/Almaty", value: "Asia/Almaty" },
  { label: "Asia/Amman", value: "Asia/Amman" },
  { label: "Asia/Anadyr", value: "Asia/Anadyr" },
  { label: "Asia/Aqtau", value: "Asia/Aqtau" },
  { label: "Asia/Aqtobe", value: "Asia/Aqtobe" },
  { label: "Asia/Ashgabat", value: "Asia/Ashgabat" },
  { label: "Asia/Ashkhabad", value: "Asia/Ashkhabad" },
  { label: "Asia/Atyrau", value: "Asia/Atyrau" },
  { label: "Asia/Baghdad", value: "Asia/Baghdad" },
  { label: "Asia/Bahrain", value: "Asia/Bahrain" },
  { label: "Asia/Baku", value: "Asia/Baku" },
  { label: "Asia/Bangkok", value: "Asia/Bangkok" },
  { label: "Asia/Barnaul", value: "Asia/Barnaul" },
  { label: "Asia/Beirut", value: "Asia/Beirut" },
  { label: "Asia/Bishkek", value: "Asia/Bishkek" },
  { label: "Asia/Brunei", value: "Asia/Brunei" },
  { label: "Asia/Calcutta", value: "Asia/Calcutta" },
  { label: "Asia/Chita", value: "Asia/Chita" },
  { label: "Asia/Choibalsan", value: "Asia/Choibalsan" },
  { label: "Asia/Chongqing", value: "Asia/Chongqing" },
  { label: "Asia/Chungking", value: "Asia/Chungking" },
  { label: "Asia/Colombo", value: "Asia/Colombo" },
  { label: "Asia/Dacca", value: "Asia/Dacca" },
  { label: "Asia/Damascus", value: "Asia/Damascus" },
  { label: "Asia/Dhaka", value: "Asia/Dhaka" },
  { label: "Asia/Dili", value: "Asia/Dili" },
  { label: "Asia/Dubai", value: "Asia/Dubai" },
  { label: "Asia/Dushanbe", value: "Asia/Dushanbe" },
  { label: "Asia/Famagusta", value: "Asia/Famagusta" },
  { label: "Asia/Gaza", value: "Asia/Gaza" },
  { label: "Asia/Harbin", value: "Asia/Harbin" },
  { label: "Asia/Hebron", value: "Asia/Hebron" },
  { label: "Asia/Ho_Chi_Minh", value: "Asia/Ho_Chi_Minh" },
  { label: "Asia/Hong_Kong", value: "Asia/Hong_Kong" },
  { label: "Asia/Hovd", value: "Asia/Hovd" },
  { label: "Asia/Irkutsk", value: "Asia/Irkutsk" },
  { label: "Asia/Istanbul", value: "Asia/Istanbul" },
  { label: "Asia/Jakarta", value: "Asia/Jakarta" },
  { label: "Asia/Jayapura", value: "Asia/Jayapura" },
  { label: "Asia/Jerusalem", value: "Asia/Jerusalem" },
  { label: "Asia/Kabul", value: "Asia/Kabul" },
  { label: "Asia/Kamchatka", value: "Asia/Kamchatka" },
  { label: "Asia/Karachi", value: "Asia/Karachi" },
  { label: "Asia/Kashgar", value: "Asia/Kashgar" },
  { label: "Asia/Kathmandu", value: "Asia/Kathmandu" },
  { label: "Asia/Katmandu", value: "Asia/Katmandu" },
  { label: "Asia/Khandyga", value: "Asia/Khandyga" },
  { label: "Asia/Kolkata", value: "Asia/Kolkata" },
  { label: "Asia/Krasnoyarsk", value: "Asia/Krasnoyarsk" },
  { label: "Asia/Kuala_Lumpur", value: "Asia/Kuala_Lumpur" },
  { label: "Asia/Kuching", value: "Asia/Kuching" },
  { label: "Asia/Kuwait", value: "Asia/Kuwait" },
  { label: "Asia/Macao", value: "Asia/Macao" },
  { label: "Asia/Macau", value: "Asia/Macau" },
  { label: "Asia/Magadan", value: "Asia/Magadan" },
  { label: "Asia/Makassar", value: "Asia/Makassar" },
  { label: "Asia/Manila", value: "Asia/Manila" },
  { label: "Asia/Muscat", value: "Asia/Muscat" },
  { label: "Asia/Nicosia", value: "Asia/Nicosia" },
  { label: "Asia/Novokuznetsk", value: "Asia/Novokuznetsk" },
  { label: "Asia/Novosibirsk", value: "Asia/Novosibirsk" },
  { label: "Asia/Omsk", value: "Asia/Omsk" },
  { label: "Asia/Oral", value: "Asia/Oral" },
  { label: "Asia/Phnom_Penh", value: "Asia/Phnom_Penh" },
  { label: "Asia/Pontianak", value: "Asia/Pontianak" },
  { label: "Asia/Pyongyang", value: "Asia/Pyongyang" },
  { label: "Asia/Qatar", value: "Asia/Qatar" },
  { label: "Asia/Qostanay", value: "Asia/Qostanay" },
  { label: "Asia/Qyzylorda", value: "Asia/Qyzylorda" },
  { label: "Asia/Rangoon", value: "Asia/Rangoon" },
  { label: "Asia/Riyadh", value: "Asia/Riyadh" },
  { label: "Asia/Saigon", value: "Asia/Saigon" },
  { label: "Asia/Sakhalin", value: "Asia/Sakhalin" },
  { label: "Asia/Samarkand", value: "Asia/Samarkand" },
  { label: "Asia/Seoul", value: "Asia/Seoul" },
  { label: "Asia/Shanghai", value: "Asia/Shanghai" },
  { label: "Asia/Singapore", value: "Asia/Singapore" },
  { label: "Asia/Srednekolymsk", value: "Asia/Srednekolymsk" },
  { label: "Asia/Taipei", value: "Asia/Taipei" },
  { label: "Asia/Tashkent", value: "Asia/Tashkent" },
  { label: "Asia/Tbilisi", value: "Asia/Tbilisi" },
  { label: "Asia/Tehran", value: "Asia/Tehran" },
  { label: "Asia/Tel_Aviv", value: "Asia/Tel_Aviv" },
  { label: "Asia/Thimbu", value: "Asia/Thimbu" },
  { label: "Asia/Thimphu", value: "Asia/Thimphu" },
  { label: "Asia/Tokyo", value: "Asia/Tokyo" },
  { label: "Asia/Tomsk", value: "Asia/Tomsk" },
  { label: "Asia/Ujung_Pandang", value: "Asia/Ujung_Pandang" },
  { label: "Asia/Ulaanbaatar", value: "Asia/Ulaanbaatar" },
  { label: "Asia/Ulan_Bator", value: "Asia/Ulan_Bator" },
  { label: "Asia/Urumqi", value: "Asia/Urumqi" },
  { label: "Asia/Ust-Nera", value: "Asia/Ust-Nera" },
  { label: "Asia/Vientiane", value: "Asia/Vientiane" },
  { label: "Asia/Vladivostok", value: "Asia/Vladivostok" },
  { label: "Asia/Yakutsk", value: "Asia/Yakutsk" },
  { label: "Asia/Yangon", value: "Asia/Yangon" },
  { label: "Asia/Yekaterinburg", value: "Asia/Yekaterinburg" },
  { label: "Asia/Yerevan", value: "Asia/Yerevan" },
  { label: "Atlantic/Azores", value: "Atlantic/Azores" },
  { label: "Atlantic/Bermuda", value: "Atlantic/Bermuda" },
  { label: "Atlantic/Canary", value: "Atlantic/Canary" },
  { label: "Atlantic/Cape_Verde", value: "Atlantic/Cape_Verde" },
  { label: "Atlantic/Faeroe", value: "Atlantic/Faeroe" },
  { label: "Atlantic/Faroe", value: "Atlantic/Faroe" },
  { label: "Atlantic/Jan_Mayen", value: "Atlantic/Jan_Mayen" },
  { label: "Atlantic/Madeira", value: "Atlantic/Madeira" },
  { label: "Atlantic/Reykjavik", value: "Atlantic/Reykjavik" },
  { label: "Atlantic/South_Georgia", value: "Atlantic/South_Georgia" },
  { label: "Atlantic/St_Helena", value: "Atlantic/St_Helena" },
  { label: "Atlantic/Stanley", value: "Atlantic/Stanley" },
  { label: "Australia/ACT", value: "Australia/ACT" },
  { label: "Australia/Adelaide", value: "Australia/Adelaide" },
  { label: "Australia/Brisbane", value: "Australia/Brisbane" },
  { label: "Australia/Broken_Hill", value: "Australia/Broken_Hill" },
  { label: "Australia/Canberra", value: "Australia/Canberra" },
  { label: "Australia/Currie", value: "Australia/Currie" },
  { label: "Australia/Darwin", value: "Australia/Darwin" },
  { label: "Australia/Eucla", value: "Australia/Eucla" },
  { label: "Australia/Hobart", value: "Australia/Hobart" },
  { label: "Australia/LHI", value: "Australia/LHI" },
  { label: "Australia/Lindeman", value: "Australia/Lindeman" },
  { label: "Australia/Lord_Howe", value: "Australia/Lord_Howe" },
  { label: "Australia/Melbourne", value: "Australia/Melbourne" },
  { label: "Australia/NSW", value: "Australia/NSW" },
  { label: "Australia/North", value: "Australia/North" },
  { label: "Australia/Perth", value: "Australia/Perth" },
  { label: "Australia/Queensland", value: "Australia/Queensland" },
  { label: "Australia/South", value: "Australia/South" },
  { label: "Australia/Sydney", value: "Australia/Sydney" },
  { label: "Australia/Tasmania", value: "Australia/Tasmania" },
  { label: "Australia/Victoria", value: "Australia/Victoria" },
  { label: "Australia/West", value: "Australia/West" },
  { label: "Australia/Yancowinna", value: "Australia/Yancowinna" },
  { label: "Brazil/Acre", value: "Brazil/Acre" },
  { label: "Brazil/DeNoronha", value: "Brazil/DeNoronha" },
  { label: "Brazil/East", value: "Brazil/East" },
  { label: "Brazil/West", value: "Brazil/West" },
  { label: "CET", value: "CET" },
  { label: "CST6CDT", value: "CST6CDT" },
  { label: "Canada/Atlantic", value: "Canada/Atlantic" },
  { label: "Canada/Central", value: "Canada/Central" },
  { label: "Canada/Eastern", value: "Canada/Eastern" },
  { label: "Canada/Mountain", value: "Canada/Mountain" },
  { label: "Canada/Newfoundland", value: "Canada/Newfoundland" },
  { label: "Canada/Pacific", value: "Canada/Pacific" },
  { label: "Canada/Saskatchewan", value: "Canada/Saskatchewan" },
  { label: "Canada/Yukon", value: "Canada/Yukon" },
  { label: "Chile/Continental", value: "Chile/Continental" },
  { label: "Chile/EasterIsland", value: "Chile/EasterIsland" },
  { label: "Cuba", value: "Cuba" },
  { label: "EET", value: "EET" },
  { label: "EST5EDT", value: "EST5EDT" },
  { label: "Egypt", value: "Egypt" },
  { label: "Eire", value: "Eire" },
  { label: "Etc/GMT", value: "Etc/GMT" },
  { label: "Etc/GMT+0", value: "Etc/GMT+0" },
  { label: "Etc/GMT+1", value: "Etc/GMT+1" },
  { label: "Etc/GMT+10", value: "Etc/GMT+10" },
  { label: "Etc/GMT+11", value: "Etc/GMT+11" },
  { label: "Etc/GMT+12", value: "Etc/GMT+12" },
  { label: "Etc/GMT+2", value: "Etc/GMT+2" },
  { label: "Etc/GMT+3", value: "Etc/GMT+3" },
  { label: "Etc/GMT+4", value: "Etc/GMT+4" },
  { label: "Etc/GMT+5", value: "Etc/GMT+5" },
  { label: "Etc/GMT+6", value: "Etc/GMT+6" },
  { label: "Etc/GMT+7", value: "Etc/GMT+7" },
  { label: "Etc/GMT+8", value: "Etc/GMT+8" },
  { label: "Etc/GMT+9", value: "Etc/GMT+9" },
  { label: "Etc/GMT-0", value: "Etc/GMT-0" },
  { label: "Etc/GMT-1", value: "Etc/GMT-1" },
  { label: "Etc/GMT-10", value: "Etc/GMT-10" },
  { label: "Etc/GMT-11", value: "Etc/GMT-11" },
  { label: "Etc/GMT-12", value: "Etc/GMT-12" },
  { label: "Etc/GMT-13", value: "Etc/GMT-13" },
  { label: "Etc/GMT-14", value: "Etc/GMT-14" },
  { label: "Etc/GMT-2", value: "Etc/GMT-2" },
  { label: "Etc/GMT-3", value: "Etc/GMT-3" },
  { label: "Etc/GMT-4", value: "Etc/GMT-4" },
  { label: "Etc/GMT-5", value: "Etc/GMT-5" },
  { label: "Etc/GMT-6", value: "Etc/GMT-6" },
  { label: "Etc/GMT-7", value: "Etc/GMT-7" },
  { label: "Etc/GMT-8", value: "Etc/GMT-8" },
  { label: "Etc/GMT-9", value: "Etc/GMT-9" },
  { label: "Etc/GMT0", value: "Etc/GMT0" },
  { label: "Etc/Greenwich", value: "Etc/Greenwich" },
  { label: "Etc/UCT", value: "Etc/UCT" },
  { label: "Etc/UTC", value: "Etc/UTC" },
  { label: "Etc/Universal", value: "Etc/Universal" },
  { label: "Etc/Zulu", value: "Etc/Zulu" },
  { label: "Europe/Amsterdam", value: "Europe/Amsterdam" },
  { label: "Europe/Andorra", value: "Europe/Andorra" },
  { label: "Europe/Astrakhan", value: "Europe/Astrakhan" },
  { label: "Europe/Athens", value: "Europe/Athens" },
  { label: "Europe/Belfast", value: "Europe/Belfast" },
  { label: "Europe/Belgrade", value: "Europe/Belgrade" },
  { label: "Europe/Berlin", value: "Europe/Berlin" },
  { label: "Europe/Bratislava", value: "Europe/Bratislava" },
  { label: "Europe/Brussels", value: "Europe/Brussels" },
  { label: "Europe/Bucharest", value: "Europe/Bucharest" },
  { label: "Europe/Budapest", value: "Europe/Budapest" },
  { label: "Europe/Busingen", value: "Europe/Busingen" },
  { label: "Europe/Chisinau", value: "Europe/Chisinau" },
  { label: "Europe/Copenhagen", value: "Europe/Copenhagen" },
  { label: "Europe/Dublin", value: "Europe/Dublin" },
  { label: "Europe/Gibraltar", value: "Europe/Gibraltar" },
  { label: "Europe/Guernsey", value: "Europe/Guernsey" },
  { label: "Europe/Helsinki", value: "Europe/Helsinki" },
  { label: "Europe/Isle_of_Man", value: "Europe/Isle_of_Man" },
  { label: "Europe/Istanbul", value: "Europe/Istanbul" },
  { label: "Europe/Jersey", value: "Europe/Jersey" },
  { label: "Europe/Kaliningrad", value: "Europe/Kaliningrad" },
  { label: "Europe/Kiev", value: "Europe/Kiev" },
  { label: "Europe/Kirov", value: "Europe/Kirov" },
  { label: "Europe/Lisbon", value: "Europe/Lisbon" },
  { label: "Europe/Ljubljana", value: "Europe/Ljubljana" },
  { label: "Europe/London", value: "Europe/London" },
  { label: "Europe/Luxembourg", value: "Europe/Luxembourg" },
  { label: "Europe/Madrid", value: "Europe/Madrid" },
  { label: "Europe/Malta", value: "Europe/Malta" },
  { label: "Europe/Mariehamn", value: "Europe/Mariehamn" },
  { label: "Europe/Minsk", value: "Europe/Minsk" },
  { label: "Europe/Monaco", value: "Europe/Monaco" },
  { label: "Europe/Moscow", value: "Europe/Moscow" },
  { label: "Europe/Nicosia", value: "Europe/Nicosia" },
  { label: "Europe/Oslo", value: "Europe/Oslo" },
  { label: "Europe/Paris", value: "Europe/Paris" },
  { label: "Europe/Podgorica", value: "Europe/Podgorica" },
  { label: "Europe/Prague", value: "Europe/Prague" },
  { label: "Europe/Riga", value: "Europe/Riga" },
  { label: "Europe/Rome", value: "Europe/Rome" },
  { label: "Europe/Samara", value: "Europe/Samara" },
  { label: "Europe/San_Marino", value: "Europe/San_Marino" },
  { label: "Europe/Sarajevo", value: "Europe/Sarajevo" },
  { label: "Europe/Saratov", value: "Europe/Saratov" },
  { label: "Europe/Simferopol", value: "Europe/Simferopol" },
  { label: "Europe/Skopje", value: "Europe/Skopje" },
  { label: "Europe/Sofia", value: "Europe/Sofia" },
  { label: "Europe/Stockholm", value: "Europe/Stockholm" },
  { label: "Europe/Tallinn", value: "Europe/Tallinn" },
  { label: "Europe/Tirane", value: "Europe/Tirane" },
  { label: "Europe/Tiraspol", value: "Europe/Tiraspol" },
  { label: "Europe/Ulyanovsk", value: "Europe/Ulyanovsk" },
  { label: "Europe/Uzhgorod", value: "Europe/Uzhgorod" },
  { label: "Europe/Vaduz", value: "Europe/Vaduz" },
  { label: "Europe/Vatican", value: "Europe/Vatican" },
  { label: "Europe/Vienna", value: "Europe/Vienna" },
  { label: "Europe/Vilnius", value: "Europe/Vilnius" },
  { label: "Europe/Volgograd", value: "Europe/Volgograd" },
  { label: "Europe/Warsaw", value: "Europe/Warsaw" },
  { label: "Europe/Zagreb", value: "Europe/Zagreb" },
  { label: "Europe/Zaporozhye", value: "Europe/Zaporozhye" },
  { label: "Europe/Zurich", value: "Europe/Zurich" },
  { label: "GB", value: "GB" },
  { label: "GB-Eire", value: "GB-Eire" },
  { label: "GMT", value: "GMT" },
  { label: "GMT0", value: "GMT0" },
  { label: "Greenwich", value: "Greenwich" },
  { label: "Hongkong", value: "Hongkong" },
  { label: "Iceland", value: "Iceland" },
  { label: "Indian/Antananarivo", value: "Indian/Antananarivo" },
  { label: "Indian/Chagos", value: "Indian/Chagos" },
  { label: "Indian/Christmas", value: "Indian/Christmas" },
  { label: "Indian/Cocos", value: "Indian/Cocos" },
  { label: "Indian/Comoro", value: "Indian/Comoro" },
  { label: "Indian/Kerguelen", value: "Indian/Kerguelen" },
  { label: "Indian/Mahe", value: "Indian/Mahe" },
  { label: "Indian/Maldives", value: "Indian/Maldives" },
  { label: "Indian/Mauritius", value: "Indian/Mauritius" },
  { label: "Indian/Mayotte", value: "Indian/Mayotte" },
  { label: "Indian/Reunion", value: "Indian/Reunion" },
  { label: "Iran", value: "Iran" },
  { label: "Israel", value: "Israel" },
  { label: "Jamaica", value: "Jamaica" },
  { label: "Japan", value: "Japan" },
  { label: "Kwajalein", value: "Kwajalein" },
  { label: "Libya", value: "Libya" },
  { label: "MET", value: "MET" },
  { label: "MST7MDT", value: "MST7MDT" },
  { label: "Mexico/BajaNorte", value: "Mexico/BajaNorte" },
  { label: "Mexico/BajaSur", value: "Mexico/BajaSur" },
  { label: "Mexico/General", value: "Mexico/General" },
  { label: "NZ", value: "NZ" },
  { label: "NZ-CHAT", value: "NZ-CHAT" },
  { label: "Navajo", value: "Navajo" },
  { label: "PRC", value: "PRC" },
  { label: "PST8PDT", value: "PST8PDT" },
  { label: "Pacific/Apia", value: "Pacific/Apia" },
  { label: "Pacific/Auckland", value: "Pacific/Auckland" },
  { label: "Pacific/Bougainville", value: "Pacific/Bougainville" },
  { label: "Pacific/Chatham", value: "Pacific/Chatham" },
  { label: "Pacific/Chuuk", value: "Pacific/Chuuk" },
  { label: "Pacific/Easter", value: "Pacific/Easter" },
  { label: "Pacific/Efate", value: "Pacific/Efate" },
  { label: "Pacific/Enderbury", value: "Pacific/Enderbury" },
  { label: "Pacific/Fakaofo", value: "Pacific/Fakaofo" },
  { label: "Pacific/Fiji", value: "Pacific/Fiji" },
  { label: "Pacific/Funafuti", value: "Pacific/Funafuti" },
  { label: "Pacific/Galapagos", value: "Pacific/Galapagos" },
  { label: "Pacific/Gambier", value: "Pacific/Gambier" },
  { label: "Pacific/Guadalcanal", value: "Pacific/Guadalcanal" },
  { label: "Pacific/Guam", value: "Pacific/Guam" },
  { label: "Pacific/Honolulu", value: "Pacific/Honolulu" },
  { label: "Pacific/Johnston", value: "Pacific/Johnston" },
  { label: "Pacific/Kiritimati", value: "Pacific/Kiritimati" },
  { label: "Pacific/Kosrae", value: "Pacific/Kosrae" },
  { label: "Pacific/Kwajalein", value: "Pacific/Kwajalein" },
  { label: "Pacific/Majuro", value: "Pacific/Majuro" },
  { label: "Pacific/Marquesas", value: "Pacific/Marquesas" },
  { label: "Pacific/Midway", value: "Pacific/Midway" },
  { label: "Pacific/Nauru", value: "Pacific/Nauru" },
  { label: "Pacific/Niue", value: "Pacific/Niue" },
  { label: "Pacific/Norfolk", value: "Pacific/Norfolk" },
  { label: "Pacific/Noumea", value: "Pacific/Noumea" },
  { label: "Pacific/Pago_Pago", value: "Pacific/Pago_Pago" },
  { label: "Pacific/Palau", value: "Pacific/Palau" },
  { label: "Pacific/Pitcairn", value: "Pacific/Pitcairn" },
  { label: "Pacific/Pohnpei", value: "Pacific/Pohnpei" },
  { label: "Pacific/Ponape", value: "Pacific/Ponape" },
  { label: "Pacific/Port_Moresby", value: "Pacific/Port_Moresby" },
  { label: "Pacific/Rarotonga", value: "Pacific/Rarotonga" },
  { label: "Pacific/Saipan", value: "Pacific/Saipan" },
  { label: "Pacific/Samoa", value: "Pacific/Samoa" },
  { label: "Pacific/Tahiti", value: "Pacific/Tahiti" },
  { label: "Pacific/Tarawa", value: "Pacific/Tarawa" },
  { label: "Pacific/Tongatapu", value: "Pacific/Tongatapu" },
  { label: "Pacific/Truk", value: "Pacific/Truk" },
  { label: "Pacific/Wake", value: "Pacific/Wake" },
  { label: "Pacific/Wallis", value: "Pacific/Wallis" },
  { label: "Pacific/Yap", value: "Pacific/Yap" },
  { label: "Poland", value: "Poland" },
  { label: "Portugal", value: "Portugal" },
  { label: "ROK", value: "ROK" },
  { label: "Singapore", value: "Singapore" },
  { label: "SystemV/AST4", value: "SystemV/AST4" },
  { label: "SystemV/AST4ADT", value: "SystemV/AST4ADT" },
  { label: "SystemV/CST6", value: "SystemV/CST6" },
  { label: "SystemV/CST6CDT", value: "SystemV/CST6CDT" },
  { label: "SystemV/EST5", value: "SystemV/EST5" },
  { label: "SystemV/EST5EDT", value: "SystemV/EST5EDT" },
  { label: "SystemV/HST10", value: "SystemV/HST10" },
  { label: "SystemV/MST7", value: "SystemV/MST7" },
  { label: "SystemV/MST7MDT", value: "SystemV/MST7MDT" },
  { label: "SystemV/PST8", value: "SystemV/PST8" },
  { label: "SystemV/PST8PDT", value: "SystemV/PST8PDT" },
  { label: "SystemV/YST9", value: "SystemV/YST9" },
  { label: "SystemV/YST9YDT", value: "SystemV/YST9YDT" },
  { label: "Turkey", value: "Turkey" },
  { label: "UCT", value: "UCT" },
  { label: "US/Alaska", value: "US/Alaska" },
  { label: "US/Aleutian", value: "US/Aleutian" },
  { label: "US/Arizona", value: "US/Arizona" },
  { label: "US/Central", value: "US/Central" },
  { label: "US/East-Indiana", value: "US/East-Indiana" },
  { label: "US/Eastern", value: "US/Eastern" },
  { label: "US/Hawaii", value: "US/Hawaii" },
  { label: "US/Indiana-Starke", value: "US/Indiana-Starke" },
  { label: "US/Michigan", value: "US/Michigan" },
  { label: "US/Mountain", value: "US/Mountain" },
  { label: "US/Pacific", value: "US/Pacific" },
  { label: "US/Pacific-New", value: "US/Pacific-New" },
  { label: "US/Samoa", value: "US/Samoa" },
  { label: "UTC", value: "UTC" },
  { label: "Universal", value: "Universal" },
  { label: "W-SU", value: "W-SU" },
  { label: "WET", value: "WET" },
  { label: "Zulu", value: "Zulu" },
]

export const employeesNumberRanges = [
  { label: "1 - 9", value: "1-9" },
  { label: "10 - 24", value: "10-24" },
  { label: "25+", value: ">25" },
]

export const currencies = [
  { label: "£ GBP", value: "GBP", shortLabel: "£" },
  { label: "€ EUR", value: "EUR", shortLabel: "€" },
  { label: "$ USD", value: "USD", shortLabel: "$" },
]

export const organizationRoles = [
  { label: "Business owner", value: "BUSINESS_OWNER" },
  { label: "Manager", value: "MANAGER" },
  { label: "Supervisor", value: "SUPERVISOR" },
  { label: "Staff", value: "STAFF" },
  { label: "Other", value: "OTHER" },
]

export const productCategories = [
  { label: "Food", value: "Food", icon: faCroissant },
  { label: "Beverages", value: "Beverages", icon: faGlassMartini },
  { label: "Other", value: "Other", icon: faBallPile },
]

export const recipeDishCategories = [
  { label: "Food", value: "Food", icon: faCroissant },
  { label: "Beverages", value: "Beverages", icon: faGlassMartini },
  { label: "Other", value: "Other", icon: faBallPile },
]

export const productFoodCategories = [
  { label: "Meat & Poultry", value: "Meat & Poultry" },
  { label: "Seafood", value: "Seafood" },
  { label: "Fruit/Vegetables", value: "Fruit/Vegetables" },
  { label: "Sandwiches", value: "Sandwiches" },
  { label: "Bakery", value: "Bakery" },
  { label: "Grocery", value: "Grocery" },
  { label: "Dairy products", value: "Dairy products" },
  { label: "Confectionery & Snacks", value: "Confectionery & Snacks" },
]

export const productBeverageCategories = [
  { label: "Hot drinks", value: "Hot drinks" },
  { label: "Water", value: "Water" },
  { label: "Soft Drinks", value: "Soft Drinks" },
  { label: "Juices/Smoothies", value: "Juices/Smoothies" },
  { label: "Beer & Cider", value: "Beer & Cider" },
  { label: "Wine", value: "Wine" },
  { label: "Spirits", value: "Spirits" },
]

export const productOtherCategories = [
  { label: "Cleaning", value: "Cleaning" },
  { label: "Other", value: "Other" },
]

export const productCategoriesCombined = [
  {
    label: "Beverages",
    options: productBeverageCategories.map((cat) => {
      return { ...cat, groupValue: "Beverages", groupLabel: "Beverages" }
    }),
  },
  {
    label: "Food",
    options: productFoodCategories.map((cat) => {
      return { ...cat, groupValue: "Food", groupLabel: "Food" }
    }),
  },
  {
    label: "Other",
    options: productOtherCategories.map((cat) => {
      return { ...cat, groupValue: "Other", groupLabel: "Other" }
    }),
  },
]

export const defaultFilterProductCategories = {
  includeUncategorised: true,
  mainCategories: [
    {
      name: "Food",
      subCategories: productFoodCategories.map((c) => c.label),
      includeUncategorised: true,
    },
    {
      name: "Beverages",
      subCategories: productBeverageCategories.map((c) => c.label),
      includeUncategorised: true,
    },
    {
      name: "Other",
      subCategories: productOtherCategories.map((c) => c.label),
      includeUncategorised: true,
    },
  ],
}

export const weekdays = [
  { label: "Sunday", value: "SUNDAY" },
  { label: "Monday", value: "MONDAY" },
  { label: "Tuesday", value: "TUESDAY" },
  { label: "Wednesday", value: "WEDNESDAY" },
  { label: "Thursday", value: "THURSDAY" },
  { label: "Friday", value: "FRIDAY" },
  { label: "Saturday", value: "SATURDAY" },
]

export const productMeasures = [
  { label: "L", value: "L", type: "volume" },
  { label: "ml", value: "ml", type: "volume" },
  { label: "kg", value: "kg", type: "weight" },
  { label: "g", value: "g", type: "weight" },
  { label: "lbs", value: "lbs", type: "weight" },
  { label: "cl", value: "cl", type: "volume" },
  { label: "gal", value: "gal", type: "volume" },
  { label: "pint", value: "pt_UK", type: "volume" },
  { label: "hf pint", value: "hf_pt_UK", type: "volume" },
  { label: "fl oz", value: "fl_oz_UK", type: "volume" },
  { label: "oz", value: "oz", type: "weight" },
  { label: "each", value: "each", type: "each" },
  { label: "%", value: "%", type: "percentage" },
]

export const productFormMeasures = [
  { label: "each", value: "each", type: "each" },
  { label: "L", value: "L", type: "volume" },
  { label: "ml", value: "ml", type: "volume" },
  { label: "kg", value: "kg", type: "weight" },
  { label: "g", value: "g", type: "weight" },
  { label: "lbs", value: "lbs", type: "weight" },
  { label: "cl", value: "cl", type: "volume" },
  { label: "gal", value: "gal", type: "volume" },
  { label: "fl oz", value: "fl_oz_UK", type: "volume" },
  { label: "oz", value: "oz", type: "weight" },
]

export const recipeMeasures = [
  { label: "L", value: "L", type: "volume" },
  { label: "ml", value: "ml", type: "volume" },
  { label: "kg", value: "kg", type: "weight" },
  { label: "g", value: "g", type: "weight" },
  { label: "lbs", value: "lbs", type: "weight" },
  { label: "cl", value: "cl", type: "volume" },
  { label: "gal", value: "gal", type: "volume" },
  { label: "pint", value: "pt_UK", type: "volume" },
  { label: "hf pint", value: "hf_pt_UK", type: "volume" },
  { label: "fl oz", value: "fl_oz_UK", type: "volume" },
  { label: "oz", value: "oz", type: "weight" },
  { label: "each", value: "each", type: "each" },
]

export const productMeasuresSingleLiquid = [
  { label: "L", value: "L" },
  { label: "ml", value: "ml" },
  { label: "cl", value: "cl" },
  { label: "fl oz", value: "fl_oz_UK" },
]

export const productMeasuresMultipleLiquid = [
  { label: "L", value: "L" },
  { label: "gal", value: "gal" },
]

export const fluidMeasures = [
  { label: "L", value: "L" },
  { label: "ml", value: "ml" },
  { label: "gal", value: "gal" },
  { label: "cl", value: "cl" },
]

export const weightMeasures = [
  { label: "kg", value: "kg" },
  { label: "g", value: "g" },
  { label: "lbs", value: "lbs" },
  { label: "oz", value: "oz" },
]

export const orderStates = [
  { label: "Draft", value: "DRAFT", color: "#F3D019" },
  { label: "Placed", value: "PLACED", color: "#F37519" },
  { label: "Awaiting approval", value: "PENDING_APPROVAL", color: "#F3D019" },
  { label: "Cancelled", value: "CANCELED", color: "#E22C54" },
  { label: "Completed", value: "COMPLETED", color: "#35CC5F" },
  { label: "In review", value: "REVIEW", color: "#F3D019" },
]

export const deliveryNoteStates = [
  { label: "Draft", value: "DRAFT", color: "#F3D019" },
  { label: "In Query", value: "IN_QUERY", color: "#F37519" },
  { label: "Rejected", value: "REJECTED", color: "#E22C54" },
  { label: "Approved", value: "APPROVED", color: "#35CC5F" },
  { label: "Completed", value: "COMPLETED", color: "#35CC5F" },
]

export const invoiceStates = [
  { label: "In review", value: "REVIEW", color: "#F3D019" },
  { label: "Draft", value: "DRAFT", color: "#F3D019" },
  { label: "In Query", value: "IN_QUERY", color: "#F37519" },
  { label: "Rejected", value: "REJECTED", color: "#E22C54" },
  { label: "Approved", value: "APPROVED", color: "#35CC5F" },
]

export const stockTakeStates = [
  { label: "In progress", value: "IN_PROGRESS", color: "#F37519" },
  { label: "Finalised", value: "COMPLETED", color: "#35CC5F" },
  { label: "Completed", value: "COMPLETED", color: "#35CC5F" },
]

export const stockTransferStates = [
  { label: "Draft", value: "DRAFT", color: "#F3D019" },
  { label: "Sent", value: "SENT", color: "#F37519" },
  { label: "Received", value: "RECEIVED", color: "#35CC5F" },
  { label: "Cancelled", value: "CANCELLED", color: "#E22C54" },
]

export const stockTransferStatesIncoming = [
  { label: "Sent", value: "SENT", color: "#F37519" },
  { label: "Received", value: "RECEIVED", color: "#35CC5F" },
  { label: "Cancelled", value: "CANCELLED", color: "#E22C54" },
]

export const stockTransferExportOptions = [
  { label: "Transfers out", value: "OUT", color: "#E22C54" },
  { label: "Transfers in", value: "IN", color: "#35CCC3" },
  { label: "All transfers", value: "ALL", color: "#E2E2E2" },
]

export const inviteStates = [
  { label: "No", value: false, color: "#E22C54" },
  { label: "Yes", value: true, color: "#35CC5F" },
]

export const recipeStates = [
  { label: "Draft", value: "DRAFT", color: "#F3D019" },
  { label: "Published", value: "PUBLISHED", color: "#35CC5F" },
]

export const dishStates = [
  { label: "Draft", value: "DRAFT", color: "#F3D019" },
  { label: "Published", value: "PUBLISHED", color: "#35CC5F" },
]

export const paymentHistoryStates = [
  { label: "Success", value: "succeeded", color: "#35CC5F" },
  { label: "Pending", value: "pending", color: "#F3D019" },
  { label: "Failed", value: "failed", color: "#dc3545" },
  { label: "Refunded", value: "refunded", color: "#6c757d" },
  {
    label: "Partially Refunded",
    value: "partially_refunded",
    color: "#6c757d",
  },
  { label: "Authorized", value: "authorized", color: "#6c757d" },
  { label: "Voided", value: "voided", color: "#6c757d" },
]

export const saleKeys = [
  { label: "Item Name", value: "name" },
  { label: "POS ID", value: "posId" },
  { label: "Sales Value", value: "totalValue" },
  { label: "Qty Sold", value: "soldQty" },
]

export const wasteReasons = [
  { label: "Breakage", value: "BREAKAGE" },
  { label: "Out of date", value: "OUT_OF_DATE" },
  { label: "Spillage", value: "SPILLAGE" },
  { label: "Staff meals", value: "STAFF_MEALS" },
  { label: "Other", value: "OTHER" },
]

export const productSingleUnits = [
  { label: "Bottle", value: "Bottle" },
  { label: "Can", value: "Can" },
  { label: "Keg", value: "Keg" },
  { label: "Other", value: "Other" },
]

export const productUnits = [
  { label: "Bottle", value: "Bottle" },
  { label: "Can", value: "Can" },
  { label: "Keg", value: "Keg" },
  { label: "Pack", value: "Case" },
  { label: "Other", value: "Other" },
]

export const supplierKeys = [
  { label: "Supplier Name", value: "name", required: true },
  { label: "Email Address", value: "email", required: true },
  { label: "Contact Name", value: "contactName", required: true },
  { label: "Account Number", value: "accountNumber" },
  { label: "Phone Number", value: "telephone" },
  { label: "Orders: Tel", value: "ordersContact.telephone" },
  { label: "Orders: Email", value: "ordersContact.emails" },
  { label: "Deliveries: Tel", value: "deliveriesContact.telephone" },
  { label: "Deliveries: Email", value: "deliveriesContact.emails" },
  { label: "Invoices: Tel", value: "invoicesContact.telephone" },
  { label: "Invoices: Email", value: "invoicesContact.emails" },
  { label: "Website", value: "website" },
  { label: "Notes", value: "notes" },
  { label: "Address Line 1", value: "address.addressLine1" },
  { label: "Address Line 2", value: "address.addressLine2" },
  { label: "City", value: "address.city" },
  { label: "Post Code", value: "address.postCode" },
  { label: "Country", value: "address.country" },
  { label: "Currency", value: "currency" },
]

export const productKeys = [
  { label: "Item Name", value: "name", required: true },
  { label: "Barcode", value: "barcode" },
  { label: "Unit", value: "unit" },
  { label: "Size", value: "size" },
  { label: "Measure", value: "measure" },
  { label: "Cost price", value: "price" },
  { label: "Category", value: "category" },
  { label: "Subcategory", value: "subCategory" },
  { label: "SKU / Product code", value: "code" },
  { label: "POS ID", value: "posId" },
  { label: "Min qty in stock", value: "minQtyInStock" },
  { label: "Pack size", value: "productCase.size" },
  { label: "Pack price", value: "productCase.price" },
  { label: "Pack SKU", value: "productCase.code" },
  { label: "Product description", value: "description" },
  { label: "Notes", value: "notes" },
]

export const productSearchQueryParameters = [
  { label: "name", value: "partialProductName" },
  { label: "barcode", value: "partialBarcode" },
  { label: "SKU", value: "partialCode" },
  { label: "POS ID", value: "partialPosId" },
]

export const supplierCatalogProductSearchQueryParameters = [
  { label: "name", value: "partialProductName" },
  { label: "SKU", value: "partialCode" },
]

export const deliveryNotesQueryParameters = [
  { label: "Product name", value: "partialProductName" },
  { label: "Product SKU", value: "partialProductCode" },
  { label: "Document number", value: "deliveryNoteNumber" },
]

export const invoicesQueryParameters = [
  { label: "Product name", value: "partialProductName" },
  { label: "Product SKU", value: "partialProductCode" },
  { label: "Document number", value: "invoiceNumber" },
]

export const recipesQueryParameters = [
  { label: "Name", value: "partialRecipeName" },
  { label: "Ingredient name", value: "partialIngredientName" },
  { label: "POS ID", value: "partialPosId" },
]

export const dishQueryParameters = [
  { label: "Name", value: "partialDishName" },
  { label: "Ingredient name", value: "partialIngredientName" },
  { label: "POS ID", value: "partialPosId" },
]

export const paymentPlans = [
  {
    showOnBillingpage: false, // This prop is being used to conditionally render a plan in billing/purchase section
    label: "Trial",
    values: ["TRIAL"], // VAlues is a response from BE
    isBeta: false,
    comingsoon: false,
  },
  {
    // Adding a separate object based on Ruben's suggestion
    showOnBillingpage: false,
    label: "Free",
    values: ["DEFAULT"],
    isBeta: false,
    comingsoon: false,
  },
  {
    showOnBillingpage: false,
    label: "Starter",
    values: ["STARTER"],
    isBeta: false,
    comingsoon: false,
  },
  {
    showOnBillingpage: false,
    label: "Free",
    values: ["FREEMIUM"],
    pricing: {
      annual: { price: 0, price_monthly: 0, stripePriceId: "" },
      monthly: { price: 0, price_monthly: 0, stripePriceId: "" },
    },
    needsSubscriptionId: false,
    isBeta: false,
    comingsoon: false,
    isMostPopular: false,
    tag: "per site/ account",
    tagColor: "primaryGreen",
    tagStyle: { backgroundColor: "#f2faf6" },
    description:
      "Forever free for super small operators. Perfect for simplifying stock, waste and order management.",
    featuresTitle: "Key Features:",
    usps: [
      { label: "Unlimited users (granular permissions)" },
      { label: "Order from your suppliers" },
      { label: "Stocktake with barcode scanning" },
      { label: "Recipes management" },
      { label: "Waste recording & tracking " },
      { label: "Stock transfers" },
      { label: "3-way validation by orders, deliveries, invoices" },
      { label: "Stock discrepancy waste reports, COGs, GP analysis" },
      { label: "Try out our integrations with POS & Accounting software" },
      { label: "Whatsapp support" },
      /* // option with info label
      {
        label: "COGS & GP analysis reports",
        info: "Be on top of your profit. Identify margins gaps, the easy way.",
      },
      */
    ],
    //btnText: "Select this plan",
    btnText: "Get in touch",
    //btnTextUpgrade: "Select this plan",
    btnTextUpgrade: "Get in touch",
    btnColor: "primaryPink",
    btnSuffix: "*limited usage applies",
    btnAction: "support",
  },
  {
    showOnBillingpage: true,
    label: "Pro",
    values: ["ENTERPRISE", "ENTERPRISE_PLUS_SCANNING"],
    pricing: {
      annual: {
        price: 82800, // (YEARLY) price passed to stripe (+ 20% vat) & used to show yearly subscription amount (without vat)
        price_monthly: 6900, // (montly) for price label in card
        stripePriceId: process.env.GATSBY_STRIPE_PRO_PRICING_KEY_ANNUAL,
      },
      monthly: {
        price: 94800, // (MONTHLY) price passed to stripe (+ 20% vat) & used to show monthly subscription amount (without vat)
        price_monthly: 7900, // (montly) for price label in card
        stripePriceId: process.env.GATSBY_STRIPE_PRO_PRICING_KEY_MONTHLY,
      },
    },
    needsSubscriptionId: true,
    //action: "stripe-payment",
    action: "support",
    keyInCode: "PRO",
    isBeta: true,
    comingsoon: false,
    isMostPopular: false,
    //tag: "per site/ account",
    //tagColor: "primaryGreen",
    tagStyle: { backgroundColor: "#f2faf6" },
    description:
      "Ideal for smaller single-site businesses for streamlined processes.",
    featuresTitle: "Everything in Free plan",
    featuresIconColor: "primaryBlue",
    featuresIcon: faPlusCircle,
    usps: [
      { label: "Access to all product features" },
      { label: "POS & Accounting integration" },
      { label: "Automated invoice reconciliation (30 invoices included)" },
      { label: "Chat & email support" },
      { label: "Site visits available" },
    ],
    //btnText: "Select this plan",
    btnText: "Get in Touch",
    //btnTextUpgrade: "Upgrade to Pro plan",
    btnTextUpgrade: "Get in Touch",
    btnColor: "primaryPink",
    btnSuffix: "*unlocked Pro features",
    btnAction: "support",
  },
  {
    showOnBillingpage: true,
    label: "Premium",
    values: ["NAMELATER"], // To be named to the BE value later
    keyInCode: "NAMELATER",
    pricing: {
      annual: { price: 142800, price_monthly: 11900, stripePriceId: "" },
      monthly: { price: 166800, price_monthly: 13900, stripePriceId: "" },
    },
    needsSubscriptionId: false,
    isBeta: false,
    comingsoon: true,
    isMostPopular: true,
    tag: "per site",
    tagColor: "primaryGreen",
    tagStyle: { backgroundColor: "#f2faf6" },
    description:
      "Ideal for larger or multi-site operations for better cost control.",
    featuresTitle: "Everything in Pro plan",
    featuresIconColor: "primaryBlue",
    featuresIcon: faPlusCircle,
    usps: [
      { label: "Admin account to manage all sites/ areas" },
      { label: "Stock transfers" },
      { label: "Automated invoice reconciliation (unlimited invoices)*" },
      { label: "Dedicated Account Manager" },
      { label: "Specialised training available" },
    ],
    btnText: "Book a demo",
    btnTextUpgrade: "Book a demo",
    btnColor: "primaryPink",
    btnSuffix: "* special offer for sign ups until 30 June 2024",
    btnAction: "book-demo",
  },
  {
    showOnBillingpage: true,
    label: "Custom",
    values: ["CUSTOM"], // To be named to the BE value later
    keyInCode: "CUSTOM",
    pricing: false,
    priceLabel: "Based on your needs",
    needsSubscriptionId: false,

    isBeta: false,
    comingsoon: true,
    isMostPopular: false,
    //tag: "custom",
    //tagColor: "primaryPink",
    description:
      "Ideal for hotels or larger groups for better stock control & loss prevention",
    featuresTitle: "Everything in Premium plan + your requirements",
    featuresIconColor: "primaryBlue",
    featuresIcon: faPlusCircle,
    usps: [
      { label: "Tailored & scalable" },
      { label: "Features & integrations requests" },
      { label: "Personalised set up & roll out plan" },
      { label: "Customised expert consultations" },
    ],
    btnText: "Get in touch",
    btnTextUpgrade: "Get in touch",
    btnColor: "primaryBlue",
    btnSuffix:
      "*arrange a discovery call/ demo with a team member to identify your needs and pricing that fits you, create a bespoke offer",
    btnAction: "support",
  },
]

export const integrationConfig = {
  SQUARE: {
    title: "Square",
    description:
      "Connect to POS system Square. The easy way to have your sales data at your finger tips.",
    logo: "/images/integrations/square-icon.svg",
  },
  CLOVER: {
    title: "Clover",
    description: "Connect to POS system Clover, the all-in-one payment system",
    logo: "/images/integrations/clover-icon.svg",
  },
  LIGHTSPEED: {
    title: "Lightspeed",
    description:
      "Connect to Lightspeed commerce platform for retail & hospitality",
    logo: "/images/integrations/lightspeed-icon.svg",
    comingSoon: true,
  },
  XERO: {
    title: "Xero",
    description:
      "Connect to your accounting software Xero and increase efficiency",
    logo: "/images/integrations/xero-icon.svg",
  },
  SAGE: {
    title: "Sage",
    description:
      "Connect to your accounting software Sage and increase efficiency",
    logo: "/images/integrations/sage.svg",
  },
  GOEPOS: {
    title: "GoEpos",
    description:
      "Connect to POS system GoEpos, the user-friendly ipad POS system",
    logo: "/images/integrations/goepos.png",
  },
  NEWBRIDGE: {
    title: "Newbridge",
    description: "Connect to EPoS system Newbridge",
    logo: "/images/integrations/newbridge-icon.png",
    // comingSoon: true,
  },
  WOOCOMMERCE: {
    title: "WooCommerce",
    description: "Connect to Wordpress' most popular e-commerce module",
    logo: "/images/integrations/woocommerce-icon.svg",
    comingSoon: true,
  },
  SUMUP: {
    title: "SumUp",
    description: "Connect to SumUp POS system",
    logo: "/images/integrations/sumup.svg",
    comingSoon: true,
  },
}

export const salesOrigins = {
  ...integrationConfig,
  HBM: {
    title: "Email",
    description: "Live import from email",
    logo: "/images/integrations/email.svg",
  },
}

//For now I added some sample videos
export const helpVideos = [
  {
    pathname: "/dashboard",
    src: "https://www.youtube.com/embed/0bnybT-HLQU?autoplay=1",
  },
  {
    pathname: "/dashboard/products/items",
    src: "https://www.youtube.com/embed/nrUbGluo45g?autoplay=1",
  },
  {
    pathname: "/dashboard/purchases/suppliers",
    src: "https://www.youtube.com/embed/pIflHYKPdoQ?autoplay=1",
  },
  {
    pathname: "/dashboard/purchases/orders",
    src: "https://www.youtube.com/embed/Fj_Xn07Ujrg?autoplay=1",
  },
  {
    pathname: "/dashboard/purchases/deliveries",
    src: "https://www.youtube.com/embed/Fj_Xn07Ujrg?autoplay=1",
  },
  {
    pathname: "/dashboard/purchases/invoices",
    src: "https://www.youtube.com/embed/Fj_Xn07Ujrg?autoplay=1",
  },
]

export const getStartedSteps = [
  {
    name: "id",
    icon: faFlag,
    subItems: [],
    checked: null,
  },
  {
    name: "orgId",
    icon: faUserCheck,
    subItems: [],
    checked: null,
  },
  {
    name: "hasAddedProducts",
    icon: faWineBottle,
    subItems: [
      {
        name: "hasImportedProductTemplate",
      },
      {
        name: "hasImportedOwnProducts",
      },
      {
        name: "hasImportedInvoices",
      },
    ],
    checked: null,
  },
  {
    name: "hasCheckedCorrectPricesAndBarcodes",
    icon: faSearchDollar,
    subItems: [],
    checked: null,
  },
]

export const getNextSteps = [
  {
    name: "hasAddedStocktake",
    icon: faSync,
    subItems: [],
    checked: null,
  },
  {
    name: "hasAddedRecipe",
    icon: faUtensils,
    subItems: [],
    checked: null,
  },
  {
    name: "hasAddedWaste",
    icon: faTrashAlt,
    subItems: [],
    checked: null,
  },
]

export const searchFilterSelectStyles = {
  container: (provided) => ({
    ...provided,
    position: "static",
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#fff",
    borderLeft: "none",
    borderRadius: "0 0.25rem 0.25rem 0",
    width: "fit-content",
    borderColor: state.isFocused ? "#FC3762" : "#e2e8f0",
    boxShadow: "none",
    zIndex: state.isFocused ? 2 : null,
    minHeight: 45,
    ":hover": {
      ...provided[":hover"],
      borderColor: state.isFocused ? "#FC3762" : "#e2e8f0",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0px",
    width: "0px",
  }),
  menu: (provided) => ({
    ...provided,
    left: "0px",
  }),
}

export const editRecipeInfoTabs = [
  {
    key: "generalInfo",
    label: "General info",
  },
  {
    key: "salesAndProfit",
    label: "Sales & Profit",
  },
]

export const editRecipeTabs = [
  {
    key: "ingredients",
    label: "Ingredients",
  },
  {
    key: "portions",
    label: "Portions",
  },
  {
    key: "method",
    label: "Method",
  },
  {
    key: "waste",
    label: "Waste",
  },
  {
    key: "files",
    label: "Files",
  },
  {
    key: "notes",
    label: "Notes",
  },
]

export const recipeActions = [
  { key: "recipe.duplicate", title: "Duplicate", type: "default" },
  // { key: "recipe.add_to_dish", title: "Add to dish", type: "default" },
  ...(isDevelopment
    ? [{ key: "recipe.print", title: "Print", type: "default" }]
    : []),
  { key: "recipe.delete", title: "Delete", type: "danger" },
]

export const editDishInfoTabs = [
  {
    key: "generalInfo",
    label: "General info",
  },
  {
    key: "salesAndProfit",
    label: "Sales & Profit",
  },
]

export const editDishTabs = [
  {
    key: "elements",
    label: "Dish elements",
  },
  {
    key: "method",
    label: "Method",
  },
  {
    key: "waste",
    label: "Waste",
  },
  {
    key: "files",
    label: "Files",
  },
  {
    key: "notes",
    label: "Notes",
  },
]

export const dishActions = [
  { key: "dish.duplicate", title: "Duplicate", type: "default" },
  // { key: "dish.add_to_menu", title: "Add to menu", type: "default" },
  { key: "dish.print", title: "Print", type: "default" },
  { key: "dish.delete", title: "Delete", type: "danger" },
]

export const percentageAndFullMeasures = [
  {
    label: "%",
    value: "percentage",
    type: "percentage",
  },
  {
    label: "full",
    value: "full",
    type: "full",
  },
]

export const allergens = [
  { label: "celery", value: "celery", icon: "/images/icon/celery.png" },
  {
    label: "gluten",
    value: "cereals_containing_gluten",
    icon: "/images/icon/wheat-grains.png",
  },
  {
    label: "crustaceans",
    value: "crustaceans",
    icon: "/images/icon/crab-with-two-claws.png",
  },
  { label: "eggs", value: "eggs", icon: "/images/icon/eggs.png" },
  { label: "fish", value: "fish", icon: "/images/icon/fish.png" },
  { label: "lupins", value: "lupin", icon: "/images/icon/lupins.png" },
  { label: "milk", value: "milk", icon: "/images/icon/milk-bottle.png" },
  { label: "molluscs", value: "molluscs", icon: "/images/icon/mollusc.png" },
  { label: "mustard", value: "mustard", icon: "/images/icon/mustard.png" },
  { label: "nuts", value: "nuts", icon: "/images/icon/almond.png" },
  { label: "peanuts", value: "peanuts", icon: "/images/icon/peanut.png" },
  {
    label: "sesame seeds",
    value: "sesame_seeds",
    icon: "/images/icon/sesame.png",
  },
  { label: "soya", value: "soya", icon: "/images/icon/beans.png" },
  {
    label: "sulphites",
    value: "sulphur_dioxide",
    icon: "/images/icon/sulfur-dioxide.png",
  },
]

export const initialAddressStub = {
  addressLine1: "",
  companyName: "",
  city: "",
  postCode: "",
  zoneId: "",
  country: "",
  //zoneId passed from props
  //country passed from props
}

export const initialDeliveryAddressStub = {
  addressLine1: "",
  addressLine2: "",
  city: "",
  postCode: "",
  country: "",
  // country: "", passed from props
}

export const downloadFormatLabels = {
  xlsx: "Export to .xlsx",
  csv: "Export to .csv",
  sage_csv: "Sage import .csv",
}

export const exportObject: ExportObjectInterface = {
  orders: {
    singular: "order",
    statusOptions: orderStates,
    transferOptions: [],
    reportTypes: [
      {
        value: "SUMMARY",
        label: "Summary",
        subUrl: "",
        status: true,
        supplier: true,
        period: true,
        productSelector: false,
        downloadFormats: ["xlsx"],
      },
      {
        value: "DETAILED",
        label: "Detailed",
        subUrl: "/products",
        status: false,
        supplier: false,
        period: true,
        productSelector: true,
        downloadFormats: ["xlsx"],
      },
    ],
  },
  deliveries: {
    urlType: "delivery-notes",
    singular: "delivery",
    statusOptions: deliveryNoteStates,
    transferOptions: [],
    reportTypes: [
      {
        value: "SUMMARY",
        label: "Summary",
        subUrl: "",
        status: true,
        supplier: true,
        period: true,
        productSelector: false,
        downloadFormats: ["xlsx", "csv"],
      },
      {
        value: "DETAILED",
        label: "Detailed",
        subUrl: "/delivery-notes-and-products",
        status: true,
        supplier: true,
        period: true,
        productSelector: false,
        downloadFormats: ["xlsx"],
      },
    ],
  },
  invoices: {
    singular: "invoice",
    statusOptions: invoiceStates,
    transferOptions: [],
    reportTypes: [
      {
        value: "SUMMARY",
        label: "Summary",
        subUrl: "",
        status: true,
        supplier: true,
        period: true,
        productSelector: true,
        downloadFormats: ["xlsx", "csv"],
      },
      {
        value: "DETAILED",
        label: "Detailed",
        subUrl: "/invoices-and-products",
        status: true,
        supplier: true,
        period: true,
        productSelector: false,
        downloadFormats: ["xlsx"],
      },
      {
        value: "SAGE",
        label: "Sage",
        subUrl: "/sage",
        status: true,
        supplier: true,
        period: true,
        productSelector: false,
        downloadFormats: ["csv"],
      },
    ],
  },
  transfers: {
    urlType: "stock-transfers",
    singular: "stock transfer",
    statusOptions: [],
    transferOptions: stockTransferExportOptions,
    reportTypes: [
      {
        value: "SUMMARY",
        label: "Summary",
        subUrl: "/products",
        transfers: true,
        period: true,
        downloadFormats: ["xlsx"],
      },
      {
        value: "DETAILED",
        label: "Detailed",
        subUrl: "/products/detailed",
        transfers: true,
        period: true,
        downloadFormats: ["xlsx"],
      },
    ],
  },
}
