import React, { useContext, useState, useEffect } from "react"
import Helmet from "react-helmet"
import * as styles from "./Onboarding.module.css"
import { navigate } from "gatsby"
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "context/GlobalContextProvider"
import {
  createOrganization,
  createSubOrganization,
} from "services/organization"
import { getOnboardingSteps } from "services/onboarding"
import { objHasValue } from "services/helpers"
import { organizationRoles } from "services/constants"
import { showError } from "services/toast"
import { getDecodedtoken, refreshLogin } from "services/auth"
import { getMyPaymentPlan } from "services/payment"

import Header from "components/dashboard/Header/Header"
import Loader from "components/common/Loader/Loader"
import InitialStep from "components/onboarding/InitialStep/InitialStep"
import AddressDetails from "components/onboarding/AddressDetails/AddressDetails"
import CompanyDetails from "components/onboarding/CompanyDetails/CompanyDetails"
import AccountType from "../AccountType/AccountType"
import LocationDetails from "../LocationDetails/LocationDetails"
import { sendEvent } from "services/gtm"
import { useLocation } from "@reach/router"
import queryString from "query-string"
import { getCombinedUserInformation } from "services/user"
import { faBuilding } from "@fortawesome/pro-light-svg-icons"

const Onboarding = () => {
  const dispatch = useContext(GlobalDispatchContext)
  const { organization, user } = useContext(GlobalStateContext)
  const location = useLocation()
  const [step, setStep] = useState<
    | "account-type"
    | "initial"
    | "company-details"
    | "address-details"
    | "add-locations"
    | "get-started"
  >("account-type")

  const [loading, setLoading] = useState(false)

  const initialAddress = {
    addressLine1: "",
    addressLine2: "",
    city: "",
    country: "",
    postCode: "",
    zoneId: "",
  }
  const [organizationDetails, setOrganizationDetails] = useState({
    companyName: "",
    businessType: "",
    employeesNumberRange: "",
    logo: "",
    website: "",
    telephone: "",
  })
  const [selectedAccountType, setSelectedAccountType] = useState<any>(null)
  const [address, setAddress] = useState(initialAddress)
  const [deliveryAddress, setDeliveryAddress] = useState(initialAddress)
  const [locationsData, setLocationsData] = useState<any[]>([])
  const [isAdmin, setIsAdmin] = useState<boolean>(true)

  const dotCount: number = selectedAccountType?.type === "enterprise" ? 3 : 2
  const queryParams = queryString.parse(location.search)

  useEffect(() => {
    let isAdminUser = true
    // If user is not an admin, select 'company' account type automatically and go to next step
    getToken().then((res: any) => {
      if (res) {
        isAdminUser = res.authorities.includes("admin")
        if (!isAdminUser) {
          setStep("company-details")
          setSelectedAccountType({
            type: "company",
            headerText: "Create One Account only",
            subText:
              "Suitable for smaller businesses that want to manage a single location / one area",
            icon: faBuilding,
          })
          setIsAdmin(false)
        }
      }
    })

    // If organization already exists, redirect to dashboard
    if (
      organization &&
      !(queryParams?.afterSignup && isAdminUser) &&
      !loading
    ) {
      navigate("/dashboard")
      return
    }
  }, [])

  const getToken = async () => {
    return await getDecodedtoken()
  }

  const finalize = async () => {
    setLoading(true)

    const deliveryAddressExists = objHasValue(deliveryAddress)
    const params = {
      ...organizationDetails,
      address,
      deliveryAddress: deliveryAddressExists ? deliveryAddress : null,
      contactDetails: {
        telephone: organizationDetails.telephone,
      },
    }
    const defaultRole: string = organizationRoles[0].value

    await createOrganization(defaultRole, params)
      .then(async (organizationRes) => {
        if (organizationRes.status === 409) {
          throw new Error(organizationRes.message)
        }
        dispatch({ type: "SET_ORGANIZATION", payload: organizationRes })
        await refreshLogin(organizationRes.id)
        return organizationRes
      })
      .then(async (organizationRes) => {
        const createdSubOrganisations: any = []

        for (const location of locationsData) {
          const formattedParams = {
            address: location.address,
            deliveryAddress: objHasValue(location.deliveryAddress)
              ? location.deliveryAddress
              : null,
            companyName: location.address.companyName,
          }
          const org = await createSubOrganization(formattedParams)
          createdSubOrganisations.push(org)
        }

        dispatch({
          type: "SET_ORGANIZATIONS",
          payload: [...createdSubOrganisations, organizationRes].map((a) => {
            return {
              value: a.id,
              label: a.companyName,
              type: a.mainOrgId
                ? "SUB"
                : selectedAccountType?.type === "enterprise"
                ? "MAIN"
                : "STANDARD",
            }
          }),
        })
        dispatch({
          type: "SET_ORGANIZATION",
          payload: {
            ...organizationRes,
            subOrgIds: [...createdSubOrganisations.map((o) => o.id)],
          },
        })
        return organizationRes
      })
      .then((organizationRes) => {
        // Refresh login again after creating sub organizations to make sure they're attached to the token information too
        return refreshLogin(organizationRes.id)
      })
      .then((userRes) => {
        dispatch({ type: "SET_USER", payload: userRes })
        return getCombinedUserInformation()
      })
      .then((userInfo) => {
        dispatch({ type: "SET_COMBINED_USER_INFORMATION", payload: userInfo })
        return getMyPaymentPlan()
      })
      .then((paymentPlanRes) => {
        dispatch({ type: "SET_PAYMENT_PLAN", payload: paymentPlanRes })

        getOnboardingSteps()
          .then((onboardingSteps) => {
            dispatch({
              type: "UPDATE_ONBOARDING_STEPS",
              payload: { onboardingSteps: onboardingSteps },
            })
          })
          .catch((error) => {
            showError(error.message)
          })
        sendEvent("finalizeAccount", {
          email: user.username,
          organization: params,
          accountType: selectedAccountType.type,
        })
        setStep("get-started")
      })
      .catch((err) => {
        showError(err.message)
      })

    setLoading(false)
    // Remove 'skipped' flag if exists

    // dispatch({ type: 'UPDATE_ORGANIZATION', payload: values })
  }

  return (
    <>
      <Helmet>
        <title>Welcome</title>
      </Helmet>
      <div className={styles.container}>
        <Header title="Welcome" />
        <div className={styles.content}>
          <div className={styles.contentWrapper}>
            <Loader
              isLoading={loading}
              style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
            >
              Preparing your account..
            </Loader>

            {step === "account-type" && (
              <AccountType
                nextStep={() => setStep("company-details")}
                selectedAccountType={selectedAccountType}
                setSelectedAccountType={setSelectedAccountType}
              />
            )}

            {step === "company-details" && (
              <CompanyDetails
                organizationDetails={organizationDetails}
                onDataChange={(data) => setOrganizationDetails(data.nextValues)}
                dotCount={dotCount}
                prevStep={() => setStep("account-type")}
                nextStep={() => setStep("address-details")}
                isAdmin={isAdmin}
              />
            )}

            {step === "address-details" && (
              <AddressDetails
                selectedAccountType={selectedAccountType}
                address={address}
                deliveryAddress={deliveryAddress}
                onAddressChange={(data) => setAddress(data.nextValues)}
                onDeliveryAddressChange={(data) =>
                  setDeliveryAddress(data.nextValues)
                }
                dotCount={dotCount}
                prevStep={() => setStep("company-details")}
                nextStep={() =>
                  selectedAccountType.type === "enterprise"
                    ? setStep("add-locations")
                    : finalize()
                }
              />
            )}
            {step === "add-locations" && (
              <>
                <div className="flex flex-col items-center">
                  <h2 className="text-4xl mb-4">Add Locations / Areas</h2>
                  <p className="text-lg px-4 text-gray-700 mb-6 text-center">
                    Please add locations / areas ie kitchen, bar, cellar or
                    another venue
                  </p>
                  <span className="rounded-full text-xs self-center bg-gray-200 py-2 px-4 mb-4">
                    *fees apply to each location / area
                  </span>
                </div>
                <LocationDetails
                  initialLocations={locationsData}
                  nextStep={finalize}
                  prevStep={() => setStep("address-details")}
                  onLocationsChange={(locations) => {
                    setLocationsData(locations)
                  }}
                  dotCount={dotCount}
                  zoneId={address.zoneId}
                  country={address.country}
                />
              </>
            )}

            {step === "get-started" && (
              //Step with video and get started button
              <InitialStep nextStep={() => navigate("/dashboard")} />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Onboarding
