import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { usePromise, usePrevious } from "react-use"
import { getOrders } from "services/order"
import { searchSuppliers } from "services/supplier"
import SearchInput from "components/forms/SearchInput"
import AsyncSelect from "components/forms/AsyncSelect"
import Loader from "components/common/Loader/Loader"
import OrderSelectItem from "components/orders/OrderSelectItem/OrderSelectItem"

import * as styles from "./OrderSelect.module.css"

const OrderSelect = ({ onSelect, selectedSupplier }) => {
  const [loading, setLoading] = useState(false)
  const fromPromise = usePromise()
  const [orderData, setOrderData] = useState({
    content: [],
  })
  const [q, setQ] = useState("")
  const previousQ = usePrevious(q)
  // const [status, setStatus] = useState(null)
  // const previousStatus = usePrevious(status)
  const [supplier, setSupplier] = useState(selectedSupplier)
  const previousSupplier = usePrevious(supplier)

  const [paginationData, setPaginationData] = useState({
    page: 0,
    size: 999,
    totalPages: "",
    totalElements: "",
    numberOfElements: "",
  })
  const previousPage = usePrevious(paginationData.page)

  const getData = async () => {
    setLoading(true)

    let params = {
      page: paginationData.page,
      sort: "createdDate,desc",
      status: "PLACED", // Set status harcoded to placed for now
      includeIsDelivered: true,
    }

    if (supplier) {
      params.supplierId = supplier.id
      if (previousSupplier && previousSupplier.id !== supplier.id) {
        params.page = 0
      }
    }

    // if (status) {
    //   params.status = status.value

    //   if (previousStatus && previousStatus.value !== status.value) {
    //     params.page = 0
    //   }
    // }

    if (q) {
      params.partialPONumber = q

      if (previousQ && previousQ.value !== q.value) {
        params.page = 0
      }
    }

    // fromPromise prevents call on unmount of component
    const result = await fromPromise(getOrders(params))

    if (result && !result.error) {
      setOrderData({ ...orderData, ...result })
      setPaginationData({
        ...paginationData,
        size: result.size,
        totalPages: result.totalPages,
        totalElements: result.totalElements,
        numberOfElements: result.numberOfElements,
      })
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!selectedSupplier) {
      getData()
    } else {
      setSupplier(selectedSupplier)
    }
  }, [selectedSupplier])

  // useEffect(() => {
  //   if (previousStatus || status) {
  //     getData()
  //   }
  // }, [status])

  useEffect(() => {
    if (previousPage !== undefined && paginationData.page !== previousPage) {
      getData()
    }
  }, [paginationData.page])

  useEffect(() => {
    if (previousSupplier || supplier) {
      getData()
    }
  }, [supplier])

  useEffect(() => {
    if (previousQ || q) {
      getData()
    }
  }, [q])

  return (
    <>
      <div className={styles.container}>
        <div className={styles.subHeader}>
          <div className="my-2 px-2 w-1/2">
            <SearchInput
              label="PO number"
              placeholder="PO number"
              className="form-control rounded"
              onSearchChange={(val) => {
                setQ(val)
              }}
            />
          </div>
          {/* <div className="my-2 px-2 w-1/3">
            <FilterSelect
              options={orderStates}
              value={status}
              onChange={val => {
                setStatus(val)
              }}
              placeholder="Status: all"
              isClearable={true}
            />
          </div> */}
          <div className="my-2 px-2 w-1/2">
            <AsyncSelect
              promise={searchSuppliers}
              placeholder="All suppliers"
              isDisabled={supplier}
              value={
                selectedSupplier
                  ? {
                      value: selectedSupplier,
                      label: selectedSupplier.name,
                    }
                  : supplier
                  ? { value: supplier, label: supplier.name }
                  : null
              }
              isClearable={true}
              optionLabel="name"
              onChange={(val) => {
                setSupplier(val ? val.value : null)
              }}
            />
          </div>
        </div>

        <div className={styles.content}>
          <Loader
            isLoading={loading}
            style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
          >
            Loading orders..
          </Loader>
          <div className="w-full flex-col flex-grow -mt-px">
            <div className="flex flex-grow flex-col">
              {orderData.content.length > 0 &&
                orderData.content.map((order) => {
                  return (
                    <React.Fragment key={order.id}>
                      <OrderSelectItem order={order} onSelect={onSelect} />
                    </React.Fragment>
                  )
                })}
              {orderData.content.length === 0 && !loading && (
                <p className={styles.noOrders}>No orders founds</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

OrderSelect.propTypes = {
  onSelect: PropTypes.func,
  selectedSupplier: PropTypes.object,
}

export default OrderSelect
