import React from "react"
import PropTypes from "prop-types"

import { roundNumber } from "services/helpers"

import ExpandableCategorisedProductTable from "components/common/ExpandableCategorisedProductTable/ExpandableCategorisedProductTable"

const DiscrepancyReportContentCategorised = ({ data }) => {
  const Title = ({ rowData }) => {
    return (
      <div className="flex flex-col my-3 text-sm">
        <span className="font-sansSemiBold text-sm font-semibold text-primaryBlue w-full">
          {rowData.name}
        </span>
        <span className="text-gray-700">
          {rowData.size} {rowData.measure}
        </span>
      </div>
    )
  }

  Title.propTypes = {
    name: PropTypes.string,
    size: PropTypes.number,
    measure: PropTypes.string,
  }

  const setSharedColumnProperties = (currentColumn) => {
    // Assigning properties to the object before sending it to the BaseTable
    return {
      headerRenderer: ({ column }) => {
        //Allowing newline characters in column title
        return (
          <span className="whitespace-pre-wrap w-full block">
            {column.title.toString()}
          </span>
        )
      },
      className: "text-sm",
      flexGrow: 1,
      flexShrink: 0,
      //Spreading main object as last step, allowing to overwite defaults made in this function
      ...currentColumn,
    }
  }

  const columns = [
    {
      key: "name",
      title: "Item name",
      dataKey: "name",
      width: 200,
      headerClassName: "pl-6",
      cellRenderer: Title,
    },
    {
      key: "openingQty",
      title: "Opening\nqty",
      width: 100,
      dataKey: "openingQty",
      className: "text-sm",
      cellRenderer: ({ rowData }) => (
        <>
          {!isNaN(rowData.openingQty) ? roundNumber(rowData.openingQty) : null}
        </>
      ),
    },
    {
      key: "deliveryQty",
      title: "Delivery\n(+)",
      width: 100,
      dataKey: "deliveryQty",
      cellRenderer: ({ rowData }) => (
        <>
          {!isNaN(rowData.deliveryQty)
            ? roundNumber(rowData.deliveryQty)
            : null}
        </>
      ),
    },
    {
      key: "transferredQty",
      title: `Transfers\n(+/-)`,
      width: 100,
      dataKey: "transferredQty",
      className: "text-sm",
      cellRenderer: ({ rowData }) => (
        <>
          {!isNaN(rowData.transferredQty)
            ? roundNumber(rowData.transferredQty)
            : null}
        </>
      ),
    },
    {
      key: "soldQty",
      title: "Sales\n(-)",
      width: 100,
      dataKey: "soldQty",
      cellRenderer: ({ rowData }) => (
        <>{!isNaN(rowData.soldQty) ? roundNumber(rowData.soldQty) : null}</>
      ),
    },
    {
      key: "wasteQty",
      title: "Waste\n(-)",
      width: 100,
      dataKey: "wasteQty",
      cellRenderer: ({ rowData }) => (
        <>{!isNaN(rowData.wasteQty) ? roundNumber(rowData.wasteQty) : null}</>
      ),
    },
    {
      key: "expectedClosingQty",
      title: "Expected closing\nqty",
      width: 100,
      dataKey: "expectedClosingQty",
      cellRenderer: ({ rowData }) => (
        <>
          {!isNaN(rowData.expectedClosingQty)
            ? roundNumber(rowData.expectedClosingQty)
            : null}
        </>
      ),
    },
    {
      key: "currentQty",
      title: "Actual closing\nqty",
      width: 100,
      dataKey: "currentQty",
      cellRenderer: ({ rowData }) => (
        <>
          {!isNaN(rowData.currentQty) ? roundNumber(rowData.currentQty) : null}
        </>
      ),
    },
    {
      key: "discrepancyQty",
      title: "Discrepancy\nqty",
      width: 110,
      dataKey: "discrepancyQty",
      cellRenderer: ({ rowData }) => (
        <span
          className={
            rowData.discrepancyQty < 0
              ? "text-primaryPink font-sansSemiBold font-semibold"
              : ""
          }
        >
          {!isNaN(rowData.discrepancyQty)
            ? roundNumber(rowData.discrepancyQty)
            : null}
        </span>
      ),
    },
    {
      key: "discrepancyAmount",
      title: "Discrepancy\nvalue",
      width: 115,
      dataKey: "discrepancyAmount",
      className: "text-sm",
      cellRenderer: ({ rowData }) => (
        <span
          className={
            rowData.totalDiscrepancyAmount < 0
              ? "text-primaryPink font-sansSemiBold font-semibold"
              : ""
          }
        >
          {rowData.totalDiscrepancyAmount //don't check for !isNaN because we want to leave blank if totalDiscrepancyAmount is 0
            ? roundNumber(rowData.totalDiscrepancyAmount)
            : null}
        </span>
      ),
    },
  ].map(setSharedColumnProperties)

  return (
    <>
      {data.categorisedProducts && (
        <ExpandableCategorisedProductTable
          data={data}
          columns={columns}
          productValueMap={(prod) => {
            return {
              totalDiscrepancyAmount: prod.discrepancyAmount,
            }
          }}
        />
      )}
    </>
  )
}

DiscrepancyReportContentCategorised.propTypes = {
  data: PropTypes.object,
}

export default DiscrepancyReportContentCategorised
