import React, { useContext } from "react"
import ActionsButton from "components/common/ActionsButton/ActionsButton"
import usePermissions from "hooks/usePermissions"
import { GlobalStateContext } from "context/GlobalContextProvider"

const OrderItemActions = ({ order, handleActionClick }) => {
  const permissionObj = usePermissions("Orders")
  const { organization } = useContext(GlobalStateContext)

  const options: Array<{
    key: string
    title: string
    type: string
    disabled?: boolean
  }> = []

  const isUnfinished = (order) =>
    order.status === "REVIEW" || order.status === "DRAFT"

  const isDelivered = order.isDelivered

  if (order.status !== "PENDING_APPROVAL") {
    options.push({
      key: "order.duplicate",
      title: !isUnfinished(order) ? "Re-order" : "Edit",
      type: "default",
      disabled: !permissionObj?.permissions.modify,
    })

    if (order.status === "PLACED") {
      options.push({
        key: "order.resend",
        title: "Re-send order",
        type: "default",
        disabled: !permissionObj?.permissions.modify,
      })
      if (!isDelivered) {
        options.push({
          key: "order.mark_delivered",
          title: "Mark delivered",
          type: "default",
          disabled: !permissionObj?.permissions.modify,
        })
      }
      options.push({
        key: "order.cancel",
        title: "Cancel",
        type: "danger",
        disabled: !permissionObj?.permissions.modify,
      })
    } else {
      options.push({
        key: "order.mark_placed",
        title: "Mark placed",
        type: "default",
        disabled: !permissionObj?.permissions.modify,
      })
    }

    if (order.status === "DRAFT") {
      options.push({
        key: "order.place",
        title: "Place order",
        type: "default",
        disabled: !permissionObj?.permissions.modify,
      })
    }

    if (isUnfinished(order)) {
      options.push({
        key: "order.delete",
        title: "Delete",
        type: "danger",
        disabled: !permissionObj?.permissions.delete,
      })
    }
  }

  return (
    <div className="px-1 flex-shrink-0" style={{ textAlign: "right" }}>
      {order?.organizations.length &&
        order?.organizations[0] === organization?.id && (
          <ActionsButton
            options={options}
            onActionClick={(action) => handleActionClick(action, order)}
            disabled={!options.length}
          />
        )}
    </div>
  )
}

export default OrderItemActions
