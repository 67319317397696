import React, { ReactNode } from "react"

import ActionsButton from "components/common/ActionsButton/ActionsButton"

//@ts-ignore
import * as styles from "./Container.module.css"

interface Props {
  data: {}
  actionOptions: {
    key: string
    title: string
    type?: string
    disabled?: boolean
  }[]
  handleActionClick: (action: string, data: {}) => void
  children: ReactNode
}

const Container = ({
  data,
  actionOptions,
  handleActionClick,
  children,
}: Props) => {
  return (
    <div className={`${styles.item} border -mb-px`}>
      <div
        className={`flex flex-wrap items-center content-center justify-between h-full `}
      >
        <div className="flex flex-col my-2 md:flex-row  justify-between flex-wrap">
          {children}
        </div>
        <div className="flex justify-end md:items-center">
          <div className="px-3 flex-shrink-0 text-right">
            {actionOptions.length > 0 && (
              <ActionsButton
                options={actionOptions}
                onActionClick={(action) => handleActionClick(action, data)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Container
