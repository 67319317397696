import React from "react"

type Props = {
  children: any
  className?: string
}

const TabContent = (props: Props) => {
  const { children, className = null } = props
  if (className) {
    return <div className={className}>{children}</div>
  } else {
    return <>{children}</>
  }
}

export default TabContent
