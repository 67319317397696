// extracted by mini-css-extract-plugin
export var content = "AddRecipeWasteForm-module--content--jK+J0";
export var dateField = "AddRecipeWasteForm-module--dateField--7Il0d";
export var formBottom = "AddRecipeWasteForm-module--formBottom--GH8w4";
export var header = "AddRecipeWasteForm-module--header--SZbii";
export var inputCol = "AddRecipeWasteForm-module--inputCol--I0I27";
export var productMeta = "AddRecipeWasteForm-module--productMeta--VQ4Y3";
export var productName = "AddRecipeWasteForm-module--productName--CTaot";
export var quantityInput = "AddRecipeWasteForm-module--quantityInput--u8Ig8";
export var quantityInputSecond = "AddRecipeWasteForm-module--quantityInputSecond--ISvze";
export var quantityLabel = "AddRecipeWasteForm-module--quantityLabel--AvYud";
export var subValueContainer = "AddRecipeWasteForm-module--subValueContainer--vsRhU";
export var subValueLabel = "AddRecipeWasteForm-module--subValueLabel--tqKHx";
export var summary = "AddRecipeWasteForm-module--summary--O2IZQ";
export var summaryItem = "AddRecipeWasteForm-module--summaryItem--5FymI";
export var summaryItemList = "AddRecipeWasteForm-module--summaryItemList--QlKex";