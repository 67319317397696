import { Icon, IconProp } from "@fortawesome/fontawesome-svg-core"
import { businessTypes } from "./constants"

export interface Supplier {
  id: string
  name: string
  contactName: string
  email: string
  emails?: null
}
export interface Product {
  id: string
  name: string
  description?: string
  barcode?: string
  code: string
  size: number
  measure?: string
  category?: string
  subCategory: string
  unit?: string | null
  orderQty?: null
  orderQtyInCase?: null
  orderCaseSize?: null
  dnQty: number
  receivedQty: number
  receivedQtyInCase: boolean
  price?: number
  comment?: null
  isConfirmed: boolean
  productDiscrepancies?: null
  quantity: number
  packaging: "single" | "multiple"
}

export interface GPReport {
  categorisedProducts: object[]
  closingStockTakeReportId: string
  createdAt: string
  filterCategories: {
    includeUncategorised: boolean
    mainCategories: [
      {
        includeUncategorised: boolean
        name: string
        subCategories: string[]
      }
    ]
  }
  id: string
  isManuallyEdited: boolean
  openingStockTakeReportId: string
  organizations: string[]
  periodFrom: string
  periodTo: string
  sales: {
    id: string
  }
  totals: {
    isManuallyEditedExpectedGP: boolean
    isManuallyEditedSoldValue: boolean
    totalActualCOGS: number
    totalActualGP: number
    totalActualGPPercent: number
    totalExpectedCOGS: number
    totalExpectedGP: number
    totalExpectedGPPercent: number
    totalSoldValue: number
    totalVarianceGP: number
    totalVarianceGPPercent: number
    totalWastePercent: number
    totalWasteValue: number
  }
  uncategorisedProducts: {
    categoryTotals: {
      name: string
      totalCOGS: number
      totalCostClosingStock: number
    }
    products: [
      {
        barcode: string
        category: string
        id: string
        measure: string
        name: string
        price: number
        size: number
        subCategory: string
        unit: string
      }
    ]
  }
  updatedAt: string
}

export interface ErrorResponse {
  message: string
  errors?: { defaultMessage: string }[]
  status: number
}
export interface PageableData<A> {
  size: number
  totalPages: number
  totalElements: number
  numberOfElements: number
  error: string | undefined
  content: Array<A>
  empty: boolean
  first: boolean
  last: boolean
  number: number
}
export interface ListItem {
  id: string
  name: string
  text: string
  date: Date
  type?: string
  status?: string | undefined
  organization: string
}

export interface FileItem {
  fileId: string
  createdAt?: string
  fileName: string
}
export interface ProductOrder {
  barcode: string
  barcodes: string[]
  category: string
  description: string
  measure: string
  price: number | ""
  size: number | string
  subCategory: string
  id?: any
  groupId?: string
  unit: string
  productCase: ProductCase | ""
  packaging: "single" | "multiple"
  quantity: number
  allergens: string[]
}

export interface ProductFormData extends ProductOrder {
  generateBarcode?: boolean
  posId?: string
  notes?: string
  code?: string
  supplierId?: string
  supplierName?: string
}

export interface ProductCase {
  code?: any
  size: number
  price: number
}

export interface RecipeIngredient {
  cost: number | null
  measure: string
  product: Product
  usedQty: number | null
  usedQtyInProductMeasure?: number
}

export interface ProductCategory {
  label: string
  value: string
  icon?: Icon
}

export interface Address {
  city: string
  postCode: string
  addressLine1: string

  addressLine2?: string
  companyName?: string
  country?: string
  zoneId?: string
}
const bTypes = businessTypes.map((t) => t.value)
export type BusinessType = (typeof bTypes)[number]

export interface ContactDetails {
  email: string
  firstname: string
  lastname: string
  telephone: string
}
export interface Organization {
  additionalContacts?: any
  address: Address
  businessType: BusinessType
  companyName: string
  contactDetails?: ContactDetails
  deliveryAddress?: Address
  description?: string
  employeesNumberRange?: string
  id: string
  isDemoAccount?: boolean
  logo?: string | null
  masterOrgId?: string | null
  orgCreator?: string | null
  subOrgIds?: string[]
  mainOrgId?: string
  website?: string
  type?: "SUB" | "STANDARD" | "MAIN"
}

export interface OrganizationPermissions {
  general: {
    isMain: boolean
    isSub: boolean
  }
  products: {
    editSupplier: boolean
    remove: boolean
  }
  dishes: {
    remove: boolean
  }
  recipes: {
    remove: boolean
  }
  suppliers: {
    remove: boolean
  }
}

export interface Permission {
  id?: string
  name: string
  category?: string
  permissions: { read: boolean; modify: boolean; delete: boolean }
}

export enum DiscrepancyStatus {
  PENDING = "PENDING",
  RESOLVED = "RESOLVED",
}
export interface MenuItem {
  link: string
  name: string
  disabled?: boolean
  label?: string
  status?: string
}
export interface ParentMenuItem extends MenuItem {
  icon: IconProp
  children?: MenuItem[]
}

export interface IntegrationButtonProps {
  status?: "ACTIVE" | "INACTIVE" | undefined
  id?: string | null
  onConnectionChange?: () => void
  canConnect: boolean
  canDisconnect: boolean
}

export interface GrowyzeBackendGETResponse<T> {
  content: T[]
  empty: boolean
  first: boolean
  last: boolean
  number: number
  numberOfElements: number
  pageable: {
    page: number
    size: number
    sort: string
  }
  size: number
  sort: {
    empty: boolean
    sorted: boolean
    unsorted: boolean
  }
  totalElements: number
  totalPages: number
}
export interface MarketplaceSupplier extends Supplier {
  marketplaceSupplier: {
    email: string
    id: string
    name: string
  }
}

export interface FlexiblePeriod {
  from: Date | string | undefined
  to: Date | string | undefined
  enteredTo: Date | string | undefined
}

export interface ExtendedFlexiblePeriod extends FlexiblePeriod {
  inUserTimezone: FlexiblePeriod
}

export interface SubCategory {
  active: boolean
  name: string
  desc?: string | null
}

export interface Category {
  active: boolean
  name: string
  subCategories: SubCategory[]
  id?: string
  default?: boolean
  organizations?: string[]
  desc: string | null
  mainCategoryId?: string | null
}

export interface CategoryGroup {
  name: string
  categories: Category[]
}

export interface FilterCategories {
  includeUncategorised: boolean
  mainCategories: {
    name: string
    subCategories: string[]
    includeUncategorised: boolean
  }[]
}

export interface CategoryParams {
  includeUncategorised: boolean
  mainCategories: null | MainCategory[]
}

export interface MainCategory {
  includeUncategorised: boolean
  name: string
  subCategories: string[]
}
