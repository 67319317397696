import { get, post, put, remove } from "./api"
import { FileItem } from "./types"
import Invoice from "../components/invoices/Invoice/Invoice"
import { decimalCount, roundNumber } from "./helpers"
import { AccountingPublishParams } from "./accounting"

export const getInvoices = (params) => {
  return get("/invoices", params)
}

export const addInvoice = (invoice) => {
  return post("/invoices", invoice)
}

export const updateInvoice = (invoiceId, params) => {
  return put(`/invoices/${invoiceId}`, params)
}

export const querySupplier = (invoiceId, params) => {
  return post(`/invoices/${invoiceId}/query-supplier`, params, {
    headers: { "Content-Type": "multipart/form-data" },
  })
}

export const rejectInvoice = (invoiceId, params) => {
  return post(`/invoices/${invoiceId}/reject-invoice`, params, {
    headers: { "Content-Type": "multipart/form-data" },
  })
}

export const approveInvoice = (invoiceId) => {
  return post(`/invoices/${invoiceId}/approve-invoice`)
}

export const createInvoiceFromDeliveryNote = (deliveryNoteId) => {
  return post(`/invoices/create-from-dn?deliveryNoteId=${deliveryNoteId}`)
}

export const removeInvoice = (invoiceId) => {
  return remove(`/invoices/${invoiceId}`)
}

export const updateInvoiceFiles = async (
  invoiceId: string,
  params: { files: FileItem[] }
) => {
  return post(`/invoices/${invoiceId}/files`, params)
}

export const createDeliveryFromInvoice = async (invoiceId) => {
  return post(`/delivery-notes/from-invoice?invoiceId=${invoiceId}`)
}

export const publishToAccounting = (
  provider: string,
  invoiceId: string,
  params: any
) => {
  return post(`/invoices/${invoiceId}/${provider}`, params)
}

export const caseSizeToUnitSize = (
  qty: number | null,
  product: InvoicedProduct
) => (qty !== null ? product.orderCaseSize * qty : "?")

export const unitSizeToCaseSize = (
  qty: number | null,
  product: InvoicedProduct
) => (qty !== null && product.orderCaseSize ? qty / product.orderCaseSize : "?")

export const getUnitQty = (
  qty: number | null,
  inCase: boolean,
  product: InvoicedProduct
) => {
  let q = qty !== null ? (inCase ? caseSizeToUnitSize(qty, product) : qty) : "?"
  if (q !== "?") {
    q = decimalCount(q) > 3 ? parseFloat(q).toFixed(3) : q
  }
  return q
}

export const getCaseQty = (
  qty: number | null,
  inCase: boolean,
  product: InvoicedProduct
) => {
  let q = qty !== null ? (inCase ? qty : unitSizeToCaseSize(qty, product)) : "?"
  if (q !== "?") {
    q = decimalCount(q) > 3 ? parseFloat(q).toFixed(3) : q
  }
  return q
}

export const getUnitPrice = (
  price: number,
  inCase: boolean,
  product: InvoicedProduct
) => {
  return roundNumber(
    inCase && product.orderCaseSize ? price / product.orderCaseSize : price,
    3
  )
}

export const getCasePrice = (
  price: number,
  inCase: boolean,
  product: InvoicedProduct
) => {
  return roundNumber(inCase ? price : price * product.orderCaseSize, 3)
}

export interface Supplier {
  id: string
  name: string
  contactName: string
  email: string
  emails?: any
  currency: string
}

export interface AccountingAccount {
  code: string
  description: string
  id: string
  name: string
  type: string
  taxRate?: any
}

export interface InvoicedProduct {
  description: string
  barcode: string
  code?: any
  size: number
  measure: string
  category: string
  subCategory: string
  unit: string
  orderCaseSize?: any
  dnReceivedQty: number
  receivedQtyInCase: boolean
  invoiceQty: number
  invoiceQtyInCase: boolean
  invoicePrice: number
  orderPrice: number
  expectedTotalCost?: any
  xero?: { account: AccountingAccount }
  sage?: { account: AccountingAccount }
  invoicedTotalCost?: any
  comment?: any
  isConfirmed: boolean
  isAcceptedPrice?: any
  isDuplicated?: any
  productDiscrepancies?: any
}

export interface ExtractedFile {
  fileId: string
  fileName: string
  createdAt: Date
}

export interface Invoice {
  id: string
  organizations: string[]
  invoiceNumber: string
  deliveryNoteNumber?: any
  deliveryNoteId?: any
  po?: any
  dateOfIssue: Date
  dateOfScanning: Date
  approvedDate?: any
  inQueryDate?: any
  rejectedDate?: any
  supplier: Supplier
  products: InvoicedProduct[]
  extractedFile: ExtractedFile | undefined
  files?: any
  totalCost: number
  expectedTotalCost?: any
  invoicedTotalCost?: any
  deltaTotalCost?: any
  deltaInvoicedExpectedTotalCost?: any
  globalDiscrepancies?: any
  status: string
  message?: any
  grossTotalCost?: number
  totalVat?: number
  dueDate?: Date
  messageQueryToSupplier?: any
  hasQtyDiscrepancies: boolean
  hasProductPriceDiscrepancies: boolean
  hasNoReceivedProducts: boolean
  hasNoInvoicedProducts: boolean
  hasNoOrderedProducts: boolean
  hasTotalCostDiscrepancy: boolean
  organizationsNames: string[]
  xeroInvoice?: AccountingPublishParams
  sageInvoice?: AccountingPublishParams
}
