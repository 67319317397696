import React, { useState, useEffect, useRef } from "react"
import { usePromise, usePrevious, useMountedState } from "react-use"
import { getProducts } from "services/product"
import { searchSuppliers } from "services/supplier"
import {
  faFilter,
  faPlus,
  faCheck,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/pro-regular-svg-icons"
import {
  faPencil,
  faSpinnerThird,
  faTimes,
} from "@fortawesome/pro-light-svg-icons"
import SearchInput from "components/forms/SearchInput"
import AsyncSelect from "components/forms/AsyncSelect"
import FilterSelect from "components/forms/FilterSelect"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  productCategoriesCombined,
  productSingleUnits,
  productSearchQueryParameters,
  searchFilterSelectStyles,
} from "services/constants"

import classNames from "classnames/bind"
//@ts-ignore
import * as styles from "./NewProductSelect.module.css"
import ProductSelectItem from "../ProductSelectItem/ProductSelectItem"
import { faCube, faWineBottle } from "@fortawesome/pro-solid-svg-icons"
import EditPrice from "../EditPrice/EditPrice"
import {
  isTouchDevice,
  setQtyInputValues,
  sumArrayValues,
} from "services/helpers"
import { ItemFromSelector, SelectedItem } from "./types"

const cx = classNames.bind(styles)

export const hasQuantities = (item: SelectedItem) =>
  sumArrayValues(Object.values(item.quantities)) > 0

type NewProductSelectProps = {
  [key: string]: any
}

const NewProductSelect = ({
  onSelect,
  selectedSupplier,
  searchBySupplier,
  searchByBarcode,
  searchBySKU,
  searchByCategories,
  searchByPackaging,
  onAddItem,
  onClose,
  priceLabel,
  highlightPosId,
  multiSelect = true,
  negativeQuantitiesAllowed = false,
  requiredFields = [],
  qtyInputTypes = [{ label: "Qty", shortLabel: "Q", quantityKey: "qty" }],
  enablePriceEdit = true,
  enableMultiplePackaging = true,
  qtyPicker = true,
  createNewProduct = null,
  forcePackingForProducts = {},
}: NewProductSelectProps) => {
  const _isMounted = useMountedState()
  const fromPromise = usePromise()
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const [loading, setLoading] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [lastScrollTop, setLastScrollTop] = useState(0)
  const [queryParameter, setQueryParameter] = useState({
    label: "name",
    value: "partialProductName",
  })
  const [loadedAll, setLoadedAll] = useState(false)
  const [summaryOpen, setSummaryOpen] = useState(false)

  const [productData, setProductData] = useState<{
    content: Array<ItemFromSelector>
    totalElements: number
  }>({
    content: [],
    totalElements: 0,
  })
  const [q, setQ] = useState("")
  const previousQ = usePrevious(q)
  const [supplier, setSupplier] = useState(
    selectedSupplier ? selectedSupplier : null
  )
  const [editItem, setEditItem] = useState<SelectedItem | undefined>(undefined)

  const previousSupplier = usePrevious(supplier)

  const [packaging, setPackaging] = useState<{ value: string } | null>(null)
  const previousPackaging = usePrevious(packaging)

  const [category, setCategory] = useState<{ value: string } | null>(null)
  const previousCategory = usePrevious(category)

  const hasFilters =
    searchBySupplier ||
    searchByBarcode ||
    searchBySKU ||
    searchByCategories ||
    searchByPackaging
  // const [selectedPackaging, setSelectedPackaging] = useState([])

  const [paginationData, setPaginationData] = useState({
    page: 0,
    size: 10,
    totalPages: "",
    totalElements: "",
    numberOfElements: "",
  })
  const previousPage = usePrevious(paginationData.page)

  const [selectedProducts, setSelectedProducts] = useState<Array<SelectedItem>>(
    []
  )

  const selectedProductsWithQuantities = () =>
    selectedProducts.filter(hasQuantities)

  const searchParameters = productSearchQueryParameters.filter((p) => {
    let isSKU = false
    let isBarcode = false
    if (!searchBySKU && p.value === "partialCode") {
      isSKU = true
    }
    if (!searchByBarcode && p.value === "partialBarcode") {
      isBarcode = true
    }
    return !isSKU && !isBarcode
  })

  const getFilterCount = () => {
    let count = 0
    if (supplier) {
      count++
    }
    if (packaging) {
      count++
    }
    if (category) {
      count++
    }
    return count
  }

  const filterCount = getFilterCount()

  const onSelectItem = ({ item, packaging, quantities }) => {
    const newSelectedItem = {
      ...item,
      packaging,
      quantities,
      selectedPrice:
        packaging === "multiple" ? item.productCase.price : item.price,
    }

    setSelectedProducts(
      selectedProducts
        // Clear any previous occurences of the product to avoid duplicates
        .filter((p) => p.id !== item.id)
        // Attach the selected product to the list of selected products
        .concat([newSelectedItem])
    )
    if (!multiSelect) {
      onSelect(newSelectedItem)
      resetState()
    }
  }

  const deselectItem = (item) => {
    const arrayWithoutTarget = selectedProducts.filter((i) => i.id !== item.id)
    setSelectedProducts(arrayWithoutTarget)
  }

  const onEdit = (item) => {
    setEditItem(item)
  }

  const resetState = () => {
    setSelectedProducts([])
  }

  const handlePriceChange = (item, price) => {
    const latestProducts = [...selectedProducts]
    setSelectedProducts(
      latestProducts.map((p) => {
        if (p.id === item.id) {
          return { ...p, selectedPrice: price }
        } else {
          return p
        }
      })
    )
  }

  const summaryContent = (
    <>
      <div className="flex-grow w-full relative overflow-hidden">
        <div className="flex flex-col w-full h-full overflow-auto">
          {selectedProductsWithQuantities().map((p) => {
            return (
              <div key={p.id} className="flex items-center px-4 py-3 border-b">
                <FontAwesomeIcon
                  icon={p.packaging === "multiple" ? faCube : faWineBottle}
                  className="text-primaryBlue self-start mr-3"
                />
                <div className="flex flex-col flex-grow">
                  <h2 className="text-primaryBlue font-sansSemiBold font-semibold mb-2">
                    {p.name}
                  </h2>
                  <div className="flex flex-wrap items-baseline justify-start">
                    {qtyInputTypes.length > 0 &&
                      qtyInputTypes.map((qtyInputType) => {
                        return (
                          <div key={qtyInputType.quantityKey}>
                            <span className="uppercase text-xs text-gray-600 mr-2">
                              {qtyInputType.label}
                            </span>
                            <span className="text-primaryBlue font-semibold font-sansSemiBold mr-4">
                              {p.quantities[qtyInputType.quantityKey]}
                            </span>
                          </div>
                        )
                      })}
                    {enablePriceEdit && (
                      <button
                        onClick={() => onEdit(p)}
                        className="bg-primaryBlueVeryLight flex items-center rounded-full px-3 mr-4"
                      >
                        <span className="uppercase text-xs text-gray-600 mr-2">
                          {priceLabel ? priceLabel : "Price"}
                        </span>
                        <span className="text-primaryBlue font-semibold font-sansSemiBold mr-2">
                          {p.selectedPrice}
                        </span>

                        <FontAwesomeIcon
                          className="text-primaryBlueLighter text-sm"
                          icon={faPencil}
                        />
                      </button>
                    )}
                  </div>
                </div>
                <button
                  className="text-red-600"
                  onClick={() => deselectItem(p)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )

  const getData = async () => {
    if (!_isMounted()) {
      return
    }
    setLoading(true)

    interface SearchParams {
      page: number
      size: number
      sort: string
      supplierId?: string
      supplierName?: string
      partialProductName?: string
      partialCode?: string
      partialBarcode?: string
      partialPosId?: string
      unit?: string
      subCategory?: string
      extended: boolean
    }

    const params: SearchParams = {
      page: paginationData.page,
      size: paginationData.size,
      sort: "barcode,asc",
      extended: true,
    }

    if (supplier) {
      params.supplierId = supplier.id
    }

    if (packaging) {
      params.unit = packaging.value

      if (previousPackaging && previousPackaging.value !== packaging.value) {
        params.page = 0
      }
    }

    if (q) {
      switch (queryParameter.value) {
        case "partialProductName":
          params.partialProductName = q
          break
        case "partialCode":
          params.partialCode = q
          break
        case "partialBarcode":
          params.partialBarcode = q
          break
        case "partialPosId":
          params.partialPosId = q
          break
        default:
          params.partialProductName = q
          break
      }
    }

    if (category) {
      params.subCategory = category.value

      if (previousCategory && previousCategory.value !== category.value) {
        params.page = 0
      }
    }

    // fromPromise prevents call on unmount of component
    const result = await fromPromise(getProducts(params))

    if (result && !result.error) {
      const newContent = [
        ...result.content.map((item) => {
          return {
            ...item,
          }
        }),
      ]

      if (paginationData.page === 0) {
        setProductData({ ...result, content: [...newContent] })
      } else {
        setProductData({
          ...result,
          content: [...productData.content, ...newContent],
        })
      }

      // Fix scrollTop after products are appended
      // only on touch devices
      if (
        isTouchDevice() &&
        loadingMore &&
        lastScrollTop > 0 &&
        scrollRef.current != null
      ) {
        scrollRef.current.scrollTop = lastScrollTop
      }

      setPaginationData({
        ...paginationData,
        size: result.size,
        totalPages: result.totalPages,
        totalElements: result.totalElements,
        numberOfElements: result.numberOfElements,
      })

      setLoadingMore(false)
      setLoadedAll(paginationData.page === result.totalPages - 1)
    }
    setLoading(false)
  }

  const resetPage = () => {
    if (paginationData.page === 0) {
      return getData()
    }
    setPaginationData({
      ...paginationData,
      page: 0,
    })
  }

  const handleEndReached = () => {
    if (loading || loadingMore || loadedAll) return
    loadMore()
  }

  const loadMore = () => {
    setLoadingMore(true)
    setPaginationData({ ...paginationData, page: paginationData.page + 1 })
  }

  const showFilters = () => {
    setFiltersOpen(true)
  }

  const onScroll = () => {
    if (scrollRef.current !== null) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current
      setLastScrollTop(scrollTop)

      if (scrollHeight - (scrollTop + clientHeight) < 300) {
        handleEndReached()
      }
    }
  }

  const getSelectedItem = (item) =>
    selectedProducts.find((p) => p.id === item.id)

  const isSelected = (item) => getSelectedItem(item)?.id === item.id

  const getQuantities = (item) => {
    const isSelected =
      selectedProducts.length > 0 &&
      selectedProducts.find((p) => p.id === item.id)

    if (isSelected) {
      return isSelected.quantities
    } else {
      return setQtyInputValues(qtyInputTypes, 0)
    }
  }

  const getPackaging = (item) => {
    const isSelected =
      selectedProducts.length > 0 &&
      selectedProducts.find((p) => p.id === item.id)

    if (isSelected) {
      return isSelected.packaging
    } else {
      return "single"
    }
  }

  useEffect(() => {
    if (!supplier) {
      if (!selectedSupplier) {
        getData()
      } else {
        setSupplier(selectedSupplier)
      }
    }
  }, [selectedSupplier, supplier])

  useEffect(() => {
    if (previousPage !== undefined) {
      getData()
    }
  }, [paginationData.page])

  useEffect(() => {
    if (previousSupplier || supplier) {
      resetPage()
    }
  }, [supplier])

  useEffect(() => {
    if (previousPackaging || packaging) {
      getData()
    }
  }, [packaging])

  useEffect(() => {
    if (previousCategory || category) {
      resetPage()
    }
  }, [category])

  useEffect(() => {
    if (previousQ || q) {
      resetPage()
    }
  }, [previousQ, q])

  return (
    <>
      <div className={styles.container}>
        {/* Overlay */}
        {/* {filtersOverlay} */}

        {hasFilters && (
          <div className={cx("filtersOverlay", { invisible: !filtersOpen })}>
            <div className="flex justify-between py-3 border-b px-4 md:px-6 flex-shrink-0">
              <h4 className="text-primaryBlue text-lg font-semibold font-sansSemiBold">
                Filters
              </h4>
              <button onClick={() => setFiltersOpen(false)} className="text-xl">
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <div className="flex-grow overflow-auto py-3">
              {searchBySupplier && (
                <div className="py-1 px-4 md:px-6 w-full">
                  <AsyncSelect
                    promise={searchSuppliers}
                    placeholder="All suppliers"
                    isDisabled={selectedSupplier || loading}
                    value={
                      selectedSupplier
                        ? {
                            value: selectedSupplier,
                            label: selectedSupplier.name,
                          }
                        : supplier
                        ? { value: supplier, label: supplier.name }
                        : null
                    }
                    isClearable={true}
                    optionLabel="name"
                    onChange={(val) => {
                      setSupplier(val ? val.value : null)
                    }}
                  />
                </div>
              )}
              {searchByCategories && (
                <div className="py-1 px-4 md:px-6 w-full">
                  <FilterSelect
                    options={productCategoriesCombined}
                    value={category}
                    getOptionLabel={(opt) => `${opt.groupLabel} - ${opt.label}`}
                    onChange={async (val) => {
                      await setCategory(val)
                    }}
                    placeholder="All categories"
                    isClearable={true}
                    isSearchable={true}
                    disabled={loading}
                    className="my-1 w-full text-sm md:text-base"
                  />
                </div>
              )}

              {searchByPackaging && (
                <div className="py-1 px-4 md:px-6 w-full">
                  <FilterSelect
                    options={productSingleUnits}
                    value={packaging}
                    onChange={async (val) => {
                      setPackaging(val)
                    }}
                    placeholder="All packaging"
                    isClearable={true}
                    disabled={loading}
                    isSearchable={true}
                    className="my-1 w-full text-sm md:text-base"
                  />
                </div>
              )}
            </div>
            <div className="flex justify-between py-3 border-t px-4 md:px-6 flex-shrink-0 relative z-30">
              <button
                onClick={() => setFiltersOpen(false)}
                className="button button--primaryGreen"
                disabled={loading}
              >
                {loading && (
                  <FontAwesomeIcon
                    icon={faSpinnerThird}
                    spin
                    className="mr-2"
                  />
                )}
                Show {productData.totalElements} results
              </button>
            </div>
          </div>
        )}

        {/* Header */}
        <div className={cx("subHeader")}>
          <div className="my-2 px-1 w-full flex">
            <div className="relative w-full flex">
              <SearchInput
                label="Name"
                placeholder={`Product ${queryParameter.label}`}
                className="form-control rounded md:text-base"
                onSearchChange={(val) => {
                  setQ(val)
                }}
                resetVal={queryParameter}
              />
              <FilterSelect
                options={searchParameters}
                value={queryParameter && queryParameter.value}
                onChange={(val) => {
                  setQueryParameter(val)
                }}
                stylesOverride={searchFilterSelectStyles}
              />
            </div>
            {hasFilters && (
              <button
                onClick={showFilters}
                className="bg-paleBlue border relative rounded-r px-4 -ml-1 rounded-l-none"
              >
                <FontAwesomeIcon icon={faFilter} />
                {filterCount > 0 && (
                  <span className="text-center text-xs font-sansSemiBold font-semibold absolute -top-2 -right-2 bg-primaryBlue text-white w-5 h-5 rounded-full block">
                    {filterCount}
                  </span>
                )}
              </button>
            )}
            {onClose && (
              <button
                onClick={() => {
                  onClose()
                  resetState()
                }}
                className="text-xl px-3 ml-3"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            )}
          </div>
        </div>

        <div className={styles.content}>
          <div
            className="w-full flex-grow overflow-auto"
            ref={scrollRef}
            onScroll={onScroll}
          >
            {productData.content.length === 0 && (
              <div className={styles.noProducts}>
                <p className="mb-4">
                  {loading ? "Loading..." : "No products found"}
                </p>
                {onAddItem && (
                  <button
                    type="button"
                    onClick={onAddItem}
                    className="
              button button--autoWidth button--primaryGreen
              addManualButton
            "
                  >
                    <FontAwesomeIcon icon={faPlus} className="mr-3" />
                    Add item
                  </button>
                )}
              </div>
            )}
            {productData.content.map((item, index) => {
              return (
                <ProductSelectItem
                  key={`${item.id}-${index}`}
                  item={item}
                  forcePackaging={forcePackingForProducts[item.id] ?? false}
                  qtyInputTypes={qtyInputTypes}
                  isSelected={isSelected(item)}
                  negativeQuantitiesAllowed={negativeQuantitiesAllowed}
                  onSelect={({ packaging, quantities }) =>
                    onSelectItem({
                      item,
                      packaging,
                      quantities,
                    })
                  }
                  onDeSelect={() => deselectItem(item)}
                  quantities={getQuantities(item)}
                  packaging={getPackaging(item)}
                  enableMultiplePackaging={enableMultiplePackaging}
                  requiredFields={requiredFields}
                  highlightPosId={highlightPosId}
                  qtyPicker={qtyPicker}
                  onSelectDisabled={
                    !multiSelect && selectedProducts.length >= 1 ? true : false
                  }
                />
              )
            })}
            {loadingMore && (
              <div className="w-full flex items-center justify-center px-4 py-1">
                <FontAwesomeIcon icon={faSpinnerThird} spin />
              </div>
            )}
          </div>
        </div>
        {(multiSelect || createNewProduct) && (
          <footer className={cx("footer", { open: summaryOpen })}>
            <div
              className={cx(
                "flex px-4 lg:px-6 py-3 lg:py-4 w-full items-center justify-end",
                {
                  "border-b": summaryOpen,
                }
              )}
            >
              {multiSelect && (
                <button
                  onClick={() => setSummaryOpen(!summaryOpen)}
                  className="flex items-start flex-grow"
                >
                  <FontAwesomeIcon
                    icon={summaryOpen ? faChevronDown : faChevronUp}
                    className="mr-3 mt-1"
                  />
                  <span className="font-semibold mr-4 text-left flex flex-grow">
                    <span className="text-primaryBlue mr-2">
                      {selectedProductsWithQuantities().length}{" "}
                      {selectedProductsWithQuantities().length === 1
                        ? "product"
                        : "products"}
                    </span>
                    <span className="text-gray-600">selected</span>
                  </span>
                </button>
              )}
              {createNewProduct && (
                <button
                  onClick={createNewProduct}
                  className={cx(
                    "button button--autoWidth button--primaryGreen",
                    { "mr-4": multiSelect }
                  )}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    className={styles.buttonIcon}
                  />
                  <span className={styles.buttonText}>Add item</span>
                </button>
              )}
              {!summaryOpen && multiSelect && (
                <button
                  type="button"
                  className="button button--autoWidth button--primaryGreen"
                  onClick={() => {
                    // Old method - Adding only products that have quantities, leaving for reference
                    // onSelect(selectedProductsWithQuantities())
                    onSelect(selectedProducts)
                    resetState()
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCheck}
                    className={styles.buttonIcon}
                  />
                  <span className={styles.buttonText}>Confirm</span>
                </button>
              )}
            </div>

            {summaryOpen && summaryContent}
            {/* Edit panel from bottom */}
            {editItem != undefined && (
              <>
                <div
                  className={cx("editOverlay", { show: editItem })}
                  onClick={() => setEditItem(undefined)}
                ></div>
                <div className={cx("editWrapper", { isOpen: editItem })}>
                  <div className="border-b py-2 px-4 text-center">
                    <h3 className="text-primaryBlue font-sansSemiBold font-semibold">
                      Edit price: {editItem.name}
                    </h3>
                  </div>
                  <EditPrice
                    item={editItem}
                    onSave={(price) => {
                      handlePriceChange(editItem, price)
                      setEditItem(undefined)
                    }}
                  />
                </div>
              </>
            )}
          </footer>
        )}
      </div>
    </>
  )
}

export default NewProductSelect
