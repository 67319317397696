import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//@ts-ignore
import * as styles from "./ItemIcon.module.css"

interface ItemIconProps {
  icon: any
  color: string
  backgroundColor: string
  linkURL: string
}

const ItemIcon = ({ icon, color, backgroundColor, linkURL }: ItemIconProps) => {
  return (
    <div className="pl-3 flex items-center">
      <Link to={linkURL} className={`${styles.imageWrapper} mr-4`}>
        <div
          className={styles.iconWrapper}
          style={{
            backgroundColor,
            color,
          }}
        >
          <FontAwesomeIcon icon={icon} />
        </div>
      </Link>
    </div>
  )
}

export default ItemIcon
