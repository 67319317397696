import { useState } from "react"
import * as queryString from "query-string"
export interface paginationDefaults {
  page: number
  size: number
  totalPages?: string
  totalElements?: string
  numberOfElements?: string
}

export const paginationDefaults: paginationDefaults = {
  page: 0,
  size: 20,
  totalPages: "",
  totalElements: "",
  numberOfElements: "",
}

export interface paginationProps extends paginationDefaults {
  pagination: any
  set: Function
  setFromResult: Function
  goto: Function
}

export const usePagination = () => {
  const queryParams = queryString.parse(location.search)

  const [paginationData, setPaginationData] = useState({
    ...paginationDefaults,
    page: Number(queryParams.page) || paginationDefaults.page,
  })

  const setFromResult = (result) => {
    setPaginationData({
      page: result.pageable.pageNumber ?? paginationData.page,
      size: result.size,
      totalPages: result.totalPages,
      totalElements: result.totalElements,
      numberOfElements: result.numberOfElements,
    })
  }

  return {
    ...paginationData,
    set: setPaginationData,
    setFromResult: setFromResult,
    goto: (page) => setPaginationData({ ...paginationData, page }),
  }
}

export default usePagination
