import React, { useCallback, useState } from "react"
import Modal from "react-modal"
import PropTypes from "prop-types"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons"

import Calendar from "components/common/Calendar/Calendar"

import * as styles from "./ConfirmModalStocktake.module.css"

Modal.setAppElement("body")

const ConfirmModalStocktake = ({
  onRequestClose,
  onConfirmDate,
  stocktake,
}) => {
  const [completedAt, setCompletedAt] = useState(new Date(stocktake.createdAt))

  const confirm = useCallback(
    (completedAt) => {
      onRequestClose()
      if (onConfirmDate) {
        onConfirmDate(completedAt)
      }
    },
    [onRequestClose, onConfirmDate]
  )

  return (
    <Modal isOpen onRequestClose={onRequestClose} portalClassName="confirm">
      <button
        type="button"
        onClick={onRequestClose}
        className="text-gray-700 absolute right-0 py-4 px-6"
      >
        <FontAwesomeIcon size="lg" icon={faTimes} />
      </button>
      <div className={styles.container}>
        <div className={styles.welcomeContent}>
          <h1 className="text-xl sm:text-2xl mb-4 mt-3">{`Finalise ${
            stocktake.name || "Stocktake"
          }`}</h1>
          <p className="text-lg text-gray-700">{`Finalising the stocktake will combine all areas into one single stock count report`}</p>
          <Calendar
            onSelect={(date) => {
              setCompletedAt(date)
            }}
            selectedDate={completedAt}
            mindate={null}
          />
        </div>

        <div className={styles.buttons}>
          <button
            type="button"
            onClick={onRequestClose}
            className="button--autoWidth px-4 py-2 font-semibold font-sansSemiBold text-gray-700"
          >
            cancel
          </button>
          <button
            type="button"
            onClick={() => confirm(completedAt)}
            className="button button--autoWidth button--primaryGreen"
          >
            confirm
          </button>
        </div>
      </div>
    </Modal>
  )
}

ConfirmModalStocktake.propTypes = {
  onRequestClose: PropTypes.func,
  onConfirmDate: PropTypes.func,
  stocktake: PropTypes.object,
}

export default ConfirmModalStocktake
