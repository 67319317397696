import React, { useState, useEffect, useContext, useRef } from "react"
import { usePromise } from "react-use"
import Header from "components/dashboard/Header/Header"
import Loader from "components/common/Loader/Loader"
import StockTake from "components/stock/StockTake/StockTake"
import {
  getDiscrepancyReport,
  updateDiscrepancyReportById,
  getStockTake,
  downloadDiscrepancyReport,
} from "services/stock-take"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faExclamationTriangle,
  faSyncAlt,
} from "@fortawesome/pro-duotone-svg-icons"
import {
  faFileDownload,
  faSync,
  faEllipsisH,
  faPencil,
} from "@fortawesome/pro-regular-svg-icons"
import {
  roundNumber,
  slugify,
  uuidv4,
  convertTimeStampFormat,
} from "services/helpers"
import { ModalContext } from "context/ModalContext"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/GlobalContextProvider"
import DropdownButton from "components/common/DropdownButton/DropdownButton"
import SearchInput from "components/forms/SearchInput"
import { useMediaQuery } from "react-responsive"
import Helmet from "react-helmet"
import { format } from "date-fns"
import PropTypes from "prop-types"
import DiscrepancyReportModal from "components/stock/DiscrepancyReportModal/DiscrepancyReportModal"
import DiscrepancyReportContentCategorised from "../DiscrepancyReportContentCategorised/DiscrepancyReportContentCategorised"

import * as styles from "./DiscrepancyReport.module.css"
import usePermissions from "hooks/usePermissions"
import { showError } from "services/toast"

const DiscrepancyReport = ({ discrepancyReportId }) => {
  const [discrepancyData, setDiscrepancyData] = useState({})
  const fromPromise = usePromise()
  const [loading, setLoading] = useState(true)
  const [generating, setGenerating] = useState(false)
  const dispatch = useContext(GlobalDispatchContext)
  const modal = useContext(ModalContext)
  const contentRef = useRef(null)
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
  const [query, setQuery] = useState("")
  const { organization } = useContext(GlobalStateContext)
  const discrepancyReportPermissionObj = usePermissions(
    "Stock discrepancy report"
  )
  const stockTakesPermissionObj = usePermissions("Stocktakes")

  const DEFAULT_ERROR_MESSAGE = "Something went wrong!"

  const getData = async (regenerate = false) => {
    if (!regenerate) {
      setLoading(true)
    }

    if (regenerate) {
      const {
        openingStockTakeReportId,
        filterCategories,
        includeProductsContainCustomMainCategory,
        includeProductsContainNullMainCategory,
        sales,
      } = discrepancyData

      const params = {}

      if (filterCategories) {
        params.filterCategories = filterCategories
      }

      if (includeProductsContainCustomMainCategory) {
        params.includeProductsContainCustomMainCategory =
          includeProductsContainCustomMainCategory
      }

      if (includeProductsContainNullMainCategory) {
        params.includeProductsContainNullMainCategory =
          includeProductsContainNullMainCategory
      }

      const updated = await fromPromise(
        updateDiscrepancyReportById(
          discrepancyReportId,
          openingStockTakeReportId,
          sales ? sales.id : null,
          params
        )
      )

      if (
        !updated ||
        [400, 401, 402, 403, 404, 405, 406, 407, 408, 409].includes(
          updated.status
        )
      ) {
        if (updated.status === 403) {
          // do nothing, message comes from api.js
        } else if (updated.status.toString().charAt(0) === "4") {
          let errorText = DEFAULT_ERROR_MESSAGE
          if (updated?.errors?.length > 0) {
            errorText = updated.errors[0].defaultMessage
          } else if (updated.message) {
            errorText = updated.message
          }
          return showError(errorText)
        } else {
          return showError(DEFAULT_ERROR_MESSAGE)
        }
      }
    }

    const getParams = {}

    if (query) {
      getParams.partialProductName = query
    }
    // fromPromise prevents call on unmount of component
    const result = await fromPromise(
      getDiscrepancyReport(discrepancyReportId, getParams)
    )

    if (
      result &&
      ![400, 401, 402, 403, 404, 405, 406, 407, 408, 409].includes(
        result.status
      )
    ) {
      setDiscrepancyData(result)
      setLoading(false)
      setGenerating(false)

      if (regenerate && contentRef.current) {
        contentRef.current.refreshContent()
      }
    } else {
      if (result.status === 403) {
        // do nothing, message comes from api.js
      } else if (result.status?.toString().charAt(0) === "4") {
        let errorText = DEFAULT_ERROR_MESSAGE
        if (result?.errors?.length > 0) {
          errorText = result.errors[0].defaultMessage
        } else if (result.message) {
          errorText = result.message
        }
        showError(errorText)
      } else {
        showError(DEFAULT_ERROR_MESSAGE)
      }
    }

    setLoading(false)
    setGenerating(false)
  }

  useEffect(() => {
    getData()
  }, [query])

  const regenerate = () => {
    setGenerating(true)
    getData(true)
  }

  const handleActionClick = ({ type }) => {
    const fileName = slugify(discrepancyData.name)
    switch (type) {
      case "export.csv":
        downloadDiscrepancyReport(discrepancyReportId, "csv", fileName)
        break
      case "export.pdf":
        downloadDiscrepancyReport(discrepancyReportId, "pdf", fileName)
        break
      case "export.xlsx":
        downloadDiscrepancyReport(discrepancyReportId, "xlsx", fileName)
        break
      default:
        break
    }
  }

  const openStockTake = async () => {
    const stocktake = await getStockTake(discrepancyData.stockTakeReportId)
    dispatch({ type: "RESET_NEW_STOCKTAKE" })

    const params = {
      id: stocktake.id,
      name: stocktake.name,
      createdAt: stocktake.createdAt,
      completedAt: stocktake.completedAt,
      note: stocktake.note,
      status: stocktake.status,
      totalAmount: stocktake.totalAmount,
      totalDiscrepancyAmount: discrepancyData.totalDiscrepancyAmount,
      discrepancyReportId: discrepancyReportId,
      key: uuidv4(),
      unsynced: false,
      products: [],
      recipes: [],
      subStockTakeReports: [],
    }
    dispatch({
      type: "CREATE_NEW_STOCKTAKE",
      payload: {
        stockTakeData: params,
      },
    })

    for (let i = 0; i < stocktake.subStockTakeReports.length; i++) {
      const subRep = stocktake.subStockTakeReports[i]

      dispatch({
        type: "CREATE_NEW_STOCKTAKE_SUBREPORT",
        payload: {
          stockTakeData: {
            ...subRep,
            key: uuidv4(),
            products: [],
            recipes: [],
            unsynced: false,
          },
        },
      })
    }
    return modal.showModal(StockTake, { onUpdate: () => regenerate() })
  }

  const onEditStocktakeReport = () => {
    modal.showModal(DiscrepancyReportModal, {
      stockTakeReportId: discrepancyReportId,
      title: "Edit stocktake report",
      loadingText: "Updating the Stock Discrepancy Report",
      discrepancyReportData: discrepancyData,
      isUpdate: true,
    })
  }

  return (
    <>
      <Helmet>
        <title>Discrepancy report</title>
      </Helmet>
      <div className={styles.container}>
        <Header
          to="/dashboard/stock/stocktake?tab=history"
          title="Discrepancy report"
        />

        <div className={styles.content}>
          <Loader
            isLoading={loading}
            style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
          >
            Loading report..
          </Loader>

          <div className={styles.header}>
            <div className={styles.headerContent}>
              <div className={styles.headerTopColumn}>
                <div className={styles.thumbnail}>
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                  <span className={styles.thumbnailBadge}>
                    <span>
                      <FontAwesomeIcon size="xs" icon={faSyncAlt} />
                    </span>
                  </span>
                </div>
                <div
                  className="my-2 mr-4 flex flex-col"
                  style={{ maxWidth: !isTabletOrMobile ? "33.33%" : "53%" }}
                >
                  <h2 className="text-xl mb-1">{discrepancyData.name}</h2>
                  {!loading && (
                    <>
                      <div className="text-gray-700">
                        <span className="text-xs md:text-base mr-6">
                          Period:{" "}
                          <strong>{`${format(
                            new Date(discrepancyData.periodFrom),
                            "dd/MM/yy"
                          )}`}</strong>{" "}
                          -{" "}
                          <strong>{`${format(
                            new Date(discrepancyData.periodTo),
                            "dd/MM/yy"
                          )}
                        `}</strong>
                        </span>

                        <button
                          onClick={onEditStocktakeReport}
                          disabled={
                            !discrepancyReportPermissionObj?.permissions.modify
                          }
                        >
                          <FontAwesomeIcon icon={faPencil} />
                        </button>
                      </div>
                      <span className="text-xs text-gray-600">
                        {discrepancyData.updatedAt
                          ? `Updated at  ${convertTimeStampFormat(
                              discrepancyData.updatedAt,
                              organization?.address.zoneId
                            )}`
                          : `Created at ${convertTimeStampFormat(
                              discrepancyData.createdAt,
                              organization?.address.zoneId
                            )}`}
                      </span>
                    </>
                  )}
                </div>

                <div className="flex flex-1 items-center">
                  <div className="my-2 w-full max-w-md">
                    <SearchInput
                      label="Search by products"
                      placeholder="Search by products"
                      className="form-control rounded"
                      onSearchChange={setQuery}
                    />
                  </div>
                  <div className={styles.headerRight}>
                    <button
                      type="button"
                      disabled={!stockTakesPermissionObj?.permissions.modify}
                      className="hidden md:flex items-center text-orange-600 rounded hover:text-orange-500 font-sansSemiBold font-semibold px-0 lg:px-6 py-2 flex-shrink-0"
                      onClick={regenerate}
                    >
                      <FontAwesomeIcon
                        icon={faSync}
                        spin={generating}
                        className="mr-2 block"
                      />
                      Refresh
                    </button>
                  </div>
                  {discrepancyData.stockTakeReportId && !isTabletOrMobile && (
                    <button
                      className="button button--autoWidth ml-auto button--paleBlue mr-2 md:mr-4"
                      onClick={openStockTake}
                      disabled={!stockTakesPermissionObj?.permissions.read}
                    >
                      View stocktake
                    </button>
                  )}
                  <DropdownButton
                    containerClass="ml-3 md:ml-0"
                    onActionClick={handleActionClick}
                    label={<FontAwesomeIcon icon={faFileDownload} />}
                    mobileIcon={faEllipsisH}
                    options={[
                      ...(isTabletOrMobile
                        ? [
                            {
                              title: "Refresh",
                              key: "refresh",
                              icon: faSync,
                            },
                            {
                              title: "View stocktake",
                              key: "stocktake.view",
                              disabled:
                                !stockTakesPermissionObj?.permissions.read,
                            },
                          ]
                        : []),
                      {
                        title: "Export to .CSV",
                        key: "export.csv",
                        disabled:
                          !discrepancyReportPermissionObj?.permissions.read,
                      },
                      {
                        title: "Export to .XLSX",
                        key: "export.xlsx",
                        disabled:
                          !discrepancyReportPermissionObj?.permissions.read,
                      },
                      // { title: "Export to .PDF", key: "export.pdf" },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.reportContent}>
            <DiscrepancyReportContentCategorised data={discrepancyData} />
          </div>
        </div>
        {!loading && (
          <div className={styles.bottomSummary}>
            Total value:{" "}
            <span
              className={`font-sansSemiBold font-semibold ${
                discrepancyData.totalDiscrepancyAmount < 0
                  ? "text-primaryPink"
                  : discrepancyData.totalDiscrepancyAmount > 0
                  ? "text-primaryGreen"
                  : ""
              }`}
            >
              {roundNumber(discrepancyData.totalDiscrepancyAmount)}
            </span>
          </div>
        )}
      </div>
    </>
  )
}

DiscrepancyReport.propTypes = {
  discrepancyReportId: PropTypes.string,
}

export default DiscrepancyReport
