import React from "react"
import PropTypes from "prop-types"
import dot from "services/dot-prop-immutable"
import { subStockTakeHelper } from "services/stock-take"

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const initialOrder = {
  supplier: null,
  po: null,
  id: null,
  status: undefined,
  notes: "",
  deliveryAddress: {},
  expectedDeliveryDate: null,
  items: [],
}

const initialDeliveryNote = {
  supplier: null,
  deliveryNoteNumber: "",
  dateOfScanning: "",
  deliveryDate: "",
  files: null,
  extractedFile: null,
  selectedFiles: [],
  isCreatedManuallyWithoutOrder: false,
  globalDiscrepancies: [],
  hasDNQtyDiscrepancies: false,
  hasNoDeliveredProducts: false,
  hasNoOrderedProducts: false,
  hasReceivedQtyDiscrepancies: false,
  hasReceivedOrderQtyDiscrepancies: false,
  id: null,
  po: null,
  products: [],
  status: "DRAFT",
}

const initialInvoice = {
  supplier: null,
  invoiceNumber: "",
  dateOfScanning: "",
  deltaTotalCost: null,
  expectedTotalCost: null,
  dateOfIssue: "",
  deliveryDate: "",
  files: null,
  extractedFile: null,
  selectedFiles: [],
  globalDiscrepancies: [],
  hasNoInvoicedProducts: false,
  hasNoOrderedProducts: false,
  hasNoReceivedProducts: false,
  hasProductPriceDiscrepancies: false,
  hasQtyDiscrepancies: false,
  hasTotalCostDiscrepancy: false,
  deliveryNoteNumber: null,
  message: "",
  totalCost: null,
  id: null,
  po: null,
  products: [],
  status: "DRAFT",
}

const initialStockTake = {
  completedAt: "",
  createdAt: "",
  id: null,
  name: "",
  note: "",
  key: null,
  unsynced: true,
  products: [],
  recipes: [],
  totalAmount: 0,
  discrepancyReportId: null,
  totalDiscrepancyAmount: null,
  status: undefined,
  subStockTakeReports: [],
}

const initialStockTakeSheet = {
  createdAt: "",
  id: null,
  name: "",
  note: "",
  key: null,
  unsynced: true,
  organizations: [],
  selectedOrganizations: [],
  products: [],
  updatedAt: "",
  version: null,
}

const initialStockTransfer = {
  canceledAt: "",
  canceledByOrgId: "",
  createdAt: "",
  hasProductsComments: null,
  id: "",
  nonMatchingReceivedProductsCount: 0,
  organizations: [],
  products: [],
  recipes: [],
  dishes: [],
  receivedAt: "",
  receiverOrgId: "",
  receiverOrgName: "",
  receiverOrg: {},
  senderOrgId: "",
  senderOrgName: "",
  senderOrg: {},
  sentAt: "",
  status: "DRAFT",
}

const initialWasteGroup = {
  id: null,
  updatedAt: "",
  products: [],
  totalCost: null,
}

export const initialPaymentPlan = {
  creationDate: null,
  expirationDate: null,
  id: "",
  orgId: "",
  stripeCustomerId: "",
  stripeSubscriptionId: "",
  stripePaymentRecurring: "",
  type: "DEFAULT",
  enabledFeatures: [],
}

export const initialOnboardingSteps = {
  id: null,
  orgId: null,
  hasAddedProducts: null,
  hasCheckedCorrectPricesAndBarcodes: null,
  hasAddedStocktake: null,
  hasAddedWaste: null,
  hasAddedRecipe: null,
}

export const newMinQuantityOrder = {
  productsInBasket: [], //Needs to have quantity property
}

export const initialGlobalOrder = {
  products: [],
}

export const initalAccounting = {
  accounts: null,
  suppliers: null,
  taxRates: null,
  taxRateZero: null,
}

const initialState = {
  user: null,
  organization: null,
  organizationPermissions: null,
  paymentPlan: initialPaymentPlan,
  organizations: [],
  selectedOrganizations: null,
  formattedOrganizations: [],
  newOrder: initialOrder,
  newMinQuantityOrder: newMinQuantityOrder,
  newDeliveryNote: initialDeliveryNote,
  newInvoice: initialInvoice,
  stockTakeSaveQueue: [],
  newStockTake: initialStockTake,
  newStockTakeSheet: initialStockTakeSheet,
  newStockTransfer: initialStockTransfer,
  onboardingSteps: initialOnboardingSteps,
  selectedWasteGroup: initialWasteGroup,
  sidebarCollapsed: false,
  mobileMenuOpen: false,
  mobileKeyboardOpen: false,
  selectedAiGeneratedRecipe: null,
  globalOrder: initialGlobalOrder,
  accounting: {},
}

function reducer(state, action) {
  console.info(`ACTION: ${action.type}`)
  switch (action.type) {
    case "RESET_STATE": {
      return initialState
    }
    case "TOGGLE_SIDEBAR": {
      return {
        ...state,
        sidebarCollapsed: !state.sidebarCollapsed,
      }
    }
    case "OPEN_MOBILE_MENU": {
      return {
        ...state,
        mobileMenuOpen: true,
      }
    }
    case "CLOSE_MOBILE_MENU": {
      return {
        ...state,
        mobileMenuOpen: false,
      }
    }
    case "KEYBOARD_IS_OPEN": {
      return {
        ...state,
        mobileKeyboardOpen: true,
      }
    }
    case "KEYBOARD_IS_CLOSED": {
      return {
        ...state,
        mobileKeyboardOpen: false,
      }
    }
    case "SET_USER": {
      return {
        ...state,
        user: action.payload,
      }
    }
    case "SET_COMBINED_USER_INFORMATION": {
      return {
        ...state,
        ...action.payload,
      }
    }
    case "SET_ORGANIZATION": {
      return {
        ...state,
        organization: action.payload,
        selectedOrganizations: [action.payload.id],
      }
    }
    case "UPDATE_ORGANIZATION": {
      return {
        ...state,
        organization: {
          ...state.organization,
          ...action.payload,
        },
      }
    }
    case "SET_PAYMENT_PLAN": {
      return {
        ...state,
        paymentPlan: action.payload,
      }
    }
    case "SET_ORGANIZATIONS": {
      return {
        ...state,
        organizations: action.payload,
      }
    }
    case "SET_SELECTED_ORGANIZATIONS": {
      return {
        ...state,
        selectedOrganizations: action.payload,
      }
    }
    case "UPDATE_NEW_ORDER": {
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          ...action.payload,
        },
      }
    }
    case "RESET_NEW_ORDER": {
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          ...initialOrder,
          _initialItems: [],
        },
      }
    }
    case "UPDATE_NEW_DELIVERY_NOTE": {
      return {
        ...state,
        newDeliveryNote: {
          ...state.newDeliveryNote,
          ...action.payload,
        },
      }
    }
    case "RESET_NEW_DELIVERY_NOTE": {
      const { resetSupplier } = action.options
      return {
        ...state,
        newDeliveryNote: {
          ...state.newDeliveryNote,
          ...initialDeliveryNote,
          supplier: resetSupplier ? null : state.newDeliveryNote.supplier,
        },
      }
    }
    case "UPDATE_NEW_INVOICE": {
      return {
        ...state,
        newInvoice: {
          ...state.newInvoice,
          ...action.payload,
        },
      }
    }
    case "RESET_NEW_INVOICE": {
      return {
        ...state,
        newInvoice: {
          ...state.newInvoice,
          ...initialInvoice,
        },
      }
    }

    /**
     * Main Stocktake actions
     */
    case "CREATE_NEW_STOCKTAKE": {
      const { stockTakeData } = action.payload
      return {
        ...state,
        newStockTake: stockTakeData,
      }
    }
    case "UPDATE_NEW_STOCKTAKE": {
      const { stockTakeData } = action.payload
      return {
        ...state,
        newStockTake: {
          ...state.newStockTake,
          ...stockTakeData,
          key: state.newStockTake.key,
          products: state.newStockTake.products
            ? state.newStockTake.products
            : [],
          subStockTakeReports: state.newStockTake.subStockTakeReports,
        },
      }
    }

    case "ADD_NEW_STOCKTAKE_PRODUCT": {
      const { product } = action.payload
      return {
        ...state,
        newStockTake: {
          ...state.newStockTake,
          products: [product, ...state.newStockTake.products],
        },
      }
    }

    case "ADD_AI_GENERATED_RECIPE": {
      const { recipe } = action.payload
      return {
        ...state,
        selectedAiGeneratedRecipe: recipe,
      }
    }

    case "ADD_NEW_STOCKTAKE_RECIPE": {
      const { recipe } = action.payload
      return {
        ...state,
        newStockTake: {
          ...state.newStockTake,
          recipes: [recipe, ...state.newStockTake.recipes],
        },
      }
    }

    case "ADD_NEW_STOCKTAKE_PRODUCTS_FROM_SHEET": {
      const { stockTakeData, products, recipes } = action.payload
      return {
        ...state,
        newStockTake: {
          ...state.newStockTake,
          ...stockTakeData,
          products: products.concat([...state.newStockTake.products]),
          recipes: recipes.concat([...state.newStockTake.recipes]),
        },
      }
    }

    case "REMOVE_NEW_STOCKTAKE_PRODUCT": {
      const { productId, subStockTakeId = false } = action.payload

      const subPath = subStockTakeHelper(state, subStockTakeId)
      const products = dot.get(state, `newStockTake${subPath}.products`)
      const productIndex = products.findIndex((p) => p.id == productId)

      return {
        ...dot.set(
          state,
          `newStockTake${subPath}.products.${productIndex}.unsynced.remove`,
          true
        ),
      }
    }

    case "REMOVE_NEW_STOCKTAKE_PRODUCT_RESULT": {
      const { productId, subStockTakeId = false, success } = action.payload

      const subPath = subStockTakeHelper(state, subStockTakeId)
      let products = dot.get(state, `newStockTake${subPath}.products`)
      if (success) {
        products = products.filter((p) => p.id !== productId)
      } else {
        const productIndex = products.findIndex((p) => p.id == productId)
        products[productIndex].unsynced.remove = false
      }

      return {
        ...dot.set(state, `newStockTake${subPath}.products`, products),
      }
    }

    case "REMOVE_NEW_STOCKTAKE_RECIPE": {
      const { recipeId, subStockTakeId = false } = action.payload

      const subPath = subStockTakeHelper(state, subStockTakeId)
      const recipes = dot.get(state, `newStockTake${subPath}.recipes`)
      const recipeIndex = recipes.findIndex((r) => r.id == recipeId)

      return {
        ...dot.set(
          state,
          `newStockTake${subPath}.recipes.${recipeIndex}.unsynced.remove`,
          true
        ),
      }
    }

    case "REMOVE_NEW_STOCKTAKE_RECIPE_RESULT": {
      const { recipeId, subStockTakeId = false, success } = action.payload

      const subPath = subStockTakeHelper(state, subStockTakeId)
      let recipes = dot.get(state, `newStockTake${subPath}.recipes`)

      if (success) {
        recipes = recipes.filter((r) => r.id !== recipeId)
      } else {
        const recipeIndex = recipes.findIndex((r) => r.id == recipeId)
        recipes[recipeIndex].unsynced.remove = false
      }

      return {
        ...dot.set(state, `newStockTake${subPath}.recipes`, recipes),
      }
    }

    case "UPDATE_NEW_STOCKTAKE_PRODUCT": {
      const { product, subStockTakeId = false } = action.payload

      const subPath = subStockTakeHelper(state, subStockTakeId)
      const products = dot.get(state, `newStockTake${subPath}.products`)
      const productIndex = products.findIndex((p) => p.id == product.id)

      return {
        ...dot.set(
          state,
          `newStockTake${subPath}.products.${productIndex}`,
          product
        ),
      }
    }

    case "UPDATE_NEW_STOCKTAKE_RECIPE": {
      const { recipe, subStockTakeId = false } = action.payload

      const subPath = subStockTakeHelper(state, subStockTakeId)
      const recipes = dot.get(state, `newStockTake${subPath}.recipes`)
      const recipeIndex = recipes.findIndex((r) => r.id == recipe.id)

      return {
        ...dot.set(
          state,
          `newStockTake${subPath}.recipes.${recipeIndex}`,
          recipe
        ),
      }
    }

    case "UPDATE_NEW_STOCKTAKE_PRODUCTS": {
      const { products } = action.payload
      const currentProducts = [...state.newStockTake.products]
      const updatedStockTakeProductBarcodes = products.map((i) => i.barcode)
      const currentStockTakeProducts = currentProducts.filter(
        (p) => updatedStockTakeProductBarcodes.indexOf(p.barcode) < 0
      )
      return {
        ...state,
        newStockTake: {
          ...state.newStockTake,
          products: [...currentStockTakeProducts, ...products],
        },
      }
    }

    case "UPDATE_NEW_STOCKTAKE_RECIPES": {
      const { recipes } = action.payload
      const currentRecipes = [...state.newStockTake.recipes]
      const updatedStockTakeRecipeIds = recipes.map((i) => i.recipe.id)
      const currentStockTakeRecipes = currentRecipes.filter(
        (r) => updatedStockTakeRecipeIds.indexOf(r.recipe.id) < 0
      )
      return {
        ...state,
        newStockTake: {
          ...state.newStockTake,
          recipes: [...currentStockTakeRecipes, ...recipes],
        },
      }
    }

    case "FINALIZE_NEW_STOCKTAKE": {
      return {
        ...state,
        newStockTake: {
          ...state.newStockTake,
          ...action.payload,
          status: "COMPLETED",
        },
      }
    }

    /**
     * Substocktake actions
     */
    case "CREATE_NEW_STOCKTAKE_SUBREPORT": {
      const { stockTakeData } = action.payload
      return {
        ...state,
        newStockTake: {
          ...state.newStockTake,
          subStockTakeReports: [
            ...state.newStockTake.subStockTakeReports,
            stockTakeData,
          ],
        },
      }
    }
    case "UPDATE_NEW_STOCKTAKE_SUBREPORT": {
      const { stockTakeData, subStockTakeKey } = action.payload
      let currentReports = [...state.newStockTake.subStockTakeReports]
      currentReports = currentReports.map((r) => {
        if (r.key === subStockTakeKey) {
          return {
            ...r,
            ...stockTakeData,
            products: r.products,
            key: r.key,
          }
        } else {
          return r
        }
      })
      return {
        ...state,
        newStockTake: {
          ...state.newStockTake,
          subStockTakeReports: [...currentReports],
        },
      }
    }

    case "ADD_NEW_STOCKTAKE_SUBREPORT_PRODUCT": {
      const { product, subStockTakeKey } = action.payload
      const newSubStockTakeReports = [
        ...state.newStockTake.subStockTakeReports.map((r) => {
          if (r.key === subStockTakeKey) {
            return {
              ...r,
              products: [product, ...r.products],
            }
          } else {
            return r
          }
        }),
      ]
      return {
        ...state,
        newStockTake: {
          ...state.newStockTake,
          subStockTakeReports: [...newSubStockTakeReports],
        },
      }
    }

    case "ADD_NEW_STOCKTAKE_SUBREPORT_RECIPE": {
      const { recipe, subStockTakeKey } = action.payload
      const newSubStockTakeReports = [
        ...state.newStockTake.subStockTakeReports.map((r) => {
          if (r.key === subStockTakeKey) {
            return {
              ...r,
              recipes: [recipe, ...r.recipes],
            }
          } else {
            return r
          }
        }),
      ]
      return {
        ...state,
        newStockTake: {
          ...state.newStockTake,
          subStockTakeReports: [...newSubStockTakeReports],
        },
      }
    }

    case "ADD_NEW_STOCKTAKE_SUBREPORT_PRODUCTS_FROM_SHEET": {
      const { stockTakeData, products, recipes, subStockTakeKey } =
        action.payload
      const newSubStockTakeReports = [
        ...state.newStockTake.subStockTakeReports.map((r) => {
          if (r.key === subStockTakeKey) {
            return {
              ...r,
              ...stockTakeData,
              products: products.concat([...r.products]),
              recipes: recipes.concat([...r.recipes]),
            }
          } else {
            return r
          }
        }),
      ]
      return {
        ...state,
        newStockTake: {
          ...state.newStockTake,
          subStockTakeReports: [...newSubStockTakeReports],
        },
      }
    }

    case "UPDATE_NEW_STOCKTAKE_SUBREPORT_RECIPE_QTY": {
      const { recipeId, subStockTakeId, qty } = action.payload
      const index = state.newStockTake.subStockTakeReports
        .map((r) => r.id)
        .indexOf(subStockTakeId)

      const newRecipes = [
        ...state.newStockTake.subStockTakeReports[index].recipes.map((r) => {
          if (r.id === recipeId) {
            return {
              ...r,
              totalQty: qty,
              totalCost: qty * r.recipe.totalCost,
            }
          } else {
            return r
          }
        }),
      ]

      return {
        ...state,
        newStockTake: {
          ...state.newStockTake,
          subStockTakeReports: [
            ...state.newStockTake.subStockTakeReports.map((r) => {
              if (r.id === subStockTakeId) {
                return {
                  ...r,
                  recipes: [...newRecipes],
                }
              } else {
                return r
              }
            }),
          ],
        },
      }
    }

    case "UPDATE_NEW_STOCKTAKE_SUBREPORT_PRODUCTS": {
      const { products, subStockTakeId } = action.payload
      const subStockTakeIndex =
        state.newStockTake.subStockTakeReports.findIndex(
          (r) => r.id == subStockTakeId
        )
      const currentProducts = [
        ...state.newStockTake.subStockTakeReports[subStockTakeIndex].products,
      ]
      const updatedStockTakeProductBarcodes = products.map((i) => i.barcode)
      const currentStockTakeProducts = currentProducts.filter(
        (p) => updatedStockTakeProductBarcodes.indexOf(p.barcode) < 0
      )

      const currentSubStockTakes = state.newStockTake.subStockTakeReports
      currentSubStockTakes[subStockTakeIndex].products = [
        ...products,
        ...currentStockTakeProducts,
      ]

      return {
        ...state,
        newStockTake: {
          ...state.newStockTake,
          subStockTakeReports: [...currentSubStockTakes],
        },
      }
    }

    case "UPDATE_NEW_STOCKTAKE_SUBREPORT_RECIPES": {
      const { recipes, subStockTakeId } = action.payload

      const subStockTakeIndex = state.newStockTake.subStockTakeReports
        .map((r) => r.id)
        .indexOf(subStockTakeId)
      const currentRecipes = [
        ...state.newStockTake.subStockTakeReports[subStockTakeIndex].recipes,
      ]
      const updatedStockTakeRecipeIds = recipes.map((i) => i.recipe.id)
      const currentStockTakeRecipes = currentRecipes.filter(
        (r) => updatedStockTakeRecipeIds.indexOf(r.recipe.id) < 0
      )

      const currentSubStockTakes = state.newStockTake.subStockTakeReports.map(
        (r) => {
          if (r.id === subStockTakeId) {
            return {
              ...r,
              recipes: [...currentStockTakeRecipes, ...recipes],
            }
          } else {
            return r
          }
        }
      )

      return {
        ...state,
        newStockTake: {
          ...state.newStockTake,
          subStockTakeReports: [...currentSubStockTakes],
        },
      }
    }

    case "REMOVE_NEW_STOCKTAKE_SUBREPORT": {
      const { subStockTakeKey } = action.payload
      return {
        ...state,
        newStockTake: {
          ...state.newStockTake,
          subStockTakeReports: [
            ...state.newStockTake.subStockTakeReports.filter(
              (st) => st.key !== subStockTakeKey
            ),
          ],
        },
      }
    }

    case "RESET_NEW_STOCKTAKE": {
      return {
        ...state,
        newStockTake: {
          ...state.newStockTake,
          ...initialStockTake,
        },
      }
    }

    /**
     * Stocktake sheet actions
     */
    case "CREATE_NEW_STOCKTAKE_SHEET": {
      const { stockTakeSheetData } = action.payload
      return {
        ...state,
        newStockTakeSheet: stockTakeSheetData,
      }
    }

    case "RESET_NEW_STOCKTAKE_SHEET": {
      return {
        ...state,
        newStockTakeSheet: {
          ...state.newStockTakeSheet,
          ...initialStockTakeSheet,
        },
      }
    }

    case "UPDATE_NEW_STOCKTAKE_SHEET": {
      const { stockTakeSheetData } = action.payload
      return {
        ...state,
        newStockTakeSheet: {
          ...state.newStockTakeSheet,
          ...stockTakeSheetData,
          // products: state.newStockTakeSheet.products
          //   ? state.newStockTakeSheet.products
          //   : [],
        },
      }
    }
    /**
     * Stock transfer actions
     */

    case "CREATE_NEW_STOCK_TRANSFER": {
      const { stockTransferData } = action.payload

      return {
        ...state,
        newStockTransfer: {
          ...state.newStockTransfer,
          ...stockTransferData,
        },
      }
    }

    case "RESET_NEW_STOCK_TRANSFER": {
      return {
        ...state,
        newStockTransfer: {
          ...state.newStockTransfer,
          ...initialStockTransfer,
        },
      }
    }

    case "UPDATE_NEW_STOCK_TRANSFER": {
      const { stockTransferData } = action.payload
      return {
        ...state,
        newStockTransfer: {
          ...state.newStockTransfer,
          ...stockTransferData,
        },
      }
    }

    case "UPDATE_NEW_STOCK_TRANSFER_CONTENT": {
      const { items, type } = action.payload
      return {
        ...state,
        newStockTransfer: {
          ...state.newStockTransfer,
          [type]: items,
        },
      }
    }

    case "UPDATE_NEW_STOCK_TRANSFER_CONTENT_ITEM_ADD": {
      const { item, type } = action.payload
      const items = dot.get(state, `newStockTransfer.${type}`)
      return {
        ...dot.set(state, `newStockTransfer.${type}`, [...items, ...[item]]),
      }
    }

    case "UPDATE_NEW_STOCK_TRANSFER_CONTENT_ITEM": {
      const { item, type, id, idField } = action.payload

      const items = dot.get(state, `newStockTransfer.${type}`)
      const itemIndex =
        idField == "source.refId"
          ? items.findIndex((i) => i?.source?.refId == id)
          : items.findIndex((i) => i[idField] == id)
      const orgItem = items[itemIndex]

      return {
        ...dot.set(state, `newStockTransfer.${type}.${itemIndex}`, {
          ...orgItem,
          ...item,
        }),
      }
    }

    case "UPDATE_NEW_STOCK_TRANSFER_CONTENT_ITEM_REMOVE": {
      const { id, type, idField } = action.payload

      const items = dot
        .get(state, `newStockTransfer.${type}`)
        .filter((item) => item[idField] !== id)

      return {
        ...dot.set(state, `newStockTransfer.${type}`, [...items]),
      }
    }

    /**
     * Waste actions
     */

    case "ADD_NEW_WASTE_GROUP": {
      return {
        ...state,
        selectedWasteGroup: initialWasteGroup,
      }
    }

    case "SELECT_WASTE_GROUP": {
      const { selectedWasteGroup } = action.payload
      return {
        ...state,
        selectedWasteGroup: {
          ...state.selectedWasteGroup,
          ...selectedWasteGroup,
        },
      }
    }

    case "RESET_WASTE_GROUP_PRODUCTS": {
      return {
        ...state,
        selectedWasteGroup: initialWasteGroup,
      }
    }

    case "UPDATE_ONBOARDING_STEPS": {
      const { onboardingSteps } = action.payload

      return {
        ...state,
        onboardingSteps,
      }
    }

    //
    // MinQuantityStocktakeOrder
    //

    case "ADD_MULTIPLE_TO_GLOBAL_ORDER": {
      //Locals
      const local = state.globalOrder
      const currentProducts = local.products

      const { products } = action.payload

      const newUniqueProducts = products.filter(
        (product) => !currentProducts.find((a) => a.barcode === product.barcode)
      )

      const res = newUniqueProducts.map((a) => {
        return {
          ...a,
          _basketQuantity: Math.ceil(a._basketQuantity ? a._basketQuantity : 1),
          _selectedCase: a._selectedCase,
        }
      })

      return {
        ...state,
        globalOrder: {
          products: currentProducts.concat(res),
        },
      }
    }

    // GLOBAL ORDER

    case "ADD_TO_GLOBAL_ORDER": {
      const local = state.globalOrder
      const { product, _basketQuantity, _selectedCase } = action.payload

      const currentProducts = local.products

      return {
        ...state,
        globalOrder: {
          products: currentProducts.concat({
            //Expecting product to match default product data, from basetable it should be rowData
            ...product,
            //Optionally passable properties
            _basketQuantity: Math.ceil(
              _basketQuantity
                ? _basketQuantity
                : product._basketQuantity
                ? product._basketQuantity
                : 1
            ),
            _selectedCase: _selectedCase
              ? _selectedCase
              : product._selectedCase
              ? product._selectedCase
              : null,
          }),
        },
      }
    }

    case "REMOVE_FROM_GLOBAL_ORDER": {
      const local = state.globalOrder

      const { productId } = action.payload
      const currentProducts = local.products

      const tempCurrentProducts = [...currentProducts]

      //Removing specific index matching product id from current product list
      tempCurrentProducts.splice(
        tempCurrentProducts.findIndex((a) => a.id === productId),
        1
      )

      return {
        ...state,
        globalOrder: {
          products: tempCurrentProducts,
        },
      }
    }

    case "UPDATE_GLOBAL_ORDER_PRODUCT": {
      const local = state.globalOrder
      const { product, _basketQuantity, _selectedCase, replaceWith } =
        action.payload
      const currentProducts = local.products

      const tempCurrentProducts = [...currentProducts]
      const spreadTarget = replaceWith ? replaceWith : product

      tempCurrentProducts[
        tempCurrentProducts.findIndex((a) => a.id === product.id)
      ] = {
        ...spreadTarget,
        //Optionally passable properties, removed Math.ceil
        _basketQuantity: Math.ceil(
          _basketQuantity
            ? _basketQuantity
            : spreadTarget._basketQuantity
            ? spreadTarget._basketQuantity
            : 1
        ),
        _selectedCase: _selectedCase
          ? _selectedCase
          : spreadTarget._selectedCase
          ? spreadTarget._selectedCase
          : null,
      }

      return {
        ...state,
        globalOrder: {
          products: [...tempCurrentProducts],
        },
      }
    }

    case "CLEAR_GLOBAL_ORDER_PRODUCT": {
      return {
        ...state,
        globalOrder: {
          products: [],
        },
      }
    }

    case "ACCOUNTING": {
      return {
        ...state,
        accounting: {
          ...state.accounting,
          [action.payload.provider.key]: {
            ...(state.accounting[action.payload.provider.key] ||
              initalAccounting),
            [action.payload.field]: action.payload.value,
          },
        },
      }
    }

    default:
      throw new Error("Bad Action Type")
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

GlobalContextProvider.propTypes = {
  children: PropTypes.any,
}

export default GlobalContextProvider
