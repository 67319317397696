import { faShoppingCart } from "@fortawesome/pro-duotone-svg-icons"
import { faCube, faTimes, faWineBottle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PlaceOrderModal from "components/common/PlaceOrderModal/PlaceOrderModal"
import { AsideContext } from "context/AsideContext"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/GlobalContextProvider"
import { ModalContext } from "context/ModalContext"
import React, { useContext, useMemo } from "react"
import {
  groupGlobalProductsBySuppliers,
  roundNumber,
  saveOrder,
} from "services/helpers"
import { calculateTotalCost } from "services/order"
import { showError } from "services/toast"

function ProductsGlobalOrderOverview() {
  const aside = useContext(AsideContext)

  const modal = useContext(ModalContext)
  const { globalOrder, user, organization } = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  const totalCost = useMemo(
    () => calculateTotalCost(globalOrder),
    [globalOrder.products]
  )

  const hasMultipleSuppliers =
    groupGlobalProductsBySuppliers(globalOrder.products).length > 1

  return (
    <div className="flex w-full h-full bg-white sticky top-0 right-0 p-4 overflow-hidden">
      <div className="h-full flex flex-col w-full">
        <div className="flex-shrink-0">
          <div className="mt-2">
            <span className="text-gray-500 font-semibold">
              Total est cost (exc VAT):{" "}
            </span>
            <span className="font-semibold text-primaryBlue block text-3xl mt-2">
              {totalCost}
            </span>
          </div>
          <div className="my-4">
            <span>Order overview</span>
            <hr />
          </div>
        </div>
        <div className="overflow-auto flex-1 h-full pr-3">
          {groupGlobalProductsBySuppliers(globalOrder.products)?.map(
            (data: any) => {
              return (
                <div
                  key={data.supplier.supplierId + data.products.barcode}
                  className="mt-2"
                >
                  <div>
                    <span className="font-semibold text-sm">
                      {data.supplier.supplierName || data.supplier.label}
                    </span>
                    <ul className="ml-1 my-2">
                      {data.products.map((current: any) => {
                        const product = current
                        return (
                          <li
                            className="flex justify-between first:border-t border-b p-1 py-2 text-xs"
                            key={product.id}
                          >
                            <div className="space-x-2 flex">
                              <p className="text-gray-600 overflow-ellipsis">
                                <span className="font-semibold mr-2 inline-block">
                                  <FontAwesomeIcon
                                    icon={
                                      current._selectedCase === "single"
                                        ? faWineBottle
                                        : faCube
                                    }
                                  />{" "}
                                  x{" "}
                                  {current._basketQuantity || current.quantity}
                                </span>
                                {current.name}
                              </p>
                            </div>
                            <div className="flex items-center">
                              <span className="font-semibold text-gray-600 mx-4">
                                {current._selectedCase === "single"
                                  ? roundNumber(
                                      current._basketQuantity *
                                        parseFloat(current.price)
                                    )
                                  : roundNumber(
                                      current._basketQuantity *
                                        parseFloat(current?.productCase?.price)
                                    )}
                              </span>
                              <button
                                type="button"
                                onClick={() => {
                                  dispatch({
                                    type: "REMOVE_FROM_GLOBAL_ORDER",
                                    payload: {
                                      productId: current.id,
                                    },
                                  })
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  className="text-red-500 text-base"
                                />
                              </button>
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
              )
            }
          )}
        </div>
        <div className="flex flex-col gap-y-3 pt-4 justify-center">
          <button
            type="button"
            className="text-primaryPink font-semibold w-max self-center"
            onClick={() => {
              dispatch({ type: "CLEAR_GLOBAL_ORDER_PRODUCT" })
            }}
          >
            Cancel Selection
          </button>

          <div className="flex w-full justify-center items-center gap-x-2">
            <button
              disabled={!globalOrder.products.length}
              type="button"
              onClick={() =>
                modal.showModal(PlaceOrderModal, {
                  askToNotifySupplier: false,
                  title: `Create draft order${hasMultipleSuppliers ? "s" : ""}`,
                  text: "This action will place orders in DRAFT status for all the items that you have selected, for each supplier",
                  onConfirm: () => {
                    saveOrder(
                      "DRAFT",
                      true,
                      groupGlobalProductsBySuppliers(globalOrder.products),
                      user,
                      organization
                    )
                      .then(() => {
                        dispatch({ type: "CLEAR_GLOBAL_ORDER_PRODUCT" })
                        aside.hideAside()
                      })
                      .catch(() => {
                        showError(
                          "There was a problem while creating multiple orders through global order"
                        )
                      })
                  },
                })
              }
              className="button space-x-2 flex justify-center items-center w-full"
            >
              <FontAwesomeIcon icon={faShoppingCart} />
              <span>
                Create draft order
                {hasMultipleSuppliers && "s"}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductsGlobalOrderOverview
