import React from "react"
import {
  AccountingProvider,
  publishableInvoiceStates,
} from "services/accounting"
import { Invoice } from "services/invoices"
import AccountingStatusLabel from "./AccountingStatusLabel"

type AccountingStatusLabelsProps = {
  invoice: Invoice
  providers: AccountingProvider
}

const AccountingStatusLabels = (props: AccountingStatusLabelsProps) => {
  const { invoice, providers } = props

  let numLabels = 0
  const labels = Object.values(providers).map(
    (provider: AccountingProvider, i) => {
      if (provider?.id && !publishableInvoiceStates.includes(invoice.status)) {
        const published = invoice[provider.key + "Invoice"] || null
        numLabels++
        return (
          <AccountingStatusLabel
            key={i}
            provider={provider}
            published={published}
          />
        )
      }
    }
  )
  return (
    numLabels !== 0 && (
      <div
        className="absolute -top-px -right-px border-b border-l border-gray-200 flex flex-row items-center bg-white shadow-sm text-primaryBlueLighter px-2 py-1 rounded-bl-lg mb-0 text-xs space-x-2"
        style={{ margin: "1px" }}
      >
        {labels}
      </div>
    )
  )
}

export default AccountingStatusLabels
