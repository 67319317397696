import { getOnboardingSteps } from "services/onboarding"
import {
  initialOnboardingSteps,
  initialPaymentPlan,
} from "context/GlobalContextProvider"
import { get, post, remove, put } from "./api"
import { getCurrentUser } from "./auth"
import { orgMapToArray } from "./helpers"
import { getMyOrganization } from "./organization"
import { getMyPaymentPlan } from "./payment"
import { Organization, OrganizationPermissions } from "./types"
import { getIntegrations } from "./integrations"

export const getAllUsers = () => {
  return get("/users")
}

export const contactSupport = (params) => {
  return post("/users/contact-support", params, { authenticate: false })
}

export const changePassword = (params) => {
  return post("/users/change-password", params)
}

export const getInvitations = (params) => {
  return get("/users/invitations", params)
}

export const inviteUser = (params) => {
  return post("/users/invite", params)
}

export const deleteInvitation = (username) => {
  return remove(`/users/invite/?username=${username}`)
}

export const acceptInvitation = (token) => {
  return post(`/users/accept-invitation?token=${token}`, undefined, {
    authenticate: false,
  })
}

export const registerInvitedUser = (params) => {
  return post(`/users/register-invited-user`, params, { authenticate: false })
}

export const removeUserFromOrganization = (username) => {
  return remove(`/users/remove-from-organization/?username=${username}`)
}

export const getMyOrganizations = () => {
  return get(`/users/my-orgs`)
}

export const getUserPermissions = (username: string, config) => {
  return get(
    `/users/permissions`,
    {
      username: username,
    },
    config
  )
}

export const updateUserPermissions = (username: string, params, config?) => {
  let usern = username
  if (username.includes("+")) {
    usern = username.replace("+", "%2B")
  }
  return put(`/users/permissions?username=${usern}`, params, config)
}

const defaultUserInformation = {
  user: null,
  organization: null,
  organizationPermissions: null,
  paymentPlan: initialPaymentPlan,
  organizations: [],
  selectedOrganizations: [],
  organizationsFormatted: [],
  onboardingSteps: initialOnboardingSteps,
  organizationGroup: [],
  integrations: [],
}

const initialSpecificOrgPermissions: OrganizationPermissions = {
  general: {
    isMain: false,
    isSub: false,
  },
  products: {
    editSupplier: true,
    remove: true,
  },
  recipes: {
    remove: true,
  },
  dishes: {
    remove: true,
  },
  suppliers: {
    remove: true,
  },
}

/**
 * Extra permission layer on top of the Growyze user permissions that come from BE.
 * Specific org permissions that are based on SUB/MAIN accounts go here
 */
const getSpecificOrgPermissions = (org: Organization) => {
  const isSubAccount = org?.mainOrgId !== null
  const isMainAccount = org.subOrgIds && org.subOrgIds.length > 0
  // clone is needed because of multi-level object
  const permissions = structuredClone(initialSpecificOrgPermissions)

  if (isSubAccount) {
    permissions.general.isSub = true
    permissions.general.isMain = false
    permissions.dishes.remove = false
    permissions.recipes.remove = false
    permissions.products.remove = false
    permissions.products.editSupplier = false
    permissions.suppliers.remove = false
  }
  if (isMainAccount) {
    permissions.general.isMain = true
    permissions.general.isSub = false
  }

  return permissions
}

export const getCombinedUserInformation = async () => {
  const resObject: {
    user: any | null
    organization: Organization | null
    organizationPermissions: OrganizationPermissions | null
    paymentPlan: any | null
    organizations: any[]
    selectedOrganizations: string[]
    organizationsFormatted: any[]
    onboardingSteps: any
    organizationGroup: string[]
    integrations: string[]
  } = defaultUserInformation

  try {
    resObject.user = await getCurrentUser()
  } catch (e) {
    console.error("cound not fetch user")
  }

  if (
    resObject.user?.organizations[0] !== "growyze-default" ||
    resObject.user?.roles?.includes("admin")
  ) {
    try {
      // Get organization
      resObject.organization = await getMyOrganization()
      resObject.selectedOrganizations = []
    } catch (e) {
      console.error("could not fetch organization")
    }

    // Set specific org permissions
    if (resObject.organization) {
      resObject.organizationPermissions = getSpecificOrgPermissions(
        resObject.organization
      )
    }

    try {
      // Get payment plan
      resObject.paymentPlan = await getMyPaymentPlan()
    } catch (e) {
      console.error("could not payment plan")
    }

    try {
      // Get organization list
      const orgMap = await getMyOrganizations()
      resObject.organizations = orgMapToArray(orgMap)
      resObject.organizationsFormatted = (() => {
        const orgs = orgMap.organizations

        // Merge all org types and add selected status if applicable
        const [mainOrgs, subOrgs, standardOrgs, demoOrgs] = [
          orgs.filter((a) => a.type === "MAIN"),
          orgs.filter((a) => a.type === "SUB"),
          orgs.filter((a) => a.type === "STANDARD"),
          orgs.filter((a) => a.type === "DEMO"),
        ].map((currentOrgGroup) => {
          return currentOrgGroup.map((org) => {
            return {
              ...org,
              isSelected: org.id === resObject.organization?.id,
            }
          })
        })

        // Create an nested object with main and subs and sort the subs alphabetically
        const mappedMainOrgs = mainOrgs.map((a) => {
          const subOrgsMapped =
            a.subOrgIds.length > 0 // Map over subOrgIds, assign org object
              ? a.subOrgIds
                  .map((b) => subOrgs.find((c) => c.id === b))
                  .filter((a) => Boolean(a)) // filter/remove all undefined
                  .sort((a, b) =>
                    a.companyName.toLowerCase() > b.companyName.toLowerCase()
                      ? 1
                      : -1
                  )
              : []

          return {
            ...a,
            subOrgsMapped: subOrgsMapped,
          }
        })

        // Make a list of all sub accounts that have been connected to a main account
        let subsConnectedToMain: string[] = []
        mappedMainOrgs.forEach((org) => {
          subsConnectedToMain = subsConnectedToMain.concat(
            org.subOrgsMapped.map((a) => a.id)
          )
        })

        // Get a list of all sub accounts that are not connected to a main account
        const disconnectedSubAccounts = subOrgs.filter(
          (a: any) => !subsConnectedToMain.includes(a.id)
        )

        const result = [
          ...mappedMainOrgs,
          ...standardOrgs,
          ...disconnectedSubAccounts,
          ...demoOrgs,
        ]

        // Sort all top level orgs alphabetically and move the selected one on top.
        const sortedResult = result
          .sort((a, b) =>
            a.companyName.toLowerCase() > b.companyName.toLowerCase() ? 1 : -1
          )
          .sort((a) => {
            const isMainSelected = a.isSelected
            const isSubSelected =
              a.subOrgsMapped &&
              a.subOrgsMapped.filter((org) => org.isSelected).length > 0
            return isMainSelected || isSubSelected ? -1 : 1
          })

        return sortedResult
      })()
    } catch (e) {
      console.log("error", e)
      console.error("could not get user organizations")
    }

    // Get onboarding steps
    try {
      resObject.onboardingSteps = await getOnboardingSteps()
    } catch (error) {
      console.error("could not get onboarding steps")
    }

    // Get organization group
    try {
      resObject.organizationGroup = await getOrganizationGroup(resObject)
    } catch (error) {
      console.error("could get organization group")
    }

    // Get integrations
    try {
      resObject.integrations = await getIntegrations({}, {})
    } catch (error) {
      console.error("could get integrations")
    }
  }

  return resObject
}

const getMainOrg = (data) => {
  if (data.organizationPermissions.general.isMain) {
    return data.organizations.find((org) => org.value === data.organization.id)
  }
  if (data.organizationPermissions.general.isSub) {
    return data.organizations.find(
      (org) => org.id === data.organization.mainOrgId
    )
  }

  return ""
}

const getMainId = (data) => {
  return getMainOrg(data).value
}

const getSubOrgs = (data) => {
  if (!data.organizationPermissions.general.isMain) {
    return []
  }

  const mainId = getMainId(data)
  const subOrgs = data.organizationsFormatted.find(
    (org) => org.id === mainId
  ).subOrgsMapped
  const subIds = subOrgs.map((subOrg) => subOrg.id)

  return data.organizations.filter((org) => subIds.includes(org.value))
}

export const getSubIds = (data) => {
  return getSubOrgs(data).map((subOrg) => subOrg.value)
}

const getOrganizationGroup = (data) => {
  if (data?.organizationPermissions?.general.isMain) {
    return [getMainId(data), ...getSubIds(data)]
  } else {
    return data?.selectedOrganizations
  }
}

export const getOrgNameById = (id, organizations) => {
  return organizations.find((org) => org.value === id)?.label
}
