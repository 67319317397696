import React, { useContext, useState, useRef } from "react"
import { Link, navigate } from "gatsby"
import { useClickAway } from "react-use"
import * as styles from "./UserDropdown.module.css"
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "../../../context/GlobalContextProvider"
import { logout } from "services/auth"
import classNames from "classnames"
import UserAvatar from "components/common/UserAvatar/UserAvatar"
import usePermissions from "hooks/usePermissions"

const UserDropdown = () => {
  const { user: currentUser, organization } = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)
  const permissionObj = usePermissions("Company settings")

  useClickAway(dropdownRef, () => {
    setIsOpen(false)
  })

  const doLogout = (e) => {
    e.preventDefault()

    logout(async () => {
      if (window?.fcWidget) {
        window.fcWidget?.user?.clear()
        window.fcWidget.close()
      }
      await navigate("/", { replace: true })
      dispatch({ type: "RESET_STATE" })
    })
  }

  return currentUser ? (
    <div className={styles.container} ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={styles.toggleButton}
      >
        <UserAvatar user={currentUser} />
      </button>
      <div className={classNames(styles.content, { hidden: !isOpen })}>
        <div className="px-4 pb-2 mb-2 border-b flex flex-col">
          <span className="font-sansSemiBold font-medium text-gray-800">
            {`${currentUser.firstName} ${currentUser.lastName}`}
            {organization && organization.companyName && (
              <span className="block text-xs text-gray-600">
                {organization.companyName}
              </span>
            )}
          </span>
          <span className="text-sm text-gray-600">{currentUser.username}</span>
        </div>
        <Link to="/dashboard/account-settings" className={styles.menuItem}>
          My account
        </Link>
        {permissionObj?.permissions.modify && (
          <Link to="/dashboard/company-settings" className={styles.menuItem}>
            Company settings
          </Link>
        )}
        <button onClick={(e) => doLogout(e)} className={styles.menuItem}>
          Log out
        </button>
      </div>
    </div>
  ) : null
}

export default UserDropdown
