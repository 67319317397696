/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext, useRef } from "react"
import { usePromise, usePrevious } from "react-use"
import { navigate } from "@reach/router"
import SearchInput from "components/forms/SearchInput"
import {
  faFileDownload,
  faPlus,
  faSync,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useMediaQuery } from "react-responsive"
import { useLocation } from "@reach/router"
import * as queryString from "query-string"

import FilterSelect from "components/forms/FilterSelect"
import {
  createDish,
  exportDishes,
  getDishes,
  reassignDishToOrg,
  reassingDishesToOrgs,
  removeDish,
  unassignDishesFromOrgs,
  updateDishFeaturedImage,
  updateDishFiles,
} from "services/dishes"
import Helmet from "react-helmet"
import { dishQueryParameters, recipeDishCategories } from "services/constants"

import usePermissions from "hooks/usePermissions"
import { ModalContext } from "context/ModalContext"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"

import * as styles from "./Dishes.module.css"
import { showError, showSuccess } from "services/toast"
import { recalculateAllRecipesWithLatestPrices } from "services/recipes"

import DishItemName from "../DishItemName/DishItemName"
import CostProfitChart from "components/dashboard/CostProfitChart/CostProfitChart"
import ActionsButton from "components/common/ActionsButton/ActionsButton"
import DishItemContent from "../Dishitem/DishItemContent"
import DropdownButton from "components/common/DropdownButton/DropdownButton"
import { GlobalStateContext } from "context/GlobalContextProvider"
import { toast } from "react-toastify"
import ModalAssignProductLocations from "components/common/ModalAssignLocations/ModalAssignLocations"
import SelectAllCell from "components/baseTable/cells/SelectAllCell"
import SelectCell from "components/baseTable/cells/SelectCell"
import { Permission } from "services/types"
import ExtBaseTable from "components/baseTable/ExtBaseTable"
import { faBallPile } from "@fortawesome/pro-duotone-svg-icons"
import { getDishesDummy } from "services/dummy"
import { initialiseQueryParameterState } from "services/helpers"
import usePagination, {
  paginationDefaults,
} from "components/base/hooks/usePagination"

const Dishes = () => {
  const location = useLocation()
  const { organizationPermissions } = useContext(GlobalStateContext)
  const isAllowedToManageLocations = organizationPermissions?.general?.isMain
  const queryParams = queryString.parse(location.search)
  const queryParamsFormatted = {
    partialDishName:
      queryParams.partialDishName && String(queryParams.partialDishName),
    partialPosId: queryParams.partialPosId && String(queryParams.partialPosId),
    partialIngredientName:
      queryParams.partialIngredientName &&
      String(queryParams.partialIngredientName),
    parameterValue:
      queryParams.parameterValue && String(queryParams.parameterValue),
    category: queryParams.category && String(queryParams.category),
    sort: queryParams.sort,
  }

  const permissionObj = usePermissions("Recipes & Dishes") as Permission

  const [dishesData, setDishesData] = useState({
    content: [],
  })
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })

  const searchParamType =
    queryParamsFormatted.parameterValue || "partialDishName"

  const defaultQ =
    queryParamsFormatted.partialDishName || queryParamsFormatted.partialPosId
      ? searchParamType === "partialDishName"
        ? queryParamsFormatted.partialDishName
        : queryParamsFormatted.partialPosId
      : ""

  const [q, setQ] = useState<string | null>(defaultQ)
  const previousQ = usePrevious(q)

  const [queryParameter, setQueryParameter] = useState(
    initialiseQueryParameterState(
      dishQueryParameters,
      searchParamType,
      "partialDishName"
    )
  )

  const pagination = usePagination()
  const fromPromise = usePromise()
  const [loading, setLoading] = useState(false)
  const [category, setCategory] = useState<{
    label: string
    value: string
  } | null>(
    queryParamsFormatted.category
      ? {
          label: queryParamsFormatted.category,
          value: queryParamsFormatted.category,
        }
      : null
  )
  const previousCategory = usePrevious(category)

  const sortParam = //ensures sort state is persisted via url params
    !Array.isArray(queryParams.sort) && queryParams.sort
      ? queryParams.sort.split(",")
      : ["name", "asc"]

  const [sortState, setSortState] = useState<{ [x: string]: any }>({
    [sortParam[0]]: sortParam[1],
  })

  const modal = useContext(ModalContext)

  const tableRef = useRef<any>()

  // EFFECTS

  useEffect(() => {
    getData()
  }, [sortState, q, pagination.page, category])

  // ACTIONS

  interface getDataParams {
    page: typeof paginationDefaults.page
    size: number
    sort?: string
    parameterValue?: string
    partialDishName?: string
    partialPosId?: string
    partialIngredientName?: string
    category?: string
  }

  const getData = async () => {
    setLoading(true)

    const params: getDataParams = {
      page: pagination.page,
      size: pagination.size,
      sort: `${Object.keys(sortState)[0]},${Object.values(sortState)[0]}`,
      parameterValue: queryParameter.value,
    }

    if (q) {
      switch (queryParameter.value) {
        case "partialDishName":
          params.partialDishName = q
          break
        case "partialPosId":
          params.partialPosId = q
          break
        case "partialIngredientName":
          params.partialIngredientName = q
          break
        default:
          params.partialDishName = q
          break
      }
      if (previousQ !== q && previousQ !== undefined) {
        params.page = 0
      }
    }

    if (category) {
      params.category = category.value
      if (previousCategory !== category && previousCategory !== undefined) {
        params.page = 0
      }
    }

    // Change url based on params
    // @ts-ignore
    const query = new URLSearchParams(params)
    const path = `${location.pathname}?${query.toString()}`
    window.history.replaceState(null, "", path)

    // fromPromise prevents call on unmount of component
    const result = await fromPromise(getDishes(params))

    if (result && !result.error) {
      setDishesData({ ...dishesData, ...result })
      pagination.setFromResult(result)

      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const recalculateRecipes = async () => {
    const recalculated = await recalculateAllRecipesWithLatestPrices()
    if (recalculated) {
      showSuccess("Dishes recalculated successfully!")
      getData()
    }
  }

  const itemActions = [
    {
      key: "dish.duplicate",
      title: "Duplicate",
      type: "default",
      disabled: !permissionObj?.permissions.modify,
    },
    // { key: "dish.add_to_menu", title: "Add to menu", type: "default" },
    ...(isAllowedToManageLocations
      ? [
          {
            key: "dish.manageLocations",
            title: "Manage locations",
            type: "default",
            disabled: !permissionObj?.permissions.modify,
          },
        ]
      : []),
    {
      key: "dish.delete",
      title: "Delete",
      type: "danger",
      disabled:
        !permissionObj?.permissions.delete ||
        !organizationPermissions?.dishes?.remove,
    },
  ]

  const onColumnSort = ({ key, order }) => setSortState({ [key]: order })

  const deleteDish = async (dish) => {
    const deleted = await removeDish(dish.id)
    if (deleted) {
      showSuccess("Dish deleted!")
      getData()
    }
  }

  const onDelete = (dish) => {
    // @ts-ignore
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: "Delete dish",
      text: "Are you sure you want to delete this dish?",
      confirmButtonText: "Delete",
      onConfirm: () => deleteDish(dish),
    })
  }

  const onDuplicate = async (dish) => {
    const newDish = {
      ...dish,
      posId: null,
      menus: null,
      files: [],
      featuredFile: null,
      name: `Copy of ${dish.name}`,
    }

    const currentFiles = dish.files
    const currentFeaturedFile = dish.featuredFile

    delete newDish.id
    delete newDish.children

    try {
      const result = await createDish(newDish)

      if (currentFiles && currentFiles.length > 0) {
        await updateDishFiles(result.id, { files: currentFiles })
      }
      if (currentFeaturedFile) {
        await updateDishFeaturedImage(result.id, currentFeaturedFile)
      }
      showSuccess("Dish duplicated!")
      getData()
      navigate(`/dashboard/products/cookbook/dishes/${result.id}`)
    } catch (e) {
      console.log(e)
      showError("Something went wrong")
    }
  }

  const onManageLocations = (dishes: any[]) => {
    const dishIds = dishes.map((a) => a.id)
    const organizationIds = dishes.map((a) => a.organizations).flat(1)

    const isBulkSelect = dishes.length > 1

    // @ts-ignore
    modal.showModal(ModalAssignProductLocations, {
      items: [...dishes],
      onSubmit: (_recipeIds, orgIds, _action) => {
        const callRequest =
          _action === "assign"
            ? //Assign
              isBulkSelect
              ? reassingDishesToOrgs(dishIds, orgIds)
              : reassignDishToOrg(dishes[0].id, { organizations: orgIds })
            : //Unassign
              unassignDishesFromOrgs(dishIds, orgIds)
        return callRequest
          .catch((err) => {
            toast.error(err)
          })
          .finally(() => {
            getData()
            setSelection([])
          })
      },
      type: "dish",
      showActionPicker: dishes.length > 1,
      initialOrgs: isBulkSelect ? [] : organizationIds,
    })
  }

  const handleItemActionClick = (action, dish) => {
    const { type } = action
    switch (type) {
      case "dish.view":
      case "dish.edit":
        navigate(`/dashboard/products/cookbook/dishes/${dish.id}`)
        break
      case "dish.manageLocations":
        onManageLocations([dish])
        break
      case "dish.delete":
        onDelete(dish)
        break
      case "dish.duplicate":
        onDuplicate(dish)
        break
      default:
        break
    }
  }

  const handleGroupActionClick = (action, selection, onDeleteMultiple?) => {
    const { type } = action
    /* eslint-disable */

    switch (type) {
      case "items.delete":
        onDeleteMultiple(selection.map((a) => a.id))
        break

      case "items.manageLocations":
        //Assign locations

        onManageLocations(selection)
        break

      default:
        break
    }
  }

  // BASETABLE

  const [selection, setSelection] = useState([])

  const columns = [
    ...(isAllowedToManageLocations
      ? [
          {
            key: "select",
            title: "Select",
            dataKey: "select",
            width: 45,
            flexGrow: 0,
            flexShrink: 0,
            headerRenderer: (
              <SelectAllCell
                selection={selection}
                setSelection={setSelection}
                onSelectAll={() => setSelection([...dishesData.content])}
              />
            ),
            cellRenderer: (
              <SelectCell selection={selection} setSelection={setSelection} />
            ),
          },
        ]
      : []),
    {
      key: "name",
      title: "Item",
      dataKey: "name",
      width: 200,
      flexGrow: 1,
      flexShrink: 0,
      sortable: true,
      cellRenderer: ({ rowData }) => (
        <DishItemName
          dish={rowData}
          permissionObj={permissionObj}
          handleActionClick={handleItemActionClick}
        />
      ),
    },
    {
      key: "salePrice",
      title: "Sales price",
      width: 90,
      dataKey: "salePrice",
      className: "text-sm",
      flexShrink: 0,
    },
    {
      key: "costProfit",
      width: 150,
      dataKey: "costProfit",
      flexGrow: 1,
      className: "h-full",
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <CostProfitChart
          cost={rowData.totalCost}
          profit={rowData.profit}
          costPercentage={rowData.totalCostPercent}
          profitPercentage={rowData.profitPercent}
        />
      ),
    },
    {
      key: "action",
      flexShrink: 0,
      width: 60,
      className: "justify-end actions",
      cellRenderer: ({ rowData }) => {
        return (
          <ActionsButton
            options={itemActions}
            onActionClick={(action) => handleItemActionClick(action, rowData)}
          />
        )
      },
    },
  ]

  const formattedData = (data) => {
    const latestData = [...data]

    latestData.forEach((item) => {
      item.children = [
        {
          id: `${item.id}-detail`,
          content: item.sections && item,
        },
      ]
    })

    return latestData
  }

  const resetRowHeights = () => {
    //enables recalc of row heights when child is expandable
    tableRef.current?.resetAfterRowIndex(0, true)
  }

  const rowRenderer = ({ rowData, cells }) => {
    if (rowData.content)
      return (
        <div
          className={"orderDetails w-full px-8 pb-8 itemContent overflow-auto"}
        >
          <DishItemContent
            dish={rowData.content}
            updateParentTable={resetRowHeights}
          />
        </div>
      )
    return cells
  }

  const handleActionClick = (type) => {
    switch (type) {
      case "export.xlsx":
        return exportDishes("xlsx")
      case "export.deleted.xlsx":
        return exportDishes("xlsx", true)
    }
  }

  return (
    <>
      <Helmet>
        <title>Dishes</title>
      </Helmet>
      <div className={styles.container}>
        <div className={styles.subHeader}>
          <div className="flex items-center flex-grow relative my-2 w-auto md:w-40 mr-4 md:mr-6">
            <SearchInput
              label="Search by name"
              placeholder={
                isTabletOrMobile
                  ? `Search by ${queryParameter.label.toLowerCase()}`
                  : `Search by dish ${queryParameter.label.toLowerCase()}`
              }
              className="form-control rounded rounded-tr-none rounded-br-none mr-4 md:mr-6"
              onSearchChange={(val) => {
                setQ(val)
              }}
              initialValue={q}
            />
            <FilterSelect
              options={dishQueryParameters}
              value={queryParameter && queryParameter.value}
              onChange={(val) => {
                setQueryParameter(val)
              }}
              stylesOverride={{
                container: (provided) => ({
                  ...provided,
                  position: "static",
                }),
                control: (provided, state) => ({
                  ...provided,
                  backgroundColor: "#fff",
                  borderLeft: "none",
                  borderRadius: "0 0.25rem 0.25rem 0",
                  width: "fit-content",
                  borderColor: state.isFocused ? "#FC3762" : "#e2e8f0",
                  boxShadow: "none",
                  zIndex: state.isFocused ? 2 : null,
                  minHeight: 45,
                  ":hover": {
                    ...provided[":hover"],
                    borderColor: state.isFocused ? "#FC3762" : "#e2e8f0",
                  },
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  padding: "0px",
                  width: "0px",
                }),
                menu: (provided) => ({
                  ...provided,
                  left: "0px",
                }),
              }}
            />
          </div>

          <div className="inline-flex justify-self-end items-center">
            <FilterSelect
              options={recipeDishCategories}
              value={category && category?.value}
              onChange={(val) => {
                setCategory(val)
              }}
              placeholder="All categories"
              isClearable={true}
              isSearchable={isTabletOrMobile ? false : true}
              className="my-2 lg:my-0 w-48 mr-4 md:mr-6"
            />
            <button
              className="button my-1 lg:my-2 ml-auto button--autoWidth button--primaryGreen mr-2 flex-grow"
              onClick={(e) => {
                e.preventDefault()
                recalculateRecipes()
              }}
            >
              <FontAwesomeIcon icon={faSync} className="lg:mr-2" />
              <span className="hidden lg:inline">Recalculate</span>
            </button>
            <button
              className="button my-1 lg:my-2 ml-auto button--autoWidth button--primaryGreen flex-grow"
              onClick={(e) => {
                e.preventDefault()
                navigate("dishes/new")
              }}
              disabled={!permissionObj?.permissions.modify}
            >
              <FontAwesomeIcon icon={faPlus} className="lg:mr-2" />
              <span className="hidden lg:inline">Add Dish</span>
            </button>

            <DropdownButton
              containerClass="ml-2"
              onActionClick={(opt) => {
                const { type } = opt
                handleActionClick(type)
              }}
              label={<FontAwesomeIcon icon={faFileDownload} />}
              mobileIcon={faFileDownload}
              options={[
                {
                  title: "Export to .XLSX",
                  key: "export.xlsx",
                  disabled: !permissionObj?.permissions.read,
                },
                {
                  title: "Export deleted to .XLSX",
                  key: "export.deleted.xlsx",
                  disabled: !permissionObj?.permissions.read,
                },
              ]}
            />
          </div>
        </div>

        {selection?.length > 0 && (
          <div className="flex mx-8 mt-6">
            <div className="flex">
              <div>
                <span className="font-semibold mr-4">
                  <span className="text-primaryBlue">
                    {selection.length}{" "}
                    {selection.length === 1 ? "item" : "items"}
                  </span>{" "}
                  <span className="text-gray-600">selected</span>
                </span>
              </div>

              <DropdownButton
                dropDirection="top-left"
                label="Apply action"
                buttonClass={
                  "button button--primaryGreen button--smallest text-sm"
                }
                options={[
                  {
                    key: "items.manageLocations",
                    title: "Manage locations",
                    disabled:
                      !permissionObj?.permissions.modify ||
                      !organizationPermissions?.general.isMain,
                  },
                ]}
                onActionClick={(action) =>
                  handleGroupActionClick(action, selection)
                }
              />
            </div>
          </div>
        )}

        <div className={styles.content}>
          <ExtBaseTable
            key="table"
            title="Dishes"
            id="dishes"
            ref={tableRef}
            loading={loading}
            pagination={pagination}
            data={formattedData(dishesData.content)}
            columns={columns}
            estimatedRowHeight={200}
            sortState={sortState}
            onColumnSort={onColumnSort}
            expandColumnKey={"name"}
            empty={{
              icon: faBallPile,
              color: "blue",
              getDummy: () => getDishesDummy,
            }}
            expand={{}}
            rowRenderer={rowRenderer}
          />
        </div>
      </div>
    </>
  )
}

export default Dishes
