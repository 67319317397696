import React, { FunctionComponent, ReactNode } from "react"
import BodyPortal from "components/common/BodyPortal/BodyPortal"
import { useState } from "react"

interface ModalConfig {
  id: string
  comp:
    | FunctionComponent<any>
    | ReactNode
    | ((onRequestClose: any) => React.JSX.Element)
  props: any
}

interface ExtraModalProps {
  [key: string]: any
}

export const useModals = (comps: ModalConfig[]) => {
  // list of active modalIds
  const [visibleModals, setVisibleModals] = useState<string[]>([])
  const [extraModalProps, setExtraModalProps] = useState<ExtraModalProps>()

  const showModal = (modalId: string, props?: any) => {
    if (props && Object.keys(props).length) {
      setExtraModalProps({ ...extraModalProps, [modalId]: props })
    }
    setVisibleModals([...visibleModals, modalId])
  }
  const hideModal = (modalId) =>
    setVisibleModals(visibleModals.filter((id) => id !== modalId))

  const RenderModals = () => (
    <BodyPortal id="modal-root">
      {comps.map(({ id, comp, props }) => {
        const isVisible = visibleModals.includes(id)
        const extraProps = extraModalProps && extraModalProps[id]
        const ModalComp = comp
        return isVisible ? (
          // @ts-ignore
          <ModalComp
            key={id}
            onRequestClose={(data) => {
              hideModal(id)
              if (props.onClose) {
                props.onClose(data)
              }
            }}
            {...props}
            {...extraProps}
          />
        ) : null
      })}
    </BodyPortal>
  )

  return { showModal, hideModal, RenderModals }
}
