import React, { useState, useCallback, useContext } from "react"
import PropTypes from "prop-types"
import { useInterval } from "react-use"
import Loader from "components/common/Loader/Loader"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faPlus } from "@fortawesome/pro-regular-svg-icons"
import { faFilePdf } from "@fortawesome/pro-light-svg-icons"
import Modal from "react-modal"
import {
  extractInvoiceDataFromPdf,
  extractInvoiceDataFromImg,
  cancelRequest,
  operatePdf,
  createPdf,
} from "services/file-processing"
import { addInvoice, updateInvoice } from "services/invoices"
import { showError, showSuccess } from "services/toast"
import ConfirmDetails from "components/invoices/ConfirmDetails/ConfirmDetails"
import SupplierSelect from "components/suppliers/SupplierSelect/SupplierSelect"
import DocumentCamera from "components/common/DocumentCamera/DocumentCamera"
import DocumentCameraFiles from "components/common/DocumentCameraFiles/DocumentCameraFiles"
import DeliveryNoteSelect from "components/delivery-notes/DeliveryNoteSelect/DeliveryNoteSelect"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/GlobalContextProvider"
import classNames from "classnames/bind"
import FormData from "form-data"

//@ts-ignore
import * as styles from "./Invoice.module.css"
import usePermissions from "hooks/usePermissions"
import { Permission } from "services/types"

const cx = classNames.bind(styles)

const invoicedProduct = {
  description: null,
  comment: null,
  barcode: "",
  invoicePrice: null,
  invoiceQty: null,
  orderPrice: null,
  productDiscrepancies: null,
  dnReceivedQty: null,
}

const Invoice = ({ onRequestClose, onUpdate, ...otherProps }) => {
  const dispatch = useContext(GlobalDispatchContext)
  const permissionObj = usePermissions("Invoices") as Permission
  const { user, newInvoice } = useContext(GlobalStateContext)
  const [loading, setLoading] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])
  const [selectedSupplier, setSelectedSupplier] = useState(null)
  const [confirmed, setConfirmed] = useState(false)
  const [extractedFile, setExtractedFile] = useState(null)
  const [isPolling, setPolling] = useState(false)
  const [jobId, setJobId] = useState(null)
  const [cameraEnabled, setCameraEnabled] = useState(true)
  const cameraDemoUsers = ["kati@growyze.com", "hi@rubenkuipers.design"]
  const [currentStep, setCurrentStep] = useState(
    cameraDemoUsers.includes(user.username) ? 1 : 3
  )

  /* eslint-disable */
  const onImageCapture = useCallback((dataUri, originalFile) => {
    const newFiles = [
      ...selectedFiles,
      {
        dataUri: dataUri,
        file: originalFile,
        selected: true,
      },
    ]
    setSelectedFiles([...newFiles])

    // If pdf - process this file directly (single upload)
    if (originalFile.type === "application/pdf") {
      setConfirmed(true)
      return uploadFiles(newFiles)
    }
  }, [])

  const uploadFiles = useCallback(async (files) => {
    setCurrentStep(2)
    setLoading(true)

    const list = files.filter((file) => file.selected)
    const results = []

    for (let i = 0; i < list.length; i++) {
      let formData = new FormData()
      let params = {
        client: "invoice",
      }
      const currentFile = list[i].file
      const type = currentFile.type
      const isLast = i === list.length - 1
      formData.append("file", currentFile)

      if (type !== "application/pdf" && isLast) {
        params.completed = true
      }

      // Add previous file ID if exists
      if (list.length > 1 && i > 0 && type !== "application/pdf") {
        params.fileId = results[i - 1].fileId
      }

      const result =
        type === "application/pdf"
          ? await operatePdf(formData, params) // if pdf operate on pdf directly
          : list.length > 1 // only create pdf for more than 1 img file
          ? await createPdf(formData, params) // create pdf
          : await extractInvoiceDataFromImg(formData, newInvoice.supplier.name) // extract from only img directly

      if (result) {
        results.push(result)
      }

      if (result && isLast) {
        // If is a job, first get job result, otherwise parse result directly
        if (result.jobId) {
          setJobId(result.jobId)

          if (result.fileId) {
            setExtractedFile(result)
          }
          setPolling(true)
          return
        } else {
          setExtractedFile(result ? result : null)
          return parseResult(result)
        }
      }
    }
  }, [])

  const saveInvoice = async (InvParams) => {
    const saveResult = newInvoice.id
      ? await updateInvoice(InvParams)
      : await addInvoice(InvParams)

    if (saveResult && saveResult.id && !saveResult.error) {
      const {
        dateOfScanning,
        invoiceNumber,
        dateOfIssue,
        deliveryNoteNumber,
        deliveryNoteId,
        deltaTotalCost,
        expectedTotalCost,
        files,
        extractedFile,
        globalDiscrepancies,
        hasProductPriceDiscrepancies,
        invoicedTotalCost,
        hasNoOrderedProducts,
        hasNoInvoicedProducts,
        hasNoReceivedProducts,
        hasTotalCostDiscrepancy,
        hasQtyDiscrepancies,
        message,
        totalCost,
        totalVat,
        dueDate,
        grossTotalCost,
        products,
        supplier,
        status,
        po,
        id,
      } = saveResult

      dispatch({
        type: "UPDATE_NEW_INVOICE",
        payload: {
          dateOfScanning,
          invoiceNumber,
          dateOfIssue,
          deliveryNoteNumber,
          deliveryNoteId,
          deltaTotalCost,
          expectedTotalCost,
          files,
          extractedFile,
          globalDiscrepancies,
          hasProductPriceDiscrepancies,
          hasNoOrderedProducts,
          invoicedTotalCost,
          hasNoInvoicedProducts,
          hasNoReceivedProducts,
          hasTotalCostDiscrepancy,
          hasQtyDiscrepancies,
          message,
          totalCost,
          totalVat,
          dueDate,
          grossTotalCost,
          products,
          supplier,
          status,
          po,
          id,
        },
      })
      setLoading(false)
      if (onUpdate) {
        onUpdate()
      }
      setCurrentStep(3)
    } else if (saveResult) {
      setLoading(false)

      if (saveResult.status === 404 || saveResult.status === 400) {
        // If not found select open select supplier page
        if (saveResult.status === 404) {
          setCurrentStep(3)
        }

        if (saveResult.errors && saveResult.errors.length > 0) {
          showError(
            saveResult.errors[0].defaultMessage || "Something went wrong"
          )
        } else {
          showError(saveResult.message || "Something went wrong")
        }
        return
      }
      if (saveResult.errors && saveResult.errors.length > 0) {
        showError(saveResult.errors[0].defaultMessage || "Something went wrong")
      } else {
        showError(saveResult.message || "Something went wrong")
      }
    }
  }

  const parseResult = useCallback(
    (result) => {
      if (result && !result.error && result.status !== 400) {
        if (!result.items || result.items.length === 0) {
          showError("No items found on invoice")
          resetState()
          return
        }
        let InvoiceParams = {
          ...newInvoice,
          po: result.poNumber,
          extractedFile: extractedFile
            ? {
                fileId: extractedFile.fileId,
                fileName: extractedFile.fileName,
              }
            : result.fileId
            ? { fileId: result.fileId, fileName: result.fileName }
            : null,
          files: result.files,
          selectedFiles: selectedFiles,
          dateOfIssue: result.dateOfIssue || new Date().toISOString(),
          invoiceNumber: result.invoiceNumber,
          deliveryNoteId: result.deliveryNoteId ? result.deliveryNoteId : null,
          deliveryNoteNumber: result.deliveryNoteNumber,
          status: "DRAFT",
          supplier: newInvoice.supplier,
          totalCost: result.totalCost,
          products: result.items
            ? result.items.map((item) => {
                return {
                  ...invoicedProduct,
                  code: item.code,
                  description: item.desc,
                  invoicePrice: item.price,
                  invoiceQty: item.qty,
                }
              })
            : [],
        }

        // if (result.id) {
        //   InvoiceParams.deliveryNoteId = result.id
        // }

        saveInvoice(InvoiceParams)

        setLoading(false)
        setCurrentStep(3)
      } else if (result && result.message) {
        resetState()
        return showError(result.message, { autoClose: 5000 })
      }
    },
    [dispatch, newInvoice, extractedFile]
  )

  const handleDeliveryNoteSelect = async (deliveryNote) => {
    dispatch({ type: "RESET_NEW_INVOICE" })

    setLoading(true)

    const InvoiceParams = {
      ...newInvoice,
      po: deliveryNote.po,
      extractedFile: null,
      files: null,
      dateOfIssue: new Date().toISOString(),
      invoiceNumber: null,
      deliveryNoteNumber: deliveryNote.deliveryNoteNumber,
      deliveryNoteId: deliveryNote.id,
      status: "DRAFT",
      supplier: {
        id: deliveryNote.supplier.id,
        name: deliveryNote.supplier.name,
      },
      products: deliveryNote.products
        ? deliveryNote.products.map((product) => {
            return {
              ...invoicedProduct,
              description: product.name,
              barcode: product.barcode,
              comment: product.comment,
              dnReceivedQty: product.receivedQtyInCase
                ? product.dnReceivedQty * product.orderCaseSize
                : product.dnReceivedQty,
              invoicePrice: product.price ? product.price : 0,
              invoiceQty: product.dnQty,
              orderPrice: product.price ? product.price : 0,
              invoiceQtyInCase: product.receivedQtyInCase,
              receivedQtyInCase: product.receivedQtyInCase,
            }
          })
        : [],
      totalCost: null,
    }

    saveInvoice(InvoiceParams)
  }

  const toggleFile = (file, index) => {
    setSelectedFiles([
      ...selectedFiles.map((fileItem, fileIndex) => {
        if (index === fileIndex) {
          return {
            ...fileItem,
            selected: !fileItem.selected,
          }
        } else {
          return fileItem
        }
      }),
    ])
  }

  const resetState = () => {
    setLoading(false)
    setPolling(false)
    setJobId(null)
    setExtractedFile(null)
    setSelectedFiles([])
    setCurrentStep(1)
    setConfirmed(false)
    setCameraEnabled(true)
  }

  const confirmSupplier = async () => {
    dispatch({
      type: "UPDATE_NEW_INVOICE",
      payload: {
        supplier: selectedSupplier,
      },
    })
    // setSelectDelivery(true)
  }

  const handleSupplierSelect = (supplier) => {
    setSelectedSupplier(supplier)
  }

  /**
   * Interval that resolves when extraction job is finished
   *
   * @return  {Function}  Calls parseResult
   */
  useInterval(
    async () => {
      const result = await extractInvoiceDataFromPdf(
        jobId,
        newInvoice.supplier.name
      )
      if (result.status === "SUCCEEDED") {
        setPolling(false)
        setJobId(null)
        return parseResult(result)
      }
    },
    isPolling ? 2000 : null
  )

  let content = (
    <>
      <div className="bg-black w-full h-full flex items-center justify-center">
        {cameraDemoUsers.indexOf(user.username) > -1 ? (
          <>
            <DocumentCameraFiles
              selectedFiles={selectedFiles}
              onConfirm={() => {
                setConfirmed(true)
                uploadFiles(selectedFiles)
              }}
              onSelectToggle={toggleFile}
            />
            <DocumentCamera
              onCapture={onImageCapture}
              accept={`image/jpeg,image/png,image/jpg${
                !selectedFiles.length ? ",application/pdf" : ""
              }`}
              blur={currentStep === 3 || currentStep === 4}
              onAddManually={() => {
                setSelectedFiles([])
                setCurrentStep(3)
                setCameraEnabled(false)
              }}
              cameraEnabled={cameraEnabled}
            />
          </>
        ) : (
          <div className="w-full max-w-lg px-4 text-center">
            <h2 className="text-xl text-primaryGreen mb-3">
              Scanning is currently being improved
            </h2>
            <p className="text-white opacity-75 mb-4">
              We will notify you when you can easily scan your invoices again.
            </p>
            <button
              className="button button--autoWidth button--primaryGreen"
              onClick={() => {
                setSelectedFiles([])
                setCurrentStep(3)
              }}
              disabled={!permissionObj?.permissions.modify}
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Add manually
            </button>
          </div>
        )}
      </div>
    </>
  )

  /**
   * Show image preview when uploading
   *
   * @var {[type]}
   */
  if (selectedFiles.length > 0 && confirmed) {
    // Confirm screen
    content = (
      <>
        {selectedFiles[selectedFiles.length - 1].file.type ===
        "application/pdf" ? (
          <div className={styles.confirmPdf}>
            <FontAwesomeIcon icon={faFilePdf} className={styles.pdfIcon} />
            <span>{selectedFiles[selectedFiles.length - 1].file.name}</span>
          </div>
        ) : (
          <img
            alt="Delivery Note Preview"
            className={styles.confirmImg}
            src={selectedFiles[selectedFiles.length - 1].dataUri}
          />
        )}
        {currentStep === 2 && <div className={styles.scanning} />}
      </>
    )
  }

  if (!newInvoice.supplier && cameraDemoUsers.indexOf(user.username) > -1) {
    content = (
      <div className="h-full overflow-hidden flex relative z-10 flex-col bg-white">
        <h2 className="px-4 lg:px-6 py-4 text-xl text-primaryBlue">
          Select your supplier
        </h2>
        <div className="flex-grow px-4 overflow-auto relative">
          <Loader isLoading={loading} />
          <SupplierSelect onSelect={handleSupplierSelect} />
        </div>
        <div className="p-4 border-t">
          <button
            disabled={loading}
            onClick={() => confirmSupplier()}
            className="button button--primaryBlue"
          >
            Confirm
          </button>
        </div>
      </div>
    )
  }

  return (
    <Modal
      isOpen
      style={{ content: { bottom: "40px", border: 0, overflow: "hidden" } }}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={!loading}
      shouldCloseOnEsc={!loading && currentStep !== 3 && currentStep !== 4}
      portalClassName="invoice"
      {...otherProps}
    >
      <div className={styles.container}>
        <div className={styles.navBar}>
          <nav className={cx("header", "transparent")}>
            <button onClick={onRequestClose}>
              <FontAwesomeIcon icon={faArrowLeft} className={styles.backIcon} />
            </button>

            <div className={styles.headerMain}>
              <h2 className={styles.title}>Add invoice</h2>
            </div>

            <div className={styles.placeHolder}></div>
          </nav>
        </div>

        <div
          className={cx("content", "step" + currentStep, {
            contentConfirm: !!selectedFiles.length,
          })}
        >
          {content}
          <div className={styles.scanPending}>
            <div className={styles.scanPendingAnimation} />
            <p className={styles.scanPendingText}>
              {isPolling ? "Processing the file..." : "Scanning the file..."}
            </p>
            {/* Disable cancel when polling is going on for response file */}
            {!isPolling && (
              <button
                className={styles.scanPendingButton}
                onClick={() => {
                  resetState()
                  cancelRequest()
                }}
              >
                CANCEL
              </button>
            )}
          </div>

          {(currentStep === 3 || currentStep === 4) && (
            <div
              className={styles.clickArea}
              onClick={() => {
                resetState()
              }}
            ></div>
          )}

          <div className={styles.confirmContainer}>
            <div className={styles.confirm}>
              <ConfirmDetails
                onCancel={() => {
                  resetState()
                  if (onUpdate) {
                    onUpdate()
                  }
                }}
                onConfirm={() => {
                  onRequestClose()
                  showSuccess("Invoice approved!")
                  if (onUpdate) {
                    onUpdate()
                  }
                }}
                onSave={() => {
                  if (onUpdate) {
                    onUpdate()
                  }
                }}
                onReject={() => {
                  onRequestClose()
                  showError("Invoice rejected!")
                  if (onUpdate) {
                    onUpdate()
                  }
                }}
                onQuery={() => {
                  onRequestClose()
                  if (onUpdate) {
                    onUpdate()
                  }
                }}
                onFilesChange={() => {
                  if (onUpdate) {
                    onUpdate()
                  }
                }}
              />
            </div>
          </div>

          <div className={styles.orderSelectContainer}>
            <div className={styles.orderSelect}>
              <div className="h-full overflow-hidden flex flex-col bg-white">
                <div className="px-6 pt-4 pb-0 flex flex-shrink-0 items-center">
                  <button
                    className="text-primaryBlue mr-4"
                    onClick={() => {
                      resetState()
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </button>
                  <h2 className="text-xl text-primaryBlue">
                    Select a delivery
                  </h2>
                </div>
                <div className="flex-grow flex flex-col overflow-hidden relative">
                  <DeliveryNoteSelect onSelect={handleDeliveryNoteSelect} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

Invoice.propTypes = {
  onRequestClose: PropTypes.func,
  onUpdate: PropTypes.func,
}

export default Invoice
