import React, { useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/GlobalContextProvider"
import { useContext, useRef, useState } from "react"
import { useClickAway } from "react-use"
import { faChevronDown } from "@fortawesome/pro-light-svg-icons"

interface LocationsSelectorProps {
  className?: string
  height?: number
  width?: number
  allOrganizations?: boolean
}
const LocationsSelector = ({
  className = "",
  allOrganizations = false,
}: LocationsSelectorProps) => {
  const {
    organizations,
    organizationGroup,
    organizationsFormatted,
    selectedOrganizations,
  } = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  const [popupVisible, setpopupVisible] = useState<boolean>(false)
  const [allLocations, setAllLocations] = useState(true)
  const ref = useRef(null)

  const toggleVisibility = () => setpopupVisible((prev) => !prev)

  const userSelectedOrganizations = Array.isArray(selectedOrganizations)
    ? selectedOrganizations
    : []

  useClickAway(ref, () => {
    setpopupVisible(false)
  })

  useEffect(() => {
    if (userSelectedOrganizations?.length !== organizationOptions?.length) {
      setAllLocations(false)
    }
  }, [userSelectedOrganizations])

  useEffect(() => {
    updateSelectedOrganizations(organizationOptions.map((org) => org.id))
    setAllLocations(true)
  }, [])

  const updateSelectedOrganizations = (organizations) => {
    dispatch({ type: "SET_SELECTED_ORGANIZATIONS", payload: organizations })
  }

  const handleAllLocations = () => {
    if (allLocations) {
      updateSelectedOrganizations([])
      setAllLocations(false)
    } else {
      updateSelectedOrganizations(organizationOptions.map((org) => org.id))
      setAllLocations(true)
    }
  }

  const getAllOrganizations = () => {
    const orgs: { id: string; sub: boolean }[] = []
    organizationsFormatted?.map((org) => {
      orgs.push({ id: org.id, sub: false })

      if (org.subOrgsMapped?.length > 0) {
        org.subOrgsMapped.map((subOrg) => {
          orgs.push({ id: subOrg.id, sub: true })
        })
      }
    })

    return orgs
  }

  const organizationOptions: { id: string; sub: boolean }[] = allOrganizations
    ? getAllOrganizations()
    : organizationGroup?.map((id: string, i: number) =>
        i === 0 ? { id: id, sub: false } : { id: id, sub: true }
      )

  if (organizationOptions?.length <= 1) return null

  return (
    <div ref={ref}>
      <button
        type="button"
        onClick={toggleVisibility}
        style={{ paddingTop: "10.25px", paddingBottom: "10.25px" }}
        className={
          "cursor-pointer px-4 rounded flex items-center border border-gray-300 bg-paleBlue text-primaryGray focus:border-error text-left w-56 " +
          className
        }
      >
        <span className="w-full pr-4">
          {allLocations
            ? "All locations"
            : `${userSelectedOrganizations.length} location` +
              (userSelectedOrganizations.length === 1 ? "" : "s")}
        </span>
        <div className="ml-auto pl-4 border-l border-gray-600 border-opacity-50">
          <FontAwesomeIcon
            className="text-gray-600 text-opacity-50 hover:text-opacity-100"
            icon={faChevronDown}
            rotation={popupVisible ? 180 : undefined}
          />
        </div>
      </button>

      <div className="relative">
        {popupVisible && (
          <div
            className="absolute top-0 mt-2 translate-y-full left-0 bg-white shadow-md border rounded-lg p-5 flex w-72 space-x-8"
            style={{ zIndex: 999 }}
          >
            <ul className="space-y-2 max-h-52 w-full overflow-y-auto p-1">
              <li className="flex items-center space-x-2">
                <button
                  className="flex items-center space-x-2"
                  onClick={handleAllLocations}
                >
                  <input
                    type="checkbox"
                    className="text-primaryBlue"
                    readOnly
                    checked={allLocations}
                  />
                  <span className="font-semibold">All locations</span>
                </button>
              </li>

              {organizationOptions.map((orgOption) => {
                const visible = Boolean(
                  userSelectedOrganizations.find(
                    (selOrg) => selOrg === orgOption.id
                  )
                )
                return (
                  <li
                    key={"Org-" + orgOption.id}
                    className={
                      "flex items-center space-x-2 " +
                      (orgOption.sub ? "pl-2" : "")
                    }
                  >
                    <button
                      className="flex items-center space-x-2"
                      onClick={() =>
                        visible
                          ? updateSelectedOrganizations([
                              ...userSelectedOrganizations.filter(
                                (selectedOrg) => selectedOrg !== orgOption.id
                              ),
                            ])
                          : updateSelectedOrganizations([
                              ...userSelectedOrganizations,
                              orgOption.id,
                            ])
                      }
                    >
                      <input
                        type="checkbox"
                        className="text-primaryBlue"
                        readOnly
                        checked={visible}
                      />
                      <span>
                        {
                          organizations.find(
                            (org) => org.value === orgOption.id
                          ).label
                        }
                      </span>
                    </button>
                  </li>
                )
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

export default LocationsSelector
