import React, { useContext, useState } from "react"
import Helmet from "react-helmet"
import Header from "components/dashboard/Header/Header"
import format from "date-fns/format"
import { AsideContext, AsideContextType } from "context/AsideContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileDownload } from "@fortawesome/pro-light-svg-icons"
import AsideCalendar from "components/common/AsideCalendar/AsideCalendar"
import Loader from "components/common/Loader/Loader"
//@ts-ignore
import * as styles from "./PriceChangesReport.module.css"
import { GlobalStateContext } from "context/GlobalContextProvider"
import { convertToTimeZoneUTCString } from "services/helpers"
import { exportPriceReportInPeriodOfTime } from "services/price-reports"
import usePermissions from "hooks/usePermissions"
import { Permission } from "services/types"

const PriceChangesReport = () => {
  const { user } = useContext(GlobalStateContext)
  const aside = useContext<AsideContextType>(AsideContext)
  const [loading, setLoading] = useState(false)
  const [selectedPeriod, setSelectedPeriod] = useState({
    from: "",
    to: "",
    enteredTo: "",
  })
  const permissionObj = usePermissions("Price changes") as Permission

  const toggleDatePicker = () => {
    //@ts-ignore
    aside.showAside(AsideCalendar, {
      headerText: "Date picker",
      selectedPeriod,
      onConfirm: (data) => {
        setSelectedPeriod(data)
        aside.hideAside()
      },
      onClose: () => aside.hideAside(),
    })
  }

  const onExport = async () => {
    setLoading(true)
    const { from, enteredTo } = selectedPeriod
    const exportParams = {
      from: from && convertToTimeZoneUTCString(from, user.zoneId),
      to: enteredTo && convertToTimeZoneUTCString(enteredTo, user.zoneId),
    }
    await exportPriceReportInPeriodOfTime(exportParams)
    setLoading(false)
  }

  const formatDate = (date) => {
    if (!date) return
    return format(date, "dd/MM/yyyy")
  }

  return (
    <>
      <Helmet>
        <title>Price changes</title>
      </Helmet>
      <div className={styles.container}>
        <Header title="Price changes" />
        <div className={styles.content}>
          <Loader isLoading={loading}>Downloading report..</Loader>
          <div className="flex flex-col w-full lg:w-1/2 items-start">
            <h2 className="text-lg font-sansSemiBold font-semibold mb-1">
              Generate a price changes report
            </h2>
            <p className="text-md text-gray-700 mb-4">
              Want to know the price changes of your products over time? Select
              your period below and hit export to download a report.
            </p>
            {!permissionObj?.permissions.read ||
            !permissionObj.permissions.modify ? (
              <p className="text-primaryPink">
                You have no rights to generate price changes reports. Please
                contact your account manager
              </p>
            ) : (
              <>
                <label className={styles.label}>Select period</label>
                <div className="flex flex-row relative w-full md:w-10/12">
                  <div className="input-container mr-4">
                    <input
                      type="text"
                      value={formatDate(selectedPeriod.from) || ""}
                      className="cursor-pointer form-control rounded"
                      placeholder="From"
                      readOnly
                      onClick={toggleDatePicker}
                    />
                  </div>
                  <div className="input-container mr-2">
                    <input
                      type="text"
                      className="cursor-pointer form-control rounded"
                      value={formatDate(selectedPeriod.to) || ""}
                      placeholder="To"
                      readOnly
                      onClick={toggleDatePicker}
                    />
                  </div>
                </div>
                <button
                  type="button"
                  onClick={onExport}
                  disabled={!selectedPeriod.from || !selectedPeriod.enteredTo}
                  className={`button button--autoWidth button--primaryGreen mt-6`}
                >
                  <FontAwesomeIcon icon={faFileDownload} className="mr-2" />{" "}
                  Export
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default PriceChangesReport
