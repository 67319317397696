import { get, post, put, remove, patch } from "./api"
import { saveAs } from "file-saver"

// Connection

export const getStockTransferConnectionsByCriteria = (params) => {
  return get("/stock-transfer-connections", params)
}

export const createStockTransferConnection = (params) => {
  return post("/stock-transfer-connections", params)
}

export const deleteStockTransferConnectionById = (params) => {
  return remove(
    `/stock-transfer-connections/${params.stockTransferConnectionId}`
  )
}

// Transfers

export const getReceivedStockTransfersByCriteria = (params) => {
  return get("/stock-transfers/received", params)
}

export const getSentStockTransfersByCriteria = (params) => {
  return get("/stock-transfers/sent", params)
}

export const createStockTransfer = (params) => {
  return post("/stock-transfers", params.payload)
}

export const getStockTransferById = (params) => {
  return get(`/stock-transfers/${params.stockTransferId}`)
}

export const updateStockTransfer = (params) => {
  return put(`/stock-transfers/${params.stockTransferId}`, params.payload)
}

export const deleteStockTransferById = (params) => {
  return remove(`/stock-transfers/${params.stockTransferId}`)
}

export const cancelStockTransfer = (params) => {
  return post(`/stock-transfers/${params.stockTransferId}/cancel`)
}

export const receiveStockTransfer = (params) => {
  return post(
    `/stock-transfers/${params.stockTransferId}/receive?receivedAt=${params.receivedAt}`
  )
}

export const revertStockTransferToSent = (params) => {
  return patch(`/stock-transfers/${params.stockTransferId}/revert-to-sent`)
}

export const updateStockTransferSentProduct = (params) => {
  return put(
    `/stock-transfers/${params.stockTransferId}/sent-product/${params.sentProductId}`,
    params.productPayload
  )
}

export const updateStockTransferItem = (params) => {
  return put(
    `/stock-transfers/${params.stockTransferId}/${params.direction}-${params.typeKey}/${params.id}`,
    params.payload
  )
}

export const exportStockTransfersDetailedAsXlsx = async (params) => {
  const response = await get(
    "/stock-transfers/products/detailed/export-as-xlsx",
    params,
    {
      download: true,
    }
  )
  if (response) {
    const result = await response.blob()
    saveAs(result, params.fileName)
  } else {
    return false
  }
}
