import ColoredHighlight from "components/common/ColoredHighlight/ColoredHighlight"
import EditPreferredProduct from "components/suppliers/EditPreferredProduct/EditPreferredProduct"
import EditProduct from "components/suppliers/EditProduct/EditProduct"
import { AsideContext } from "context/AsideContext"
import React, { SetStateAction, useContext } from "react"
import { roundNumber } from "services/helpers"
import { getProductsByGroupId } from "services/product"
import { Category } from "../../../services/types"
import ActionCell from "./ActionCell"
import CategoryCell from "./CategoryCell"
import PackageCell from "./PackageCell"
import PriceCell from "./PriceCell"
import SkuCell from "./SkuCell"
import SupplierCell from "./SupplierCell"
import TitleCell from "./TitleCell"

const useProductTableColumns = (
  permissionObj: any,
  getData: () => Promise<void>,
  setProducts: SetStateAction<any>,
  showingStockOnHandData: boolean,
  globalOrder: any,
  multiSelectActive: boolean,
  handleActionClick: (action: any, item: any) => void,
  categories: Category[]
) => {
  const aside = useContext(AsideContext)

  const startEditProduct = async (product: any, onAsideClose?: void) => {
    const values = { ...product, generateBarcode: false }

    if (product.preferred) {
      const products = product.children
        ? [product, ...product.children]
        : await getProductsByGroupId(product.groupId)

      aside.showAside(EditPreferredProduct, {
        products: products,
        fetchProducts: getData,
        onClose: () => {
          aside.hideAside()
        },
      })
    } else {
      aside.showAside(EditProduct, {
        headerText: "Edit product",
        initialValues: values,
        onClose: onAsideClose ? onAsideClose : aside.hideAside,
      })
    }
  }

  const columns = [
    {
      key: "foldableToggler",
      width: 30,
      flexShrink: 0,
    },
    {
      key: "name",
      title: "Item",
      dataKey: "name",
      width: 150,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => {
        return (
          <TitleCell
            enableClick={permissionObj?.permissions.modify}
            rowData={rowData}
            onClickEvent={startEditProduct}
          />
        )
      },
      sortable: true,
    },
    {
      key: "supplierId",
      title: "Supplier",
      width: 80,
      dataKey: "supplierId",
      className: "text-sm",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => <SupplierCell rowData={rowData} />,
    },
    {
      key: "packaging",
      title: "Packaging",
      width: 120,
      dataKey: "packaging",
      className: "text-sm",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <PackageCell setProducts={setProducts} rowData={rowData} />
      ),
    },
    {
      hidden: showingStockOnHandData,
      key: "category",
      title: "Category",
      width: 120,
      dataKey: "category",
      flexGrow: 1,
      flexShrink: 0,
      className: "text-sm",
      cellRenderer: ({ rowData }) => (
        <CategoryCell rowData={rowData} categories={categories} />
      ),
      sortable: true,
    },
    {
      key: "price",
      title: "Cost price",
      width: 80,
      dataKey: "price",
      flexGrow: 1,
      className: "text-sm",
      flexShrink: 0,
      cellRenderer: ({ rowData }) => <PriceCell rowData={rowData} />,
    },
    {
      hidden: showingStockOnHandData,
      key: "sku",
      title: "SKU",
      width: 100,
      dataKey: "sku",
      className: "text-sm break-all",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => <SkuCell rowData={rowData} />,
    },
    {
      hidden: !showingStockOnHandData,
      key: "stock-on-hand",
      dataKey: "stock-on-hand",
      title: "Stock on hand",
      className: "justify-center xl:justify-start",
      width: 100,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => {
        return (
          <ColoredHighlight
            value={
              rowData.stockOnHand === null
                ? null
                : roundNumber(rowData.stockOnHand)
            }
          />
        )
      },
    },
    {
      hidden: !showingStockOnHandData,
      key: "avg-weekly-consumption",
      dataKey: "avg-weekly-consumption",
      title: "Avg weekly consumption",
      width: 100,
      flexGrow: 1,
      flexShrink: 0,
      className: "justify-center xl:justify-start",
      cellRenderer: ({ rowData }) => {
        return (
          <ColoredHighlight
            value={
              rowData.avgWeeklyConsumption === null
                ? null
                : roundNumber(rowData.avgWeeklyConsumption)
            }
          />
        )
      },
    },
    {
      hidden: !showingStockOnHandData,
      key: "awaiting-delivery",
      dataKey: "awaiting-delivery",
      title: "Awaiting delivery",
      width: 100,
      flexGrow: 1,
      flexShrink: 0,
      className: "justify-center xl:justify-start",
      cellRenderer: ({ rowData }) => {
        return (
          <ColoredHighlight
            value={
              rowData.awaitingDelivery === null
                ? null
                : roundNumber(rowData.awaitingDelivery)
            }
          />
        )
      },
    },
    {
      key: "action",
      title: "Actions",
      flexGrow: 1,
      flexShrink: 0,
      width: 150,
      className: "justify-end actions",
      headerClassName: "justify-end actions",
      cellRenderer: ({ rowData }) => (
        <ActionCell
          rowData={rowData}
          permissionObj={permissionObj}
          globalOrder={globalOrder}
          multiSelectActive={multiSelectActive}
          handleActionClick={handleActionClick}
        />
      ),
    },
  ]

  return columns
}

export default useProductTableColumns
