/* eslint-disable @typescript-eslint/no-explicit-any */

import { saveAs } from "file-saver"
export interface ProductDish {
  name: string
  barcode: string
  unit?: any
  category: string
  subCategory: string
  measure: string
  size: number
  price: number
  supplierName: string
  supplierId: string
  packaging: string
}
export interface Recipe {
  name: string
  barcode: string
  unit?: any
  category: string
  subCategory: string
  measure: string
  size: number
  totalCost: number
  totalCostPercent: number
  price: number
  description: string
  notes: string
  allergens: string[]
  mayContainAllergens: string[]
  profit: number
  profitPercent: number
  hasDeletedIngredients: boolean | null
  featuredFile?: any
  ingredients?: RecipeIngredient[]
  posId?: string
  otherIngredients?: string
  otherIngredientsCost?: number
}

export interface ProductRow {
  product: ProductDish
  usedQty: number
  measure: string
  usedQtyInProductMeasure: number
  cost: number
}

export interface RecipeRow {
  recipe: Recipe
  usedQty: number
  measure: string
  usedQtyInProductMeasure: number
  cost: number
}

export interface OtherIngredientRow {
  name: string
  usedQty: number
  measure: string
  usedQtyInProductMeasure: number
  cost: number
}

export interface RecipeContainer {
  ingredient: ProductRow
  recipe: RecipeRow
  type: "RECIPE"
}
export interface OtherIngredientContainer {
  ingredient: null
  recipe: null
  otherIngredient: OtherIngredientRow
  type: "OTHER_INGREDIENT"
}

export interface ProductContainer {
  ingredient: ProductRow
  recipe: null
  otherIngredient: null
  type: "INGREDIENT"
}

export type DishElement =
  | ProductContainer
  | RecipeContainer
  | OtherIngredientContainer

export type UnboxedDishElement = ProductRow | RecipeRow | OtherIngredientRow

export type DishElementType = DishElement["type"]

export interface Section {
  name: string
  elements: Array<DishElement>
}

export interface Dish {
  allergens: string[]
  mayContainAllergens: string[]
  id: string
  organizations: string[]
  name: string
  posId: string
  method?: any
  notes?: any
  category: string
  totalCost: number
  totalCostPercent: number
  profit: number
  profitPercent: number
  suggestedSalePrice: number | null
  targetMarginPercent: number | null
  salePrice: number
  weight?: any
  sections: Section[]
  files?: any
  featuredFile?: any
  folder?: any
  createdDate: Date
  updatedDate: Date
  status: string
  menus: any[]
  waste: {
    cost: number
    percent: number
  }
  hasDeletedIngredients: boolean | null
}
type DishResponse = Promise<Dish>

import { get, post, put, remove } from "./api"
import { RecipeIngredient } from "./types"

const url = "/dishes/"
export const createDish = (dish: DishResponse) => post(url, dish)
export const saveDish = (id, dish): DishResponse => put(url + id, dish)
export const getDish = (id): DishResponse => get(url + id)
export const getDishes = (params) => get(url, params)
export const removeDish = (id): DishResponse => remove(url + id)

export const updateDishFeaturedImage = (id, params): DishResponse => {
  return post(`${url}${id}/featured-file`, params)
}

export const updateDishFiles = (id, params): DishResponse => {
  return post(`${url}${id}/files`, params)
}
export const getDishesByIds = (params: {
  dishesIds: string[]
}): DishResponse => {
  return post(`${url}get-by-ids`, params)
}

export const exportDishes = async (format, deleted = false) => {
  const subString = deleted ? "/deleted-ingredients" : ""
  const response = await get(
    `/dishes${subString}/export-as-${format}`,
    {},
    {
      download: true,
    }
  )

  if (response) {
    const result = await response.blob()
    const subName = deleted ? "-deleted" : ""
    saveAs(result, `all${subName}-dishes.${format}`)
  } else {
    return false
  }
}

export const reassignDishToOrg = (dishId, params) => {
  return post(`/dishes/${dishId}/reassign`, params)
}

export const getDishLocations = (dishId) => {
  return get(`/dishes/${dishId}/locations`)
}

export const reassingDishesToOrgs = (
  dishesIds: string[] = [],
  organizations: string[] = []
) => {
  return post(`/dishes/assign`, {
    organizations: organizations,
    dishesIds: dishesIds,
  })
}

export const unassignDishesFromOrgs = (
  dishesIds: string[] = [],
  organizations: string[] = []
) => {
  return post(`/dishes/unassign`, {
    organizations: organizations,
    dishesIds: dishesIds,
  })
}
