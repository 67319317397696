/* eslint-disable react/prop-types */
import React, { useContext } from "react"
import PropTypes from "prop-types"
import BaseTable, { AutoResizer } from "react-base-table"

import { ModalContext } from "context/ModalContext"

import { formatCurrencyValue, roundNumber } from "services/helpers"
import { useMediaQuery } from "react-responsive"

import WasteDetailsModal from "../WasteDetailsModal/WasteDetailsModal"

import * as styles from "./WasteReportContent.module.css"

const WasteReportContent = ({ wasteReport }) => {
  const modal = useContext(ModalContext)
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
  const currency = null

  const columns = [
    {
      key: "itemName",
      title: "Item name",
      dataKey: "itemName",
      width: 200,
      flexGrow: 1,
      flexShrink: 0,
      headerClassName: "pl-6",
      cellRenderer: ({ rowData }) => (
        <div className="flex flex-col my-3 pl-2 text-sm">
          <span className="font-bold text-sm font-sansBold text-primaryBlue">
            {rowData.product.name}
          </span>
        </div>
      ),
    },
    {
      key: "unit",
      title: "Unit",
      dataKey: "unit",
      width: 75,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <div className="text-sm">
          {rowData.product.unit ? rowData.product.unit : "No unit given"}
        </div>
      ),
    },
    {
      key: "sizeMeasure",
      title: "Size/measure",
      dataKey: "sizeMeasure",
      width: 75,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <div className="text-sm">
          {rowData.product.size && <span>{rowData.product.size}&nbsp;</span>}
          {rowData.product.measure && <span>{rowData.product.measure}</span>}
          {!rowData.product.size && !rowData.product.measure && (
            <span>No size/measure</span>
          )}
        </div>
      ),
    },
    {
      key: "category",
      title: "Category",
      dataKey: "category",
      width: 150,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => {
        return (
          <div className="text-sm">{`${rowData.product.category} - ${rowData.product.subCategory}`}</div>
        )
      },
    },
    {
      key: "totalQty",
      title: "Qty",
      dataKey: "totalQty",
      width: 75,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <div className="text-sm">{roundNumber(rowData.totalQty)}</div>
      ),
    },
    {
      key: "totalCost",
      title: "Cost",
      dataKey: "totalCost",
      width: 75,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <div className="text-sm">
          <span className="text-primaryPink font-sansSemiBold font-semibold">
            {currency
              ? formatCurrencyValue(rowData.totalCost)
              : roundNumber(rowData.totalCost)}
          </span>
        </div>
      ),
    },
  ]

  const onSelect = ({ rowData }) => {
    modal.showModal(WasteDetailsModal, {
      wasteProduct: rowData,
      wasteReport,
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className="w-full flex h-full flex-col flex-grow">
          <AutoResizer>
            {({ width }) => (
              <BaseTable
                fixed={isTabletOrMobile ? true : false}
                estimatedRowHeight={80}
                rowKey={"barcode"}
                width={width}
                maxHeight={
                  wasteReport.products.length === 0 ? undefined : Infinity
                }
                height={wasteReport.products.length === 0 ? 150 : Infinity}
                data={wasteReport.products}
                onEndReachedThreshold={300}
                rowClassName="cursor-pointer"
                ignoreFunctionInColumnCompare={false}
                columns={columns}
                emptyRenderer={
                  <div className={styles.noProducts}>
                    No waste records found
                  </div>
                }
                rowEventHandlers={{ onClick: onSelect }}
              />
            )}
          </AutoResizer>
        </div>
      </div>
    </div>
  )
}

WasteReportContent.propTypes = {
  wasteReport: PropTypes.object,
}

export default WasteReportContent
