import React, { useContext, useState, useMemo } from "react"
import { showError, showSuccess } from "services/toast"
import { createSupplierProduct } from "services/supplier"
import { reassignProductGroupToOrgs, updateProduct } from "services/product"

import Loader from "components/common/Loader/Loader"
import ProductForm from "components/suppliers/ProductForm/ProductForm"
import {
  Category,
  GrowyzeBackendGETResponse,
  Permission,
  ProductFormData,
  SubCategory,
} from "../../../services/types"
//@ts-ignore
import * as styles from "./EditProduct.module.css"
import usePermissions from "hooks/usePermissions"
import FormDropdown from "components/forms/FormDropdown"
import LocationSelection from "components/common/LocationSelection/LocationSelection"
import { GlobalStateContext } from "context/GlobalContextProvider"
import { getCategories } from "../../../services/categories"

const initialData = {
  barcode: "",
  barcodes: [],
  id: "",
  groupId: "",
  category: "",
  generateBarcode: false,
  subCategory: "",
  measure: "",
  posId: "",
  description: "",
  notes: "",
  name: "",
  size: "",
  price: "" as const,
  code: "",
  unit: "",
  supplierId: "",
  supplierName: "",
  organizations: [],
  productCase: "" as const,
  packaging: "single" as const,
  quantity: 0,
  allergens: [],
  mayContainAllergens: [],
  orderedIn: { single: true, pack: false, both: false } as {
    single: boolean
    pack: boolean
    both: boolean
  },
}

const EditProduct = ({
  initialValues = initialData,
  supplierData = {
    id: "",
    name: "",
  },
  onClose,
  createProduct,
  onSubmitCallback,
}) => {
  const initialiseForm = (categories?: Category[]) => {
    const newValues = { ...initialData, ...initialValues }

    if (supplierData.id && supplierData.name) {
      newValues.supplierId = supplierData.id
      newValues.supplierName = supplierData.name
    }

    if (Number(newValues.price) === 0) {
      // Default price value, empty string (nullable) is the initial value for Formik for input fields
      newValues.price = ""
    }

    if (!newValues.orderedIn) {
      //Init ordered in values based on productCase if not set on BE
      if (!newValues.productCase) {
        newValues.orderedIn = { single: true, pack: false, both: false }
      } else {
        newValues.orderedIn = { single: false, pack: false, both: true }
      }
    }

    if (categories) {
      const matchedCat = categories.find(
        (c: Category) => c.name === newValues.category
      )
      const matchedSubCat = matchedCat?.subCategories?.find(
        (subC: SubCategory) => subC.name === newValues.subCategory
      )

      if (matchedCat) {
        newValues.category = matchedCat.name
      } else {
        newValues.category = ""
      }

      if (matchedSubCat) {
        newValues.subCategory = matchedSubCat.name
      } else {
        newValues.subCategory = ""
      }
    }

    return newValues
  }

  const [productData, setProductData] = useState<ProductFormData>(
    initialiseForm()
  )
  const [categories, setCategories] = useState<Category[]>([])
  const [submitEnabled, setSubmitEnabled] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedOrgs, setSelectedOrgs] = useState<string[]>([])

  const { organizationPermissions } = useContext(GlobalStateContext)
  const permissionObj = usePermissions("Items") as Permission
  const isAllowedToManageLocations = organizationPermissions?.general.isMain

  const saveLocations = async (groupId: string) => {
    // We need to filter out main account for this request
    const params = {
      organizations: selectedOrgs,
    }
    await reassignProductGroupToOrgs(groupId, params)
  }

  const handleSubmit = async () => {
    setLoading(true)
    const { id } = productData

    if (!createProduct) {
      const result = await updateProduct(id, productData)
      if (
        result &&
        !result.error &&
        !result.errors &&
        result.status !== 400 &&
        result.status !== 409
      ) {
        setLoading(false)
        if (onSubmitCallback) {
          onSubmitCallback(result)
        }
        onClose("product-updated")
        showSuccess("Product updated!")
      } else {
        setLoading(false)
        showError(
          result.message || "Saving failed. Did you enter all required fields?"
        )
      }
    } else {
      productData.id = null // set product id to null if data has come from master db
      if (!productData.supplierId) {
        productData.supplierId = "no-supplier-id"
      }
      const result = await createSupplierProduct(
        productData.supplierId,
        productData,
        productData.generateBarcode
      )
      if (
        result &&
        !result.error &&
        !result.errors &&
        result.status !== 400 &&
        result.status !== 409
      ) {
        if (isAllowedToManageLocations) {
          await saveLocations(result.groupId)
        }
        setLoading(false)
        if (onSubmitCallback) {
          onSubmitCallback(result)
        }
        onClose("product-created")
        showSuccess("Product created!")
      } else {
        setLoading(false)
        showError(
          result.message || "Saving failed. Did you enter all required fields?"
        )
      }
    }
  }

  const handleDataChange = (data) => {
    setProductData(data.nextValues)
  }

  const getLatestCategories = async () => {
    try {
      const res: GrowyzeBackendGETResponse<Category> = await getCategories({
        active: true,
      })

      return res.content
    } catch (e) {
      console.log(e)
    }
  }

  React.useEffect(() => {
    let isMounted = true

    if (isMounted) {
      getLatestCategories().then((categories) => {
        if (categories) {
          setCategories(categories)
          setProductData(initialiseForm(categories))
        }
      })
    }
    return () => {
      isMounted = false
    }
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <Loader isLoading={loading}>Saving..</Loader>
        <ProductForm
          initialValues={productData}
          supplierData={supplierData}
          onDataChange={handleDataChange}
          onValidChange={(isValid) => setSubmitEnabled(isValid)}
          createProduct={createProduct}
          categories={categories}
        />
        {isAllowedToManageLocations && (
          <FormDropdown buttonText="Location / Area" fullWidth={true}>
            <LocationSelection
              currentSelection={selectedOrgs}
              onSelectionChange={(orgs) => setSelectedOrgs(orgs)}
              itemId={productData.id}
              groupId={productData.groupId}
              itemType="item"
              itemClass={"md:w-1/2"}
            />
          </FormDropdown>
        )}
      </div>
      <footer className={styles.footer}>
        <button
          type="button"
          className="mr-6 font-semibold font-sansSemiBold"
          onClick={(e) => {
            e.preventDefault()
            onClose()
          }}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={
            !submitEnabled || loading || !permissionObj?.permissions.modify
          }
          onClick={(e) => {
            e.preventDefault()
            handleSubmit()
          }}
          className="button button--autoWidth button--primaryGreen"
        >
          {!createProduct ? "Save" : "Add"}
        </button>
      </footer>
    </div>
  )
}

export default EditProduct
