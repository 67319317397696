import { get, post, put, remove } from "./api"
import { saveAs } from "file-saver"

export const getSales = (params) => {
  return get("/sales", params)
}

export const getSale = (saleId, params) => {
  return get(`/sales/${saleId}`, params)
}

export const getSalesProducts = (saleId, params) => {
  return get(`/sales/${saleId}/products`, params)
}

export const removeSale = (saleId) => {
  return remove(`/sales/${saleId}`)
}

export const convertSalesFile = (params) => {
  return post("/sales/convert", params, {
    headers: { "Content-Type": "multipart/form-data" },
  })
}

export const createSales = (params) => {
  return post("/sales/", params)
}

export const combineSales = (params) => {
  return post("/sales/combine", params)
}

export const exportSales = async (salesId, fileType, fileName) => {
  const response = await get(
    `/sales/${salesId}/export-as-${fileType}?fileName=${fileName}`,
    {},
    {
      download: true,
    }
  )
  if (response) {
    const result = await response.blob()
    saveAs(result, `${fileName}.${fileType}`)
  } else {
    return false
  }
}

export const extractfromSquare = (params) => {
  const { from, to } = params
  if (!to || !from) {
    throw new Error("Missing required param from or to")
  }
  return post(`/sales/extract-from-square?from=${from}&to=${to}`)
}

export const refreshOnNonMatchingPosIds = (salesId) => {
  if (!salesId) {
    throw new Error("No sales id found")
  }
  return put(`/sales/${salesId}/non-matching-posIds`)
}
