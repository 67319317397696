import { get, post } from "./api"
import { saveAs } from "file-saver"

export interface ExportReportTypeInterface {
  value: string
  label: string
  subUrl?: string
  status?: boolean
  supplier?: boolean
  transfers?: boolean
  period: boolean
  productSelector?: boolean
  downloadFormats: string[]
}

interface TypeExportObjectInterface {
  urlType?: string
  singular: string
  statusOptions: { label: string; value: string; color: string }[]
  transferOptions: { label: string; value: string; color: string }[]
  reportTypes: ExportReportTypeInterface[]
}

export interface ExportObjectInterface {
  orders: TypeExportObjectInterface
  deliveries: TypeExportObjectInterface
  invoices: TypeExportObjectInterface
  transfers: TypeExportObjectInterface
}

const exportResponse = async (response, fileName) => {
  if (response) {
    const result = await response.blob()
    saveAs(result, fileName)
  } else {
    return false
  }
}

export const exportSummary = async (url, params, fileName) => {
  const response = await get(url, params, {
    download: true,
  })

  exportResponse(response, fileName)
}

export const exportDetailed = async (url, params, fileName) => {
  const statusQuery = params.status ? `&status=${params.status}` : ""
  const response = await post(
    `${url}?from=${params.from}&to=${params.to}${statusQuery}`,
    params,
    {
      download: true,
    }
  )

  exportResponse(response, fileName)
}

export const exportProductsOfInvoicesAsXlsx = async (params, fileName) => {
  const response = await post("/invoices/products/export-as-xlsx", params, {
    download: true,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/octet-stream",
    },
  })

  exportResponse(response, fileName)
}
