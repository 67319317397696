import React, { useState, useRef, useContext } from "react"
import { Formik, Form, FieldArray, FieldArrayRenderProps } from "formik"
import FormikEffect from "components/forms/FormikEffect"
import * as Yup from "yup"
import { faWineBottle, faCube } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames/bind"
import {
  productFormMeasures,
  productMeasuresSingleLiquid,
  productMeasuresMultipleLiquid,
  productSingleUnits,
  allergens,
} from "services/constants"
import {
  formatCategoriesForAsyncSelect,
  normalizeNullValues,
} from "services/helpers"
import { getGlobalProducts } from "services/product"
import { searchSuppliers } from "services/supplier"
import EditableAsyncSelect from "components/forms/EditableAsyncSelect"
import AsyncSelect from "components/forms/AsyncSelect"
import TextInput from "components/forms/TextInput"
import BarcodeInput from "components/forms/BarcodeInput/BarcodeInput"
import TextArea from "components/forms/TextArea"
import CustomSelect from "components/forms/CustomSelect"
import { useMediaQuery } from "react-responsive"
import ReactTooltip from "react-tooltip"
import { faInfoCircle, faMinus, faPlus } from "@fortawesome/pro-light-svg-icons"
import FormDropdown from "components/forms/FormDropdown"
import { GlobalStateContext } from "context/GlobalContextProvider"
import { Category } from "../../../services/types"

const Schema = Yup.object().shape(
  {
    barcode: Yup.string().when("generateBarcode", {
      is: false,
      then: Yup.string().required("Barcode is required"),
    }),
    barcodes: Yup.array().of(Yup.string().required("Barcode is required")),
    name: Yup.string().required("Name is required"),
    price: Yup.number().required("Price is required"),
    // supplierName: Yup.string().required("Supplier is required"),
    size: Yup.number()
      .positive("Size must be bigger than 0")
      .when("measure", {
        is: (measure) => measure,
        then: Yup.number().required("Size is required").nullable(),
      })
      .nullable(),
    measure: Yup.string()
      .when("size", {
        is: (size) => size,
        then: Yup.string().required("Measure is required").nullable(),
      })
      .nullable(),
    productCase: Yup.lazy((value) => {
      switch (typeof value) {
        case "object":
          return Yup.object()
            .shape({
              size: Yup.number()
                .integer("Please enter a whole number")
                .positive("Please enter a positive number")
                .required("Size is required"),
              price: Yup.number().required("Price is required"),
            })
            .default("")
        case "string":
          return Yup.string().nullable().default("")

        default:
          return Yup.mixed()
      }
    }),
  },
  ["size", "measure"]
)

interface ProductFormProps {
  onDataChange: () => void
  onValidChange: () => void
  initialValues: {}
  supplierData: {}
  createProduct: () => void
  categories: Category[]
}

const ProductForm = ({
  onDataChange,
  onValidChange,
  initialValues,
  supplierData = {},
  createProduct,
  categories = [],
}: ProductFormProps) => {
  const hasCaseInitial = !(
    initialValues?.productCase === null || initialValues?.productCase === ""
  )

  const [calculateFromUnitPrice, setCalculateFromUnitPrice] = useState(false)
  const [calculateFromMultiplePrice, setCalculateFromMultiplePrice] =
    useState(false)
  const [hasCase, setHasCase] = useState(hasCaseInitial)
  const [copyProductCode, setCopyProductCode] = useState(false)

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })

  const tooltipSingleElement = useRef(null)
  const tooltipMultipleElement = useRef(null)
  const barcodeArrayHelpersRef = useRef<FieldArrayRenderProps | null>(null)

  const { organizationPermissions } = useContext(GlobalStateContext)
  const isSubAccount = organizationPermissions?.general?.isSub

  const searchProducts = (inputValue) => {
    const params = {
      size: 80,
      page: 0,
      name: inputValue ? inputValue : "",
    }
    return getGlobalProducts(params)
  }

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={normalizeNullValues(initialValues)}
      validationSchema={Schema}
    >
      {(formikProps) => {
        const { values, setValues, setFieldValue, dirty } = formikProps

        const onCalculateUnitPrice = () => {
          const { price, productCase } = values

          if ((!price || !productCase.size) && !calculateFromUnitPrice) {
            return ReactTooltip.show(tooltipMultipleElement?.current)
          }

          setCalculateFromUnitPrice(!calculateFromUnitPrice)
          setFieldValue(
            "productCase.price",
            calculateFromUnitPrice
              ? ""
              : parseFloat(price * productCase.size).toFixed(2)
          )
        }

        const onCalculateMultiplePrice = () => {
          const { price, size } = values.productCase

          if ((!price || !size) && !calculateFromMultiplePrice) {
            return ReactTooltip.show(tooltipSingleElement?.current)
          }

          setCalculateFromMultiplePrice(!calculateFromMultiplePrice)

          setFieldValue(
            "price",
            calculateFromMultiplePrice
              ? ""
              : parseFloat(price / size).toFixed(2)
          )
        }

        const onProductCodeCopy = () => {
          const _productCode = values.code
          let { code } = values.productCase
          if (_productCode.length !== 0) {
            code = _productCode
          }
          setCopyProductCode(!copyProductCode)

          setFieldValue("productCase.code", copyProductCode ? "" : code)
        }

        const handleOrderedInChange = (e) => {
          const val = e.target.value
          if (val) {
            setFieldValue("orderedIn", { [val]: true })
          }
        }

        const handleHasCaseChange = () => {
          if (hasCase) {
            setValues({
              ...values,
              productCase: "",
              orderedIn: {
                single: true,
                pack: false,
                both: false,
              },
            })
          } else {
            setValues({
              ...values,
              productCase: {},
              orderedIn: {
                single: false,
                pack: false,
                both: true,
              },
            })
          }
          setHasCase(!hasCase)
        }

        const supplierFieldDirty =
          (initialValues.supplierId != values.supplierId ||
            values.supplierId == "") &&
          initialValues.supplierId != "" &&
          dirty
        const supplierSelectDisabled =
          supplierData.id || supplierData.name || isSubAccount

        return (
          <>
            <FormikEffect
              onChange={onDataChange}
              onValidationChange={onValidChange}
            />
            <Form className="flex flex-col flex-shrink-0 w-full">
              <div className="form-group">
                <div className="input-container">
                  <EditableAsyncSelect
                    required={true}
                    name="name"
                    label="Item name *"
                    allowCreateWhileLoading={true}
                    type="text"
                    creatable={true}
                    promise={searchProducts}
                    formatOptionLabel={(selectValue, meta) => {
                      return meta.context === "menu" ? (
                        <div className="flex flex-col">
                          <span>
                            {selectValue.value.name
                              ? selectValue.value.name
                              : selectValue.label}
                          </span>
                          <span className="text-sm text-gray-600">
                            {selectValue.value.barcode}
                          </span>
                          <span className="text-sm text-gray-600">
                            {selectValue.value.size}
                            {selectValue.value.measure}
                          </span>
                        </div>
                      ) : selectValue.value.name ? (
                        selectValue.value.name
                      ) : (
                        selectValue.value
                      )
                    }}
                    getOptionLabel={(opt) => opt.value.name}
                    formatCreateLabel={(label) => (
                      <div>
                        <span className="font-sansSemiBold font-semibold">
                          Create new item
                        </span>{" "}
                        &apos;{label}&apos;
                      </div>
                    )}
                    placeholder="Item name *"
                    backspaceRemovesValue={false}
                    createOptionPosition="first"
                    isClearable={true}
                    defaultValue={
                      values.name
                        ? { label: values.name, value: values.name }
                        : null
                    }
                    value={
                      values.name
                        ? { label: values.name, value: values.name }
                        : null
                    }
                    onChange={async (option, meta) => {
                      if (meta.action === "clear") {
                        setValues({
                          ...values,
                          barcode: "",
                          size: "",
                          measure: "",
                          category: "",
                          subCategory: "",
                          notes: "",
                        })
                        return
                      }

                      if (!option || !option.value) {
                        setFieldValue("name", "")
                        return
                      }

                      if (typeof option.value !== "object") {
                        setFieldValue("name", option.value)
                        return
                      }

                      const {
                        barcode,
                        category,
                        measure,
                        size,
                        subCategory,
                        name,
                        notes,
                      } = option.value

                      setValues({
                        ...values,
                        barcode,
                        name,
                        notes: notes ? notes : "",
                        category: category ? category : "",
                        measure: measure ? measure : "",
                        size: size ? size : "",
                        subCategory: subCategory ? subCategory : "",
                      })
                    }}
                    className="form-control form-control--first"
                  />
                </div>
              </div>
              <div className="flex items-center border-r">
                <div className="form-group w-full">
                  <BarcodeInput
                    required={true}
                    name="barcode"
                    label={"Barcode *"}
                    onStatusChange={({ generate }) => {
                      setFieldValue("generateBarcode", generate)
                    }}
                    onBarcodeUpdate={(barcode) => {
                      setFieldValue("barcode", barcode)
                    }}
                    type="text"
                    placeholder={"Barcode *"}
                    className="form-control"
                    enableGenerateBarcode={!initialValues.id}
                    disabled={organizationPermissions.general.isSub}
                    disabledMessage={
                      organizationPermissions.general.isSub
                        ? "You can only add a new barcode. This barcode can only be changed from the main account."
                        : ""
                    }
                  />
                </div>
                {values.barcodes.length === 0 && (
                  <div className="flex items-center h-full border-t">
                    <button
                      type="button"
                      onClick={() => barcodeArrayHelpersRef?.current?.push("")}
                      className="btn bg-primaryBlueVeryLight px-2 rounded text-gray-700 font-sansBold mr-2 h-6 w-6 flex-shrink-0"
                    >
                      <FontAwesomeIcon icon={faPlus} size="sm" />
                    </button>
                  </div>
                )}
              </div>
              <FieldArray
                name="barcodes"
                render={(ArrayHelpers) => {
                  const { push, remove } = ArrayHelpers

                  const handleAddBarcode = (barcode: string) => {
                    push(barcode)
                  }
                  const handleRemoveBarcode = (index: number) => {
                    remove(index)
                  }

                  barcodeArrayHelpersRef.current = ArrayHelpers

                  const barcodes = values.barcodes || []

                  return barcodes.map((barcode: string, index: number) => {
                    const isLastItem = index === barcodes.length - 1
                    const barcodeNumber = index + 2
                    return (
                      <div
                        className="flex items-center border-r"
                        key={`barcode-${index}`}
                      >
                        <div className="form-group w-full">
                          <BarcodeInput
                            required={true}
                            name={`barcodes.${index}`}
                            label={`Barcode ${barcodeNumber}`}
                            placeholder={`Barcode ${barcodeNumber}`}
                            onStatusChange={({ generate }) => {
                              setFieldValue("generateBarcode", generate)
                            }}
                            onBarcodeUpdate={(barcode) => {
                              setFieldValue(`barcodes.${index}`, barcode)
                            }}
                            type="text"
                            className="form-control"
                            enableGenerateBarcode={false}
                          />
                        </div>
                        <div className="flex items-center h-full border-t">
                          {isLastItem && (
                            <button
                              type="button"
                              onClick={() => handleAddBarcode("")}
                              className="btn bg-primaryBlueVeryLight px-2 rounded text-gray-700 font-sansBold mr-2 h-6 w-6 flex-shrink-0"
                            >
                              <FontAwesomeIcon icon={faPlus} size="sm" />
                            </button>
                          )}

                          <button
                            type="button"
                            onClick={() => handleRemoveBarcode(index)}
                            className="btn bg-primaryPinkLighter px-2 rounded text-gray-700 font-sansBold mr-2 h-6 w-6 flex-shrink-0"
                          >
                            <FontAwesomeIcon
                              icon={faMinus}
                              size="sm"
                              className="text-white"
                            />
                          </button>
                        </div>
                      </div>
                    )
                  })
                }}
              />
              <div className="form-group form-group--flex">
                <div className="input-container input-container--left">
                  <AsyncSelect
                    promise={searchSuppliers}
                    optionLabel="name"
                    optionValue="id"
                    onChange={(val) => {
                      setValues({
                        ...values,
                        supplierId: val?.value ?? "",
                        supplierName: val?.label ?? "",
                      })
                    }}
                    showValidations={false}
                    required={false}
                    name="supplier"
                    id="supplier"
                    label="Supplier"
                    placeholder="Supplier"
                    isClearable={initialValues.supplierId == ""}
                    className={
                      "form-control form-dropdown " +
                      (supplierSelectDisabled ? "opacity-40" : "")
                    }
                    defaultValue={
                      supplierData.id || supplierData.name
                        ? {
                            value: supplierData.id,
                            label: supplierData.name,
                          }
                        : values.supplierId
                        ? {
                            value: values.supplierId,
                            label: values.supplierName,
                          }
                        : null
                    }
                    value={
                      supplierData.id || supplierData.name
                        ? {
                            value: supplierData.id,
                            label: supplierData.name,
                          }
                        : values.supplierId
                        ? {
                            value: values.supplierId,
                            label: values.supplierName,
                          }
                        : null
                    }
                    staticOptions={{
                      label: "No Supplier",
                      value: "no-supplier-id",
                    }}
                    isSearchable={!isTabletOrMobile}
                    // blurInputOnSelect={isTabletOrMobile}
                    disabled={supplierSelectDisabled}
                  />
                </div>

                <div className="input-container input-container--right">
                  <TextInput
                    required={false}
                    name="code"
                    label="SKU / Product Code"
                    type="text"
                    placeholder="SKU / Product Code"
                    className="form-control"
                  />
                  <ReactTooltip
                    id={"sku_tooltip"}
                    type="light"
                    place="left"
                    effect="float"
                    border={true}
                    borderColor="#e2e8f0"
                    clickable
                  />
                  <span
                    data-for={"sku_tooltip"}
                    data-tip="The reference provided by the supplier for this item"
                  >
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className={"absolute right-4 top-4 opacity-75 z-10"}
                    />
                  </span>
                </div>
              </div>

              <div className="form-group form-group--flex">
                <div className="input-container input-container--left">
                  <CustomSelect
                    name="subCategory"
                    label="Category"
                    placeholder="Category"
                    isClearable={true}
                    className="form-control"
                    onSelectCallback={(val) => {
                      if (val) {
                        setFieldValue("category", val.groupValue)
                        setFieldValue("subCategory", val.value)
                      } else {
                        setFieldValue("category", "")
                        setFieldValue("subCategory", "")
                      }
                    }}
                    options={formatCategoriesForAsyncSelect(categories)}
                  />
                </div>

                <div className="input-container input-container--right">
                  <TextInput
                    required={false}
                    name="posId"
                    label="POS ID"
                    type="text"
                    placeholder="POS ID"
                    className="form-control "
                  />

                  <ReactTooltip
                    id={"pos_id_tooltip"}
                    type="light"
                    place="left"
                    effect="float"
                    border={true}
                    borderColor="#e2e8f0"
                    clickable
                  />
                  <span
                    data-for={"pos_id_tooltip"}
                    data-tip="The ID you’ve set up in your POS for this item"
                  >
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className={"absolute right-4 top-4 opacity-75 z-10"}
                    />
                  </span>
                </div>
              </div>

              <div className="form-group">
                <div className="input-container">
                  <TextInput
                    showValidations={false}
                    required={false}
                    name="minQtyInStock"
                    label="minQtyInStock"
                    min={0}
                    type="number"
                    placeholder="Min qty in stock"
                    className="form-control form-control--last"
                  />
                </div>
              </div>

              {supplierFieldDirty && (
                <div className="mb-4 mt-6 rounded border border-red-500 bg-red-100 p-3 font-bold">
                  Supplier is changed, please save first (other changes won`t be
                  saved).
                </div>
              )}

              <h5 className="mb-4 mt-6 text-gray-700">Packaging</h5>

              <div className="form-group form-group--flex-nowrap">
                <div className="form-group-label input-container--1-4 font-semibold ">
                  <FontAwesomeIcon
                    icon={faWineBottle}
                    size="xs"
                    className="mr-2"
                  />
                  single
                </div>
                <div className="form-group form-group--flex input-container--3-4">
                  <div className="form-group form-group--flex w-full">
                    <div
                      className="input-container input-container--1-3 input-container--middle"
                      // style={{ maxWidth: "calc(100% - 100px)" }}
                    >
                      <CustomSelect
                        showValidations={false}
                        required={false}
                        isClearable={true}
                        name="unit"
                        id="unit"
                        label="Unit"
                        placeholder="Unit"
                        className="form-control"
                        options={productSingleUnits}
                      />
                    </div>
                    <div
                      className="input-container input-container--1-3 input-container--middle"
                      style={{ maxWidth: isTabletOrMobile ? "100%" : "100px" }}
                    >
                      <TextInput
                        showValidations={false}
                        required={false}
                        name="size"
                        label="Size"
                        min={0}
                        type="number"
                        placeholder="Size"
                        className="form-control"
                      />
                    </div>
                    <div
                      className="input-container input-container--1-3"
                      // style={{ maxWidth: "calc(100% - 100px)" }}
                    >
                      <CustomSelect
                        showValidations={false}
                        required={false}
                        isClearable={true}
                        name="measure"
                        id="measure"
                        label="Measure"
                        placeholder="Measure"
                        className="form-control form-control--topright"
                        options={
                          values.unit === "Bottle" || values.unit === "Can"
                            ? productMeasuresSingleLiquid
                            : values.unit === "Keg"
                            ? productMeasuresMultipleLiquid
                            : productFormMeasures
                        }
                      />
                    </div>
                  </div>

                  <div className="form-group form-group--flex w-full">
                    <div className="input-container input-container--1-2">
                      <TextInput
                        required={true}
                        name="price"
                        label="Cost price *"
                        min={0}
                        type="number"
                        placeholder="Cost price *"
                        className="form-control"
                      />
                    </div>
                    <div className="input-container input-container--1-2">
                      <label className="items-center input-flex form-control form-control--bottomgroup form-control--borderl-none">
                        <input
                          type="checkbox"
                          value={""}
                          onChange={onCalculateMultiplePrice}
                          className="form-checkbox text-primaryPink"
                          checked={calculateFromMultiplePrice}
                        />
                        <span
                          data-tip="Please provide package price and package size"
                          ref={tooltipSingleElement}
                        />
                        <span className="ml-2 text-xs text-gray-700">
                          calculate from package price
                        </span>
                        <ReactTooltip
                          type="light"
                          place="bottom"
                          effect="solid"
                          border={true}
                          borderColor="#e2e8f0"
                          offset={{ top: -12, left: 7 }}
                          afterShow={() => setTimeout(ReactTooltip.hide, 3000)}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group form-group--flex-nowrap mt-4">
                <div
                  className={`form-group-label input-container--1-4 font-semibold ${
                    !hasCase && "opacity-50"
                  }`}
                >
                  <FontAwesomeIcon icon={faCube} size="xs" className="mr-2" />
                  multiple
                </div>

                <div className="form-group form-group--flex input-container--3-4">
                  <div className="form-group form-group--flex w-full ">
                    <label
                      className={classNames(
                        "items-center input-flex form-control form-control--topright",
                        { "form-control--bottomgroup": !hasCase }
                      )}
                    >
                      <input
                        type="checkbox"
                        value={""}
                        onChange={handleHasCaseChange}
                        name="hasCase"
                        className={classNames("form-checkbox text-primaryPink")}
                        checked={hasCase}
                        disabled={hasCaseInitial && createProduct}
                      />
                      <span className="ml-2 text-gray-700">in a package</span>
                    </label>
                  </div>

                  {hasCase && (
                    <div className="form-group form-group--flex-nostack w-full form-control ">
                      <div className="input-container input-container--1-2 input-container--middle">
                        <TextInput
                          showValidations={true}
                          required={true}
                          name="productCase.size"
                          label="Size"
                          min={0}
                          type="number"
                          placeholder="Package size *"
                          className="form-control"
                          value={values.productCase.size || ""}
                        />
                      </div>
                      <div
                        className="input-container input-flex lowercase"
                        style={{ maxWidth: !isTabletOrMobile && "50%" }}
                      >
                        {values.unit && values.unit !== "Other"
                          ? values.unit + "s per pack"
                          : "items per pack"}
                      </div>
                    </div>
                  )}

                  {hasCase && (
                    <div className="form-group form-group--flex w-full">
                      <div className="input-container input-container--1-2">
                        <TextInput
                          required={true}
                          name="productCase.price"
                          label="Cost price *"
                          min={0}
                          type="number"
                          placeholder="Cost price *"
                          className="form-control "
                          value={values.productCase.price || ""}
                        />
                      </div>
                      <div className="input-container input-container--1-2">
                        <label className="items-center input-flex form-control">
                          <input
                            type="checkbox"
                            value={""}
                            onChange={onCalculateUnitPrice}
                            name="hasCase"
                            className="form-checkbox text-primaryPink"
                            checked={calculateFromUnitPrice}
                          />
                          <span
                            data-tip="Please provide unit price and package size"
                            ref={tooltipMultipleElement}
                          />
                          <span className="ml-2 text-xs text-gray-700">
                            calculate from unit price
                          </span>
                          <ReactTooltip
                            type="light"
                            place="bottom"
                            effect="solid"
                            border={true}
                            borderColor="#e2e8f0"
                            offset={{ top: -12, left: 7 }}
                            afterShow={() =>
                              setTimeout(ReactTooltip.hide, 3000)
                            }
                          />
                        </label>
                      </div>
                    </div>
                  )}

                  {hasCase && (
                    <div className="form-group form-group--flex w-full">
                      <div className="input-container input-container--1-2">
                        <TextInput
                          required={false}
                          name="productCase.code"
                          label="SKU / Product code"
                          type="text"
                          placeholder="SKU / Product code"
                          className="form-control form-control--bottomgroup"
                          value={values.productCase.code || ""}
                        />
                      </div>
                      <div className="input-container input-container--1-2">
                        <label className="items-center input-flex form-control form-control--bottomgroup form-control--borderl-none">
                          <input
                            type="checkbox"
                            value={""}
                            name="codeCopy"
                            onChange={onProductCodeCopy}
                            className="form-checkbox text-primaryPink"
                            checked={copyProductCode}
                          />
                          <span
                            data-tip="Please provide package price and package size"
                            ref={tooltipSingleElement}
                          />
                          <span className="ml-2 text-xs text-gray-700">
                            same as item SKU/Product code
                          </span>
                          <ReactTooltip
                            type="light"
                            place="bottom"
                            effect="solid"
                            border={true}
                            borderColor="#e2e8f0"
                            offset={{ top: -12, left: 7 }}
                            afterShow={() =>
                              setTimeout(ReactTooltip.hide, 3000)
                            }
                          />
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="form-group form-group--flex-nowrap mt-4 mb-4">
                <div className="form-group-label input-container--1-4 font-semibold ">
                  Ordered in
                </div>
                <div className="form-group form-group--flex input-container--3-4">
                  <div className="form-group form-group--flex w-full items-center input-flex form-control form-control--topright form-control--bottomgroup">
                    <label className="inline-block mr-4">
                      <input
                        type="radio"
                        value="single"
                        onChange={handleOrderedInChange}
                        name="orderedIn"
                        className={classNames(
                          "form-checkbox text-primaryBlue focus:shadow-none focus:border-0"
                        )}
                        checked={values.orderedIn.single}
                      />
                      <span className="ml-2 text-gray-700">single</span>
                    </label>

                    <label
                      className={classNames("inline-block mr-4", {
                        "opacity-50": !hasCase,
                      })}
                    >
                      <input
                        type="radio"
                        value="pack"
                        onChange={handleOrderedInChange}
                        name="orderedIn"
                        className={classNames(
                          "form-checkbox text-primaryBlue focus:shadow-none focus:border-0"
                        )}
                        checked={values.orderedIn.pack}
                        disabled={!hasCase}
                      />
                      <span className="ml-2 text-gray-700">pack</span>
                    </label>

                    <label
                      className={classNames("inline-block mr-4", {
                        "opacity-50": !hasCase,
                      })}
                    >
                      <input
                        type="radio"
                        value="both"
                        onChange={handleOrderedInChange}
                        name="orderedIn"
                        className={classNames(
                          "form-checkbox text-primaryBlue focus:shadow-none focus:border-0"
                        )}
                        checked={values.orderedIn.both}
                        disabled={!hasCase}
                      />
                      <span className="ml-2 text-gray-700">both</span>
                    </label>
                  </div>
                </div>
              </div>

              <FormDropdown buttonText="Allergens" fullWidth={true}>
                <div className="my-3">
                  <div className="flex justify-end">
                    <div className="flex w-40">
                      <h4 className="w-1/2 text-xs text-center">Contains</h4>
                      <h4 className="w-1/2 text-xs text-center">May contain</h4>
                    </div>
                  </div>
                  {allergens.map((allergen) => {
                    const { label, value, icon } = allergen

                    const handleAllergenChange = (event) => {
                      const isChecked = event.target.checked
                      const name = event.target.name
                      const currentAllergens = [...values.allergens]

                      const nameIndex = currentAllergens.findIndex(
                        (allergen) => allergen === name
                      )

                      if (isChecked && nameIndex === -1) {
                        currentAllergens.push(name)
                      } else if (nameIndex !== -1) {
                        currentAllergens.splice(nameIndex, 1)
                      }

                      setFieldValue("allergens", currentAllergens)
                    }

                    const handleMayContainAllergenChange = (event) => {
                      const isChecked = event.target.checked
                      const name = event.target.name
                      const currentMayContainAllergens = [
                        ...values.mayContainAllergens,
                      ]

                      const nameIndex = currentMayContainAllergens.findIndex(
                        (allergen) => allergen === name
                      )

                      if (isChecked && nameIndex === -1) {
                        currentMayContainAllergens.push(name)
                      } else if (nameIndex !== -1) {
                        currentMayContainAllergens.splice(nameIndex, 1)
                      }

                      setFieldValue(
                        "mayContainAllergens",
                        currentMayContainAllergens
                      )
                    }

                    return (
                      <div
                        className="flex justify-between items-center w-full py-1 capitalize border-b"
                        key={value}
                      >
                        <div className="flex items-center text-sm">
                          <img className="w-8 p-1 mr-2" src={icon} />
                          {label}
                        </div>
                        <div className="flex items-center w-40">
                          <div className="w-1/2 flex justify-center">
                            <input
                              type="checkbox"
                              className="form-checkbox text-primaryBlueLighter cursor-pointer"
                              id={value}
                              name={value}
                              checked={values.allergens?.includes(value)}
                              onChange={handleAllergenChange}
                            />
                          </div>
                          <div className="w-1/2 flex justify-center">
                            <input
                              type="checkbox"
                              className="form-checkbox text-primaryBlueLighter cursor-pointer"
                              id={value}
                              name={value}
                              checked={values.mayContainAllergens?.includes(
                                value
                              )}
                              onChange={handleMayContainAllergenChange}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </FormDropdown>

              <FormDropdown buttonText="Product description" fullWidth={true}>
                <div className="form-group">
                  <div className="input-container">
                    <TextArea
                      showValidations={false}
                      name="description"
                      className="form-control form-control--first form-control--last"
                      placeholder="Product desciption"
                      label="Product description"
                    />
                  </div>
                </div>
              </FormDropdown>

              <FormDropdown buttonText="Notes" fullWidth={true}>
                <div className="form-group">
                  <div className="input-container">
                    <TextArea
                      showValidations={false}
                      name="notes"
                      className="form-control form-control--last form-control--first"
                      placeholder="Any notes, e.g. related to product, taste, etc"
                      label="Notes"
                    />
                  </div>
                </div>
              </FormDropdown>
            </Form>
          </>
        )
      }}
    </Formik>
  )
}

export default ProductForm
