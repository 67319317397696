import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { acceptInvitation } from "services/user"
import queryString from "query-string"
import { Link, navigate } from "gatsby"
import Loader from "components/common/Loader/Loader"
import Logo from "inline-svg/growyze-logo-primary-blue-dark.svg"
import ContactModal from "components/common/ContactModal/ContactModal"
import { ModalConsumer } from "../../../context/ModalContext"
import { container } from "./AcceptInvitation.module.css"
import { logout } from "services/auth"
import { showSuccess } from "services/toast"

const AdminInvite = ({ location }) => {
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const handleAcceptInvitation = async (data) => {
    setLoading(true)
    const token = data
    const acceptInvitationResult = await acceptInvitation(token)
    setLoading(false)
    if (acceptInvitationResult.status === 404) {
      setError(acceptInvitationResult.message)
    } else if (acceptInvitationResult.error) {
      setError("Something went wrong. Please try again or contact us.")
    } else {
      showSuccess(
        "Invitation accepted! Please login again to access your new venue."
      )
      navigate(`/dashboard`, { replace: true })
    }
  }

  const init = () => {
    logout(() => {
      const queryParams = queryString.parse(location.search)

      if (queryParams.token) {
        handleAcceptInvitation(queryParams.token)
      }
    })
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <div className={container}>
      <Loader
        isLoading={loading}
        style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
      >
        Loading..
      </Loader>

      {error && (
        <div className="text-center mb-6">
          <Logo className="mb-4 block mx-auto" />
          <h1 className="text-4xl mb-4">Something went wrong...</h1>
          <div className="text-primaryPink block w-full mx-auto max-w-xs text-center font-sansBold font-semibold mb-4">
            {error}
          </div>
          <p className="text-sm text-gray-700 text-center mb-5 px-6">
            Having trouble?{" "}
            <ModalConsumer>
              {({ showModal }) => (
                <a
                  href="mailto:support@growyze.com"
                  className="text-primaryPink"
                  onClick={(e) => {
                    e.preventDefault()
                    showModal(ContactModal)
                  }}
                >
                  Contact us
                </a>
              )}
            </ModalConsumer>
          </p>
          <p className="text-sm text-gray-700 text-center mb-5 px-6">
            <Link className="text-primaryPink" to="/login">
              Log in
            </Link>{" "}
          </p>
        </div>
      )}
    </div>
  )
}

AdminInvite.propTypes = {
  location: PropTypes.object,
}

export default AdminInvite
