import { get, post, remove, put } from "./api"
import { saveAs } from "file-saver"

export const createRecipe = (params) => {
  return post("/recipes/", params)
}
export const getRecipes = (params) => {
  return get("/recipes/", params)
}
export const getRecipe = (recipeId) => {
  return get(`/recipes/${recipeId}`)
}
export const getRecipeByBarcode = (barcode) => {
  return get(`/recipes/barcode/${barcode}`)
}

export const getRecipeLocations = (recipeId) => {
  return get(`/recipes/${recipeId}/locations`)
}

export const updateRecipe = (recipeId, params) => {
  return put(`/recipes/${recipeId}`, params)
}

export const removeRecipe = (recipeId) => {
  return remove(`/recipes/${recipeId}`)
}

export const updateRecipeFeaturedImage = (recipeId, params) => {
  return post(`/recipes/${recipeId}/featured-file`, params)
}

export const updateRecipeFiles = (recipeId, params) => {
  return post(`/recipes/${recipeId}/files`, params)
}

export const recalculateAllRecipesWithLatestPrices = () => {
  return post(`/recipes/update-latest-prices`)
}

export const exportRecipes = async (format, deleted = false) => {
  const subString = deleted ? "/deleted-ingredients" : ""
  const response = await get(
    `/recipes${subString}/export-as-${format}`,
    {},
    {
      download: true,
    }
  )

  if (response) {
    const result = await response.blob()
    const subName = deleted ? "-deleted" : ""
    saveAs(result, `all${subName}-recipes.${format}`)
  } else {
    return false
  }
}

export const reassignRecipeToOrg = (recipeId, params) => {
  return post(`/recipes/${recipeId}/reassign`, params)
}

export const reassingRecipesToOrgs = (
  recipesIds = [],
  organizationsIds = []
) => {
  return post(`/recipes/assign`, {
    organizations: organizationsIds,
    recipesIds: recipesIds,
  })
}

export const unassignRecipesFromOrgs = (
  recipesIds = [],
  organizationsIds = []
) => {
  return post(`/recipes/unassign`, {
    organizations: organizationsIds,
    recipesIds: recipesIds,
  })
}

export const getAiRecipeSuggestions = (params) => {
  return get(`/ai/recipe/suggestions?productName=${params.productName}`)
}
