import React, { useState, useContext, useCallback, useEffect } from "react"
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "context/GlobalContextProvider"
import { ModalContext } from "context/ModalContext"
import Modal from "react-modal"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import { showError, showSuccess } from "services/toast"
import { useDebounce } from "react-use"
import {
  createStockTakeSheet,
  getStockTakeSheet,
  updateStockTakeSheet,
  deleteStockTakeSheet,
  refreshStockTakeSheet,
} from "services/stock-take"
import StockTakeSheetHeader from "components/stock/StockTakeSheetHeader/StockTakeSheetHeader"
import StockTakeSheetContent from "components/stock/StockTakeSheetContent/StockTakeSheetContent"
import OptionsPopup from "components/common/OptionsPopup/OptionsPopup"
import EditName from "components/stock/EditName/EditName"
import { apiResponseIsError, removeDuplicatesFromArray } from "services/helpers"
import usePermissions from "hooks/usePermissions"
import { Permission } from "services/types"

const StockTakeSheet = ({ onRequestClose, ...otherProps }) => {
  const modal = useContext(ModalContext)
  const [loading, setLoading] = useState(false)
  const { newStockTakeSheet } = useContext(GlobalStateContext)
  const [sheetToEdit, setSheetToEdit] = useState(false)
  const [popupTitle, setPopupTitle] = useState("")
  const permissionObj = usePermissions("Stocktakes") as Permission

  const getData = async (sheet) => {
    if (sheet.id) {
      await refreshStockTakeSheet(sheet.id)
      const selectedSheet = await getStockTakeSheet(sheet.id)
      updateState(selectedSheet)
    }
    return
  }

  useEffect(() => {
    getData(newStockTakeSheet)
  }, [])

  const hideOptions = useCallback(() => {
    setSheetToEdit(false)
    // Wait for animation to be done
    setTimeout(() => {
      setPopupTitle("")
    }, 300)
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, cancel] = useDebounce(
    () => {
      if (!hasUnsavedChanges(newStockTakeSheet)) {
        return
      } else {
        saveSheet(newStockTakeSheet)
      }
    },
    5000,
    [newStockTakeSheet]
  )

  const dispatch = useContext(GlobalDispatchContext)

  const onEdit = (sheet) => {
    setSheetToEdit(sheet)
    setPopupTitle("Edit sheet name")
  }

  const updateName = (name) => {
    dispatch({
      type: "UPDATE_NEW_STOCKTAKE_SHEET",
      payload: {
        stockTakeSheetData: {
          name,
          unsynced: true,
        },
      },
    })
    hideOptions()
  }

  const hasUnsyncedProducts = (sheet) => {
    return (
      sheet.products &&
      sheet.products.filter((product) => product.unsynced === true).length > 0
    )
  }

  const sheetProductsAreValid = (products) => {
    const addedBarcodes = {}

    products.forEach((product) => {
      if (addedBarcodes[product.barcode]) {
        showError(
          `Product with barcode, ${product.barcode} has already been added to the stocksheet, please add another`
        )
      } else {
        addedBarcodes[product.barcode] = true
      }
    })
    return Object.keys(addedBarcodes).length === products.length
  }

  const sheetRecipesAreValid = (recipes) => {
    const addedIds = {}

    recipes.forEach((recipe) => {
      if (addedIds[recipe.recipeId]) {
        showError(
          `Recipe with ID, ${recipe.id} has already been added to the stocksheet, please add another`
        )
      } else {
        addedIds[recipe.recipeId] = true
      }
    })
    return Object.keys(addedIds).length === recipes.length
  }

  const hasUnsavedChanges = (sheet) => {
    return !sheet.id || sheet.unsynced || hasUnsyncedProducts(sheet)
  }

  const emitUpdate = () => {
    if (otherProps.onUpdate && permissionObj?.permissions.modify) {
      otherProps.onUpdate()
    }
  }

  const updateState = (res) => {
    if (!permissionObj?.permissions.modify) {
      return
    }
    const data = {
      ...res,
      products: res.products.map((prod) => {
        return { ...prod, unsynced: false }
      }),
      unsynced: false,
    }

    return dispatch({
      type: "UPDATE_NEW_STOCKTAKE_SHEET",
      payload: {
        stockTakeSheetData: data,
      },
    })
  }

  const afterSave = (sheet, result) => {
    setLoading(false)
    if (apiResponseIsError(result)) {
      showError(result?.message)
      getData(newStockTakeSheet)
    } else if (result) {
      emitUpdate()
      updateState(result, sheet.key)
    }
  }

  const saveSheet = async (sheet) => {
    setLoading(true)
    const params = {
      name: sheet.name,
      note: sheet.note,
      products: removeDuplicatesFromArray(sheet.products, "barcode"),
      recipes: removeDuplicatesFromArray(sheet.recipes, "recipeId"),
    }

    let result

    if (sheet.id && hasUnsavedChanges(sheet)) {
      result = await updateStockTakeSheet(sheet.id, params)
      return afterSave(sheet, result)
    } else if (sheet.id) {
      return afterSave(sheet, result)
    } else {
      result = await createStockTakeSheet(params)
      return afterSave(sheet, result)
    }
  }

  const onDeleteStockTakeSheet = async (sheet) => {
    setLoading(true)
    const deleted = await deleteStockTakeSheet(sheet.id)

    if (
      deleted &&
      deleted.status !== 500 &&
      deleted.status !== 400 &&
      !deleted.message
    ) {
      onRequestClose()
      dispatch({ type: "RESET_NEW_STOCKTAKE" })
      emitUpdate()
      setLoading(false)
      showSuccess("Sheet deleted!")
      return
    } else {
      setLoading(false)
      showError("Deleting failed, please try again later")
    }
  }

  const deleteStockTakeSheetConfirm = (sheet) =>
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: `Delete ${sheet.name}`,
      text: `Are you sure you want to delete ${sheet.name}? This cannot be undone.`,
      confirmButtonText: "Delete",
      onConfirm: () => onDeleteStockTakeSheet(sheet),
    })

  return (
    <Modal
      isOpen
      style={{ content: { bottom: "40px", border: 0, overflow: "hidden" } }}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={!loading}
      shouldCloseOnEsc={!loading}
      {...otherProps}
    >
      <StockTakeSheetHeader
        sheet={newStockTakeSheet}
        allSaved={!hasUnsavedChanges(newStockTakeSheet)}
        isSaving={loading}
        onEdit={() => onEdit(newStockTakeSheet)}
        onSave={() => saveSheet(newStockTakeSheet)}
        onDelete={() => deleteStockTakeSheetConfirm(newStockTakeSheet)}
      />

      <StockTakeSheetContent
        data={newStockTakeSheet}
        onAddProductCallback={sheetProductsAreValid}
        onAddRecipeCallback={sheetRecipesAreValid}
        onClose={onRequestClose}
      />

      <OptionsPopup
        active={sheetToEdit !== false}
        title={popupTitle}
        activeCallback={hideOptions}
      >
        {sheetToEdit && (
          <EditName
            currentName={sheetToEdit.name}
            isSaving={loading}
            onSave={updateName}
          />
        )}
      </OptionsPopup>
    </Modal>
  )
}

export default StockTakeSheet
