import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons"
import * as styles from "./ConfirmDetailsMessages.module.css"

const ConfirmDetailsMessages = ({ note }) => {
  const hasDiscrepancies = () => {
    return (
      (note.isCreatedManuallyWithoutOrder &&
        note.hasReceivedQtyDiscrepancies) ||
      (!note.isCreatedManuallyWithoutOrder &&
        note.hasReceivedOrderQtyDiscrepancies)
    )
  }
  return (
    <>
      {note.supplier && hasDiscrepancies() && (
        <div className={styles.warningContainer}>
          <div className={styles.warning}>
            <ul className={styles.warningItems}>
              <li className="py-1">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="mr-2 mt-1"
                />
                There are discrepancies identified. Please check and confirm!
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  )
}

ConfirmDetailsMessages.propTypes = {
  note: PropTypes.object,
}

export default ConfirmDetailsMessages
