import { get, remove, post, put } from "./api"
import { saveAs } from "file-saver"

// GET

export const getWastes = (params) => {
  return get("/wastes", params)
}

export const getWastesById = (wasteGroupId) => {
  return get(`/wastes/${wasteGroupId}`)
}

export const getWasteGroupForCurrentDay = () => {
  return get("/wastes/current-day")
}

// CREATE

export const createWaste = (params, wasteGroupId) => {
  return post("/wastes", params, { query: { wasteGroupId } })
}

export const createWasteRecipe = (params, wasteGroupId) => {
  return post("/wastes/recipe", params, { query: { wasteGroupId } })
}

export const createWasteDish = (params, wasteGroupId) => {
  return post("/wastes/dish", params, { query: { wasteGroupId } })
}

// EDIT

export const editWaste = (wasteGroupId, wasteId, barcode, params) => {
  return put(
    `/wastes/${wasteGroupId}/products/${barcode}/waste/${wasteId}`,
    params
  )
}

export const editWasteRecipe = (wasteGroupId, wasteId, recipeId, params) => {
  return put(
    `/wastes/${wasteGroupId}/recipe/${recipeId}/waste/${wasteId}`,
    params
  )
}

export const editWasteDish = (wasteGroupId, wasteId, dishId, params) => {
  return put(`/wastes/${wasteGroupId}/dish/${dishId}/waste/${wasteId}`, params)
}

// DELETE

export const deleteWaste = (wasteGroupId, wasteId, barcode) => {
  return remove(`/wastes/${wasteGroupId}/products/${barcode}/waste/${wasteId}`)
}

export const deleteWasteGroupById = (wasteGroupId) => {
  return remove(`/wastes/${wasteGroupId}`)
}

export const deleteWasteProduct = (wasteGroupId, barcode) => {
  return remove(`/wastes/${wasteGroupId}/products/${barcode}`)
}

export const deleteWasteRecipe = (wasteGroupId, recipeId) => {
  return remove(`/wastes/${wasteGroupId}/recipe/${recipeId}`)
}

export const deleteWasteDish = (wasteGroupId, dishId) => {
  return remove(`/wastes/${wasteGroupId}/dish/${dishId}`)
}

export const deleteWasteRecipeRecord = (wasteGroupId, recipeId, wasteId) => {
  return remove(`/wastes/${wasteGroupId}/recipe/${recipeId}/waste/${wasteId}`)
}

export const deleteWasteDishRecord = (wasteGroupId, dishId, wasteId) => {
  return remove(`/wastes/${wasteGroupId}/dish/${dishId}/waste/${wasteId}`)
}

// EXPORT

export const constructWasteReport = (params) => {
  return get(`/wastes/report`, params)
}

export const exportWasteReportAsCSV = async (params) => {
  const response = await get("/wastes/report/export-as-csv", params, {
    download: true,
  })

  if (response) {
    const result = await response.blob()
    saveAs(result, `waste-report-${params.from}-${params.to}.csv`)
  } else {
    return false
  }
}

export const updateWasteFiles = (wasteGroupId, barcode, wasteId, params) => {
  return post(
    `/wastes/${wasteGroupId}/products/${barcode}/waste/${wasteId}/files`,
    params
  )
}

export const updateWasteRecipeFiles = (
  wasteGroupId,
  recipeId,
  wasteId,
  params
) => {
  return post(
    `/wastes/${wasteGroupId}/recipe/${recipeId}/waste/${wasteId}/files`,
    params
  )
}

export const updateWasteDishFiles = (wasteGroupId, dishId, wasteId, params) => {
  return post(
    `/wastes/${wasteGroupId}/dish/${dishId}/waste/${wasteId}/files`,
    params
  )
}
