import React, { useContext, useEffect, useState } from "react"
import Header from "components/dashboard/Header/Header"
import CompleteProfileWidget from "components/dashboard/CompleteProfileWidget/CompleteProfileWidget"
import GetStartedModal from "../GetStartedModal/GetStartedModal"

import { countCompletedSteps } from "services/helpers"
import { getStartedSteps, getNextSteps } from "services/constants"
import Helmet from "react-helmet"
import * as styles from "./Dashboard.module.css"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/GlobalContextProvider"
import { ModalContext } from "context/ModalContext"
import Rocket from "inline-svg/rocket.svg"
import Logo from "inline-svg/growyze-icon.svg"
import { getOnboardingSteps } from "services/onboarding"
import ChartFilters from "../ChartFilters/ChartFilters"
import {
  faBoxOpen,
  faFileAlt,
  faShoppingCart,
} from "@fortawesome/pro-duotone-svg-icons"
import DashboardPanel from "../DashboardPanel/DashboardPanel"
import DashboardRecentStocktakes from "./DashboardRecentStocktakes/DashboardRecentStocktakes"

const Dashboard = () => {
  const modal = useContext(ModalContext)
  const [loading, setLoading] = useState(true)
  const { user: currentUser, onboardingSteps } = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  const firstOnboardingSteps = getStartedSteps.map((step) => {
    return {
      ...step,
      checked: onboardingSteps?.id && onboardingSteps[step.name],
    }
  })

  const secondOnboardingSteps = getNextSteps.map((step) => {
    return {
      ...step,
      checked: onboardingSteps?.id && onboardingSteps[step.name],
    }
  })

  const showGetStartedModal = () => {
    modal.showModal(GetStartedModal, {
      title: "Get started with growyze",
      description:
        "This simple guide helps you get the most of growyze in a few simple steps.",
      isFirstSteps: true,
      svgIcon: (
        <Rocket className="min-w-20 w-20 h-20 -my-20 flex-shrink-0 transform rotate-45" />
      ),
      withNotification: true,
    })
  }

  const showNextStepsModal = () => {
    modal.showModal(GetStartedModal, {
      title: "What is next?",
      description:
        "Why not complete your first stocktake, transfer your recipes and start recording waste?",
      isFirstSteps: false,
      svgIcon: <Logo className="min-w-12 w-12 h-12 mr-6 ml-3 flex-shrink-0" />,
      withNotification: false,
    })
  }

  const fetchOnboarding = async () => {
    try {
      setLoading(true)
      const res = await getOnboardingSteps()
      await dispatch({
        type: "UPDATE_ONBOARDING_STEPS",
        payload: { onboardingSteps: res },
      })
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchOnboarding()
    return () => setLoading(false)
  }, [])

  const options = [
    { value: "TODAY", displayName: "Today" },
    { value: "LAST_WEEK", displayName: "Last week" },
    { value: "LAST_MONTH", displayName: "Last month" },
  ]

  const [selectedOption, setSelectedOption] = useState(options[2])
  const [refreshObserver, setrefreshObserver] = useState(0)

  const components = Object.freeze([
    {
      key: "orders",
      displayName: "Orders",
      className: "flex w-full lg:w-1/2 mb-4 overflow-hidden",
      component: (
        <DashboardPanel
          chartType="line"
          apiRoute="orders"
          selectedOption={selectedOption}
          refreshObserver={refreshObserver}
          header={{
            title: "Orders",
            fontAwesomeIcon: faShoppingCart,
            iconBackgroundColor: "#ffe5f0",
            iconColor: "rgb(252, 55, 98)",
          }}
        />
      ),
    },
    {
      key: "invoices",
      displayName: "Invoices",
      className: "flex w-full lg:w-1/2 mb-4 overflow-hidden",
      component: (
        <DashboardPanel
          chartType="line"
          apiRoute="invoices"
          selectedOption={selectedOption}
          refreshObserver={refreshObserver}
          header={{
            title: "Invoices",
            fontAwesomeIcon: faFileAlt,
            iconBackgroundColor: "rgba(231, 237, 255, 1)",
            iconColor: "rgba(38, 118, 238, 1)",
          }}
        />
      ),
    },
    {
      key: "order-status",
      displayName: "Order status",
      className: "flex w-full lg:w-1/2 mb-4 overflow-hidden",
      component: (
        <DashboardPanel
          chartType="bar"
          apiRoute="orders"
          selectedOption={selectedOption}
          refreshObserver={refreshObserver}
          header={{
            title: "Order status",
            fontAwesomeIcon: faShoppingCart,
            iconBackgroundColor: "#ffe5f0",
            iconColor: "rgb(252, 55, 98)",
            // iconColor: "#272764"
          }}
        />
      ),
    },
    {
      key: "delivery-status",
      displayName: "Delivery status",
      className: "flex w-full lg:w-1/2 mb-4 overflow-hidden",
      component: (
        <DashboardPanel
          chartType="bar"
          apiRoute="delivery-notes"
          selectedOption={selectedOption}
          refreshObserver={refreshObserver}
          header={{
            title: "Delivery notes",
            fontAwesomeIcon: faBoxOpen,
            iconColor: "rgb(185, 29, 179)",
            iconBackgroundColor: "rgb(250, 234, 255)",
          }}
        />
      ),
    },
    {
      key: "recent-stocktakes",
      displayName: "Recent stocktakes",
      className: "w-full",
      style: {},
      component: <DashboardRecentStocktakes />,
    },
  ])

  const [visibleCharts, setVisibleCharts] = useState(
    components.map(({ key, displayName, className, style }) => ({
      key,
      displayName,
      className,
      style,
      visible: true,
    }))
  )

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <div className={styles.container}>
        <Header title="Dashboard" />
        <div className={styles.content}>
          {!loading &&
            onboardingSteps?.id &&
            countCompletedSteps(firstOnboardingSteps) !== "4/4" && (
              <CompleteProfileWidget
                user={currentUser}
                steps={firstOnboardingSteps}
                title="Get started with growyze"
                buttonText="Show me"
                actionHandler={showGetStartedModal}
                svgComponent={<Rocket className={styles.rocketIcon} />}
              />
            )}

          {!loading &&
            onboardingSteps?.id &&
            countCompletedSteps(firstOnboardingSteps) === "4/4" &&
            countCompletedSteps(secondOnboardingSteps) !== "3/3" && (
              <CompleteProfileWidget
                user={currentUser}
                steps={secondOnboardingSteps}
                title="What is next with growyze..."
                buttonText="Show me"
                actionHandler={showNextStepsModal}
                svgComponent={<Logo className={styles.growyzeIcon} />}
              />
            )}
          {/* <Widgets /> */}
          {!loading && (
            <>
              <ChartFilters
                setrefreshObserver={setrefreshObserver}
                setSelectedOption={setSelectedOption}
                selectedOption={selectedOption}
                options={options}
                visibleCharts={visibleCharts}
                setVisibleCharts={setVisibleCharts}
              />
              <div className="w-full flex flex-wrap">
                {visibleCharts
                  .filter((component) => component.visible)
                  .map((component) => {
                    return (
                      <div
                        className={component.className + " px-2"}
                        style={component.style}
                        key={component.key}
                      >
                        {
                          components.find((x) => x.key === component.key)
                            .component
                        }
                      </div>
                    )
                  })}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Dashboard
