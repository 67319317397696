import React from "react"
import { Category, SubCategory } from "../../../services/types"

interface CategoryCellProps {
  rowData: {
    category: string
    subCategory: string
  }
  categories: Category[]
}

const CategoryCell = ({ rowData, categories }: CategoryCellProps) => {
  const matchedCat = categories.find(
    (c: Category) => c.name === rowData.category
  )
  const matchedSubCat = matchedCat?.subCategories?.find(
    (subC: SubCategory) => subC.name === rowData.subCategory
  )

  return (
    <>
      {`${matchedCat ? matchedCat.name : ""} ${
        matchedSubCat ? `- ${matchedSubCat.name}` : ""
      }`}
    </>
  )
}

export default React.memo(CategoryCell)
