import { faChevronDown } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ProductIcon from "components/common/ProductIcon/ProductIcon"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/GlobalContextProvider"
import React, { SetStateAction, useContext } from "react"
import { selectedPackagingMatchesOrderedInConfig } from "services/order"
import { showError } from "services/toast"

interface PackageCellRowData {
  _selectedCase: "multiple" | "single"
  _isSub: boolean
  size: number
  id: string
  groupId: string
  measure: string
  productCase: {
    size: number
    price: number | string
  }
}

interface PackageCellProps {
  rowData: PackageCellRowData
  setProducts(data: any): SetStateAction<any>
}

const PackageCell = ({ rowData, setProducts }: PackageCellProps) => {
  const dispatch = useContext(GlobalDispatchContext)
  const { globalOrder } = useContext(GlobalStateContext)

  const hasCase = !!rowData.productCase
  const selectedCase = rowData._selectedCase

  const inCase = hasCase && selectedCase === "multiple"

  const productInContext = globalOrder.products.find(
    (a: any) => a.id === rowData.id
  )

  const changeProductState = (e) => {
    const value = e.target.value
    const newRowData = { ...rowData, _selectedCase: value }

    if (selectedPackagingMatchesOrderedInConfig(newRowData)) {
      //Update the product in global state
      productInContext &&
        dispatch({
          type: "UPDATE_GLOBAL_ORDER_PRODUCT",
          payload: {
            product: productInContext,
            _selectedCase: value,
          },
        })
    } else {
      if (productInContext) {
        // Let the user know the cart item was not updated.
        showError(
          "You're not allowed to order the product in this package type"
        )
      }
    }
    //Update local products
    setProducts((prev) => {
      const temp = [
        ...prev.content.map((item: any) => {
          // if this is a sub-item
          if (rowData._isSub && item.groupId === rowData.groupId) {
            return {
              ...item,
              children: item.children.map((child) => {
                if (child.id === rowData.id) {
                  return {
                    ...child,
                    _selectedCase: value,
                  }
                } else {
                  return child
                }
              }),
            }
          } else {
            if (item.id === rowData.id) {
              return {
                ...item,
                _selectedCase: value,
              }
            }
            return item
          }
        }),
      ]

      return {
        content: temp,
      }
    })
  }
  return (
    <div className="flex items-center h-8">
      <ProductIcon product={rowData} inCase={inCase} />
      {hasCase ? (
        <div className="relative">
          <select
            className={`font-sansSemiBold lg:text-sm text-xs bg-transparent font-semibold text-primaryBlue bg-none lg:pl-2 px-1 border-0 cursor-pointer truncate pr-6`}
            onChange={(e) => changeProductState(e)}
            value={rowData._selectedCase === "multiple" ? "multiple" : "single"}
          >
            <option value="multiple">
              {rowData.productCase.size} x {rowData.size} {rowData.measure}
            </option>
            <option value="single">
              {rowData.size} {rowData.measure}
            </option>
          </select>
          <FontAwesomeIcon
            icon={faChevronDown}
            className="absolute text-xs text-primaryBlue right-1 top-3 pointer-events-none"
          />
        </div>
      ) : (
        <span className="font-sansSemiBold lg:text-sm text-xs font-semibold text-primaryBlue lg:px-2 px-1">
          {rowData.size} {rowData.measure}
        </span>
      )}
    </div>
  )
}

export default React.memo(PackageCell)
