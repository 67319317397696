import { get, put, remove, post, patch } from "./api"
import { saveAs } from "file-saver"

export const getProducts = (params) => {
  return get("/products", params)
}

export const getProductsByIdsByPost = (params) => {
  return post(`/products`, params)
}

export const getProductById = (productId) => {
  return get(`/products/${productId}`)
}

export const getProductsByGroupId = (groupId, params = {}) => {
  return get(`/products/group/${groupId}`, params)
}

export const updateProduct = (productId, params) => {
  return put(`/products/${productId}`, params)
}

export const getGlobalProducts = (params) => {
  return get("/global-products", params)
}

export const getGlobalProductByBarcode = (barcode) => {
  return get(`/global-products/${barcode}`)
}

export const getProductByBarcode = (barcode) => {
  return get(`/products/barcode/${barcode}`)
}

export const getProductByNameAndBarcode = (params) => {
  return get(`/products/search-by-barcode-and-name`, params)
}

export const getProductsFromSupplierMarketplace = (params) => {
  return get("/products/marketplace", params)
}

export const getProductLocations = (productId: string) => {
  return get(`/products/${productId}/locations`)
}

export const getProductsByBarcodes = (params) => {
  return post("/products/get-by-barcodes", params)
}

export const removeProduct = (productId) => {
  return remove(`/products/${productId}`)
}

export const removeMultipleProducts = (params) => {
  return remove("/products/multiple", params)
}

export const convertProductFile = (params) => {
  return post("/products/convert", params, {
    headers: { "Content-Type": "multipart/form-data" },
  })
}

export const exportProductsToCsv = async (params = {}) => {
  const response = await get("/products/export-as-csv", params, {
    download: true,
  })

  if (response) {
    const result = await response.blob()
    const fileName = params.supplierId
      ? `supplier-${params.supplierId}-products.csv`
      : "all-products.csv"
    saveAs(result, fileName)
  } else {
    return false
  }
}

export const exportProductsToXlsx = async (params = {}) => {
  const response = await get("/products/export-as-xlsx", params, {
    download: true,
  })

  if (response) {
    const result = await response.blob()
    const fileName = params.supplierId
      ? `supplier-${params.supplierId}-products.xlsx`
      : "all-products.xlsx"
    saveAs(result, fileName)
  } else {
    return false
  }
}

export const updateProductPrices = (supplierId, barcode, params) => {
  return patch(
    `/products/prices?supplierId=${supplierId}&barcode=${barcode}`,
    params
  )
}

export const groupProducts = (preferredGroupId, childrenGroupIds) => {
  return post("/products/group", {
    otherGroupsIds: childrenGroupIds,
    preferredGroupId: preferredGroupId,
  })
}

export const unmarkAsSameItem = (productId, target: "single" | "group") => {
  return remove(
    `/products/${productId}/group?removeOnlyThis=${
      target === "group" ? false : true
    }`
  )
}

export const unAssignProductGroupsFromOrgs = (params: any) => {
  return post("/products/unassign", params)
}

export const assignProductGroupsToOrgs = (params: any) => {
  return post("/products/assign", params)
}

export const reassignProductGroupToOrgs = (groupId: string, params: any) => {
  return post(`/products/group/${groupId}/reassign`, params)
}
