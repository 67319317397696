import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import Modal from "react-modal"
import { navigate } from "gatsby"

interface GoEposConnectModalProps {
  onRequestClose(): void
}

const GoEposConnectModal: React.FunctionComponent<GoEposConnectModalProps> = ({
  onRequestClose,
  ...otherProps
}: GoEposConnectModalProps) => {
  return (
    <Modal
      isOpen
      onRequestClose={onRequestClose}
      portalClassName="confirm"
      {...otherProps}
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="text-gray-700 absolute right-0 py-4 px-6"
      >
        <FontAwesomeIcon size="lg" icon={faTimes} />
      </button>

      <div className="flex flex-col items-center">
        <div className="overflow-hidden flex-grow flex flex-col w-full">
          <header className="flex items-center px-4 py-3 text-center border-b flex-shrink-0">
            <img
              className="w-14"
              src="/images/integrations/goepos.png"
              alt="GoEpos logo"
            />
            <h2 className="text-primaryBlue text-xl">GoEpos integration</h2>
          </header>
          <div
            className="flex justify-center p-5"
            style={{ minHeight: "200px" }}
          >
            <p className="text-gray-700">
              Copy your API key and organization id located under the&nbsp;
              <button
                className="text-primaryBlue underline"
                onClick={() => {
                  {
                    navigate("/dashboard/company-settings?tab=api-key")
                    onRequestClose()
                  }
                }}
              >
                API
              </button>
              &nbsp;tab into the GoEpos portal. GoEpos will activate the
              integration automatically.
            </p>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default GoEposConnectModal
