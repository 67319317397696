import React, { useContext } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons"
import { GlobalStateContext } from "context/GlobalContextProvider"

interface InitialStepProps {
  nextStep(): void
}

const InitialStep = ({ nextStep }: InitialStepProps) => {
  const { user } = useContext(GlobalStateContext)

  return user ? (
    <div className="flex flex-col items-center text-center">
      <h1 className="text-4xl mb-3">
        {`Hi ${user.firstName}, welcome aboard!`}
      </h1>
      <p className="text-lg mb-4 text-gray-700">
        Watch this brief introduction video to help you get started fast & easy
      </p>
      <iframe
        src="https://www.youtube.com/embed/dvPkoxABI0M?rel=0"
        title="YouTube video player"
        frameBorder="0"
        className="w-full h-64 md:h-76 lg:h-96 rounded"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      <button
        type="button"
        onClick={nextStep}
        className="button button--autoWidth w-auto my-4 bg-primaryPink"
      >
        Let’s start <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
      </button>
      <br />
    </div>
  ) : null
}

export default InitialStep
