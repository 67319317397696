import { faExclamation } from "@fortawesome/pro-light-svg-icons"
import { faPencil } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import InvoicedProductItem from "components/invoices/InvoicedProductItem/InvoicedProductItem"
import { format } from "date-fns"
import React, { useContext, useEffect, useState } from "react"
import Select from "react-select"
import { formatQuantityValue } from "services/helpers"
import { Invoice, InvoicedProduct, AccountingAccount } from "services/invoices"
import {
  getAccountingAccounts,
  getAccountingSuppliers,
  getAccountingTaxRates,
  AccountingEditParams,
  AccountingProvider,
} from "services/accounting"
import Loader from "components/common/Loader/Loader"
import {
  GlobalDispatchContext,
  GlobalStateContext,
  initalAccounting,
} from "context/GlobalContextProvider"

interface AccountingPublishFormProps {
  provider: AccountingProvider
  invoice: Invoice
  AccountingParams: AccountingEditParams
  integrationId: string | null
  invoicedProducts: InvoicedProduct[]
  onEditDetails(): void
  setAccountingParams(props: AccountingEditParams): void
  handleInvoiceItemChange(val, field: string, index: number): void
  setDefaultAccountForLineItems(
    newAccount: AccountingAccount,
    prevAccount: AccountingAccount
  ): void
}

const AccountingPublishForm: React.FunctionComponent<
  AccountingPublishFormProps
> = (props: AccountingPublishFormProps) => {
  const {
    provider,
    invoice,
    AccountingParams,
    integrationId,
    invoicedProducts,
    onEditDetails,
    setAccountingParams,
    handleInvoiceItemChange,
    setDefaultAccountForLineItems,
  } = props

  const [loading, setLoading] = useState<boolean>(false)
  const { accounting } = useContext(GlobalStateContext)
  const { accounts, suppliers, taxRates, taxRateZero } =
    accounting[provider.key] || initalAccounting

  const dispatch = useContext(GlobalDispatchContext)
  const providerKey = provider.key

  const setAccountingDispatch = (field, value) => {
    // BE API returns a rawError string when authentication fails
    const setValue = value?.rawError ? [] : value
    dispatch({
      type: "ACCOUNTING",
      payload: {
        provider: provider,
        field: field,
        value: setValue,
      },
    })
  }

  useEffect(() => {
    const abortController = new AbortController()
    const getAccountingRelations = async () => {
      try {
        const params = {
          [providerKey + "IntegrationId"]: integrationId,
        }

        if (!accounts) {
          const accountRes = await getAccountingAccounts(providerKey, params, {
            signal: abortController.signal,
          })
          setAccountingDispatch("accounts", accountRes)
        }

        if (!suppliers) {
          const supplierRes = await getAccountingSuppliers(
            providerKey,
            params,
            {
              signal: abortController.signal,
            }
          )
          setAccountingDispatch("suppliers", supplierRes)
        }

        let setTaxRateZero = taxRateZero
        if (!taxRates) {
          const taxRatesRes = await getAccountingTaxRates(providerKey, params, {
            signal: abortController.signal,
          })

          if (taxRatesRes) {
            const taxRateOptions = [
              {
                label: "Default",
                options: [
                  {
                    name: "Extract from invoice",
                    displayedAs: "Extract from invoice",
                    taxType: null,
                    taxRate: null,
                  },
                ],
              },
              { label: "From Accounting", options: taxRatesRes },
            ]
            setAccountingDispatch(
              "taxRates",
              taxRatesRes?.rawError ? [] : taxRateOptions
            )
          }

          // find tax rate zero
          if (!taxRatesRes?.rawError) {
            const zeroTaxRates = taxRatesRes?.filter(
              (rate) => rate[provider.taxPercentagField] === 0
            )
            if (zeroTaxRates.length > 0) {
              setTaxRateZero = zeroTaxRates[0]
              setAccountingDispatch("taxRateZero", setTaxRateZero)
            }
          }
        }

        if (AccountingParams?.totalVat === 0) {
          setAccountingParams({
            ...AccountingParams,
            taxRate: setTaxRateZero,
          })
        }

        setLoading(false)
      } catch (e) {
        console.log(e)
      }
    }
    setLoading(true)
    getAccountingRelations()
    return () => {
      setLoading(false)
      abortController.abort()
    }
  }, [])

  const handleTextareaChange = (event) => {
    const target = event.target
    const value = target.value ? target.value : ""
    setAccountingParams({ ...AccountingParams, description: value })
  }

  const lineWidth = provider.taxPerLine ? "md:w-1/3" : "md:w-1/2"

  return (
    <>
      <Loader isLoading={loading} />
      <div
        className={
          "relative overflow-hidden flex-grow " + (loading ? "opacity-50" : "")
        }
      >
        <div className="flex items-start flex-wrap px-4 md:px-4">
          <div className="mt-4 w-full flex flex-col md:w-1/2 px-2">
            <label className="font-sansSemiBold font-semibold mb-2">
              Invoice number<sup className="text-red-600">*</sup>
            </label>
            <div className="flex items-start">
              <span
                className={`font-bold font-sansBold ${
                  !invoice.invoiceNumber ? "text-red-600" : " text-primaryBlue"
                }`}
              >
                {invoice.invoiceNumber
                  ? invoice.invoiceNumber
                  : "Invoice number is required"}
              </span>
              <button
                type="button"
                onClick={() => onEditDetails()}
                className="text-gray-700 ml-2"
              >
                <FontAwesomeIcon icon={faPencil} />
              </button>
            </div>
          </div>
          <div className="mt-4 mb-2 w-full flex flex-col md:w-1/2 px-2">
            <label className="font-sansSemiBold font-semibold mb-2">
              Due date<sup className="text-red-600">*</sup>
            </label>
            <div className="flex items-start">
              <span
                className={`font-bold font-sansBold ${
                  !invoice.dueDate ? "text-red-600" : "text-primaryBlue"
                }`}
              >
                {invoice.dueDate
                  ? format(new Date(invoice.dueDate), "dd/MM/yyyy")
                  : "Due date is required"}
              </span>
              <button
                type="button"
                onClick={() => onEditDetails()}
                className="text-gray-700 ml-2"
              >
                <FontAwesomeIcon icon={faPencil} />
              </button>
            </div>
          </div>
          <div className="mt-2 mb-2 w-full md:w-1/2 px-2">
            <div className="flex items-center flex-wrap">
              <label className="font-sansSemiBold font-semibold">
                Accounting supplier<sup className="text-red-600">*</sup>
              </label>
              {invoice.supplier && (
                <span className="bg-primaryBlueVeryLight rounded-full ml-auto block px-3 py-1 text-sm">
                  <FontAwesomeIcon
                    icon={faExclamation}
                    className="mr-2 text-sm"
                  />
                  <span className="uppercase text-xs">Invoice supplier:</span>{" "}
                  <span className="font-semibold font-sansSemiBold text-primaryBlue">
                    {invoice.supplier.name}
                  </span>
                </span>
              )}
            </div>
            <Select
              name="supplier"
              options={suppliers}
              placeholder={"Select a supplier"}
              isDisabled={loading}
              getOptionLabel={(opt) => opt.name}
              getOptionValue={(opt) => opt}
              onChange={(value) => {
                setAccountingParams({ ...AccountingParams, supplier: value })
              }}
              value={AccountingParams.supplier || null}
              className="mt-3"
            />
          </div>
          <div className="mt-2 mb-2 w-full md:w-1/2 px-2">
            <label className="font-sansSemiBold font-semibold">
              Accounting default account<sup className="text-red-600">*</sup>
            </label>
            <Select
              name="account"
              options={accounts}
              placeholder={"Select a default Accounting account"}
              isDisabled={loading}
              getOptionLabel={(opt) => `${opt.code} - ${opt.name}`}
              getOptionValue={(opt) => opt}
              onChange={(newAccount) => {
                const currentAccount = AccountingParams.account
                setDefaultAccountForLineItems(newAccount, currentAccount)
                setAccountingParams({
                  ...AccountingParams,
                  account: newAccount,
                })
              }}
              value={AccountingParams.account || null}
              className="mt-3"
            />
          </div>

          <div className="mt-2 mb-2 w-full px-2">
            <label className="font-sansSemiBold font-semibold mb-4 block">
              Accounting invoice items
            </label>
            <div className="flex items-center flex-wrap w-full mb-4">
              <div
                className={
                  "border-b pb-2 w-full text-xs text-gray-700 hidden md:block uppercase font-sansSemiBold font-semibold " +
                  lineWidth
                }
              >
                Item name
              </div>
              <div
                className={
                  "border-b pb-2 w-full pl-2 text-xs text-gray-700 hidden md:block font-sansSemiBold font-semibold uppercase " +
                  lineWidth
                }
              >
                Accounting Account
              </div>
              {provider.taxPerLine && (
                <div
                  className={
                    "border-b pb-2 w-full pl-2 text-xs text-gray-700 hidden md:block font-sansSemiBold font-semibold uppercase " +
                    lineWidth
                  }
                >
                  Tax Rate
                </div>
              )}
              {invoice.products.map((item, index) => {
                return (
                  <div
                    className={`flex items-center flex-wrap w-full ${
                      index % 2 === 0 ? "bg-gray-50" : ""
                    }`}
                    key={`${item.barcode}-${index}`}
                  >
                    <div className={"w-full py-2 px-2 " + lineWidth}>
                      <InvoicedProductItem item={item} />
                    </div>
                    <div className={"w-full py-2 px-2 " + lineWidth}>
                      <Select
                        name={`account-invoice-item-${index}`}
                        options={accounts}
                        placeholder={"Account (optional)"}
                        isDisabled={loading}
                        getOptionLabel={(opt) => `${opt.code} - ${opt.name}`}
                        getOptionValue={(opt) => opt}
                        onChange={(val) => {
                          handleInvoiceItemChange(val, "account", index)
                          handleInvoiceItemChange(val.taxRate, "taxRate", index)
                        }}
                        value={
                          invoicedProducts[index][providerKey]?.account || null
                        }
                      />
                    </div>
                    {provider.taxPerLine && (
                      <div className={"w-full py-2 px-2 " + lineWidth}>
                        <Select
                          name={`taxRate-invoice-item-${index}`}
                          options={taxRates}
                          placeholder={"Tax rate (optional)"}
                          isDisabled={loading}
                          getOptionLabel={provider.taxOptionLabelRenderFn}
                          getOptionValue={(opt) => opt}
                          onChange={(val) =>
                            handleInvoiceItemChange(val, "taxRate", index)
                          }
                          value={
                            invoicedProducts[index][providerKey]?.taxRate ||
                            null
                          }
                        />
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
          <div className="mb-4 w-full px-2 flex flex-col">
            <label className="font-sansSemiBold font-semibold mb-1">
              Total Net Cost<sup className="text-red-600">*</sup>
            </label>
            <div className="input-container input-container--left input-container--right">
              <input
                required={true}
                name="netCost"
                type="number"
                value={AccountingParams.netCost ?? ""}
                placeholder="Total NET"
                className="form-control form-control--smaller form-control--first form-control--last"
                onChange={(e) => {
                  setAccountingParams({
                    ...AccountingParams,
                    netCost: formatQuantityValue(e.target.value),
                  })
                }}
              />
            </div>
          </div>
          {taxRates && (
            <div className="mb-4 w-full md:w-1/2 px-2 flex flex-col relative">
              <label className="font-sansSemiBold font-semibold mb-1">
                Tax rate
              </label>
              <Select
                name="taxRate"
                options={taxRates}
                placeholder={"Select a tax rate"}
                maxMenuHeight={200}
                isDisabled={loading}
                getOptionLabel={provider.taxOptionLabelRenderFn}
                getOptionValue={(opt) => opt}
                onChange={(value) => {
                  setAccountingParams({
                    ...AccountingParams,
                    taxRate: value ? value : null,
                  })
                }}
                value={
                  AccountingParams?.taxRate
                    ? AccountingParams.taxRate
                    : taxRates[0]?.options[0]
                }
              />
            </div>
          )}
          <div className="mb-4 w-full md:w-1/2 px-2 flex flex-col">
            <label className="font-sansSemiBold font-semibold mb-1">
              Total VAT
            </label>
            <div className="input-container input-container--left input-container--right">
              <input
                required={true}
                name="totalVat"
                type="number"
                placeholder="Total VAT"
                value={AccountingParams.totalVat ?? ""}
                className="form-control form-control--smaller form-control--first form-control--last"
                onChange={(e) => {
                  setAccountingParams({
                    ...AccountingParams,
                    totalVat: formatQuantityValue(e.target.value),
                  })
                }}
              />
            </div>
          </div>
          <div className="mb-4 w-full px-2">
            <label className="font-sansSemiBold font-semibold mb-2 block">
              Accounting description
            </label>
            <textarea
              className={`form-control border rounded border-gray-400 w-full mb-4 text-left`}
              style={{ resize: "none" }}
              rows={6}
              value={AccountingParams.description ?? ""}
              onChange={handleTextareaChange}
              placeholder={`Add a description in Accounting`}
            ></textarea>
          </div>
          {!invoice.invoiceNumber && (
            <span className="text-red-600 block px-2 py-2">
              Invoice number is required to publish to Accounting
            </span>
          )}
          {!invoice.dueDate && (
            <span className="text-red-600 block px-2 py-2">
              Due date is required to publish to Accounting
            </span>
          )}
        </div>
      </div>
    </>
  )
}

export default AccountingPublishForm
