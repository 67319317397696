import React from "react"

import Loader from "components/common/Loader/Loader"
import withPagination from "components/base/withPagination"

import "../base/Dummy-list.css"
import { EmptyProps } from "components/base/EmptyListState"
import SetEmptyListState from "./plugins/SetEmptyListState"

interface BaseListProps {
  loading: boolean
  data: any
  empty: EmptyProps
  rowRender: any
  id?: string
  title?: string
  pagination?: any
}

// create a custom table
const BaseList: any = (props: BaseListProps) => {
  const pagination = withPagination({
    pagination: props.pagination,
    data: props.data,
  })

  let forceProps: any = {}
  forceProps = SetEmptyListState(props, forceProps)

  const listProps = { ...props, ...forceProps }

  return (
    <>
      <Loader
        isLoading={listProps.loading}
        style={{ backgroundColor: "rgba(255,255,255,0.95)" }}
      >
        Loading {listProps.title}..
      </Loader>
      <div className={`w-full h-auto ${listProps.id}List`}>
        <div className={"flex flex-col BaseList " + listProps.className}>
          {listProps.data.length > 0 &&
            listProps.data.map((rowData) => {
              return listProps.rowRender(rowData)
            })}
        </div>
        {listProps.emptyList}
      </div>
      {pagination}
    </>
  )
}

export default BaseList
