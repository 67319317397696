/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback, useContext } from "react"
import { usePromise, useMountedState } from "react-use"
import { isAfter, parseISO } from "date-fns"
import { getStockTakeSheets } from "services/stock-take"
import BaseTable, { AutoResizer } from "react-base-table"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faList,
  faSpinner,
  faSyncAlt,
} from "@fortawesome/pro-duotone-svg-icons"
import PropTypes from "prop-types"
import { convertTimeStampFormat } from "services/helpers"
import * as styles from "./StockTakeSheetSelect.module.css"
import { GlobalStateContext } from "context/GlobalContextProvider"

const StockTakeSheetSelect = ({ selectSheet, onSelect, onClose }) => {
  const _isMounted = useMountedState()
  const [loading, setLoading] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)
  const [loadedAll, setLoadedAll] = useState(false)
  const fromPromise = usePromise()
  const [productData, setProductData] = useState({
    content: [],
  })
  const { organization } = useContext(GlobalStateContext)
  const [paginationData, setPaginationData] = useState({
    page: 0,
    size: 20, //Incrementing size to make sure amount of items fit the screen and are scrollable
    totalPages: "",
    totalElements: "",
    numberOfElements: "",
  })

  const columns = [
    {
      key: "description",
      title: "Name",
      dataKey: "description",
      width: 200,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => {
        return (
          <div className="lg:pl-3 flex items-center">
            <button
              onClick={(e) => {
                e.preventDefault()
                onSelect(rowData)
                onClose()
              }}
              className={styles.thumbnail}
            >
              <FontAwesomeIcon icon={faList} />
              <span className={styles.thumbnailBadge}>
                <span>
                  <FontAwesomeIcon size="xs" icon={faSyncAlt} />
                </span>
              </span>
            </button>

            <button
              onClick={(e) => {
                e.preventDefault()
                onSelect(rowData)
                onClose()
              }}
              style={{
                minWidth: "300px",
                maxWidth: "300px",
                textAlign: "left",
              }}
              className="font-sansSemiBold focus:outline-none font-semibold text-primaryBlue py-3 px-3 flex flex-col"
            >
              <span>{rowData.name || "New stocktake"}</span>
              <div className="flex items-center">
                {isAfter(
                  parseISO(rowData.updatedAt),
                  parseISO(rowData.createdAt)
                ) ? (
                  <span className="text-gray-700 text-sm font-sansSemiBold font-semibold flex items-center">
                    <span className="mr-1">Updated at:</span>{" "}
                    {convertTimeStampFormat(
                      rowData.updatedAt,
                      organization?.address.zoneId
                    )}
                  </span>
                ) : (
                  <span className="text-gray-700 text-sm font-sansSemiBold font-semibold flex items-center">
                    <span className="mr-1">Created at:</span>{" "}
                    {convertTimeStampFormat(
                      rowData.createdAt,
                      organization?.address.zoneId
                    )}
                  </span>
                )}
              </div>
            </button>
          </div>
        )
      },
    },
    {
      key: "totalItems",
      title: "Total items",
      dataKey: "totalItems",
      width: 60,
      flexGrow: 1,
      flexShrink: 0,
      headerClassName: "flex justify-end pr-4 lg:pr-0",
      className:
        "font-sansSemiBold font-semibold text-gray-700 flex justify-end pr-4 lg:pr-0",
      cellRenderer: ({ rowData }) => {
        return (
          <div className="flex flex-col my-3">
            {rowData.productsCount + rowData.recipesCount}
          </div>
        )
      },
    },
    {
      key: "action",
      flexGrow: 1,
      flexShrink: 0,
      width: 100,
      headerClassName: "hidden md:flex",
      className: "justify-end hidden md:flex",
      // eslint-disable-next-line react/prop-types
      cellRenderer: ({ rowData }) => {
        // eslint-disable-next-line react/prop-types
        return (
          <>
            <button
              type="button"
              className="button whitespace-nowrap button--smaller button--autoWidth button--paleBlue mr-2"
              onClick={(e) => {
                e.preventDefault()
                onSelect(rowData)
                onClose()
              }}
            >
              Select
            </button>
          </>
        )
      },
    },
  ]
  const getData = useCallback(async () => {
    if (!_isMounted()) {
      return
    }
    setLoading(true)

    let params = {
      page: paginationData.page,
      size: paginationData.size,
      sort: "createdAt,desc", //Show newest elements first
    }

    // fromPromise prevents call on unmount of component
    const result = await fromPromise(getStockTakeSheets(params))

    if (result && !result.error) {
      const newContent = [
        ...result.content.map((item) => {
          return {
            ...item,
          }
        }),
      ]

      if (paginationData.page === 0) {
        setProductData({ content: [...newContent] })
      } else {
        setProductData({ content: [...productData.content, ...newContent] })
      }

      setPaginationData({
        ...paginationData,
        size: result.size,
        totalPages: result.totalPages,
        totalElements: result.totalElements,
        numberOfElements: result.numberOfElements,
      })
      setLoadingMore(false)
      setLoadedAll(paginationData.page === result.totalPages - 1)
      setLoading(false)
    } else {
      setLoading(false)
    }
  })

  useEffect(() => {
    if (selectSheet) {
      getData()
    }
  }, [selectSheet, paginationData.page])

  const handleEndReached = () => {
    if (loading || loadingMore || loadedAll) return
    loadMore()
  }

  const loadMore = () => {
    setLoadingMore(true)
    setPaginationData({ ...paginationData, page: paginationData.page + 1 })
  }
  // const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
  return (
    <>
      <div className={styles.container}>
        {loading && (
          <div className="absolute bottom-0 left-50 -translate-x-1/2 w-full z-50 pointer-events-none flex justify-center items-center mb-5">
            <h1 className="rounded-xl border px-3 py-2 bg-white">
              <FontAwesomeIcon icon={faSpinner} spin /> Loading...
            </h1>
          </div>
        )}
        <div className={styles.content}>
          <div className="w-full flex-grow">
            <AutoResizer>
              {({ width, height }) => (
                <BaseTable
                  fixed={false}
                  estimatedRowHeight={80}
                  width={width}
                  disabled={loading}
                  ignoreFunctionInColumnCompare={false} // Very important (hooks won't work otherwise)delive
                  loadingMore={loadingMore}
                  onEndReachedThreshold={50}
                  onEndReached={handleEndReached}
                  maxHeight={
                    productData.content.length === 0 ? undefined : height
                  }
                  height={productData.content.length === 0 ? 200 : height}
                  // headerHeight={0}{isTabletOrMobile ? mobileColumns : columns}
                  data={productData.content}
                  columns={columns}
                  emptyRenderer={
                    <div className={styles.noProducts}>
                      <p className="mb-4">No stocksheets found</p>
                    </div>
                  }
                />
              )}
            </AutoResizer>
          </div>
        </div>
        <footer className={styles.footer}>
          <button className={styles.cancelButton} onClick={onClose}>
            Close
          </button>
        </footer>
      </div>
    </>
  )
}

StockTakeSheetSelect.propTypes = {
  selectSheet: PropTypes.bool,
  onSelect: PropTypes.func,
  onClose: PropTypes.func,
}

export default StockTakeSheetSelect
