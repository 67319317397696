import React, { useState, useEffect, useContext } from "react"
import { usePromise, usePrevious } from "react-use"
import { navigate, Link } from "@reach/router"
import Helmet from "react-helmet"
import { useMediaQuery } from "react-responsive"
import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import OrderItemInfo from "./OrderItemInfo/OrderItemInfo"
import OrderItemState from "./OrderItemState/OrderItemState"
import OrderItemCost from "./OrderItemCost/OrderItemCost"
import OrderItemContent from "./OrderItemContent/OrderItemContent"
import OrderItemActions from "./OrderItemActions/OrderItemActions"
import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import FilterSelect from "components/forms/FilterSelect"
import AsyncSelect from "components/forms/AsyncSelect"
import Header from "components/dashboard/Header/Header"
import PlaceOrderModal from "components/common/PlaceOrderModal/PlaceOrderModal"
import DeliveryNoteDetails from "components/delivery-notes/DeliveryNoteDetails/DeliveryNoteDetails"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/GlobalContextProvider"
import { ModalContext } from "context/ModalContext"

import {
  completeOrder,
  removeOrder,
  cancelOrder,
  updateOrder,
  getOrders,
  resendOrder,
} from "services/order"
import {
  getSupplierById,
  getSuppliers,
  searchSuppliers,
} from "services/supplier"
import { orderStates } from "services/constants"
import { formatCurrencyValue } from "services/helpers"
import { showError, showSuccess } from "services/toast"

import usePermissions from "hooks/usePermissions"

import * as styles from "./Orders.module.css"
import { apiResponseIsError } from "../../../services/helpers"
import { faShoppingCart } from "@fortawesome/pro-duotone-svg-icons"
import { faFileDownload } from "@fortawesome/pro-regular-svg-icons"
import ExtBaseTable from "components/baseTable/ExtBaseTable"
import { getOrdersDummy } from "services/dummy"
import usePagination from "components/base/hooks/usePagination"
import LocationsSelector from "components/dashboard/ChartFilters/LocationsSelector"
import FiltersButton from "components/common/FiltersButton/FiltersButton"

const Orders = () => {
  const { organization, selectedOrganizations, organizationPermissions, user } =
    useContext(GlobalStateContext)
  const permissionObj = usePermissions("Orders")
  const dispatch = useContext(GlobalDispatchContext)
  const modal = useContext(ModalContext)
  const [loading, setLoading] = useState(false)
  const fromPromise = usePromise()
  const [orderData, setOrderData] = useState({
    content: [],
  })
  const [supplierData, setSupplierData] = useState({
    content: [],
  })

  const [status, setStatus] = useState(null)
  const previousStatus = usePrevious(status)
  const [supplier, setSupplier] = useState(null)
  const previousSupplier = usePrevious(supplier)
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 })
  const hasAddress =
    organization &&
    organization.address &&
    organization.address.addressLine1 &&
    organization.address.city
  const hasDeliveryAddress =
    organization &&
    organization.deliveryAddress &&
    organization.deliveryAddress.addressLine1 &&
    organization.deliveryAddress.city
  const hasNoSuppliers = supplierData.content.length === 0
  const pagination = usePagination()

  const getData = async () => {
    if (
      organizationPermissions?.general?.isMain &&
      selectedOrganizations.length === 0
    ) {
      setOrderData({ content: [] })
      return
    }

    setLoading(true)

    const params = {
      page: pagination.page,
      sort: "createdDate,desc",
      includeApprovers: true,
      includeIsDelivered: true,
    }

    if (organizationPermissions?.general?.isMain) {
      params["organizations"] = selectedOrganizations
    }

    if (supplier) {
      params.supplierId = supplier.value
      if (previousSupplier && previousSupplier.value !== supplier.value) {
        params.page = 0
      }
    }

    if (status) {
      params.status = status.value

      if (previousStatus && previousStatus.value !== status.value) {
        params.page = 0
      }
    }

    // fromPromise prevents call on unmount of component
    const result = await fromPromise(getOrders(params))

    if (result && !result.error) {
      setOrderData({ ...orderData, ...result })
      pagination.setFromResult(result)

      const suppliersResult = await getSuppliers()

      if (suppliersResult && !suppliersResult.error) {
        setSupplierData({ ...supplierData, ...suppliersResult })
        setLoading(false)
      } else {
        setLoading(false)
      }
    } else {
      setLoading(false)
    }
  }

  // EFFECTS

  useEffect(() => {
    getData()
  }, [status, pagination.page, supplier, selectedOrganizations])

  // ACTIONS

  const isUnfinished = (order) =>
    order.status === "REVIEW" || order.status === "DRAFT"

  const duplicateOrder = (orderData) => {
    const isDraft = isUnfinished(orderData)
    if (isDraft) {
      //Edit draft order
      navigate(`orders/${orderData.id}?step=items`)
    } else {
      //Create new order with duplicate data
      dispatch({
        type: "UPDATE_NEW_ORDER",
        payload: {
          po: null,
          id: null,
          supplier: orderData.supplier,
          status: "",
          notes: orderData.notes || "",
          deliveryAddress: orderData.deliveryAddress,
          expectedDeliveryDate: orderData.expectedDeliveryDate,
          items: orderData.items.map((item) => {
            return { ...item, id: item.productId }
          }),
          extractedFile: orderData.extractedFile,
          approvers: orderData.approvers,
        },
      })
      navigate("orders/new?step=items")
    }
  }

  const markPlaced = async (order) => {
    const result = await completeOrder(order.po, order.supplier.id, {
      status: "PLACED",
    })

    if (apiResponseIsError(result)) {
      if (result?.message) {
        showError(result.message)
      } else {
        showError("Something went wrong...")
      }
    } else {
      showSuccess("Order updated!")
      getData()
    }
  }

  const deleteOrder = async (order) => {
    const result = await removeOrder(order.id)

    if (apiResponseIsError(result)) {
      if (result?.message) {
        showError(result.message)
      } else {
        showError("Something went wrong...")
      }
    } else {
      showSuccess("Order deleted!")
      getData()
    }
  }

  const placeOrder = async (order, additionalParams) => {
    const params = {
      items: order.items.map((item) => {
        return {
          productId: item.productId,
          name: item.name,
          unit: item.unit,
          quantity: item.quantity,
          orderInCase: item.orderInCase,
        }
      }),
      notes: order.notes,
      deliveryAddress: order.deliveryAddress,
      po: order.po,
      supplier: {
        id: order.supplier.id,
        name: order.supplier.name,
      },
    }

    const result = await updateOrder(
      order.id,
      {
        ...params,
        status: "PLACED",
      },
      {
        firstName: user.firstName,
        lastName: user.lastName,
        skipSendingEmail: additionalParams.shouldNotifySupplier,
      }
    )

    if (apiResponseIsError(result)) {
      if (result?.message) {
        showError(result.message)
      } else {
        showError("Something went wrong...")
      }
    } else {
      getData()
    }
  }

  const startCancel = async (order) => {
    const result = await cancelOrder(order.id)

    if (apiResponseIsError(result)) {
      if (result?.message) {
        showError(result.message)
      } else {
        showError("Something went wrong...")
      }
    } else {
      showSuccess("Order cancelled!")
      getData()
    }
  }

  const onResendOrder = async (order) => {
    const result = await resendOrder(order.id)

    if (apiResponseIsError(result)) {
      if (result?.message) {
        showError(result.message)
      } else {
        showError("Something went wrong...")
      }
    } else {
      showSuccess("Order resent!")
      getData()
    }
  }

  const onDelete = (order) =>
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: `Delete ${order.po || "Order"}`,
      text: "Are you sure you want to delete this order?",
      confirmButtonText: "Delete",
      onConfirm: () => deleteOrder(order),
    })

  const onMarkDelivered = (order) => {
    //Creating new note
    const newNote = {
      status: "DRAFT",
      supplier: order.supplier,
      dateOfScanning: "",
      isCreatedManuallyWithoutOrder: false,
      po: order.po, //orderId
      products: order.items.map((currentItem) => {
        const finalProduct: any = {}
        const keysToExtract = [
          "barcode",
          "category",
          "code",
          "measure",
          "name",
          "orderInCase",
          "price",
          "receivedQty",
          "quantity",
          "size",
          "subCategory",
          "unit",
        ]
        const quantitiesToAdd = ["receivedQty", "dnQty", "orderQty"]

        keysToExtract.forEach((currentKey) => {
          finalProduct[currentKey] = currentItem[currentKey]
        })

        quantitiesToAdd.forEach((quantityKey) => {
          finalProduct[quantityKey] = currentItem["quantity"]
        })

        finalProduct["receivedQtyInCase"] = currentItem.orderInCase
        finalProduct["orderQtyInCase"] = currentItem.orderInCase
        finalProduct["orderCaseSize"] = currentItem.productCase?.size

        return finalProduct
      }),
    }

    //Setting the context of note
    dispatch({
      type: "RESET_NEW_DELIVERY_NOTE",
      options: { resetSupplier: true },
    })

    dispatch({ type: "UPDATE_NEW_DELIVERY_NOTE", payload: newNote })

    modal.showModal(DeliveryNoteDetails, {
      shouldShowCalendarImmediately: true,
      onUpdate: () => {
        getData()
      },
    })
  }

  const onMarkPlaced = (order) =>
    modal.showModal(ConfirmModal, {
      type: "success",
      title: `Mark ${order.po || "Order"} as placed`,
      text: "Are you sure you want to mark this order as placed?",
      confirmButtonText: "Mark as placed",
      onConfirm: () => markPlaced(order),
    })

  const onPlaceOrder = async (order) => {
    const supplier = await getSupplierById(order.supplier.id)
    const minOrderValue = supplier?.minOrderConfig?.minOrderValue
    const orderIsAboveMinValue = minOrderValue
      ? order.totalCost > minOrderValue
      : true

    modal.showModal(PlaceOrderModal, {
      title: `Are you sure you want to place this order?`,
      text: orderIsAboveMinValue
        ? null
        : `The total is below the min order value of ${formatCurrencyValue(
            minOrderValue
          )} for this supplier`,
      confirmButtonText: "Place Order",
      cancelButtonText: "Close",
      onConfirm: (additionalParams) => placeOrder(order, additionalParams),
    })
  }

  const onCancel = (order) =>
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: `Cancel ${order.po || "Order"}`,
      text: "Are you sure you want to cancel this order?",
      confirmButtonText: "Cancel now",
      cancelButtonText: "Close",
      onConfirm: () => startCancel(order),
    })

  const handleActionClick = (action, order) => {
    const { type } = action
    switch (type) {
      case "order.duplicate":
        duplicateOrder(order)
        break
      case "order.resend":
        onResendOrder(order)
        break
      case "order.mark_delivered":
        onMarkDelivered(order)
        break
      case "order.mark_placed":
        onMarkPlaced(order)
        break
      case "order.place":
        onPlaceOrder(order)
        break
      case "order.delete":
        onDelete(order)
        break
      case "order.cancel":
        onCancel(order)
        break
      default:
        break
    }
  }

  // BASETABLE

  const columns = [
    {
      key: "name",
      title: "Item",
      dataKey: "name",
      width: 200,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => <OrderItemInfo order={rowData} />,
    },
    {
      key: "supplier",
      title: "Supplier",
      width: 150,
      dataKey: "supplier",
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => (
        <Link
          to={`/dashboard/purchases/suppliers/${rowData?.supplier?.id}`}
          className="text-primaryBlue truncate"
        >
          {rowData?.supplier?.name}
        </Link>
      ),
    },
    {
      key: "status",
      title: "Status",
      dataKey: "status",
      className: "py-2",
      width: 150,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => <OrderItemState order={rowData} />,
    },
    {
      key: "cost",
      title: "Est. cost (exc VAT)",
      dataKey: "cost",
      width: 150,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => <OrderItemCost order={rowData} />,
    },
    {
      key: "action",
      flexGrow: 1,
      flexShrink: 0,
      width: 60,
      className: "justify-end actions",
      cellRenderer: ({ rowData }) => (
        <OrderItemActions
          order={rowData}
          handleActionClick={handleActionClick}
        />
      ),
    },
  ]

  // Location column is only shown for main orgs
  if (organizationPermissions?.general?.isMain) {
    columns.splice(1, 0, {
      key: "location",
      title: "Location/Area",
      dataKey: "location",
      width: 150,
      flexGrow: 1,
      flexShrink: 0,
      cellRenderer: ({ rowData }) => <span>{rowData?.organizationsNames}</span>,
    })
  }

  const formattedData = (data) => {
    const latestData = [...data]

    latestData.forEach((item) => {
      item.children = [
        {
          id: `${item.id}-detail`,
          content: item.items && item,
        },
      ]
    })

    return latestData
  }

  const rowRenderer = ({ rowData, cells }) => {
    if (rowData.content)
      return (
        <div className={"orderDetails w-full px-8 pb-8 itemContent"}>
          <OrderItemContent order={rowData.content} />
        </div>
      )
    return cells
  }

  return (
    <>
      <Helmet>
        <title>Orders</title>
      </Helmet>
      <div className={styles.container}>
        <Header title="Orders" />

        <div className={styles.subHeader}>
          <FiltersButton>
            <FilterSelect
              options={orderStates}
              value={status}
              onChange={(val) => {
                setStatus(val)
              }}
              placeholder={isTabletOrMobile ? "Status" : "Status: all"}
              isClearable={true}
              className="w-56"
            />
            <AsyncSelect
              promise={searchSuppliers}
              placeholder={isTabletOrMobile ? "Supplier" : "All suppliers"}
              isClearable={true}
              optionLabel="name"
              optionValue="id"
              onChange={(val) => {
                setSupplier(val)
              }}
              className="w-56"
            />
            <LocationsSelector />
          </FiltersButton>

          <div className="ml-auto space-x-4">
            <button
              className="button my-2 ml-auto button--autoWidth button--primaryGreen"
              onClick={(e) => {
                e.preventDefault()

                if (!hasDeliveryAddress && !hasAddress) {
                  return modal.showModal(ConfirmModal, {
                    type: "success",
                    title: "No address",
                    text: "To place an order a company and/or address is needed",
                    confirmButtonText: "Add address",
                    onConfirm: () => {
                      navigate("/dashboard/company-settings", {
                        state: { initialTab: "company-details" },
                      })
                    },
                  })
                }

                if (hasNoSuppliers) {
                  return modal.showModal(ConfirmModal, {
                    type: "success",
                    title: "No suppliers yet",
                    text: "You have no suppliers added yet. To place an order, please add a supplier.",
                    confirmButtonText: "Add supplier",
                    onConfirm: () => {
                      navigate("/dashboard/purchases/suppliers")
                    },
                  })
                }

                dispatch({ type: "RESET_NEW_ORDER" })
                navigate("orders/new")
              }}
              disabled={!permissionObj?.permissions.modify}
            >
              <FontAwesomeIcon icon={faPlus} className="lg:mr-2" />
              <span className="hidden lg:inline">New order</span>
            </button>

            <button
              onClick={(e) => {
                e.preventDefault()
                navigate("/dashboard/purchases/orders/export")
              }}
              className="button text-center button--autoWidth button--primaryGreen"
              disabled={!permissionObj?.permissions.read}
            >
              <FontAwesomeIcon icon={faFileDownload} />
            </button>
          </div>
        </div>

        <div className={styles.content}>
          <ExtBaseTable
            loading={loading}
            pagination={pagination}
            data={formattedData(orderData.content)}
            columns={columns}
            ignoreFunctionInColumnCompare={false} // Very important (hooks won't work otherwise)
            title="Orders"
            id="order"
            empty={{
              icon: faShoppingCart,
              color: "pink",
              getDummy: () => getOrdersDummy,
            }}
            estimatedRowHeight={200}
            expandColumnKey={"name"}
            expand={{}}
            rowRenderer={rowRenderer}
          />
        </div>
      </div>
    </>
  )
}

export default Orders
