import React, { useState, useEffect, useRef, useContext } from "react"
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link, useLocation } from "@reach/router"
import classNames from "classnames/bind"
import { menuItems, isDevelopment } from "services/constants"
import { useClickAway } from "react-use"
import { faBan } from "@fortawesome/pro-regular-svg-icons"
import * as styles from "./DesktopNav.module.css"
import { MenuItem, ParentMenuItem } from "services/types"
import { GlobalStateContext } from "context/GlobalContextProvider"
import ReferralButton from "components/common/ReferralButton/ReferralButton"

const cx = classNames.bind(styles)

interface DesktopNavItemSingleProps {
  item: ParentMenuItem
  collapsed?: boolean
}

interface DesktopNavItemCollectionProps {
  item: ParentMenuItem
  collapsed?: boolean
}

const DesktopNavItemSingle = ({
  item,
  collapsed,
}: DesktopNavItemSingleProps) => {
  return (
    <li className="mb-2">
      <Link
        to={item.link || "/not-found"}
        tabIndex={item.disabled ? -1 : 0}
        getProps={({ isCurrent, isPartiallyCurrent }) => {
          return {
            className: cx("navItem", {
              // exclude homepage dashboard from active status
              activeItem:
                isCurrent || (isPartiallyCurrent && item.link !== "./"),
              disabled: item.disabled,
            }),
          }
        }}
      >
        <span className={styles.navItemIcon}>
          <FontAwesomeIcon icon={item.icon} />
        </span>
        {!collapsed && <span className="flex-grow">{item.name}</span>}
        {item.label && (
          <span
            className="label label--pink absolute"
            style={{ right: !collapsed ? "1.5rem" : "0.2rem" }}
          >
            {collapsed ? "" : item.label}
          </span>
        )}
        {item.disabled && (
          <FontAwesomeIcon
            className="ml-auto mr-2 text-primaryPinkLighter"
            icon={faBan}
          />
        )}
      </Link>
    </li>
  )
}

const DesktopNavItemCollection = ({
  item,
  collapsed,
}: DesktopNavItemCollectionProps) => {
  const [active, setActive] = useState(false)
  const [init, setInit] = useState(false)
  const location = useLocation()
  const dropdownRef = useRef(null)

  useClickAway(dropdownRef, () => {
    if (collapsed) {
      setActive(false)
    }
  })

  useEffect(() => {
    if (active && !init) {
      setInit(true)
    }
  }, [active, init])

  useEffect(() => {
    const isCurrent = location.pathname === item.link
    const isPartiallyCurrent = location.pathname.includes(item.link)

    if ((isCurrent || isPartiallyCurrent) && !init) {
      if (!collapsed) {
        setActive(true)
      }
    }
  }, [location, init, item])

  return (
    <li className={cx("mb-2", { relative: !collapsed })} ref={dropdownRef}>
      <Link
        tabIndex={item.disabled ? -1 : 0}
        to={item.link || "not-found"}
        onClick={(e) => {
          e.preventDefault()
          setActive(!active)
        }}
        getProps={({ isCurrent, isPartiallyCurrent }) => {
          return {
            className: cx("navItem", {
              activeItem: isCurrent || isPartiallyCurrent,
              collapsed: collapsed,
              disabled: item.disabled,
            }),
          }
        }}
      >
        <span className={styles.navItemIcon}>
          <FontAwesomeIcon icon={item.icon} />
        </span>
        {!collapsed && <span className="flex-grow">{item.name}</span>}
        {item.label && (
          <span
            className="label label--pink absolute"
            style={{ right: !collapsed ? "1.5rem" : "0.2rem" }}
          >
            {collapsed ? "" : item.label}
          </span>
        )}
        {!collapsed && (
          <FontAwesomeIcon
            className="ml-auto mr-3"
            size="xs"
            icon={faChevronDown}
            //@ts-ignore
            rotation={active ? 180 : 0}
          />
        )}
      </Link>
      {active && (
        <ul className={cx("navChildren", { collapsed: collapsed })}>
          {item.children
            ?.filter((item) => isDevelopment || item.status !== "alpha")
            .map((item, index) => (
              <li key={`${item.name}-${index}`}>
                <Link
                  to={item.link || "/not-found"}
                  tabIndex={item.disabled ? -1 : 0}
                  getProps={({ isCurrent, isPartiallyCurrent }) => {
                    return {
                      className: cx("navChildItem", {
                        activeChildItem: isCurrent || isPartiallyCurrent,
                        disabled: item.disabled,
                      }),
                    }
                  }}
                >
                  <span>{item.name}</span>
                  {item.disabled && (
                    <FontAwesomeIcon
                      className="ml-auto mr-2 text-primaryPinkLighter"
                      icon={faBan}
                    />
                  )}
                </Link>
              </li>
            ))}
        </ul>
      )}
    </li>
  )
}

const DesktopNav = ({ isCollapsed }: { isCollapsed: boolean }) => {
  const { organization } = useContext(GlobalStateContext)
  const [navItems, setNavItems] = useState<MenuItem[] | ParentMenuItem[]>([])
  const getMenuItems = async () => {
    const items = await menuItems()
    setNavItems(items)
  }
  useEffect(() => {
    getMenuItems()
  }, [organization])

  return (
    <nav className={`${isCollapsed ? "w-auto" : "w-full"} flex flex-col`}>
      <ul>
        {navItems.map((item, index) => {
          return item.children && item.children.length > 0 ? (
            <DesktopNavItemCollection
              collapsed={isCollapsed}
              key={index}
              item={item}
            />
          ) : (
            <DesktopNavItemSingle
              collapsed={isCollapsed}
              key={index}
              item={item}
            />
          )
        })}
      </ul>
      <div className="mt-auto">
        <ReferralButton collapsed={isCollapsed} />
      </div>
    </nav>
  )
}

export default DesktopNav
