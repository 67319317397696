import { get } from "./api"
import { saveAs } from "file-saver"

export const exportPriceReportInPeriodOfTime = async (params) => {
  const response = await get("/price-reports/export-as-xlsx", params, {
    download: true,
  })
  if (response) {
    const result = await response.blob()
    saveAs(result, `price-changes-report-${params.from}-${params.to}.xlsx`)
  } else {
    return false
  }
}
