import React, { useState, useContext } from "react"
import { ModalContext } from "context/ModalContext"

import { formatCurrencyValue, roundNumber } from "services/helpers"

import { format } from "date-fns"
import classNames from "classnames/bind"

import ConfirmModal from "components/common/ConfirmModal/ConfirmModal"
import EditWasteModal from "../EditWasteModal/EditWasteModal"
import WasteTable from "../WasteTable/WasteTable"

import * as styles from "./WasteContent.module.css"
import ReactTooltip from "react-tooltip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons"

interface Props {
  wasteGroup: {
    id: string
    products: { product: { id: string } }[]
    recipes: { id: string }[]
    dishes: { id: string }[]
    totalCost: number
  }
  removeWaste: (waste: any, product: any, wasteGroupId: any) => Promise<void>
  deleteWasteProduct: (wasteGroupId: any, wasteId: any) => Promise<void>
  deleteWasteRecipe: (wasteGroupId: any, wasteId: any) => Promise<void>
  deleteWasteDish: (wasteGroupId: any, wasteId: any) => Promise<void>
  deleteWasteRecipeRecord: (
    wasteGroupId: any,
    recipeId: any,
    wasteId: any
  ) => Promise<void>
  deleteWasteDishRecord: (
    wasteGroupId: any,
    dishId: any,
    wasteId: any
  ) => Promise<void>
  editWaste: (
    wasteGroupId: any,
    wasteId: any,
    barcode: any,
    updatedData: any
  ) => Promise<void>
  editWasteRecipe: (
    wasteGroupId: any,
    wasteId: any,
    recipeId: any,
    updatedData: any
  ) => Promise<void>
  editWasteDish: (
    wasteGroupId: any,
    wasteId: any,
    dishId: any,
    updatedData: any
  ) => Promise<void>
}

const WasteContent = ({
  wasteGroup,
  removeWaste,
  deleteWasteProduct,
  deleteWasteRecipe,
  deleteWasteDish,
  deleteWasteRecipeRecord,
  deleteWasteDishRecord,
  editWaste,
  editWasteRecipe,
  editWasteDish,
}: Props) => {
  const modal = useContext(ModalContext)
  const [tab, setTab] = useState("items")

  const cx = classNames.bind(styles)

  const currency = null

  const onDeleteWaste = (waste, product) => {
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: `Delete all waste records for ${
        format(new Date(waste.timeOfRecord), "dd-MM-yy - HH:mm") || "Waste"
      }`,
      text: "Are you sure you want to delete all records?",
      confirmButtonText: "Delete",
      onConfirm: () => {
        removeWaste(waste, product, wasteGroup.id)
      },
    })
  }

  const onDeleteWasteProduct = (product) => {
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: `Delete all waste records for ${product.product.name}?`,
      text: "Are you sure you want to delete all records?",
      confirmButtonText: "Delete",
      onConfirm: () => {
        return deleteWasteProduct(wasteGroup.id, product)
      },
    })
  }

  const onDeleteWasteRecipe = (waste) => {
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: `Delete all wastes for ${waste.recipe.name}?`,
      text: "Are you sure you want to delete all wastes?",
      confirmButtonText: "Delete",
      onConfirm: () => {
        return deleteWasteRecipe(wasteGroup.id, waste.recipe.id)
      },
    })
  }

  const onDeleteWasteDish = (waste) => {
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: `Delete all wastes for ${waste.dish.name}?`,
      text: "Are you sure you want to delete all wastes?",
      confirmButtonText: "Delete",
      onConfirm: () => {
        return deleteWasteDish(wasteGroup.id, waste.dish.id)
      },
    })
  }

  const onDeleteWasteRecipeRecord = (singleWaste, waste) => {
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: `Delete all wastes for ${waste.recipe.name}?`,
      text: "Are you sure you want to delete all wastes?",
      confirmButtonText: "Delete",
      onConfirm: () => {
        return deleteWasteRecipeRecord(
          wasteGroup.id,
          waste.recipe.id,
          singleWaste.id
        )
      },
    })
  }

  const onDeleteWasteDishRecord = (singleWaste, waste) => {
    modal.showModal(ConfirmModal, {
      type: "danger",
      title: `Delete all wastes for ${waste.dish.name}?`,
      text: "Are you sure you want to delete all wastes?",
      confirmButtonText: "Delete",
      onConfirm: () => {
        return deleteWasteDishRecord(
          wasteGroup.id,
          waste.dish.id,
          singleWaste.id
        )
      },
    })
  }

  const onEditWaste = (waste, product) => {
    modal.showModal(EditWasteModal, {
      product: product.product,
      waste,
      wasteGroup: wasteGroup,
      onEdit: (product, wasteId, updatedData) => {
        return editWaste(wasteGroup.id, wasteId, product.barcode, updatedData)
      },
    })
  }

  const onEditWasteRecipe = (waste, recipe) => {
    modal.showModal(EditWasteModal, {
      recipe,
      waste,
      wasteGroup: wasteGroup,
      onEdit: (recipe, wasteId, updatedData) => {
        return editWasteRecipe(
          wasteGroup.id,
          wasteId,
          recipe.recipe.id,
          updatedData
        )
      },
    })
  }

  const onEditWasteDish = (waste, dish) => {
    modal.showModal(EditWasteModal, {
      dish,
      waste,
      wasteGroup: wasteGroup,
      onEdit: (dish, wasteId, updatedData) => {
        return editWasteDish(wasteGroup.id, wasteId, dish.dish.id, updatedData)
      },
    })
  }

  const calculateTotalCostWasteItems = (items) => {
    return items.reduce(
      (accumulator, item) =>
        item.totalCost ? accumulator + item.totalCost : accumulator + 0,
      0
    )
  }

  return (
    <div className={styles.content}>
      <div className="flex flex-col overflow-auto">
        <ul className={styles.tabs}>
          <li>
            <button
              className={cx("tabButton", {
                active: tab === "items",
              })}
              onClick={() => setTab("items")}
            >
              Items
            </button>
          </li>
          <li>
            <button
              className={cx("tabButton", {
                active: tab === "recipes",
              })}
              onClick={() => setTab("recipes")}
            >
              <div className="flex gap-x-1.5 items-center">
                <div>Recipes</div>

                <div>
                  <ReactTooltip
                    id={"recipes_tooltip"}
                    type="light"
                    place="top"
                    effect="float"
                    border={true}
                    borderColor="#e2e8f0"
                  />
                  <span
                    data-for={"recipes_tooltip"}
                    data-tip="Recipes ingredients can be found in the 'Items' section"
                  >
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </span>
                </div>
              </div>
            </button>
          </li>
          <li>
            <button
              className={cx("tabButton", {
                active: tab === "dishes",
              })}
              onClick={() => setTab("dishes")}
            >
              <div className="flex gap-x-1.5 items-center">
                <div>Dishes</div>

                <div>
                  <ReactTooltip
                    id={"dishes_tooltip"}
                    type="light"
                    place="top"
                    effect="float"
                    border={true}
                    borderColor="#e2e8f0"
                  />
                  <span
                    data-for={"dishes_tooltip"}
                    data-tip="Dishes ingredients can be found in the 'Items' section"
                  >
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </span>
                </div>
              </div>
            </button>
          </li>
        </ul>
        {tab === "items" && (
          <div className="p-2 sm:p-5">
            {wasteGroup.products?.length > 0 ? (
              wasteGroup.products.map((product, index) => {
                return (
                  <React.Fragment key={`${product.product.id}-${index}`}>
                    <WasteTable
                      waste={product}
                      onEditWaste={onEditWaste}
                      onDeleteWaste={onDeleteWaste}
                      onDeleteWasteTotal={onDeleteWasteProduct}
                      wasteGroup={wasteGroup}
                    />
                  </React.Fragment>
                )
              })
            ) : (
              <span className="text-sm">No waste items to show</span>
            )}
          </div>
        )}

        {tab === "recipes" && (
          <div className="p-5">
            {wasteGroup.recipes?.length > 0 ? (
              wasteGroup.recipes.map((recipe, index) => {
                return (
                  <React.Fragment key={`${recipe.id}-${index}`}>
                    <WasteTable
                      waste={recipe}
                      onEditWaste={onEditWasteRecipe}
                      onDeleteWaste={onDeleteWasteRecipeRecord}
                      onDeleteWasteTotal={onDeleteWasteRecipe}
                      wasteGroup={wasteGroup}
                      isRecipe
                    ></WasteTable>
                  </React.Fragment>
                )
              })
            ) : (
              <span className="text-sm">No waste items to show</span>
            )}
          </div>
        )}

        {tab === "dishes" && (
          <div className="p-5">
            {wasteGroup.dishes?.length > 0 ? (
              wasteGroup.dishes.map((dish, index) => {
                return (
                  <React.Fragment key={`${dish.id}-${index}`}>
                    <WasteTable
                      waste={dish}
                      onEditWaste={onEditWasteDish}
                      onDeleteWaste={onDeleteWasteDishRecord}
                      onDeleteWasteTotal={onDeleteWasteDish}
                      wasteGroup={wasteGroup}
                      isDish
                    ></WasteTable>
                  </React.Fragment>
                )
              })
            ) : (
              <span className="text-sm">No waste items to show</span>
            )}
          </div>
        )}
      </div>
      <div className={styles.total}>
        {tab === "items" && (
          <>
            <span className="font-sansBold text-darkOrange">{`${wasteGroup.products.length} items`}</span>
            <span className="font-sansBold text-darkOrange">{`Total cost: ${
              currency
                ? formatCurrencyValue(wasteGroup.totalCost)
                : roundNumber(wasteGroup.totalCost)
            }`}</span>
          </>
        )}

        {tab === "recipes" && (
          <>
            <span className="font-sansBold text-darkOrange">{`${
              wasteGroup.recipes ? wasteGroup.recipes.length : 0
            } recipes`}</span>
            <span className="font-sansBold text-darkOrange">{`Total cost: ${
              wasteGroup.recipes
                ? currency
                  ? formatCurrencyValue(
                      calculateTotalCostWasteItems(wasteGroup.recipes)
                    )
                  : roundNumber(
                      calculateTotalCostWasteItems(wasteGroup.recipes)
                    )
                : 0
            }`}</span>
          </>
        )}

        {tab === "dishes" && (
          <>
            <span className="font-sansBold text-darkOrange">{`${
              wasteGroup.dishes ? wasteGroup.dishes.length : 0
            } recipes`}</span>
            <span className="font-sansBold text-darkOrange">{`Total cost: ${
              wasteGroup.dishes
                ? currency
                  ? formatCurrencyValue(
                      calculateTotalCostWasteItems(wasteGroup.dishes),
                      currency
                    )
                  : roundNumber(calculateTotalCostWasteItems(wasteGroup.dishes))
                : 0
            }`}</span>
          </>
        )}
      </div>
    </div>
  )
}

export default WasteContent
