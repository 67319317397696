import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useCallback } from "react"

type Props = {
  title: string
  tabKey: string
  isActive: boolean
  handleTabChange: (tabKey: string) => void
  showWarning?: boolean
}

const TabTitle: React.FC<Props> = ({
  title,
  handleTabChange,
  tabKey,
  isActive,
  showWarning,
}) => {
  const onClick = useCallback(() => {
    handleTabChange(tabKey)
  }, [handleTabChange, tabKey])

  return (
    <li>
      <button
        type="button"
        className={`${isActive ? "active" : " "}`}
        onClick={onClick}
      >
        {title}
        {showWarning && (
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="text-red-500 ml-2"
          />
        )}
      </button>
    </li>
  )
}

export default TabTitle
