import React, { useState, useContext } from "react"
import { GlobalStateContext } from "../../../context/GlobalContextProvider"
import Modal from "react-modal"
import PropTypes from "prop-types"

import { Formik, Form } from "formik"
import * as Yup from "yup"

import { sendEmailForStockBelowMinQty } from "services/stock-take"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTimes,
  faSpinnerThird,
  faEnvelope,
} from "@fortawesome/pro-light-svg-icons"
import {
  faExclamationTriangle,
  faSyncAlt,
} from "@fortawesome/pro-duotone-svg-icons"

import TextInput from "../../forms/TextInput"

import * as styles from "./EmailListModal.module.css"

Modal.setAppElement("body")

const EmailListSchema = Yup.object().shape({
  username: Yup.string()
    .email("Email is invalid")
    .required("Email is required"),
})

const EmailListModal = ({
  onRequestClose,
  stockBelowMinQtyReportId,
  ...otherProps
}) => {
  const { organization } = useContext(GlobalStateContext)
  const [error, setError] = useState("")
  const [submitted, setSubmitted] = useState(false)

  const onSubmit = async (params) => {
    const result = await sendEmailForStockBelowMinQty(
      stockBelowMinQtyReportId,
      params.username
    )

    if (result && !result.error) {
      setSubmitted(true)
    } else {
      setError(result.message)
    }
  }

  const initialValues = {
    username: organization?.orgCreator?.username || "",
  }

  return (
    <Modal
      isOpen
      portalClassName="contact"
      onRequestClose={onRequestClose}
      {...otherProps}
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="text-gray-700 absolute right-0 py-4 px-6"
      >
        <FontAwesomeIcon size="lg" icon={faTimes} />
      </button>
      <Formik
        initialValues={initialValues}
        validationSchema={EmailListSchema}
        onSubmit={onSubmit}
      >
        {({ isValid, isSubmitting }) => (
          <Form className={styles.form}>
            <div className={styles.welcomeContent}>
              <div className={styles.thumbnail}>
                <FontAwesomeIcon icon={faSyncAlt} />
                <span className={styles.thumbnailBadge}>
                  <span>
                    <FontAwesomeIcon size="xs" icon={faExclamationTriangle} />
                  </span>
                </span>
              </div>
              <h1 className="text-xl mb-4">Stock on hand below min qty</h1>
              <p className="text-gray-700">Send the report to:</p>
            </div>

            {error && <div className={styles.error}>{error}</div>}

            {!isSubmitting && !submitted && (
              <>
                <div className="form-group">
                  <div className="input-container">
                    <TextInput
                      name="username"
                      autoComplete="username"
                      type="email"
                      className="form-control"
                      placeholder="Email address"
                      label="Email address"
                    />
                  </div>
                </div>

                <div className="flex justify-center">
                  <button
                    disabled={!isValid || isSubmitting}
                    type="submit"
                    className="button button--primaryGreen button--autoWidth mt-5 mb-4"
                  >
                    <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                    Send email
                  </button>
                </div>
              </>
            )}

            {isSubmitting && !submitted && (
              <div className="text-center text-3xl text-primaryPink">
                <FontAwesomeIcon icon={faSpinnerThird} spin />
              </div>
            )}

            {submitted && (
              <div className="text-center text-lg text-primaryGreen font-sansBold">
                <p>The report has been emailed</p>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

EmailListModal.propTypes = {
  onRequestClose: PropTypes.func,
  stockBelowMinQtyReportId: PropTypes.string,
}

export default EmailListModal
